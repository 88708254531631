<div class="toolbox_eletronicFence" *ngIf="this.permissionService.hasPermission('CERCA_BOTAO_FUNCAO', this.permissions)">
  <mat-button-toggle-group
    name="route"
    [selected]="selected"
    (click)="onChangeValue()"
  >
    <mat-button-toggle value="electronicFence" [ngClass]="{ btnSelected: selected }">
      <mat-icon>layers</mat-icon>
      <span class="btn-icon-text">Cerca Eletrônica</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

