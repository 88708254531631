<div class="header">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="logo col-2 col-md-6 d-none d-sm-block">
        <a routerLink="/"><img src="../../assets/img/logo.svg" height="30" alt="" /></a>
      </div>
      <div class="logo col-2 col-md-6 d-block d-sm-none">
        <a href="#"><img src="../../assets/img/logo-mobile.svg" height="30" alt="" /></a>
      </div>
      <div class="col-10 col-md-6">
        <nav class="navbar navbar-user navbar-expand">
          <div class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto nav-flex-icons align-items-center">
              <li class="nav-item mx-1">
                  <a class="nav-link bell" (click)="toggleNotificationList()" >
                    <img src="../../assets/img/bell.svg" height="25" alt="" />
                    <span class="badge badge-notify" *ngIf="notifications.length > 0">0</span>
                    <span class="badge badge-notify" *ngIf="notifications.length == 0"></span>
                  </a>
                <ul *ngIf="showNotificationList" class="notification-list">
                  <li *ngFor="let notification of notifications">
                      <div class="notification-item">
                          <div class="notification-column">
                              <b>GEST/SUPERV:</b> {{ notification.responsible_name_disapproval }}
                          </div>
                          <div class="notification-column">
                              <b>Descrição:</b> {{ notification.description_rejection }}
                          </div>
                          <div class="notification-column" style="text-align: center;">
                              <button mat-flat-button color="primary" (click)="viewRejectionOperator(notification.uuid)">
                                <mat-icon>check_circle</mat-icon>
                            </button>
                          </div>
                      </div>
                  </li>
              </ul>
            </li>

              <li class="nav-item avatar dropdown mx-1 text-right" ngbDropdown>
                <a class="nav-link" id="dropdownProfile" data-toggle="dropdown" ngbDropdownToggle aria-haspopup="true"
                  aria-expanded="false" ngbDropdownToggle>
                  <img src="../../assets/img/user.svg" width="35" class="rounded-circle" alt="avatar image" />
                </a>
                <div ngbDropdownMenu aria-labelledby="dropdownProfile"
                  class="dropdown-menu dropdown-menu-right dropdown-secondary">
                  <a class="dropdown-item" href="/settings">Minha conta</a>
                  <a class="dropdown-item" href="#" (click)="logout()">Sair</a>
                </div>
              </li>
              <li id="menu-mobile" class="nav-item mx-1 menu-mobile d-block d-md-none">
                <a href="#"><i class="fas fa-bars"></i></a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</div>