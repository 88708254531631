import { Component, Input, OnInit } from '@angular/core';
import { Skeleton } from './skeletonPayload';

const skeletonDefault:Skeleton[] = [{
                            styleClass: "p-mb-2", 
                            width: "10rem", 
                            height: "4rem", 
                            borderRadius: "", 
                            size: "",
                            shape: ""
                        } ];

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {

  @Input() public payload: Skeleton[] = skeletonDefault;

  constructor() { this.payload = skeletonDefault; }

  ngOnInit() {
    console.log(this.payload)
  }

  public geettleton(){}
}
