<div class="header">
  <h3>Configuração</h3>
</div>

<div class="container-fluid" style="overflow-y: auto;">
  <div class="container">
    <div class="row">
      <app-list-toggle [dataValue]="dataValue" style="width: 40em;"></app-list-toggle>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 p-20" style="display: flex !important; justify-content: flex-end !important;">
      <button type="button" mat-flat-button color="warn" (click)="onCancel()">Cancelar</button>
      <button class="ml-10" mat-flat-button color="primary" (click)="onSave()" cdkFocusInitial>Salvar</button>
    </div>
  </div>
</div>
