import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-list-toggle',
  templateUrl: './app-list-toggle.component.html',
  styleUrls: ['./app-list-toggle.component.scss'],
})
export class AppListToggleComponent implements OnInit {
  /**
   * Valores abaixo são para exemplificar oque este componente espere, substitua-os quando fizer uso deste componente
   *
   * ```
   *  <app-list-toggle [dataSource]="dataSource" [displayedColumns]="displayedColumns" [dataValue]="dataValue"></app-list-toggle>
   *  ```
   */
  @Input() displayedColumns: string[] = ['operation', 'slide'];

  @Input() dataSource = [
    { operation: 'Leitura', booleanAttribute: 'flLeitura' },
    { operation: 'Inserção', booleanAttribute: 'flInsercao' },
    { operation: 'Atualização', booleanAttribute: 'flAtualizacao' },
    { operation: 'Deleção', booleanAttribute: 'flDelecao' },
  ];

  @Input() dataValue = {
    flLeitura: true,
    flInsercao: true,
    flAtualizacao: true,
    flDelecao: true,
  };

  @Output() masterToggleChange = new EventEmitter<any>();
  @Output() toggleChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  onMasterToggleChange(event: MatSlideToggleChange) {
    this.dataSource.map(
      (element) => (this.dataValue[element.booleanAttribute] = event.checked)
    );
    this.masterToggleChange.emit(event);
  }

  onSlaveToggleChange(event, element) {
    this.dataValue[element.booleanAttribute] = !this.dataValue[
      element.booleanAttribute
    ];
    this.toggleChange.emit({ event, data: element });
  }

  hasAttributeFalse = (): boolean =>
    this.dataSource.every(
      (element) => this.dataValue[element.booleanAttribute]
    );
}
