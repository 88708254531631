/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { MapToolboxService } from "./../../../services/map.toolbox.service";
import { MatDialogRef } from "@angular/material/dialog";
import { MapButtonService } from "./../../../services/map.buttom.service";
import { ThemePalette } from "@angular/material/core";
import lodash from "lodash";
import { ContentInterface } from "./../../../contracts/Content.interface";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";
import ApiService from "src/app/services/api.service";
import { FormGroup } from "@angular/forms";
import { Company } from "src/app/models/Company.model";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { NgxSpinnerService } from "ngx-spinner";

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  idEmpresa: number;
  subtasks?: Task[];
}

declare let google: any;

@Component({
  selector: "app-electronic-fence",
  templateUrl: "./electronic-fence.component.html",
  styleUrls: ["./electronic-fence.component.scss"],
})
export class ElectronicFenceComponent implements OnInit {
  public electronicFenceName;

  private service: MapToolboxService;
  private mapButtonService: MapButtonService;

  public fanceForm: FormGroup;

  private geoCoder;

  public tabSelected = 0;
  public company: any = [];
  public cercaEletronicaLocalProcessamento: any = [];
  public cercaEletronicaTipo: any = [];
  public empresaSelected = null;
  public empresaValue = null;
  public cercaEletronicaLocalProcessamentoSelected;
  public cercaEletronicaTipoSelected;
  public companiesSelect: any = [];
  public companiesFilter: any = [];
  public empresaFilterSelected;

  command: any;
  addressOrigem: any;

  velocidadeAlarmeVeiculo: number;
  velocidadeAlarmePainel: number;

  botaoConfirmar = false;

  private apiService: ApiService;

  task: Task = {
    name: "Todos",
    completed: false,
    color: "primary",
    subtasks: [],
    idEmpresa: 0,
  };
  markers = [];

  constructor(
    apiService: ApiService,
    private serviceApiBase: ApiBaseService,
    service: MapToolboxService,
    mapButtonService: MapButtonService,
    public dialogRef: MatDialogRef<any>,
    private spinner: NgxSpinnerService
  ) {
    this.service = service;
    this.mapButtonService = mapButtonService;
    this.apiService = apiService;
    this.dialogRef.afterClosed().subscribe(() => {
      if (!this.botaoConfirmar) {
        this.mapButtonService.setElectronicFenceButton(false);
      }
    });
  }

  ngOnInit(): void {
    this.setup();
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tabSelected = tabChangeEvent.index;
  }

  setTab(index: number): void {
    console.log("index", index);
  }

  velocidadeAlarmeVeiculoChanged(event: any): void {
    this.velocidadeAlarmeVeiculo = event.target.value;
  }
  velocidadeAlarmePainelChanged(event: any): void {
    this.velocidadeAlarmePainel = event.target.value;
  }

  close(): void {
    this.mapButtonService.setElectronicFenceButton(false);
    this.dialogRef.close();
  }

  async setup(): Promise<void> {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
    // const company = await this.apiService.get<ContentInterface[]>({
    //   url: "empresa/perfil/" + perfil + "?size=2000",
    // });

    const params = {
      size: 999999,
      page: 0,
      profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
    };

    const result = await this.serviceApiBase.get<any>({
      url: new Company()["nomeConsultaApiBaseGetAll"],
      params,
    });

    console.log(result);

    this.company = result["content"];

    const cercaEletronicaLocalProcessamento = await this.apiService.get<
      ContentInterface[]
    >({
      url: "cerca-eletronica-local-processamento" + "?size=2000",
    });

    this.cercaEletronicaLocalProcessamento =
      cercaEletronicaLocalProcessamento["content"];

    const cercaEletronicaTipo = await this.apiService.get<ContentInterface[]>({
      url: "cerca-eletronica-tipo" + "?size=2000",
    });

    this.cercaEletronicaTipo = cercaEletronicaTipo["content"];

    this.companiesSelect = [
      { name: "Divisão", value: 1 },
      { name: "Matriz", value: 2 },
      { name: "Filial", value: 3 },
    ];
  }

  submit(): void {
    this.botaoConfirmar = true;
    var posicoes = "";

    let elements = [];
    const itens = this.task.subtasks.filter((y) => y.completed == true);

    itens.forEach((action) => {
      const { id_terminal } = this.markers.find((x) => x.placa == action.name);
      elements.push(id_terminal);
    });

    elements.forEach((id) => {});

    console.log(this.empresaValue);
    var data;

    data = {
      action: "electronicFence_map",
      values: {
        nomeCercaEletronica: this.electronicFenceName.toUpperCase(),
        velocidadeAlarmeVeiculo: this.velocidadeAlarmeVeiculo,
        velocidadeAlarmePainel: this.velocidadeAlarmePainel,
        idEmpresa: this.empresaValue.id,
        cnpj: this.empresaValue.cnpj,
        idCercaEletronicaLocalProcessamento:
          this.cercaEletronicaLocalProcessamentoSelected,
        idCercaEletronicaTipo: this.cercaEletronicaTipoSelected,
        veiculos: elements,
      },
    };

    this.service.setCommand(data);
    this.dialogRef.close();
  }

  listaDePlacas = new Array<any>();
  eletric_fence_payload = new Array();
  allComplete: boolean = false;

  public someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return (
      this.task.subtasks.filter((t) => t.completed).length > 0 &&
      !this.allComplete
    );
  }

  public updateAllComplete() {
    this.allComplete =
      this.task.subtasks != null &&
      this.task.subtasks.every((t) => t.completed);
  }

  public setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach((t) => (t.completed = completed));
  }

  public handleFilter(event: string) {
    let query = "";

    if (event) {
      query = event.toLocaleUpperCase();
    }

    this.task.subtasks = this.listaDePlacas.filter(({ name }) =>
      name.match(new RegExp("(" + query + ")"))
    );
  }

  private buildPosicaoAtualTerminalList(source: Array<any>): Array<any> {
    const items = source.map((e) => e.posicaoAtualTerminalList);

    const itemsChildren = source
      .map((value) => value.children.map((e) => e.posicaoAtualTerminalList))
      .flat();

    const itemsChildrenInChildren = source
      .map((value) => value.children.map((e) => e.children))
      .flat();

    itemsChildrenInChildren.forEach((e) => {
      if (e.length > 0) {
        e.forEach((element) => {
          itemsChildren.push(element.posicaoAtualTerminalList);
        });
      }
    });

    return lodash.flattenDeep([...items, ...itemsChildren]);
  }

  // public async loadMarkers(): Promise<void> {
  //   try {
  //     const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(); //localStorage.getItem('idPerfil');
  //     if (!perfil) {
  //       throw new Error("Nenhum perfil encontrado");
  //     }
  //     const url = `posicao-atual-terminal/perfil/${perfil}`;
  //     const source = await this.apiService.get<any[]>({
  //       url,
  //     });

  //     if (!source) {
  //       throw new Error("Nenhum result encontrado");
  //     }

  //     const result = this.buildPosicaoAtualTerminalList(source);

  //     console.log(result);

  //     result.forEach((e) => {
  //       this.task.subtasks.push({
  //         name: e.placa,
  //         completed: false,
  //         color: "primary",
  //         idEmpresa: e.idEmpresa,
  //       });
  //       this.listaDePlacas.push({
  //         name: e.placa,
  //         completed: false,
  //         color: "primary",
  //         idEmpresa: e.idEmpresa,
  //       });
  //     });
  //     this.markers = result;

  //     console.log(this.task);
  //     console.log(this.markers);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  public async loadMarkersFilter(company: any): Promise<void> {
    try {
      console.log(company);
      this.spinner.show();
      var url: any;
      var source: any;
      console.log(this.empresaFilterSelected);
      if (this.empresaFilterSelected == "Divisão") {
        url = `posicao-atual-terminal/idDivisao/${company.id}`;
        source = await this.apiService.get<any[]>({
          url,
        });
      } else {
        url = `posicao-atual-terminal/idEmpresa/${company.id}`;
        source = await this.apiService.get<any[]>({
          url,
        });
      }

      console.log(source);

      this.task.subtasks = [];

      if (!source) {
        throw new Error("Nenhum result encontrado");
      }

      source.forEach((e) => {
        if (this.empresaFilterSelected == "Divisão") {
          this.task.subtasks.push({
            name: e.placa,
            completed: false,
            color: "primary",
            idEmpresa: e.id_divisao,
          });
          this.listaDePlacas.push({
            name: e.placa,
            completed: false,
            color: "primary",
            idEmpresa: e.id_divisao,
          });
        } else {
          this.task.subtasks.push({
            name: e.placa,
            completed: false,
            color: "primary",
            idEmpresa: e.id_empresa,
          });
          this.listaDePlacas.push({
            name: e.placa,
            completed: false,
            color: "primary",
            idEmpresa: e.id_empresa,
          });
        }
      });
      this.markers = source;

      this.spinner.hide();
    } catch (error) {
      console.error(error);
    }
  }

  public filterCompany() {
    var idEmpresa = this.empresaFilterSelected;
    var empresas = [];

    if (idEmpresa == "Divisão") {
      this.empresaSelected = "Divisão";
      this.company.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Matriz") {
      this.empresaSelected = "Matriz";
      this.company.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Filial") {
      this.empresaSelected = "Filial";
      this.company.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    console.log(this.companiesFilter);
  }
}
