import {
    AbstractControl,
    FormBuilder, FormControl,
    FormGroup,
    FormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import storageX from '../../storageCore/storageX';
import {STORAGE_KEY_CURRENT_USER, STORAGE_KEY_ID_PERFIL} from '../../storageCore/constStorageKeys';
import {Company} from '../../models/Company.model';
import {ApiBaseService} from '../../services/migrate/api.base.service';
import axios from 'axios';
import {TransporterService} from '../../services/transporter.service';
import {PerfilEmpresa} from '../../models/PerfilEmpresa.model';
import {VehiclesMenuService} from '../../services/vehicles-menu.service';
import {MatSelectChange} from '@angular/material/select';
import ApiService from '../../services/api.service';
import {toCamel} from 'snake-camel';
import {User} from '../../models/User.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {Transporter} from '../../models/transporter.model';
import {PerfilUsuario} from '../../models/PerfilUsuario.model';
import {Router} from '@angular/router';

export class TransporterCore {

    constructor(private formBuilder: FormBuilder,
                private baseService: ApiBaseService,
                private vehicleService: VehiclesMenuService,
                private apiService: ApiService,
                private apiTransporterService: TransporterService,
                protected spinner: NgxSpinnerService,
                protected router: Router) {
        this.apiBaseService = baseService;
        this.service = apiService;
        this.vehicleMenuService = vehicleService;
        this.transporterService = apiTransporterService;
    }
    public companies: Array<any>;
    public transporterFormGroup: FormGroup;
    public userFormGroup: FormGroup;
    protected apiBaseService: ApiBaseService;
    protected vehicleMenuService: VehiclesMenuService;
    protected service: ApiService;
    protected transporterService: TransporterService;
    public errorMessage;
    public transporterProfilesOfCompany: Array<any>;
    public vehiclesOfProfile: Array<any>;
    public companiesFiltered: Array<any>;
    public transporter: Transporter;
    public userOfTransporter: User;
    public empresaSelected: any;
    public userProfiles;

    // TODO - Criar classe utilitária para centralizar os métodos de validação
    public static cpfValidator(control: FormControl) {
        console.log('Entrou na função de validação');
        const cpf = control.value;
        if (cpf) {
            let numbers, digits, sum, i, result, equalDigits;
            equalDigits = 1;
            if (cpf.length < 11) {
                return {cpfNotValid: true};
            }

            for (i = 0; i < cpf.length - 1; i++) {
                if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
                    equalDigits = 0;
                    break;
                }
            }

            if (!equalDigits) {
                numbers = cpf.substring(0, 9);
                digits = cpf.substring(9);
                sum = 0;
                for (i = 10; i > 1; i--) {
                    sum += numbers.charAt(10 - i) * i;
                }

                result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

                if (result !== Number(digits.charAt(0))) {
                    return {cpfNotValid: true};
                }
                numbers = cpf.substring(0, 10);
                sum = 0;

                for (i = 11; i > 1; i--) {
                    sum += numbers.charAt(11 - i) * i;
                }
                result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

                if (result !== Number(digits.charAt(1))) {
                    return {cpfNotValid: true};
                }
                return null;
            } else {
                return {cpfNotValid: true};
            }
        }
        return null;
    }

    public loadFormDefinition(): FormGroup {
        return this.formBuilder.group(
            {
                registrationType: ['', Validators.required],
                company: ['', Validators.required],
                name: ['', Validators.required],
                cpf: new FormControl('', [Validators.required, TransporterCore.cpfValidator]),
                registration: ['', Validators.required],
                birthDate: ['', Validators.required],
                phone: ['', Validators.required],
                email: ['', Validators.required],
                postalCode: ['', Validators.required],
                district: ['', Validators.required],
                city: ['', Validators.required],
                street: ['', Validators.required],
                number: ['', Validators.required],
                complement: ['', Validators.required],
                reference: [''],
                state: ['', Validators.required],
                country: ['', Validators.required]
            },
            {updateOn: 'blur'}
        );
    }

    public loadUserFormDefinition(): FormGroup {
        return this.formBuilder.group(
            {
                profile: ['', Validators.required],
                login: ['', Validators.required],
                userName: ['', Validators.required],
                email: ['', Validators.email],
                matriculation: ['', Validators.required],
                password: ['', Validators.required],
                vehicles: ['', Validators.min(1)],
            },
            {updateOn: 'blur'}
        );
    }

    public getRegistrationType(): any {
        return [
            { name: 'Divisão', value: 1 },
            { name: 'Matriz', value: 2 },
            { name: 'Filial', value: 3 },
        ];
    }

    protected loadTransporter(transporterUuid: string, toClone: boolean): void {
        this.spinner.show();
        this.transporterService.getByUuid(transporterUuid).subscribe((transporter) => {
            this.transporter = toCamel(transporter);
            const response = this.apiBaseService.get<User>({
                url: new User().nomeConsultaApiBase + '/' + this.transporter.userId,
            }).then((u) => {
                this.userOfTransporter = u;
                this.getUserProfiles(u.id).then((res) => {
                        this.userProfiles = res.content;
                    }
                );
                this.fillTransporterData();
                this.loadCompanies().then(() => {
                    this.fillSelectedCompany();
                    if (this.empresaSelected.idEmpresaMatriz === undefined && this.empresaSelected.cnpj !== undefined){
                        // Matriz
                        this.getProfilesOfCompanyOrDivision('Matriz', this.empresaSelected.id).then(() => {
                            this.fillSelectedProfile();
                        });
                    }

                    if (this.empresaSelected.idEmpresaMatriz !== undefined){
                        // Matriz
                        this.getProfilesOfCompanyOrDivision('Filial', this.empresaSelected.id).then(() => {
                            this.fillSelectedProfile();
                        });
                    }

                    if (this.empresaSelected.idEmpresaMatriz === undefined && this.empresaSelected.cnpj === undefined){
                        // Divisão
                        this.getProfilesOfCompanyOrDivision('Divisão', this.empresaSelected.id).then(() => {
                            this.fillSelectedProfile();
                        });
                    }

                });
                if (toClone){
                    this.cleanFormToClone();
                }

                // this.fillSelectedProfile();

                this.spinner.hide();
            });
        }, (error) => {
            this.spinner.hide();
            throw new EvalError(error);
        });


    }
    public async loadCompanies(): Promise<void> {
        const params = {
            size: 999999,
            page: 0,
            profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
        };

        const result = await this.apiBaseService.get<any>({
            url: new Company().nomeConsultaApiBaseGetAll,
            params,
        });

        this.companies = result.content;

    }

    protected async getProfilesOfCompanyOrDivision(registrationType: any, companyId: any) {
        let params: any;
        if (registrationType === 'Divisão') {
            params = {
                divisaoId: companyId
            };

        } else {
            params = {
                empresaId: companyId
            };
        }


        const result = await this.apiBaseService.get<any>({
            url: new PerfilEmpresa().listConsultaTransporter,
            params
        });

        this.transporterProfilesOfCompany = result.map((f) => f.perfil);
    }
    protected loadVehicles(profileId: number, vehiclesSelected: Array<number>): void {
        this.vehicleMenuService.getVehiclesToSelectByProfileAndSearch(profileId, '').subscribe(
            (vehicles) => {
                this.vehiclesOfProfile = vehicles;
                if (vehiclesSelected !== null && vehiclesSelected.length > 0) {
                    //Seta os veiculos selecionados no fromulario
                    this.userFormGroup.get('vehicles').setValue(
                        this.vehiclesOfProfile.filter(vp => (
                           vehiclesSelected.includes(vp.id_veiculo)
                )));
                }
            }, (error) => {
                console.log('Houve um erro ao carregar os veiculos do perfil' + error);
            }
        );
    }

    numberOnly(event) {
        console.log('função keyup');
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }

    async searchCep(): Promise<void> {
        const { postalCode } = this.transporterFormGroup.value;
        const postalCodeClean = postalCode.replace(/\D/g, "");

        if (postalCodeClean !== "") {
            const cepValidatorRules = /^[0-9]{8}$/;
            if (cepValidatorRules.test(postalCodeClean)) {
                const url = `https://viacep.com.br/ws/${postalCodeClean}/json`;
                try {
                    this.spinner.show();
                    const { data } = await axios.get(url);
                    const { logradouro, complemento, bairro, localidade, uf } = data;

                    this.transporterFormGroup.controls["street"].setValue(logradouro);
                    this.transporterFormGroup.controls["complement"].setValue(complemento);
                    this.transporterFormGroup.controls["district"].setValue(bairro);
                    this.transporterFormGroup.controls["city"].setValue(localidade);
                    this.transporterFormGroup.controls["state"].setValue(uf);
                    this.transporterFormGroup.controls["country"].setValue("BRASIL");
                    this.spinner.hide();
                } catch (error) {
                    this.spinner.hide();
                    throw new EvalError(error);
                }
            }
        }
    }

    filterCompany(event: any): void {
        {
            console.log(event);
            const registrationType = event.value;
            const companies = [];

            if (registrationType === 'Divisão') {
                this.companies.forEach((element) => {
                    if (element.cnpj == null) {
                        companies.push(element);
                    }
                });
            }

            if (registrationType === 'Matriz') {
                this.companies.forEach((element) => {
                    if (element.cnpj != null && element.idEmpresaMatriz == null) {
                        companies.push(element);
                    }
                });
            }

            if (registrationType === 'Filial') {
                this.companies.forEach((element) => {
                    if (element.cnpj != null && element.idEmpresaMatriz != null) {
                        companies.push(element);
                    }
                });
            }

            this.companiesFiltered = companies;
        }

    }

    onChangeCompany(event: MatSelectChange): void {
        const registrationType = this.transporterFormGroup.controls.registrationType.value;
        const companyId = event.value.id;
        this.getProfilesOfCompanyOrDivision(registrationType, companyId);
    }

    onChangeProfile(event: MatSelectChange): void {
        const profileId = event.value.id;
        this.loadVehicles(profileId, null);
    }

    getUserProfiles(userId: number): Promise<any> {
        const url = 'usuario-perfil';
        return this.service.get<any>({
            url,
            params: {
                usuario: userId,
            },
        });
    }

    private fillTransporterData(): void {
        this.transporterFormGroup.get('name').setValue(this.transporter.name);
        this.transporterFormGroup.get('cpf').setValue(this.transporter.cpf);
        this.transporterFormGroup.get('registration').setValue(this.transporter.registration);
        this.transporterFormGroup.get('birthDate').setValue(this.transporter.birthDate);
        this.transporterFormGroup.get('phone').setValue(this.transporter.phone);
        this.transporterFormGroup.get('postalCode').setValue(this.transporter.postalCode);
        this.transporterFormGroup.get('district').setValue(this.transporter.district);
        this.transporterFormGroup.get('city').setValue(this.transporter.city);
        this.transporterFormGroup.get('state').setValue(this.transporter.state);
        this.transporterFormGroup.get('country').setValue(this.transporter.country);
        this.transporterFormGroup.get('street').setValue(this.transporter.street);
        this.transporterFormGroup.get('number').setValue(this.transporter.number);
        this.transporterFormGroup.get('complement').setValue(this.transporter.complement);
        this.transporterFormGroup.get('reference').setValue(this.transporter.reference);
        this.transporterFormGroup.get('email').setValue(this.userOfTransporter.dsEmail);
        this.userFormGroup.get('login').setValue(this.userOfTransporter.cdUsuario);
        this.userFormGroup.get('userName').setValue(this.userOfTransporter.nmUsuario);
        this.userFormGroup.get('email').setValue(this.userOfTransporter.dsEmail);
        this.userFormGroup.get('matriculation').setValue(this.userOfTransporter.nrMatricula);

    }

    private fillSelectedCompany(): void {
        this.companies.forEach((c) => {
            if (c.id === this.transporter.companyId || c.id === this.transporter.divisionId){
                this.empresaSelected = c;
                this.transporterFormGroup.get('company').setValue(c);
                if (c.cnpj === undefined){
                    this.transporterFormGroup.get('registrationType').setValue('Divisão');
                    this.filterCompany({value: 'Divisão'});
                }
                if (c.cnpj !== undefined && c.idEmpresaMatriz !== undefined) {
                    this.transporterFormGroup.get('registrationType').setValue('Filial');
                    this.filterCompany({value: 'Filial'});
                }
                if (c.cnpj !== undefined && c.idEmpresaMatriz === undefined) {
                    this.transporterFormGroup.get('registrationType').setValue('Matriz');
                    this.filterCompany({value: 'Matriz'});
                }
            }
        });
    }

    private fillSelectedProfile(): void {
        this.transporterProfilesOfCompany.forEach((p) => {
            if (this.userProfiles.some(perf => p.id = perf.perfil.id)) {
                this.userFormGroup.get('profile').setValue(p);
                this.loadVehicles(p.id, this.transporter.vehicles);
            }
        });
        return null;

    }

    protected createTransporter(): void {
        this.spinner.show();
        console.log('Criando o usuário para o transporter...');
        const user = {
            cdUsuario: this.userFormGroup.get('login').value,
            nmUsuario: this.userFormGroup.get('userName').value,
            dsEmail: this.userFormGroup.get('email').value,
            nrMatricula: this.userFormGroup.get('matriculation').value,
            cdSenha: this.userFormGroup.get('password').value
        };

        const post = {
            ...user,
            ativo: true,
            idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
        };

        this.apiBaseService.post<User>(
            new User().nomeConsultaApiBase,
            post
        ).then(userCreated => {
            console.log('Usuario criado -> ' + userCreated);
            console.log('Criando o vinculo do usuario com o perfil');
            const perfilUsuario = {
                ativo: true,
                perfil: { id: this.userFormGroup.get('profile').value.id },
                usuario: { id: userCreated.id },
                flLeitura: true,
                flInsercao: true,
                flAtualizacao: true,
                flDelecao: true,
            };
            this.apiBaseService.post<PerfilUsuario>(
                'usuario-perfil',
                perfilUsuario
            ).then(createdProfile => {
                console.log('Profile criado com sucesso' + createdProfile);
                console.log('Criando o transporter...');

                const transporter = new Transporter(this.transporterFormGroup.value);
                transporter.userId = userCreated.id;
                transporter.postalCode = transporter.postalCode.replace('-', '');

                const registrationType = this.transporterFormGroup.controls.registrationType.value;
                if (registrationType === 'Matriz' || registrationType === 'Filial') {
                    transporter.companyId = this.transporterFormGroup.controls.company.value.id;
                }
                if (registrationType === 'Divisão') {
                    transporter.divisionId = this.transporterFormGroup.controls.company.value.id;
                }

                transporter.vehicles = this.userFormGroup.get('vehicles').value.map(vehicle => vehicle.id_veiculo);

                this.transporterService.save(transporter).subscribe((persistedTransporter) => {
                    console.log(persistedTransporter);
                    this.router.navigate(['/transporters']);
                    this.spinner.hide();
                }, (error) => {
                    this.spinner.hide();
                    this.errorMessage = 'Houve um erro ao persistir o transportador' + error.error[0].message;
                    throw new EvalError(this.errorMessage);
                });
            }, reason => {
                console.log(reason);
                this.spinner.hide();
                this.errorMessage = 'Houve um erro ao persistir perfil do usuário. ' + reason.message;
                throw new EvalError(this.errorMessage);
            });

        }, (error) => {
            this.spinner.hide();
            this.errorMessage = 'Houve um erro ao persistir o usuário. ' + error.message;
            throw new EvalError(this.errorMessage);
        });

    }

    private cleanFormToClone(): void {
        this.userFormGroup.get('login').reset();
        this.userFormGroup.get('userName').reset();
        this.userFormGroup.get('matriculation').reset();
    }

}
