import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToolsService } from "../services/migrate/tools.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  public errorMessage;

  constructor(private formBuilder: FormBuilder, public tools: ToolsService) {
    //this.tools.builder().api.oAuth();
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      input: ["", Validators.required],
    });
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  public async findUser() {
    const currencyUrl = window.location.href.replace(
      this.tools.builder().router.url,
      ""
    );

    const params = this.tools.builder().params;

    params.setParam("value", this.forgotPasswordForm.value.input);
    params.setParam("urlredirect", `${currencyUrl}/validate-code?token=`);

    this.tools.builder().spinner.show();

    this.tools
      .builder()
      .api.put(
        `${
          this.tools.builder().api.getApiUrl().notify
        }/users/recovers-passwords`,
        null,
        params.build()
      )
      .subscribe(() => {
        this.tools.builder().spinner.hide();

        Swal.fire({
          icon: "success",
          title: "Tudo certo",
          html: `<h6>'Foi enviado para o seu e-mail um código para que você possa recuperar o seu acesso!'</h6> <br>
                     <b>${this.forgotPasswordForm.value.input}</b>`,
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
        }).then(() => {
          this.tools.builder().router.navigate(["/login"]);
        });
      });

    /*     this.submitted = true;

    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.loading = true; */

    /*    try {
     this.gerarCode();
      this.me(this.f.username.value);

      this.router.navigate([this.returnUrl]);
    } catch (exception) {
      this.errorMessage = exception.message;
      console.error(exception);
      this.loading = false;
    } */
  }
  /*   async me(username: string): Promise<User> {
    const params = {
      cdUsuario: username,
    };

    try {
      const result = await this.service.get<User[]>({
        url: 'usuario/full/query',
        params,
      });

      const [user] = result;

      if (!user) {
        Promise.reject(Error('Invalid user'));
      }

      return user;
    } catch (error) {
      console.error('me', error);
      return null;
    }
  }
  public async gerarCode() {
    try {
      const url = 'http://localhost:4200';
      const post = {
        client_id: environment.clientIdSensedia,
        redirect_uri: url,
      };

      const response = await this.httpOAuth.request<any>({
        method: 'post',
        url: 'oauth/grant-code',
        data: post,
      });

      var strUrl = response.data.redirect_uri;
      var result = strUrl.replace(url + '/?code=', '');
      var access_token = this.gerarToken(result);
      return result;
    } catch (error) {
      return '';
    }
  }

  public async gerarToken(code) {
    try {
      const response = await this.httpOAuthToken.request<any>({
        method: 'post',
        url: 'oauth/access-token?grant_type=authorization_code&code=' + code,
        data: '{ }',
      });

      storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN).set( response.data.access_token ); //localStorage.setItem('access_token', response.data.access_token);
    } catch (error) {
      return '';
    }
  } */
}
