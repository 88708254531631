<div class="main-page cadastros journey-main">
    <div class="container-fluid"> 
        <div class="row align-items-center">
            <div class="col-12 my-2">
              <h1>Relatório Envio de Comandos</h1>
            </div>
        </div>            
        <p-menubar #menu [model]="items"></p-menubar>
        <mat-horizontal-stepper [linear]="true" #stepper>
            <mat-step>
                <p-table #journeyheader [value]="this.resultado" tableStyleClass="table">    
                    <ng-template pTemplate="header" let-columns>            
                        <tr [hidden]="this.resultado.length == 0">
                            <th style="text-align:center !important;border:none">Placa</th>
                            <th style="text-align:center !important;border:none">Usuário</th>
                            <th style="text-align:center !important;border:none">Comando</th>
                            <th style="text-align:center !important;border:none">Texto Envio</th>
                            <th style="text-align:center !important;border:none">Status</th>
                            <th style="text-align:center !important;border:none">Data Adição</th>                    
                            <th style="text-align:center !important;border:none">Data Confirmação</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowdata>          
                        <tr>
                            <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["terminal"].veiculo.cdPlaca}}</td>
                            <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["nomeUsuario"]}}</td>
                            <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["terminalComando"].nmNome}}</td>
                            <td style="text-align:center !important;border:none; overflow-wrap: break-word;" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["dsTextoEnvio"]}}</td>                            
                            <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["terminalComandoStatus"].dsDescricao}}</td>
                            <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{this.convertTimeStamp(rowdata["dtAdicao"])}}</td>
                            <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["terminalComandoStatus"].id == 1?this.convertTimeStamp(rowdata["dtConfirmacao"]):""}}</td>                                    
                        </tr>                 
                    </ng-template>
                </p-table>                          
            </mat-step>     
        </mat-horizontal-stepper>
    </div>
</div>

<p-sidebar [(visible)]="this.filterDialog" position="right">
    <div style="margin-bottom: 20px;">
        <span>Filtros</span> 
    </div>
    <form [formGroup]="this.consultaFormGroup">
        <div class="form-row">
            <div class="form-group col-lg-12">
            <mat-form-field appearance="outline">
                <mat-label>Data Inicío</mat-label>
                <input formControlName="dataInicio" type="datetime-local" matInput placeholder="Inicio" required />
            </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-lg-12">
            <mat-form-field appearance="outline">
                <mat-label>Data Fim</mat-label>
                <input formControlName="dataFim" type="datetime-local" matInput placeholder="Fim" required />
            </mat-form-field>
            </div>
        </div>    

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Empresa</mat-label>
                    <input type="text" matInput [formControl]="controlEmpresa" [matAutocomplete]="autoEmpresa">                     
                    <mat-autocomplete #autoEmpresa="matAutocomplete" [displayWith]="displayEmpresa">
                         <mat-option *ngFor="let type of this.companysFiltered | async" [value]="type">
                            {{type.nmNome}}
                        </mat-option>
                    </mat-autocomplete>                       
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Placa / Frota</mat-label >
                    <input type="text" matInput [formControl]="controlVeiculo" [matAutocomplete]="autoTerminal">                     
                    <mat-autocomplete #autoTerminal="matAutocomplete" [displayWith]="displayTerminal">
                         <mat-option *ngFor="let type of this.vehiclesFiltered | async" [value]="type">
                            {{type.cdPlaca}}/{{type.frota}}
                        </mat-option>
                    </mat-autocomplete>                       
                </mat-form-field>            
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Usuário</mat-label>
                    <input type="text" matInput [formControl]="controlUsuario" [matAutocomplete]="autoUsuario">                     
                    <mat-autocomplete #autoUsuario="matAutocomplete" [displayWith]="displayUsuario">
                         <mat-option *ngFor="let type of this.usersFiltered | async" [value]="type">
                            {{type.cdUsuario}}
                        </mat-option>
                    </mat-autocomplete>                       
                </mat-form-field>            
            </div>
        </div>  

        <div class="form-row">
            <div class="form-group col-md-8">                                
                <button type="submit" (click)="onConsultar()">
                    Consultar
                </button>                
            </div>
        </div>       
    </form>  
</p-sidebar>