export class VwSensor {
  nomeConsultaApiBaseSensor = "sensor/vehicle/";
  id: number;
  codigoSensor: string;
  perfilId: number;
  nomeEmpresa: string;
  nomeDivisao: string;
  placa: string;
  idEmpresa: number;
  idDivisao: number;
}
