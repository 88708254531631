export const FIELDS = [
  'idEmpresa',
  'idTerminalIcone',
  'idVeiculo',
  'numeroTerminal',
  'sim',
  'imei',
  'voltagem',
  'observacao',
  'conectado',
  'instalado',
  'nomeIn1',
  'nomeIn2',
  'nomeIn3',
  'nomeIn4',
  'nomeIn5',
  'nomeIn6',
  'nomeIn7',
  'nomeIn8',
  'nomeIn9',
  'nomeIn10',
  'nomeOut1',
  'nomeOut2',
  'nomeOut3',
  'nomeOut4',
  'nomeOut5',
  'nomeOut6',
  'minutoAlarmeParada',
  'minutoAlarmePontoMorto',
  'fusoHorarioEnum',
  'enumTipoVelocidade',
  'possuiTeclado',
  'possuiTpms',
  'possuiSensorFadiga',
  'limiteExcessoVeloc',
  'limiteExcessoVelocChuva',
  'limiteExcessoRpm',
  'fatorConversaoVelocidadeCan',
  'calcularHorimetroParado',
];

export const NO_SELECTED_COMPANY = {
  id: 0.1,
  placa: 'Nenhum veículo encontrado',
};

export const VHEICLE_WITHOUT_TERMINAL_NOT_FOUND_MSG =
  'Nessa empresa não há veículos disponíveis para adicionar um terminal. <br> Todos os veículos já possuem terminais ou não há veículos registrados!';
