<!-- <button class="close" mat-button (click)="close(null)">X</button> -->

<div *ngIf="loader" style="justify-items: center; width: 100%;">
    <mat-spinner value="10" style="margin: 0 auto;" class="loader"></mat-spinner>
</div>

<div *ngIf="!loader">
    <h2 mat-dialog-title>Tipos Disponiveis de Usuário</h2>
    <div id="perfil-selector">
        <div class="container-fluid overContainer">
            <div class="d-flex">
                <mat-selection-list [multiple]="false">
                    <mat-list-option
                        (click)="onPerfilClick(perfil)"
                        *ngFor="let perfil of perfils">
                        <mat-icon mat-list-icon>folder_shared</mat-icon>
                        <div mat-line>{{ perfil }}</div>
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
    </div>
</div>
