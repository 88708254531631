import { Component, ViewChild, OnInit } from '@angular/core';
import {
  MatButtonToggleChange,
  MatButtonToggle,
} from '@angular/material/button-toggle';
import { MapToolboxService } from './../../../../services/map.toolbox.service';

@Component({
  selector: 'toolbox-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss'],
})
export class ButtonZoomComponent implements OnInit {
  @ViewChild(MatButtonToggle) btnZoomGroup: MatButtonToggle;

  public action: string;
  private service: MapToolboxService;

  constructor(service: MapToolboxService) {
    this.service = service;
  }
  ngOnInit(): void {
    this.service.currentToolboxCommand.subscribe((action) => {
      this.action = action;
    });
  }

  toggleView(change: MatButtonToggleChange): void {
    const { value, source } = change;
    this.btnZoomGroup = source;
    this.action = value;
    this.service.setToolboxCommand(value);
  }
}
