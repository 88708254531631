import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-axle-five-simple-view",
  templateUrl: "./axle-five-simple-view.component.html",
  styleUrls: ["./axle-five-simple-view.component.scss"],
})
export class AxleFiveSimpleViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorFiveLeftSelect: any = [];
  public sensorFiveRightSelect: any = [];

  public sensorFiveRightCombo: any = [];
  public sensorFiveLeftCombo: any = [];

  public axisFiveSimpleLeftForm: FormGroup;
  public axisFiveSimpleRightForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisFiveSimpleLeftForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisFiveSimpleRightForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisFiveSimpleLeftForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 5 ESQ"
      );
      this.axisFiveSimpleRightForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 5 DIR"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo5Direito;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 44) {
              eixo5Direito = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 44) {
              eixo5Direito = filter;
            }
          }
        });

        var eixo5Esquerdo;

        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 43) {
              eixo5Esquerdo = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 43) {
              eixo5Esquerdo = filter;
            }
          }
        });

        // EIXO 5 DIREITO
        if (eixo5Direito != null) {
          this.axisFiveSimpleRightForm.controls["id"].setValue(eixo5Direito.id);

          if (eixo5Direito.sensor != null) {
            this.sensorFiveRightCombo.push(eixo5Direito.sensor);
            this.sensorFiveRightSelect = eixo5Direito.sensor.id;
          }

          if (eixo5Direito.pneu != null) {
            this.axisFiveSimpleRightForm.controls["pneu"].setValue(
              eixo5Direito.pneu.numeroFogo
            );
          }

          this.axisFiveSimpleRightForm.controls["nrPressaoMinima"].setValue(
            eixo5Direito.nrPressaoMinima
          );

          this.axisFiveSimpleRightForm.controls["nrPressaoMaxima"].setValue(
            eixo5Direito.nrPressaoMaxima
          );

          this.axisFiveSimpleRightForm.controls["nrTemperaturaMaxima"].setValue(
            eixo5Direito.nrTemperaturaMaxima
          );
        }

        // EIXO 5 ESQUERDO
        if (eixo5Esquerdo != null) {
          this.axisFiveSimpleLeftForm.controls["id"].setValue(eixo5Esquerdo.id);

          if (eixo5Esquerdo.sensor != null) {
            this.sensorFiveLeftCombo.push(eixo5Esquerdo.sensor);
            this.sensorFiveLeftSelect = eixo5Esquerdo.sensor.id;
          }

          if (eixo5Esquerdo.pneu != null) {
            this.axisFiveSimpleLeftForm.controls["pneu"].setValue(
              eixo5Esquerdo.pneu.numeroFogo
            );
          }

          this.axisFiveSimpleLeftForm.controls["nrPressaoMinima"].setValue(
            eixo5Esquerdo.nrPressaoMinima
          );

          this.axisFiveSimpleLeftForm.controls["nrPressaoMaxima"].setValue(
            eixo5Esquerdo.nrPressaoMaxima
          );

          this.axisFiveSimpleLeftForm.controls["nrTemperaturaMaxima"].setValue(
            eixo5Esquerdo.nrTemperaturaMaxima
          );
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
