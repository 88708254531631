import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { ReportEngineService } from "../../../services/report-engine.service";
import { CoreService } from "src/app/services/migrate/api.core.service";
import { ReportEngineModel } from "src/app/models/report-engine-model";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { ReportEngineModelPaginated } from "src/app/models/report-engine-model-paginated";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";

import Swal from "sweetalert2";
import { MinioService } from "src/app/services/minio.service";

@Component({
  selector: "app-report-async-detail",
  templateUrl: "./report-async-detail.component.html",
  styleUrls: ["./report-async-detail.component.scss"],
})
export class ReportAsyncDetailComponent implements OnInit {
  public reportEngineService: ReportEngineService;
  public minioService: MinioService;
  public serviceCore: CoreService;
  public datePipe: DatePipe;

  public reportTypeEngineModelsPaginated: ReportEngineModelPaginated;
  public reportEngineModel: ReportEngineModel;
  public errorMessage;
  public reportsPage: ReportEngineModelPaginated;
  public reports: MatTableDataSource<ReportEngineModel>;
  public tableColumns: string[];
  public actualPage: PageEvent;
  public permissions: [];
  public filter = "";

  constructor(
    datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    reportEngineService: ReportEngineService,
    apiserviceCore: CoreService,
    minioService: MinioService
  ) {
    this.serviceCore = apiserviceCore;
    this.reportEngineService = reportEngineService;
    this.minioService = minioService;
    this.datePipe = datePipe;
  }

  ngOnInit(): void {
    this.initialPage();
    this.loadReports(this.actualPage, null);
    this.loadTableDefinitions();
  }

  private loadReports(page: PageEvent, name: string): void {
    this.spinner.show();

    var requestOperator: string = storageX
      .whereKeyIs(STORAGE_KEY_CURRENT_USER)
      .get()?.id;

    this.reportEngineService
      .getAllReport(page, requestOperator, name)
      .subscribe(
        (t) => {
          this.reportsPage = t;
          this.reports = new MatTableDataSource<ReportEngineModel>(
            this.reportsPage.content
          );
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter os relatorios");
          this.spinner.hide();
        }
      );
  }

  applyFilter(): void {
    this.loadReports(this.actualPage, this.filter);
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "dateStart",
      "dateEnd",
      "statusReportsEnum",
      "reportName",
      "plate",
      "driverName",
      "filePdf",
      "fileXlxs",
      "view",
      "update",
      "delete",
    ];
  }

  public async handleDelete(element): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Excluir Relatorio",
      html: `<h6>Tem certeza que você deseja excluir esse relatorio? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.deleteReports(element);
      }
    });
  }

  deleteReports(element): void {
    this.spinner.show();
    this.reportEngineService.deleteReport(element.uuid).subscribe(
      (v) => {
        console.log("Exclusão realizada com sucesso");
        this.loadReports(this.actualPage, this.filter);
        this.spinner.hide();
      },
      (e) => {
        console.log("Erro ao excluir o transportador");
        this.spinner.hide();
        this.errorMessage = e.message;
        throw new EvalError(e);
      }
    );
  }

  exportReport(element): void {
    this.minioService.exportReport(element).subscribe();
  }

  exportReportJson(element): void {
    this.reportEngineService.exportJsonReport(element).subscribe((response) => {
      const linkMinio = response.link_minio;
      if (linkMinio) {
        window.open(linkMinio, "_blank");
      } else {
        console.error("Link MinIO não fornecido na resposta.");
      }
    });
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage, this.filter);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }

  formatDate(dateString: string): string {
    // Converte a string de data para um objeto Date
    const date = new Date(dateString);

    // Formata a data usando o DatePipe
    return this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
  }

  getStatusStyle(status: string): { [key: string]: string } {
    switch (status) {
      case "ERROR":
        return { color: "red" };
      case "FINISHED":
        return { color: "green" };
      case "PROCESSING":
        return { color: "blue" };
      default:
        return {}; // Estilo padrão, se o status não corresponder a nenhum caso
    }
  }
}
