import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { TypeProfile } from "../../models/TypeProfile.model";
import { Validation } from "./validation";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-create-perfil-tipo",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ViewPerfilTipoComponent implements OnInit {
  public perfilTipoForm: FormGroup;
  public perfilTipo: TypeProfile;
  public perfilTipoId: number;
  public title = "Visualizar Perfil Tipo";
  public errorMessage;
  private apiBaseService: ApiBaseService;
  validation: Validation;

  ngOnInit(): void {
    this.perfilTipoForm = this.formBuilder.group(this.validation.rules());
    this.perfilTipoId = this.activatedRoute.snapshot.params["id"];
    this.loadPerfilTipo(this.perfilTipoId);
    //this.setup();
  }
  public async loadPerfilTipo(id): Promise<void> {
    try {
      const response = await this.apiBaseService.get<TypeProfile>({
        url: new TypeProfile()["nomeConsultaApiBase"] + "/" + id,
      });
      this.perfilTipo = response as TypeProfile;
      this.fillUserData();
    } catch (error) {
      console.error(error);
    }
  }
  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }

  fillUserData(): void {
    const fields = ["cdPerfilTipo", "nmPerfilTipo"];
    fields.forEach((element) => {
      this.perfilTipoForm.controls[element].setValue(this.perfilTipo[element]);
    });
  }
}
