import { Component, OnInit } from "@angular/core";
import { MapToolboxService } from "./../../../../services/map.toolbox.service";
import { MapButtonService } from "./../../../../services/map.buttom.service";
import { PermissionsService } from "src/app/helpers/permissions.service";

@Component({
  selector: "toolbox-button-path",
  templateUrl: "./button-path.component.html",
  styleUrls: ["./button-path.component.scss"],
})
export class ButtonPathComponent implements OnInit {
  selected = false;
  disabled = true;
  permissions: [];

  private service: MapToolboxService;
  private mapButtonService: MapButtonService;

  constructor(
    service: MapToolboxService,
    mapButtonService: MapButtonService,
    public permissionService: PermissionsService
  ) {
    this.service = service;
    this.mapButtonService = mapButtonService;
  }

  ngOnInit(): void {
    this.permissions = this.permissionService.getPermissions();
    this.selected = false;
    this.disabled = true;
    this.mapButtonService.currentPathButton.subscribe((action) => {
      if (action && action !== this.selected) {
        this.selected = action;
      }
    });
    this.mapButtonService.currentPathButtonDisabled.subscribe((action) => {
      this.disabled = action;
    });
  }

  onChangeValue(): void {
    if (this.disabled) {
      return;
    }
    const value = !this.selected;
    this.selected = value;
    this.mapButtonService.setPathButton(value);
    if (!value) {
      this.disabled = true;
    }
    this.service.setToolboxCommand(value ? "button_path" : "button_path_reset");
  }
}
