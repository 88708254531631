import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Pneu } from "src/app/models/Pneu.model";
import { Vehicle } from "src/app/models/Vehicle.model";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-pneu-posicao-view",
  templateUrl: "./pneu-posicao-view.component.html",
  styleUrls: ["./pneu-posicao-view.component.scss"],
})
export class PneuPosicaoViewComponent implements OnInit {
  public pneuPosicaoForm: FormGroup;

  @Input() pneu: Pneu;
  @Input() pneuId: number;

  public title = "Cadastrar Pneu";
  public errorMessage;
  private apiBaseService: ApiBaseService;
  public tirePositionFilterSelected;

  public vehicleSelected;
  public vehiclesTireSelected;

  public vehicles: any = [];
  public vehiclesTire: any = [];

  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.apiBaseService = apiBaseService;
  }

  public namePositionSelect = [];

  ngOnInit(): void {
    this.pneuPosicaoForm = this.formBuilder.group(
      {
        veiculo: ["", Validators.required],
        posicaoPneu: ["", Validators.required],
      },
      { updateOn: "blur" }
    );

    this.setup();
  }

  async setup(): Promise<void> {
    try {
      this.spinner.show();
      var params;

      if (this.pneu.divisao != null) {
        params = {
          idCompanyOrDivision: this.pneu.divisao.id,
          cnpj: null,
        };
      } else {
        params = {
          idCompanyOrDivision: this.pneu.empresa.id,
          cnpj: this.pneu.empresa.cnpj,
        };
      }

      const result = await this.apiBaseService.get<any>({
        url: new Vehicle()["nomeConsultaApiBaseGetCompanyOrDivision"],
        params,
      });

      if (result == "") {
        this.handleEmpty();
      }

      this.vehicles = result;

      if (this.pneu.veiculo != null) {
        this.pneuPosicaoForm.controls["veiculo"].setValue(this.pneu.veiculo.id);
        this.filterVehicleInit(this.pneu);
        this.vehiclesTireSelected = this.pneu.posicaoPneu.id;
      }

      this.spinner.hide();
    } catch (error) {
      console.error(error);
    }
  }

  public async handleEmpty(): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Empresa sem veiculos com eixo",
      html: `<h6>Essa empresa não contém veiculos com eixo definido
       <small>!</h6>`,
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public filterVehicleInit(pneu: Pneu) {
    var veiculo = this.vehicles.filter(
      (client) => client.id == this.pneu.veiculo.id
    );

    if (veiculo[0].nrQuantidadeEixo == 1) {
      if (veiculo[0].tipoRodagem != "S") {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 3, nome: "EIXO 1 ESQ EXT" },
          { id: 4, nome: "EIXO 1 DIR EXT" },
          { id: 19, nome: "EIXO 1 ESQ INT" },
          { id: 20, nome: "EIXO 1 DIR INT" },
        ];
      } else {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 35, nome: "EIXO 1 ESQ" },
          { id: 36, nome: "EIXO 1 DIR" },
        ];
      }
    }

    if (veiculo[0].nrQuantidadeEixo == 2) {
      if (veiculo[0].tipoRodagem != "S") {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 3, nome: "EIXO 1 ESQ EXT" },
          { id: 4, nome: "EIXO 1 DIR EXT" },
          { id: 19, nome: "EIXO 1 ESQ INT" },
          { id: 20, nome: "EIXO 1 DIR INT" },
          { id: 5, nome: "EIXO 2 ESQ EXT" },
          { id: 6, nome: "EIXO 2 DIR EXT" },
          { id: 21, nome: "EIXO 2 ESQ INT" },
          { id: 22, nome: "EIXO 2 DIR INT" },
        ];
      } else {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 35, nome: "EIXO 1 ESQ" },
          { id: 36, nome: "EIXO 1 DIR" },
          { id: 37, nome: "EIXO 2 ESQ" },
          { id: 38, nome: "EIXO 2 DIR" },
        ];
      }
    }

    if (veiculo[0].nrQuantidadeEixo == 3) {
      if (veiculo[0].tipoRodagem != "S") {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 3, nome: "EIXO 1 ESQ EXT" },
          { id: 4, nome: "EIXO 1 DIR EXT" },
          { id: 19, nome: "EIXO 1 ESQ INT" },
          { id: 20, nome: "EIXO 1 DIR INT" },
          { id: 5, nome: "EIXO 2 ESQ EXT" },
          { id: 6, nome: "EIXO 2 DIR EXT" },
          { id: 21, nome: "EIXO 2 ESQ INT" },
          { id: 22, nome: "EIXO 2 DIR INT" },
          { id: 7, nome: "EIXO 3 ESQ EXT" },
          { id: 8, nome: "EIXO 3 DIR EXT" },
          { id: 23, nome: "EIXO 3 ESQ INT" },
          { id: 24, nome: "EIXO 3 DIR INT" },
        ];
      } else {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 35, nome: "EIXO 1 ESQ" },
          { id: 36, nome: "EIXO 1 DIR" },
          { id: 37, nome: "EIXO 2 ESQ" },
          { id: 38, nome: "EIXO 2 DIR" },
          { id: 39, nome: "EIXO 3 ESQ" },
          { id: 40, nome: "EIXO 3 DIR" },
        ];
      }
    }

    if (veiculo[0].nrQuantidadeEixo == 4) {
      if (veiculo[0].tipoRodagem != "S") {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 3, nome: "EIXO 1 ESQ EXT" },
          { id: 4, nome: "EIXO 1 DIR EXT" },
          { id: 19, nome: "EIXO 1 ESQ INT" },
          { id: 20, nome: "EIXO 1 DIR INT" },
          { id: 5, nome: "EIXO 2 ESQ EXT" },
          { id: 6, nome: "EIXO 2 DIR EXT" },
          { id: 21, nome: "EIXO 2 ESQ INT" },
          { id: 22, nome: "EIXO 2 DIR INT" },
          { id: 7, nome: "EIXO 3 ESQ EXT" },
          { id: 8, nome: "EIXO 3 DIR EXT" },
          { id: 23, nome: "EIXO 3 ESQ INT" },
          { id: 24, nome: "EIXO 3 DIR INT" },
          { id: 9, nome: "EIXO 4 ESQ EXT" },
          { id: 10, nome: "EIXO 4 DIR EXT" },
          { id: 25, nome: "EIXO 4 ESQ INT" },
          { id: 26, nome: "EIXO 4 DIR INT" },
        ];
      } else {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 35, nome: "EIXO 1 ESQ" },
          { id: 36, nome: "EIXO 1 DIR" },
          { id: 37, nome: "EIXO 2 ESQ" },
          { id: 38, nome: "EIXO 2 DIR" },
          { id: 39, nome: "EIXO 3 ESQ" },
          { id: 40, nome: "EIXO 3 DIR" },
          { id: 41, nome: "EIXO 4 ESQ" },
          { id: 42, nome: "EIXO 4 DIR" },
        ];
      }
    }

    if (veiculo[0].nrQuantidadeEixo == 5) {
      if (veiculo[0].tipoRodagem != "S") {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 3, nome: "EIXO 1 ESQ EXT" },
          { id: 4, nome: "EIXO 1 DIR EXT" },
          { id: 19, nome: "EIXO 1 ESQ INT" },
          { id: 20, nome: "EIXO 1 DIR INT" },
          { id: 5, nome: "EIXO 2 ESQ EXT" },
          { id: 6, nome: "EIXO 2 DIR EXT" },
          { id: 21, nome: "EIXO 2 ESQ INT" },
          { id: 22, nome: "EIXO 2 DIR INT" },
          { id: 7, nome: "EIXO 3 ESQ EXT" },
          { id: 8, nome: "EIXO 3 DIR EXT" },
          { id: 23, nome: "EIXO 3 ESQ INT" },
          { id: 24, nome: "EIXO 3 DIR INT" },
          { id: 9, nome: "EIXO 4 ESQ EXT" },
          { id: 10, nome: "EIXO 4 DIR EXT" },
          { id: 25, nome: "EIXO 4 ESQ INT" },
          { id: 26, nome: "EIXO 4 DIR INT" },
          { id: 11, nome: "EIXO 5 ESQ EXT" },
          { id: 12, nome: "EIXO 5 DIR EXT" },
          { id: 27, nome: "EIXO 5 ESQ INT" },
          { id: 28, nome: "EIXO 5 DIR INT" },
        ];
      } else {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 35, nome: "EIXO 1 ESQ" },
          { id: 36, nome: "EIXO 1 DIR" },
          { id: 37, nome: "EIXO 2 ESQ" },
          { id: 38, nome: "EIXO 2 DIR" },
          { id: 39, nome: "EIXO 3 ESQ" },
          { id: 40, nome: "EIXO 3 DIR" },
          { id: 41, nome: "EIXO 4 ESQ" },
          { id: 42, nome: "EIXO 4 DIR" },
          { id: 43, nome: "EIXO 5 ESQ" },
          { id: 44, nome: "EIXO 5 DIR" },
        ];
      }
    }

    if (veiculo[0].nrQuantidadeEixo == 6) {
      if (veiculo[0].tipoRodagem != "S") {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 3, nome: "EIXO 1 ESQ EXT" },
          { id: 4, nome: "EIXO 1 DIR EXT" },
          { id: 19, nome: "EIXO 1 ESQ INT" },
          { id: 20, nome: "EIXO 1 DIR INT" },
          { id: 5, nome: "EIXO 2 ESQ EXT" },
          { id: 6, nome: "EIXO 2 DIR EXT" },
          { id: 21, nome: "EIXO 2 ESQ INT" },
          { id: 22, nome: "EIXO 2 DIR INT" },
          { id: 7, nome: "EIXO 3 ESQ EXT" },
          { id: 8, nome: "EIXO 3 DIR EXT" },
          { id: 23, nome: "EIXO 3 ESQ INT" },
          { id: 24, nome: "EIXO 3 DIR INT" },
          { id: 9, nome: "EIXO 4 ESQ EXT" },
          { id: 10, nome: "EIXO 4 DIR EXT" },
          { id: 25, nome: "EIXO 4 ESQ INT" },
          { id: 26, nome: "EIXO 4 DIR INT" },
          { id: 11, nome: "EIXO 5 ESQ EXT" },
          { id: 12, nome: "EIXO 5 DIR EXT" },
          { id: 27, nome: "EIXO 5 ESQ INT" },
          { id: 28, nome: "EIXO 5 DIR INT" },
          { id: 13, nome: "EIXO 6 ESQ EXT" },
          { id: 14, nome: "EIXO 6 DIR EXT" },
          { id: 29, nome: "EIXO 6 ESQ INT" },
          { id: 30, nome: "EIXO 6 DIR INT" },
        ];
      } else {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 35, nome: "EIXO 1 ESQ" },
          { id: 36, nome: "EIXO 1 DIR" },
          { id: 37, nome: "EIXO 2 ESQ" },
          { id: 38, nome: "EIXO 2 DIR" },
          { id: 39, nome: "EIXO 3 ESQ" },
          { id: 40, nome: "EIXO 3 DIR" },
          { id: 41, nome: "EIXO 4 ESQ" },
          { id: 42, nome: "EIXO 4 DIR" },
          { id: 43, nome: "EIXO 5 ESQ" },
          { id: 44, nome: "EIXO 5 DIR" },
          { id: 45, nome: "EIXO 6 ESQ" },
          { id: 46, nome: "EIXO 6 DIR" },
        ];
      }
    }

    if (veiculo[0].nrQuantidadeEixo == 7) {
      if (veiculo[0].tipoRodagem != "S") {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 3, nome: "EIXO 1 ESQ EXT" },
          { id: 4, nome: "EIXO 1 DIR EXT" },
          { id: 19, nome: "EIXO 1 ESQ INT" },
          { id: 20, nome: "EIXO 1 DIR INT" },
          { id: 5, nome: "EIXO 2 ESQ EXT" },
          { id: 6, nome: "EIXO 2 DIR EXT" },
          { id: 21, nome: "EIXO 2 ESQ INT" },
          { id: 22, nome: "EIXO 2 DIR INT" },
          { id: 7, nome: "EIXO 3 ESQ EXT" },
          { id: 8, nome: "EIXO 3 DIR EXT" },
          { id: 23, nome: "EIXO 3 ESQ INT" },
          { id: 24, nome: "EIXO 3 DIR INT" },
          { id: 9, nome: "EIXO 4 ESQ EXT" },
          { id: 10, nome: "EIXO 4 DIR EXT" },
          { id: 25, nome: "EIXO 4 ESQ INT" },
          { id: 26, nome: "EIXO 4 DIR INT" },
          { id: 11, nome: "EIXO 5 ESQ EXT" },
          { id: 12, nome: "EIXO 5 DIR EXT" },
          { id: 27, nome: "EIXO 5 ESQ INT" },
          { id: 28, nome: "EIXO 5 DIR INT" },
          { id: 13, nome: "EIXO 6 ESQ EXT" },
          { id: 14, nome: "EIXO 6 DIR EXT" },
          { id: 29, nome: "EIXO 6 ESQ INT" },
          { id: 30, nome: "EIXO 6 DIR INT" },
          { id: 15, nome: "EIXO 7 ESQ EXT" },
          { id: 16, nome: "EIXO 7 DIR EXT" },
          { id: 31, nome: "EIXO 7 ESQ INT" },
          { id: 32, nome: "EIXO 7 DIR INT" },
        ];
      } else {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 35, nome: "EIXO 1 ESQ" },
          { id: 36, nome: "EIXO 1 DIR" },
          { id: 37, nome: "EIXO 2 ESQ" },
          { id: 38, nome: "EIXO 2 DIR" },
          { id: 39, nome: "EIXO 3 ESQ" },
          { id: 40, nome: "EIXO 3 DIR" },
          { id: 41, nome: "EIXO 4 ESQ" },
          { id: 42, nome: "EIXO 4 DIR" },
          { id: 43, nome: "EIXO 5 ESQ" },
          { id: 44, nome: "EIXO 5 DIR" },
          { id: 45, nome: "EIXO 6 ESQ" },
          { id: 46, nome: "EIXO 6 DIR" },
          { id: 47, nome: "EIXO 7 ESQ" },
          { id: 48, nome: "EIXO 7 DIR" },
        ];
      }
    }

    if (veiculo[0].nrQuantidadeEixo == 8) {
      if (veiculo[0].tipoRodagem != "S") {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 3, nome: "EIXO 1 ESQ EXT" },
          { id: 4, nome: "EIXO 1 DIR EXT" },
          { id: 19, nome: "EIXO 1 ESQ INT" },
          { id: 20, nome: "EIXO 1 DIR INT" },
          { id: 5, nome: "EIXO 2 ESQ EXT" },
          { id: 6, nome: "EIXO 2 DIR EXT" },
          { id: 21, nome: "EIXO 2 ESQ INT" },
          { id: 22, nome: "EIXO 2 DIR INT" },
          { id: 7, nome: "EIXO 3 ESQ EXT" },
          { id: 8, nome: "EIXO 3 DIR EXT" },
          { id: 23, nome: "EIXO 3 ESQ INT" },
          { id: 24, nome: "EIXO 3 DIR INT" },
          { id: 9, nome: "EIXO 4 ESQ EXT" },
          { id: 10, nome: "EIXO 4 DIR EXT" },
          { id: 25, nome: "EIXO 4 ESQ INT" },
          { id: 26, nome: "EIXO 4 DIR INT" },
          { id: 11, nome: "EIXO 5 ESQ EXT" },
          { id: 12, nome: "EIXO 5 DIR EXT" },
          { id: 27, nome: "EIXO 5 ESQ INT" },
          { id: 28, nome: "EIXO 5 DIR INT" },
          { id: 13, nome: "EIXO 6 ESQ EXT" },
          { id: 14, nome: "EIXO 6 DIR EXT" },
          { id: 29, nome: "EIXO 6 ESQ INT" },
          { id: 30, nome: "EIXO 6 DIR INT" },

          { id: 15, nome: "EIXO 7 ESQ EXT" },
          { id: 16, nome: "EIXO 7 DIR EXT" },
          { id: 31, nome: "EIXO 7 ESQ INT" },
          { id: 32, nome: "EIXO 7 DIR INT" },
          { id: 17, nome: "EIXO 8 ESQ EXT" },
          { id: 18, nome: "EIXO 8 DIR EXT" },
          { id: 33, nome: "EIXO 8 ESQ INT" },
          { id: 34, nome: "EIXO 8 DIR INT" },
        ];
      } else {
        this.vehiclesTire = [
          { id: 1, nome: "DIRECIONAL DIR" },
          { id: 2, nome: "DIRECIONAL ESQ" },
          { id: 51, nome: "ESTEPE 1" },
          { id: 52, nome: "ESTEPE 2" },
          { id: 35, nome: "EIXO 1 ESQ" },
          { id: 36, nome: "EIXO 1 DIR" },
          { id: 37, nome: "EIXO 2 ESQ" },
          { id: 38, nome: "EIXO 2 DIR" },
          { id: 39, nome: "EIXO 3 ESQ" },
          { id: 40, nome: "EIXO 3 DIR" },
          { id: 41, nome: "EIXO 4 ESQ" },
          { id: 42, nome: "EIXO 4 DIR" },
          { id: 43, nome: "EIXO 5 ESQ" },
          { id: 44, nome: "EIXO 5 DIR" },
          { id: 45, nome: "EIXO 6 ESQ" },
          { id: 46, nome: "EIXO 6 DIR" },
          { id: 47, nome: "EIXO 7 ESQ" },
          { id: 48, nome: "EIXO 7 DIR" },
          { id: 49, nome: "EIXO 8 ESQ" },
          { id: 50, nome: "EIXO 8 DIR" },
        ];
      }
    }
  }
}
