import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService } from 'src/app/services/api.service';
import { STORAGE_KEY_CURRENT_USER } from 'src/app/storageCore/constStorageKeys';
import storageX from 'src/app/storageCore/storageX';
import { MapToolboxService } from './../../../../services/map.toolbox.service';

@Component({
  selector: 'app-add-marker',
  templateUrl: './add-marker.component.html',
  styleUrls: ['./add-marker.component.scss'],
})
export class AddMarkerComponent implements OnInit {
  public lat;
  public lng;
  public isLoader = false;
  public markerName;
  private service: ApiService;
  private mapService: MapToolboxService;

  constructor(
    private apiService: ApiService,
    mapService: MapToolboxService,
    public dialogRef: MatDialogRef<AddMarkerComponent>,
    @Inject(MAT_DIALOG_DATA) public coords: any
  ) {
    this.service = apiService;
    this.mapService = mapService;
  }

  ngOnInit(): void {
    const { lat, lng } = this.coords;
    this.lat = lat;
    this.lng = lng;
  }

  async onSubmit(): Promise<void> {
    try {
      this.isLoader = true;
      const user = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get(); //JSON.parse(localStorage.getItem('currentUser'));

      const values = {
        nome: this.markerName,
        latitude: this.lat,
        longitude: this.lng,
      };

      const post = {
        cdLatidude: this.lat,
        cdLongitude: this.lng,
        nmNome: this.markerName,
        usuario: {
          id: user.id,
        },
      };

      const url = 'usuario-marker';
      const { id } = await this.service.post<any>(url, post);
      const data = {
        action: 'addMarkerToMap',
        values: {
          ...values,
          id,
        },
      };
      this.mapService.setCommand(data);
      this.isLoader = false;
      this.dialogRef.close();
    } catch (exception) {
      this.isLoader = false;
      console.error(exception);
    }
  }
}
