import { IhandleStorage } from './iHandleStorage';

export abstract class ManegerStorage implements IhandleStorage {
  
    abstract get();

    abstract set( value ): void;
    
    abstract remove();

    abstract removeAll(): void;
    
}