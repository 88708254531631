import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Pneu } from "../../models/Pneu.model";
import { ApiBaseService } from "../../services/migrate/api.base.service";
import { Company } from "src/app/models/Company.model";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-create-pneu",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ViewPneuComponent implements OnInit {
  public pneuForm: FormGroup;
  public pneu: Pneu;
  public pneuId: number;
  public title = "Atualizar Pneu";
  public errorMessage;
  private apiBaseService: ApiBaseService;

  public empresaSelected;
  public empresaFilterSelected;

  public companies: any = [];
  public companiesFilter: any = [];
  public companiesSelect: any = [
    { name: "Divisão", value: 1 },
    { name: "Matriz", value: 2 },
    { name: "Filial", value: 3 },
  ];

  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.apiBaseService = apiBaseService;
  }

  ngOnInit(): void {
    this.pneuForm = this.formBuilder.group(
      {
        idEmpresa: ["", Validators.required],
        selecaoEmpresa: ["", Validators.required],
        marca: ["", Validators.required],
        tamanho: ["", Validators.required],
        numeroFogo: ["", Validators.required],
        kmEntrada: [""],
        kmSaida: [""],
        observacao: [""],
      },
      { updateOn: "blur" }
    );
    this.pneuId = this.activatedRoute.snapshot.params["id"];
    this.loadPneu(this.pneuId);
  }
  public async loadPneu(id): Promise<void> {
    try {
      this.spinner.show();

      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      const result = await this.apiBaseService.get<any>({
        url: new Company()["nomeConsultaApiBaseGetAll"],
        params,
      });

      this.companies = result["content"];

      const response = await this.apiBaseService.get<Pneu>({
        url: new Pneu()["nomeConsultaApiBase"] + "/" + id,
      });
      this.pneu = response as Pneu;

      console.log(this.pneu);

      if (this.pneu.empresa != null) {
        if (this.pneu.empresa.empresaMatriz == null) {
          this.empresaFilterSelected = "Matriz";
        } else {
          this.empresaFilterSelected = "Filial";
        }
      } else {
        this.empresaFilterSelected = "Divisão";
      }

      this.filterCompany();

      if (this.pneu.empresa != null) {
        this.pneuForm.controls["idEmpresa"].setValue(this.pneu.empresa.id);
      } else {
        this.pneuForm.controls["idEmpresa"].setValue(this.pneu.divisao.id);
      }

      this.fillUserData();
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public filterCompany() {
    var idEmpresa = this.empresaFilterSelected;
    this.pneuForm.controls["idEmpresa"].setValue(null);
    var empresas = [];

    if (idEmpresa == "Divisão") {
      this.companies.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Matriz") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Filial") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    console.log(this.companiesFilter);
  }

  fillUserData(): void {
    const fields = [
      "numeroFogo",
      "marca",
      "tamanho",
      "observacao",
      "kmEntrada",
      "kmSaida",
    ];
    fields.forEach((element) => {
      this.pneuForm.controls[element].setValue(this.pneu[element]);
    });
  }

  public coverterUpperCase(pneuForm: FormGroup) {
    pneuForm.get("marca").setValue(pneuForm.get("marca").value.toUpperCase());

    pneuForm
      .get("tamanho")
      .setValue(pneuForm.get("tamanho").value.toUpperCase());

    pneuForm
      .get("numeroFogo")
      .setValue(pneuForm.get("numeroFogo").value.toUpperCase());

    pneuForm
      .get("observacao")
      .setValue(pneuForm.get("observacao").value.toUpperCase());
  }
}
