import { ToolsService } from "src/app/services/migrate/tools.service";

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { ManagerAnalyzeModel } from "src/app/models/manager-analyze-model";
import { RejectionManagerModel } from "src/app/models/rejection-manager-model";
import { MatTableDataSource } from "@angular/material/table";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-treat-analysis-select",
  templateUrl: "./treat-analysis-select.component.html",
  styleUrls: ["./treat-analysis-select.component.scss"],
})
export class TreatAnalysisSelectComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public datePipe: DatePipe;

  public analysisOperatorForm: FormGroup;
  public dataSource: MatTableDataSource<any>;

  public analysisOperator = [];

  public companyFilterSelected;
  public typesReportSelected;

  public managerAnalyzeModel: ManagerAnalyzeModel;
  public rejectionManagerModel: RejectionManagerModel;

  public analyzeReportUuid = "";
  public tableColumns: string[];

  public errorMessage;

  ngOnInit(): void {
    this.analyzeReportUuid = this.activatedRoute.snapshot.params.uuid;
    this.loadDataElements();
    this.analysisOperatorForm = this.formBuilder.group(
      {
        analyze: ["", Validators.required],
      },
      { updateOn: "blur" }
    );
  }

  formatDate(dateString: string): string {
    // Converte a string de data para um objeto Date
    const date = new Date(dateString);

    // Formata a data usando o DatePipe
    return this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
  }

  constructor(
    datePipe: DatePipe,
    private tools: ToolsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    analyzeTripService: AnalyzeTripService
  ) {
    this.datePipe = datePipe;
    this.analyzeTripService = analyzeTripService;
  }

  public async loadDataElements() {
    try {
      this.tools.builder().spinner.show();

      this.analyzeTripService
        .getAllAnalysisReport(this.analyzeReportUuid)
        .subscribe((t) => {
          this.analysisOperator.push(t);
        });

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  public async submitForm(): Promise<void> {
    const values = this.analysisOperatorForm.value;
    this.router.navigate([
      "/analise-operacional/treatment/" +
        this.analyzeReportUuid +
        "/" +
        values.analyze.uuid +
        "/" +
        values.analyze.analyze_end_date,
    ]);
  }

  public async generateNewAnalysis(): Promise<void> {
    this.router.navigate([
      "/analise-operacional/new-analysis/" + this.analyzeReportUuid,
    ]);
  }
}
