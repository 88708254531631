import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-axle-eight-dual-view",
  templateUrl: "./axle-eight-dual-view.component.html",
  styleUrls: ["./axle-eight-dual-view.component.scss"],
})
export class AxleEightDualViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorEightIntLeftSelect: any = [];
  public sensorEightIntRightSelect: any = [];

  public sensorEightExtLeftSelect: any = [];
  public sensorEightExtRightSelect: any = [];

  public sensorEightIntRightCombo: any = [];
  public sensorEightIntLeftCombo: any = [];

  public sensorEightExtRightCombo: any = [];
  public sensorEightExtLeftCombo: any = [];

  public axisEightDoubleLeftIntForm: FormGroup;
  public axisEightDoubleLeftExtForm: FormGroup;
  public axisEightDoubleRightIntForm: FormGroup;
  public axisEightDoubleRightExtForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisEightDoubleLeftIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisEightDoubleLeftExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisEightDoubleRightIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisEightDoubleRightExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisEightDoubleLeftIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 8 ESQ INT"
      );
      this.axisEightDoubleLeftExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 8 ESQ EXT"
      );

      this.axisEightDoubleRightIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 8 DIR INT"
      );
      this.axisEightDoubleRightExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 8 DIR EXT"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo8DireitoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 18) {
              eixo8DireitoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 18) {
              eixo8DireitoExt = filter;
            }
          }
        });
        var eixo8EsquerdoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 17) {
              eixo8EsquerdoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 17) {
              eixo8EsquerdoExt = filter;
            }
          }
        });
        var eixo8DireitoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 34) {
              eixo8DireitoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 34) {
              eixo8DireitoInt = filter;
            }
          }
        });
        var eixo8EsquerdoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 33) {
              eixo8EsquerdoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 33) {
              eixo8EsquerdoInt = filter;
            }
          }
        });

        // EIXO 8 DIREITO EXTERNO
        if (eixo8DireitoExt != null) {
          this.axisEightDoubleRightExtForm.controls["id"].setValue(
            eixo8DireitoExt.id
          );

          if (eixo8DireitoExt.sensor != null) {
            this.sensorEightExtRightCombo.push(eixo8DireitoExt.sensor);
            this.sensorEightExtRightSelect = eixo8DireitoExt.sensor.id;
          }

          if (eixo8DireitoExt.pneu != null) {
            this.axisEightDoubleRightExtForm.controls["pneu"].setValue(
              eixo8DireitoExt.pneu.numeroFogo
            );
          }

          this.axisEightDoubleRightExtForm.controls["nrPressaoMinima"].setValue(
            eixo8DireitoExt.nrPressaoMinima
          );

          this.axisEightDoubleRightExtForm.controls["nrPressaoMaxima"].setValue(
            eixo8DireitoExt.nrPressaoMaxima
          );

          this.axisEightDoubleRightExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo8DireitoExt.nrTemperaturaMaxima);
        }

        // EIXO 8 ESQUERDO EXTERNO
        if (eixo8EsquerdoExt != null) {
          this.axisEightDoubleLeftExtForm.controls["id"].setValue(
            eixo8EsquerdoExt.id
          );

          if (eixo8EsquerdoExt.sensor != null) {
            this.sensorEightExtLeftCombo.push(eixo8EsquerdoExt.sensor);
            this.sensorEightExtLeftSelect = eixo8EsquerdoExt.sensor.id;
          }

          if (eixo8EsquerdoExt.pneu != null) {
            this.axisEightDoubleLeftExtForm.controls["pneu"].setValue(
              eixo8EsquerdoExt.pneu.numeroFogo
            );
          }

          this.axisEightDoubleLeftExtForm.controls["nrPressaoMinima"].setValue(
            eixo8EsquerdoExt.nrPressaoMinima
          );

          this.axisEightDoubleLeftExtForm.controls["nrPressaoMaxima"].setValue(
            eixo8EsquerdoExt.nrPressaoMaxima
          );

          this.axisEightDoubleLeftExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo8EsquerdoExt.nrTemperaturaMaxima);
        }

        // EIXO 8 DIREITO INTERNO
        if (eixo8DireitoInt != null) {
          this.axisEightDoubleRightIntForm.controls["id"].setValue(
            eixo8DireitoInt.id
          );

          if (eixo8DireitoInt.sensor != null) {
            this.sensorEightIntRightCombo.push(eixo8DireitoInt.sensor);
            this.sensorEightIntRightSelect = eixo8DireitoInt.sensor.id;
          }

          if (eixo8DireitoInt.pneu != null) {
            this.axisEightDoubleRightIntForm.controls["pneu"].setValue(
              eixo8DireitoInt.pneu.numeroFogo
            );
          }

          this.axisEightDoubleRightIntForm.controls["nrPressaoMinima"].setValue(
            eixo8DireitoInt.nrPressaoMinima
          );

          this.axisEightDoubleRightIntForm.controls["nrPressaoMaxima"].setValue(
            eixo8DireitoInt.nrPressaoMaxima
          );

          this.axisEightDoubleRightIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo8DireitoInt.nrTemperaturaMaxima);
        }

        // EIXO 8 ESQUERDO INTERNO
        if (eixo8EsquerdoInt != null) {
          this.axisEightDoubleLeftIntForm.controls["id"].setValue(
            eixo8EsquerdoInt.id
          );

          if (eixo8EsquerdoInt.sensor != null) {
            this.sensorEightIntLeftCombo.push(eixo8EsquerdoInt.sensor);
            this.sensorEightIntLeftSelect = eixo8EsquerdoInt.sensor.id;
          }

          if (eixo8EsquerdoInt.pneu != null) {
            this.axisEightDoubleLeftIntForm.controls["pneu"].setValue(
              eixo8EsquerdoInt.pneu.numeroFogo
            );
          }

          this.axisEightDoubleLeftIntForm.controls["nrPressaoMinima"].setValue(
            eixo8EsquerdoInt.nrPressaoMinima
          );

          this.axisEightDoubleLeftIntForm.controls["nrPressaoMaxima"].setValue(
            eixo8EsquerdoInt.nrPressaoMaxima
          );

          this.axisEightDoubleLeftIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo8EsquerdoInt.nrTemperaturaMaxima);
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
