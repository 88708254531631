import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-steppe-view",
  templateUrl: "./steppe-view.component.html",
  styleUrls: ["./steppe-view.component.scss"],
})
export class SteppeViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorLeftSelect: any = [];
  public sensorRightSelect: any = [];

  public sensorRightCombo: any = [];
  public sensorLeftCombo: any = [];

  public steppeOneForm: FormGroup;
  public steppeTwoForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.steppeOneForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: ["", Validators.required],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.steppeTwoForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: ["", Validators.required],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.steppeOneForm.controls["cdPosicaoPneu"].setValue("ESTEPE 1");
      this.steppeTwoForm.controls["cdPosicaoPneu"].setValue("ESTEPE 2");

      if (this.veiculoSensores.length > 0) {
        var estepeUm;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 51) {
              estepeUm = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 51) {
              estepeUm = filter;
            }
          }
        });

        var estepeDois;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 52) {
              estepeDois = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 52) {
              estepeDois = filter;
            }
          }
        });

        //ESTEPE 1 SET
        if (estepeUm != null) {
          this.steppeOneForm.controls["id"].setValue(estepeUm.id);

          if (estepeUm.sensor != null) {
            this.sensorRightCombo.push(estepeUm.sensor);
            this.sensorRightSelect = estepeUm.sensor.id;
          }

          if (estepeUm.pneu != null) {
            this.steppeOneForm.controls["pneu"].setValue(
              estepeUm.pneu.numeroFogo
            );
          }

          this.steppeOneForm.controls["nrPressaoMinima"].setValue(
            estepeUm.nrPressaoMinima
          );

          this.steppeOneForm.controls["nrPressaoMaxima"].setValue(
            estepeUm.nrPressaoMaxima
          );

          this.steppeOneForm.controls["nrTemperaturaMaxima"].setValue(
            estepeUm.nrTemperaturaMaxima
          );
        }

        //ESTEPE 2 SET
        if (estepeDois != null) {
          this.steppeTwoForm.controls["id"].setValue(estepeDois.id);

          if (estepeDois.sensor != null) {
            this.sensorLeftCombo.push(estepeDois.sensor);
            this.sensorLeftSelect = estepeDois.sensor.id;
          }

          if (estepeDois.pneu != null) {
            this.steppeTwoForm.controls["pneu"].setValue(
              estepeDois.pneu.numeroFogo
            );
          }

          this.steppeTwoForm.controls["nrPressaoMinima"].setValue(
            estepeDois.nrPressaoMinima
          );

          this.steppeTwoForm.controls["nrPressaoMaxima"].setValue(
            estepeDois.nrPressaoMaxima
          );

          this.steppeTwoForm.controls["nrTemperaturaMaxima"].setValue(
            estepeDois.nrTemperaturaMaxima
          );
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
