<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])" data-title="Voltar"><i
                            class="fas fa-arrow-left"></i></a>
                    Consultar Rélatorios Assincronos
                </h1>
            </div>
        </div>
        <div *ngIf="reportsPage?.page.totalElements === 0">
            <div class="row no-gutters mt-3">
                <p>Nenhum registro até o momento...</p>
            </div>
            <div class="row no-gutters mt-3">
                <a (click)="this.router.navigate(['/relatorio-operacional-assincrono'])" mat-button color="primary"
                   class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                    Gerar um novo relatorio
                </a>
            </div>
        </div>
        <div class="row no-gutters mt-3" *ngIf="reportsPage?.page.totalElements > 0">
            <div class="mat-elevation table-content">
                <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field fxFlex="40%" class="ml-2">
                        <mat-label>Filtrar por nome</mat-label>
                        <input matInput autocomplete="off"
                               (keyup)="this.applyFilter()" [(ngModel)]="filter" style="text-transform: uppercase;" placeholder="Ex: João da silva" #input>
                    </mat-form-field>

                </div>
                <table mat-table [dataSource]="reports" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="dateStart">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Inicio</th>
                        <td mat-cell *matCellDef="let element">{{ formatDate(element.date_start) }}</td>
                    </ng-container>
                    <ng-container matColumnDef="dateEnd">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Fim</th>
                        <td mat-cell *matCellDef="let element">{{ formatDate(element.date_end) }}</td>
                      </ng-container>
                    <ng-container matColumnDef="statusReportsEnum">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status Relatorio</th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="getStatusStyle(element.status_reports_enum)">
                          <span *ngIf="element.status_reports_enum === 'ERROR'; else successProcessing">
                            <span style="color: red;">ERRO</span>
                          </span>
                          <ng-template #successProcessing>
                            <span *ngIf="element.status_reports_enum === 'FINISHED'" style="color: green;">SUCESSO</span>
                            <span *ngIf="element.status_reports_enum === 'PROCESSING'" style="color: blue;">EM PROCESSAMENTO</span>
                          </ng-template>
                        </td>
                      </ng-container>
                    <ng-container matColumnDef="reportName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo do Relatorio</th>
                        <td mat-cell *matCellDef="let element">{{ element.report_name }} {{ element.report_type == 'SYNTHETIC' ? '(Sintético)' :  '(Análitico)'}}</td>
                    </ng-container>
                    <ng-container matColumnDef="plate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Placa</th>
                        <td mat-cell *matCellDef="let element">{{ element.plate }}</td>
                    </ng-container>
                    <ng-container matColumnDef="driverName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome Motorista</th>
                        <td mat-cell *matCellDef="let element">{{ element.driver_name }}</td>
                    </ng-container>
     
                    <ng-container matColumnDef="filePdf">
                        <th mat-header-cell *matHeaderCellDef>PDF</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.minio_object_pdf && element.status_reports_enum === 'FINISHED'" class="alerta mr-2" data-title="Arquivo PDF" matTooltip="Arquivo PDF">
                                <a (click)="exportReport(element.minio_object_pdf)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-pdf.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="fileXlxs">
                        <th mat-header-cell *matHeaderCellDef>EXCEL</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.minio_object_xlxs && element.status_reports_enum === 'FINISHED'" class="alerta mr-2" data-title="Arquivo EXCEL" matTooltip="Arquivo EXCEL">
                                <a (click)="exportReport(element.minio_object_xlxs)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-xlxs.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.minio_object_json && element.status_reports_enum === 'FINISHED'" class="alerta mr-2" data-title="Visualizar" matTooltip="Visualizar">
                                <a (click)="this.router.navigate(['/relatorio-operacional-assincrono/view/' + element.minio_object_json])"class="btn btn-link">
                                    <img height="20" src="assets/img/view.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="update">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.status_reports_enum != 'PROCESSING'" class="alerta mr-2" data-title="Editar" matTooltip="Editar">
                                <a (click)="this.router.navigate(['/relatorio-operacional-assincrono/edit/' + element.uuid])" class="btn btn-link">
                                    <img height="20" src="assets/img/edit.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="handleDelete(element)" class="btn btn-link" matTooltip="Apagar">
                                <img height="20" src="assets/img/trash-cadastro.svg" alt="" />
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 40, 100]"
                               [pageSize]="reportsPage.page.size"
                               (page)="onChangePage($event)"
                               [length]="reportsPage.page.totalElements"
                               showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>
