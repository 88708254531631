<div class="main-page cadastros">
  <div class="container-fluid">
      <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

      <mat-tab-group>
        <mat-tab label="Dados">
          <ng-template matTabContent>
            <div class="row mt-3 no-gutters p-4 row-form">
              <div class="col-12">
                <form [formGroup]="pneuForm" (ngSubmit)="submitForm()">
                  <h2 class="mb-3">Novo Pneu</h2>
                  <div class="form-row" >
                    <div class="form-group col-md-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Tipo de Cadastro</mat-label>
                        <mat-select formControlName="selecaoEmpresa" [(value)]="empresaFilterSelected" [(ngModel)]="empresaFilterSelected"
                       [readonly]=true [disabled]=true required>
                          <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                            {{ type.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="form-group col-md-4">
                      <mat-form-field appearance="outline">
                        <mat-label *ngIf="pneuForm.get('selecaoEmpresa').value == null">Selecione um tipo de cadastro</mat-label>
                        <mat-label *ngIf="pneuForm.get('selecaoEmpresa').value == 'Matriz'">Matrizes</mat-label>
                        <mat-label *ngIf="pneuForm.get('selecaoEmpresa').value == 'Filial'">Filiais</mat-label>
                        <mat-label *ngIf="pneuForm.get('selecaoEmpresa').value == 'Divisão'">Divisões</mat-label>
                        <mat-select formControlName="idEmpresa" [(value)]="empresaSelected" [(ngModel)]="empresaSelected" [readonly]=true [disabled]=true required>
                          <mat-option *ngFor="let type of companiesFilter" [value]="type.id">
                            {{ type.nome }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="form-group col-lg-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Tamanho</mat-label>
                        <input formControlName="tamanho" matInput placeholder="Tamanho" maxlength="50" style="text-transform: uppercase;" [readonly]=true [disabled]=true required />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-lg-6"> 
                      <mat-form-field appearance="outline">
                        <mat-label>Marca</mat-label>
                        <input formControlName="marca" matInput placeholder="Marca" style="text-transform: uppercase;"
                          maxlength="100" [readonly]=true [disabled]=true required/>
                      </mat-form-field>
                    </div>
                    <div class="form-group col-lg-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Número de fogo</mat-label>
                        <input formControlName="numeroFogo" matInput placeholder="Número de fogo" maxlength="15" style="text-transform: uppercase;" [readonly]=true [disabled]=true required />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-lg-6"> 
                      <mat-form-field appearance="outline">
                        <mat-label>Km de Entrada</mat-label>
                        <input formControlName="kmEntrada" matInput placeholder="km de entrada" 
                          maxlength="100"/>
                      </mat-form-field>
                    </div>
                    <div class="form-group col-lg-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Km de Saída</mat-label>
                        <input formControlName="kmSaida" matInput placeholder="Km de Saída" maxlength="15" />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-lg-12">
                      <mat-form-field appearance="outline">
                        <mat-label>Observação</mat-label>
                        <input formControlName="observacao" matInput maxlength="200" placeholder="Observação" style="text-transform: uppercase;"  [readonly]=true [disabled]=true/>
                      </mat-form-field>
                    </div>
                  </div>      
                  <div *ngIf="errorMessage" class="alert alert-danger">
                    {{ errorMessage }}
                  </div>
                </form>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Posição Pneu" [disabled]="!pneuId">
          <ng-template matTabContent>
            <app-pneu-posicao-view [pneuId]="pneuId" [pneu]="pneu">
            </app-pneu-posicao-view>
          </ng-template> 
        </mat-tab>
      </mat-tab-group>
  </div>
</div>
