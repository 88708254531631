import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { EQUIPAMENTOS } from "./equipamentos-constants";
import { Equipamentos } from "./models/equipamentos";
import {
  TABLE_TR_TELEMETRIA,
  Telemetria,
  TelemetriaType,
} from "./models/telemetria-namespace";
import { ToolsService } from "src/app/services/migrate/tools.service";
import { ApiEndPoints } from "src/app/services/migrate/apiEndPoints.enum";
import { TerminalMarker } from "../../../main/terminal-marker";
import { Vehicle } from "src/app/models/Vehicle.model";
import { VeiculoSensor } from "src/app/models/VeiculoSensor.model";
import { ToolboxControl } from "../../toolbox/toolbox_control";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-telemetria",
  templateUrl: "./telemetria.component.html",
  styleUrls: ["./telemetria.component.scss"],
})
export class TelemetriaComponent implements OnInit {
  public marker;

  public nomePosicao;
  public numeroFogo;
  public codigoSensor;
  public nrPressaoMaxima;
  public nrPressaoMinima;
  public nrTemperaturaMaxima;
  public statusPneu;

  public categoriaVeiculo;

  public eqipmtConst = EQUIPAMENTOS;

  public teste = false;

  public pneuSelect: any = [
    {
      name: "DIRECIONAL DIR",
      id: 1,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "DIRECIONAL ESQ",
      id: 2,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 1 ESQ EXT",
      id: 3,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 1 DIR EXT",
      id: 4,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 2 ESQ EXT",
      id: 5,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 2 DIR EXT	",
      id: 6,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 3 ESQ EXT",
      id: 7,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 3 DIR EXT",
      id: 8,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 4 ESQ EXT",
      id: 9,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 4 DIR EXT",
      id: 10,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 5 ESQ EXT",
      id: 11,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 5 DIR EXT",
      id: 12,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 6 ESQ EXT",
      id: 13,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 6 DIR EXT",
      id: 14,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 7 ESQ EXT",
      id: 15,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 7 DIR EXT",
      id: 16,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 8 ESQ EXT",
      id: 17,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 8 DIR EXT",
      id: 18,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 1 ESQ INT",
      id: 19,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 1 DIR INT",
      id: 20,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 2 ESQ INT",
      id: 21,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 2 DIR INT",
      id: 22,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 3 ESQ INT",
      id: 23,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 3 DIR INT",
      id: 24,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 4 ESQ INT",
      id: 25,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 4 DIR INT",
      id: 26,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 5 ESQ INT",
      id: 27,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 5 DIR INT",
      id: 28,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 6 ESQ INT",
      id: 29,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 6 DIR INT",
      id: 30,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 7 ESQ INT",
      id: 31,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 7 DIR INT",
      id: 32,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 8 ESQ INT",
      id: 33,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 8 DIR INT",
      id: 34,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 1 ESQ",
      id: 35,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 1 DIR",
      id: 36,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 2 ESQ",
      id: 37,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 2 DIR",
      id: 38,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 3 ESQ",
      id: 39,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 3 DIR",
      id: 40,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 4 ESQ",
      id: 41,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 4 DIR",
      id: 42,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 5 ESQ",
      id: 43,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 5 DIR",
      id: 44,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 6 ESQ",
      id: 45,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 6 DIR",
      id: 46,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 7 ESQ",
      id: 47,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 7 DIR",
      id: 48,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 8 ESQ",
      id: 49,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 8 DIR",
      id: 50,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "ESTEPE 1",
      id: 51,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "ESTEPE 2",
      id: 52,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 9 ESQ",
      id: 54,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },
    {
      name: "EIXO 9 DIR",
      id: 53,
      ativo: false,
      statusPneu: 0,
      pressaoAtual: 0,
      temperaturaAtual: 0,
    },

    { name: "EIXO 9 ESQ EXT", id: 58, ativo: false, statusPneu: 0 },
    { name: "EIXO 9 DIR EXT", id: 56, ativo: false, statusPneu: 0 },
    { name: "EIXO 9 ESQ INT", id: 57, ativo: false, statusPneu: 0 },
    { name: "EIXO 9 DIR INT", id: 55, ativo: false, statusPneu: 0 },
    { name: "DIRECIONAL DIR 2", id: 60, ativo: false, statusPneu: 0 },
    { name: "DIRECIONAL ESQ 2", id: 59, ativo: false, statusPneu: 0 },
  ];

  public items = [];
  private pneus = new Array<any>();

  public quantidadeEixo = null;
  public tipoRodagem = null;
  public rodagem = null;
  public placa = null;

  public pneusAtivos = new Array<VeiculoSensor>();

  public allFieldsTelm = new Array<any>();
  public telemetria = Array<Array<Telemetria>>();
  public equipamentosAtiv = new Array<Equipamentos>();
  public equipamentosDesativ = new Array<Equipamentos>();

  constructor(
    public toolboxControl: ToolboxControl,
    public tools: ToolsService,
    public terminalMarker: TerminalMarker,
    private spinner: NgxSpinnerService,
    private serviceApiBase: ApiBaseService
  ) {}

  ngOnInit(): void {
    this.items = [];
    this.setData();
  }

  private setData() {
    const { marker } = { marker: null };
    this.marker = this.terminalMarker.markerTelemetria;
    this.marker.pneuPosition = "";
    this.getEquipamentosDesativados();
    this.sensores(
      this.terminalMarker.markerTelemetria.terminal.veiculo.id,
      this.terminalMarker.markerTelemetria.terminal.id
    );

    this.spinner.hide();
  }

  private async sensores(idVeiculo, idTerminal) {
    const result = await this.serviceApiBase.get<Vehicle>({
      url:
        new Vehicle()["statusPneusApiBase"] +
        idVeiculo +
        "/terminal/" +
        idTerminal,
    });

    if (result.tipoRodagem == "D") {
      this.rodagem = "DUPLA";
    }

    if (result.tipoRodagem == "S") {
      this.rodagem = "SIMPLES";
    }

    this.placa = result.cdPlaca;
    this.quantidadeEixo = result.nrQuantidadeEixo;
    this.tipoRodagem = result.tipoRodagem;
    this.categoriaVeiculo = result.categoriaVeiculo;

    if (result.veiculoSensorTelemetriaList.length > 0) {
      this.pneusAtivos = result.veiculoSensorTelemetriaList;

      this.pneusAtivos.forEach((element) => {
        var pneu = this.pneuSelect.filter(
          (pneu) => pneu.name == element.posicaoPneu
        );

        if (pneu.length > 0) {
          pneu[0].ativo = true;
          pneu[0].statusPneu = element.statusPneu;
          pneu[0].temperaturaAtual = element.temperaturaAtual;
          pneu[0].pressaoAtual = element.pressaoAtual;
        }
      });
    }
  }

  public fecharModal() {
    const closeModalButton = document.querySelector("#close-modal");
    const modal = document.querySelector("#modal");
    const fade = document.querySelector("#fade");

    const toggleModal = () => {
      modal.classList.toggle("hide");
      fade.classList.toggle("hide");
    };

    [closeModalButton, fade].forEach((el) => {
      el.addEventListener("click", () => toggleModal());
    });
  }

  public openDialog(posicaoPneu, idSelect) {
    var sensor = this.pneusAtivos.filter(
      (pneu) => pneu.posicaoPneu == posicaoPneu.name
    );

    this.nomePosicao = sensor[0].posicaoPneu;
    this.codigoSensor = sensor[0].codigoSensor;
    this.numeroFogo = sensor[0].nrFogo;
    this.nrPressaoMaxima = sensor[0].nrPressaoMaxima;
    this.nrPressaoMinima = sensor[0].nrPressaoMinima;
    this.nrTemperaturaMaxima = sensor[0].nrTemperaturaMaxima;
    var eventoStatusPneus = "Status OK do Pneu";

    //Trata posições do gateway novo, onde a descrição do status já vem do backend
    if (sensor[0].statusPneuDesc != null && sensor[0].statusPneuDesc != '') {
      eventoStatusPneus = sensor[0].statusPneuDesc;
    } else {
      //Gateway Legado
      if (sensor[0].statusPneu == 1) {
        eventoStatusPneus = "Alarme de Pressão de Pneu";
      }

      if (sensor[0].statusPneu == 2) {
        eventoStatusPneus = "Alarme de Temperatura Pneu";
      }

      if (sensor[0].statusPneu == 3) {
        eventoStatusPneus = "Alarme de Pressão e de Temperatura";
      }
    }


    this.statusPneu = eventoStatusPneus;

    const openModalButton = document.querySelector("#" + idSelect);
    const closeModalButton = document.querySelector("#close-modal");
    const modal = document.querySelector("#modal");
    const fade = document.querySelector("#fade");

    const toggleModal = () => {
      modal.classList.toggle("hide");
      fade.classList.toggle("hide");
    };

    console.log(closeModalButton);

    [openModalButton, closeModalButton, fade].forEach((el) => {
      el.addEventListener("click", () => toggleModal());
    });
  }

  public async deleteSensor(id): Promise<void> {
    try {
      this.spinner.show();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  private loadTelemetria(): void {
    const responseFields = Object.keys(this.marker);

    const type = new TelemetriaType();

    TABLE_TR_TELEMETRIA.forEach((info) => {
      const result = responseFields.filter((r) =>
        r.match(`(${info.fieldKey})`)
      );
      type[info.fieldKey] = this.sort(result);
    });

    this.allFieldsTelm = [
      ...type.voltagemBateriaSensorPneu,
      ...type.pressaoSensorPneu,
      ...type.temperaturaSensorPneu,
      ...type.idSensorPneu,
    ];

    this.buildTableTd(type);
  }

  private buildTableTd(type: TelemetriaType): void {
    const telemetria = Array<Array<Telemetria>>();

    const size = +this.allFieldsTelm.length;

    const objectKeys = +Object.keys(type).length;

    let i = +(size / objectKeys).toFixed();

    while (i > 0) {
      const results: Array<string> = this.allFieldsTelm.filter(
        (r) => r.replace(/\D+/g, "") == i
      );

      let tdTable = new Array<any>();

      results.forEach((field) => {
        const { title } = TABLE_TR_TELEMETRIA.find(({ fieldKey }) =>
          field.match(`(${fieldKey})`)
        );
        tdTable.push(new Telemetria(field, title));
      });

      telemetria.push(tdTable);

      tdTable = [];

      i--;
    }
    this.telemetria = telemetria.reverse();
    this.telemetria.reverse();

    const payloadPosicaoPneu = new Array<any>();

    for (const i of this.telemetria) {
      i[0] = new Telemetria("pneuPosition", "Posição do Pneu");

      if (this.marker[i[3].field]) {
        payloadPosicaoPneu.push(this.marker[i[3].field]);
      }
    }

    this.getPneusPositions(payloadPosicaoPneu);
  }

  private getPneusPositions(payload): void {
    if (this.pneus.length == 0) {
      this.tools.builder().spinner.show();

      this.tools
        .builder()
        .api.post(
          `${this.tools.builder().api.getApiUrl().api}${
            ApiEndPoints.REPORT_TERMINAL_STATUS_PNEUS
          }`,
          payload,
          {}
        )
        .subscribe((r: any) => {
          this.pneus = r;
          this.tools.builder().spinner.hide();
        });
    }
  }

  public findPneuPosition(sensorCode) {
    return this.pneus.find((e) => e?.sensorCode == this.marker[sensorCode])
      ?.pneu;
  }

  private sort(array: Array<string>): Array<string> {
    return array.sort(function (a, b) {
      return Number(a?.replace(/\D+/g, "")) - Number(b?.replace(/\D+/g, ""));
    });
  }

  public getEquipamentosDesativados(): Array<Equipamentos> {
    const enuns: string = this.marker?.enumIndicadorVeiculo;

    this.equipamentosDesativ = [];

    if (!enuns) {
      return (this.equipamentosDesativ = this.eqipmtConst);
    }

    const ens: Array<string> = enuns.split(",");

    this.eqipmtConst.forEach((action) => {
      const result = ens.find((en) => Number(en) == action.enum);

      if (!result) {
        this.items.push({ label: action.value });
        this.equipamentosDesativ.push(action);
      }
    });

    return this.equipamentosDesativ;
  }

  public getEquipamentosAtivados(enuns: String): Array<Equipamentos> {
    this.equipamentosAtiv = [];

    if (!enuns) {
      return;
    }

    const ens: Array<string> = enuns.split(",");

    ens.forEach((en) => {
      this.equipamentosAtiv.push(this.eqipmtConst[Number(en)]);
    });

    return this.equipamentosAtiv;
  }

  convertSecondsInHours(second: number): String {
    if (!second) {
      return null;
    }

    let totalSeconds = second;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // If you want strings with leading zeroes:
    const resMinutes = String(minutes).padStart(2, "0");
    const resHours = String(hours).padStart(2, "0");
    const resSeconds = String(seconds).padStart(2, "0");

    return resHours + ":" + resMinutes + ":" + resSeconds.substring(0, 2);
  }

  getInOutName(type: string, index: number): string {
    switch (type) {
      case 'IN': {
        switch (index) {
          case 1: {
            if (this.marker.terminal.nmNomeIn1 == null || this.marker.terminal.nmNomeIn1 === ''){
              return 'Entrada 1:';
            } else {
              return this.marker.terminal.nmNomeIn1 + ':';
            }
          }
          case 2: {
            if (this.marker.terminal.nmNomeIn2 == null || this.marker.terminal.nmNomeIn2 === ''){
              return 'Entrada 2:';
            } else {
              return this.marker.terminal.nmNomeIn2 + ':';
            }
          }
          case 3: {
            if (this.marker.terminal.nmNomeIn3 == null || this.marker.terminal.nmNomeIn3 === ''){
              return 'Entrada 3:';
            } else {
              return this.marker.terminal.nmNomeIn3 + ':';
            }
          }
          case 4: {
            if (this.marker.terminal.nmNomeIn4 == null || this.marker.terminal.nmNomeIn4 === ''){
              return 'Entrada 4:';
            } else {
              return this.marker.terminal.nmNomeIn4 + ':';
            }
          }
          case 5: {
            if (this.marker.terminal.nmNomeIn5 == null || this.marker.terminal.nmNomeIn5 === ''){
              return 'Entrada 5:';
            } else {
              return this.marker.terminal.nmNomeIn5 + ':';
            }
          }
          case 6: {
            if (this.marker.terminal.nmNomeIn6 == null || this.marker.terminal.nmNomeIn6 === ''){
              return 'Entrada 6:';
            } else {
              return this.marker.terminal.nmNomeIn6 + ':';
            }
          }
          case 7: {
            if (this.marker.terminal.nmNomeIn7 == null || this.marker.terminal.nmNomeIn7 === ''){
              return 'Entrada 7:';
            } else {
              return this.marker.terminal.nmNomeIn7 + ':';
            }
          }
          case 8: {
            if (this.marker.terminal.nmNomeIn8 == null || this.marker.terminal.nmNomeIn8 === ''){
              return 'Entrada 8:';
            } else {
              return this.marker.terminal.nmNomeIn8 + ':';
            }
          }
          case 9: {
            if (this.marker.terminal.nmNomeIn9 == null || this.marker.terminal.nmNomeIn9 === ''){
              return 'Entrada 9:';
            } else {
              return this.marker.terminal.nmNomeIn9 + ':';
            }
          }
          case 10: {
            if (this.marker.terminal.nmNomeIn10 == null || this.marker.terminal.nmNomeIn10 === ''){
              return 'Entrada 10:';
            } else {
              return this.marker.terminal.nmNomeIn10 + ':';
            }
          }
        }
        break;
      }
      case 'OUT': {
        switch (index) {
          case 1: {
            if (this.marker.terminal.nmNomeOut1 == null || this.marker.terminal.nmNomeOut1 === ''){
              return 'Saída 1:';
            } else {
              return this.marker.terminal.nmNomeOut1 + ':';
            }
          }
          case 2: {
            if (this.marker.terminal.nmNomeOut2 == null || this.marker.terminal.nmNomeOut2 === ''){
              return 'Saída 2:';
            } else {
              return this.marker.terminal.nmNomeOut2 + ':';
            }
          }
          case 3: {
            if (this.marker.terminal.nmNomeOut3 == null || this.marker.terminal.nmNomeOut3 === ''){
              return 'Saída 3:';
            } else {
              return this.marker.terminal.nmNomeOut3 + ':';
            }
          }
          case 4: {
            if (this.marker.terminal.nmNomeOut4 == null || this.marker.terminal.nmNomeOut4 === ''){
              return 'Saída 4:';
            } else {
              return this.marker.terminal.nmNomeOut4 + ':';
            }
          }
          case 5: {
            if (this.marker.terminal.nmNomeOut5 == null || this.marker.terminal.nmNomeOut5 === ''){
              return 'Saída 5:';
            } else {
              return this.marker.terminal.nmNomeOut5 + ':';
            }
          }

        }
        break;
      }
    }
  }
}
