import { VehicleTypeInterface } from "../contracts/VehicleType.interface";
import { FuelTypeVehicle } from "../contracts/FuelTypeVehicle.interface";
import { VeiculoSensor } from "./VeiculoSensor.model";
import { Company } from "./Company.model";
import { Division } from "./Division.model";

export class Vehicle {
  nomeConsultaApiBase = "veiculos";
  statusPneusApiBase = "veiculos/status-pneus/";
  nomeConsultaApiBaseModify = "veiculos/modify";
  nomeConsultaApiBaseGetAll = "veiculos/getAll";
  nomeConsultaApiBaseGetCompanyOrDivision = "veiculos/getvehicle";
  listCamposConsulta = [
    "placa",
    "nmempresa",
    "marca",
    "nmModelo",
    "nmterminal",
  ];
  permissaoVisualizacao = "VEHICLE_ATUALIZACAO_FUNCIONALIDADE";
  permissaoDelecao = "VEHICLE_DELECAO_FUNCIONALIDADE";
  enderecoRoute = "vehicles";

  ativo?: boolean;
  id?: number;
  nmNome: string;
  empresa: Company;
  divisao: Division;
  cdPlaca: string;
  cdRenavam: string;
  cdChassi: string;
  cdAntt: string;
  cdPbt: string;
  nmMarca: string;
  nmModelo: string;
  nrQuantidadeEixo: string;
  idVeiculoTipo: VehicleTypeInterface;
  idVeiculoCombustivelTipo: FuelTypeVehicle;
  nrAnoFabricacao: string;
  nrAnoModelo: string;
  cdCor: string;
  tipoRodagem: string;
  nmCidade: string;
  nmEstado: string;
  nmPais: string;
  veiculoSensorList: VeiculoSensor[];
  veiculoSensorTelemetriaList?: VeiculoSensor[];
  nrCoeficienteConsumo: string;
  nrOdometro: string;
  nrTelefoneProprietario: string;
  nmNomeProprietario: string;
  dsEmail: string;
  nrCapacidadeTanque: string;
  possuiTpms: boolean;
  categoriaVeiculo?: string;
}
