import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { TerminalAlarme } from "../../models/TerminalAlarme.model";
import { ApiService } from "../../services/api.service";
import { Validation } from "./validation";
import { NgxSpinnerService } from "ngx-spinner";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-create-terminal-alarme",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class EditTerminalAlarmeComponent implements OnInit {
  public terminalalarmeForm: FormGroup;
  public terminalalarmeSelected;
  public terminalalarme: TerminalAlarme;
  public terminalalarmeId: number;
  public title = "Atualizar Eventos";
  public errorMessage;
  private service: ApiService;
  private validation: Validation;

  ngOnInit(): void {
    this.terminalalarmeForm = this.formBuilder.group(this.validation.rules());
    this.terminalalarmeId = this.activatedRoute.snapshot.params["id"];
    this.loadTerminalAlarme(this.terminalalarmeId);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.service = apiService;
    this.validation = new Validation();
  }

  fillUserData(): void {
    const fields = [
      "cdAtributo1",
      "cdAtributo2",
      "cdCodigo",
      "nmNomeIconeCss",
      "dsMensagem",
      "ativo",
      "flMarkerRelatorioGerencial",
      "flExibePainelEvento",
      "cdCor",
      "nmFator",
      "nrOrdemPrioridade",
      "dsProcedimentoOperador",
    ];
    fields.forEach((element) => {
      this.terminalalarmeForm.controls[element].setValue(
        this.terminalalarmeSelected[element]
      );
    });
  }

  public async loadTerminalAlarme(id): Promise<void> {
    this.spinner.show();

    try {
      const response = await this.service.get<TerminalAlarme>({
        url: `terminal-alarme/${id}`,
      });
      this.terminalalarmeSelected = response as TerminalAlarme;
      this.fillUserData();

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public async submitForm(): Promise<void> {
    try {
      this.spinner.show();

      this.coverterUpperCase(this.terminalalarmeForm);
      const values = this.terminalalarmeForm.value;
      const id = Math.floor(this.terminalalarmeId);
      const post = {
        id,
        ...values,
        idUsuarioAlteracao: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()
          ?.id,
      };

      await this.service.put<TerminalAlarme>(
        this.terminalalarmeId,
        "terminal-alarme",
        post
      );

      this.spinner.hide();

      this.router.navigate(["/terminal-alarme"]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public coverterUpperCase(terminalalarmeForm: FormGroup) {
    if (terminalalarmeForm.get("dsMensagem").value != null) {
      terminalalarmeForm
        .get("dsMensagem")
        .setValue(terminalalarmeForm.get("dsMensagem").value.toUpperCase());
    }

    if (terminalalarmeForm.get("cdCor").value != null) {
      terminalalarmeForm
        .get("cdCor")
        .setValue(terminalalarmeForm.get("cdCor").value.toUpperCase());
    }

    if (terminalalarmeForm.get("dsProcedimentoOperador").value != null) {
      terminalalarmeForm
        .get("dsProcedimentoOperador")
        .setValue(
          terminalalarmeForm.get("dsProcedimentoOperador").value.toUpperCase()
        );
    }
  }
}
