import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

import { ContentInterface } from "../../../contracts/Content.interface";
import { Driver } from "../../../models/Driver.model";
import { ApiService } from "../../../services/api.service";
import { Validation } from "../validation";

import axios from "axios";
import storageX from "src/app/storageCore/storageX";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import { NgxSpinnerService } from "ngx-spinner";
import { PrimeNGConfig } from "primeng/api";
import PRIME_TRANSLATE from "src/app/utils/primetranslate";
import { ToolsService } from "src/app/services/migrate/tools.service";
import { MSG } from "./create.component";
import { Company } from "src/app/models/Company.model";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-create-driver",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class EditDriverComponent implements OnInit {
  private apiBaseService: ApiBaseService;
  public loader = false;
  public driverForm: FormGroup;
  public driver: Driver;
  public driverEdit: Driver;
  public driverId: number;

  public companyId: number = null;
  public empresaSelected;
  public empresaFilterSelected;
  public companyForm: FormGroup;

  public modoEdicao = true;
  public title = "Atualizar Motorista";
  public titleVar = "";
  public errorMessage;
  private service: ApiService;
  private validation: Validation;
  public visible;
  public stateOp = "off";
  public yearRange = String(new Date().getFullYear())
    .concat(":")
    .concat(String(new Date().getFullYear() + 20));
  public stateOptions;

  public companies: any = [];
  public companiesFilter: any = [];
  public companiesSelect: any = [];
  public notifyDate: Date;
  public selectDate: Date;
  public invlidDate = true;

  public handleChange(event) {
    this.notifyDate = null;
    this.visible = event.value;
    this.stateOp = event.value;
  }
  public cancelChange() {
    this.notifyDate = null;
    this.visible = false;
    this.stateOp = "off";
  }

  onSelect(event) {
    let now = new Date();

    this.selectDate = event;

    if (this.selectDate <= now) {
      this.invlidDate = true;
      this.tools.builder().openToast.emit(MSG);
    } else {
      this.invlidDate = false;
    }
  }

  public onClose() {
    let now = new Date();
    if (this.selectDate <= now) {
      this.invlidDate = true;
      this.tools.builder().openToast.emit(MSG);
      return;
    }
    this.invlidDate = false;
    this.notifyDate = this.selectDate;
  }

  ngOnInit(): void {
    this.primengConfig.setTranslation(PRIME_TRANSLATE);
    this.driverForm = this.formBuilder.group(
      {
        selecaoEmpresa: [""],
        empresa: ["", Validators.required],
        empresaEdit: [""],
        nmNome: ["", Validators.required],
        cdIbutton: [""],
        rfid: [""],
        cdCodigo: ["", Validators.required],
        cdSenha: ["", Validators.required],
        cdRG: ["", Validators.required],
        cdCpf: ["", Validators.required],
        nmOrgaoEmissor: ["", Validators.required],
        cdNumeroRegistro: ["", Validators.required],
        dtNascimento: [""],
        cdNumeroCnh: [""],
        dtValidadeCnh: [""],
        cdCategoriaCnh: [""],
        nrTelefone: [""],
        dsEmail: [""],
        dsObservacao: [""],
        flAlarmeVencimentoCnhEmail: [false],
        flAlarmeVencimentoCnhSms: [false],
        cdCep: [""],
        txEndereco: [""],
        cdNumero: [""],
        dsComplemento: [""],
        nmBairro: [""],
        nmCidade: [""],
        nmEstado: [""],
        nmPais: [""],
        motoristaAutorizacaoAnttList: this.formBuilder.array([
          this.formBuilder.group({
            autorizacaoAnttId: [""],
            dtCadastro: [""],
            dtVencimento: [""],
            flGerarAlertaVencimento: [false],
            nmTipoAutorizacao: [""],
            nrNumero: [""],
          }),
        ]),
        motoristaAutorizacaoList: this.formBuilder.array([
          this.formBuilder.group({
            autorizacaoId: [""],
            dtCadastro: [""],
            dtVencimento: [""],
            flGerarAlertaVencimento: [false],
            nmTipoAutorizacao: [""],
            nrNumero: [""],
          }),
        ]),
        motoristaJornadaTrabalhoList: this.formBuilder.array(
          this.worktimeList()
        ),
      },
      { updateOn: "blur" }
    );
    this.driverId = this.activatedRoute.snapshot.params["id"];
    this.loadDriver(this.driverId);
    this.setup();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  get motoristaAutorizacaoAnttItems(): FormArray {
    return this.driverForm.get("motoristaAutorizacaoAnttList") as FormArray;
  }

  get motoristaAutorizacaoItems(): FormArray {
    return this.driverForm.get("motoristaAutorizacaoList") as FormArray;
  }

  get motoristaJornadaTrabalhoItems(): FormArray {
    return this.driverForm.get("motoristaJornadaTrabalhoList") as FormArray;
  }

  addAutorizacaoAnttItem(element?: any): void {
    if (element) {
      const {
        id,
        dtCadastro,
        dtVencimento,
        nmTipoAutorizacao,
        flGerarAlertaVencimento,
        nrNumero,
      } = element;

      const form = this.formBuilder.group({
        autorizacaoAnttId: [id],
        dtCadastro: [dtCadastro],
        dtVencimento: [dtVencimento],
        flGerarAlertaVencimento: [flGerarAlertaVencimento],
        nmTipoAutorizacao: [nmTipoAutorizacao],
        nrNumero: [nrNumero],
      });

      this.motoristaAutorizacaoAnttItems.push(form);
      return;
    } else {
      this.motoristaAutorizacaoAnttItems.push(
        this.formBuilder.group({
          autorizacaoAnttId: [""],
          dtCadastro: [""],
          dtVencimento: [""],
          flGerarAlertaVencimento: [false],
          nmTipoAutorizacao: [""],
          nrNumero: [""],
        })
      );
    }
  }
  deleteAutorizacaoAnttItem(index): void {
    this.motoristaAutorizacaoAnttItems.removeAt(index);
  }

  addAutorizacaoItem(element?: any): void {
    if (element) {
      const {
        id,
        dtCadastro,
        dtVencimento,
        nmTipoAutorizacao,
        flGerarAlertaVencimento,
        nrNumero,
      } = element;

      const form = this.formBuilder.group({
        autorizacaoId: [id],
        dtCadastro: [dtCadastro],
        dtVencimento: [dtVencimento],
        flGerarAlertaVencimento: [flGerarAlertaVencimento],
        nmTipoAutorizacao: [nmTipoAutorizacao],
        nrNumero: [nrNumero],
      });

      this.motoristaAutorizacaoItems.push(form);
      return;
    } else {
      this.motoristaAutorizacaoItems.push(
        this.formBuilder.group({
          autorizacaoId: [""],
          dtCadastro: [""],
          dtVencimento: [""],
          flGerarAlertaVencimento: [false],
          nmTipoAutorizacao: [""],
          nrNumero: [""],
        })
      );
    }
  }

  deleteAutorizacaoItem(index): void {
    this.motoristaAutorizacaoItems.removeAt(index);
  }

  public dayDefault = {
    ativo: true,
    cdHoraFim: "",
    cdHoraFimIntervalo: "",
    cdHoraInicio: "",
    cdHoraInicioIntervalo: "",
    id: null,
    selected: false,
  };

  public days = [
    {
      cdDiaSemana: 1,
      ...this.dayDefault,
      name: "Domingo",
    },
    {
      cdDiaSemana: 2,
      ...this.dayDefault,
      name: "Segunda",
    },
    {
      cdDiaSemana: 3,
      ...this.dayDefault,
      name: "Terça",
    },
    {
      cdDiaSemana: 4,
      ...this.dayDefault,
      name: "Quarta",
    },
    {
      cdDiaSemana: 5,
      ...this.dayDefault,
      name: "Quinta",
    },
    {
      cdDiaSemana: 6,
      ...this.dayDefault,
      name: "Sexta",
    },
    {
      cdDiaSemana: 7,
      ...this.dayDefault,
      name: "Sábado",
    },
  ];

  worktimeList(): any {
    const worktime = [];

    this.days.forEach(({ cdDiaSemana, selected }) => {
      worktime.push(
        this.formBuilder.group({
          id: [""],
          cdDiaSemana: [cdDiaSemana],
          selected: [selected],
          cdHoraFim: [""],
          cdHoraFimIntervalo: [""],
          cdHoraInicio: [""],
          cdHoraInicioIntervalo: [""],
        })
      );
    });

    return worktime;
  }

  async setup(): Promise<void> {
    try {
      this.spinner.show();

      const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(); //localStorage.getItem('idPerfil');
      this.loader = true;
      const driver = await this.service.get<ContentInterface[]>({
        url: "motorista",
      });
      this.driver = driver["content"];

      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      const result = await this.apiBaseService.get<any>({
        url: new Company()["nomeConsultaApiBaseGetAll"],
        params,
      });

      this.companies = result["content"];

      this.companiesSelect = [
        { name: "Divisão", value: 1 },
        { name: "Matriz", value: 2 },
        { name: "Filial", value: 3 },
      ];

      console.log(this.driverEdit);

      this.companies.forEach((element) => {
        if (element.id == this.driverEdit.empresa.id) {
          if (element.cnpj == null) {
            this.titleVar = "Divisão";
          }

          if (element.cnpj != null && element.idEmpresaMatriz == null) {
            this.titleVar = "Matriz";
          }

          if (element.cnpj != null && element.idEmpresaMatriz != null) {
            this.titleVar = "Filial";
          }
        }
      });

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  constructor(
    private baseService: ApiBaseService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private tools: ToolsService,
    private primengConfig: PrimeNGConfig,
    private activatedRoute: ActivatedRoute
  ) {
    this.apiBaseService = baseService;
    this.service = apiService;
    this.stateOptions = [
      { label: "Off", value: "off" },
      { label: "On", value: "on" },
    ];
  }

  async searchCep(): Promise<void> {
    const { cdCep } = this.driverForm.value;
    const cep = cdCep.replace(/\D/g, "");

    if (cep !== "") {
      const cepValidatorRules = /^[0-9]{8}$/;
      if (cepValidatorRules.test(cep)) {
        const url = `https://viacep.com.br/ws/${cep}/json`;
        try {
          this.spinner.show();

          const { data } = await axios.get(url);
          const { logradouro, complemento, bairro, localidade, uf } = data;

          this.driverForm.controls["txEndereco"].setValue(logradouro);
          this.driverForm.controls["dsComplemento"].setValue(complemento);
          this.driverForm.controls["nmBairro"].setValue(bairro);
          this.driverForm.controls["nmCidade"].setValue(localidade);
          this.driverForm.controls["nmEstado"].setValue(uf);
          this.driverForm.controls["nmPais"].setValue("BRASIL");

          this.spinner.hide();
        } catch (error) {
          this.spinner.hide();
          throw new EvalError(error);
        }
      }
    }
  }

  public async loadDriver(id): Promise<void> {
    this.spinner.show();
    try {
      const response = await this.service.get<Driver>({
        url: `motorista/find/${id}`,
      });

      this.driver = response as Driver;
      console.log(this.driver);
      this.fillUserData();
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  jornadaTrabalhoFields = [
    "cdDiaSemana",
    "cdHoraFim",
    "cdHoraFimIntervalo",
    "cdHoraInicio",
    "cdHoraInicioIntervalo",
  ];

  fillUserData(): void {
    console.log(this.driver.empresa);
    this.driverEdit = this.driver;
    this.empresaSelected = this.driver.empresa.id;
    console.log(this.empresaSelected);
    if (this.empresaSelected) {
      console.log(this.driverForm);
      this.driverForm.controls["empresa"].setValue(this.empresaSelected);
      console.log(this.driverForm);
    }

    const fields = [
      "nmNome",
      "cdIbutton",
      "cdCodigo",
      "cdSenha",
      "cdRG",
      "cdCpf",
      "nmOrgaoEmissor",
      "cdNumeroRegistro",
      "dtNascimento",
      "cdNumeroCnh",
      "dtValidadeCnh",
      "cdCategoriaCnh",
      "nrTelefone",
      "dsEmail",
      "dsObservacao",
      "flAlarmeVencimentoCnhSms",
      "flAlarmeVencimentoCnhEmail",
      "cdCep",
      "txEndereco",
      "cdNumero",
      "dsComplemento",
      "nmBairro",
      "nmCidade",
      "nmEstado",
      "nmPais",
      "rfid"
    ];
    fields.forEach((element) => {
      this.driverForm.controls[element].setValue(this.driver[element]);
    });

    const motoristaAutorizacaoAnttList =
      this.driver.motoristaAutorizacaoAnttList;

    if (motoristaAutorizacaoAnttList.length > 0) {
      this.deleteAutorizacaoAnttItem(0);
    }

    motoristaAutorizacaoAnttList.forEach((element) => {
      this.addAutorizacaoAnttItem(element);
    });

    const motoristaAutorizacaoList = this.driver.motoristaAutorizacaoList;

    if (motoristaAutorizacaoList.length > 0) {
      this.deleteAutorizacaoItem(0);
    }

    motoristaAutorizacaoList.forEach((element) => {
      this.addAutorizacaoItem(element);
    });

    const motoristaJornada = this.driver.motoristaJornadaTrabalhoList;

    this.motoristaJornadaTrabalhoItems.controls.forEach(
      (formGroup: FormGroup, index) => {
        this.jornadaTrabalhoFields.forEach((it) => {
          const day = this.days[index];
          const value = motoristaJornada?.find(
            (e) => parseInt(e.cdDiaSemana) === day.cdDiaSemana
          );

          if (value && value[it] !== undefined) {
            formGroup.controls["id"].setValue(value["id"]);
            formGroup.controls["selected"].setValue(true);
            formGroup.controls[it].setValue(value[it]);
          }
        });
      }
    );
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();

    try {
      this.coverterUpperCase(this.driverForm);

      const values = this.driverForm.value;
      const id = Math.floor(this.driverId);

      const authAnttList = values.motoristaAutorizacaoAnttList.filter(
        (element) => {
          const {
            autorizacaoAnttId: id,
            nmTipoAutorizacao,
            nrNumero,
          } = element;
          if (nmTipoAutorizacao && nrNumero) {
            delete element.autorizacaoAnttId;
            return {
              id,
              ...element,
            };
          }
        }
      );

      const authList = values.motoristaAutorizacaoList.filter((element) => {
        const { autorizacaoId: id, nmTipoAutorizacao, nrNumero } = element;
        if (nmTipoAutorizacao && nrNumero) {
          delete element.autorizacaoId;
          return {
            id,
            ...element,
          };
        }
      });

      const motoristaAutorizacaoAnttList =
        authAnttList && authAnttList.length > 0 ? authAnttList : null;

      const motoristaAutorizacaoList =
        authList && authList.length > 0 ? authList : null;

      const motoristaJornadaTrabalhoList = values.motoristaJornadaTrabalhoList
        .filter((e) => e.selected)
        .map((i) => {
          delete i.selected;
          return i;
        });

      if (
        motoristaJornadaTrabalhoList &&
        motoristaJornadaTrabalhoList.length > 0
      ) {
        motoristaJornadaTrabalhoList.filter((e) => {
          if (
            e.cdDiaSemana === "" ||
            e.cdHoraFim === "" ||
            e.cdHoraInicio === "" ||
            e.cdHoraInicioIntervalo === ""
          ) {
            this.errorMessage =
              "Se foi marcado um dia de jornada de trabalho, todos os campos da linha de horário são obrigatórios";
            return;
          }
        });
      }

      var post;

      console.log(values);

      if (values.empresaEdit != "" || values.empresaEdit != null) {
        post = {
          id,
          ...values,
          motoristaAutorizacaoAnttList,
          motoristaAutorizacaoList,
          motoristaJornadaTrabalhoList,
          empresa: {
            id: values.empresaEdit.id,
            cdCnpj: values.empresaEdit.cnpj,
          },
          idUsuarioAlteracao: storageX
            .whereKeyIs(STORAGE_KEY_CURRENT_USER)
            .get()?.id,
        };
      } else {
        post = {
          id,
          ...values,
          motoristaAutorizacaoAnttList,
          motoristaAutorizacaoList,
          motoristaJornadaTrabalhoList,
          empresa: {
            id: values.empresa,
          },
          idUsuarioAlteracao: storageX
            .whereKeyIs(STORAGE_KEY_CURRENT_USER)
            .get()?.id,
        };
      }

      console.log(values);

      await this.service.put<Driver>(this.driverId, "motorista", post);
      this.spinner.hide();

      this.router.navigate(["/drivers"]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public filterCompany() {
    var idEmpresa = this.empresaFilterSelected;
    var empresas = [];

    if (idEmpresa == "Divisão") {
      this.companies.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Matriz") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Filial") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    console.log(this.companiesFilter);
  }

  public coverterUpperCase(driverForm: FormGroup) {
    console.log(driverForm);

    if (driverForm.get("nmNome").value != null) {
      driverForm
        .get("nmNome")
        .setValue(driverForm.get("nmNome").value.toUpperCase());
    }

    if (driverForm.get("nmOrgaoEmissor").value != null) {
      driverForm
        .get("nmOrgaoEmissor")
        .setValue(driverForm.get("nmOrgaoEmissor").value.toUpperCase());
    }

    if (driverForm.get("cdCategoriaCnh").value != null) {
      driverForm
        .get("cdCategoriaCnh")
        .setValue(driverForm.get("cdCategoriaCnh").value.toUpperCase());
    }

    if (driverForm.get("dsObservacao").value != null) {
      driverForm
        .get("dsObservacao")
        .setValue(driverForm.get("dsObservacao").value.toUpperCase());
    }

    if (driverForm.get("cdIbutton").value != null) {
      driverForm
        .get("cdIbutton")
        .setValue(driverForm.get("cdIbutton").value.toUpperCase());
    }

    if (driverForm.get("txEndereco").value != null) {
      driverForm
        .get("txEndereco")
        .setValue(driverForm.get("txEndereco").value.toUpperCase());
    }

    if (driverForm.get("dsComplemento").value != null) {
      driverForm
        .get("dsComplemento")
        .setValue(driverForm.get("dsComplemento").value.toUpperCase());
    }

    if (driverForm.get("nmBairro").value != null) {
      driverForm
        .get("nmBairro")
        .setValue(driverForm.get("nmBairro").value.toUpperCase());
    }

    if (driverForm.get("nmCidade").value != null) {
      driverForm
        .get("nmCidade")
        .setValue(driverForm.get("nmCidade").value.toUpperCase());
    }

    if (driverForm.get("nmEstado").value != null) {
      driverForm
        .get("nmEstado")
        .setValue(driverForm.get("nmEstado").value.toUpperCase());
    }

    if (driverForm.get("nmPais").value != null) {
      driverForm
        .get("nmPais")
        .setValue(driverForm.get("nmPais").value.toUpperCase());
    }
  }
}
