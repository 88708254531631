import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-axle-six-dual-view",
  templateUrl: "./axle-six-dual-view.component.html",
  styleUrls: ["./axle-six-dual-view.component.scss"],
})
export class AxleSixDualViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorSixIntLeftSelect: any = [];
  public sensorSixIntRightSelect: any = [];

  public sensorSixExtLeftSelect: any = [];
  public sensorSixExtRightSelect: any = [];

  public sensorSixIntRightCombo: any = [];
  public sensorSixIntLeftCombo: any = [];

  public sensorSixExtRightCombo: any = [];
  public sensorSixExtLeftCombo: any = [];

  public axisSixDoubleLeftIntForm: FormGroup;
  public axisSixDoubleLeftExtForm: FormGroup;
  public axisSixDoubleRightIntForm: FormGroup;
  public axisSixDoubleRightExtForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisSixDoubleLeftIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisSixDoubleLeftExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisSixDoubleRightIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisSixDoubleRightExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisSixDoubleLeftIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 6 ESQ INT"
      );
      this.axisSixDoubleLeftExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 6 ESQ EXT"
      );

      this.axisSixDoubleRightIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 6 DIR INT"
      );
      this.axisSixDoubleRightExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 6 DIR EXT"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo6DireitoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 14) {
              eixo6DireitoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 14) {
              eixo6DireitoExt = filter;
            }
          }
        });
        var eixo6EsquerdoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 13) {
              eixo6EsquerdoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 13) {
              eixo6EsquerdoExt = filter;
            }
          }
        });
        var eixo6DireitoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 30) {
              eixo6DireitoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 30) {
              eixo6DireitoInt = filter;
            }
          }
        });
        var eixo6EsquerdoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 29) {
              eixo6EsquerdoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 29) {
              eixo6EsquerdoInt = filter;
            }
          }
        });

        // EIXO 6 DIREITO EXTERNO
        if (eixo6DireitoExt != null) {
          this.axisSixDoubleRightExtForm.controls["id"].setValue(
            eixo6DireitoExt.id
          );

          if (eixo6DireitoExt.sensor != null) {
            this.sensorSixExtRightCombo.push(eixo6DireitoExt.sensor);
            this.sensorSixExtRightSelect = eixo6DireitoExt.sensor.id;
          }

          if (eixo6DireitoExt.pneu != null) {
            this.axisSixDoubleRightExtForm.controls["pneu"].setValue(
              eixo6DireitoExt.pneu.numeroFogo
            );
          }

          this.axisSixDoubleRightExtForm.controls["nrPressaoMinima"].setValue(
            eixo6DireitoExt.nrPressaoMinima
          );

          this.axisSixDoubleRightExtForm.controls["nrPressaoMaxima"].setValue(
            eixo6DireitoExt.nrPressaoMaxima
          );

          this.axisSixDoubleRightExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo6DireitoExt.nrTemperaturaMaxima);
        }

        // EIXO 6 ESQUERDO EXTERNO
        if (eixo6EsquerdoExt != null) {
          this.axisSixDoubleLeftExtForm.controls["id"].setValue(
            eixo6EsquerdoExt.id
          );

          if (eixo6EsquerdoExt.sensor != null) {
            this.sensorSixExtLeftCombo.push(eixo6EsquerdoExt.sensor);
            this.sensorSixExtLeftSelect = eixo6EsquerdoExt.sensor.id;
          }

          if (eixo6EsquerdoExt.pneu != null) {
            this.axisSixDoubleLeftExtForm.controls["pneu"].setValue(
              eixo6EsquerdoExt.pneu.numeroFogo
            );
          }

          this.axisSixDoubleLeftExtForm.controls["nrPressaoMinima"].setValue(
            eixo6EsquerdoExt.nrPressaoMinima
          );

          this.axisSixDoubleLeftExtForm.controls["nrPressaoMaxima"].setValue(
            eixo6EsquerdoExt.nrPressaoMaxima
          );

          this.axisSixDoubleLeftExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo6EsquerdoExt.nrTemperaturaMaxima);
        }

        // EIXO 6 DIREITO INTERNO
        if (eixo6DireitoInt != null) {
          this.axisSixDoubleRightIntForm.controls["id"].setValue(
            eixo6DireitoInt.id
          );

          if (eixo6DireitoInt.sensor != null) {
            this.sensorSixIntRightCombo.push(eixo6DireitoInt.sensor);
            this.sensorSixIntRightSelect = eixo6DireitoInt.sensor.id;
          }

          if (eixo6DireitoInt.pneu != null) {
            this.axisSixDoubleRightIntForm.controls["pneu"].setValue(
              eixo6DireitoInt.pneu.numeroFogo
            );
          }

          this.axisSixDoubleRightIntForm.controls["nrPressaoMinima"].setValue(
            eixo6DireitoInt.nrPressaoMinima
          );

          this.axisSixDoubleRightIntForm.controls["nrPressaoMaxima"].setValue(
            eixo6DireitoInt.nrPressaoMaxima
          );

          this.axisSixDoubleRightIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo6DireitoInt.nrTemperaturaMaxima);
        }

        // EIXO 6 ESQUERDO INTERNO
        if (eixo6EsquerdoInt != null) {
          this.axisSixDoubleLeftIntForm.controls["id"].setValue(
            eixo6EsquerdoInt.id
          );

          if (eixo6EsquerdoInt.sensor != null) {
            this.sensorSixIntLeftCombo.push(eixo6EsquerdoInt.sensor);
            this.sensorSixIntLeftSelect = eixo6EsquerdoInt.sensor.id;
          }

          if (eixo6EsquerdoInt.pneu != null) {
            this.axisSixDoubleLeftIntForm.controls["pneu"].setValue(
              eixo6EsquerdoInt.pneu.numeroFogo
            );
          }

          this.axisSixDoubleLeftIntForm.controls["nrPressaoMinima"].setValue(
            eixo6EsquerdoInt.nrPressaoMinima
          );

          this.axisSixDoubleLeftIntForm.controls["nrPressaoMaxima"].setValue(
            eixo6EsquerdoInt.nrPressaoMaxima
          );

          this.axisSixDoubleLeftIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo6EsquerdoInt.nrTemperaturaMaxima);
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
