import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Chart } from "chart.js";
import { CockpitGraphicService } from "./cockpit-graphic.service";
import { interval } from "rxjs";

@Component({
  selector: "app-cockpit-graphic",
  templateUrl: "./cockpit-graphic.component.html",
  styleUrls: ["./cockpit-graphic.component.scss"],
})
export class CockpitGraphicComponent implements OnInit {
  public filterDays: any = [];
  valuesTypeGraphic: any[];

  public selectedDays;
  public valueDays = 30;
  public interval = interval(30000);

  public config = {
    type: "pie",
  };

  public todosEventosGraphic: Chart;
  public superAquecimentoMotorGraphic: Chart;
  public bateriaVeiculoDesconectadaGraphic: Chart;
  public motoristaNaoIdentificadoGraphic: Chart;
  public entrouCercaGraphic: Chart;
  public temperaturaIrregularPneuGraphic: Chart;
  public impactoVibracaoDetectadoGraphic: Chart;
  public possivelJammerDetectadoGraphic: Chart;
  public panicoAcionadoGraphic: Chart;
  public fimBatericaBackupGraphic: Chart;

  @ViewChild("todosEventos", { static: true }) todosEventos: ElementRef;

  @ViewChild("superAquecimentoMotor", { static: true })
  superAquecimentoMotor: ElementRef;

  @ViewChild("bateriaVeiculoDesconectada", { static: true })
  bateriaVeiculoDesconectada: ElementRef;

  @ViewChild("motoristaNaoIdentificado", { static: true })
  motoristaNaoIdentificado: ElementRef;

  @ViewChild("entrouCerca", { static: true })
  entrouCerca: ElementRef;

  @ViewChild("temperaturaIrregularPneu", { static: true })
  temperaturaIrregularPneu: ElementRef;

  @ViewChild("impactoVibracaoDetectado", { static: true })
  impactoVibracaoDetectado: ElementRef;

  @ViewChild("possivelJammerDetectado", { static: true })
  possivelJammerDetectado: ElementRef;

  @ViewChild("panicoAcionado", { static: true })
  panicoAcionado: ElementRef;

  @ViewChild("fimBatericaBackup", { static: true })
  fimBatericaBackup: ElementRef;

  constructor(private httpService: CockpitGraphicService) {}

  async ngOnInit(): Promise<void> {
    this.todosEventosGraphic = new Chart(
      this.todosEventos.nativeElement,
      this.config
    );

    this.superAquecimentoMotorGraphic = new Chart(
      this.superAquecimentoMotor.nativeElement,
      this.config
    );

    this.bateriaVeiculoDesconectadaGraphic = new Chart(
      this.bateriaVeiculoDesconectada.nativeElement,
      this.config
    );

    this.motoristaNaoIdentificadoGraphic = new Chart(
      this.motoristaNaoIdentificado.nativeElement,
      this.config
    );

    this.entrouCercaGraphic = new Chart(
      this.entrouCerca.nativeElement,
      this.config
    );

    this.temperaturaIrregularPneuGraphic = new Chart(
      this.temperaturaIrregularPneu.nativeElement,
      this.config
    );

    this.impactoVibracaoDetectadoGraphic = new Chart(
      this.impactoVibracaoDetectado.nativeElement,
      this.config
    );

    this.possivelJammerDetectadoGraphic = new Chart(
      this.possivelJammerDetectado.nativeElement,
      this.config
    );

    this.panicoAcionadoGraphic = new Chart(
      this.panicoAcionado.nativeElement,
      this.config
    );

    this.fimBatericaBackupGraphic = new Chart(
      this.fimBatericaBackup.nativeElement,
      this.config
    );

    this.valuesTypeGraphic = [
      { id: 75, value: "panicoAcionado" },
      { id: 79, value: "bateriaVeiculoDesconectada" },
      { id: 94, value: "fimBatericaBackup" },
      { id: 97, value: "impactoVibracaoDetectado" },
      { id: 100, value: "superAquecimentoMotor" },
      { id: 104, value: "entrouCerca" },
      { id: 121, value: "motoristaNaoIdentificado" },
      { id: 132, value: "possivelJammerDetectado" },
      { id: 140, value: "temperaturaIrregularPneu" },
    ];

    this.filterDays = [
      { name: "30 DIAS", value: 30 },
      { name: "15 DIAS", value: 15 },
      { name: "7 DIAS", value: 7 },
    ];

    this.selectedDays = this.filterDays[0];
    this.setGraphic(this.selectedDays.value);

    this.interval.subscribe((r) => {
      this.setGraphic(this.valueDays);
    });
  }

  public filterDaysTreated(): void {
    this.valueDays = this.selectedDays;
    this.setGraphic(this.valueDays);
  }

  public setGraphic(value) {
    this.httpService.event(value).subscribe((events) => {
      if (events.length > 0) {
        this.generalChart(events);
        this.outhersCharts(events);
      } else {
        //sem graficos
        this.generalChartEmpty();
        this.outhersChartsEmpty();
      }
    });
  }

  public generalChartEmpty() {
    let labels = ["Sem Dados"];

    let itensData = [1];

    const data = {
      labels: labels,
      datasets: [
        {
          data: itensData,
          backgroundColor: ["rgb(111, 120, 117)"],
        },
      ],
    };

    const config = {
      type: "pie",
      data: data,
    };

    this.todosEventosGraphic.config = config;
    this.todosEventosGraphic.update();
  }

  public generalChart(value) {
    var treated = [];
    var notTreated = [];
    let labels = ["Tratados", "Não Tratados"];

    value.filter((element) => {
      if (element.flFinalizado == null || element.flFinalizado == false) {
        notTreated.push(element);
      }
    });

    value.filter((element) => {
      if (element.flFinalizado == true) {
        treated.push(element);
      }
    });

    let itensData = [treated.length, notTreated.length];

    const data = {
      labels: labels,
      datasets: [
        {
          data: itensData,
          backgroundColor: ["rgb(76, 242, 70)", "rgb(224, 120, 22)"],
        },
      ],
    };

    const config = {
      type: "pie",
      data: data,
    };

    this.todosEventosGraphic.config = config;
    this.todosEventosGraphic.update();
  }

  public outhersChartsEmpty() {
    let labels = ["Sem Dados"];

    this.valuesTypeGraphic.forEach((element) => {
      let itensData = [1];

      const data = {
        labels: labels,
        datasets: [
          {
            data: itensData,
            backgroundColor: ["rgb(111, 120, 117)"],
          },
        ],
      };

      const config = {
        type: "pie",
        data: data,
      };

      var chart: Chart = this.chartFilter(element.value);
      chart.config = config;
      chart.update();
    });
  }

  public outhersCharts(value) {
    let labels = ["Tratados", "Não Tratados"];

    this.valuesTypeGraphic.forEach((element) => {
      var treated = [];
      var notTreated = [];
      value.filter((elementTreated) => {
        if (
          (elementTreated.flFinalizado == null ||
            elementTreated.flFinalizado == false) &&
          elementTreated.idTerminalAlarme == element.id
        ) {
          notTreated.push(element);
        }
      });

      value.filter((elementTreated) => {
        if (
          elementTreated.flFinalizado == true &&
          elementTreated.idTerminalAlarme == element.id
        ) {
          treated.push(element);
        }
      });

      if (treated.length == 0 && notTreated.length == 0) {
        var labelEmpty = ["Sem Dados"];
        let itensData = [1];

        const data = {
          labels: labelEmpty,
          datasets: [
            {
              data: itensData,
              backgroundColor: ["rgb(111, 120, 117)"],
            },
          ],
        };

        const config = {
          type: "pie",
          data: data,
        };

        var chart: Chart = this.chartFilter(element.value);
        chart.config = config;
        chart.update();
      } else {
        let itensData = [treated.length, notTreated.length];

        const data = {
          labels: labels,
          datasets: [
            {
              data: itensData,
              backgroundColor: ["rgb(76, 242, 70)", "rgb(224, 120, 22)"],
            },
          ],
        };

        const config = {
          type: "pie",
          data: data,
        };

        var chart: Chart = this.chartFilter(element.value);
        chart.config = config;
        chart.update();
      }
    });
  }

  public chartFilter(chart): Chart {
    if (chart == "panicoAcionado") {
      return this.panicoAcionadoGraphic;
    }

    if (chart == "bateriaVeiculoDesconectada") {
      return this.bateriaVeiculoDesconectadaGraphic;
    }

    if (chart == "fimBatericaBackup") {
      return this.fimBatericaBackupGraphic;
    }

    if (chart == "impactoVibracaoDetectado") {
      return this.impactoVibracaoDetectadoGraphic;
    }

    if (chart == "superAquecimentoMotor") {
      return this.superAquecimentoMotorGraphic;
    }

    if (chart == "entrouCerca") {
      return this.entrouCercaGraphic;
    }

    if (chart == "motoristaNaoIdentificado") {
      return this.motoristaNaoIdentificadoGraphic;
    }

    if (chart == "possivelJammerDetectado") {
      return this.possivelJammerDetectadoGraphic;
    }

    if (chart == "temperaturaIrregularPneu") {
      return this.temperaturaIrregularPneuGraphic;
    }

    if (chart == "todosEventos") {
      return this.todosEventosGraphic;
    }
  }
}
