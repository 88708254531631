import { Component, OnInit } from '@angular/core';
import {TransporterService} from '../../services/transporter.service';
import {TransporterCore} from './transporter.core';
import {FormBuilder} from '@angular/forms';
import {ApiBaseService} from '../../services/migrate/api.base.service';
import {VehiclesMenuService} from '../../services/vehicles-menu.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Transporter} from '../../models/transporter.model';
import {Router} from '@angular/router';
import {TransporterPaginated} from '../../models/transporter-paginated';
import {MatTableDataSource} from '@angular/material/table';
import {PermissionsService} from '../../helpers/permissions.service';
import {PageEvent} from '@angular/material/paginator';
import ApiService from '../../services/api.service';

@Component({
  selector: 'app-transporter',
  templateUrl: './transporter.component.html',
  styleUrls: ['./transporter.component.scss']
})
export class TransporterComponent extends TransporterCore implements OnInit {

  public transportersPage: TransporterPaginated;
  public transporters: MatTableDataSource<Transporter>;
  public tableColumns: string[];
  public actualPage: PageEvent;
  public permissions: [];
  public filter = '';

  constructor(formBuilder: FormBuilder,
              apiBaseService: ApiBaseService,
              apiService: ApiService,
              vehicleService: VehiclesMenuService,
              transporterService: TransporterService,
              spinner: NgxSpinnerService,
              public router: Router,
              public permissionService: PermissionsService) {
    super(formBuilder, apiBaseService, vehicleService, apiService, transporterService, spinner, router);
    this.transporterService = transporterService;
  }

  ngOnInit(): void {
    this.initialPage();
    this.loadTransporters(this.actualPage, null);
    this.loadTableDefinitions();
  }

  private loadTransporters(page: PageEvent, name: string): void {
    this.spinner.show();
    this.transporterService.getAll(page, name).subscribe((t) => {
      this.transportersPage = t;
      this.transporters = new MatTableDataSource<Transporter>(this.transportersPage.content);
      this.spinner.hide();
    }, (error) => {
      console.log('Erro ao obter os transportadores');
      this.spinner.hide();
    });
  }

  applyFilter(): void {
    this.loadTransporters(this.actualPage, this.filter);
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      'name',
      'cpf',
      'birthdate',
      'update',
      'clone',
      'view',
      'delete',
    ];
  }

  deleteTransporter(element): void {
    if (confirm('Tem certeza que deseja deletar este registro?')) {
        this.spinner.show();
        this.transporterService.delete(element.uuid).subscribe((v) => {
          console.log('Exclusão realizada com sucesso');
          this.loadTransporters(this.actualPage, this.filter);
          this.spinner.hide();
        }, (e) => {
          console.log('Erro ao excluir o transportador');
          this.spinner.hide();
          this.errorMessage = e.message;
          throw new EvalError(e);
        });
    }
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadTransporters(this.actualPage, this.filter);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }
}
