<div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

    <ng-container matColumnDef="operation">
      <th mat-header-cell *matHeaderCellDef>Operação</th>
      <td mat-cell *matCellDef="let element">{{element.operation}}</td>
    </ng-container>

    <ng-container matColumnDef="slide">
      <th mat-header-cell *matHeaderCellDef style="display: flex !important; justify-content: flex-end !important;">
        <mat-slide-toggle color="primary" (change)="onMasterToggleChange($event)" [checked]="hasAttributeFalse()"></mat-slide-toggle>
      </th>
      <td mat-cell *matCellDef="let element" style="display: flex !important; justify-content: flex-end !important;">
        <mat-slide-toggle color="primary" [name]="element.booleanAttribute" [checked]="dataValue[element.booleanAttribute]"
                          (change)="onSlaveToggleChange($event, element)">
        </mat-slide-toggle>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
