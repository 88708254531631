import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-axle-two-dual-view",
  templateUrl: "./axle-two-dual-view.component.html",
  styleUrls: ["./axle-two-dual-view.component.scss"],
})
export class AxleTwoDualViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorTwoIntLeftSelect: any = [];
  public sensorTwoIntRightSelect: any = [];

  public sensorTwoExtLeftSelect: any = [];
  public sensorTwoExtRightSelect: any = [];

  public sensorTwoIntRightCombo: any = [];
  public sensorTwoIntLeftCombo: any = [];

  public sensorTwoExtRightCombo: any = [];
  public sensorTwoExtLeftCombo: any = [];

  public axisTwoDoubleLeftIntForm: FormGroup;
  public axisTwoDoubleLeftExtForm: FormGroup;

  public axisTwoDoubleRightIntForm: FormGroup;
  public axisTwoDoubleRightExtForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisTwoDoubleLeftIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisTwoDoubleLeftExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisTwoDoubleRightIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisTwoDoubleRightExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisTwoDoubleLeftIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 2 ESQ INT"
      );
      this.axisTwoDoubleLeftExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 2 ESQ EXT"
      );

      this.axisTwoDoubleRightIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 2 DIR INT"
      );
      this.axisTwoDoubleRightExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 2 DIR EXT"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo2DireitoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 6) {
              eixo2DireitoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 6) {
              eixo2DireitoExt = filter;
            }
          }
        });

        var eixo2EsquerdoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 5) {
              eixo2EsquerdoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 5) {
              eixo2EsquerdoExt = filter;
            }
          }
        });

        var eixo2DireitoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 22) {
              eixo2DireitoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 22) {
              eixo2DireitoInt = filter;
            }
          }
        });

        var eixo2EsquerdoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 21) {
              eixo2EsquerdoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 21) {
              eixo2EsquerdoInt = filter;
            }
          }
        });

        // EIXO 2 DIR EXT
        if (eixo2DireitoExt != null) {
          this.axisTwoDoubleRightExtForm.controls["id"].setValue(
            eixo2DireitoExt.id
          );

          if (eixo2DireitoExt.sensor != null) {
            this.sensorTwoExtRightCombo.push(eixo2DireitoExt.sensor);
            this.sensorTwoExtRightSelect = eixo2DireitoExt.sensor.id;
          }

          if (eixo2DireitoExt.pneu != null) {
            this.axisTwoDoubleRightExtForm.controls["pneu"].setValue(
              eixo2DireitoExt.pneu.numeroFogo
            );
          }

          this.axisTwoDoubleRightExtForm.controls["nrPressaoMinima"].setValue(
            eixo2DireitoExt.nrPressaoMinima
          );

          this.axisTwoDoubleRightExtForm.controls["nrPressaoMaxima"].setValue(
            eixo2DireitoExt.nrPressaoMaxima
          );

          this.axisTwoDoubleRightExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo2DireitoExt.nrTemperaturaMaxima);
        }

        // EIXO 2 ESQ EXT
        if (eixo2EsquerdoExt != null) {
          this.axisTwoDoubleLeftExtForm.controls["id"].setValue(
            eixo2EsquerdoExt.id
          );

          if (eixo2EsquerdoExt.sensor != null) {
            this.sensorTwoExtLeftCombo.push(eixo2EsquerdoExt.sensor);
            this.sensorTwoExtLeftSelect = eixo2EsquerdoExt.sensor.id;
          }

          if (eixo2EsquerdoExt.pneu != null) {
            this.axisTwoDoubleLeftExtForm.controls["pneu"].setValue(
              eixo2EsquerdoExt.pneu.numeroFogo
            );
          }

          this.axisTwoDoubleLeftExtForm.controls["nrPressaoMinima"].setValue(
            eixo2EsquerdoExt.nrPressaoMinima
          );

          this.axisTwoDoubleLeftExtForm.controls["nrPressaoMaxima"].setValue(
            eixo2EsquerdoExt.nrPressaoMaxima
          );

          this.axisTwoDoubleLeftExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo2EsquerdoExt.nrTemperaturaMaxima);
        }

        // EIXO 2 DIR INT
        if (eixo2DireitoInt != null) {
          this.axisTwoDoubleRightIntForm.controls["id"].setValue(
            eixo2DireitoInt.id
          );

          if (eixo2DireitoInt.sensor != null) {
            this.sensorTwoIntRightCombo.push(eixo2DireitoInt.sensor);
            this.sensorTwoIntRightSelect = eixo2DireitoInt.sensor.id;
          }

          if (eixo2DireitoInt.pneu != null) {
            this.axisTwoDoubleRightIntForm.controls["pneu"].setValue(
              eixo2DireitoInt.pneu.numeroFogo
            );
          }

          this.axisTwoDoubleRightIntForm.controls["nrPressaoMinima"].setValue(
            eixo2DireitoInt.nrPressaoMinima
          );

          this.axisTwoDoubleRightIntForm.controls["nrPressaoMaxima"].setValue(
            eixo2DireitoInt.nrPressaoMaxima
          );

          this.axisTwoDoubleRightIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo2DireitoInt.nrTemperaturaMaxima);
        }

        // EIXO 2 ESQ INT
        if (eixo2EsquerdoInt != null) {
          this.axisTwoDoubleLeftIntForm.controls["id"].setValue(
            eixo2EsquerdoInt.id
          );

          if (eixo2EsquerdoInt.sensor != null) {
            this.sensorTwoIntLeftCombo.push(eixo2EsquerdoInt.sensor);
            this.sensorTwoIntLeftSelect = eixo2EsquerdoInt.sensor.id;
          }

          if (eixo2EsquerdoInt.pneu != null) {
            this.axisTwoDoubleLeftIntForm.controls["pneu"].setValue(
              eixo2EsquerdoInt.pneu.numeroFogo
            );
          }

          this.axisTwoDoubleLeftIntForm.controls["nrPressaoMinima"].setValue(
            eixo2EsquerdoInt.nrPressaoMinima
          );

          this.axisTwoDoubleLeftIntForm.controls["nrPressaoMaxima"].setValue(
            eixo2EsquerdoInt.nrPressaoMaxima
          );

          this.axisTwoDoubleLeftIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo2EsquerdoInt.nrTemperaturaMaxima);
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
