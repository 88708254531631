<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>Relatório Operacional Assíncrono</app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
              <div class="col-12">
                  <form [formGroup]="ReportOperationalForm" (ngSubmit)="submitForm()">
                      <h2 class="mb-3">Dados do Relatório</h2>
                        <div class="form-row" >
                            <div class="form-group col-4">
                              <mat-form-field appearance="outline">
                                <mat-label>Data Inicio</mat-label>
                                <input formControlName="dateStart" type="datetime-local" matInput placeholder="Data Inicio" required />
                              </mat-form-field>
                            </div>
                            <div class="form-group col-4">
                              <mat-form-field appearance="outline">
                                <mat-label>Data Fim</mat-label>
                                <input formControlName="dateEnd" type="datetime-local" matInput placeholder="Data Fim" required />
                              </mat-form-field>
                            </div>
                            <div class="form-group col-md-4">
                              <mat-form-field appearance="outline">
                                <mat-label>Tipo de Relatório</mat-label>
                                  <mat-select formControlName="typesReport" [(value)]="typesReportSelected" [(value)]="typesReportSelected" required>
                                      <mat-option *ngFor="let type of reportTypeEngineModelsPaginated" [value]="type.uuid">
                                      {{ type.name }} {{ type.report_template == 'SYNTHETIC' ? '(Sintético)' :  '(Análitico)'}}
                                      </mat-option> 
                                  </mat-select> 
                              </mat-form-field>
                            </div>
                        </div>
                        <div class="form-row" >
                          <div class="form-group col-md-3">
                            <mat-form-field appearance="outline">
                              <mat-label>Modelo de Empresa</mat-label>
                              <mat-select formControlName="selectCompany" [(value)]="companyFilterSelected" [(ngModel)]="companyFilterSelected"
                              (selectionChange)="this.filterCompany()" required>
                                <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                                  {{ type.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="form-group col-md-3">
                            <mat-form-field appearance="outline">
                              <mat-label>Empresa</mat-label>
                              <input type="text" matInput [formControl]="controlCompany" [matAutocomplete]="autoEmpresa" required>                     
                                <mat-autocomplete #autoEmpresa="matAutocomplete" [displayWith]="displayCompany">
                                    <mat-option *ngFor="let type of this.companiesFilter" [value]="type">
                                        {{type.nmNome}}
                                    </mat-option>
                                </mat-autocomplete>                       
                              </mat-form-field>
                          </div>
                          <div class="form-group col-md-3">
                            <mat-form-field appearance="outline" *ngIf="controlCompany.value != ''">
                                <mat-label>Placa / Frota</mat-label >
                                <input type="text" formControlName="terminalId" matInput [formControl]="controlVehicle" [matAutocomplete]="autoTerminal">                     
                                <mat-autocomplete #autoTerminal="matAutocomplete" [displayWith]="displayTerminal">
                                    <mat-option *ngFor="let type of this.vehiclesFiltered | async" [value]="type">
                                        {{type.cdPlaca}}/{{type.frota}}
                                    </mat-option>
                                </mat-autocomplete>                       
                            </mat-form-field>            
                         </div>
                          <div class="form-group col-md-3">
                              <mat-form-field appearance="outline" *ngIf="controlCompany.value != ''">
                                  <mat-label>Motorista</mat-label>
                                  <input type="text" formControlName="driverName"  matInput [formControl]="controlDriver" [matAutocomplete]="autoMotorista">                     
                                  <mat-autocomplete #autoMotorista="matAutocomplete" [displayWith]="displayDriver">
                                       <mat-option *ngFor="let type of this.driversFiltered| async" [value]="type">
                                          {{type.nmNome}}
                                      </mat-option>
                                  </mat-autocomplete>                       
                              </mat-form-field>            
                          </div>
                        </div>

                        <div class="form-row" >
                          <div class="form-group col-4">
                            <mat-checkbox formControlName="gpsJumps" >Remover o salto GPS ?</mat-checkbox>
                          </div>
                          <div class="form-group col-4">
                            <mat-checkbox formControlName="exportPdf" >Gravar arquivo em PDF ?</mat-checkbox>
                          </div>
                          <div class="form-group col-4">
                            <mat-checkbox formControlName="exportExcel" >Gravar arquivo em EXCEL ?</mat-checkbox>
                          </div>
                        </div>
                        

                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit" [disabled]="!ReportOperationalForm.valid"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Gerar Relatório
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
