<div class="main-page config">
  <div class="container-fluid">
    <!-- LINHA TITULO -->
    <div class="row align-items-center">
      <div class="col-12 my-2">
        <h1>Configurações de usuário</h1>
      </div>
    </div>
    <!-- ITEM CARDS -->
    <!-- <div class="row mt-3 no-gutters p-4 row-form">
      <div class="col-12">
        <h2 class="mb-3">Dados da conta</h2>
        <div class="row align-items-center">
          <div class="col-lg-9 order-1 order-lg-0 my-2 my-lg-0">
            <div class="form-row">
              <div class="form-group col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome completo"
                  [(ngModel)]="nomeCompleto"
                />
              </div>
              <div class="form-group col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome de usuário"
                  [(ngModel)]="nomeUsuario"
                />
              </div>
              <div class="form-group col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="E-mail"
                  [(ngModel)]="emailUsuario"
                />
              </div>
              <div class="form-group col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Número Matrícula"
                  [(ngModel)]="matriculaUsuario"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group mt-2 mb-0">
          <button
            type="button"
            class="btn btn-salvar my-2 my-md-0"
            (click)="submitForm()"
          >
            Salvar alterações
          </button>
        </div>
      </div>
    </div>  -->

    <div class="row mt-5 no-gutters p-4 row-form">
      <div class="col-12">
        <form
          [formGroup]="updatePasswordForm"
          (ngSubmit)="submitUpdatePassword()"
        >
          <h2 class="mb-3">Alterar senha</h2>
          <div class="form-row">
            <div class="form-group col-lg-4">
              <input
                formControlName="cdSenhaAnterior"
                type="password"
                class="form-control"
                placeholder="Senha atual"
              />
            </div>
            <div class="form-group col-lg-4">
              <input
                formControlName="cdSenhanova"
                type="password"
                class="form-control"
                placeholder="Nova senha"
              />
            </div>
          </div>
          <div *ngIf="updatePasswordErrorMessage" class="alert alert-danger">
            {{ updatePasswordErrorMessage }}
          </div>

          <div class="form-group mt-2 mb-0">
            <button
              type="submit"
              [disabled]="!updatePasswordForm.valid || updatePasswordLoader"
              class="btn btn-salvar my-2 my-md-0"
            >
              Salvar alterações
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
