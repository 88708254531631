// tslint:disable: no-unused-expression
import { Injectable } from "@angular/core";
import _ from "lodash";
import {
  STORAGE_KEY_PATHS,
  STORAGE_KEY_PERMISSIONS,
} from "../storageCore/constStorageKeys";
import storageX from "../storageCore/storageX";

@Injectable({
  providedIn: "root",
})
export class PermissionsService {
  constructor() {}

  getPermissions = () => {
    return storageX.whereKeyIs(STORAGE_KEY_PERMISSIONS).get(); // JSON.parse(localStorage.getItem('permissions'));
  };

  getPaths = () => {
    return storageX.whereKeyIs(STORAGE_KEY_PATHS).get(); //JSON.parse(localStorage.getItem('paths'));
  };

  hasPermission = (permission, permissionStorage?) => {
    const permissions = permissionStorage
      ? permissionStorage
      : storageX.whereKeyIs(STORAGE_KEY_PERMISSIONS).get(); //JSON.parse(localStorage.getItem('permissions'));

    if (!permissions) {
      return false;
    }

    return _.includes(permissions, permission);
  };

  hasPath = (path, pathStorage?) => {
    const paths = pathStorage
      ? pathStorage
      : storageX.whereKeyIs(STORAGE_KEY_PATHS).get(); //JSON.parse(localStorage.getItem('paths'));

    if (!paths) {
      return false;
    }

    return _.includes(paths, path);
  };
}
