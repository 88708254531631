import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import lodash from "lodash";
import { Route } from "../../models/Route.model";
import { ApiService } from "../../services/api.service";
import { Validation } from "./validation";
import { NgxSpinnerService } from "ngx-spinner";
import storageX from "../../storageCore/storageX";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "../../storageCore/constStorageKeys";
import { ThemePalette } from "@angular/material/core";
import { Company } from "src/app/models/Company.model";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
  idEmpresa: number;
}

@Component({
  selector: "app-create-route",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class EditRouteComponent implements OnInit {
  public routeForm: FormGroup;
  public route: Route;
  public routeId: number;
  public title = "Atualizar Rota";
  public errorMessage;
  private service: ApiService;
  private validation: Validation;
  public vehicles = [];

  public empresaSelected;
  public companyId;

  public empresaEdit;

  public company: any = [];
  public errorDebug;
  public companyForm;

  constructor(
    private apiService: ApiService,
    private serviceApiBase: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public activatedRoute: ActivatedRoute
  ) {
    this.service = apiService;
    this.validation = new Validation();
  }

  task: Task = {
    name: "Todos",
    completed: false,
    color: "primary",
    subtasks: [],
    idEmpresa: 0,
  };
  markers = [];

  ngOnInit(): void {
    this.routeForm = this.formBuilder.group(
      {
        nmNome: ["", Validators.required],
        nrDistanciaTolerada: ["", Validators.required],
        nrLimiteVelocidade: ["", Validators.required],
        flAtivoPontoControle: ["", Validators.required],
        empresa: ["", Validators.required],
      },
      { updateOn: "blur" }
    );
    this.routeId = this.activatedRoute.snapshot.params["id"];

    this.loadRoute(this.routeId);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  async setup(): Promise<void> {
    try {
      this.spinner.show();

      const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();

      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      const result = await this.serviceApiBase.get<any>({
        url: new Company()["nomeConsultaApiBaseGetAll"],
        params,
      });

      this.company = result["content"];

      console.log(this.company);

      this.fillUserData();

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  fillUserData(): void {
    this.empresaSelected = this.route.empresa.id;

    if (this.empresaSelected) {
      this.routeForm.controls["empresa"].setValue(this.empresaSelected);
    }

    this.company.forEach((element) => {
      if (element.id == this.empresaSelected) {
        if (element.cnpj == null) {
          this.empresaEdit = "Divisão";
        }

        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          this.empresaEdit = "Filial";
        }

        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          this.empresaEdit = "Matriz";
        }
      }
    });

    const fields = [
      "nmNome",
      "nrDistanciaTolerada",
      "nrLimiteVelocidade",
      "flAtivoPontoControle",
    ];

    fields.forEach((element) => {
      this.routeForm.controls[element].setValue(this.route[element]);
    });
  }

  public async loadRoute(id): Promise<void> {
    try {
      this.spinner.show();
      const response = await this.service.get({
        url: `rota/${id}`,
      });
      this.route = response as Route;
      this.spinner.hide();
      //this.loadMarkers();
      this.setup();
      this.loadMarkersFilter();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public async submitForm(): Promise<void> {
    try {
      this.spinner.show();
      const values = this.routeForm.value;
      const id = Math.floor(this.routeId);

      console.log(values);

      let elements = [];
      const itens = this.task.subtasks.filter((y) => y.completed == true);

      itens.forEach((action) => {
        const { id_terminal } = this.markers.find(
          (x) => x.placa == action.name
        );
        elements.push(id_terminal);
      });

      var post;

      if (this.empresaEdit != "Divisão") {
        post = {
          id,
          ...values,
          empresa: { id: this.empresaSelected },
          dsPosicoes: this.route.dsPosicoes,
          flAtivoPontoControle: this.routeForm.value.flAtivoPontoControle,
          nrLimiteVelocidade: this.routeForm.value.nrLimiteVelocidade,
          nrDistanciaTolerada: this.routeForm.value.nrDistanciaTolerada,
          flAtivo: true,
          terminalRotaList:
            elements.length == 0
              ? null
              : elements.map((element) => {
                  return {
                    idTerminal: element,
                  };
                }),
          idUsuarioAlteracao: storageX
            .whereKeyIs(STORAGE_KEY_CURRENT_USER)
            .get()?.id,
        };
      } else {
        post = {
          id,
          ...values,
          empresa: { id: null },
          divisao: { id: this.empresaSelected },
          dsPosicoes: this.route.dsPosicoes,
          flAtivoPontoControle: this.routeForm.value.flAtivoPontoControle,
          nrLimiteVelocidade: this.routeForm.value.nrLimiteVelocidade,
          nrDistanciaTolerada: this.routeForm.value.nrDistanciaTolerada,
          flAtivo: true,
          terminalRotaList:
            elements.length == 0
              ? null
              : elements.map((element) => {
                  return {
                    idTerminal: element,
                  };
                }),
          idUsuarioAlteracao: storageX
            .whereKeyIs(STORAGE_KEY_CURRENT_USER)
            .get()?.id,
        };
      }

      await this.service.put<Route>(this.routeId, "rota", post);

      this.spinner.hide();
      this.router.navigate(["/routes"]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  listaDePlacas = new Array<any>();
  eletric_fence_payload = new Array();
  allComplete: boolean = false;

  public updateAllComplete() {
    this.allComplete =
      this.task.subtasks != null &&
      this.task.subtasks.every((t) => t.completed);
  }

  public someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return (
      this.task.subtasks.filter((t) => t.completed).length > 0 &&
      !this.allComplete
    );
  }

  public setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach((t) => (t.completed = completed));
  }

  public handleFilter(event: string) {
    let query = "";

    if (event) {
      query = event.toLocaleUpperCase();
    }

    this.task.subtasks = this.listaDePlacas.filter(({ name }) =>
      name.match(new RegExp("(" + query + ")"))
    );
  }

  // public async loadMarkers(): Promise<void> {
  //   try {
  //     const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(); //localStorage.getItem('idPerfil');
  //     if (!perfil) {
  //       throw new Error("Nenhum perfil encontrado");
  //     }
  //     const url = `posicao-atual-terminal/perfil/${perfil}`;
  //     const source = await this.apiService.get<any[]>({
  //       url,
  //     });

  //     if (!source) {
  //       throw new Error("Nenhum result encontrado");
  //     }

  //     const result = this.buildPosicaoAtualTerminalList(source);

  //     result.forEach((e) => {
  //       this.task.subtasks.push({
  //         name: e.placa,
  //         completed: false,
  //         color: "primary",
  //       });
  //       this.listaDePlacas.push({
  //         name: e.placa,
  //         completed: false,
  //         color: "primary",
  //       });
  //     });

  //     this.markers = result;

  //     this.route.terminalRotaList.forEach((action) => {
  //       const itens = this.task.subtasks;

  //       itens.forEach((action2) => {
  //         const { idTerminal } = this.markers.find(
  //           (x) => x.placa == action2.name
  //         );
  //         if (idTerminal == action.idTerminal) {
  //           action2.completed = true;
  //         }
  //       });
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  private buildPosicaoAtualTerminalList(source: Array<any>): Array<any> {
    const items = source.map((e) => e.posicaoAtualTerminalList);

    const itemsChildren = source
      .map((value) => value.children.map((e) => e.posicaoAtualTerminalList))
      .flat();

    const itemsChildrenInChildren = source
      .map((value) => value.children.map((e) => e.children))
      .flat();

    itemsChildrenInChildren.forEach((e) => {
      if (e.length > 0) {
        e.forEach((element) => {
          itemsChildren.push(element.posicaoAtualTerminalList);
        });
      }
    });

    return lodash.flattenDeep([...items, ...itemsChildren]);
  }

  public async loadMarkersFilter(): Promise<void> {
    try {
      this.spinner.show();
      var url: any;
      var source: any;
      console.log(this.route.empresa);
      if (this.route.empresa == null) {
        url = `posicao-atual-terminal/idDivisao/${this.route.divisao.id}`;
        source = await this.apiService.get<any[]>({
          url,
        });
      } else {
        url = `posicao-atual-terminal/idEmpresa/${this.route.empresa.id}`;
        source = await this.apiService.get<any[]>({
          url,
        });
      }

      this.task.subtasks = [];

      if (!source) {
        throw new Error("Nenhum result encontrado");
      }

      source.forEach((e) => {
        if (e.id_empresa == null) {
          if (this.route.divisao.id == e.id_divisao) {
            this.task.subtasks.push({
              name: e.placa,
              completed: false,
              color: "primary",
              idEmpresa: e.id_divisao,
            });
            this.listaDePlacas.push({
              name: e.placa,
              completed: false,
              color: "primary",
              idEmpresa: e.id_divisao,
            });
          }
        } else {
          if (this.route.empresa.id == e.id_empresa) {
            this.task.subtasks.push({
              name: e.placa,
              completed: false,
              color: "primary",
              idEmpresa: e.id_empresa,
            });
            this.listaDePlacas.push({
              name: e.placa,
              completed: false,
              color: "primary",
              idEmpresa: e.id_empresa,
            });
          }
        }
      });
      this.markers = source;
    } catch (error) {
      console.error(error);
    }
  }
}
