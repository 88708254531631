import { Component } from '@angular/core';
import { ToolboxControl } from './toolbox_control';

@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.scss'],
})
export class ToolboxComponent {
  constructor(public toolboxControl: ToolboxControl){}
}
