import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NgxSpinnerService } from "ngx-spinner";
import { Pneu } from "../../models/Pneu.model";
import storageX from "../../storageCore/storageX";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "../../storageCore/constStorageKeys";
import { ApiBaseService } from "../../services/migrate/api.base.service";
import { Company } from "src/app/models/Company.model";

@Component({
  selector: "app-create-pneu",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ClonePneuComponent implements OnInit {
  public pneuForm: FormGroup;
  public pneu: Pneu;
  public pneuId: number;
  public pneuIdClone: number;
  public title = "Duplicar Pneu";
  public errorMessage;
  private apiBaseService: ApiBaseService;

  public empresaSelected;
  public empresaFilterSelected;

  public companies: any = [];
  public companiesFilter: any = [];
  public companiesSelect: any = [
    { name: "Divisão", value: 1 },
    { name: "Matriz", value: 2 },
    { name: "Filial", value: 3 },
  ];

  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.apiBaseService = apiBaseService;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.pneuForm = this.formBuilder.group(
      {
        idEmpresa: ["", Validators.required],
        selecaoEmpresa: ["", Validators.required],
        marca: ["", Validators.required],
        tamanho: ["", Validators.required],
        numeroFogo: ["", Validators.required],
        kmEntrada: [""],
        kmSaida: [""],
        observacao: [""],
      },
      { updateOn: "blur" }
    );
    this.pneuIdClone = this.activatedRoute.snapshot.params["id"];
    this.loadPneu(this.pneuIdClone);
  }

  public async loadPneu(id): Promise<void> {
    try {
      this.spinner.show();

      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      const result = await this.apiBaseService.get<any>({
        url: new Company()["nomeConsultaApiBaseGetAll"],
        params,
      });

      this.companies = result["content"];

      const response = await this.apiBaseService.get<Pneu>({
        url: new Pneu()["nomeConsultaApiBase"] + "/" + id,
      });
      this.pneu = response as Pneu;

      if (this.pneu.empresa != null) {
        if (this.pneu.empresa.empresaMatriz == null) {
          this.empresaFilterSelected = "Matriz";
        } else {
          this.empresaFilterSelected = "Filial";
        }
      } else {
        this.empresaFilterSelected = "Divisão";
      }

      this.filterCompany();

      if (this.pneu.empresa != null) {
        this.pneuForm.controls["idEmpresa"].setValue(this.pneu.empresa.id);
      } else {
        this.pneuForm.controls["idEmpresa"].setValue(this.pneu.divisao.id);
      }

      this.fillUserData();
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  fillUserData(): void {
    const fields = [
      "numeroFogo",
      "marca",
      "tamanho",
      "observacao",
      "kmEntrada",
      "kmSaida",
    ];
    fields.forEach((element) => {
      this.pneuForm.controls[element].setValue(this.pneu[element]);
    });
  }

  public filterCompany() {
    var idEmpresa = this.empresaFilterSelected;
    this.pneuForm.controls["idEmpresa"].setValue(null);
    var empresas = [];

    if (idEmpresa == "Divisão") {
      this.companies.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Matriz") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Filial") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    console.log(this.companiesFilter);
  }

  public async submitForm(): Promise<void> {
    try {
      this.spinner.show();
      this.coverterUpperCase(this.pneuForm);

      const values = this.pneuForm.value;
      var post;

      if (this.empresaFilterSelected != "Divisão") {
        post = {
          ...values,
          empresa: {
            id: parseInt(values.idEmpresa),
          },
          divisao: null,
          idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
        };
      } else {
        post = {
          ...values,
          divisao: {
            id: parseInt(values.idEmpresa),
          },
          empresa: null,
          idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
        };
      }

      const result = await this.apiBaseService.post<Pneu>(
        new Pneu()["nomeConsultaApiBase"],
        post
      );

      console.log(result);
      this.spinner.hide();
      this.router.navigate(["/pneu/edit/" + result.id]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public coverterUpperCase(pneuForm: FormGroup) {
    pneuForm.get("marca").setValue(pneuForm.get("marca").value.toUpperCase());

    pneuForm
      .get("tamanho")
      .setValue(pneuForm.get("tamanho").value.toUpperCase());

    pneuForm
      .get("numeroFogo")
      .setValue(pneuForm.get("numeroFogo").value.toUpperCase());

    pneuForm
      .get("observacao")
      .setValue(pneuForm.get("observacao").value.toUpperCase());
  }
}
