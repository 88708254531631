<div class="main-page cadastros">
  <div class="container-fluid">
    <app-title>{{ title ? title : 'Inserir novo' }} </app-title>
    <mat-tab-group>
      <!-- *ngxPermissionsOnly="['PERFIL_INSERCAO']" -->
      <mat-tab label="Dados">
        <ng-template matTabContent>
          <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
              <form [formGroup]="perfilForm" >
                <h2 class="mb-3">Novo Perfil</h2>
                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Nome do Perfil</mat-label>
                      <input autofocus formControlName="nmPerfil" matInput placeholder="Nome do Perfil"
                        maxlength="200"  [readonly]=true [disabled]=true/>
                    </mat-form-field>

                    <validation-errors [form]="perfilForm" controlLabel="Perfil" controlName="nmPerfil">
                    </validation-errors>
                  </div>
                  <div class="form-group col-lg-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Desc. Perfil</mat-label>
                      <input formControlName="dsPerfil" matInput placeholder="Descrição do Perfil" maxlength="200"  [readonly]=true [disabled]=true/>
                    </mat-form-field>

                    <validation-errors [form]="perfilForm" controlLabel="Perfil" controlName="dsPerfil">
                    </validation-errors>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-lg-12">
                    <mat-form-field>
                      <mat-label>Tipo Perfil</mat-label>

                      <mat-select formControlName="perfilTipo" [(value)]="perfilTipoSelected" [readonly]=true [disabled]=true>
                        <mat-option *ngFor="let type of types" [value]="type.id">
                          {{ type.nmPerfilTipo }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <!-- *ngxPermissionsOnly="['PERFIL_FUNCIONALIDADE_INSERCAO']" -->
      <mat-tab label="Funcionalidade" [disabled]="!perfilId">
        <ng-template matTabContent>
          <!--[isDetail]="isDetail" -->
          <app-form-aba-funcionalidade [perfilId]="perfilId">
          </app-form-aba-funcionalidade>
        </ng-template>
      </mat-tab>
      <!-- *ngxPermissionsOnly="['PERFIL_FUNCIONALIDADE_INSERCAO']" -->
      <mat-tab label="Empresa" [disabled]="!perfilId">
        <ng-template matTabContent>
          <!--[isDetail]="isDetail" -->
          <app-form-aba-empresa [perfilId]="perfilId">
          </app-form-aba-empresa>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
