import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { TransporterCore } from "./transporter.core";
import { ApiBaseService } from "../../services/migrate/api.base.service";
import { TransporterService } from "../../services/transporter.service";
import { NgxSpinnerService } from "ngx-spinner";
import { VehiclesMenuService } from "../../services/vehicles-menu.service";
import ApiService from "../../services/api.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-create-transporter",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CreateTransporterComponent
  extends TransporterCore
  implements OnInit
{
  public title = "Cadastrar Transportador/Agregado";
  public formMode: any;
  public titleVar = "";
  public registrationTypes: Array<any>;
  public companies: Array<any>;
  public companiesFiltered: Array<any>;
  public hide = true;

  constructor(
    formBuilder: FormBuilder,
    apiBaseService: ApiBaseService,
    apiService: ApiService,
    vehicleService: VehiclesMenuService,
    transporterService: TransporterService,
    spinner: NgxSpinnerService,
    router: Router
  ) {
    super(
      formBuilder,
      apiBaseService,
      vehicleService,
      apiService,
      transporterService,
      spinner,
      router
    );
    this.transporterService = transporterService;
    this.transporterFormGroup = super.loadFormDefinition();
    this.userFormGroup = super.loadUserFormDefinition();
  }

  ngOnInit(): void {
    this.formMode = "create";
    this.registrationTypes = this.getRegistrationType();
    this.loadCompanies();
  }

  submitForm(): void {
    this.createTransporter();
  }
}
