import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Perfil } from "../../models/Perfil.model";
import { Validation } from "./validation";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { PerfilTipo } from "src/app/models/PerfilTipo.model";

@Component({
  selector: "app-create-perfil",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CreatePerfilComponent implements OnInit {
  private apiBaseService: ApiBaseService;

  public perfilForm: FormGroup;
  public perfilId: number;
  public perfilTipoSelected;
  public title = "Cadastrar Perfil";
  public errorMessage;
  private validation: Validation;
  public types: any = [];
  public ngClassStepper = "stepper-click-desabled";

  public pageSize = 10;

  ngOnInit(): void {
    this.perfilForm = this.formBuilder.group(this.validation.rules());
    this.setup();
  }
  async setup(): Promise<void> {
    try {
      this.spinner.show();
      var pageIndex = 0;

      const params = {
        size: this.pageSize,
        page: pageIndex,
      };
      const types = await this.apiBaseService.get<PerfilTipo>({
        url: new PerfilTipo()["nomeConsultaApiBase"],
        params,
      });
      this.types = types["content"];

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }

  public async submitForm(stepper?): Promise<void> {
    this.errorMessage = null;
    this.spinner.show();

    try {
      this.coverterUpperCase(this.perfilForm);
      const values = this.perfilForm.value;
      const post = {
        ...values,
        perfilTipo: { id: parseInt(values.perfilTipo) },
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      const result = await this.apiBaseService.post<Perfil>(
        new Perfil()["nomeConsultaApiBase"],
        post
      );

      this.perfilId = result?.id;

      this.ngClassStepper = "stepper-click-enable";
      this.spinner.hide();

      stepper.next();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public coverterUpperCase(perfilForm: FormGroup) {
    if (perfilForm.get("nmPerfil").value != null) {
      perfilForm
        .get("nmPerfil")
        .setValue(perfilForm.get("nmPerfil").value.toUpperCase());
    }

    if (perfilForm.get("dsPerfil").value != null) {
      perfilForm
        .get("dsPerfil")
        .setValue(perfilForm.get("dsPerfil").value.toUpperCase());
    }
  }
}
