export const environment = {
  production: false,
  environment: "hml",
  apiBaseUrlOAuth: "https://api-hml.hgdigital.io",
  apiUrl: "https://api-hml.hgdigital.io/setera-api/v1",
  api: "https://api-hml.hgdigital.io/",

  othersUrl: {
    api: "https://api-hml.hgdigital.io/",
    core: "https://api-hml.hgdigital.io/setera-core/v1",
    reports: "https://api-hml.hgdigital.io/setera-reports/v1",
    events: "https://api-hml.hgdigital.io/setera-events/v1",
    notify: "https://api-hml.hgdigital.io/setera-notify/v1/notifys",
  },

  empresaId: 6872,

  apiKeyFipe: "d72b569603a805c199beb37a9928f35d",
  clientIdSensedia: "641f6a33-6f75-35ab-90a6-67657f2ecf46",
  clientSecretSensedia: "b82b92da-5f0b-3ede-849a-dd3ac13e026d",
  googleApiGeoLocate: "https://www.googleapis.com/geolocation/v1/geolocate",
  googleApiGeoLocateKey: "AIzaSyB1nFyiEouQVDhO2i6Dt-yj_EMCK5tCuV0",
  apibase: "https://api-hml.hgdigital.io/setera-api-base/v1",
  apiMapBox:
    "pk.eyJ1IjoicmVuYXRvcGdvbWVzIiwiYSI6ImNsMDJvMnEyMzEydGYzaW8wZWhxbzN5eGoifQ.4xlmxw4zg5HeGe2b0ovW5g",
  // Configuração das novas apis, seguindo o modelo dos novos projetos e com suporte a HATEOAS
  apisConfig: {
    seteraFleetApi: {
      microServiceBaseUrl: "https://api-hml.hgdigital.io/",
      apiBaseUrl: "https://api-hml.hgdigital.io/setera-fleet/v1/",
    },
    seteraTrackingHardwareApi: {
      microServiceBaseUrl: "https://api-hml.hgdigital.io/",
      apiBaseUrl: "https://api-hml.hgdigital.io/setera-tracking-hardware/v1/",
    },
    seteraReportEngine: {
      microServiceBaseUrl: "https://api-hml.hgdigital.io/",
      apiBaseUrl: "https://api-hml.hgdigital.io/setera-report-engine/v1/",
    },
    seteraAnalyzeTrip: {
      microServiceBaseUrl: "https://api-hml.hgdigital.io/",
      apiBaseUrl: "https://api-hml.hgdigital.io/setera-analyze-trip/v1/",
    },
    gateway: {
      baseOauthUrl: "https://api-manager.hgdigital.io/oauth2",
      clientId: "8joUf_wrLXuGLEubEyvVZGfoKSsa",
      clientSecret: "u_kgidyDT8QeREETjyJHdCLc1goa",
    },
  },
};
