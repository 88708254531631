import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-axle-seven-dual-view",
  templateUrl: "./axle-seven-dual-view.component.html",
  styleUrls: ["./axle-seven-dual-view.component.scss"],
})
export class AxleSevenDualViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorSevenIntLeftSelect: any = [];
  public sensorSevenIntRightSelect: any = [];

  public sensorSevenExtLeftSelect: any = [];
  public sensorSevenExtRightSelect: any = [];

  public sensorSevenIntRightCombo: any = [];
  public sensorSevenIntLeftCombo: any = [];

  public sensorSevenExtRightCombo: any = [];
  public sensorSevenExtLeftCombo: any = [];

  public axisSevenDoubleLeftIntForm: FormGroup;
  public axisSevenDoubleLeftExtForm: FormGroup;
  public axisSevenDoubleRightIntForm: FormGroup;
  public axisSevenDoubleRightExtForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisSevenDoubleLeftIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisSevenDoubleLeftExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisSevenDoubleRightIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisSevenDoubleRightExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );
    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisSevenDoubleLeftIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 7 ESQ INT"
      );
      this.axisSevenDoubleLeftExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 7 ESQ EXT"
      );

      this.axisSevenDoubleRightIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 7 DIR INT"
      );
      this.axisSevenDoubleRightExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 7 DIR EXT"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo7DireitoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 16) {
              eixo7DireitoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 16) {
              eixo7DireitoExt = filter;
            }
          }
        });
        var eixo7EsquerdoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 15) {
              eixo7EsquerdoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 15) {
              eixo7EsquerdoExt = filter;
            }
          }
        });
        var eixo7DireitoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 32) {
              eixo7DireitoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 32) {
              eixo7DireitoInt = filter;
            }
          }
        });
        var eixo7EsquerdoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 31) {
              eixo7EsquerdoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 31) {
              eixo7EsquerdoInt = filter;
            }
          }
        });

        // EIXO 7 DIREITO EXTERNO
        if (eixo7DireitoExt != null) {
          this.axisSevenDoubleRightExtForm.controls["id"].setValue(
            eixo7DireitoExt.id
          );

          if (eixo7DireitoExt.sensor != null) {
            this.sensorSevenExtRightCombo.push(eixo7DireitoExt.sensor);
            this.sensorSevenExtRightSelect = eixo7DireitoExt.sensor.id;
          }

          if (eixo7DireitoExt.pneu != null) {
            this.axisSevenDoubleRightExtForm.controls["pneu"].setValue(
              eixo7DireitoExt.pneu.numeroFogo
            );
          }

          this.axisSevenDoubleRightExtForm.controls["nrPressaoMinima"].setValue(
            eixo7DireitoExt.nrPressaoMinima
          );

          this.axisSevenDoubleRightExtForm.controls["nrPressaoMaxima"].setValue(
            eixo7DireitoExt.nrPressaoMaxima
          );

          this.axisSevenDoubleRightExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo7DireitoExt.nrTemperaturaMaxima);
        }

        // EIXO 7 ESQUERDO EXTERNO
        if (eixo7EsquerdoExt != null) {
          this.axisSevenDoubleLeftExtForm.controls["id"].setValue(
            eixo7EsquerdoExt.id
          );

          if (eixo7EsquerdoExt.sensor != null) {
            this.sensorSevenExtLeftCombo.push(eixo7EsquerdoExt.sensor);
            this.sensorSevenExtLeftSelect = eixo7EsquerdoExt.sensor.id;
          }

          if (eixo7EsquerdoExt.pneu != null) {
            this.axisSevenDoubleLeftExtForm.controls["pneu"].setValue(
              eixo7EsquerdoExt.pneu.numeroFogo
            );
          }

          this.axisSevenDoubleLeftExtForm.controls["nrPressaoMinima"].setValue(
            eixo7EsquerdoExt.nrPressaoMinima
          );

          this.axisSevenDoubleLeftExtForm.controls["nrPressaoMaxima"].setValue(
            eixo7EsquerdoExt.nrPressaoMaxima
          );

          this.axisSevenDoubleLeftExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo7EsquerdoExt.nrTemperaturaMaxima);
        }

        // EIXO 7 DIREITO INTERNO
        if (eixo7DireitoInt != null) {
          this.axisSevenDoubleRightIntForm.controls["id"].setValue(
            eixo7DireitoInt.id
          );

          if (eixo7DireitoInt.sensor != null) {
            this.sensorSevenIntRightCombo.push(eixo7DireitoInt.sensor);
            this.sensorSevenIntRightSelect = eixo7DireitoInt.sensor.id;
          }

          if (eixo7DireitoInt.pneu != null) {
            this.axisSevenDoubleRightIntForm.controls["pneu"].setValue(
              eixo7DireitoInt.pneu.numeroFogo
            );
          }

          this.axisSevenDoubleRightIntForm.controls["nrPressaoMinima"].setValue(
            eixo7DireitoInt.nrPressaoMinima
          );

          this.axisSevenDoubleRightIntForm.controls["nrPressaoMaxima"].setValue(
            eixo7DireitoInt.nrPressaoMaxima
          );

          this.axisSevenDoubleRightIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo7DireitoInt.nrTemperaturaMaxima);
        }

        // EIXO 7 ESQUERDO INTERNO
        if (eixo7EsquerdoInt != null) {
          this.axisSevenDoubleLeftIntForm.controls["id"].setValue(
            eixo7EsquerdoInt.id
          );

          if (eixo7EsquerdoInt.sensor != null) {
            this.sensorSevenIntLeftCombo.push(eixo7EsquerdoInt.sensor);
            this.sensorSevenIntLeftSelect = eixo7EsquerdoInt.sensor.id;
          }

          if (eixo7EsquerdoInt.pneu != null) {
            this.axisSevenDoubleLeftIntForm.controls["pneu"].setValue(
              eixo7EsquerdoInt.pneu.numeroFogo
            );
          }

          this.axisSevenDoubleLeftIntForm.controls["nrPressaoMinima"].setValue(
            eixo7EsquerdoInt.nrPressaoMinima
          );

          this.axisSevenDoubleLeftIntForm.controls["nrPressaoMaxima"].setValue(
            eixo7EsquerdoInt.nrPressaoMaxima
          );

          this.axisSevenDoubleLeftIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo7EsquerdoInt.nrTemperaturaMaxima);
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
