import { Component, ViewChild, OnInit, Injectable } from "@angular/core";
import { MatButtonToggle } from "@angular/material/button-toggle";

import { MapToolboxService } from "./../../../../services/map.toolbox.service";
import { MapButtonService } from "./../../../../services/map.buttom.service";
import { PermissionsService } from "src/app/helpers/permissions.service";

@Component({
  selector: "toolbox-electronic-fence",
  templateUrl: "./button-electronic-fence.component.html",
  styleUrls: ["./button-electronic-fence.component.scss"],
})
@Injectable({
  providedIn: "root",
})
export class ButtonElectronicFenceComponent implements OnInit {
  @ViewChild(MatButtonToggle) btnSearchGroup: MatButtonToggle;
  permissions: [];
  selected = false;

  private service: MapToolboxService;
  private mapButtonService: MapButtonService;
  constructor(
    service: MapToolboxService,
    mapButtonService: MapButtonService,
    public permissionService: PermissionsService
  ) {
    this.service = service;
    this.mapButtonService = mapButtonService;
  }
  ngOnInit(): void {
    this.permissions = this.permissionService.getPermissions();
    this.mapButtonService.currentElectronicFenceButton.subscribe((action) => {
      if (action !== this.selected) this.selected = action;
    });
  }

  onChangeValue(): void {
    if (this.selected) {
      this.mapButtonService.setElectronicFenceButton(false);
      this.service.setToolboxCommand("hide-electronic-fence");
      return;
    }
    const value = !this.selected;
    this.selected = value;
    this.mapButtonService.setElectronicFenceButton(value);
    this.service.setToolboxCommand("electronic-fence");
  }
}
