import { ToolsService } from "src/app/services/migrate/tools.service";

import Swal from "sweetalert2";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { ManagerAnalyzeModel } from "src/app/models/manager-analyze-model";
import { RejectionManagerModel } from "src/app/models/rejection-manager-model";
import { toCamel } from "snake-camel";
import { MatTableDataSource } from "@angular/material/table";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_UUID_MANAGER } from "src/app/storageCore/constStorageKeys";
import { DatePipe } from "@angular/common";
import { MinioService } from "src/app/services/minio.service";

@Component({
  selector: "app-manager-treatment",
  templateUrl: "./manager-treatment.component.html",
  styleUrls: ["./manager-treatment.component.scss"],
})
export class ManagerTreatmentComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public minioService: MinioService;
  public datePipe: DatePipe;

  public treatmentManagerForm: FormGroup;
  public dataSource: MatTableDataSource<any>;

  public treatmentManagersSelected;
  public treatmentManager = [];

  public companyFilterSelected;
  public typesReportSelected;

  public managerAnalyzeModel: ManagerAnalyzeModel;
  public rejectionManagerModel: RejectionManagerModel;

  public analyzeReportUuid = "";
  public packageNumber = "";
  public tableColumns: string[];
  public removeCategory: string = 'false';

  public errorMessage;

  ngOnInit(): void {
    this.analyzeReportUuid = this.activatedRoute.snapshot.params.uuid;
    this.loadTableDefinitions();
    this.loadDataElements();
    this.treatmentManagerForm = this.formBuilder.group(
      {
        treatment: ["", Validators.required],
        managerComment: ["", Validators.required],
      },
      { updateOn: "blur" }
    );
  }

  public async onToggleChange() {
    this.spinner.show();
    this.removeCategory = this.removeCategory === 'true' ? 'false' : 'true';

    this.analyzeTripService
      .getOperatorTreat(this.analyzeReportUuid, this.removeCategory)
      .subscribe((t) => {
        this.dataSource = t;
      });

    this.spinner.hide();

  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "treatmentDate",
      "operatorDealings",
      "nameOperator",
      "descriptionAnalyze",
      "state",
      "city",
      "minioObject",
    ];
  }

  exportFile(element): void {
    this.minioService.exportReport(element).subscribe();
  }

  constructor(
    datePipe: DatePipe,
    private tools: ToolsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    analyzeTripService: AnalyzeTripService,
    minioService: MinioService
  ) {
    this.datePipe = datePipe;
    this.analyzeTripService = analyzeTripService;
    this.minioService = minioService;
  }

  public async loadDataElements() {
    try {
      this.spinner.show();

      this.analyzeTripService
        .getByOneUuidAnalyzeReport(this.analyzeReportUuid)
        .subscribe((t) => {
          console.log(t);
          this.packageNumber = t.package_number;
        });

      this.analyzeTripService.getAllManagerDealings().subscribe((t) => {
        this.treatmentManager.push(t);
      });

      this.analyzeTripService
        .getOperatorTreat(this.analyzeReportUuid, this.removeCategory)
        .subscribe((t) => {
          this.dataSource = t;
        });

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public async submitForm(): Promise<void> {
    console.log(this.treatmentManagerForm.get("treatment").value);
    if (
      this.treatmentManagerForm.get("treatment").value ==
      "f83aab7d-4733-4597-abde-093aa42715e7"
    ) {
      this.handleRejection();
    } else {
      this.handleReport();
    }
  }

  public sendSupervisor() {
    this.spinner.show();
    const values = this.treatmentManagerForm.value;

    var managerAnalyze: ManagerAnalyzeModel = new ManagerAnalyzeModel();

    managerAnalyze.descriptionAnalyze = values.managerComment;
    managerAnalyze.managerDealingsUuid = values.treatment;
    managerAnalyze.managerUuid = storageX
      .whereKeyIs(STORAGE_KEY_UUID_MANAGER)
      .get();
    managerAnalyze.analyzeUuid = this.analyzeReportUuid;

    this.analyzeTripService
      .createAnalyzeManager(managerAnalyze)
      .subscribe((t) => {
        this.managerAnalyzeModel = toCamel(t) as ManagerAnalyzeModel;
      });

    // Inicia o temporizador de 3 segundos
    setTimeout(() => {
      // Esconde o spinner
      this.spinner.hide();

      // Navega para a rota desejada

      this.router.navigate(["/analise-gestor"]);
    }, 3000); // 3000 milissegundos = 3 segundos
  }

  public rejectionManager() {
    this.spinner.show();
    const values = this.treatmentManagerForm.value;

    var rejectionManager: RejectionManagerModel = new RejectionManagerModel();

    rejectionManager.descriptionRejection = values.managerComment;
    rejectionManager.managerUuid = storageX
      .whereKeyIs(STORAGE_KEY_UUID_MANAGER)
      .get();
    rejectionManager.analyzeReportUuid = this.analyzeReportUuid;

    this.analyzeTripService
      .createRejectionManager(rejectionManager)
      .subscribe((t) => {
        this.managerAnalyzeModel = toCamel(t) as RejectionManagerModel;
      });

    // Inicia o temporizador de 3 segundos
    setTimeout(() => {
      // Esconde o spinner
      this.spinner.hide();

      // Navega para a rota desejada

      this.router.navigate(["/analise-gestor"]);
    }, 3000); // 3000 milissegundos = 3 segundos
  }

  public async handleReport(): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Finalizar Analise",
      html: `<h6>tem certeza que deseja finalizar essa análise?</h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.sendSupervisor();
      }
    });
  }

  public async handleRejection(): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Rejeitar Analise",
      html: `<h6>tem certeza que deseja rejeitar essa análise?</h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.rejectionManager();
      }
    });
  }
}
