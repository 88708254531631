<mat-dialog-content class="mat-typography">
  <div class="container">
    <h2>Terminais próximos ao Local</h2>

    <mat-tab-group
      animationDuration="0ms"
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab label="Pesquisar por endereço ou local" (click)="setTab(1)">
        <div class="form-group">
          <br />
          <label>Endereço ou Local:</label>
          <input
            type="text"
            class="form-control"
            (keydown.enter)="$event.preventDefault()"
            placeholder="Digite um endereço ou localidade..."
            autocorrect="off"
            autocapitalize="off"
            spellcheck="off"
            type="text"
            #search_proximity
          />

          <p *ngIf="address" class="address">
            <ngb-alert [dismissible]="false">
              <strong>Endereço encontrado: </strong> <br />
              {{ address }}
            </ngb-alert>
          </p>
        </div>

        <div class="form-group">
          <label>Distância do ponto (Km): </label>
          <input
            type="text"
            class="form-control distance"
            (keyup)="distanceChanged($event)"
            type="number"
          />
        </div>
      </mat-tab>
      <!-- Pesquisa por ponto no mapa -->
      <mat-tab label="Pesquisar por click no mapa" (click)="setTab(2)">
        <br />
        <div class="form-group">
          <label>Distância do ponto (Km): </label>
          <input
            type="text"
            class="form-control distance"
            (keyup)="distanceChanged($event)"
            type="number"
          />
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fechar</button>
  <button
    [disabled]="
      (tabSelected === 0 &&
        (!latitude || !longitude || !distance || distance <= 0)) ||
      !distance ||
      distance <= 0
    "
    mat-button
    (click)="submit()"
  >
    Consultar
  </button>
</mat-dialog-actions>
