import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { User } from "../../models/User.model";
import { Validation } from "./validation";
import storageX from "src/app/storageCore/storageX";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { Company } from "src/app/models/Company.model";

@Component({
  selector: "app-create-user",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CreateUserComponent implements OnInit {
  public userForm: FormGroup;
  public userId: number;
  public title = "Cadastrar Usuário";
  public errorMessage;
  private service: ApiBaseService;
  private validation: Validation;
  public types: any = [];
  public situations: any = [];
  public hide = false;
  public perfilSelected;

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      cdUsuario: ["", Validators.required],
      nmUsuario: ["", Validators.required],
      dsEmail: ["", Validators.required],
      nrMatricula: [0, Validators.required],
      cdSenha: ["", Validators.required],
      generalFilterPermission: [""],
    });
    this.hide = true;
    this.setup();
  }

  constructor(
    private apiService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.service = apiService;
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();
    try {
      const values = this.userForm.value;

      var post;

      post = {
        ...values,
        ativo: true,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      const result = await this.service.post<User>(
        new User()["nomeConsultaApiBase"],
        post
      );
      this.spinner.hide();
      console.log(result.id);
      this.router.navigate(["/users/edit/" + result.id]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  async setup(): Promise<void> {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
    try {
      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      this.spinner.hide();
    } catch (error) {
      console.error(error);
    }
  }
}
