<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="manutencaoForm">
                    <h2 class="mb-3">Nova Manutenção</h2>
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <mat-form-field>
                                <mat-label>Placa do Veículo</mat-label>
                                <mat-select formControlName="veiculo" [(value)]="veiculoSelected" required [readonly]=true [disabled]=true>
                                    <mat-option *ngFor="let types of vehicle" [value]="types.id">
                                        {{ types.cdPlaca }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label>KM</mat-label>
                                <input formControlName="nrQuilometragem" matInput placeholder="Quilometragem"  (keypress)="numberOnly($event)" [readonly]=true [disabled]=true/>
                            </mat-form-field>
<!--
                            <validation-errors [form]="manutencaoForm" controlLabel="Número Quilometragem"
                                controlName="nrQuilometragem">
                            </validation-errors>
-->
                        </div>
                        <div class="form-group col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data Manutenção</mat-label>
                                <input formControlName="dtManutencao" type="date" matInput
                                    placeholder="Data Manutenção"  [readonly]=true [disabled]=true/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Descrição</mat-label>
                                <input formControlName="dsDescricao" matInput placeholder="Descrição" maxlength="200"  [readonly]=true [disabled]=true/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-6">
                            <mat-slide-toggle name="flAlarmeGerado" formControlName="flAlarmeGerado" color="primary" [readonly]=true [disabled]=true>
                                Alarme Gerado
                            </mat-slide-toggle>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>