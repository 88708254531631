import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

import { UserInterface } from './../../contracts/User.interface';
import { User } from './../../models/User.model';

import ApiService from './../../services/api.service';
import storageX from 'src/app/storageCore/storageX';
import { STORAGE_KEY_CURRENT_USER } from 'src/app/storageCore/constStorageKeys';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  private service: ApiService;
  public users: MatTableDataSource<UserInterface>;
  public user: User;
  public userId: number;
  public errorMessage;
  public userForm: FormGroup;

  public updatePasswordLoader = false;
  public updatePasswordErrorMessage;
  public updatePasswordForm: FormGroup;

  public nomeCompleto: string;
  public nomeUsuario: string;
  public emailUsuario: string;
  public matriculaUsuario: string;

  private currentUserSubject: BehaviorSubject<User>;
  // public currentUser: Observable<User>;

  constructor(
    apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.service = apiService;
    this.users = new MatTableDataSource<UserInterface>([]);

    this.currentUserSubject = new BehaviorSubject<User>(
      storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get() //JSON.parse(localStorage.getItem('currentUser'))
    );
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  ngOnInit(): void {
    this.updatePasswordForm = this.formBuilder.group({
      cdSenhaAnterior: [''],
      cdSenhanova: [''],
    });

    this.nomeCompleto = this.currentUserValue.nmUsuario;
    this.nomeUsuario = this.currentUserValue.cdUsuario;
    this.emailUsuario = this.currentUserValue.dsEmail;
    this.matriculaUsuario = this.currentUserValue.nrMatricula;
  }

  public async loadUser(id): Promise<void> {}

  public async submitForm(): Promise<void> {

    try {
      this.spinner.show();

      this.service.patch(this.currentUserValue.id, 'usuario', {
        nmUsuario: { nmUsuario: this.currentUserValue.nmUsuario },
        cdUsuario: { cdUsuario: this.currentUserValue.cdUsuario },
        dsEmail: { dsEmail: this.currentUserValue.dsEmail },
        nrMatricula: { nrMatricula: this.currentUserValue.nrMatricula },
      });

      this.spinner.hide();
     } catch (error) {
         this.spinner.hide();
         throw new EvalError( error );
     }
  }

  async submitUpdatePassword(): Promise<void> {
  /*   this.updatePasswordLoader = true; */
    this.updatePasswordErrorMessage = null;
    try {

      this.spinner.show();

      const values = this.updatePasswordForm.value;
      const res = await this.service.patch<any>(
        this.currentUserValue.id,
        'usuario/atualizar-senha',
        values
      );
      this.updatePasswordLoader = false;
      this.spinner.hide();
    } catch (error) {
      const { message, status } = error;
      this.spinner.hide();
    /*   this.updatePasswordLoader = false; */
      this.updatePasswordErrorMessage =
        status === 400 ? 'Senha atual incorrreta' : message;
    }
  }
}
