import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class DownloadCsv{

  constructor(private http: HttpClient){}

  public download(): Observable<any>{
    return this.http.get('assets/csvs/exemplo_lote.csv', {
      responseType: 'blob'
    });
   }
  }
