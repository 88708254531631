export class TelemetriaType{
    pneuPosition: Array<String>;
    pressaoSensorPneu: Array<String>;
    temperaturaSensorPneu: Array<String>;
    voltagemBateriaSensorPneu: Array<String>;
    idSensorPneu: Array<String>;    
  }

  export class Telemetria{
    field
    title;

     constructor(field, title){
       this.field = field;
       this.title = title;
     }
  }

  export const TABLE_TR_TELEMETRIA = [
 
    { fieldKey: 'pneuPosition', title: 'Posição do pneu' },
    { fieldKey: 'pressaoSensorPneu', title: 'Pressão (PSI):' },
    { fieldKey: 'temperaturaSensorPneu', title: 'Temperatura (⁰C):' },
    { fieldKey: 'voltagemBateriaSensorPneu', title: 'Status Bateria:' },
    { fieldKey: 'idSensorPneu', title: 'Sensor Pneu (ID):'}  
  ];
