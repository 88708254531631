export const STORAGE_KEY_KEEP_LOGGEDIN: string = "keepLoggedIn";
export const STORAGE_KEY_ID_PERFIL: string = "idPerfil";
export const STORAGE_KEY_CURRENT_USER: string = "currentUser";
export const STORAGE_KEY_CD_USUARIO: string = "cdUsuario";
export const STORAGE_KEY_ID_SUPERVISOR: string = "supervisorId";
export const STORAGE_KEY_ID_MANAGER: string = "managerId";
export const STORAGE_KEY_UUID_SUPERVISOR: string = "supervisorUuid";
export const STORAGE_KEY_UUID_MANAGER: string = "managerUuid";
export const STORAGE_KEY_ACCESS_TOKEN_WSO2: string = "access_token";
export const STORAGE_KEY_UUID: string = "uuid";
export const STORAGE_KEY_PERMISSIONS: string = "permissions";
export const STORAGE_KEY_PATHS: string = "paths";
export const STORAGE_KEY_CURRENT_PERFIL: string = "currentPerfil";
export const STORAGE_KEY_CURRENT_PERFIL_FUNCIONALIDADES =
  "currentPerfilFuncionalidades";

export const STORAGE_KEY_CURRENT_VEHICLES = "currentVehicles";

export const STORAGE_ALLKEYS: Array<string> = [
  STORAGE_KEY_ID_PERFIL,
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_CD_USUARIO,
  STORAGE_KEY_PERMISSIONS,
  STORAGE_KEY_PATHS,
  STORAGE_KEY_CURRENT_PERFIL,
  STORAGE_KEY_CURRENT_PERFIL_FUNCIONALIDADES,
  STORAGE_KEY_CURRENT_VEHICLES,
];
