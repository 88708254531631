import {
  STORAGE_KEY_ACCESS_TOKEN_WSO2,
  STORAGE_KEY_ID_PERFIL,
} from "./../../storageCore/constStorageKeys";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import storageX from "src/app/storageCore/storageX";

@Injectable({
  providedIn: "root",
})
export class CockpitService {
  constructor(private http: HttpClient) {}

  public amount(): Observable<any> {
    return this.http.get(`${environment.othersUrl.events}/v2/events-panel`, {
      params: {
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get(),
      },
    });
  }

  public update(id, payload): Observable<any> {
    return this.http.patch(
      `${environment.othersUrl.events}/v2/events-panel/${id}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get(),
        },
      }
    );
  }

  public loadEvents(id, psize = "10", ppage = "0"): Observable<any> {
    return this.http.get(
      `${environment.othersUrl.events}/v2/events-panel/${id}`,
      {
        params: {
          profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
          size: psize,
          page: ppage,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get(),
        },
      }
    );
  }

  public loadStatusPneu(payload, IdTerminalAlarme): Observable<any> {
    return this.http.post(
      `${environment.othersUrl.reports}/terminal-status-pneus/`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get(),
        },
        params: {
          idTerminalAlarme: IdTerminalAlarme,
        },
      }
    );
  }

  public getCountTerminaisOffline(idPerfil): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/posicao-atual-terminal/perfil/${idPerfil}/offline/count`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get(),
        },
      }
    );
  }

  public getCountTerminaisOnline(idPerfil): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/posicao-atual-terminal/perfil/${idPerfil}/online/count`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get(),
        },
      }
    );
  }

  public getTerminaisOffline(idPerfil): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/posicao-atual-terminal/perfil/${idPerfil}/offline`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get(),
        },
      }
    );
  }

  public getTerminaisOnline(idPerfil): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/posicao-atual-terminal/perfil/${idPerfil}/online`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get(),
        },
      }
    );
  }
}
