import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-axle-seven-simple-view",
  templateUrl: "./axle-seven-simple-view.component.html",
  styleUrls: ["./axle-seven-simple-view.component.scss"],
})
export class AxleSevenSimpleViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorSevenLeftSelect: any = [];
  public sensorSevenRightSelect: any = [];

  public sensorSevenRightCombo: any = [];
  public sensorSevenLeftCombo: any = [];

  public axisSevenSimpleLeftForm: FormGroup;
  public axisSevenSimpleRightForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisSevenSimpleLeftForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisSevenSimpleRightForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisSevenSimpleRightForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 7 ESQ"
      );
      this.axisSevenSimpleLeftForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 7 DIR"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo7Direito;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 48) {
              eixo7Direito = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 48) {
              eixo7Direito = filter;
            }
          }
        });

        var eixo7Esquerdo;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 47) {
              eixo7Esquerdo = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 47) {
              eixo7Esquerdo = filter;
            }
          }
        });

        // EIXO 7 DIREITO
        if (eixo7Direito != null) {
          this.axisSevenSimpleRightForm.controls["id"].setValue(
            eixo7Direito.id
          );

          if (eixo7Direito.sensor != null) {
            this.sensorSevenRightCombo.push(eixo7Direito[0].sensor);
            this.sensorSevenRightSelect = eixo7Direito[0].sensor.id;
          }

          if (eixo7Direito.pneu != null) {
            this.axisSevenSimpleRightForm.controls["pneu"].setValue(
              eixo7Direito.pneu.numeroFogo
            );
          }

          this.axisSevenSimpleRightForm.controls["nrPressaoMinima"].setValue(
            eixo7Direito.nrPressaoMinima
          );

          this.axisSevenSimpleRightForm.controls["nrPressaoMaxima"].setValue(
            eixo7Direito.nrPressaoMaxima
          );

          this.axisSevenSimpleRightForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo7Direito.nrTemperaturaMaxima);
        }

        // EIXO 7 ESQUERDO
        if (eixo7Esquerdo != null) {
          this.axisSevenSimpleLeftForm.controls["id"].setValue(
            eixo7Esquerdo.id
          );

          if (eixo7Esquerdo.sensor != null) {
            this.sensorSevenLeftCombo.push(eixo7Esquerdo[0].sensor);
            this.sensorSevenLeftSelect = eixo7Esquerdo[0].sensor.id;
          }

          if (eixo7Esquerdo.pneu) {
            this.axisSevenSimpleLeftForm.controls["pneu"].setValue(
              eixo7Esquerdo.pneu.numeroFogo
            );
          }

          this.axisSevenSimpleLeftForm.controls["nrPressaoMinima"].setValue(
            eixo7Esquerdo.nrPressaoMinima
          );

          this.axisSevenSimpleLeftForm.controls["nrPressaoMaxima"].setValue(
            eixo7Esquerdo.nrPressaoMaxima
          );

          this.axisSevenSimpleLeftForm.controls["nrTemperaturaMaxima"].setValue(
            eixo7Esquerdo.nrTemperaturaMaxima
          );
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
