<!-- <button class="close" mat-button (click)="close(null)">X</button> -->

<div *ngIf="loader" style="justify-items: center; width: 100%;">
  <mat-spinner value="10" style="margin: 0 auto;" class="loader"></mat-spinner>
</div>

<div *ngIf="!loader">
  <h2 mat-dialog-title>Seleção de Perfil</h2>
  <div id="perfil-selector">
    <div class="container-fluid overContainer">
      <div class="d-flex">
        <mat-selection-list [multiple]="false">
          <mat-list-option
            (click)="onPerfilClick(up)"
            *ngFor="let up of perfils; index as i"
          >
            <mat-icon mat-list-icon>folder_shared</mat-icon>
            <div mat-line>{{ up.nmPerfil }}</div>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>
</div>
