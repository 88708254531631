import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class OAuthService {
  protected refresh$ = new EventEmitter<any>();

  constructor(private http: HttpClient) {}

  /*  private refreshToken(){
    let time = Number(this.oAuth.expires_in.toString() + '000') - 360000;
    setInterval(() => {
       this.refresh(this.oAuth.refresh_token).subscribe((r: any) =>{
        this.oAuth = r;
        this.apiService.token = this.oAuth.access_token;
       })
     }, time);
 }

 private refreshTokenSubascrible(){
  this.refresh$.subscribe(() =>{
    this.refreshToken();
  })
 } */
}
