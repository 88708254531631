import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-axle-nine-simple-view",
  templateUrl: "./axle-nine-simple-view.component.html",
  styleUrls: ["./axle-nine-simple-view.component.scss"],
})
export class AxleNineSimpleViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorNineLeftSelect: any = [];
  public sensorNineRightSelect: any = [];

  public sensorNineRightCombo: any = [];
  public sensorNineLeftCombo: any = [];

  public axisNineSimpleLeftForm: FormGroup;
  public axisNineSimpleRightForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisNineSimpleLeftForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisNineSimpleRightForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisNineSimpleRightForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 9 ESQ"
      );
      this.axisNineSimpleLeftForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 9 DIR"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo9Direito;

        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 53) {
              eixo9Direito = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 53) {
              eixo9Direito = filter;
            }
          }
        });

        var eixo9Esquerdo;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 54) {
              eixo9Esquerdo = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 54) {
              eixo9Esquerdo = filter;
            }
          }
        });

        // EIXO 9 DIREITO
        if (eixo9Direito != null) {
          this.axisNineSimpleRightForm.controls["id"].setValue(eixo9Direito.id);

          if (eixo9Direito.sensor != null) {
            this.sensorNineRightCombo.push(eixo9Direito.sensor);
            this.sensorNineRightSelect = eixo9Direito.sensor.id;
          }

          if (eixo9Direito.pneu != null) {
            this.axisNineSimpleRightForm.controls["pneu"].setValue(
              eixo9Direito.pneu.numeroFogo
            );
          }

          this.axisNineSimpleRightForm.controls["nrPressaoMinima"].setValue(
            eixo9Direito.nrPressaoMinima
          );

          this.axisNineSimpleRightForm.controls["nrPressaoMaxima"].setValue(
            eixo9Direito.nrPressaoMaxima
          );

          this.axisNineSimpleRightForm.controls["nrTemperaturaMaxima"].setValue(
            eixo9Direito.nrTemperaturaMaxima
          );
        }

        // EIXO 9 ESQUERDO
        if (eixo9Esquerdo != null) {
          this.axisNineSimpleLeftForm.controls["id"].setValue(eixo9Esquerdo.id);

          if (eixo9Esquerdo.sensor != null) {
            this.sensorNineLeftCombo.push(eixo9Esquerdo.sensor);
            this.sensorNineLeftSelect = eixo9Esquerdo.sensor.id;
          }

          if (eixo9Esquerdo.pneu != null) {
            this.axisNineSimpleLeftForm.controls["pneu"].setValue(
              eixo9Esquerdo.pneu.numeroFogo
            );
          }

          this.axisNineSimpleLeftForm.controls["nrPressaoMinima"].setValue(
            eixo9Esquerdo.nrPressaoMinima
          );

          this.axisNineSimpleLeftForm.controls["nrPressaoMaxima"].setValue(
            eixo9Esquerdo.nrPressaoMaxima
          );

          this.axisNineSimpleLeftForm.controls["nrTemperaturaMaxima"].setValue(
            eixo9Esquerdo.nrTemperaturaMaxima
          );
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
