import { NgxSpinnerService } from "ngx-spinner";
import { ContentInterface } from "./../../contracts/Content.interface";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

import { Vehicle } from "../../models/Vehicle.model";
import { DownloadCsv } from "src/app/services/download_csv.service";
import storageX from "src/app/storageCore/storageX";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { Company } from "src/app/models/Company.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-create-vehicle",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CreateVehicleComponent implements OnInit {
  public veiculoId: number;
  public veiculoTipoSelected;
  public veiculoCombustivelTipoSelected;
  public categoriaVeiculoSelected;
  public empresaUpdateSelected = true;
  public atualizarEmpresa = false;
  public empresaSelected;
  public empresaFilterSelected;
  public vehicleForm: FormGroup;
  public veiculo;
  public title = "Cadastrar Veículo";
  public errorMessage;
  public axisQuantity = null;
  public fuelTypes: any = [];
  public vehicleTypes: any = [];
  public categoriaVeiculos: any = [];
  public companies: any = [];
  public companiesFilter: any = [];
  public axisSelect: any = [];
  public tipoRodagemSelect: any = [];
  public cambioAutomaticoSelect: any = [];

  public cambioAutomatico: any = [
    { name: "SIM", value: true },
    { name: "NÃO", value: false },
  ];

  public records: any[] = [];

  public companiesSelect: any = [
    { name: "Divisão", value: 1 },
    { name: "Matriz", value: 2 },
    { name: "Filial", value: 3 },
  ];

  public quantidadeEixo = [
    { name: "1 EIXO", value: 1 },
    { name: "2 EIXOS", value: 2 },
    { name: "3 EIXOS", value: 3 },
    { name: "4 EIXOS", value: 4 },
    { name: "5 EIXOS", value: 5 },
    { name: "6 EIXOS", value: 6 },
    { name: "7 EIXOS", value: 7 },
    { name: "8 EIXOS", value: 8 },
    { name: "9 EIXOS", value: 9 },
  ];

  public tipoRodagem = [
    { name: "SIMPLES", value: "S" },
    { name: "DUPLA", value: "D" },
  ];

  public envioLote = true;
  public nomeArq: String = "";
  public cond = true;
  public compLote = 0;
  public btnText = "Processar";
  public btnDisabled = false;

  @ViewChild("csvReader") csvReader: any;

  ngOnInit(): void {
    this.vehicleForm = this.formBuilder.group(
      {
        selecaoEmpresa: [""],
        idEmpresa: [""],
        cdPlaca: [""],
        frota: [""],
        cdRenavam: [""],
        cdChassi: [""],
        cdAntt: [""],
        cdPbt: [""],
        nmMarca: [""],
        nmModelo: [""],
        nrQuantidadeEixo: [""],
        idVeiculoTipo: ["", Validators.required],
        idVeiculoCombustivelTipo: ["", Validators.required],
        idCategoriaVeiculo: [""],
        nrAnoFabricacao: [""],
        nrAnoModelo: [""],
        cdCor: [""],
        tipoRodagem: [""],
        nmCidade: [""],
        nmEstado: [""],
        nmPais: [""],
        nrCoeficienteConsumo: [""],
        nrOdometro: [""],
        nrCapacidadeTanque: [""],
        nrTelefoneProprietario: [""],
        nmNomeProprietario: [""],
        dsEmail: [""],
        cambioAutomatico: [false],
        veiculoSensorList: this.formBuilder.array([]),
      },
      { updateOn: "blur" }
    );

    this.setup();
  }

  constructor(
    private serviceApiBase: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private doenload_cvs: DownloadCsv,
    private spinner: NgxSpinnerService,
    private http: HttpClient
  ) { }

  uploadListener($event: any): void {
    this.nomeArq = $event.target.files[0].name;

    let text = [];
    let files = $event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
        this.records = this.getDataRecordsArrayFromCSVFile(
          csvRecordsArray,
          headersRow.length
        );
        this.compLote = this.records.length;
      };

      reader.onerror = function () {
        console.log("error is occured while reading file!");
      };
    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  public async searchPlate() {
    this.spinner.show();
    let placa = this.vehicleForm.get('cdPlaca')?.value;

    console.log(placa);
    const apiUrl = `https://placas.fipeapi.com.br/placas/${placa}?key=${environment.apiKeyFipe}`;

    this.http.get(apiUrl).subscribe(
      (response: any) => {
        console.log('Dados da API FIPE:', response);
        if (response?.data?.veiculo) {
          this.fillForm(response.data.veiculo);
        }
        this.spinner.hide();
      },
      (error) => {
        console.error('Erro ao buscar dados da placa:', error);
        this.spinner.hide();
      }
    );
  }


  fillForm(veiculo: any) {
    // Extrair "ano" e separar em fabricação e modelo
    const [anoFabricacao, anoModelo] = veiculo.ano.split('/');

    // Extrair "marca_modelo" e separar em marca e modelo
    const [marca, modelo] = veiculo.marca_modelo.split('/');

    // Preencher os campos do formulário
    this.vehicleForm.patchValue({
      nmEstado: veiculo.uf,  // Estado (UF)
      nrAnoFabricacao: anoFabricacao,  // Ano de Fabricação
      nrAnoModelo: anoModelo,  // Ano Modelo
      cdCor: veiculo.cor,  // Cor
      cdPbt: veiculo.pbt,  // PBT
      cdChassi: veiculo.chassi,  // Chassi
      nmCidade: veiculo.municipio,  // Cidade
      nmPais: veiculo.procedencia === 'Nacional' ? 'BRASIL' : 'IMPORTADO',  // País
      nmMarca: marca.trim(),  // Marca
      nmModelo: modelo.trim(),  // Modelo
    });
  }

  allowOnlyLettersAndNumbers(event: KeyboardEvent) {
    const charCode = event.key.charCodeAt(0);
    if (!/[A-Za-z0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }


  async setup(): Promise<void> {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
    try {
      const types = await this.serviceApiBase.get<ContentInterface[]>({
        url: "veiculo-tipo",
      });
      this.vehicleTypes = types["content"];

      const combustiveltipo = await this.serviceApiBase.get<ContentInterface[]>(
        {
          url: "veiculo-combustivel-tipo",
        }
      );
      this.fuelTypes = combustiveltipo["content"];

      const categoriaVeiculo = await this.serviceApiBase.get<
        ContentInterface[]
      >({
        url: "categoria-veiculo",
      });
      this.categoriaVeiculos = categoriaVeiculo["content"];

      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      const result = await this.serviceApiBase.get<any>({
        url: new Company()["nomeConsultaApiBaseGetAll"],
        params,
      });

      this.companies = result["content"];

      this.spinner.hide();
    } catch (error) {
      console.error(error);
    }
  }

  public async fncProcessar($event: any): Promise<void> {
    this.cond = true;
    if (this.nomeArq == "") {
      alert("Você deve selecionar um arquivo CSV");
      this.cond = false;
    }
    if (
      (this.vehicleForm.controls["idEmpresa"].value == "" ||
        this.vehicleForm.controls["idVeiculoTipo"].value == "" ||
        this.vehicleForm.controls["idVeiculoCombustivelTipo"].value == "") &&
      this.cond == true
    ) {
      console.log(this.vehicleForm);
      alert(
        "- Você deve selecionar uma empresa\n- Você deve selecionar um tipo de veículo\n- Você deve selecionar um tipo de combustível"
      );
      this.cond = false;
    }

    if (this.cond == true && this.compLote > 0 && this.btnDisabled == false) {
      this.btnDisabled = true;
      this.btnText = "Processando...";

      this.spinner.show();

      try {
        const values = this.vehicleForm.value;

        const post = {
          ...values,
          veiculoTipo: { id: parseInt(values.veiculoTipo) },
          veiculoCombustivelTipo: {
            id: parseInt(values.veiculoCombustivelTipo),
          },
          empresa: { id: parseInt(values.empresa) },
          idUsuarioCriacao: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()
            ?.id,
        };
        await this.serviceApiBase.post<Vehicle>(
          new Vehicle()["nomeConsultaApiBase"],
          post
        );
        this.spinner.hide();
        this.router.navigate(["/consulta/VEHICLE_FUNCIONALIDADE"]);
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(",");
      if (curruntRecord.length == headerLength) {
        this.vehicleForm.controls["cdAntt"].setValue(curruntRecord[0]);

        this.vehicleForm.controls["cdChassi"].setValue(curruntRecord[1]);

        this.vehicleForm.controls["cdCor"].setValue(curruntRecord[2]);

        this.vehicleForm.controls["cdPbt"].setValue(curruntRecord[3]);

        this.vehicleForm.controls["cdPlaca"].setValue(curruntRecord[4]);

        this.vehicleForm.controls["cdRenavam"].setValue(curruntRecord[5]);

        this.vehicleForm.controls["dsEmail"].setValue(curruntRecord[6]);

        this.vehicleForm.controls["nmCidade"].setValue(curruntRecord[7]);

        this.vehicleForm.controls["nmEstado"].setValue(curruntRecord[8]);

        this.vehicleForm.controls["nmMarca"].setValue(curruntRecord[9]);

        this.vehicleForm.controls["nmModelo"].setValue(curruntRecord[10]);

        this.vehicleForm.controls["nmNomeProprietario"].setValue(
          curruntRecord[11]
        );

        this.vehicleForm.controls["nmPais"].setValue(curruntRecord[12]);

        var curruntRecord13 = Number(curruntRecord[13]);
        this.vehicleForm.controls["nrAnoFabricacao"].setValue(curruntRecord13);

        var curruntRecord14 = Number(curruntRecord[14]);
        this.vehicleForm.controls["nrAnoModelo"].setValue(curruntRecord14);

        var curruntRecord15 = Number(curruntRecord[15]);
        this.vehicleForm.controls["nrCapacidadeTanque"].setValue(
          curruntRecord15
        );

        this.vehicleForm.controls["nrCoeficienteConsumo"].setValue(
          curruntRecord[16]
        );

        var curruntRecord17 = Number(curruntRecord[17]);
        this.vehicleForm.controls["nrOdometro"].setValue(curruntRecord17);

        var curruntRecord18 = Number(curruntRecord[18]);
        this.vehicleForm.controls["nrQuantidadeEixo"].setValue(curruntRecord18);

        this.vehicleForm.controls["nrTelefoneProprietario"].setValue(
          curruntRecord[19]
        );

        this.veiculoSensorItems.push(
          this.formBuilder.group({
            nrSensor: [curruntRecord[20]],

            cdPosicaoPneu: [curruntRecord[21]],

            cdSensor: [curruntRecord[22]],

            nrPressaoMinima: [curruntRecord[23]],

            nrPressaoMaxima: [curruntRecord[24]],

            nrTemperaturaMaxima: [curruntRecord[25]],
          })
        );
        this.vehicleForm.controls["veiculoSensorList"].setValue(
          this.veiculoSensorItems
        );
        csvArr.push(this.vehicleForm.value);
      }
    }
    return csvArr;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(",");
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();

    try {
      this.coverterUpperCase(this.vehicleForm);
      const values = this.vehicleForm.value;
      var post;

      if (this.empresaFilterSelected != "Divisão") {
        post = {
          ...values,
          veiculoTipo: { id: parseInt(values.veiculoTipo) },
          veiculoCombustivelTipo: {
            id: parseInt(values.veiculoCombustivelTipo),
          },
          empresa: {
            id: parseInt(values.idEmpresa),
          },
          idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
        };
      } else {
        post = {
          ...values,
          veiculoTipo: { id: parseInt(values.veiculoTipo) },
          veiculoCombustivelTipo: {
            id: parseInt(values.veiculoCombustivelTipo),
          },
          divisao: {
            id: parseInt(values.idEmpresa),
          },
          idEmpresa: null,
          idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
        };
      }

      await this.serviceApiBase.post<Vehicle>(
        new Vehicle()["nomeConsultaApiBase"],
        post
      );
      this.spinner.hide();
      this.router.navigate(["/consulta/VEHICLE_FUNCIONALIDADE"]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  get veiculoSensorItems(): FormArray {
    return this.vehicleForm.get("veiculoSensorList") as FormArray;
  }

  public downloadCsv() {
    this.doenload_cvs.download().subscribe(
      (r) => {
        let file = new Blob([r], { type: "application/csv" });
        console.log(file);
        let fileURL = URL.createObjectURL(file);
        console.log(fileURL);

        let a = document.createElement("a");
        console.log(a);

        a.href = fileURL;
        a.download = "exemplo_lote.csv";
        a.click();

        setTimeout(() => {
          URL.revokeObjectURL(fileURL);
        }, 800);
      },
      (error) => {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    );
  }

  public filterCompany() {
    var idEmpresa = this.empresaFilterSelected;
    var empresas = [];

    if (idEmpresa == "Divisão") {
      this.companies.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Matriz") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Filial") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    console.log(this.companiesFilter);
  }

  public coverterUpperCase(vehicleForm: FormGroup) {
    console.log(vehicleForm);

    vehicleForm
      .get("cdPlaca")
      .setValue(vehicleForm.get("cdPlaca").value.toUpperCase());
    vehicleForm
      .get("frota")
      .setValue(vehicleForm.get("frota").value.toUpperCase());
    vehicleForm
      .get("cdChassi")
      .setValue(vehicleForm.get("cdChassi").value.toUpperCase());
    vehicleForm
      .get("cdAntt")
      .setValue(vehicleForm.get("cdAntt").value.toUpperCase());
    vehicleForm
      .get("nmMarca")
      .setValue(vehicleForm.get("nmMarca").value.toUpperCase());
    vehicleForm
      .get("nmModelo")
      .setValue(vehicleForm.get("nmModelo").value.toUpperCase());
    vehicleForm
      .get("cdCor")
      .setValue(vehicleForm.get("cdCor").value.toUpperCase());
    vehicleForm
      .get("nmCidade")
      .setValue(vehicleForm.get("nmCidade").value.toUpperCase());
    vehicleForm
      .get("nmEstado")
      .setValue(vehicleForm.get("nmEstado").value.toUpperCase());
    vehicleForm
      .get("nmPais")
      .setValue(vehicleForm.get("nmPais").value.toUpperCase());
    vehicleForm
      .get("nmNomeProprietario")
      .setValue(vehicleForm.get("nmNomeProprietario").value.toUpperCase());
  }
}
