import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-report-async-view",
  templateUrl: "./report-async-view.component.html",
  styleUrls: ["./report-async-view.component.scss"],
})
export class ReportAsyncViewComponent implements OnInit {
  public reportUuidJson = "";

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.reportUuidJson = this.activatedRoute.snapshot.params.uuid;
    console.log(this.reportUuidJson);
  }
}
