<div class="login-body">
  <div class="login-wrapper">
      <div class="login-panel">
          <img src="./assets/img/logo-login.svg" class="logo" alt="diamond-layout" />

          <form class="login-form" 
          [formGroup]="validateCodeForm"
          (ngSubmit)="this.validate()">
              <h2>Gestão em Transportes</h2>
              <p>  Digite o codigo enviado para o seu e-mail. <a> </a></p>
              <input 
                pInputText 
                formControlName="input"
                type="text"
                (keyup)="valueUperCase($event)"
                [pKeyFilter]="blockSpace"
                maxlength="6"
                placeholder="Digite o código"
              />
              <button [disabled]="this.validateCodeForm.invalid" pButton label="Validar" type="submit" ></button>
              <br>
              <button (click)="this.tools.builder().router.navigate(['/login'])"  pButton class="p-button-secondary" label="Voltar" type="button" ></button>
          </form>    
        </div>
        <div class="login-image">
          <div  style="position: relative; top: 76px;" class="login-image-content">

              <img src="assets/img/logo-white-verti.png" class="logo" alt="diamond-layout" />
              <h4>
                <a style="color: white;
                font-size: 16px;
                position: relative;
                top: -14px;
                left: 80px;" href="/index">
            
            <i class="pi pi-arrow-left
            " style="font-weight: 700;
            color: white;
            font-size: 26px;
            position: relative;
            top: 6px;
            left: -7px;"></i>

              Voltar ao site</a> </h4>
          </div>
          <div class="image-footer">            
          </div>
      </div>
  </div>
</div>
