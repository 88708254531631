<div class="main-page cadastros">
  <div class="container-fluid">
    <app-title>{{ title ? title : 'Inserir novo' }}</app-title>

    <!-- ITEM CARDS -->
    <div class="row mt-3 no-gutters p-4 row-form">
      <div class="col-12">
        <form [formGroup]="companyForm">
          <h2 class="mb-3">Dados cadastrais</h2>
          <div class="form-row">
            <div class="form-group col-6">
              <mat-form-field>
                <mat-label>Tipo de Cadastro</mat-label>
                <mat-select formControlName="selecaoEmpresa" [(value)]="empresaSelected" [(ngModel)]="empresaSelected" required [disabled]=true>
                  <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <validation-errors [form]="companyForm" controlLabel="Tipo de Cadastro" controlName="selecaoEmpresa">
              </validation-errors>
            </div>
            <div class="form-group col-lg-6" style="margin-top: 10px;">
              <mat-slide-toggle name="exibePainel" formControlName="exibePainel" color="primary" [disabled]=true>
                Exibir no painel de eventos</mat-slide-toggle>
          </div>
          </div>
          <div class="form-row">
            <div class="form-row" *ngIf="companyForm.get('selecaoEmpresa').value == 'Divisão'|| companyForm.get('selecaoEmpresa').value == null">
              <div class="form-group col-12">
                <mat-form-field>
                  <mat-label>Empresa</mat-label>
                  <mat-select formControlName="empresaMatriz" [(value)]="empresaMatrizSelected" [(ngModel)]="empresaMatrizSelected" required [disabled]=true>
                    <mat-option *ngFor="let type of companies" [value]="type.id">
                      {{ type.nome }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <validation-errors [form]="companyForm" controlLabel="Empresa" controlName="empresaMatriz">
                </validation-errors>
              </div>
            </div>

            <div class="form-group col-6" *ngIf="companyForm.get('selecaoEmpresa').value == 'Filial'|| companyForm.get('selecaoEmpresa').value == null">
              <mat-form-field>
                <mat-label>Matriz</mat-label>
                <mat-select formControlName="empresaMatriz" [(value)]="empresaMatrizSelected" [(ngModel)]="empresaMatrizSelected" [readonly]=true [disabled]=true>
                  <mat-option *ngFor="let type of companies" [value]="type.id">
                    {{ type.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="form-row" *ngIf="companyForm.get('selecaoEmpresa').value == 'Matriz' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>CNPJ</mat-label>
                <input formControlName="cnpj" matInput placeholder="CNPJ" maxlength="20" (keypress)="numberOnly($event)" [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Inscrição estadual</mat-label>
                <input formControlName="inscricaoEstadual" matInput placeholder="Inscrição estadual" maxlength="80" (keypress)="numberOnly($event)" [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Inscrição municipal</mat-label>
                <input formControlName="inscricaoMunicipal" matInput placeholder="Inscrição municipal"
                  maxlength="80" (keypress)="numberOnly($event)" [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
          </div>         
          <div class="form-row" *ngIf="companyForm.get('selecaoEmpresa').value == 'Matriz' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            <div class="form-group col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>Razão social</mat-label>
                <input formControlName="razaoSocial" matInput placeholder="Razão social" maxlength="80"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>Nome fantasia</mat-label>
                <input formControlName="nome" matInput placeholder="Nome fantasia" maxlength="80" required  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row"*ngIf="companyForm.get('selecaoEmpresa').value == 'Divisão'">
            <div class="form-group col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>Nome</mat-label>
                <input formControlName="nome" matInput placeholder="Nome fantasia" maxlength="80" required [readonly]=true [disabled]=true/>
              </mat-form-field>
              <validation-errors [form]="companyForm" controlLabel="Nome fantasia" controlName="nome">
              </validation-errors>
            </div>
          </div>
          <div class="form-row" *ngIf="companyForm.get('selecaoEmpresa').value == 'Matriz' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            <div class="form-group col-6">
              <mat-form-field>
                <mat-label>Tipo de empresa</mat-label>

                <mat-select formControlName="empresaTipo" [(value)]="empresaTipoSelected" [readonly]=true [disabled]=true>
                  <mat-option *ngFor="let type of types" [value]="type.id">
                    {{ type.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- <div class="form-group col-6">
              <mat-form-field appearance="outline">
              <mat-form-field appearance="outline" class="invisible example-full-width">
                <mat-label>Credito SMS</mat-label>
                <input formControlName="creditoSms" matInput placeholder="Credito SMS" required  (keypress)="numberOnly($event)" value="0" ngModel="0" [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div> -->
          </div>

          <h2 class="my-3">Endereço</h2>
          <div class="form-row">
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>CEP</mat-label>
                <input formControlName="cep" matInput placeholder="CEP" maxlength="10"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-1">

            </div>
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Logradouro</mat-label>
                <input formControlName="endereco" matInput placeholder="Logradouro" maxlength="80"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Número</mat-label>
                <input formControlName="numero" matInput placeholder="Número" maxlength="20"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Complemento</mat-label>
                <input formControlName="complemento" matInput placeholder="Complemento" maxlength="40"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Bairro</mat-label>
                <input formControlName="bairro" matInput placeholder="Bairro" maxlength="100"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Cidade</mat-label>
                <input formControlName="cidade" matInput placeholder="Cidade" maxlength="100"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Estado</mat-label>
                <input formControlName="estado" matInput placeholder="Estado" maxlength="100"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
          </div>
          <h2 class="my-3">Contato</h2>
          <div class="form-row">
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Contato</mat-label>
                <input formControlName="contato" matInput placeholder="Contato" maxlength="80" required  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>

            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Telefone</mat-label>
                <input formControlName="telefone" matInput placeholder="Telefone" maxlength="20" required (keypress)="numberOnly($event)" [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>

            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Celular</mat-label>
                <input formControlName="fax" matInput placeholder="Celular" maxlength="20" (keypress)="numberOnly($event)" [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>

            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>E-mail</mat-label>
                <input formControlName="email" matInput placeholder="E-mail" maxlength="80" required  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
          </div>

          <h2 class="my-3">Observações</h2>
          <div class="form-row">
            <div class="form-group col-lg-12">
              <mat-form-field appearance="outline">
                <mat-label>Observações</mat-label>
                <input formControlName="observacao" matInput placeholder="Informe observações" maxlength="200"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
          </div>

          <h2 class="my-3" *ngIf="companyForm.get('selecaoEmpresa').value == 'Empresa' || companyForm.get('selecaoEmpresa').value == 'Filial'">Autorizações ANTT</h2>

          <div formArrayName="listEmpresaAutorizacaoAntt" *ngIf="companyForm.get('selecaoEmpresa').value == 'Empresa' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            <div *ngFor="
                let item of empresaAutorizacaoAnttItems.controls;
                let itemIndex = index
              " [formGroupName]="itemIndex">
              <input *ngIf="companyId" type="hidden" formControlName="autorizacaoAnttId"  [readonly]=true [disabled]=true/>

              <div class="form-row">
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo de autorização</mat-label>
                    <input formControlName="tipoAutorizacao" matInput placeholder="Tipo de autorização"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Número</mat-label>
                    <input formControlName="numero" matInput placeholder="Número" (keypress)="numberOnly($event)" [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de cadastro </mat-label>
                    <input type="date" formControlName="dataCadastro" matInput placeholder="Data de cadastro"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de vencimento</mat-label>
                    <input type="date" formControlName="dataVencimento" matInput placeholder="Data de vencimento"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-lg-6">
                  <span>Gerar alerta de vencimento?</span>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" formControlName="flagGerarAlertaVencimento" type="radio"
                      id="inlineRadio1" [value]="true"  [readonly]=true [disabled]=true/>
                    <label class="form-check-label" for="inlineRadio1">Sim</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="flagGerarAlertaVencimento"
                      id="inlineRadio2" [value]="false"  [readonly]=true [disabled]=true/>
                    <label class="form-check-label" for="inlineRadio2">Não</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <h2 class="my-3" *ngIf="companyForm.get('selecaoEmpresa').value == 'Empresa' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            Seguro de Responsabilidade Civil do Transportador de Cargas - RCTR-C
          </h2>

          <div formArrayName="listEmpresaSeguroRctrcList" *ngIf="companyForm.get('selecaoEmpresa').value == 'Empresa' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            <div *ngFor="
                let item of empresaSeguroRctrcItems.controls;
                let itemIndex = index
              " [formGroupName]="itemIndex">
              <input *ngIf="companyId" type="hidden" formControlName="seguroRctrcId"  [readonly]=true [disabled]=true/>

              <div class="form-row">
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Número da apólice</mat-label>
                    <input formControlName="numeroApolice" matInput placeholder="Número da apólice" (keypress)="numberOnly($event)" [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Seguradora</mat-label>
                    <input formControlName="seguradora" matInput placeholder="Seguradora"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de contratação</mat-label>
                    <input formControlName="dataContratacao" matInput type="date" placeholder="Data de contratação"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de vencimento</mat-label>
                    <input formControlName="dataVencimento" matInput type="date" placeholder="Data de vencimento"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-lg-6">
                  <span>Gerar alerta de vencimento?</span>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" formControlName="flagGerarAlertaVencimento" type="radio"
                      id="rctrInlineRadio1" [value]="true"  [readonly]=true [disabled]=true/>
                    <label class="form-check-label" for="rctrInlineRadio1">Sim</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="flagGerarAlertaVencimento"
                      id="rctrInlineRadio2" [value]="false"  [readonly]=true [disabled]=true/>
                    <label class="form-check-label" for="rctrInlineRadio2">Não</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>