import { Injectable } from "@angular/core";
import { v4 as uuidv4 } from "uuid";

@Injectable({
  providedIn: "root",
})
export class UuidService {
  private instanceUuid: string;

  constructor() {
    // Gere o UUID quando o serviço for criado
    this.instanceUuid = uuidv4();
  }

  getInstanceUuid(): string {
    return this.instanceUuid;
  }
}
