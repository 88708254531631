import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MapButtonService {
  /* Search */
  private searchButton$ = new BehaviorSubject<boolean>(false);
  currentSearchButton = this.searchButton$.asObservable();

  setSearchButton(value: boolean): void {
    this.searchButton$.next(value);
  }

  /* path */
  private pathButton$ = new BehaviorSubject<boolean>(false);
  currentPathButton = this.pathButton$.asObservable();

  setPathButton(value: boolean): void {
    this.pathButton$.next(value);
  }

  private pathButtonDisabled$ = new BehaviorSubject<boolean>(true);
  currentPathButtonDisabled = this.pathButtonDisabled$.asObservable();

  setPathButtonDisabled(value: boolean): void {
    this.pathButtonDisabled$.next(value);
  }

  /* Distance */
  private distanceButton$ = new BehaviorSubject<boolean>(false);
  currentDistanceButton = this.distanceButton$.asObservable();

  setDistanceButton(value: boolean): void {
    this.distanceButton$.next(value);
  }

  private distanceButtonDisabled$ = new BehaviorSubject<boolean>(false);
  currentDistanceButtonDisabled = this.distanceButtonDisabled$.asObservable();

  setDistanceButtonDisabled(value: boolean): void {
    this.distanceButtonDisabled$.next(value);
  }

  /* route */
  public static routeButton$ = new BehaviorSubject<any>(null);
  currentRouteButton = MapButtonService.routeButton$.asObservable();

  setRouteButton(value: boolean): void {
    MapButtonService.routeButton$.next(value);
  }

  /* electronic-fence */
  public static electronicFenceButton$ = new BehaviorSubject<any>(null);
  currentElectronicFenceButton =
    MapButtonService.electronicFenceButton$.asObservable();

  setElectronicFenceButton(value: boolean): void {
    MapButtonService.electronicFenceButton$.next(value);
  }

  /* General Filter */
  public static generalFilterButton$ = new BehaviorSubject<any>(null);
  currentGeneralFilterButton =
    MapButtonService.generalFilterButton$.asObservable();

  setGeneralFilterButton(value: boolean): void {
    MapButtonService.generalFilterButton$.next(value);
  }
}
