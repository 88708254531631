import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { TerminalAlarme } from "../../models/TerminalAlarme.model";
import { ApiService } from "../../services/api.service";
import { Validation } from "./validation";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-create-terminal-alarme",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CreateTerminalAlarmeComponent implements OnInit {
  public terminalalarmeForm: FormGroup;
  public title = "Cadastrar Eventos";
  public errorMessage;
  private service: ApiService;
  private validation: Validation;

  ngOnInit(): void {
    this.terminalalarmeForm = this.formBuilder.group(this.validation.rules());

    this.terminalalarmeForm.controls["nmNomeIconeCss"].setValue(
      "Ambulancia Azul"
    );
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.service = apiService;
    this.validation = new Validation();
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();
    try {
      this.coverterUpperCase(this.terminalalarmeForm);
      const values = this.terminalalarmeForm.value;
      const post = {
        ...values,
        idUsuarioCriacao: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()
          ?.id,
      };

      await this.service.post<TerminalAlarme>("terminal-alarme", post);
      this.spinner.hide();
      this.router.navigate(["/terminal-alarme"]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public coverterUpperCase(terminalalarmeForm: FormGroup) {
    if (terminalalarmeForm.get("dsMensagem").value != null) {
      terminalalarmeForm
        .get("dsMensagem")
        .setValue(terminalalarmeForm.get("dsMensagem").value.toUpperCase());
    }

    if (terminalalarmeForm.get("cdCor").value != null) {
      terminalalarmeForm
        .get("cdCor")
        .setValue(terminalalarmeForm.get("cdCor").value.toUpperCase());
    }

    if (terminalalarmeForm.get("dsProcedimentoOperador").value != null) {
      terminalalarmeForm
        .get("dsProcedimentoOperador")
        .setValue(
          terminalalarmeForm.get("dsProcedimentoOperador").value.toUpperCase()
        );
    }
  }
}
