
<!-- LOGIN -->
<!-- <section  class="login py-5">
 --> <!--  <div class="container h-100 pt-5">
    <div class="row h-100 align-items-center justify-content-center">
      <div class="col-md-6 col-lg-5 text-center">
       
        <img src="../../assets/img/logo-login.svg" alt="" class="img-fluid" />

        <br>
        <br>

        <div>
          Crie  sua nova senha
        </div>
        <form
          class="pt-5"
         [formGroup]="forgotPasswordForm"
         (ngSubmit)="this.onSubmit()"
        >
          <div class="form-row">
            <div class="form-group col-12">
              <input
                style="color: rgba(0, 0, 0, 0.848);"
                formControlName="password"
                type="text"
                class="form-control"
                placeholder="Nova senha"
              />
            </div>

            <div class="form-group col-12">
              <input
                style="color: rgba(0, 0, 0, 0.848);"
                formControlName="confirmPassword"
                type="text"
                class="form-control"
                placeholder="Repita sua nova senha"
              />
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-login w-100 w-md-50"
          >
            Enviar
          </button>
          
          <br>
          <br>

          <button
          (click)="this.tools.builder().router.navigate(['/login'])"
          class="btn btn-login w-100 w-md-50" >
          Voltar
        </button>
        </form>
      </div>
      <div class="col-md-5 col-lg-6 offset-md-1 text-center d-none d-md-block">
        <img src="../../assets/img/img-login.svg" alt="" class="img-fluid" />
      </div>
    </div>
  </div>
</section-->

<div class="login-body">
  <div class="login-wrapper">
      <div class="login-panel">
          <img src="assets/img/logo-login.svg" class="logo" alt="diamond-layout" />

          <form class="login-form" 
          [formGroup]="forgotPasswordForm"
           (ngSubmit)="this.save()">
           <h2>Gestão em Transportes</h2>
           <p> Crie uma nova senha com no mínimo 6 caracteres, pelo menos uma letra maiúscula e um número! <a></a></p>
           <span style="top: 29px;left: 106px; cursor: pointer;">
            <i *ngIf="this.showPassword" (click)="this.showPassword = false" class="pi pi-eye" style="font-weight: 700; font-size:14px"></i>
            <i *ngIf="!this.showPassword" (click)="this.showPassword = true" class="pi pi-eye-slash" style="font-weight: 700; font-size:14px"></i>
          </span>

            <input
            weakLabel="Fraca"
            mediumLabel="Média"
            strongLabel="Forte"
            placeholder="Nova senha"
            formControlName="password"
            type="password" pPassword
            formControlName="password"
            [showPassword]="this.showPassword"
            promptLabel="Digite sua nova senha"
            >
      
              <input 
                pPassword
                [feedback]="false"
                formControlName="confirmPassword"
                type="password"
                [showPassword]="this.showPassword"
                class="form-control"
                placeholder="Repita sua nova senha"
              />
              <button pButton label="Concluir" type="submit" ></button>
              <br>
              <button (click)="this.tools.builder().router.navigate(['/login'])"  pButton class="p-button-secondary" label="Voltar" type="button" ></button>
          </form>    
        </div>
        <div class="login-image">
          <div  style="position: relative; top: 76px;" class="login-image-content">

              <img src="assets/img/logo-white-verti.png" class="logo" alt="diamond-layout" />
              <h4>
                <a style="color: white;
                font-size: 16px;
                position: relative;
                top: -14px;
                left: 80px;" href="/index">
            
            <i class="pi pi-arrow-left
            " style="font-weight: 700;
            color: white;
            font-size: 26px;
            position: relative;
            top: 6px;
            left: -7px;"></i>

              Voltar ao site</a> </h4>
          </div>
          <div class="image-footer">            
          </div>
      </div>
  </div>
</div>