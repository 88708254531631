import { element } from 'protractor';
class HandleElementStorage{
    
    public buildIn( element ): string {

        this.verifyInvalidElement( element );

        try {

        const prototype = Object.getPrototypeOf( element ).constructor.name;

          return this.convertAllElementToString( prototype, element );
            
        } catch (error) {
            
            throw new EvalError(error);
        }
    }


    public buildOut( element ): any {

        try {
          return this.convertAllElementToOriginalForm(element);
            
        } catch (error) {
            
            throw new EvalError(error);
        }

    }

    private verifyInvalidElement( element ): void{

        if(element == null){
            throw new EvalError("Valor não pode ser nulo");
        }

        if(element == undefined){
            throw new EvalError("Valor não pode ser undefined");
        }

        if(element == NaN){
            throw new EvalError("Número inválido");
        }
    }

    private convertAllElementToString( prototype, element ): string{

        switch ( prototype ) {

            case "Number":
               return String(element);

            case "Boolean":
               return String(element);
                
            case "Object":
               return JSON.stringify( element );

            case "Function":
                return String(element);

            case "Array":
                return JSON.stringify( element );
                        
            default: "String"
                return element;
        }
    }


    private convertAllElementToOriginalForm( element ): any{

        const object = this.jsonToObject( element );

        if(object){
            return object;
        }

        const number = this.stringToNumber( element );

        if(number){
            return number;
        }

        const boolean = this.stringToBoolean( element );

        if( boolean !== null ){
            return number;
        }

        return element;
    }

    private stringToNumber( element ): number{

            const value = ( element * element );

            if( Number.isNaN(value) ){
                return null;
            }

            return Number(value);
    }

    private stringToBoolean( element ): boolean{

        if( element === 'true' || element === 'false' ){
            return Boolean(element);
        }

        return null;
}

    private jsonToObject( element ): any{
        try {

            return JSON.parse( element )
            
        } catch (error) {
            return null;
        }
    }
}

export default new HandleElementStorage();