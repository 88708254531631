export enum AdditionalDataType {
    V = 'Velocidade do veículo',
    F = 'Código de cerca eletrônica',
    P = 'Pressão do turbo',
    A = 'Tempo acumulado',
    L = 'Nível do tanque',
    I = 'Inclinação',
    T = 'Temperatura',
    R = 'RPM',
    S = 'Estado da entrada/saida',
}
