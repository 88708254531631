
<form [formGroup]="axisSixSimpleRightForm">
    <div class="form-row">
        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Posição Pneu</mat-label>
              <input formControlName="cdPosicaoPneu"  placeholder="Posição Pneu" matInput [readonly]=true [disabled]=true/>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Pneu(nr de fogo)</mat-label>
                    <input  formControlName="pneu" 
                    placeholder="Número de fogo" matInput [readonly]=true [disabled]=true/>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Sensor</mat-label>
              <mat-select formControlName="cdSensor" [(value)]="sensorSixRightSelect" [(ngModel)]="sensorSixRightSelect">
                <mat-option *ngFor="let type of sensorSixRightCombo" [value]="type.id">
                  {{ type.codigoSensor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Min (PSI)</mat-label>
                <input required formControlName="nrPressaoMinima" pattern="[0-9]*" matInput
                 placeholder="Pressão Min (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Máx (PSI)*</mat-label>
                <input required formControlName="nrPressaoMaxima" pattern="[0-9]*" matInput
                placeholder="Pressão Max (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Temp. Máx (°C)*</mat-label>
                <input required formControlName="nrTemperaturaMaxima" required pattern="[0-9]*"  matInput
                placeholder="Temp. Máx (°C)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>
        <div class="form-group col-lg-3">
            <button type="submit" [disabled]="axisSixSimpleRightForm.get('id').value == '' || axisSixSimpleRightForm.get('cdSensor').value == ''" style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDelete(axisSixSimpleRightForm)">
                        Desvincular Sensor
            </button>
        </div>
    </div>
</form>
<form [formGroup]="axisSixSimpleLeftForm">
            <div class="form-row">
                <div class="form-group col-lg-2">
                    <mat-form-field appearance="outline">
                      <mat-label>Posição Pneu</mat-label>
                      <input formControlName="cdPosicaoPneu"  placeholder="Posição Pneu" matInput [readonly]=true [disabled]=true/>
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-2">
                    <mat-form-field appearance="outline">
                            <mat-label>Pneu(nr de fogo)</mat-label>
                            <input  formControlName="pneu"  
                            placeholder="Número de fogo" matInput [readonly]=true [disabled]=true/>
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-2">
                    <mat-form-field appearance="outline">
                      <mat-label>Sensor</mat-label>
                      <mat-select formControlName="cdSensor" [(value)]="sensorSixLeftSelect" [(ngModel)]="sensorSixLeftSelect">
                        <mat-option *ngFor="let type of sensorSixLeftCombo" [value]="type.id">
                          {{ type.codigoSensor }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-1">
                    <mat-form-field appearance="outline">
                            <mat-label>Pressão Min (PSI)</mat-label>
                        <input required formControlName="nrPressaoMinima" pattern="[0-9]*" matInput
                         placeholder="Pressão Min (PSI)" (keypress)="numberOnly($event)" />
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-1">
                    <mat-form-field appearance="outline">
                            <mat-label>Pressão Máx (PSI)*</mat-label>
                        <input required formControlName="nrPressaoMaxima" pattern="[0-9]*" matInput
                        placeholder="Pressão Max (PSI)" (keypress)="numberOnly($event)" />
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-1">
                    <mat-form-field appearance="outline">
                            <mat-label>Temp. Máx (°C)*</mat-label>
                        <input required formControlName="nrTemperaturaMaxima" required pattern="[0-9]*"  matInput
                        placeholder="Temp. Máx (°C)" (keypress)="numberOnly($event)" />
                    </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                    <button type="submit" [disabled]="axisSixSimpleLeftForm.get('id').value == '' || axisSixSimpleLeftForm.get('cdSensor').value == ''" style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDelete(axisSixSimpleLeftForm)">
                                Desvincular Sensor
                    </button>
                </div>
                <div class="form-group col-lg-12" style="text-align: center;">
                    <button type="submit"  style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDeleteAll()">
                        Desvincular Sensores (EIXO 6)
                    </button>
                    <button type="submit"  style="font-size: 11px;padding: 13px 13px;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormAddAll()">
                        Vincular Sensores (EIXO 6)
                    </button>
                </div>
            </div>
    </form>