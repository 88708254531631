<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="funcionalidadeForm">
                    <h2 class="mb-3">Nova Funcionalidade</h2>
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Modulo</mat-label>

                                <mat-select formControlName="modulo" [(value)]="moduloSelected" [readonly]=true [disabled]=true>
                                    <mat-option *ngFor="let modulo of modulo" [value]="modulo.id">
                                        {{ modulo.nmModulo }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Funcionalidade Tipo</mat-label>
                                <mat-select formControlName="funcionalidadeTipo" [(value)]="funcionalidadeTipoSelected" [readonly]=true [disabled]=true>
                                    <mat-option *ngFor="let type of types" [value]="type.id">
                                        {{ type.nmFuncionalidadeTipo }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Código da Funcionalidade</mat-label>
                                <input formControlName="cdFuncionalidade" matInput
                                    placeholder="Código da Funcionalidade" maxlength="20"  [readonly]=true [disabled]=true/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome Funcionalidade</mat-label>
                                <input formControlName="nmFuncionalidade" matInput placeholder="Nome Funcionalidade"
                                    maxlength="50"  [readonly]=true [disabled]=true/>
                            </mat-form-field>
                        </div>

                        <div class="form-group col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Desc. Funcionalidade</mat-label>
                                <input formControlName="dsFuncionalidade" matInput
                                    placeholder="Descrição Funcionalidade" maxlength="200"  [readonly]=true [disabled]=true/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label>URL Path</mat-label>
                                <input formControlName="cdPath" matInput placeholder="Cadastro Path" maxlength="200"  [readonly]=true [disabled]=true/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Sigla da Funcionalidade</mat-label>
                                <input formControlName="sgFuncionalidade" matInput placeholder="Funcionalidade"
                                    maxlength="10"  [readonly]=true [disabled]=true/>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>