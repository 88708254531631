
<form [formGroup]="axisEightDoubleLeftIntForm">
    <div class="form-row">
        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Posição Pneu</mat-label>
              <input formControlName="cdPosicaoPneu"  placeholder="Posição Pneu" matInput [readonly]=true [disabled]=true/>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Pneu(nr de fogo)</mat-label>
                    <input  formControlName="pneu"  
                    placeholder="Número de fogo" matInput [readonly]=true [disabled]=true/>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Sensor</mat-label>
              <mat-select formControlName="cdSensor" [(value)]="sensorEightIntLeftSelect" [(ngModel)]="sensorEightIntLeftSelect">
                <mat-option *ngFor="let type of sensorEightIntLeftCombo" [value]="type.id">
                  {{ type.codigoSensor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Min (PSI)</mat-label>
                <input required formControlName="nrPressaoMinima" pattern="[0-9]*" matInput
                 placeholder="Pressão Min (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Máx (PSI)*</mat-label>
                <input required formControlName="nrPressaoMaxima" pattern="[0-9]*" matInput
                placeholder="Pressão Max (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Temp. Máx (°C)*</mat-label>
                <input required formControlName="nrTemperaturaMaxima" required pattern="[0-9]*"  matInput
                placeholder="Temp. Máx (°C)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>
        <div class="form-group col-lg-3">
            <button type="submit" [disabled]="axisEightDoubleLeftIntForm.get('id').value == '' || axisEightDoubleLeftIntForm.get('cdSensor').value == ''" style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDelete(axisEightDoubleLeftIntForm)">
                        Desvincular Sensor
            </button>
        </div>
    </div>
</form>
<form [formGroup]="axisEightDoubleLeftExtForm">
            <div class="form-row">

                <div class="form-group col-lg-2">
                    <mat-form-field appearance="outline">
                      <mat-label>Posição Pneu</mat-label>
                      <input formControlName="cdPosicaoPneu"  placeholder="Posição Pneu" matInput [readonly]=true [disabled]=true/>
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-2">
                    <mat-form-field appearance="outline">
                            <mat-label>Pneu(nr de fogo)</mat-label>
                            <input  formControlName="pneu"  
                            placeholder="Número de fogo" matInput [readonly]=true [disabled]=true/>
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-2">
                    <mat-form-field appearance="outline">
                      <mat-label>Sensor</mat-label>
                      <mat-select formControlName="cdSensor" [(value)]="sensorEightExtLeftSelect" [(ngModel)]="sensorEightExtLeftSelect">
                        <mat-option *ngFor="let type of sensorEightExtLeftCombo" [value]="type.id">
                          {{ type.codigoSensor }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-1">
                    <mat-form-field appearance="outline">
                            <mat-label>Pressão Min (PSI)</mat-label>
                        <input required formControlName="nrPressaoMinima" pattern="[0-9]*" matInput
                         placeholder="Pressão Min (PSI)" (keypress)="numberOnly($event)" />
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-1">
                    <mat-form-field appearance="outline">
                            <mat-label>Pressão Máx (PSI)*</mat-label>
                        <input required formControlName="nrPressaoMaxima" pattern="[0-9]*" matInput
                        placeholder="Pressão Max (PSI)" (keypress)="numberOnly($event)" />
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-1">
                    <mat-form-field appearance="outline">
                            <mat-label>Temp. Máx (°C)*</mat-label>
                        <input required formControlName="nrTemperaturaMaxima" required pattern="[0-9]*"  matInput
                        placeholder="Temp. Máx (°C)" (keypress)="numberOnly($event)" />
                    </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                    <button type="submit" [disabled]="axisEightDoubleLeftExtForm.get('id').value == '' || axisEightDoubleLeftExtForm.get('cdSensor').value == ''" style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDelete(axisEightDoubleLeftExtForm)">
                                Desvincular Sensor
                    </button>
                </div>
            </div>
    </form>

    <form [formGroup]="axisEightDoubleRightIntForm">
        <div class="form-row">

            <div class="form-group col-lg-2">
                <mat-form-field appearance="outline">
                  <mat-label>Posição Pneu</mat-label>
                  <input formControlName="cdPosicaoPneu"  placeholder="Posição Pneu" matInput [readonly]=true [disabled]=true/>
                </mat-form-field>
            </div>
    
            <div class="form-group col-lg-2">
                <mat-form-field appearance="outline">
                        <mat-label>Pneu(nr de fogo)</mat-label>
                        <input  formControlName="pneu"  
                        placeholder="Número de fogo" matInput [readonly]=true [disabled]=true/>
                </mat-form-field>
            </div>
    
            <div class="form-group col-lg-2">
                <mat-form-field appearance="outline">
                  <mat-label>Sensor</mat-label>
                  <mat-select formControlName="cdSensor" [(value)]="sensorEightIntRightSelect" [(ngModel)]="sensorEightIntRightSelect">
                    <mat-option *ngFor="let type of sensorEightIntRightCombo" [value]="type.id">
                      {{ type.codigoSensor }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
    
            <div class="form-group col-lg-1">
                <mat-form-field appearance="outline">
                        <mat-label>Pressão Min (PSI)</mat-label>
                    <input required formControlName="nrPressaoMinima" pattern="[0-9]*" matInput
                     placeholder="Pressão Min (PSI)" (keypress)="numberOnly($event)" />
                </mat-form-field>
            </div>
    
            <div class="form-group col-lg-1">
                <mat-form-field appearance="outline">
                        <mat-label>Pressão Máx (PSI)*</mat-label>
                    <input required formControlName="nrPressaoMaxima" pattern="[0-9]*" matInput
                    placeholder="Pressão Max (PSI)" (keypress)="numberOnly($event)" />
                </mat-form-field>
            </div>
    
            <div class="form-group col-lg-1">
                <mat-form-field appearance="outline">
                        <mat-label>Temp. Máx (°C)*</mat-label>
                    <input required formControlName="nrTemperaturaMaxima" required pattern="[0-9]*"  matInput
                    placeholder="Temp. Máx (°C)" (keypress)="numberOnly($event)" />
                </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
                <button type="submit" [disabled]="axisEightDoubleRightIntForm.get('id').value == '' || axisEightDoubleRightIntForm.get('cdSensor').value == ''" style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDelete(axisEightDoubleRightIntForm)">
                            Desvincular Sensor
                </button>
            </div>
        </div>
</form>

<form [formGroup]="axisEightDoubleRightExtForm">
    <div class="form-row">

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Posição Pneu</mat-label>
              <input formControlName="cdPosicaoPneu"  placeholder="Posição Pneu" matInput [readonly]=true [disabled]=true/>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Pneu(nr de fogo)</mat-label>
                    <input  formControlName="pneu"  
                    placeholder="Número de fogo" matInput [readonly]=true [disabled]=true/>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Sensor</mat-label>
              <mat-select formControlName="cdSensor" [(value)]="sensorEightExtRightSelect" [(ngModel)]="sensorEightExtRightSelect">
                <mat-option *ngFor="let type of sensorEightExtRightCombo" [value]="type.id">
                  {{ type.codigoSensor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Min (PSI)</mat-label>
                <input required formControlName="nrPressaoMinima" pattern="[0-9]*" matInput
                 placeholder="Pressão Min (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Máx (PSI)*</mat-label>
                <input required formControlName="nrPressaoMaxima" pattern="[0-9]*" matInput
                placeholder="Pressão Max (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Temp. Máx (°C)*</mat-label>
                <input required formControlName="nrTemperaturaMaxima" required pattern="[0-9]*"  matInput
                placeholder="Temp. Máx (°C)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>
        <div class="form-group col-lg-3">
            <button type="submit" [disabled]="axisEightDoubleRightExtForm.get('id').value == '' || axisEightDoubleRightExtForm.get('cdSensor').value == ''" style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDelete(axisEightDoubleRightExtForm)">
                        Desvincular Sensor
            </button>
        </div>
        <div class="form-group col-lg-12" style="text-align: center;">
            <button type="submit"  style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDeleteAll()">
                Desvincular Sensores (EIXO 8)
            </button>
            <button type="submit"  style="font-size: 11px;padding: 13px 13px;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormAddAll()">
                Vincular Sensores (EIXO 8)
            </button>
        </div>
    </div>
</form>