import { Component, OnInit } from '@angular/core';
import {TransporterCore} from './transporter.core';
import {FormBuilder} from '@angular/forms';
import {ApiBaseService} from '../../services/migrate/api.base.service';
import ApiService from '../../services/api.service';
import {VehiclesMenuService} from '../../services/vehicles-menu.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TransporterService} from '../../services/transporter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Transporter} from '../../models/transporter.model';

@Component({
  selector: 'app-clone-transporter',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class CloneTransporterComponent extends TransporterCore implements OnInit {
  public title = 'Clonar Transportador/Agregado';
  public transporter: Transporter;
  public transporterUuid = '';
  public formMode: any;
  public registrationTypes: Array<any>;

  constructor(formBuilder: FormBuilder,
              apiBaseService: ApiBaseService,
              service: ApiService,
              vehicleService: VehiclesMenuService,
              transporterService: TransporterService,
              spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute,
              router: Router) {
    super(formBuilder, apiBaseService, vehicleService, service, transporterService, spinner, router);
    this.transporterService = transporterService;
    this.service = service;
    this.transporterFormGroup = super.loadFormDefinition();
    this.userFormGroup = super.loadUserFormDefinition();
  }

  ngOnInit(): void {
    console.log('Iniciando o componente de clonagem do transportador');
    this.transporterUuid = this.activatedRoute.snapshot.params.uuid;
    this.formMode = 'clone';
    this.registrationTypes = this.getRegistrationType();
    this.loadTransporter(this.transporterUuid, true);
  }

  submitForm(): void {
    this.createTransporter();
  }
}
