import { IStorageAccess } from './IstorageAccess';
import  StorageCore  from './storageCore'

export abstract class StorageAccess implements IStorageAccess{

    whereKeyIs(key : string ): StorageCore {
        return new StorageCore(key);
    }

    removeAll(): void {
        const storageCore = new StorageCore(null);
        return storageCore.removeAll();
    }

 } 