import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "./services/authentication.service";
import { UuidService } from "./services/uuid.service";
import TypeStorage from "./storageCore/typeStorage";
import keepLoggedIn from "./storageCore/keepLoggedIn";
import { PrimeNGConfig } from "primeng/api";
import { Router } from "@angular/router";
import storageX from "./storageCore/storageX";
import { STORAGE_KEY_UUID } from "./storageCore/constStorageKeys";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Setera";
  public currentUser;
  showMap = true;

  constructor(
    private config: PrimeNGConfig,
    private route: Router,
    private authenticationService: AuthenticationService,
    private primengConfig: PrimeNGConfig,
    private uuidService: UuidService
  ) {
    storageX.whereKeyIs(STORAGE_KEY_UUID).set(uuidService.getInstanceUuid());
    TypeStorage.loadStorage(keepLoggedIn.statusKeepLoggedIn());

    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  public actived(event) {
    /* implementar */
    const proto = event;

    const url = this.route.routerState.snapshot.url;

    const i = url.indexOf("/");
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }
}
