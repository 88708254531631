<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

        <mat-tab-group>
          <mat-tab label="Dados">
            <ng-template matTabContent>
              <div class="row mt-3 no-gutters p-4 row-form">
                <div class="col-12">
                  <form [formGroup]="pneuForm" (ngSubmit)="submitForm()">
                    <h2 class="mb-3">Novo Pneu</h2>
                    <div class="form-row" >
                      <div class="form-group col-md-4">
                        <mat-form-field appearance="outline">
                          <mat-label>Tipo de Cadastro</mat-label>
                          <mat-select formControlName="selecaoEmpresa" [(value)]="empresaFilterSelected" [(ngModel)]="empresaFilterSelected"
                          (selectionChange)="this.filterCompany()" required>
                            <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                              {{ type.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <validation-errors [form]="pneuForm" controlLabel="Tipo de Cadastro" controlName="selecaoEmpresa">
                        </validation-errors>
                      </div>
                      <div class="form-group col-md-4">
                        <mat-form-field appearance="outline">
                          <mat-label *ngIf="pneuForm.get('selecaoEmpresa').value == null">Selecione um tipo de cadastro</mat-label>
                          <mat-label *ngIf="pneuForm.get('selecaoEmpresa').value == 'Matriz'">Matrizes</mat-label>
                          <mat-label *ngIf="pneuForm.get('selecaoEmpresa').value == 'Filial'">Filiais</mat-label>
                          <mat-label *ngIf="pneuForm.get('selecaoEmpresa').value == 'Divisão'">Divisões</mat-label>
                          <mat-select formControlName="idEmpresa" [(value)]="empresaSelected" [(ngModel)]="empresaSelected" required>
                            <mat-option *ngFor="let type of companiesFilter" [value]="type.id">
                              {{ type.nome }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <validation-errors [form]="pneuForm" controlLabel="Matriz/Divisão/Filial" controlName="idEmpresa">
                        </validation-errors>
                      </div>
                      <div class="form-group col-lg-4">
                        <mat-form-field appearance="outline">
                          <mat-label>Tamanho</mat-label>
                          <input formControlName="tamanho" matInput placeholder="Tamanho" maxlength="50" style="text-transform: uppercase;" required />
                        </mat-form-field>
                        <validation-errors [form]="pneuForm" controlLabel="Tamanho" controlName="tamanho">
                        </validation-errors>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-lg-6"> 
                        <mat-form-field appearance="outline">
                          <mat-label>Marca</mat-label>
                          <input formControlName="marca" matInput placeholder="Marca" style="text-transform: uppercase;"
                            maxlength="100" required/>
                        </mat-form-field>
                        <validation-errors [form]="pneuForm" controlLabel="Marca" controlName="marca">
                        </validation-errors>
                      </div>
                      <div class="form-group col-lg-6">
                        <mat-form-field appearance="outline">
                          <mat-label>Número de fogo</mat-label>
                          <input formControlName="numeroFogo" matInput placeholder="Número de fogo" maxlength="15" style="text-transform: uppercase;" required />
                        </mat-form-field>
                        <validation-errors [form]="pneuForm" controlLabel="Número de fogo" controlName="numeroFogo">
                        </validation-errors>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-lg-6"> 
                        <mat-form-field appearance="outline">
                          <mat-label>Km de Entrada</mat-label>
                          <input formControlName="kmEntrada" matInput placeholder="km de entrada" pattern="[0-9]*"
                         (keypress)="numberOnly($event)"/>
                        </mat-form-field>
                      </div>
                      <div class="form-group col-lg-6">
                        <mat-form-field appearance="outline">
                          <mat-label>Km de Saída</mat-label>
                          <input formControlName="kmSaida" matInput placeholder="Número de fogo" pattern="[0-9]*"
                          (keypress)="numberOnly($event)" />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-lg-12">
                        <mat-form-field appearance="outline">
                          <mat-label>Observação</mat-label>
                          <input formControlName="observacao" matInput maxlength="200" placeholder="Observação" style="text-transform: uppercase;" />
                        </mat-form-field>
                        <!-- <span class="alert-danger" *ngIf="!userForm.get('nrMatricula').valid">
                          Matricula é um campo numérico e obrigatório!
                        </span> -->
                      </div>
                    </div>      
                    <div *ngIf="errorMessage" class="alert alert-danger">
                      {{ errorMessage }}
                    </div>
                    <div class="form-group mt-2 mb-0 text-center">
                      <back-button></back-button>
                      <button type="submit" [disabled]="!pneuForm.valid"
                        class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                        Salvar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab label="Posição Pneu" [disabled]="!pneuId">
            <ng-template matTabContent>
              <app-pneu-posicao [pneuId]="pneuId" [pneu]="pneu">
              </app-pneu-posicao>
            </ng-template> 
          </mat-tab>
        </mat-tab-group>
    </div>
</div>
