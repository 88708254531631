import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { VeiculoSensor } from "src/app/models/VeiculoSensor.model";
import { VwSensor } from "src/app/models/VwSensor.model";
import { EventEmitterSensorService } from "src/app/services/event-emitter-sensor.service";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import Swal from "sweetalert2";

@Component({
  selector: "app-axle-two-dual",
  templateUrl: "./axle-two-dual.component.html",
  styleUrls: ["./axle-two-dual.component.scss"],
})
export class AxleTwoDualComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() sensoresDisponiveis: any;
  @Input() veiculoId: number;

  @Output() atualizarSensores = new EventEmitter<void>();

  public sensorTwoIntLeftSelect: any = [];
  public sensorTwoIntRightSelect: any = [];

  public sensorTwoExtLeftSelect: any = [];
  public sensorTwoExtRightSelect: any = [];

  public sensorTwoIntRightCombo: any = [];
  public sensorTwoIntLeftCombo: any = [];

  public sensorTwoExtRightCombo: any = [];
  public sensorTwoExtLeftCombo: any = [];

  public axisTwoDoubleLeftIntForm: FormGroup;
  public axisTwoDoubleLeftExtForm: FormGroup;

  public axisTwoDoubleRightIntForm: FormGroup;
  public axisTwoDoubleRightExtForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private serviceApiBase: ApiBaseService,
    private componentResetService: EventEmitterSensorService
  ) {}

  ngOnInit(): void {
    this.componentResetService.reiniciarComponentes$.subscribe(async (a) => {
      this.sensoresDisponiveis = null;
      this.veiculoSensores = null;
      this.sensorTwoIntRightCombo = [];
      this.sensorTwoIntLeftCombo = [];
      this.sensorTwoExtRightCombo = [];
      this.sensorTwoExtLeftCombo = [];

      const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();

      const eixo = await this.serviceApiBase.get<any>({
        url: new VeiculoSensor()["nomeConsultaApiBase"] + this.veiculoId,
      });

      this.veiculoSensores = eixo["content"];

      const sensor = await this.serviceApiBase.get<any>({
        url:
          new VwSensor()["nomeConsultaApiBaseSensor"] +
          this.veiculoId +
          "/perfil/" +
          perfil,
      });

      this.sensoresDisponiveis = sensor;

      if (this.sensoresDisponiveis.length > 0) {
        this.sensoresDisponiveis.forEach((element) => {
          this.sensorTwoIntRightCombo.push(element);
          this.sensorTwoIntLeftCombo.push(element);
          this.sensorTwoExtRightCombo.push(element);
          this.sensorTwoExtLeftCombo.push(element);
        });
      }

      this.loadSensor();
    });

    this.axisTwoDoubleLeftIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisTwoDoubleLeftExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisTwoDoubleRightIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisTwoDoubleRightExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    if (this.sensoresDisponiveis.length > 0) {
      this.sensoresDisponiveis.forEach((element) => {
        this.sensorTwoExtRightCombo.push(element);
        this.sensorTwoExtLeftCombo.push(element);
        this.sensorTwoIntRightCombo.push(element);
        this.sensorTwoIntLeftCombo.push(element);
      });
    }

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisTwoDoubleLeftIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 2 ESQ INT"
      );
      this.axisTwoDoubleLeftExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 2 ESQ EXT"
      );

      this.axisTwoDoubleRightIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 2 DIR INT"
      );
      this.axisTwoDoubleRightExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 2 DIR EXT"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo2DireitoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 6) {
              eixo2DireitoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 6) {
              eixo2DireitoExt = filter;
            }
          }
        });

        var eixo2EsquerdoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 5) {
              eixo2EsquerdoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 5) {
              eixo2EsquerdoExt = filter;
            }
          }
        });

        var eixo2DireitoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 22) {
              eixo2DireitoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 22) {
              eixo2DireitoInt = filter;
            }
          }
        });

        var eixo2EsquerdoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 21) {
              eixo2EsquerdoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 21) {
              eixo2EsquerdoInt = filter;
            }
          }
        });

        // EIXO 2 DIR EXT
        if (eixo2DireitoExt != null) {
          this.axisTwoDoubleRightExtForm.controls["id"].setValue(
            eixo2DireitoExt.id
          );

          if (eixo2DireitoExt.sensor != null) {
            this.sensorTwoExtRightCombo.push(eixo2DireitoExt.sensor);
            this.sensorTwoExtRightSelect = eixo2DireitoExt.sensor.id;
          }

          if (eixo2DireitoExt.pneu != null) {
            this.axisTwoDoubleRightExtForm.controls["pneu"].setValue(
              eixo2DireitoExt.pneu.numeroFogo
            );
          }

          this.axisTwoDoubleRightExtForm.controls["nrPressaoMinima"].setValue(
            eixo2DireitoExt.nrPressaoMinima
          );

          this.axisTwoDoubleRightExtForm.controls["nrPressaoMaxima"].setValue(
            eixo2DireitoExt.nrPressaoMaxima
          );

          this.axisTwoDoubleRightExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo2DireitoExt.nrTemperaturaMaxima);
        }

        // EIXO 2 ESQ EXT
        if (eixo2EsquerdoExt != null) {
          this.axisTwoDoubleLeftExtForm.controls["id"].setValue(
            eixo2EsquerdoExt.id
          );

          if (eixo2EsquerdoExt.sensor != null) {
            this.sensorTwoExtLeftCombo.push(eixo2EsquerdoExt.sensor);
            this.sensorTwoExtLeftSelect = eixo2EsquerdoExt.sensor.id;
          }

          if (eixo2EsquerdoExt.pneu != null) {
            this.axisTwoDoubleLeftExtForm.controls["pneu"].setValue(
              eixo2EsquerdoExt.pneu.numeroFogo
            );
          }

          this.axisTwoDoubleLeftExtForm.controls["nrPressaoMinima"].setValue(
            eixo2EsquerdoExt.nrPressaoMinima
          );

          this.axisTwoDoubleLeftExtForm.controls["nrPressaoMaxima"].setValue(
            eixo2EsquerdoExt.nrPressaoMaxima
          );

          this.axisTwoDoubleLeftExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo2EsquerdoExt.nrTemperaturaMaxima);
        }

        // EIXO 2 DIR INT
        if (eixo2DireitoInt != null) {
          this.axisTwoDoubleRightIntForm.controls["id"].setValue(
            eixo2DireitoInt.id
          );

          if (eixo2DireitoInt.sensor != null) {
            this.sensorTwoIntRightCombo.push(eixo2DireitoInt.sensor);
            this.sensorTwoIntRightSelect = eixo2DireitoInt.sensor.id;
          }

          if (eixo2DireitoInt.pneu != null) {
            this.axisTwoDoubleRightIntForm.controls["pneu"].setValue(
              eixo2DireitoInt.pneu.numeroFogo
            );
          }

          this.axisTwoDoubleRightIntForm.controls["nrPressaoMinima"].setValue(
            eixo2DireitoInt.nrPressaoMinima
          );

          this.axisTwoDoubleRightIntForm.controls["nrPressaoMaxima"].setValue(
            eixo2DireitoInt.nrPressaoMaxima
          );

          this.axisTwoDoubleRightIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo2DireitoInt.nrTemperaturaMaxima);
        }

        // EIXO 2 ESQ INT
        if (eixo2EsquerdoInt != null) {
          this.axisTwoDoubleLeftIntForm.controls["id"].setValue(
            eixo2EsquerdoInt.id
          );

          if (eixo2EsquerdoInt.sensor != null) {
            this.sensorTwoIntLeftCombo.push(eixo2EsquerdoInt.sensor);
            this.sensorTwoIntLeftSelect = eixo2EsquerdoInt.sensor.id;
          }

          if (eixo2EsquerdoInt.pneu != null) {
            this.axisTwoDoubleLeftIntForm.controls["pneu"].setValue(
              eixo2EsquerdoInt.pneu.numeroFogo
            );
          }

          this.axisTwoDoubleLeftIntForm.controls["nrPressaoMinima"].setValue(
            eixo2EsquerdoInt.nrPressaoMinima
          );

          this.axisTwoDoubleLeftIntForm.controls["nrPressaoMaxima"].setValue(
            eixo2EsquerdoInt.nrPressaoMaxima
          );

          this.axisTwoDoubleLeftIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo2EsquerdoInt.nrTemperaturaMaxima);
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public async submitFormDelete(form: FormGroup): Promise<void> {
    this.spinner.show();
    try {
      const values = form.value;
      var post;

      post = {
        veiculo: {
          id: this.veiculoId,
        },
        nrPressaoMinima: values.nrPressaoMinima,
        nrPressaoMaxima: values.nrPressaoMaxima,
        nrTemperaturaMaxima: values.nrTemperaturaMaxima,
        sensor: {
          id: values.cdSensor,
        },
        pneu: null,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      console.log(post);

      const result = await this.serviceApiBase.put<VeiculoSensor>(
        values.id,
        new VeiculoSensor()["nomeConsultaApiBaseRemove"],
        post
      );

      form.get("cdSensor").setValue("");

      this.spinner.hide();

      this.handleDelete();

      this.atualizarSensores.emit();
    } catch (error) {
      this.spinner.hide();
      //this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public async submitFormDeleteAll(): Promise<void> {
    this.spinner.show();
    try {
      var estepeArray: any = [];
      var posicoesPneusList: any = [];

      estepeArray.push(this.axisTwoDoubleLeftIntForm.value);
      estepeArray.push(this.axisTwoDoubleRightIntForm.value);
      estepeArray.push(this.axisTwoDoubleLeftExtForm.value);
      estepeArray.push(this.axisTwoDoubleRightExtForm.value);

      if (estepeArray.length > 0) {
        estepeArray.forEach((element) => {
          var posicaoPneuResquest = {
            cdPosicaoPneu: element.cdPosicaoPneu,
          };
          posicoesPneusList.push(posicaoPneuResquest);
        });
      }

      var post = {
        veiculoSensores: posicoesPneusList,
      };

      const result = await this.serviceApiBase.put<VeiculoSensor>(
        this.veiculoId,
        new VeiculoSensor()["nomeConsultaApiBaseRemoveAll"],
        post
      );

      this.axisTwoDoubleLeftIntForm.get("cdSensor").setValue("");
      this.axisTwoDoubleRightIntForm.get("cdSensor").setValue("");
      this.axisTwoDoubleLeftExtForm.get("cdSensor").setValue("");
      this.axisTwoDoubleRightExtForm.get("cdSensor").setValue("");

      this.spinner.hide();

      this.handleDeleteAll();

      this.atualizarSensores.emit();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public async submitFormAddAll(): Promise<void> {
    this.spinner.show();
    try {
      var estepeArray: any = [];
      var posicoesPneusList: any = [];

      estepeArray.push(this.axisTwoDoubleLeftIntForm.value);
      estepeArray.push(this.axisTwoDoubleRightIntForm.value);
      estepeArray.push(this.axisTwoDoubleLeftExtForm.value);
      estepeArray.push(this.axisTwoDoubleRightExtForm.value);

      if (estepeArray.length > 0) {
        var boolean = this.verificarDuplicados(estepeArray);

        if (boolean == true) {
          this.handleItensDuplicados();
          this.spinner.hide();
          return;
        }

        estepeArray.forEach((element) => {
          if (element.cdSensor != "") {
            var posicaoPneuResquest = {
              cdPosicaoPneu: element.cdPosicaoPneu,
              cdSensor: element.cdSensor,
              pneu: element.pneu,
              nrPressaoMinima: element.nrPressaoMinima,
              nrPressaoMaxima: element.nrPressaoMaxima,
              nrTemperaturaMaxima: element.nrTemperaturaMaxima,
              idPerfil: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
            };
            posicoesPneusList.push(posicaoPneuResquest);
          }
        });
      }

      var post = {
        veiculoSensores: posicoesPneusList,
      };

      const result = await this.serviceApiBase.put<VeiculoSensor>(
        this.veiculoId,
        new VeiculoSensor()["nomeConsultaApiBaseAddAll"],
        post
      );

      this.spinner.hide();

      this.handleAddAll();

      this.atualizarSensores.emit();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  verificarDuplicados(axisOneArray: any[]): boolean {
    const seen = new Set();
    for (const item of axisOneArray) {
      if (seen.has(item.cdSensor)) {
        return true; // Retorna true se encontrar um valor duplicado
      } else {
        seen.add(item.cdSensor);
      }
    }
    return false; // Retorna false se não houver valores duplicados
  }

  public async handleItensDuplicados(): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Sensores selecionados estão com duplicidade, por favor corrigir!",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public async handleAddAll(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Sensores do EIXO 2 vinculados com sucesso",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public async handleDeleteAll(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Sensores do EIXO 2 desvinculados com sucesso",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public async handleDelete(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Sensor desvinculados com sucesso",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }
}
