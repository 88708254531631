<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])"
                        data-title="Voltar"><i class="fas fa-arrow-left"></i></a>
                    Consulta de dados CANBUS
                </h1>
            </div>
        </div>
        <div *ngIf="analyzeReportSupervisorsPage?.page.totalElements === 0">
            <div class="row no-gutters mt-3">
                <p>Nenhum registro até o momento...</p>
            </div>
        </div>
        <div class="row no-gutters mt-3">
            <div class="mat-elevation table-content">
                <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field fxFlex="10%" class="ml-2">
                        <mat-label>Marca</mat-label>
                        <mat-select (selectionChange)="this.applyFilter()" [(value)]="filterBrandsSelected"
                            [(ngModel)]="filterBrandsSelected">
                            <mat-option *ngFor="let type of brandsSelect" [value]="type">
                                {{ type }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="20%" class="ml-2">
                        <mat-label>Modelos</mat-label>
                        <mat-select (selectionChange)="this.applyFilter()" [(value)]="filterModelsSelected"
                            [(ngModel)]="filterModelsSelected">
                            <mat-option *ngFor="let type of modelsSelect" [value]="type">
                                {{ type }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="10%" class="ml-2">
                        <mat-label>Ano</mat-label>
                        <mat-select (selectionChange)="this.applyFilter()" [(value)]="filterYearsSelected"
                            [(ngModel)]="filterYearsSelected">
                            <mat-option *ngFor="let type of yearCambusSelect" [value]="type">
                                {{ type }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="ml-2" (click)="resetFilters()">
                        Limpar
                    </button>
                </div>
                <table mat-table [dataSource]="analyzesManagers" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="brand" style="padding: 10px;">
                        <th mat-header-cell *matHeaderCellDef>Marca</th>
                        <td mat-cell *matCellDef="let element">{{ element.brand }}</td>
                    </ng-container>
                    <ng-container matColumnDef="model">
                        <th mat-header-cell *matHeaderCellDef>Modelo</th>
                        <td mat-cell *matCellDef="let element">{{ element.model }}</td>
                    </ng-container>
                    <ng-container matColumnDef="yearCambus">
                        <th mat-header-cell *matHeaderCellDef>Ano</th>
                        <td mat-cell *matCellDef="let element">{{ element.yearCambus}}</td>
                    </ng-container>
                    <ng-container matColumnDef="acceleration">
                        <th mat-header-cell *matHeaderCellDef>Acel</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.acceleration === 'T'" style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.acceleration === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="brakes">
                        <th mat-header-cell *matHeaderCellDef>Frenag</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.brakes === 'T'" style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.brakes === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="consumptionInst">
                        <th mat-header-cell *matHeaderCellDef>Cons Inst</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.consumptionInst === 'T'"
                                style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.consumptionInst === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="consumptionStopped">
                        <th mat-header-cell *matHeaderCellDef>Cons Parado</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.consumptionStopped === 'T'"
                                style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.consumptionStopped === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="deepAcceleration">
                        <th mat-header-cell *matHeaderCellDef>Acel Fundo
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.deepAcceleration === 'T'"
                                style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.deepAcceleration === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="movementHourmeter">
                        <th mat-header-cell *matHeaderCellDef>Hor Mov
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.movementHourmeter === 'T'"
                                style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.movementHourmeter === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="odometer">
                        <th mat-header-cell *matHeaderCellDef>Odom</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.odometer === 'T'" style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.odometer === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="rpm">
                        <th mat-header-cell *matHeaderCellDef>RPM</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.rpm === 'T'" style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.rpm === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="speed">
                        <th mat-header-cell *matHeaderCellDef>Velo</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.speed === 'T'" style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.speed === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="stoppedHourmeter">
                        <th mat-header-cell *matHeaderCellDef>Hor Parado</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.stoppedHourmeter === 'T'"
                                style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.stoppedHourmeter === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="tank">
                        <th mat-header-cell *matHeaderCellDef>Tanque</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.tank === 'T'" style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.tank === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="temperature">
                        <th mat-header-cell *matHeaderCellDef>Temp</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.temperature === 'T'" style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.temperature === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="torque">
                        <th mat-header-cell *matHeaderCellDef>Torque</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.torque === 'T'" style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.torque === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="totalCons">
                        <th mat-header-cell *matHeaderCellDef>Total Consu.</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.totalCons === 'T'" style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.totalCons === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="totalHourmeter">
                        <th mat-header-cell *matHeaderCellDef>Total Horim.</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.totalHourmeter === 'T'"
                                style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.totalHourmeter === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="turbo">
                        <th mat-header-cell *matHeaderCellDef>Turbo</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="element.turbo === 'T'" style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="element.turbo === 'F'" color="warn">cancel</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="analyzeReportSupervisorsPage.page.size"
                    (page)="onChangePage($event)" [length]="analyzeReportSupervisorsPage.page.totalElements"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>