import { ContentInterface } from "./../../contracts/Content.interface";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

import { Vehicle } from "../../models/Vehicle.model";
import { ApiService } from "../../services/api.service";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { Company } from "src/app/models/Company.model";
import { getFormValidationErrors } from "src/app/helpers/validation.errors";
import { VeiculoSensor } from "src/app/models/VeiculoSensor.model";
import { VwSensor } from "src/app/models/VwSensor.model";

@Component({
  selector: "app-create-vehicle",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ViewVehicleComponent implements OnInit {
  public vehicleForm: FormGroup;
  public veiculoTipoSelected;
  public veiculoCombustivelTipoSelected;
  public empresaSelected;
  public categoriaVeiculoSelected;
  public veiculo;
  public veiculoId;
  public title = "Visualizar Veículo";
  public titleVar = "";

  public axisQuantity = null;
  public empresaFilterSelected;
  public veiculoSensores: any;

  public errorMessage;
  public fuelTypes: any = [];
  public vehicleTypes: any = [];
  public categoriaVeiculos: any = [];
  public company: any = [];
  public axisSelect: any = [];
  public tipoRodagemSelect: any = [];
  public companiesFilter: any = [];
  public cambioAutomaticoSelect: any = [];
  public sensoresDisponiveis: any;

  public cambioAutomatico: any = [
    { name: "SIM", value: true },
    { name: "NÃO", value: false },
  ];

  public companiesSelect: any = [
    { name: "Divisão", value: 1 },
    { name: "Matriz", value: 2 },
    { name: "Filial", value: 3 },
  ];

  public quantidadeEixo = [
    { name: "1 EIXO", value: 1 },
    { name: "2 EIXOS", value: 2 },
    { name: "3 EIXOS", value: 3 },
    { name: "4 EIXOS", value: 4 },
    { name: "5 EIXOS", value: 5 },
    { name: "6 EIXOS", value: 6 },
    { name: "7 EIXOS", value: 7 },
    { name: "8 EIXOS", value: 8 },
    { name: "9 EIXOS", value: 9 },
  ];

  public tipoRodagem = [
    { name: "SIMPLES", value: "S" },
    { name: "DUPLA", value: "D" },
  ];
  public errorDebug;

  public envioLote = false;
  public btnText = "Processar";
  public btnDisabled = false;

  uploadListener($event: any): void {}

  fncProcessar($event: any): void {}

  ngOnInit(): void {
    this.vehicleForm = this.formBuilder.group(
      {
        selecaoEmpresa: [""],
        idEmpresa: ["", Validators.required],
        cdPlaca: ["", Validators.required],
        frota: [""],
        cdRenavam: [""],
        cdChassi: [""],
        cdAntt: [""],
        cdPbt: [""],
        nmMarca: [""],
        nmModelo: [""],
        nrQuantidadeEixo: [""],
        idVeiculoTipo: ["", Validators.required],
        idVeiculoCombustivelTipo: ["", Validators.required],
        nrAnoFabricacao: [""],
        nrAnoModelo: [""],
        cdCor: [""],
        tipoRodagem: [""],
        nmCidade: [""],
        nmEstado: [""],
        nmPais: [""],
        nrCoeficienteConsumo: [""],
        nrOdometro: [""],
        nrCapacidadeTanque: [""],
        nrTelefoneProprietario: [""],
        nmNomeProprietario: [""],
        dsEmail: [""],
        idCategoriaVeiculo: [""],
        cambioAutomatico: [false],
      },
      { updateOn: "blur" }
    );

    this.veiculoId = this.activatedRoute.snapshot.params["id"];
    this.eixos(this.veiculoId);
    this.sensores(this.veiculoId);
    this.loadVeiculo(this.veiculoId);
  }

  public async eixos(veiculoId: any) {
    const sensor = await this.serviceApiBase.get<any>({
      url: new VeiculoSensor()["nomeConsultaApiBase"] + veiculoId,
    });

    this.veiculoSensores = sensor["content"];
  }

  public async sensores(veiculoId: any) {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();

    const sensor = await this.serviceApiBase.get<any>({
      url:
        new VwSensor()["nomeConsultaApiBaseSensor"] +
        veiculoId +
        "/perfil/" +
        perfil,
    });

    this.sensoresDisponiveis = sensor;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public async loadVeiculo(id): Promise<void> {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
    try {
      const types = await this.serviceApiBase.get<ContentInterface[]>({
        url: "veiculo-tipo",
      });
      this.vehicleTypes = types["content"];

      const combustiveltipo = await this.serviceApiBase.get<ContentInterface[]>(
        {
          url: "veiculo-combustivel-tipo",
        }
      );
      this.fuelTypes = combustiveltipo["content"];

      const categoriaVeiculo = await this.serviceApiBase.get<
        ContentInterface[]
      >({
        url: "categoria-veiculo",
      });
      this.categoriaVeiculos = categoriaVeiculo["content"];

      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      const result = await this.serviceApiBase.get<any>({
        url: new Company()["nomeConsultaApiBaseGetAll"],
        params,
      });

      this.company = result["content"];

      const response = await this.serviceApiBase.get<Vehicle>({
        url: new Vehicle()["nomeConsultaApiBase"] + "/" + this.veiculoId,
      });

      this.veiculo = response as Vehicle;

      this.axisQuantity = this.veiculo.nrQuantidadeEixo;

      if (this.axisQuantity != null) {
        this.vehicleForm.controls["nrQuantidadeEixo"].setValue(
          this.axisQuantity
        );
      }

      if (this.veiculo.tipoRodagem != null) {
        this.vehicleForm.controls["tipoRodagem"].setValue(
          this.veiculo.tipoRodagem
        );
      }

      if (this.veiculo.idEmpresa != null) {
        if (this.veiculo.idEmpresaMatriz == null) {
          this.empresaFilterSelected = "Matriz";
        } else {
          this.empresaFilterSelected = "Filial";
        }
      } else {
        this.empresaFilterSelected = "Divisão";
      }

      this.filterCompany();

      if (this.veiculo.idEmpresa != null) {
        this.vehicleForm.controls["idEmpresa"].setValue(this.veiculo.idEmpresa);
      } else {
        this.vehicleForm.controls["idEmpresa"].setValue(this.veiculo.idDivisao);
      }

      this.fillUserData();
    } catch (error) {
      console.error(error);
    }
  }

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private serviceApiBase: ApiBaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  fillUserData(): void {
    if (this.veiculo.idEmpresa) {
      this.vehicleForm.controls["idEmpresa"].setValue(this.veiculo.idEmpresa);
    }

    if (this.veiculo.idVeiculoCombustivelTipo) {
      this.vehicleForm.controls["idVeiculoCombustivelTipo"].setValue(
        this.veiculo.idVeiculoCombustivelTipo
      );
    }
    if (this.veiculo.idVeiculoTipo) {
      this.vehicleForm.controls["idVeiculoTipo"].setValue(
        this.veiculo.idVeiculoTipo
      );
    }

    Object.getOwnPropertyNames(this.veiculo).forEach((element) => {
      if (this.vehicleForm.controls[element]) {
        this.vehicleForm.controls[element].setValue(this.veiculo[element]);
      }
    });

    this.errorDebug = getFormValidationErrors(this.vehicleForm);
  }

  public filterCompany() {
    var idEmpresa = this.empresaFilterSelected;
    this.vehicleForm.controls["idEmpresa"].setValue(null);
    var empresas = [];

    if (idEmpresa == "Divisão") {
      this.company.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Matriz") {
      this.company.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Filial") {
      this.company.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    console.log(this.companiesFilter);
  }

  get veiculoSensorItems(): FormArray {
    return this.vehicleForm.get("veiculoSensorList") as FormArray;
  }

  addSensorItem(element?: any): void {
    if (element) {
      const {
        id,
        nrSensor,
        cdPosicaoPneu,
        cdSensor,
        nrPressaoMinima,
        nrPressaoMaxima,
        nrTemperaturaMaxima,
      } = element;

      const form = this.formBuilder.group({
        id: [id],
        nrSensor: [nrSensor],
        cdPosicaoPneu: [cdPosicaoPneu],
        cdSensor: [cdSensor],
        nrPressaoMinima: [nrPressaoMinima],
        nrPressaoMaxima: [nrPressaoMaxima],
        nrTemperaturaMaxima: [nrTemperaturaMaxima],
      });

      this.veiculoSensorItems.push(form);
      return;
    } else {
      this.veiculoSensorItems.push(
        this.formBuilder.group({
          id: [""],
          nrSensor: [""],
          cdPosicaoPneu: [""],
          cdSensor: [""],
          nrPressaoMinima: [""],
          nrPressaoMaxima: [""],
          nrTemperaturaMaxima: [""],
        })
      );
    }
  }

  deleteSensorItem(index): void {
    this.veiculoSensorItems.removeAt(index);
  }
}
