<div class="main-page cadastros">
    <app-title>Análises do Operador</app-title>
    <div class="container-fluid">
      <table mat-table [dataSource]="dataSource" class="mat-elevation table-content" matSort>
        <!-- Position Column -->
        <ng-container matColumnDef="operatorDealings">
          <th mat-header-cell *matHeaderCellDef class="center-text-header"> Tratativa Operador </th>
          <td mat-cell *matCellDef="let element" class="center-text-children"> {{element.operator_dealings}} </td>
        </ng-container>

        <!-- Treatment Date Column -->
      <ng-container matColumnDef="treatmentDate">
        <th mat-header-cell *matHeaderCellDef  class="center-text-header">Data da Tratativa</th>
        <td mat-cell *matCellDef="let element" class="center-text-children">{{ element.formatted_analyze_end_date }}</td>
      </ng-container>

      <!-- Name Operator Column -->
      <ng-container matColumnDef="nameOperator">
        <th mat-header-cell *matHeaderCellDef class="center-text-header"> Nome Operador </th>
        <td mat-cell *matCellDef="let element" class="center-text-children"> {{element.name_operator}} </td>
      </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="descriptionAnalyze">
          <th mat-header-cell *matHeaderCellDef class="center-text-header"> Descrição da Tratativa </th>
          <td mat-cell *matCellDef="let element" class="center-text-children"> {{element.description_analyze}} </td>
        </ng-container>

        <!-- Name Column -->
       <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef class="center-text-header"> Estado </th>
        <td mat-cell *matCellDef="let element" class="center-text-children"> {{element.state}} </td>
       </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef class="center-text-header"> Cidade </th>
          <td mat-cell *matCellDef="let element" class="center-text-children"> {{element.city}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="minioObject">
          <th mat-header-cell *matHeaderCellDef class="center-text-header"> Imagem Tratativa </th>
          <td mat-cell *matCellDef="let element" class="center-text-children"> 
            <span *ngIf="element.minio_object" class="alerta mr-2" data-title="Imagem" matTooltip="Imagem">
                <a (click)="exportFile(element.minio_object)" class="btn btn-link">
                  <img height="20" src="assets/img/file-photo.svg" alt="" />
                </a>
            </span>
        </ng-container>
      
        <!-- Adicione uma linha para as colunas na tabela -->
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
      </table>
    </div>
  </div>
  
  