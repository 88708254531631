<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="funcionalidadeForm" (ngSubmit)="submitForm()">
                    <h2 class="mb-3">Nova Funcionalidade</h2>
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Modulo *</mat-label>

                                <mat-select formControlName="modulo" [(value)]="moduloSelected">
                                    <mat-option *ngFor="let modulo of modulo" [value]="modulo.id">
                                        {{ modulo.nmModulo }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Funcionalidade Tipo *</mat-label>
                                <mat-select formControlName="funcionalidadeTipo" [(value)]="funcionalidadeTipoSelected">
                                    <mat-option *ngFor="let type of types" [value]="type.id">
                                        {{ type.nmFuncionalidadeTipo }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Código da Funcionalidade</mat-label>
                                <input formControlName="cdFuncionalidade" matInput
                                    placeholder="Código da Funcionalidade" maxlength="20" />
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome Funcionalidade</mat-label>
                                <input formControlName="nmFuncionalidade" required matInput placeholder="Nome Funcionalidade"
                                    maxlength="50" />
                            </mat-form-field>
                        </div>

                        <div class="form-group col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Desc. Funcionalidade</mat-label>
                                <input formControlName="dsFuncionalidade" matInput
                                    required
                                    placeholder="Descrição Funcionalidade" maxlength="200" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label>URL Path</mat-label>
                                <input formControlName="cdPath" matInput placeholder="Cadastro Path" maxlength="200" />
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Sigla da Funcionalidade</mat-label>
                                <input formControlName="sgFuncionalidade" matInput placeholder="Funcionalidade"
                                    maxlength="10" />
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Salvar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
