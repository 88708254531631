import { Component, OnInit } from '@angular/core';
import {PermissionsService} from '../../../../helpers/permissions.service';
import {MapToolboxService} from '../../../../services/map.toolbox.service';
import {MapButtonService} from '../../../../services/map.buttom.service';

@Component({
  selector: 'app-button-general-filter',
  templateUrl: './button-general-filter.component.html',
  styleUrls: ['./button-general-filter.component.scss']
})
export class ButtonGeneralFilterComponent implements OnInit {
  permissions: [];
  selected = false;

  public permissionService: PermissionsService;
  private service: MapToolboxService;
  private mapButtonService: MapButtonService;

  constructor( permissionService: PermissionsService,
               service: MapToolboxService,
               mapButtonService: MapButtonService) {
    this.permissionService = permissionService;
    this.service = service;
    this.mapButtonService = mapButtonService;
  }

  ngOnInit(): void {
    this.permissions = this.permissionService.getPermissions();
    this.mapButtonService.currentGeneralFilterButton.subscribe((action) => {
      if (action !== this.selected) { this.selected = action; }
    });
  }

  onClick(): void {
    if (this.selected){
      console.log('desativar a busca');
      this.selected = false;
      return;
    }
    console.log('ativar a busca');
    this.selected = true;
    this.mapButtonService.setGeneralFilterButton(true);
    this.service.setToolboxCommand('general_filter');
  }

}
