import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-axle-eight-simple-view",
  templateUrl: "./axle-eight-simple-view.component.html",
  styleUrls: ["./axle-eight-simple-view.component.scss"],
})
export class AxleEightSimpleViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorEightLeftSelect: any = [];
  public sensorEightRightSelect: any = [];

  public sensorEightRightCombo: any = [];
  public sensorEightLeftCombo: any = [];

  public axisEightSimpleLeftForm: FormGroup;
  public axisEightSimpleRightForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisEightSimpleLeftForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisEightSimpleRightForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisEightSimpleRightForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 8 ESQ"
      );
      this.axisEightSimpleLeftForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 8 DIR"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo8Direito;

        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 50) {
              console.log(filter);
              eixo8Direito = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 50) {
              console.log(filter);
              eixo8Direito = filter;
            }
          }
        });

        var eixo8Esquerdo;

        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 49) {
              console.log(filter);
              eixo8Esquerdo = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 49) {
              console.log(filter);
              eixo8Esquerdo = filter;
            }
          }
        });

        // EIXO 8 DIREITO
        if (eixo8Direito != null) {
          this.axisEightSimpleRightForm.controls["id"].setValue(
            eixo8Direito.id
          );

          if (eixo8Direito.sensor != null) {
            this.sensorEightRightCombo.push(eixo8Direito.sensor);
            this.sensorEightRightSelect = eixo8Direito.sensor.id;
          }

          if (eixo8Direito.pneu != null) {
            this.axisEightSimpleRightForm.controls["pneu"].setValue(
              eixo8Direito.pneu.numeroFogo
            );
          }

          this.axisEightSimpleRightForm.controls["nrPressaoMinima"].setValue(
            eixo8Direito.nrPressaoMinima
          );

          this.axisEightSimpleRightForm.controls["nrPressaoMaxima"].setValue(
            eixo8Direito.nrPressaoMaxima
          );

          this.axisEightSimpleRightForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo8Direito.nrTemperaturaMaxima);
        }

        // EIXO 8 ESQUERDO
        if (eixo8Esquerdo != null) {
          this.axisEightSimpleLeftForm.controls["id"].setValue(
            eixo8Esquerdo.id
          );

          if (eixo8Esquerdo.sensor != null) {
            this.sensorEightLeftCombo.push(eixo8Esquerdo.sensor);
            this.sensorEightLeftSelect = eixo8Esquerdo.sensor.id;
          }

          if (eixo8Esquerdo.pneu != null) {
            this.axisEightSimpleLeftForm.controls["pneu"].setValue(
              eixo8Esquerdo.pneu.numeroFogo
            );
          }

          this.axisEightSimpleLeftForm.controls["nrPressaoMinima"].setValue(
            eixo8Esquerdo.nrPressaoMinima
          );

          this.axisEightSimpleLeftForm.controls["nrPressaoMaxima"].setValue(
            eixo8Esquerdo.nrPressaoMaxima
          );

          this.axisEightSimpleLeftForm.controls["nrTemperaturaMaxima"].setValue(
            eixo8Esquerdo.nrTemperaturaMaxima
          );
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
