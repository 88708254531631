import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PermissionsService } from 'src/app/helpers/permissions.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  permissions: [];
  constructor(public permissionService: PermissionsService, public router: Router) {}

  ngOnInit(): void {
    this.permissions = this.permissionService.getPermissions();
  }
}
