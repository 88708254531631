<mat-dialog-content class="mat-typography">
  <div class="container">
    <h2>Criar uma nova Rota</h2>
    <div class="form-row">
      <mat-form-field>
        <mat-label>Tipo de Cadastro</mat-label>
        <mat-select [(value)]="empresaFilterSelected" [(ngModel)]="empresaFilterSelected" 
        (selectionChange)="this.filterCompany()">
          <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <mat-label *ngIf="this.empresaSelected == null">Selecione um tipo de cadastro</mat-label>
        <mat-label *ngIf="this.empresaSelected == 'Matriz'">Matrizes</mat-label>
        <mat-label *ngIf="this.empresaSelected == 'Filial'">Filiais</mat-label>
        <mat-label *ngIf="this.empresaSelected == 'Divisão'">Divisões</mat-label>
        <mat-select [(value)]="empresaValue" [(ngModel)]="empresaSelected" (selectionChange)="this.loadMarkersFilter(empresaSelected)" required>
          <mat-option *ngFor="let type of companiesFilter" [value]="type">
            {{ type.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
   

  <div class="row">
    <mat-form-field appearance="outline">
      <mat-label>Nome da Rota:</mat-label>
      <input
        matInput
        [(ngModel)]="routeName"
        placeholder="Nome da rota"
      />
    </mat-form-field>
  </div>
  <label>Selecionar veículos</label>
  <br>
  <section class="example-section">
  <div class="form-group">
    <input type="text" class="form-control" (keyup)="handleFilter($event.target.value)" placeholder="pesquisar..."
      autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search />
  </div>   
  <div class="box-placas">
    <span class="example-list-section">
      <mat-checkbox class="example-margin"
                    [checked]="allComplete"
                    [indeterminate]="someComplete()"
                    (change)="setAll($event.checked)">
        {{task.name}}
      </mat-checkbox>
    </span>
    <span class="example-list-section">
      <ul>
        <li *ngFor="let subtask of task.subtasks">
          <mat-checkbox [(ngModel)]="subtask.completed"
                        [color]="subtask.color"
                        (ngModelChange)="updateAllComplete()">
            {{subtask.name}} 
          </mat-checkbox>
        </li>
      </ul>
    </span>
  </div>
</section>
    
    <mat-tab-group
      animationDuration="0ms"
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab label="Criar rota por endereço ou local" (click)="setTab(1)">
        <div class="form-group">
          <br />
          <label>Endereço ou Local de Origem:</label>
          <input
            type="text"
            class="form-control"
            (keydown.enter)="$event.preventDefault()"
            placeholder="Digite um endereço ou localidade..."
            autocorrect="off"
            autocapitalize="off"
            spellcheck="off"
            type="text"
            #searchOrigem
          />

          <p *ngIf="addressOrigem" class="address">
            <ngb-alert [dismissible]="false">
              <strong>Endereço encontrado: </strong> <br />
              {{ addressOrigem }}
            </ngb-alert>
          </p>
        </div>
        <div class="form-group">
          <br />
          <label>Endereço ou Local de Destino:</label>
          <input
            type="text"
            class="form-control"
            (keydown.enter)="$event.preventDefault()"
            placeholder="Digite um endereço ou localidade..."
            autocorrect="off"
            autocapitalize="off"
            spellcheck="off"
            type="text"
            #searchDestino
          />

          <p *ngIf="addressDestino" class="address">
            <ngb-alert [dismissible]="false">
              <strong>Endereço encontrado: </strong> <br />
              {{ addressDestino }}
            </ngb-alert>
          </p>
        </div>
        
        <br />
        <div class="form-group">
          <label>Limite de Veloc na Rota (Km/h):</label>
          <input
            type="text"
            class="form-control distance"
            (change)="velocidadeEnderecoChanged($event)"
            (keyup)="velocidadeEnderecoChanged($event)"
            type="number"
          />
        </div>

        <br />
        <div class="form-group">
          <label>Distância Tolerada(metros):</label>
          <input
            type="text"
            class="form-control distance"
            (change)="distanceEnderecoChanged($event)"
            (keyup)="distanceEnderecoChanged($event)"
            type="number"
          />
        </div>
      </mat-tab>
      <mat-tab label="Criar rota por click no mapa" (click)="setTab(2)">
        <br />
        <div class="form-group">
          <label>Limite de Veloc na Rota (Km/h):</label>
          <input
            type="text"
            class="form-control distance"
            (change)="velocidadeMapaChanged($event)"
            (keyup)="velocidadeMapaChanged($event)"
            type="number"
          />
        </div>

        <br />
        <div class="form-group">
          <label>Distância Tolerada(metros):</label>
          <input
            type="text"
            class="form-control distance"
            (change)="distanceMapaChanged($event)"
            (keyup)="distanceMapaChanged($event)"
            type="number"
          />
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Fechar</button>
  <button
    [disabled]="
      (tabSelected == 0 && (!empresaSelected  || !routeName || !latitudeOrigem || !longitudeOrigem || !distanceEndereco || distanceEndereco <= 0 || !velocidadeEndereco || velocidadeEndereco <= 0)) ||
      (tabSelected == 1 && (!empresaSelected  || !routeName || !distanceMapa || distanceMapa <= 0 || !velocidadeMapa || velocidadeMapa <= 0))     
    "
    mat-button
    (click)="submit()"
  >
    Criar Rota
  </button>
</mat-dialog-actions>
