<h2 mat-dialog-title>Comandos / Configurações Terminais</h2>

<div class="container container-body">
  <div class="row">
    <div class="col-sm">
      <mat-radio-group [(ngModel)]="action">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
          <li ngbNavItem>
            <a ngbNavLink>Comandos</a>
            <ng-template ngbNavContent>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <mat-radio-button [value]="14">Bloqueio do Terminal:</mat-radio-button>
                    <mat-form-field *ngIf="this.action == 14" appearance="outline">
                      <mat-label>Senha do usuário</mat-label>
                      <input matInput type="password" [(ngModel)]="this.password" placeholder="informe a senha" />
                    </mat-form-field>
                    <button *ngIf="this.action == 14" mat-flat-button (click)="this.login()" color="primary" [disabled]="loading">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Validar
                    </button>
                  </div>

                  <div class="col">
                    <mat-radio-button [value]="15">Desbloqueio do Terminal:</mat-radio-button>
                    <mat-form-field *ngIf="this.action == 15" appearance="outline">
                      <mat-label>Senha do usuário</mat-label>
                      <input matInput type="password" [(ngModel)]="this.password" placeholder="informe a senha" />
                    </mat-form-field>
                    <button *ngIf="this.action == 15" mat-flat-button (click)="this.login()" color="primary" [disabled]="loading">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Validar
                    </button>
                  </div>
                </div>

                <hr />

                <div class="row">
                  <div class="col-sm-6">
                    <mat-radio-button [value]="0">Requisitar Posição Atual</mat-radio-button>
                    <br />
                    <mat-radio-button [value]="53">Ativar Saida 3</mat-radio-button>
                    <br />
                    <mat-radio-button [value]="54">Desativar Saida 3</mat-radio-button>
                    <br />
                    <mat-radio-button [value]="55">Ativar Saida 4</mat-radio-button>
                    <br />
                    <mat-radio-button [value]="56">Desativar Saida 4</mat-radio-button>
                  </div>
                  <div class="col-sm-6">
                    <mat-radio-button [value]="57">Ativar Saida 5</mat-radio-button>
                    <br />
                    <mat-radio-button [value]="58">
                      Desativar Saida 5</mat-radio-button>
                    <br />
                    <mat-radio-button [value]="59">Ativar Saida 6</mat-radio-button>
                    <br />
                    <mat-radio-button [value]="60">Desativar Saida 6</mat-radio-button>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink>Configurações</a>
            <ng-template ngbNavContent>
              <div class="container">
                <div class="row">
                  <div class="col-sm-6">
                    <mat-radio-button [value]="40">Limite velocidade:</mat-radio-button>
                    <br />
                    <mat-form-field appearance="outline">
                      <mat-label>Km/h</mat-label>
                      <input matInput [(ngModel)]="velocityLimitKMH" placeholder="informe Km/h" />
                    </mat-form-field>

                    <mat-radio-button [value]="33">
                      Intervalo de envio em movimento:</mat-radio-button>
                    <br />
                    <mat-form-field appearance="outline">
                      <mat-label>seg.</mat-label>
                      <input matInput [(ngModel)]="intervalSendMoveSeg" placeholder="informe o SEG." />
                    </mat-form-field>

                    <mat-radio-button [value]="34">
                      Intervalo de envio parado:</mat-radio-button>
                    <br />
                    <mat-form-field appearance="outline">
                      <mat-label>min.</mat-label>
                      <input matInput [(ngModel)]="intervalSendMoveMin" placeholder="informe MIN" />
                    </mat-form-field>
                  </div>

                  <div class="col-sm-6">
                    <mat-radio-button [value]="35">
                      Intervalo de envio em movimento -
                      Roaming:</mat-radio-button>
                    <br />
                    <mat-form-field appearance="outline">
                      <mat-label>seg.</mat-label>
                      <input matInput [(ngModel)]="intervalSendMoveRoamingSeg" placeholder="informe SEG" />
                    </mat-form-field>

                    <mat-radio-button [value]="36">
                      Intervalo de envio parado - Roaming:</mat-radio-button>
                    <br />
                    <mat-form-field appearance="outline">
                      <mat-label>min.</mat-label>
                      <input matInput [(ngModel)]="intervalSendMoveRoamingMin" placeholder="informe MIN" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink>Telefones</a>
            <ng-template ngbNavContent>
              <mat-radio-button [value]="32"> Telefone 1:</mat-radio-button>
              <br />
              <mat-form-field appearance="outline">
                <input matInput [(ngModel)]="phone" placeholder="informe o telefone" />
              </mat-form-field>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink>Administração</a>
            <ng-template ngbNavContent>
              <mat-radio-button [value]="29"> Reboot:</mat-radio-button>
              <br />
              <mat-radio-button [value]="31"> Texto Comando:</mat-radio-button>
              <br />
              <mat-form-field appearance="outline">
                <input matInput [(ngModel)]="commandText" placeholder="informe o roboot" />
              </mat-form-field>
            </ng-template>
          </li>
        </ul>
      </mat-radio-group>
      <div [ngbNavOutlet]="nav"></div>
    </div>
    <!-- Div que exibe os terminais -->
    <div class="col-sm-3">
      <div class="container">
        <div class="form-group">
          <input type="text" class="form-control" (keyup)="handleFilter($event.target.value)" placeholder="pesquisar..."
            autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search />
        </div>

        <div>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="plate">
              <th mat-header-cell *matHeaderCellDef>Todos</th>
              <td mat-cell *matCellDef="let element">
                {{ element.placa }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="errorMessage" class="alert alert-danger">
  {{ errorMessage }}
</div>

<div *ngIf="successMessage" class="alert alert-success">
  {{ successMessage }}
</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fechar</button>
  <button mat-flat-button [disabled]="this.action == 14 || this.action == 15 && !this.loginOk" (click)="onSubmitCommand()" color="primary" [disabled]="loading">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      CONFIRMAR
  </button>
</mat-dialog-actions>
