<div class="main-page cadastros">
  <div class="container-fluid">
    <app-title>{{ title ? title : 'Inserir novo' }} </app-title>
    <mat-tab-group>
      <mat-tab label="Dados">
        <ng-template matTabContent>
          <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
              <form [formGroup]="userForm" (ngSubmit)="submitForm()">
                <h2 class="mb-3">Novo Usuário</h2>
                <div class="form-row">
                  <div class="form-group col-lg-6"> 
                    <mat-form-field appearance="outline">
                      <mat-label>Login*</mat-label>
                      <input autocomplete="off" autofocus pattern="[0-9a-zA-Z]*" formControlName="cdUsuario" matInput placeholder="Login"
                        maxlength="100" />
                    </mat-form-field>

                    <validation-errors [form]="userForm" controlLabel="Login" controlName="cdUsuario">
                    </validation-errors>
                  </div>
                  <div class="form-group col-lg-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Nome do usuário*</mat-label>
                      <input formControlName="nmUsuario" matInput placeholder="Nome do usuário" maxlength="100"
                         />
                    </mat-form-field>
                    <validation-errors [form]="userForm" controlLabel="Nome do usuário" controlName="nmUsuario">
                    </validation-errors>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <mat-form-field appearance="outline">
                      <mat-label>E-mail*</mat-label>
                      <input formControlName="dsEmail" matInput placeholder="E-email" maxlength="100"  />
                    </mat-form-field>
                    <validation-errors [form]="userForm" controlLabel="E-mail" controlName="dsEmail">
                    </validation-errors>
                  </div>

                  <div class="form-group col-lg-3">
                    <mat-form-field appearance="outline">
                      <mat-label>Matricula</mat-label>
                      <input formControlName="nrMatricula" matInput maxlength="4" placeholder="Matricula" pattern="[0-9]*" required/>
                    </mat-form-field>
                    <span class="alert-danger" *ngIf="!userForm.get('nrMatricula').valid">
                      Matricula é um campo numérico e obrigatório!
                    </span>
                  </div>
                  
                  <div class="form-group col-lg-3" *ngIf="!userId">
                    <mat-form-field appearance="outline">
                      <mat-label>Senha*</mat-label>
                      <input autocomplete="off" [type]="hide ? 'password' : 'text'" formControlName="cdSenha" matInput placeholder="Senha" maxlength="100" />
                      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                    <validation-errors [form]="userForm" controlLabel="Senha" controlName="cdSenha">
                    </validation-errors>  
                  </div>

                  <!-- Check Box da permissão no filtro geral -->
                  <div class="form-group col-12">
                      <mat-checkbox formControlName="generalFilterPermission" >Pode utilizar o filtro geral?</mat-checkbox>
                  </div>
                      
                </div>      
                <div *ngIf="errorMessage" class="alert alert-danger">
                  {{ errorMessage }}
                </div>

                <div class="form-group mt-2 mb-0 text-center">
                  <back-button></back-button>
                  <button type="submit" [disabled]="!userForm.valid"
                    class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                    Salvar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Perfil" [disabled]="!userId">
        <ng-template matTabContent>
          <!-- <--[isDetail]="isDetail"--> 
          <app-form-aba-perfil [userId]="userId">
          </app-form-aba-perfil>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
