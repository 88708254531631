import { Division } from "./Division.model";
import { SensorInterface } from "../contracts/Sensor.interface";
import { Company } from "./Company.model";

export class Sensor implements SensorInterface {
  nomeConsultaApiBase = "sensor";
  nomeConsultaApiBaseSensor = "sensor/vehicle/";
  nomeConsultaApiBaseGetAll = "sensor/getall";
  id: number;
  descSensor: string;
  idEmpresa: number;
  idDivisao: number;
  idEmpresaMatriz: number;
}
