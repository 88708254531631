<div class="main-page consultas">
  <div class="container-fluid">
    <!-- LINHA TITULO + PESQUISA -->

    <div class="row align-items-center">
      <div class="col-12 my-2">
        <h1>
          <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])"
            data-title="Voltar"><i class="fas fa-arrow-left"></i></a>
          Consultar Ve&iacute;culos
        </h1>
      </div>
    </div>

    <!--  <mat-progress-bar mode="indeterminate" *ngIf="loader"></mat-progress-bar>-->

    <div *ngIf="vehicles?.data.length === 0 && !loader">
      <div class="row no-gutters mt-3">
        <p>Nenhum registro at&eacute; o momento...</p>
      </div>
      <div class="row no-gutters mt-3">
        <a (click)="this.router.navigate(['/vehicles/create'])" mat-button color="primary"
          class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
          Criar um novo ve&iacute;culo
        </a>
      </div>
    </div>

    <div class="row no-gutters mt-3" *ngIf="vehicles?.data.length > 0">
      <div class="mat-elevation table-content">
       <!--  <div fxLayout fxLayoutAlign="center center">
          <mat-form-field>
            <mat-label>Campo</mat-label>
            <select matNativeControl [(ngModel)]="typeFilter">
              <option *ngFor="let filtro of filtros" value={{filtro.campo}}>
                {{filtro.valor}}
              </option>
            </select>
          </mat-form-field>
          <mat-form-field fxFlex="40%" class="ml-2">
            <input autofocus type="text" matInput placeholder="Filtro" [(ngModel)]="filter" />
          </mat-form-field>
          <button type="button" mat-icon-button (click)="handleFilter()">
            <mat-icon>search</mat-icon>
          </button>
        </div> -->

        <!-- (matSortChange)="handleSort($event)" -->

       <div fxLayout fxLayoutAlign="center center">
         <mat-form-field fxFlex="40%" class="ml-2">
          <mat-label>Filtrar por placa</mat-label>
          <input matInput autocomplete="off"
          (keyup)="this.applyFilter($event)" style="text-transform: uppercase;" placeholder="Ex: PKISO" #input>
        </mat-form-field>
      </div>

        <table mat-table [dataSource]="vehicles" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <ng-container matColumnDef="placa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Placa</th>
            <td mat-cell *matCellDef="let element">{{ element.placa }}</td>
          </ng-container>

          <ng-container matColumnDef="nmempresa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Empresa
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.nmempresa}}
            </td>
          </ng-container>

          <ng-container matColumnDef="marca">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Marca</th>
            <td mat-cell *matCellDef="let element">{{ element.marca }}</td>
          </ng-container>

          <ng-container matColumnDef="nmModelo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Modelo</th>
            <td mat-cell *matCellDef="let element">{{ element.nmModelo }}</td>
          </ng-container>

          <ng-container matColumnDef="nmterminal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Número Terminal</th>
            <td mat-cell *matCellDef="let element">{{ element.nmterminal ? element.nmterminal : "--" }}</td>
          </ng-container>

          <ng-container matColumnDef="sendTpms">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <span class="alerta mr-2" data-title="Enviar dados de TPMS ao Terminal"
                *ngIf="element.possuiTpms"           
                matTooltip="Enviar dados de TPMS ao Terminal">
                <a (click)="handSendTpms(element.id)" class="btn btn-link">
                  <img height="40" src="assets/img/icon-configurar.svg" alt="" />
                </a>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="update">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <span class="alerta mr-2" data-title="Editar"
                *ngIf="this.permissionService.hasPermission('VEHICLE_ATUALIZACAO', this.permissions)"
                matTooltip="Editar">
                <a (click)="this.router.navigate(['/vehicles/edit/' + element.id])" class="btn btn-link">
                  <img height="20" src="assets/img/edit.svg" alt="" />
                </a>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="clone">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <span class="alerta mr-2" data-title="Duplicar"
                *ngIf="this.permissionService.hasPermission('VEHICLE_ATUALIZACAO', this.permissions)"
                matTooltip="Duplicar">
                <a (click)="this.router.navigate(['/vehicles/clone/' + element.id])" class="btn btn-link">
                  <img height="20" src="assets/img/duplic.svg" alt="" />
                </a>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <span class="alerta mr-2" data-title="Visualizar"
                *ngIf="this.permissionService.hasPermission('VEHICLE_ATUALIZACAO', this.permissions)"
                matTooltip="Visualizar">
                <a (click)="this.router.navigate(['/vehicles/view/' + element.id])" class="btn btn-link">
                  <img height="20" src="assets/img/view.svg" alt="" />
                </a>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button (click)="handleDelete(element.id)" class="btn btn-link"
                *ngIf="this.permissionService.hasPermission('VEHICLE_DELECAO', this.permissions)" matTooltip="Apagar">
                <img height="20" src="assets/img/trash-cadastro.svg" alt="" />
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 40, 100]" showFirstLastButtons></mat-paginator>

<!--         <mat-paginator *ngIf="vehicles.data.length" [pageSize]="this.page.size" (page)="handlePageChanged($event)"
        [length]="this.page.totalElements" showFirstLastButtons></mat-paginator> -->
      </div>
    </div>
  </div>
</div>
