import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { MapsAPILoader } from "@agm/core";
import { isPlatformBrowser } from "@angular/common";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ContentInterface } from "../../../contracts/Content.interface";
import { ApiService } from "./../../../services/api.service";

declare let google: any;

const INTERVAL_TRAKING_MARKER_IN_SECONDS = 1 * 5 * 1000;

@Component({
  selector: "app-streetview",
  templateUrl: "./streetview.component.html",
  styleUrls: ["./streetview.component.scss"],
})
export class StreetviewComponent implements OnInit {
  @ViewChild("streetviewMap") streetviewMap: any;
  @ViewChild("streetviewPano") streetviewPano: any;
  @Input() latitude;
  @Input() longitude;
  @Input() zoom = 11;
  @Input() heading = 0;
  @Input() pitch = 0;
  @Input() scrollwheel = false;

  private service: ApiService;

  public markerTracking;
  public markerTrackingInterval;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    apiService: ApiService,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.service = apiService;
  }

  ngOnInit(): void {
    console.log("init street");
    const { marker } = this.data;
    this.markerTracking = marker;
    const { latitude, longitude, direcao } = marker;
    this.latitude = parseFloat(latitude);
    this.longitude = parseFloat(longitude);
    this.heading = direcao || 0;

    /*     this.trakingMarker();
     */ this.loadStreetView();
  }

  loadStreetView(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.mapsAPILoader.load().then(() => {
        const center = { lat: this.latitude, lng: this.longitude };
        const map = new window["google"].maps.Map(
          this.streetviewMap.nativeElement,
          {
            center: center,
            zoom: this.zoom,
            scrollwheel: this.scrollwheel,
          }
        );
        const panorama = new window["google"].maps.StreetViewPanorama(
          this.streetviewPano.nativeElement,
          {
            position: center,
            pov: { heading: this.heading, pitch: this.pitch },
            scrollwheel: this.scrollwheel,
          }
        );
        map.setStreetView(panorama);
      });
    }
  }

  /*   trakingMarker(): void {
    this.loadMarker();
    this.markerTrackingInterval = interval(
      INTERVAL_TRAKING_MARKER_IN_SECONDS
    ).subscribe(
      () => {
        this.loadMarker();
      },
      (error) => console.log('error on interval', error)
    );
  }
 */
  cancelTrakingMarker(): void {
    if (this.markerTrackingInterval) {
      clearInterval(this.markerTrackingInterval);
      this.markerTrackingInterval.unsubscribe();
    }
    this.markerTracking = null;
  }

  async loadMarker(): Promise<void> {
    console.log("lat lng", this.latitude, this.longitude);
    const marker = this.markerTracking;
    if (!marker || !marker.id) {
      return;
    }

    try {
      const result = await this.service.get<ContentInterface[]>({
        url: `${"posicao-atual-terminal"}/${marker.id}`,
      });

      const response = result["content"];
      const currentMarker = response[0];

      let reBounds = false;
      if (this.markerTracking) {
        if (
          this.markerTracking.latitude !== currentMarker.latitude ||
          this.markerTracking.longitude !== currentMarker.longitude
        ) {
          reBounds = true;
        }
      }

      console.log("reBoound", reBounds);
      if (reBounds) {
        this.latitude = parseFloat(currentMarker.latitude);
        this.longitude = parseFloat(currentMarker.longitude);
        console.log("lat lng", this.latitude, this.longitude);
        this.markerTracking = currentMarker;
        this.loadStreetView();
      }
    } catch (error) {
      console.log("Error: loadMarker StreetView()", error);
    }
  }

  ngOnDestroy() {
    clearInterval(this.markerTrackingInterval);
  }
}
