
<!-- LOGIN -->
<!-- <section *ngIf="this.recover" class="login py-5">
  <div class="container h-100 pt-5">
    <div class="row h-100 align-items-center justify-content-center">
      <div class="col-md-6 col-lg-5 text-center">
       
        <img src="../../assets/img/logo-login.svg" alt="" class="img-fluid" />

        <br>
        <br>

        <div>
          Digite seu e-mail de cadastro ou nome de usuário para recuperar seu acesso.
        </div>

        <form
        class="pt-5"
         [formGroup]="forgotPasswordForm"
          (ngSubmit)="this.findUser()"
        >
          <div class="form-row">
            <div class="form-group col-12">
              <input
                style="color: rgba(0, 0, 0, 0.848);"
                formControlName="input"
                type="text"
                pInputText
                placeholder="Digite seu E-mail ou nome de usuário"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-5">
              <p-button   [disabled]="forgotPasswordForm.invalid" type="submit" label="Enviar"></p-button>
            </div>

            <div class="form-group col-5">
             
              <button
                 (click)="this.tools.builder().router.navigate(['/login'])"
                  pButton  pRipple  type="button" label="Voltar" class="p-button-secondary"> 
              </button>
                </div>
          </div>

          <br>
          <br>

        </form>
      </div>
      <div class="col-md-5 col-lg-6 offset-md-1 text-center d-none d-md-block">
        <img src="../../assets/img/img-login.svg" alt="" class="img-fluid" />
      </div>
    </div>
  </div>
</section> -->

<!-- <section *ngIf="!this.recover" class="container-fluid">
      <div class="col-md-6 col-lg-5 text-center">
       
        <img src="assets/img/logo-login.svg" alt="" class="img-fluid" />
       
        <br>
        <br>
         <div>
           <strong>
            Foi enviado para o seu e-mail de cadastro um codigo de validação.
            <br>
            Siga os passos no e-mail e recupere seu acesso.
           </strong>
        </div>

        <button pButton 
                pRipple 
                (click)="this.tools.builder().router.navigate(['/login'])"
                type="button" 
                label="Entendi" 
                class="p-button-success">
        </button>
      </div>
      <div class="col-md-5 col-lg-6 offset-md-1 text-center d-none d-md-block">
        <img src="assets/img/img-login.svg" alt="" class="img-fluid" />
      </div>
</section> -->

<!-- <nav class="navbar navbar-expand nav-custom fixed-top">
  <div class="container">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto text-center">
        <li class="nav-item">
          <a class="nav-link" href="https://www.setera.seg.br">Voltar ao site</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<section class="login py-5">
  <div class="container h-100 pt-5">
    <div class="row h-100 align-items-center justify-content-center">
      <div class="col-md-6 col-lg-5 text-center">
        <img src="../../assets/img/logo-login.svg" alt="" class="img-fluid" />
        <form class="pt-5" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-row">
            <div class="form-group col-12">
              <input formControlName="username" type="text" class="form-control" placeholder="Login" />
              <validation-errors [form]="loginForm" controlLabel="Login" controlName="username">
              </validation-errors>
            </div>
            <div class="form-group col-12">
              <input formControlName="password" type="password" class="form-control" placeholder="Senha" />
              <validation-errors [form]="loginForm" controlLabel="Senha" controlName="password">
              </validation-errors>
            </div>
          </div>
          <div class="form-row pb-4">
            <div class="form-group col-sm-6 my-2 my-sm-0 text-center text-sm-left">
              <div class="form-check link">
                  <mat-checkbox 
                  class="example-margin"
                  [checked]="this.checkBox.checked"
                  (change)="this.change($event.checked)"
                  [color]="this.checkBox.color">
                             Manter Logado
                  </mat-checkbox>
              </div>
            </div>
            <div
              class="form-group col-sm-6 my-2 my-sm-0 text-center text-sm-right"
            >
              <a class="link" (click)="this.router.navigate(['/forgot-password'])">Esqueceu sua senha?</a>
            </div> 
          </div>
          <button [disabled]="!loginForm.valid" type="submit" class="btn btn-login w-100 w-md-50">
            Entrar
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          </button>
          <div *ngIf="errorMessage">
            <span class="error">{{ errorMessage }}</span>
          </div>
        </form>
      </div>
      <div class="col-md-5 col-lg-6 offset-md-1 text-center d-none d-md-block">
        <img src="../../assets/img/img-login.svg" alt="" class="img-fluid" />
      </div>
    </div>
  </div>
</section> -->

<div class="login-body">
  <div class="login-wrapper">
      <div class="login-panel">
          <img src="assets/img/logo-login.svg" class="logo" alt="diamond-layout" />

          <form class="login-form" 
           [formGroup]="forgotPasswordForm"
          (ngSubmit)="this.findUser()">
              <h2>Gestão em Transportes</h2>
              <p> Digite seu e-mail de cadastro ou nome de usuário para recuperar seu acesso. <a> </a></p>
              <input pInputText placeholder="Digite seu E-mail ou nome de usuário"
              id="recoverPass"  formControlName="input"
              />
              <button [disabled]="this.forgotPasswordForm.invalid" pButton label="Enviar" type="submit" ></button>
              <br>
              <button (click)="this.tools.builder().router.navigate(['/login'])"  pButton class="p-button-secondary" label="Voltar" type="button" ></button>

          </form>    
        </div>
      <div class="login-image">
          <div  style="position: relative; top: 76px;" class="login-image-content">

              <img src="assets/img/logo-white-verti.png" class="logo" alt="diamond-layout" />
              <h4>
                <a style="color: white;
                font-size: 16px;
                position: relative;
                top: -14px;
                left: 80px;" href="/index">
            
            <i class="pi pi-arrow-left
            " style="font-weight: 700;
            color: white;
            font-size: 26px;
            position: relative;
            top: 6px;
            left: -7px;"></i>

              Voltar ao site</a> </h4>
          </div>
          <div class="image-footer">            
          </div>
      </div>
  </div>
</div>
