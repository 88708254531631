<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="terminalalarmeForm" (ngSubmit)="submitForm()">
                    <h2 class="mb-3">Novo Evento</h2>
                    <div class="form-row">
                        <div class="form-group col-lg-1">
                            <mat-form-field appearance="outline">
                                <mat-label>Código</mat-label>
                                <input formControlName="cdCodigo" matInput placeholder="Código" required (keypress)="numberOnly($event)"/>
                            </mat-form-field>
                        </div>

                        <div class="form-group col-lg-9">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input formControlName="dsMensagem" matInput placeholder="Mensagem" required/>
                            </mat-form-field>
                            <validation-errors [form]="terminalalarmeForm" controlLabel="Mensagem"
                                controlName="dsMensagem">
                            </validation-errors>
                        </div>
                        <div class="form-group col-md-2">
                            <mat-form-field>
                                <mat-label>Fator</mat-label>
                                <select matNativeControl formControlName="nmFator">
                                    <option value="seguranca">Segurança</option>
                                    <option value="operacional">Operacional</option>
                                    <option value="tecnico">Técnico</option>
                                </select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-lg-1">
                            <mat-form-field appearance="outline">
                                <mat-label>Cor</mat-label>
                                <input formControlName="cdCor" matInput placeholder="Cor" />
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Ordem Prioridade</mat-label>
                                <input type="number" formControlName="nrOrdemPrioridade" matInput
                                    placeholder="Núm. Ordem Prioridade" />
                            </mat-form-field>
                        </div>

                        <div class="form-group col-lg-3">
                            <mat-form-field appearance="outline" class="invisible example-full-width">
                                <mat-label>Nome Icone</mat-label>
                                <input formControlName="nmNomeIconeCss" matInput placeholder="Nome Icone" />
                            </mat-form-field>
                        </div>

                        <div class="form-group col-lg-3">
                            <mat-form-field appearance="outline" class="invisible example-full-width">
                                <mat-label>Atributo 1</mat-label>
                                <input formControlName="cdAtributo1" matInput placeholder="Cad. Atributo 1:" />
                            </mat-form-field>
                        </div>

                        <div class="form-group col-lg-3">
                            <mat-form-field appearance="outline" class="invisible example-full-width">
                                <mat-label>Atributo 2</mat-label>
                                <input formControlName="cdAtributo2" matInput placeholder="Cad. Atributo 2:" />
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-row">

                        <div class="form-group col-lg-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Procedimento para o Operador</mat-label>
                                <textarea formControlName="dsProcedimentoOperador" matInput
                                    placeholder="Procedimento Operador" maxlength="5000"></textarea>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-4">
                            <mat-slide-toggle name="flExibePainelEvento" formControlName="flExibePainelEvento"
                                color="primary">Exibir Painel de Eventos?
                            </mat-slide-toggle>
                        </div>
                        <div class="form-group col-lg-4">
                            <mat-slide-toggle name="flMarkerRelatorioGerencial"
                                formControlName="flMarkerRelatorioGerencial" color="primary">
                                Relatório Gerencial?
                            </mat-slide-toggle>
                        </div>

                        <div class="form-group col-lg-4">
                            <mat-slide-toggle name="ativo" formControlName="ativo" color="primary">
                                Ativo?
                            </mat-slide-toggle>
                        </div>
                    </div>

                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Salvar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
