import { environment } from "./../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { STORAGE_KEY_ACCESS_TOKEN_WSO2 } from "../storageCore/constStorageKeys";
import storageX from "../storageCore/storageX";

@Injectable({
  providedIn: "root",
})
export class MarkerService {
  constructor(private http: HttpClient) {}

  public getPosicaoAtualTerminalId(id: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/${"posicao-atual-terminal"}/${id}`,
      {
        headers: {
          Authorization:
            "Bearer " +
            `${storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get()}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  public postVariasCercasEletronicas(payload): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/${"cerca-eletronica"}/${"all"}`,
      payload,
      {
        headers: {
          Authorization:
            "Bearer " +
            `${storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get()}`,
          "Content-Type": "application/json",
        },
      }
    );
  }
}
