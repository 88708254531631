import { Component, ViewChild, OnInit } from "@angular/core";
import {
  MatButtonToggleChange,
  MatButtonToggle,
} from "@angular/material/button-toggle";

import { MapToolboxService } from "./../../../../services/map.toolbox.service";
import { PermissionsService } from "src/app/helpers/permissions.service";

@Component({
  selector: "toolbox-proximity",
  templateUrl: "./proximity.component.html",
  styleUrls: ["./proximity.component.scss"],
})
export class ButtonProximityComponent implements OnInit {
  @ViewChild(MatButtonToggle) btnSearchGroup: MatButtonToggle;
  permissions: [];

  public action: string;
  private service: MapToolboxService;

  constructor(
    service: MapToolboxService,
    public permissionService: PermissionsService
  ) {
    this.service = service;
  }
  ngOnInit(): void {
    this.permissions = this.permissionService.getPermissions();
    this.service.currentToolboxCommand.subscribe((action) => {
      this.action = action;
    });
  }

  toggleView(change: MatButtonToggleChange): void {
    const { value, source } = change;
    this.btnSearchGroup = source;
    this.action = value;
    this.service.setToolboxCommand(value);
  }
}
