import { Validators } from "@angular/forms";

export class Validation {
  public rules = (): object => {
    return {
      idEmpresa: ["", Validators.required],
      selecaoEmpresa: ["", Validators.required],
      codigoSensor: ["", Validators.required],
    };
  };
}
