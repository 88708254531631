import { Component, OnInit, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { ApiService } from '../../../services/api.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { AuthenticationService } from 'src/app/services/authentication.service';
import storageX from 'src/app/storageCore/storageX';
import { STORAGE_KEY_CD_USUARIO, STORAGE_KEY_CURRENT_USER } from 'src/app/storageCore/constStorageKeys';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss'],
})
export class TerminalComponent implements OnInit {
  loading = false;
  private service: ApiService;

  public action: any = '';
  public password = '';
  public velocityLimitKMH = '';
  public intervalSendMoveSeg = '';
  public intervalSendMoveMin = '';
  public intervalSendMoveRoamingSeg = '';
  public intervalSendMoveRoamingMin = '';
  public phone = '';
  public commandText = '';
  public errorMessage;
  public successMessage;
  public loginOk: boolean;

  displayedColumns: string[] = ['select', 'plate'];
  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);

  public handleFilter = (value: string): void => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<TerminalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public selecteds: any
  ) {
    this.service = apiService;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.dataSource.data = this.data;
    if (this.selecteds && this.selecteds.length > 0) {
      //Pega a row com base no ID
      this.selecteds.forEach((row) => this.selection.select(row));
    }
  }

  public async login() {
    this.errorMessage = '';
    this.loading = true;
    try {
      const login = await this.authenticationService.login(
        storageX.whereKeyIs(STORAGE_KEY_CD_USUARIO).get(), //localStorage.getItem("cdUsuario"),
        this.password
      );

      this.loading = false;
      this.loginOk = true;

    } catch (exception) {
      this.loginOk = false;
      this.errorMessage = 'Login e/ou senha inválidos.';
      this.loading = false;
      console.error(exception);
    }
  }

  toggleView(change: MatButtonToggleChange): void {
    const { value } = change;
    this.action = value;
    this.successMessage = '';
  }

  onSubmitCommand(): void {
    const action = parseInt(this.action);
    this.errorMessage = '';
    this.successMessage = '';
    console.log('Action selected: ', this.action);

    if (action === 14) {
      this.onSendCommand("");
    } else if (action === 15) {
      this.onSendCommand("");
    } else if (action === 40) {
      this.onSendCommand(this.velocityLimitKMH);
    } else if (action === 33) {
      this.onSendCommand(this.intervalSendMoveSeg);
    } else if (action === 34) {
      this.onSendCommand(this.intervalSendMoveMin);
    } else if (action === 35) {
      this.onSendCommand(this.intervalSendMoveRoamingSeg);
    } else if (action === 36) {
      this.onSendCommand(this.intervalSendMoveRoamingMin);
    } else if (action === 32) {
      this.onSendCommand(this.phone);
    } else if (action === 31) {
      this.onSendCommand(this.commandText);
    } else {
      this.onSendCommand();
    }
  }
  async onSendCommand(command?: string): Promise<void> {
    this.loading = true;
    const action = parseInt(this.action);

    console.log('Action selected 2: ', action);
    try {
      const terminals = this.selection.selected;

      console.log('TERMINAL: ', terminals);
      if (!terminals) {
        this.loading = false;
        this.errorMessage = 'Selecione um terminal';
        return;
      }

      if (isNaN(action)) {
        this.loading = false;
        this.errorMessage = 'Selecione um comando';
        return;
      }

      const post = terminals.map((e) => {
        const id = e.id_terminal;

        const data = {
          idUsuarioCriacao: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
          terminal: { id },
          terminalComandoTipo: {
            id: action,
          },
          dsTextoEnvio: command,
        };

        if (!command) {
          delete data.dsTextoEnvio;
        }

        return data;
      });

      const url = 'envio-comando-terminal/all';
      await this.service.post<any>(url, post);
      this.loading = false;
      /// dou messagem de sucesso
      this.loginOk = false;
      this.action = '';

      this.successMessage = 'Comando agendado para envio ao terminal';
    } catch (exception) {
      this.loading = false;
      this.errorMessage = exception.message;
      this.successMessage = '';
      console.error(exception);
    }
  }
}
