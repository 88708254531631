import { Validators } from '@angular/forms';

export class Validation {
  public rules = (): object => {
    return {
      selecaoEmpresa: [''],
      numeroTerminal: ['', Validators.required],
      sim: ['', Validators.required],
      imei: ['', Validators.required],
      senha: ['123456'],
      observacao: ['', Validators.required],
      conectado: ['', Validators.required],
      instalado: ['', Validators.required],
      idTerminalProtocolo: ['', Validators.required],
      idTerminalIcone: [1],
      idEmpresa: [''],
      empresaEdit: [''],
      nomeOut1: [''],
      nomeOut2: [''],
      nomeOut3: [''],
      nomeOut4: [''],
      nomeOut5: [''],
      nomeOut6: [''],
      nomeIn1: [''],
      nomeIn2: [''],
      nomeIn3: [''],
      nomeIn4: [''],
      nomeIn5: [''],
      nomeIn6: [''],
      nomeIn7: [''],
      nomeIn8: [''],
      nomeIn9: [''],
      nomeIn10: [''],
      idVeiculo: ['', Validators.required],
      minutoAlarmeParada: [''],
      minutoAlarmeExcessoDirecao: ['100'],
      minutoAlarmePontoMorto: [''],
      fusoHorarioEnum: [''],
      enumTipoVelocidade: ['', Validators.required],
      limiteExcessoVeloc: ['', Validators.required],
      limiteExcessoVelocChuva: ['', Validators.required],
      limiteExcessoRpm: ['', Validators.required],
      voltagem: ['', Validators.required],
      possuiTeclado: [false],
      possuiTpms: [false],
      possuiSensorFadiga: [false],
      calcularHorimetroParado: [false],
      atualizarEmpresa: [false],
      fatorConversaoVelocidadeCan: [''],
    };
  }
}
