import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MapToolboxService } from "src/app/services/map.toolbox.service";
import { ToolboxControl } from "../maps/toolbox/toolbox_control";
import pathControl from './controls/path.control';

@Injectable({
  providedIn: 'root',
})
export class MarkerMap{

  public showSidebar = true;
  public markersMap = new Array<any>();
  public markersMapSotrePath = new Array<any>();
  private marker$ = new BehaviorSubject<any>(null);  
  public markerTelemetria = null;
  public popupTelemetria = false;

  constructor(private mapService: MapToolboxService,
              public toolboxControl: ToolboxControl){}

  public sendMarker( marker ){
       this.marker$.next( marker );
  }

  public loadMarker(){
    return this.marker$
}

  public openTelemetria(marker){
      this.markerTelemetria = marker;    
      this.popupTelemetria = true;
  }

  public center(){
    this.mapService.setCommand( {
      action: 'center'});
  }

  public setPathHistory($this){
    const values = $this.command?.values;
    pathControl.setPathControlMarkerHistory($this, $this.command, values);
    pathControl.pathControlRouteInMap($this, values);
  }

  onCenterPosition(item, not_action?): void {

    /* if(!not_action){
      this.handleMarkerMap(item);
    } */

    const data = {
      action: 'zoom_in_path',
      values: {
        bounds: [
          {
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          },
        ],
      },
    };
    this.mapService.setCommand(data);
  }
}
