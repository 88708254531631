import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import ApiService from 'src/app/services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { VehicleTypeInterface } from 'src/app/contracts/VehicleType.interface';
import { Router } from '@angular/router';
import { ContentInterface } from 'src/app/contracts/Content.interface';
import { VehicleType } from 'src/app/models/VehicleType.model';

@Component({
  selector: 'app-vehicle-types',
  templateUrl: './vehicle-types.component.html',
  styleUrls: ['./vehicle-types.component.scss'],
})
export class VehicleTypesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[] = ['id', 'nmNome'];

  private service: ApiService;
  public vehicleTypes: MatTableDataSource<VehicleTypeInterface>;

  constructor(apiService: ApiService, private router: Router) {
    this.service = apiService;
    this.vehicleTypes = new MatTableDataSource<VehicleTypeInterface>([]);
  }

  ngOnInit(): void {
    this.loadVehicleTypes();
  }

  public handleFilter = (value: string): void => {
    this.vehicleTypes.filter = value.trim().toLocaleLowerCase();
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadVehicleTypes(pageIndex);
  };

  public handleDelete = (id: string): void => {
    this.deleteReg(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/veiculo-tipo/${id}`]);
  };

  ngAfterViewInit(): void {
    this.vehicleTypes.sort = this.sort;
    this.vehicleTypes.paginator = this.paginator;
  }

  public async deleteReg(id): Promise<void> {
    if (confirm('Tem certeza que deseja deletar esse registro?')) {
      try {
        await this.service.delete<VehicleType>(id, 'veiculo-tipo');
        this.loadVehicleTypes();
      } catch (exception) {
        this.errorMessage = exception.message;
        console.error(exception);
      }
    }
  }

  public async loadVehicleTypes(pageIndex = 0): Promise<void> {
    console.log('pageIndex', pageIndex);
    this.loader = true;
    const params = {
      size: this.pageSize,
    };
    if (pageIndex) {
      params['page'] = pageIndex;
    }

    try {
      const result = await this.service.get<ContentInterface[]>({
        url: 'veiculo-tipo',
        params,
      });

      this.vehicleTypes.data = result['content'];
      this.totalElements = result['totalElements'];
      this.totalPages = result['totalPages'];

      this.loader = false;
    } catch (error) {
      console.error('loadVehicleTypes', error);
      this.loader = false;
    }
  }
}
