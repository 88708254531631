import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { TypeProfile } from "../../models/TypeProfile.model";
import { TypeProfileInterface } from "../../contracts/TypeProfile.interface";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { Page } from "src/app/utils/paginate";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

@Component({
  selector: "app-perfil-tipo",
  templateUrl: "./perfil-tipo.component.html",
  styleUrls: ["./perfil-tipo.component.scss"],
})
export class PerfilTipoComponent implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('filter') filter: ElementRef;
  // @ViewChild('typeFilter') typeFilter: ElementRef;
  public filter;
  public typeFilter;

  public page = new Page();
  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[] = [
    //'id', OCULTADO CAMPO ID DA TABELA - 20102020 - MARCOS TAVARES
    "cdPerfilTipo",
    "nmPerfilTipo",
    "update",
    "clone",
    "view",
    "delete",
  ];

  public filtros = [
    //{ campo: 'id', valor: 'ID' },
    { campo: "cdPerfilTipo", valor: "Tipo Perfil" },
    { campo: "nmPerfilTipo", valor: "Nome Perfil" },
  ];

  private apiBaseService: ApiBaseService;

  public perfilTipo: MatTableDataSource<TypeProfileInterface>;

  constructor(
    apiBaseService: ApiBaseService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.apiBaseService = apiBaseService;
    this.perfilTipo = new MatTableDataSource<TypeProfileInterface>([]);
  }

  ngOnInit(): void {
    this.loadPerfilTipo();
  }
  //public handleFilter = (value: string): void => {
  //this.perfilTipo.filter = value.trim().toLocaleLowerCase();
  //};

  public handleSort = (sort): void => {
    this.loadPerfilTipo(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadPerfilTipo(pageIndex);
  };
  public handleFilter = (): void => {
    this.loadPerfilTipo();
  };

  public handleDelete = (id: string): void => {
    this.deletePerfilTipo(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/perfil-tipo/${id}`]);
  };

  ngAfterViewInit(): void {
    this.perfilTipo.sort = this.sort;
    this.perfilTipo.paginator = this.paginator;
  }

  public async deletePerfilTipo(id): Promise<void> {
    const idUsuario = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        await this.apiBaseService.delete<TypeProfile>(
          idUsuario,
          id,
          new TypeProfile()["nomeConsultaApiBase"]
        );

        this.spinner.hide();

        this.loadPerfilTipo();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public async loadPerfilTipo(pageIndex = 0, sort?): Promise<void> {
    const params = {
      size: this.pageSize,
      page: pageIndex,
    };

    try {
      this.spinner.show();

      const result = await this.apiBaseService.get<any>({
        url: new TypeProfile()["nomeConsultaApiBase"],
        params,
      });
      this.perfilTipo.data = result["content"];
      this.page = result["page"];

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
