import { Component, ViewChild, OnInit, Injectable } from "@angular/core";
import { MatButtonToggle } from "@angular/material/button-toggle";

import { MapToolboxService } from "./../../../../services/map.toolbox.service";
import { MapButtonService } from "./../../../../services/map.buttom.service";
import {PermissionsService} from "../../../../helpers/permissions.service";

@Component({
  selector: "toolbox-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
@Injectable({
  providedIn: "root",
})
export class ButtonSearchComponent implements OnInit {
  @ViewChild(MatButtonToggle) btnSearchGroup: MatButtonToggle;
  permissions: [];

  selected = false;

  private service: MapToolboxService;
  private mapButtonService: MapButtonService;

  constructor(service: MapToolboxService,
              mapButtonService: MapButtonService,
              public permissionService: PermissionsService) {
    this.service = service;
    this.mapButtonService = mapButtonService;
  }
  ngOnInit(): void {
    this.permissions = this.permissionService.getPermissions();
    this.mapButtonService.currentSearchButton.subscribe((action) => {
      if (action !== this.selected) this.selected = action;
    });
  }

  onChangeValue(): void {
    if (this.selected) {
      this.mapButtonService.setSearchButton(false);
      this.service.setToolboxCommand("search_reset");
      return;
    }
    const value = !this.selected;
    this.selected = value;
    this.mapButtonService.setSearchButton(value);
    this.service.setToolboxCommand("search");
  }
}
