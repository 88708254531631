import { ToolsService } from "./../../services/migrate/tools.service";
import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GoogleMapsAPIWrapper } from "@agm/core";

@Component({
  selector: "app-stop-report-map",
  templateUrl: "./stop-report-map.component.html",
  styleUrls: ["./stop-report-map.component.scss"],
})
export class StopReportMapComponent implements OnInit {
  public lat = 0;
  public lng = 0;
  public zoom = 3;
  public map: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    private tools: ToolsService,
    private formBuilder: FormBuilder,
    private gmapsApi: GoogleMapsAPIWrapper,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.lat = data.lat;
    this.lng = data.lng;
  }

  ngOnInit() {}

  mapReady(map): void {
    this.map = map;
    var latLng = new google.maps.LatLng(this.lat, this.lng);
    this.map.setCenter(latLng);
    this.map.setZoom(13);

    new google.maps.Marker({
      position: latLng,
      map,
      title: "",
    });
  }
}
