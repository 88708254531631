export enum MESSAGES {
  cdUsuario = "Login",
  nmUsuario = "Usuário",
  dsEmail = "Email",
  cdPlaca = "Placa",
  nmMarca = "Marca",
  nmModelo = "Modelo",
  nome = "Nome",
  email = "Email",
  contato = "Contato",
  placa = "Placa",
  nmterminal = "Número Terminal",
  nmempresa = "Empresa",
  marca = "Marca",
}
