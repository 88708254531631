import { ToolsService } from "src/app/services/migrate/tools.service";

import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { DatePipe } from "@angular/common";
import { MinioService } from "src/app/services/minio.service";

@Component({
  selector: "app-view-treat-analysis",
  templateUrl: "./view-treat-analysis.component.html",
  styleUrls: ["./view-treat-analysis.component.scss"],
})
export class ViewTreatAnalysisComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public minioService: MinioService;
  public datePipe: DatePipe;

  public dataSource: MatTableDataSource<any>;

  public analyzeReportUuid = "";
  public tableColumns: string[];

  public errorMessage;

  ngOnInit(): void {
    this.analyzeReportUuid = this.activatedRoute.snapshot.params.uuid;
    this.loadTableDefinitions();
    this.loadDataElements();
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "treatmentDate",
      "operatorDealings",
      "nameOperator",
      "descriptionAnalyze",
      "minioObject",
      "state",
      "city",
      "update",
      "delete",
    ];
  }

  exportFile(element): void {
    this.minioService.exportReport(element).subscribe();
  }

  constructor(
    private tools: ToolsService,
    datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    analyzeTripService: AnalyzeTripService,
    minioService: MinioService
  ) {
    this.datePipe = datePipe;
    this.analyzeTripService = analyzeTripService;
    this.minioService = minioService;
  }

  public async handleDelete(element): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Excluir Tratativa",
      html: `<h6>Tem certeza que você deseja excluir essa Tratativa? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.deleteAnalyze(element);
      }
    });
  }

  deleteAnalyze(element): void {
    this.spinner.show();
    this.analyzeTripService.deleteTreatOperator(element.uuid).subscribe(
      (v) => {
        console.log("Exclusão realizada com sucesso");
        this.loadDataElements();
        this.spinner.hide();
      },
      (e) => {
        console.log("Erro ao excluir o transportador");
        this.spinner.hide();
        this.errorMessage = e.message;
        throw new EvalError(e);
      }
    );
  }

  public async loadDataElements() {
    this.spinner.show();
    try {
      this.analyzeTripService
        .getOperatorTreat(this.analyzeReportUuid, "false")
        .subscribe((t) => {
          this.dataSource = t;
        });

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }
}
