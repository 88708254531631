import { ToolsService } from "../services/migrate/tools.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-create-new-password-with-code",
  templateUrl: "./create-new-password-with-code.component.html",
  styleUrls: ["./create-new-password-with-code.component.scss"],
})
export class CreateNewPasswordWithCodeComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  returnUrl: string;
  public recover = true;
  public errorMessage;
  private id;
  public showPassword = false;
  constructor(
    private formBuilder: FormBuilder,
    public tools: ToolsService,
    private route: ActivatedRoute
  ) {
    //this.tools.builder().api.oAuth();
    route.params.subscribe((params) => {
      this.id = params["id"];
    });
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });
  }

  passwordConfirming() {
    if (
      this.forgotPasswordForm.value.password !==
      this.forgotPasswordForm.value.confirmPassword
    ) {
      this.tools.builder().openToast.emit("As senhas devem ser igauis");
      throw new EvalError("validate rules password");
    }
  }

  private validateRulesPassword() {
    this.passwordConfirming();
    /* 
        var senha = this.forgotPasswordForm.value.password;
      var regex = new RegExp(/(?=.[A-Z])(?=.\d)(?=.)./, "g");

      console.log(regex, senha, )

       if(!regex.exec(senha))
      {
        this.tools.builder().openToast.emit("A senha deve conter no mínimo 6 caracteres, pelo menos uma letra maiúscula e um número!")
          throw new EvalError("validate rules password")
      }
      return true; */
  }

  public save() {
    this.validateRulesPassword();

    if (this.forgotPasswordForm.invalid) {
      this.tools
        .builder()
        .openToast.emit(
          "Algo errado, você preencher corretamentte os campos para continuar!"
        );
      return;
    }

    const payload = {
      password: this.forgotPasswordForm.value.password,
    };

    this.tools.builder().spinner.show();

    this.tools
      .builder()
      .api.patch(
        `${this.tools.builder().api.getApiUrl().notify}/users/create-password/${
          this.id
        }`,
        payload
      )
      .subscribe(() => {
        this.tools.builder().spinner.hide();

        Swal.fire(
          "Tudo certo",
          "Senha criada com sucesso. Você já pode efetuar o login com sua nova senha!",
          "success"
        ).then(() => {
          this.tools.builder().router.navigate(["/login"]);
        });
      });
  }
}
