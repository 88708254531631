/* eslint-disable @typescript-eslint/camelcase */
import { Component, ViewChild, AfterViewInit, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { Route } from "../../models/Route.model";
import { ApiService } from "../../services/api.service";
import { RouteInterface } from "../../contracts/Route.interface";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Page } from "src/app/utils/paginate";
import { CoreService } from "src/app/services/migrate/api.core.service";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-routes",
  templateUrl: "./routes.component.html",
  styleUrls: ["./routes.component.scss"],
})
export class RoutesComponent implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('filter') filter: ElementRef;
  // @ViewChild('typeFilter') typeFilter: ElementRef;
  public filter;
  public typeFilter;

  public page = new Page();
  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;

  /* OCULTADO CAMPO ID DA TABELA - 20102020 - MARCOS TAVARES
  public displayedColumns: string[] = [
    'id',
    'nmNome',
    'cdCodigo',
    'cdNumeroRegistro',
    'ativo',
    'update',
    'delete',
  ];
  */

  public displayedColumns: string[] = [
    "nmNome",
    "empresa.nmNome",
    "nrLimiteVelocidade",
    "nrDistanciaTolerada",
    "flAtivoPontoControle",
    "update",
    "delete",
    "view",
  ];

  /* OCULTADO CAMPO ID DA TABELA - 20102020 - MARCOS TAVARES
  public filtros = [
    { campo: 'id', valor: 'ID' },
    { campo: 'nmNome', valor: 'Nome Rota' },
    { campo: 'cdNumeroRegistro', valor: 'N�mero de Registro' },
  ];
  */

  public filtros = [{ campo: "nmNome", valor: "Nome Rota" }];

  private service: ApiService;
  private coreService: CoreService;

  public routes: MatTableDataSource<RouteInterface>;

  constructor(
    apiCoreService: CoreService,
    private spinner: NgxSpinnerService,
    apiService: ApiService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.service = apiService;
    this.coreService = apiCoreService;

    this.routes = new MatTableDataSource<RouteInterface>([]);
  }

  ngOnInit(): void {
    this.loadRoutes();
  }

  //public handleFilter = (value: string): void => {
  //this.routes.filter = value.trim().toLocaleLowerCase();
  //};

  public handleSort = (sort): void => {
    this.loadRoutes(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadRoutes(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadRoutes();
  };
  public handleDelete = (id: string): void => {
    this.deleteRoute(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/routes/${id}`]);
  };

  ngAfterViewInit(): void {
    this.routes.sort = this.sort;
    this.routes.paginator = this.paginator;
  }

  public async deleteRoute(id): Promise<void> {
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        await this.service.delete<Route>(id, "rota");

        this.spinner.hide();

        this.loadRoutes();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public async loadRoutes(pageIndex = 0, sort?): Promise<void> {
    try {
      this.spinner.show();

      const params = {
        size: this.pageSize,
        page: pageIndex,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      const result = await this.coreService.get<any>({
        url: "v2/routes",
        params,
      });

      this.routes.data = result.elements;
      this.page = result.page;

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.routes.filter = filterValue.trim().toLowerCase();

    if (this.routes.paginator) {
      this.routes.paginator.firstPage();
    }
  }
}
