import { Pneu } from "./Pneu.model";
import { PosicaoPneu } from "./PosicaoPneu.model";
import { Sensor } from "./Sensor.model";

export class VeiculoSensor {
  nomeConsultaApiBase = "veiculo-sensor/getsensor/";
  nomeConsultaApiBaseInsert = "veiculo-sensor/insertsensor";
  nomeConsultaApiBaseRemove = "veiculo-sensor/removesensor";
  nomeConsultaApiBaseRemoveAll = "veiculo-sensor/remove-all-sensor";
  nomeConsultaApiBaseAddAll = "veiculo-sensor/add-all-sensor";

  id: number;
  ativo: boolean;
  pneu: Pneu;
  sensor: Sensor;
  nrPressaoMinima: string;
  nrPressaoMaxima: string;
  nrTemperaturaMaxima: string;
  posicaoPneu?: PosicaoPneu;
  codigoSensor?: string;
  nrFogo?: string;
  statusPneu?: number;
  temperaturaAtual?: number;
  pressaoAtual?: number;
  statusPneuDesc?: string;
}
