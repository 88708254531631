<mat-dialog-content class="mat-typography">

    <div class="general-filter-container">
        <h2>Buscar um veículo pela placa</h2>
        <div class="form-group">
            <form>
                    <input class="form-control"
                           type="text"
                           placeholder="Busque o veiculo pela placa"
                           [matAutocomplete]="auto"
                           [formControl]="myControl">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setVehicle($event)"
                                      [displayWith]="getFilterDescription">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option.placa}}
                        </mat-option>

                    </mat-autocomplete>
            </form>
        </div>

    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button (click)="close()">Fechar</button>
    <button [disabled]="this.selectedOption == null" mat-button (click)="submit()">
        Ver no mapa
    </button>
</mat-dialog-actions>


