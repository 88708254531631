import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import storageX from "../storageCore/storageX";
import { STORAGE_KEY_ACCESS_TOKEN_WSO2 } from "../storageCore/constStorageKeys";
import { PageEvent } from "@angular/material/paginator";

@Injectable({
  providedIn: "root",
})
export class HttpUtil {
  token: any;
  clientId: any;

  buildPaginator(page: PageEvent, sort: string): Map<string, string> {
    const params = new Map();
    params.set("page", page.pageIndex);
    params.set("size", page.pageSize);
    params.set("sort", sort);
    return params;
  }
  buildHeadersGateway(): HttpHeaders {
    let headers = new HttpHeaders();

    if (this.token !== "" && this.clientId !== "") {
      headers = headers.append(
        "Authorization",
        "Bearer " + storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get()
      );
    }

    headers = headers.set("Accept", "application/hal+json, application/json");

    return headers;
  }
}
