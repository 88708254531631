  <div class="main-page cadastros">
  <div class="container-fluid">
    <app-title>{{ title ? title : 'Inserir novo' }}</app-title>

    <!-- ITEM CARDS -->
    <div class="row mt-3 no-gutters p-4 row-form" *ngIf="!companyId || (companyId && !loader)">
      <div class="col-12">
        <form [formGroup]="driverForm" (ngSubmit)="submitForm()">
          <h2 class="mb-3" *ngIf="title == 'Cadastrar Motorista' || title == 'Duplicar Motorista'">Novo Motorista</h2>
          <h2 class="my-3" *ngIf="title == 'Atualizar Motorista'">{{ titleVar }} - Atual</h2>
          <div class="form-row" *ngIf="title == 'Atualizar Motorista'">
            <div class="form-group col-md-8">
              <mat-form-field>
                <mat-label>{{ titleVar }}</mat-label>
                <mat-select formControlName="empresa" [(value)]="empresaSelected" [disabled]=true>
                  <mat-option *ngFor="let empresa of companies" [value]="empresa.id">
                    {{ empresa.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-8">
              <mat-form-field>
                <mat-label>Tipo de Cadastro</mat-label>
                <mat-select formControlName="selecaoEmpresa" [(value)]="empresaFilterSelected" [(ngModel)]="empresaFilterSelected" 
                (selectionChange)="this.filterCompany()">
                  <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <validation-errors [form]="driverForm" controlLabel="Tipo de Cadastro" controlName="selecaoEmpresa">
              </validation-errors>
            </div>
          </div>
          <div class="form-row"*ngIf="title == 'Cadastrar Motorista' || title == 'Duplicar Motorista'">
            <div class="form-group col-md-8">
              <mat-form-field>
                <mat-label *ngIf="driverForm.get('selecaoEmpresa').value == null">Selecione um tipo de cadastro</mat-label>
                <mat-label *ngIf="driverForm.get('selecaoEmpresa').value == 'Matriz'">Matrizes</mat-label>
                <mat-label *ngIf="driverForm.get('selecaoEmpresa').value == 'Filial'">Filiais</mat-label>
                <mat-label *ngIf="driverForm.get('selecaoEmpresa').value == 'Divisão'">Divisões</mat-label>
                <mat-select formControlName="empresa" [(value)]="empresaSelected" required>
                  <mat-option *ngFor="let empresa of companiesFilter" [value]="empresa" >
                    {{ empresa.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row"*ngIf="title == 'Atualizar Motorista'">
            <div class="form-group col-md-8">
              <mat-form-field>
                <mat-label *ngIf="driverForm.get('selecaoEmpresa').value == null">Selecione um tipo de cadastro</mat-label>
                <mat-label *ngIf="driverForm.get('selecaoEmpresa').value == 'Matriz'">Matrizes</mat-label>
                <mat-label *ngIf="driverForm.get('selecaoEmpresa').value == 'Filial'">Filiais</mat-label>
                <mat-label *ngIf="driverForm.get('selecaoEmpresa').value == 'Divisão'">Divisões</mat-label>
                <mat-select formControlName="empresaEdit">
                  <mat-option *ngFor="let empresaEdit of companiesFilter" [value]="empresaEdit">
                    {{ empresaEdit.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <h2 class="mb-3">Dados cadastrais</h2>
          <div class="form-row">
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Matrícula</mat-label>
                <input formControlName="cdCodigo" matInput placeholder="Código" maxlength="5" minlength="5"  (keypress)="numberOnly($event)" required />
              </mat-form-field>

              <validation-errors [form]="driverForm" controlLabel="Código" controlName="cdCodigo">
              </validation-errors>
            </div>
            <div class="form-group col-md-8">
              <mat-form-field appearance="outline">
                <mat-label>Nome</mat-label>
                <input formControlName="nmNome" matInput placeholder="Nome" maxlength="100" required>
              </mat-form-field>

              <validation-errors [form]="driverForm" controlLabel="Nome" controlName="nmNome">
              </validation-errors>
            </div>

            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>iButton</mat-label>
                <input formControlName="cdIbutton" matInput placeholder="iButton" maxlength="8" minlength="8" />
              </mat-form-field>
              <validation-errors [form]="driverForm" controlLabel="iButton" controlName="cdIbutton">
              </validation-errors>
            </div>

              <div class="form-group col-lg-2">
                  <mat-form-field appearance="outline">
                      <mat-label>RFID</mat-label>
                      <input formControlName="rfid" matInput placeholder="RFID" maxlength="11" minlength="11" />
                  </mat-form-field>
                  <validation-errors [form]="driverForm" controlLabel="RFID" controlName="rfid">
                  </validation-errors>
              </div>

            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Senha</mat-label>
                <input formControlName="cdSenha" matInput placeholder="Senha" maxlength="4" minlength="4"  (keypress)="numberOnly($event)"  required/>
              </mat-form-field>

              <validation-errors [form]="driverForm" controlLabel="Senha" controlName="cdSenha">
              </validation-errors>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Número de Registro</mat-label>
                <input formControlName="cdNumeroRegistro" matInput placeholder="Número de Registro" maxlength="50" />
              </mat-form-field>
            </div>
            <div class="form-group col-2">
              <mat-form-field appearance="outline">
                <mat-label>Nascimento</mat-label>
                <input formControlName="dtNascimento" type="date" matInput placeholder="Data Nascimento" required />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>CPF</mat-label>
                <input formControlName="cdCpf" matInput placeholder="CPF"  (keypress)="numberOnly($event)" maxlength="11"  required/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>RG</mat-label>
                <input formControlName="cdRG" matInput placeholder="RG" required/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Órgão Emissor</mat-label>
                <input formControlName="nmOrgaoEmissor" matInput placeholder="Orgão Emissor" required/>
              </mat-form-field>
            </div>
          </div>
          <h2 class="mb-3">Habilitação</h2>
          <div class="form-row">
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Número CNH</mat-label>
                <input formControlName="cdNumeroCnh" matInput placeholder="Número CNH" maxlength="50"  (keypress)="numberOnly($event)" required/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Categoria CNH</mat-label>
                <input formControlName="cdCategoriaCnh" matInput placeholder="Categoria CNH" maxlength="10" required/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Validade CNH</mat-label>
                <input formControlName="dtValidadeCnh" type="date" matInput placeholder="Validade CNH" required/>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-12">

              <!-- provisório -->
              <div  class="notify form-group col-lg-12">
                <h6 *ngIf="this.notifyDate" style="font-size: 13px;
                    color: #0086f1;
                margin-bottom: 17px;"> Notificação habilitada para  a data:  {{this.notifyDate | date}}  </h6>
                <h6 *ngIf="!this.notifyDate" style="font-size: 13px;
                           margin-bottom: 17px;">
                Habilitar Noticação por E-mail para vencimento da CNH?</h6>
                <p-selectButton [ngModelOptions]="{standalone: true}" [(ngModel)]="stateOp" [options]="stateOptions" (onChange)="handleChange($event)"  optionLabel="label" optionValue="value"></p-selectButton>
            </div>
             <!--  <mat-slide-toggle name="flAlarmeVencimentoCnhEmail" formControlName="flAlarmeVencimentoCnhEmail"
                color="primary">Relatório Vencimento CNH Email
              </mat-slide-toggle> -->
            </div>

            <!-- habilitar sms quando recurso for desenvolvido -->

            <!-- <div class="form-group col-lg-6">
              <mat-slide-toggle name="flAlarmeVencimentoCnhSms" formControlName="flAlarmeVencimentoCnhSms"
                color="primary">
                Relatório Vencimento CNH SMS
              </mat-slide-toggle>
            </div> -->

          </div>

          <h2 class="my-3">Endereço</h2>
          <div class="form-row">
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>CEP</mat-label>
                <input formControlName="cdCep" matInput placeholder="CEP" maxlength="10" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-1">
              <a (click)="searchCep()" mat-icon-button class="btn-cep-search">
                <mat-icon>search</mat-icon>
              </a>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-8">
              <mat-form-field appearance="outline">
                <mat-label>Logradouro</mat-label>
                <input formControlName="txEndereco" matInput placeholder="Logradouro" maxlength="80" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-1">
              <mat-form-field appearance="outline">
                <mat-label>Número</mat-label>
                <input formControlName="cdNumero" matInput placeholder="Número" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Complemento</mat-label>
                <input formControlName="dsComplemento" matInput placeholder="Complemento" maxlength="40" />
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Bairro</mat-label>
                <input formControlName="nmBairro" matInput placeholder="Bairro" maxlength="100" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Cidade</mat-label>
                <input formControlName="nmCidade" matInput placeholder="Cidade" maxlength="100" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Estado</mat-label>
                <input formControlName="nmEstado" matInput placeholder="Estado" maxlength="100" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>País</mat-label>
                <input formControlName="nmPais" matInput placeholder="País" maxlength="100" />
              </mat-form-field>
            </div>
          </div>
          <h2 class="my-3">Contato</h2>
          <div class="form-row">
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Telefone</mat-label>
                <input formControlName="nrTelefone" required matInput placeholder="Telefone" maxlength="40" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>E-mail</mat-label>
                <input formControlName="dsEmail" required matInput placeholder="E-mail" maxlength="80" />
              </mat-form-field>
            </div>
          </div>

          <h2 class="my-3">Observações</h2>
          <div class="form-row">
            <div class="form-group col-lg-12">
              <mat-form-field appearance="outline">
                <mat-label>Observações</mat-label>
                <input formControlName="dsObservacao" required matInput placeholder="Informe observações" maxlength="500" />
              </mat-form-field>
            </div>
          </div>

          <hr />

          <h2 class="my-3">Autorizações ANTT</h2>

          <div formArrayName="motoristaAutorizacaoAnttList">
            <div *ngFor="
                let item of motoristaAutorizacaoAnttItems.controls;
                let itemIndex = index
              " [formGroupName]="itemIndex">
              <input *ngIf="driverId" type="hidden" formControlName="autorizacaoAnttId" />

              <div class="form-row">
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo de autorização</mat-label>
                    <input formControlName="nmTipoAutorizacao" matInput placeholder="Tipo de autorização" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Número</mat-label>
                    <input formControlName="nrNumero" matInput placeholder="Número" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de cadastro </mat-label>
                    <input type="date" formControlName="dtCadastro" matInput placeholder="Data de cadastro" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de vencimento</mat-label>
                    <input type="date" formControlName="dtVencimento" matInput placeholder="Data de vencimento" />
                  </mat-form-field>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-lg-4">
                  <span>Gerar alerta de vencimento?</span>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" formControlName="flGerarAlertaVencimento" type="radio"
                      id="inlineAnttRadio1" [value]="true" />
                    <label class="form-check-label" for="inlineAnttRadio1">Sim</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="flGerarAlertaVencimento"
                      id="inlineAnttRadio2" [value]="false" />
                    <label class="form-check-label" for="inlineAnttRadio2">Não</label>
                  </div>
                </div>
                <div class="form-group col-lg-4 text-left text-lg-right">
                  <a (click)="deleteAutorizacaoAnttItem(itemIndex)">Excluir autorização
                    <img src="assets/img/trash.svg" class="mb-1" height="20" alt="" /></a>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-12 text-center">
            <a class="repeat-line" (click)="addAutorizacaoAnttItem()"><img src="assets/img/plus.svg" class="mb-0"
                height="20" alt="" />
              Adicionar nova autorização</a>
          </div>

          <hr />

          <h2 class="my-3">Outras Autorizações</h2>

          <div formArrayName="motoristaAutorizacaoList">
            <div *ngFor="
                let item of motoristaAutorizacaoItems.controls;
                let itemIndex = index
              " [formGroupName]="itemIndex">
              <input *ngIf="driverId" type="hidden" formControlName="autorizacaoId" />

              <div class="form-row">
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo de autorização</mat-label>
                    <input formControlName="nmTipoAutorizacao" matInput placeholder="Tipo de autorização" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Número</mat-label>
                    <input formControlName="nrNumero" matInput placeholder="Número" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de cadastro </mat-label>
                    <input type="date" formControlName="dtCadastro" matInput placeholder="Data de cadastro" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de vencimento</mat-label>
                    <input type="date" formControlName="dtVencimento" matInput placeholder="Data de vencimento" />
                  </mat-form-field>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-lg-4">
                  <span>Gerar alerta de vencimento?</span>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" formControlName="flGerarAlertaVencimento" type="radio"
                      id="inlineRadio1" [value]="true" />
                    <label class="form-check-label" for="inlineRadio1">Sim</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="flGerarAlertaVencimento"
                      id="inlineRadio2" [value]="false" />
                    <label class="form-check-label" for="inlineRadio2">Não</label>
                  </div>
                </div>
                <div class="form-group col-lg-4 text-left text-lg-right">
                  <a (click)="deleteAutorizacaoItem(itemIndex)">Excluir autorização
                    <img src="assets/img/trash.svg" class="mb-1" height="20" alt="" /></a>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-12 text-center">
            <a class="repeat-line" (click)="addAutorizacaoItem()"><img src="assets/img/plus.svg" class="mb-0"
                height="20" alt="" />
              Adicionar nova autorização</a>
          </div>

  <!--         <hr />

          <h2 class="my-3">Jornada de Trabalho</h2> -->

      <!--     <div formArrayName="motoristaJornadaTrabalhoList">
            <div *ngFor="
                let item of motoristaJornadaTrabalhoItems.controls;
                let itemIndex = index
              " [formGroupName]="itemIndex">


              <div class="form-row">
                <div class="form-group col-lg-2">
                  <section class="checkbox">
                    <mat-checkbox formControlName="selected" [checked]="days[itemIndex].cdDiaSemana"
                      [value]="days[itemIndex].cdDiaSemana">
                      {{ days[itemIndex].name }}
                    </mat-checkbox>
                  </section>
                </div>
                <div class="form-group col-lg-1">
                  <mat-label>De</mat-label>
                  <mat-form-field appearance="outline">
                    <input formControlName="cdHoraInicio" matInput type="time" placeholder="00:00" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-1">
                  <mat-label>às</mat-label>
                  <mat-form-field appearance="outline">
                    <input formControlName="cdHoraInicioIntervalo" matInput type="time" placeholder="00:00" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-1"></div>
                <div class="form-group col-lg-1">
                  <mat-label>Intervalo de</mat-label>
                  <mat-form-field appearance="outline">
                    <input formControlName="cdHoraFim" matInput type="time" placeholder="00:00" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-1">
                  <mat-label>às</mat-label>
                  <mat-form-field appearance="outline">
                    <input formControlName="cdHoraFimIntervalo" matInput type="time" placeholder="00:00" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div> -->

          <div class="form-row">
            <div class="form-group col-lg-12">
              <!-- <mat-form-field appearance="outline">
                                <mat-label>Observações</mat-label>
                                <input formControlName="dsObservacao" matInput placeholder="Informe observações" />
                            </mat-form-field> -->
            </div>
          </div>

          <div *ngIf="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>

          <div class="form-group mt-2 mb-0 text-center">
            <back-button></back-button>
            <button type="submit" [disabled]="!driverForm.valid"
              class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<p-dialog [closable]="false" [draggable]="false" [modal]="true" [(visible)]="this.visible" [breakpoints]="{'960px': '75vw'}"
[style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">

<ng-template pTemplate="header">
    <div style="
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bolder">
      Selecione uma data gerar uma notificação!
    </div>
  </ng-template>

  <div class="container">
    <div class="row mt-12 row-form">
        <div class="col-12">
          <div class="form-group">
            <div  class="col-12" style="text-align: center;">
                <p-calendar [required]="true"
                [monthNavigator]="true" 
                [yearNavigator]="true"
                 [yearRange]="this.yearRange"
                 inputId="navigators"
                 (onSelect)="this.onSelect($event)"
                 (onClose)="this.onClose()"
                [inline]="true"
                [readonlyInput]="true"  dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
          </div>
          </div>
      </div>
  </div>

  <ng-template pTemplate="footer">
      <div style="text-align: center;">
        <p-button (click)="this.cancelChange()" label="Cancelar" styleClass="p-button-text"></p-button>
        <p-button *ngIf="this.invlidDate" [disabled]="this.invlidDate" label="Concluir" styleClass="p-button-text"></p-button>
        <p-button *ngIf="!this.invlidDate" (click)="this.visible = false" label="Concluir" styleClass="p-button-text"></p-button>
      </div>
  </ng-template>
</p-dialog>
