import { Component, ViewChild, OnInit } from '@angular/core';
import {
  MatButtonToggleChange,
  MatButtonToggle,
} from '@angular/material/button-toggle';
import { MapToolboxService } from './../../../../services/map.toolbox.service';
import {PermissionsService} from "../../../../helpers/permissions.service";

@Component({
  selector: 'toolbox-add-marker',
  templateUrl: './button-add-marker.component.html',
  styleUrls: ['./button-add-marker.component.scss'],
})
export class ButtonAddMarkerComponent implements OnInit {
  @ViewChild(MatButtonToggle) btnAddMarker: MatButtonToggle;
  permissions: [];

  public action: string;
  private service: MapToolboxService;

  constructor(service: MapToolboxService,
              public permissionService: PermissionsService) {
    this.service = service;
  }
  ngOnInit(): void {
    this.permissions = this.permissionService.getPermissions();
    this.service.currentToolboxCommand.subscribe((action) => {
      this.action = action;
    });
  }

  toggleView(change: MatButtonToggleChange): void {
    const { value, source } = change;
    this.btnAddMarker = source;
    this.action = value;
    this.service.setToolboxCommand(value);
  }
}
