import { ToastMsgErro, ToastMsgInfo, ToastMsgSuccess } from './toastMsg';

export const TOAST_ERROR = function (msg, key?){
  return new ToastMsgErro(msg, key)
};

export const TOAST_INFO = function (msg, key?){
  return new ToastMsgInfo(msg, key)
};

export const TOAST_SUCCESSO = function (msg, key?){
  return new ToastMsgSuccess(msg, key)
};
