import { ApiBaseService } from "./../../services/migrate/api.base.service";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { User } from "../../models/User.model";
import { Validation } from "./validation";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-create-user",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ViewUserComponent implements OnInit {
  public userForm: FormGroup;
  public usuarioSituacaoSelected;
  public usuarioTipoSelected;
  public user: User;
  public userId: number;
  public title = "Visualizar Usuário";
  public titleVar = "";
  public errorMessage;
  private service: ApiBaseService;
  private validation: Validation;
  public types: any = [];
  public situations: any = [];

  ngOnInit(): void {
    this.userForm = this.formBuilder.group(this.validation.rules());
    this.userId = this.activatedRoute.snapshot.params["id"];
    this.loadUser(this.userId);
    this.setup();
  }

  public async loadUser(id): Promise<void> {
    try {
      this.spinner.show();

      const response = await this.service.get<User>({
        url: new User()["nomeConsultaApiBase"] + "/" + this.userId,
      });

      this.user = response as User;

      this.fillUserData();
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  async setup(): Promise<void> {}

  constructor(
    private apiService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.service = apiService;
    this.validation = new Validation();
  }

  fillUserData(): void {
    const fields = ["cdUsuario", "nrMatricula", "nmUsuario", "dsEmail"];
    fields.forEach((element) => {
      this.userForm.controls[element].setValue(this.user[element]);
    });
  }
}
