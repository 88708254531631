<form [formGroup]="steppeOneForm">
    <div class="form-row">
        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Posição Pneu</mat-label>
              <input formControlName="cdPosicaoPneu"  placeholder="Posição Pneu" matInput [readonly]=true [disabled]=true/>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Pneu(nr de fogo)</mat-label>
                    <input formControlName="pneu"  
                    placeholder="Número de fogo" matInput [readonly]=true [readonly]=true [disabled]=true />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Sensor</mat-label>
              <mat-select formControlName="cdSensor" [(value)]="sensorRightSelect" [(ngModel)]="sensorRightSelect" [readonly]=true [disabled]=true >
                <mat-option *ngFor="let type of sensorRightCombo" [value]="type.id">
                  {{ type.codigoSensor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Min (PSI)</mat-label>
                <input required formControlName="nrPressaoMinima" pattern="[0-9]*" matInput [readonly]=true [disabled]=true 
                 placeholder="Pressão Min (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Máx (PSI)*</mat-label>
                <input required formControlName="nrPressaoMaxima" pattern="[0-9]*" matInput [readonly]=true [disabled]=true 
                placeholder="Pressão Max (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Temp. Máx (°C)*</mat-label>
                <input required formControlName="nrTemperaturaMaxima" required pattern="[0-9]*"  matInput [readonly]=true [disabled]=true 
                placeholder="Temp. Máx (°C)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>
    </div>
</form>

<form [formGroup]="steppeTwoForm">
    <div class="form-row">

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Posição Pneu</mat-label>
              <input formControlName="cdPosicaoPneu"  placeholder="Posição Pneu" matInput [readonly]=true [disabled]=true/>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Pneu(nr de fogo)</mat-label>
                    <input formControlName="pneu" 
                    placeholder="Número de fogo" matInput [readonly]=true [readonly]=true [disabled]=true />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Sensor</mat-label>
              <mat-select formControlName="cdSensor" [(value)]="sensorLeftSelect" [(ngModel)]="sensorLeftSelect" [readonly]=true [disabled]=true > 
                <mat-option *ngFor="let type of sensorLeftCombo" [value]="type.id">
                  {{ type.codigoSensor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Min (PSI)</mat-label>
                <input required formControlName="nrPressaoMinima" pattern="[0-9]*" matInput [readonly]=true [disabled]=true 
                 placeholder="Pressão Min (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Máx (PSI)*</mat-label>
                <input required formControlName="nrPressaoMaxima" pattern="[0-9]*" matInput [readonly]=true [disabled]=true 
                placeholder="Pressão Max (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Temp. Máx (°C)*</mat-label>
                <input required formControlName="nrTemperaturaMaxima" required pattern="[0-9]*"  matInput [readonly]=true [disabled]=true 
                placeholder="Temp. Máx (°C)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>
    </div>
</form>