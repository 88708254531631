import { MatStepper } from "@angular/material/stepper";
import { ToolsService } from "src/app/services/migrate/tools.service";
import { Component, OnInit, AfterViewInit, Inject } from "@angular/core";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DriverReportMapComponent } from "../../driver-report/driver-report-map.component";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { ActivatedRoute } from "@angular/router";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

@Component({
  selector: "app-view-report-operator",
  templateUrl: "./view-report-operator.component.html",
  styleUrls: ["./view-report-operator.component.scss"],
})
export class ViewReportOperatorComponent implements OnInit, AfterViewInit {
  public analyzeTripService: AnalyzeTripService;

  public colsHeader = new Array<any>();

  public matStepper: MatStepper;
  public matAutocomplete: MatAutocomplete;
  public filterDialog = true;
  public items;

  public analyzeReportUuid = "";

  public resultado = new Array<any>();
  public analyticalReport = new Array<any>();

  constructor(
    private tools: ToolsService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    analyzeTripService: AnalyzeTripService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.analyzeTripService = analyzeTripService;
  }

  ngAfterViewInit(): void {
    this.filterDialog = true;
  }

  ngOnInit() {
    this.loadDataElements(this.data.analyzeReportUuid);
  }

  public onOpenDialogFilter() {
    this.filterDialog = true;
  }

  public openMap(lat, long) {
    const dialogRef = this.dialog.open(DriverReportMapComponent, {
      width: "450px",
      height: "450px",
      data: {
        lat: lat,
        lng: long,
      },
    });
  }

  public async loadDataElements(analyzeReportUuid: any) {
    try {
      this.tools.builder().spinner.show();

      var profile: string = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();

      this.analyzeTripService
        .getMinioJsonOperator(profile, analyzeReportUuid)
        .subscribe((t) => {
          this.analyticalReport.push(t);
        });

      console.log(this.analyticalReport);

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }
}
