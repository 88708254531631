import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { VeiculoSensor } from "src/app/models/VeiculoSensor.model";
import { VwSensor } from "src/app/models/VwSensor.model";
import { EventEmitterSensorService } from "src/app/services/event-emitter-sensor.service";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import Swal from "sweetalert2";

@Component({
  selector: "app-axle-six-simple-view",
  templateUrl: "./axle-six-simple-view.component.html",
  styleUrls: ["./axle-six-simple-view.component.scss"],
})
export class AxleSixSimpleViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorSixLeftSelect: any = [];
  public sensorSixRightSelect: any = [];

  public sensorSixRightCombo: any = [];
  public sensorSixLeftCombo: any = [];

  public axisSixSimpleLeftForm: FormGroup;
  public axisSixSimpleRightForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisSixSimpleLeftForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisSixSimpleRightForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisSixSimpleRightForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 6 ESQ"
      );
      this.axisSixSimpleLeftForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 6 DIR"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo6Direito;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 46) {
              eixo6Direito = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 46) {
              eixo6Direito = filter;
            }
          }
        });

        var eixo6Esquerdo;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 45) {
              eixo6Esquerdo = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 45) {
              eixo6Esquerdo = filter;
            }
          }
        });

        // EIXO 6 DIREITO
        if (eixo6Direito != null) {
          this.axisSixSimpleRightForm.controls["id"].setValue(eixo6Direito.id);

          if (eixo6Direito.sensor != null) {
            this.sensorSixRightCombo.push(eixo6Direito.sensor);
            this.sensorSixRightSelect = eixo6Direito.sensor.id;
          }

          if (eixo6Direito.pneu != null) {
            this.axisSixSimpleRightForm.controls["pneu"].setValue(
              eixo6Direito.pneu.numeroFogo
            );
          }

          this.axisSixSimpleRightForm.controls["nrPressaoMinima"].setValue(
            eixo6Direito.nrPressaoMinima
          );

          this.axisSixSimpleRightForm.controls["nrPressaoMaxima"].setValue(
            eixo6Direito.nrPressaoMaxima
          );

          this.axisSixSimpleRightForm.controls["nrTemperaturaMaxima"].setValue(
            eixo6Direito.nrTemperaturaMaxima
          );
        }

        // EIXO 6 ESQUERDO
        if (eixo6Esquerdo != null) {
          this.axisSixSimpleLeftForm.controls["id"].setValue(eixo6Esquerdo.id);

          if (eixo6Esquerdo.sensor != null) {
            this.sensorSixLeftCombo.push(eixo6Esquerdo.sensor);
            this.sensorSixLeftSelect = eixo6Esquerdo.sensor.id;
          }

          if (eixo6Esquerdo.pneu != null) {
            this.axisSixSimpleLeftForm.controls["pneu"].setValue(
              eixo6Esquerdo.pneu.numeroFogo
            );
          }

          this.axisSixSimpleLeftForm.controls["nrPressaoMinima"].setValue(
            eixo6Esquerdo.nrPressaoMinima
          );

          this.axisSixSimpleLeftForm.controls["nrPressaoMaxima"].setValue(
            eixo6Esquerdo.nrPressaoMaxima
          );

          this.axisSixSimpleLeftForm.controls["nrTemperaturaMaxima"].setValue(
            eixo6Esquerdo.nrTemperaturaMaxima
          );
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
