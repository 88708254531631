import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/helpers/permissions.service';

@Component({
  selector: 'app-registrations',
  templateUrl: './registrations.component.html',
  styleUrls: ['./registrations.component.scss'],
})
export class RegistrationsComponent implements OnInit {
  permissions: [];

  constructor(public permissionService: PermissionsService, public dialog: MatDialog, public router: Router ) {}


  ngOnInit(): void {
    this.permissions = this.permissionService.getPermissions();
  }
}
