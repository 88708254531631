/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  NgZone,
} from "@angular/core";
import { MapsAPILoader } from "@agm/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { MapToolboxService } from "./../../../services/map.toolbox.service";
import { MatDialogRef } from "@angular/material/dialog";
import { MapButtonService } from "./../../../services/map.buttom.service";
import { ThemePalette } from "@angular/material/core";
import lodash from "lodash";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";
import ApiService from "src/app/services/api.service";
import { Company } from "src/app/models/Company.model";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { NgxSpinnerService } from "ngx-spinner";

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  idEmpresa: number;
  subtasks?: Task[];
}

declare let google: any;

@Component({
  selector: "app-route",
  templateUrl: "./route.component.html",
  styleUrls: ["./route.component.scss"],
})
export class RouteComponent implements OnInit {
  public routeName;

  public empresaFilterSelected;

  private mapButtonService: MapButtonService;

  private geoCoder;

  public tabSelected = 0;
  public company: any = [];
  public companiesFilter: any = [];
  public companiesSelect: any = [];
  public empresaSelected;

  command: any;
  addressOrigem: any;

  latitudeOrigem: number;
  longitudeOrigem: number;
  distanceEndereco: number;
  velocidadeEndereco: number;

  latitudeDestino: number;
  longitudeDestino: number;
  addressDestino: any;

  distanceMapa: number;
  velocidadeMapa: number;

  botaoConfirmar = false;
  public empresaValue = null;

  private service: MapToolboxService;
  private apiService: ApiService;

  @ViewChild("searchOrigem") searchElementRefOrigem: ElementRef;
  @ViewChild("searchDestino") searchElementRefDestino: ElementRef;

  task: Task = {
    name: "Todos",
    completed: false,
    color: "primary",
    subtasks: [],
    idEmpresa: 0,
  };
  markers = [];

  constructor(
    apiService: ApiService,
    private serviceApiBase: ApiBaseService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    service: MapToolboxService,
    mapButtonService: MapButtonService,
    public dialogRef: MatDialogRef<any>,
    private spinner: NgxSpinnerService
  ) {
    this.service = service;
    this.mapButtonService = mapButtonService;
    this.apiService = apiService;
    this.dialogRef.afterClosed().subscribe(() => {
      if (!this.botaoConfirmar) {
        this.mapButtonService.setRouteButton(false);
      }
    });
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tabSelected = tabChangeEvent.index;
  }

  setTab(index: number): void {
    console.log("index", index);
  }

  distanceEnderecoChanged(event: any): void {
    this.distanceEndereco = event.target.value;
  }

  velocidadeEnderecoChanged(event: any): void {
    this.velocidadeEndereco = event.target.value;
  }

  distanceMapaChanged(event: any): void {
    this.distanceMapa = event.target.value;
  }

  velocidadeMapaChanged(event: any): void {
    this.velocidadeMapa = event.target.value;
  }

  close(): void {
    this.mapButtonService.setRouteButton(false);
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();

      const autocompleteOrigem = new google.maps.places.Autocomplete(
        this.searchElementRefOrigem.nativeElement
      );
      autocompleteOrigem.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult =
            autocompleteOrigem.getPlace();
          console.log("place", place);

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.addressOrigem = place.formatted_address;
          this.latitudeOrigem = place.geometry.location.lat();
          this.longitudeOrigem = place.geometry.location.lng();
        });
      });

      const autocompleteDestino = new google.maps.places.Autocomplete(
        this.searchElementRefDestino.nativeElement
      );
      autocompleteDestino.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult =
            autocompleteDestino.getPlace();
          console.log("place", place);

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.addressDestino = place.formatted_address;
          this.latitudeDestino = place.geometry.location.lat();
          this.longitudeDestino = place.geometry.location.lng();
        });
      });
    });

    this.setup();
  }

  async setup(): Promise<void> {
    const params = {
      size: 999999,
      page: 0,
      profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
    };

    const result = await this.serviceApiBase.get<any>({
      url: new Company()["nomeConsultaApiBaseGetAll"],
      params,
    });

    console.log(result);

    this.company = result["content"];

    this.companiesSelect = [
      { name: "Divisão", value: 1 },
      { name: "Matriz", value: 2 },
      { name: "Filial", value: 3 },
    ];
  }

  submit(): void {
    this.botaoConfirmar = true;
    var posicoes = "";

    let elements = [];
    const itens = this.task.subtasks.filter((y) => y.completed == true);

    itens.forEach((action) => {
      const { id_terminal } = this.markers.find((x) => x.placa == action.name);
      elements.push(id_terminal);
    });

    elements.forEach((id) => {});

    if (this.tabSelected === 0) {
      if (!this.latitudeOrigem || !this.longitudeOrigem) return;

      var data;

      if (this.empresaSelected.cnpj != null) {
        data = {
          action: "route_location",
          values: {
            nomeRota: this.routeName,
            latitudeOrigem: this.latitudeOrigem,
            longitudeOrigem: this.longitudeOrigem,
            latitudeDestino: this.latitudeDestino,
            longitudeDestino: this.longitudeDestino,
            distancia: this.distanceEndereco,
            velocidade: this.velocidadeEndereco,
            idEmpresa: this.empresaSelected.id,
            divisao: null,
            veiculos: elements,
          },
        };
      } else {
        data = {
          action: "route_location",
          values: {
            nomeRota: this.routeName,
            latitudeOrigem: this.latitudeOrigem,
            longitudeOrigem: this.longitudeOrigem,
            latitudeDestino: this.latitudeDestino,
            longitudeDestino: this.longitudeDestino,
            distancia: this.distanceEndereco,
            velocidade: this.velocidadeEndereco,
            idEmpresa: null,
            divisao: this.empresaSelected.id,
            veiculos: elements,
          },
        };
      }

      console.log(data);
      this.service.setCommand(data);
      this.dialogRef.close();
    }
    if (this.tabSelected === 1) {
      var data;

      if (this.empresaSelected.cnpj != null) {
        data = {
          action: "route_map",
          values: {
            nomeRota: this.routeName,
            distancia: this.distanceMapa,
            velocidade: this.velocidadeMapa,
            idEmpresa: this.empresaSelected.id,
            divisao: null,
            veiculos: elements,
          },
        };
      } else {
        data = {
          action: "route_map",
          values: {
            nomeRota: this.routeName,
            distancia: this.distanceMapa,
            velocidade: this.velocidadeMapa,
            idEmpresa: null,
            divisao: this.empresaSelected.id,
            veiculos: elements,
          },
        };
      }

      console.log(data);
      this.service.setCommand(data);
      this.dialogRef.close();
    }
  }

  listaDePlacas = new Array<any>();
  eletric_fence_payload = new Array();
  allComplete: boolean = false;

  public updateAllComplete() {
    this.allComplete =
      this.task.subtasks != null &&
      this.task.subtasks.every((t) => t.completed);
  }

  public someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return (
      this.task.subtasks.filter((t) => t.completed).length > 0 &&
      !this.allComplete
    );
  }

  public setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach((t) => (t.completed = completed));
  }

  public handleFilter(event: string) {
    let query = "";

    if (event) {
      query = event.toLocaleUpperCase();
    }

    this.task.subtasks = this.listaDePlacas.filter(({ name }) =>
      name.match(new RegExp("(" + query + ")"))
    );
  }

  private buildPosicaoAtualTerminalList(source: Array<any>): Array<any> {
    const items = source.map((e) => e.posicaoAtualTerminalList);

    const itemsChildren = source
      .map((value) => value.children.map((e) => e.posicaoAtualTerminalList))
      .flat();

    const itemsChildrenInChildren = source
      .map((value) => value.children.map((e) => e.children))
      .flat();

    itemsChildrenInChildren.forEach((e) => {
      if (e.length > 0) {
        e.forEach((element) => {
          itemsChildren.push(element.posicaoAtualTerminalList);
        });
      }
    });

    return lodash.flattenDeep([...items, ...itemsChildren]);
  }

  // public async loadMarkers(): Promise<void> {
  //   try {
  //     const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(); //localStorage.getItem('idPerfil');
  //     if (!perfil) {
  //       throw new Error("Nenhum perfil encontrado");
  //     }
  //     const url = `posicao-atual-terminal/perfil/${perfil}`;
  //     const source = await this.apiService.get<any[]>({
  //       url,
  //     });

  //     if (!source) {
  //       throw new Error("Nenhum result encontrado");
  //     }

  //     const result = this.buildPosicaoAtualTerminalList(source);

  //     result.forEach((e) => {
  //       this.task.subtasks.push({
  //         name: e.placa,
  //         completed: false,
  //         color: "primary",
  //       });
  //       this.listaDePlacas.push({
  //         name: e.placa,
  //         completed: false,
  //         color: "primary",
  //       });
  //     });
  //     this.markers = result;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  public filterCompany() {
    var idEmpresa = this.empresaFilterSelected;
    var empresas = [];

    if (idEmpresa == "Divisão") {
      this.empresaSelected = "Divisão";
      this.company.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Matriz") {
      this.empresaSelected = "Matriz";
      this.company.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Filial") {
      this.empresaSelected = "Filial";
      this.company.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    console.log(this.companiesFilter);
  }

  public async loadMarkersFilter(company: any): Promise<void> {
    try {
      this.spinner.show();
      var url: any;
      var source: any;
      console.log(this.empresaFilterSelected);
      if (this.empresaFilterSelected == "Divisão") {
        url = `posicao-atual-terminal/idDivisao/${company.id}`;
        source = await this.apiService.get<any[]>({
          url,
        });
      } else {
        url = `posicao-atual-terminal/idEmpresa/${company.id}`;
        source = await this.apiService.get<any[]>({
          url,
        });
      }

      console.log(source);

      this.task.subtasks = [];

      if (!source) {
        throw new Error("Nenhum result encontrado");
      }
      source.forEach((e) => {
        if (this.empresaFilterSelected == "Divisão") {
          this.task.subtasks.push({
            name: e.placa,
            completed: false,
            color: "primary",
            idEmpresa: e.id_divisao,
          });
          this.listaDePlacas.push({
            name: e.placa,
            completed: false,
            color: "primary",
            idEmpresa: e.id_divisao,
          });
        } else {
          this.task.subtasks.push({
            name: e.placa,
            completed: false,
            color: "primary",
            idEmpresa: e.id_empresa,
          });
          this.listaDePlacas.push({
            name: e.placa,
            completed: false,
            color: "primary",
            idEmpresa: e.id_empresa,
          });
        }
      });
      this.markers = source;

      this.spinner.hide();
    } catch (error) {
      console.error(error);
    }
  }
}
