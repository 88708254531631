import { ContentInterface } from "./../../contracts/Content.interface";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

import { Vehicle } from "../../models/Vehicle.model";
import { DownloadCsv } from "src/app/services/download_csv.service";
import storageX from "src/app/storageCore/storageX";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { getFormValidationErrors } from "src/app/helpers/validation.errors";
import { Company } from "src/app/models/Company.model";
import { VeiculoSensor } from "src/app/models/VeiculoSensor.model";
import { VwSensor } from "src/app/models/VwSensor.model";
import { EventEmitterSensorService } from "src/app/services/event-emitter-sensor.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-create-vehicle",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class EditVehicleComponent implements OnInit {
  public vehicleForm: FormGroup;
  public veiculoTipoSelected;
  public veiculoCombustivelTipoSelected;
  public categoriaVeiculoSelected;
  public empresaSelected;
  public empresaFilterSelected;
  public empresaUpdateSelect;
  public atualizarEmpresa = false;
  public veiculo;
  public veiculoId;
  public title = "Atualizar Veículo";
  public titleVar = "";

  public axisQuantity = null;
  public idVehicleCategory = null;

  public errorMessage;
  public fuelTypes: any = [];
  public vehicleTypes: any = [];
  public companies: any = [];
  public companiesUpdateSelect: any = [];
  public categoriaVeiculos: any = [];
  public companiesFilter: any = [];
  public veiculoSensores: any;
  public sensoresDisponiveis: any;
  public axisSelect: any = [];
  public tipoRodagemSelect: any = [];
  public cambioAutomaticoSelect: any = [];

  public cambioAutomatico: any = [
    { name: "SIM", value: true },
    { name: "NÃO", value: false },
  ];

  public companiesSelect: any = [
    { name: "Divisão", value: 1 },
    { name: "Matriz", value: 2 },
    { name: "Filial", value: 3 },
  ];

  public quantidadeEixo = [
    { name: "1 EIXO", value: 1 },
    { name: "2 EIXOS", value: 2 },
    { name: "3 EIXOS", value: 3 },
    { name: "4 EIXOS", value: 4 },
    { name: "5 EIXOS", value: 5 },
    { name: "6 EIXOS", value: 6 },
    { name: "7 EIXOS", value: 7 },
    { name: "8 EIXOS", value: 8 },
    { name: "9 EIXOS", value: 9 },
  ];

  public tipoRodagem = [
    { name: "SIMPLES", value: "S" },
    { name: "DUPLA", value: "D" },
  ];

  public errorDebug;

  public envioLote = false;
  public btnText = "Processar";
  public btnDisabled = false;
  public loader: false;

  uploadListener($event: any): void { }

  fncProcessar($event: any): void { }

  ngOnInit(): void {
    this.vehicleForm = this.formBuilder.group(
      {
        selecaoEmpresa: [""],
        idEmpresa: [""],
        empresa: [""],
        cdPlaca: ["", Validators.required],
        frota: [""],
        cdRenavam: [""],
        cdChassi: [""],
        cdAntt: [""],
        cdPbt: [""],
        nmMarca: [""],
        nmModelo: [""],
        nrQuantidadeEixo: [""],
        idVeiculoTipo: ["", Validators.required],
        idVeiculoCombustivelTipo: ["", Validators.required],
        idCategoriaVeiculo: [""],
        nrAnoFabricacao: [""],
        nrAnoModelo: [""],
        cdCor: [""],
        tipoRodagem: [""],
        nmCidade: [""],
        nmEstado: [""],
        nmPais: [""],
        nrCoeficienteConsumo: [""],
        nrOdometro: [""],
        nrCapacidadeTanque: [""],
        nrTelefoneProprietario: [""],
        nmNomeProprietario: [""],
        dsEmail: [""],
        cambioAutomatico: [false],
      },
      { updateOn: "blur" }
    );

    this.veiculoId = this.activatedRoute.snapshot.params["id"];
    this.eixos(this.veiculoId);
    this.sensores(this.veiculoId);
    this.loadVeiculo(this.veiculoId);
  }

  public async atualizarSensores(event: any) {
    await this.componentResetService.acionarReinicioComponentes();
  }

  public async searchPlate() {
    this.spinner.show();
    let placa = this.vehicleForm.get('cdPlaca')?.value;

    console.log(placa)
    const apiUrl = `https://placas.fipeapi.com.br/placas/${placa}?key=${environment.apiKeyFipe}`;
    this.http.get(apiUrl).subscribe(
      (response: any) => {
        console.log('Dados da API FIPE:', response);
        if (response?.data?.veiculo) {
          this.fillForm(response.data.veiculo);
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      (error) => {
        console.error('Erro ao buscar dados da placa:', error);
        this.spinner.hide();
      }
    );
  }

  fillForm(veiculo: any) {
    // Extrair "ano" e separar em fabricação e modelo
    const [anoFabricacao, anoModelo] = veiculo.ano.split('/');

    // Extrair "marca_modelo" e separar em marca e modelo
    const [marca, modelo] = veiculo.marca_modelo.split('/');

    // Preencher os campos do formulário
    this.vehicleForm.patchValue({
      nmEstado: veiculo.uf,  // Estado (UF)
      nrAnoFabricacao: anoFabricacao,  // Ano de Fabricação
      nrAnoModelo: anoModelo,  // Ano Modelo
      cdCor: veiculo.cor,  // Cor
      cdPbt: veiculo.pbt,  // PBT
      cdChassi: veiculo.chassi,  // Chassi
      nmCidade: veiculo.municipio,  // Cidade
      nmPais: veiculo.procedencia === 'Nacional' ? 'BRASIL' : 'IMPORTADO',  // País
      nmMarca: marca.trim(),  // Marca
      nmModelo: modelo.trim(),  // Modelo
    });
  }

  allowOnlyLettersAndNumbers(event: KeyboardEvent) {
    const charCode = event.key.charCodeAt(0);
    if (!/[A-Za-z0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  public async eixos(veiculoId: any) {
    const sensor = await this.serviceApiBase.get<any>({
      url: new VeiculoSensor()["nomeConsultaApiBase"] + veiculoId,
    });

    this.veiculoSensores = sensor["content"];
  }

  public async sensores(veiculoId: any) {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();

    const sensor = await this.serviceApiBase.get<any>({
      url:
        new VwSensor()["nomeConsultaApiBaseSensor"] +
        veiculoId +
        "/perfil/" +
        perfil,
    });

    this.sensoresDisponiveis = sensor;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  constructor(
    private serviceApiBase: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private doenload_cvs: DownloadCsv,
    private activatedRoute: ActivatedRoute,
    private componentResetService: EventEmitterSensorService,
    private http: HttpClient
  ) { }

  get veiculoSensorItems(): FormArray {
    return this.vehicleForm.get("veiculoSensorList") as FormArray;
  }

  public async loadVeiculo(id): Promise<void> {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
    try {
      this.spinner.show();
      const types = await this.serviceApiBase.get<ContentInterface[]>({
        url: "veiculo-tipo",
      });
      this.vehicleTypes = types["content"];

      const combustiveltipo = await this.serviceApiBase.get<ContentInterface[]>(
        {
          url: "veiculo-combustivel-tipo",
        }
      );
      this.fuelTypes = combustiveltipo["content"];

      const categoriaVeiculo = await this.serviceApiBase.get<
        ContentInterface[]
      >({
        url: "categoria-veiculo",
      });
      this.categoriaVeiculos = categoriaVeiculo["content"];

      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      const result = await this.serviceApiBase.get<any>({
        url: new Company()["nomeConsultaApiBaseGetAll"],
        params,
      });

      this.companies = result["content"];

      const response = await this.serviceApiBase.get<Vehicle>({
        url: new Vehicle()["nomeConsultaApiBase"] + "/" + this.veiculoId,
      });

      this.veiculo = response as Vehicle;

      this.axisQuantity = this.veiculo.nrQuantidadeEixo;
      this.idVehicleCategory = this.veiculo.idCategoriaVeiculo;

      if (this.axisQuantity != null) {
        this.vehicleForm.controls["nrQuantidadeEixo"].setValue(
          this.axisQuantity
        );
      }

      if (this.veiculo.tipoRodagem != null) {
        this.vehicleForm.controls["tipoRodagem"].setValue(
          this.veiculo.tipoRodagem
        );
      } else {
        this.veiculo.tipoRodagem = null;
      }

      if (this.veiculo.idEmpresa != null) {
        if (this.veiculo.idEmpresaMatriz == null) {
          this.empresaFilterSelected = "Matriz";
        } else {
          this.empresaFilterSelected = "Filial";
        }
      } else {
        this.empresaFilterSelected = "Divisão";
      }

      this.filterCompany();

      if (this.veiculo.idEmpresa != null) {
        this.vehicleForm.controls["idEmpresa"].setValue(this.veiculo.idEmpresa);
      } else {
        this.vehicleForm.controls["idEmpresa"].setValue(this.veiculo.idDivisao);
      }

      this.fillVehicleData();
      this.loader = false;
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  fillVehicleData(): void {
    if (this.veiculo.idVeiculoCombustivelTipo) {
      this.vehicleForm.controls["idVeiculoCombustivelTipo"].setValue(
        this.veiculo.idVeiculoCombustivelTipo
      );
    }
    if (this.veiculo.idVeiculoTipo) {
      this.vehicleForm.controls["idVeiculoTipo"].setValue(
        this.veiculo.idVeiculoTipo
      );
    }

    Object.getOwnPropertyNames(this.veiculo).forEach((element) => {
      if (this.vehicleForm.controls[element]) {
        this.vehicleForm.controls[element].setValue(this.veiculo[element]);
      }
    });

    this.errorDebug = getFormValidationErrors(this.vehicleForm);
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();

    try {
      this.coverterUpperCase(this.vehicleForm);
      const values = this.vehicleForm.value;
      const veiculoId = this.veiculoId;
      var post;

      if (this.empresaFilterSelected != "Divisão") {
        post = {
          ...values,
          veiculoTipo: { id: parseInt(values.veiculoTipo) },
          veiculoCombustivelTipo: {
            id: parseInt(values.veiculoCombustivelTipo),
          },
          empresa: {
            id: parseInt(values.idEmpresa),
          },
          divisao: null,
          idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
        };
      } else {
        post = {
          ...values,
          veiculoTipo: { id: parseInt(values.veiculoTipo) },
          veiculoCombustivelTipo: {
            id: parseInt(values.veiculoCombustivelTipo),
          },
          divisao: {
            id: parseInt(values.idEmpresa),
          },
          empresa: null,
          idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
        };
      }

      if (veiculoId) {
        post["id"] = parseInt(veiculoId);
      }

      await this.serviceApiBase.put<Vehicle>(
        this.veiculoId,
        new Vehicle()["nomeConsultaApiBase"],
        post
      );
      this.spinner.hide();
      this.router.navigate(["/consulta/VEHICLE_FUNCIONALIDADE"]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
    }
  }

  public downloadCsv() {
    this.spinner.show();

    this.doenload_cvs.download().subscribe(
      (r) => {
        this.spinner.hide();

        let file = new Blob([r], { type: "application/csv" });
        let fileURL = URL.createObjectURL(file);

        let a = document.createElement("a");
        a.href = fileURL;
        a.download = "exemplo_lote.csv";
        a.click();
        setTimeout(() => {
          URL.revokeObjectURL(fileURL);
        }, 800);
      },
      (error) => {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    );
  }

  public filterCompany() {
    var idEmpresa = this.empresaFilterSelected;
    this.vehicleForm.controls["idEmpresa"].setValue(null);
    var empresas = [];

    if (idEmpresa == "Divisão") {
      this.companies.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Matriz") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Filial") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    console.log(this.companiesFilter);
  }

  public coverterUpperCase(vehicleForm: FormGroup) {
    console.log(vehicleForm);

    vehicleForm
      .get("cdPlaca")
      .setValue(vehicleForm.get("cdPlaca").value.toUpperCase());
    vehicleForm
      .get("frota")
      .setValue(vehicleForm.get("frota").value.toUpperCase());
    vehicleForm
      .get("cdChassi")
      .setValue(vehicleForm.get("cdChassi").value.toUpperCase());
    vehicleForm
      .get("cdAntt")
      .setValue(vehicleForm.get("cdAntt").value.toUpperCase());
    vehicleForm
      .get("nmMarca")
      .setValue(vehicleForm.get("nmMarca").value.toUpperCase());
    vehicleForm
      .get("nmModelo")
      .setValue(vehicleForm.get("nmModelo").value.toUpperCase());
    vehicleForm
      .get("cdCor")
      .setValue(vehicleForm.get("cdCor").value.toUpperCase());
    vehicleForm
      .get("nmCidade")
      .setValue(vehicleForm.get("nmCidade").value.toUpperCase());
    vehicleForm
      .get("nmEstado")
      .setValue(vehicleForm.get("nmEstado").value.toUpperCase());
    vehicleForm
      .get("nmPais")
      .setValue(vehicleForm.get("nmPais").value.toUpperCase());
    vehicleForm
      .get("nmNomeProprietario")
      .setValue(vehicleForm.get("nmNomeProprietario").value.toUpperCase());
  }
}
