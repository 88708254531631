<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="moduloForm" (ngSubmit)="submitForm()">
                    <h2 class="mb-3">Novo Módulo</h2>
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome do Modulo</mat-label>
                                <input autofocus formControlName="nmModulo" required matInput placeholder="Nome do Modulo"
                                    maxlength="120" />
                            </mat-form-field>

                            <validation-errors [form]="moduloForm" controlLabel="Modulo" controlName="nmModulo">
                            </validation-errors>
                        </div>
                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Desc. Modulo</mat-label>
                                <input formControlName="dsModulo" required matInput placeholder="Descrição do Modulo"
                                    maxlength="254" />
                            </mat-form-field>

                            <validation-errors [form]="moduloForm" controlLabel="Modulo" controlName="dsModulo">
                            </validation-errors>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Cad. Path</mat-label>
                                <input formControlName="cdPath" matInput placeholder="Cadastro Path" maxlength="200" />
                            </mat-form-field>
                        </div>

                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Cad. Icone</mat-label>
                                <input formControlName="cdIcone" matInput placeholder="Cadastro Icone"
                                    maxlength="200" />
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Salvar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
