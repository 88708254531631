<div class="main-page cadastros">
  <div class="container-fluid">
    <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

    <div class="row mt-3 no-gutters p-4 row-form">
      <div class="col-12">
        <form [formGroup]="eletronicFenceForm" (ngSubmit)="submitForm()">
          <h2 class="mb-3">Matriz/Filial/Divisão</h2>
          <div class="form-row">
            <div class="form-group col-md-8">
              <mat-form-field>
                <mat-label *ngIf="this.empresaEdit == 'Matriz'">Matrizes</mat-label>
                <mat-label *ngIf="this.empresaEdit == 'Filial'">Filiais</mat-label>
                <mat-label *ngIf="this.empresaEdit == 'Divisão'">Divisões</mat-label>
                <mat-select formControlName="empresa" [(value)]="empresaSelected" disabled>
                  <mat-option *ngFor="let type of company" [value]="type.id">
                    {{ type.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <h2 class="mb-3">Dados Cadastrais </h2>
          <div class="form-row">
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Nome</mat-label>
                <input formControlName="nmNome" matInput placeholder="Nome" maxlength="80"
                  minlength="1" required />
              </mat-form-field>
              <validation-errors [form]="eletronicFenceForm" controlLabel="Nome" controlName="nmNome">
              </validation-errors>
            </div>
         
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Velocidade Alerta Sonoro</mat-label>
                <input type="number" formControlName="nrVelocidadeAlarmeVeiculo" matInput placeholder="Limite de Velocidade para o Veículo" minlength="1" required />
              </mat-form-field>
              <validation-errors [form]="eletronicFenceForm" controlLabel="Limite de Velocidade para o Veículo" controlName="nrVelocidadeAlarmeVeiculo">
              </validation-errors>
            </div>
         
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Velocidade Evento Plataforma</mat-label>
                <input type="number" formControlName="nrVelocidadeAlarmePainel" matInput placeholder="Limite de Velocidade para o Painel" minlength="1" required />
              </mat-form-field>
              <validation-errors [form]="eletronicFenceForm" controlLabel="Limite de Velocidade para o Painel" controlName="nrVelocidadeAlarmePainel">
              </validation-errors>
            </div>

          </div>          
          <div *ngIf="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>
          <div class="form-group mt-2 mb-0 text-center">
            <back-button></back-button>
            <button type="submit" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
              Salvar
            </button>
          </div>
        </form>

        <h2 class="mb-3">Selecionar veículos </h2>
          <div class="form-row">
            <br/>
            <section class="example-section">
              <div class="form-group">
                <input type="text" class="form-control" (keyup)="handleFilter($event.target.value)" placeholder="pesquisar..."
                  autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search />
              </div>  
            <div class="box-placas">
              <span class="example-list-section">
                <mat-checkbox class="example-margin"
                              [checked]="allComplete"
                              [indeterminate]="someComplete()"
                              (change)="setAll($event.checked)">
                  {{task.name}}
                </mat-checkbox>
              </span>
              <span class="example-list-section">
                <ul>
                  <li *ngFor="let subtask of task.subtasks">
                    <mat-checkbox [(ngModel)]="subtask.completed"
                                  [color]="subtask.color"
                                  (ngModelChange)="updateAllComplete()">
                      {{subtask.name}} 
                    </mat-checkbox>
                  </li>
                </ul>
              </span>
            </div>
            </section>
          </div> 
      </div>
    </div>
  </div>
</div>
