<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])"
                        data-title="Voltar"><i class="fas fa-arrow-left"></i></a>
                    Consulta de Relatorios mensais
                </h1>
            </div>
        </div>
        <div *ngIf="analyzeReportOperatorsPage?.page.totalElements === 0">
            <div class="row no-gutters mt-3">
                <p>Nenhum registro até o momento...</p>
            </div>
            <div class="row no-gutters mt-3">
                <a (click)="this.router.navigate(['/relatorio-analitico-mensal'])" mat-button color="primary"
                    class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                    Gerar um novo Relatorio
                </a>
            </div>
        </div>
        <div class="row no-gutters mt-3">
            <div class="mat-elevation table-content">
                <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field fxFlex="10%" class="ml-2">
                        <mat-label>Campo Filtro</mat-label>
                        <mat-select (selectionChange)="this.fieldFilter()" [(value)]="typeFieldFilterSelected"
                            [(ngModel)]="typeFieldFilterSelected">
                            <mat-option *ngFor="let type of typeFieldSelect" [value]="type.value">
                                {{ type.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="40%" class="ml-2">
                        <mat-label>Filtrar por nome</mat-label>
                        <input matInput autocomplete="off" (keyup)="this.applyFilter()" [(ngModel)]="filter"
                            style="text-transform: uppercase;" #input>
                    </mat-form-field>
                </div>
                <table mat-table [dataSource]="analyzesOperators" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="dateStart">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Inicio Viagem</th>
                        <td mat-cell *matCellDef="let element">{{ element.date_start}}</td>
                    </ng-container>
                    <ng-container matColumnDef="dateEnd">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Fim Viagem.</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.date_end }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="companyName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome Empresa</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.company_name ? element.company_name : element.division_name }}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="graficoFile">
                        <th mat-header-cell *matHeaderCellDef>Ref. Grafico</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.consumo_file && element.status_report === 'FINISHED'"
                                class="alerta mr-2" data-title="Arquivo PDF" matTooltip="Arquivo PDF">
                                <a (click)="exportReport(element.grafico_file)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-pdf.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="consumoFile">
                        <th mat-header-cell *matHeaderCellDef>Ref. Consumo</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.consumo_file && element.status_report === 'FINISHED'"
                                class="alerta mr-2" data-title="Arquivo PDF" matTooltip="Arquivo PDF">
                                <a (click)="exportReport(element.consumo_file)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-pdf.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="resumoFile">
                        <th mat-header-cell *matHeaderCellDef>Ref. Resumo</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.resumo_file && element.status_report === 'FINISHED'"
                                class="alerta mr-2" data-title="Arquivo PDF" matTooltip="Arquivo PDF">
                                <a (click)="exportReport(element.resumo_file)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-pdf.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="baseFile">
                        <th mat-header-cell *matHeaderCellDef>Ref. Base</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.base_file && element.status_report === 'FINISHED'" class="alerta mr-2"
                                data-title="Arquivo PDF" matTooltip="Arquivo PDF">
                                <a (click)="exportReport(element.base_file)" class="btn btn-link">
                                    <img height="20" src="assets/img/file-pdf.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="statusReport">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status Relatorio</th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="getStatusStyle(element.status_report)">
                            <span *ngIf="element.status_report === 'ERROR'; else successProcessing">
                                <span style="color: red;">ERRO</span>
                            </span>
                            <ng-template #successProcessing>
                                <span *ngIf="element.status_report === 'FINISHED'" style="color: green;">SUCESSO</span>
                                <span *ngIf="element.status_report === 'PROCESSING'" style="color: blue;">EM
                                    PROCESSAMENTO</span>
                            </ng-template>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="analyzeReportOperatorsPage.page.size"
                    (page)="onChangePage($event)" [length]="analyzeReportOperatorsPage.page.totalElements"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>