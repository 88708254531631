<div class="container-fluid">
  <mat-progress-bar mode="indeterminate" *ngIf="loader"></mat-progress-bar>
  <div class="row no-gutters mt-3">
    <div class="mat-elevation table-content">
<!--       <div fxLayout fxLayoutAlign="center center">
        <mat-form-field>
          <mat-label>Campo</mat-label>
          <select matNativeControl #typeFilter>
            <option value="nmNome">Nome</option>
          </select>
        </mat-form-field>
        <mat-form-field fxFlex="40%" class="ml-2">
          <input autofocus type="text" matInput placeholder="Filtro" #filter />
        </mat-form-field>
        <button type="button" mat-icon-button (click)="handleFilter()">
          <mat-icon>search</mat-icon>
        </button>
      </div> -->

      <div fxLayout fxLayoutAlign="center center">
        <mat-form-field fxFlex="40%" class="ml-2">
         <mat-label>Filtrar por placa</mat-label>
         <input matInput autocomplete="off"
         (keyup)="this.applyFilter($event)" style="text-transform: uppercase;" placeholder="Ex: PKISO" #input>
       </mat-form-field>

    </div>           


      <table mat-table [dataSource]="empresa" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="nmNome">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Matriz/Divisão/Filial
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.nmNome }}
          </td>
        </ng-container>

        <ng-container matColumnDef="vincular">
          <th mat-header-cell *matHeaderCellDef class="mat-cell-vincular">
            <div [hidden]="hideMasterToggle">
              <mat-slide-toggle (change)="onMasterToggleChange($event)" color="primary"
                [checked]="handleMasterToggle()">
              </mat-slide-toggle>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="mat-cell-vincular">
            <mat-slide-toggle (change)="onCheckboxChange($event, element)" [checked]="hasVinculo(element)"
              color="primary" [disabled]="isVinculosDisabled(element)">
            </mat-slide-toggle>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 20, 40, 100]" showFirstLastButtons></mat-paginator>
<!-- 
      <mat-paginator [pageSize]="pageSize" (page)="handlePageChanged($event)" [length]="totalElements"
        showFirstLastButtons></mat-paginator> -->
    </div>
  </div>
</div>
