import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Atividade } from "../../models/Atividade.model";
import { ApiService } from "../../services/api.service";
import { Validation } from "./validation";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-create-atividade",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CreateAtividadeComponent implements OnInit {
  public atividadeForm: FormGroup;
  public title = "Cadastrar Atividade";
  public errorMessage;
  private service: ApiService;
  private validation: Validation;
  public ativo: any = [];

  ngOnInit(): void {
    this.atividadeForm = this.formBuilder.group(this.validation.rules());
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.service = apiService;
    this.validation = new Validation();
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();
    try {
      this.coverterUpperCase(this.atividadeForm);
      const values = this.atividadeForm.value;
      const post = {
        ...values,
        idUsuarioCriacao: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()
          ?.id,
      };

      await this.service.post<Atividade>("atividade", post);
      this.spinner.hide();
      this.router.navigate(["/atividades"]);
    } catch (exception) {
      this.spinner.hide();
      this.errorMessage = exception.message;
      console.error(exception);
    }
  }

  public coverterUpperCase(atividadeForm: FormGroup) {
    console.log(atividadeForm);

    atividadeForm
      .get("nmNome")
      .setValue(atividadeForm.get("nmNome").value.toUpperCase());
    atividadeForm
      .get("dsObservacao")
      .setValue(atividadeForm.get("dsObservacao").value.toUpperCase());
  }
}
