import { Injectable } from '@angular/core';
import {HateoasBaseService} from './hateoas-base.service';
import {HttpUtil} from '../utils/http-util';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {PageEvent} from '@angular/material/paginator';
import {Observable} from 'rxjs';
import {TrackerModelPaginated} from "../models/tracker-model-paginated";

@Injectable({
  providedIn: 'root'
})
export class TrackerModelService extends HateoasBaseService {

  // tslint:disable-next-line:variable-name
  private _httpUtil: HttpUtil;
  private resourceName = 'tracker-models';

  constructor(http: HttpClient,
              httpUtil: HttpUtil) {
    super(http, httpUtil);
    this._httpUtil = httpUtil;
  }

  public getAll(): Observable<TrackerModelPaginated> {
    //TODO - Deixar essa paginação mais dinamica
    const pageEvent = new PageEvent();
    pageEvent.pageIndex = 0;
    pageEvent.pageSize = 50;

    const direction = 'ASC';
    const params = this._httpUtil.buildPaginator(pageEvent, direction);

    return this.executeActionOnResource(this.resourceName, 'get-all', null, null, params);

  }

  protected getApiBaseUrl(): string {
    return environment.apisConfig.seteraTrackingHardwareApi.apiBaseUrl;
  }

  protected getMicroServiceBaseUrl(): string {
    return environment.apisConfig.seteraTrackingHardwareApi.microServiceBaseUrl;
  }
}
