import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { FuncionalidadeTipo } from "../../models/FuncionalidadeTipo.model";
import { Validation } from "./validation";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-create-funcionalidade",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ViewFuncionalidadeTipoComponent implements OnInit {
  public funcionalidadeTipoForm: FormGroup;
  public funcionalidadeTipo: FuncionalidadeTipo;
  public funcionalidadeTipoId: number;
  public title = "Visualizar Tipo de Funcionalidade";
  public errorMessage;
  private apiBaseService: ApiBaseService;
  private validation: Validation;

  ngOnInit(): void {
    this.funcionalidadeTipoForm = this.formBuilder.group(
      this.validation.rules()
    );
    this.funcionalidadeTipoId = this.activatedRoute.snapshot.params["id"];
    this.loadFuncionalidadeTipo(this.funcionalidadeTipoId);
  }

  public async loadFuncionalidadeTipo(id): Promise<void> {
    try {
      const response = await this.apiBaseService.get<FuncionalidadeTipo>({
        url: new FuncionalidadeTipo()["nomeConsultaApiBase"] + "/" + id,
      });
      this.funcionalidadeTipo = response as FuncionalidadeTipo;
      this.fillUserData();
    } catch (error) {
      console.error(error);
    }
  }
  fillUserData(): void {
    const fields = ["nmFuncionalidadeTipo", "dsFuncionalidadeTipo"];
    fields.forEach((element) => {
      this.funcionalidadeTipoForm.controls[element].setValue(
        this.funcionalidadeTipo[element]
      );
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    apiBaseService: ApiBaseService
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }
}
