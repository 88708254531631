import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import storageX from "src/app/storageCore/storageX";
import {
  STORAGE_KEY_ACCESS_TOKEN_WSO2,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";

@Injectable({
  providedIn: "root",
})
export class CockpitGraphicService {
  constructor(private http: HttpClient) {}

  public event(value: any): Observable<any> {
    return this.http.get(`${environment.othersUrl.events}/v2/treatment-panel`, {
      params: {
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
        dhEvento: value,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get(),
      },
    });
  }
}
