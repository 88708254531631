export function validarCnpj(rawCnpj) {
  if (!rawCnpj) {
    return false;
  }

  const cnpj = rawCnpj.replace(/[^\d]+/g, '');

  // Valida a quantidade de caracteres
  if (cnpj.length !== 14) {
    return false;
  }

  // Elimina inválidos com todos os caracteres iguais
  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  // Cáculo de validação
  const tamanho = cnpj.length - 2;
  const numeros = cnpj.substring(tamanho);
  const d1 = parseInt(numeros.charAt(0), 10);
  const d2 = parseInt(numeros.charAt(1), 10);
  const calc = t => {
    const n = cnpj.substring(0, t);
    let pos = t - 7;
    let soma = 0;
    let r = 0;

    for (let i = t; i >= 1; i--) {
      soma += n.charAt(t - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    r = 11 - soma % 11;
    return r > 9 ? 0 : r;
  };

  return calc(tamanho) === d1 && calc(tamanho + 1) === d2;
}

/**
 * Has any change at some attribute?
 */
export function hasAnyDifferenceInAttributeValue(objectA, objectB): boolean {
  return objectA && objectB && Object.keys(objectB).some(k => objectA[k] !== objectB[k]);
}

export function validarCpf(rawCpf) {
  if (!rawCpf) {
    return false;
  }

  const cpf = rawCpf.replace(/\D/g, '');

  if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  let result = true;

  [9, 10].forEach(function(j) {
    let soma = 0;
    let r = 0;
    cpf.split(/(?=)/).splice(0, j).forEach(function(e, i) {
      soma += parseInt(e) * ((j + 2) - (i + 1));
    });
    r = soma % 11;
    r = (r < 2) ? 0 : 11 - r;
    if (r != cpf.substring(j, j + 1)) {
      result = false;
    }
  });
  return result;
}
