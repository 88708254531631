/* eslint-disable @typescript-eslint/no-explicit-any */
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import ApiService from 'src/app/services/api.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import axios, {AxiosInstance} from 'axios';
import {environment} from 'src/environments/environment';
import {CompanyMenuItem} from "../../../models/CompanyMenuItem.model";
import {CurrentPositionService} from "../../../services/current-position.service";

@Component({
  selector: 'app-find-vehicles',
  templateUrl: './find-vehicles.component.html',
  styleUrls: ['./find-vehicles.component.scss'],
})
export class FindVehiclesComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private apiService: ApiService;
  private currentPositionService: CurrentPositionService;
  private dialogRef: MatDialogRef<any>;
  private http: AxiosInstance;

  public retornoEntidades: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);

  public displayedColumns: string[] = [
    'select',
    'placa',
    'nmMotorista',
    'nomeEmpresa',
    'dataOnline',
    'localizacao',
  ];

  /** Novas variaveis **/

  constructor(apiService: ApiService, dialogRef: MatDialogRef<any>,
              currentPositionService: CurrentPositionService) {
    this.apiService = apiService;
    this.dialogRef = dialogRef;
    this.currentPositionService = currentPositionService;
  }


  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.retornoEntidades.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.retornoEntidades.data.forEach((row) => this.selection.select(row));
  }

  close(): void {
    this.dialogRef.close();
  }

  /** Novos Métodos Refactoring **/

  ngOnInit(): void {
    this.loadVehicles(this.dialogRef._containerInstance._config.data.selectedCompany);
  }

  public loadVehicles(company: CompanyMenuItem): void {
    if (company.company_type === 'M' || company.company_type === 'F') {
      this.currentPositionService.getCurrentPositionTerminalsByCompany(company.id).subscribe(positions => {
            positions.forEach(async (pos) => {
              pos.localizacao = await this.buscarEndereco(
                  pos.latitude,
                  pos.longitude
              );
            });
            this.retornoEntidades = new MatTableDataSource<any>(positions);

            setTimeout(() => {
              this.retornoEntidades.sort = this.sort;
              this.retornoEntidades.paginator = this.paginator;
            }, 300);
          }, error => {
            throw new Error('Erro ao abter a lista de veiculos');
          }
      );
    }

    if (company.company_type === 'D') {
      this.currentPositionService.getCurrentPositionTerminalsByDivision(company.id).subscribe(positions => {
            positions.forEach(async (pos) => {
              pos.localizacao = await this.buscarEndereco(
                  pos.latitude,
                  pos.longitude
              );
            });
            this.retornoEntidades = new MatTableDataSource<any>(positions);
            setTimeout(() => {
              this.retornoEntidades.sort = this.sort;
              this.retornoEntidades.paginator = this.paginator;
            }, 300);
          }, error => {
            throw new Error('Erro ao abter a lista de veiculos');
          }
      );
    }


  }

  /** Fim dos novos métodos do refactoring **/


  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.retornoEntidades.filter = filterValue.trim().toLowerCase();

    if (this.retornoEntidades.paginator) {
      this.retornoEntidades.paginator.firstPage();
    }
  }

  public async buscarEndereco(latitude, longitude): Promise<string> {
    try {
      this.http = axios.create({
        baseURL: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
        timeout: 100000,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const response = await this.http.request({
        method: 'get',
        url:
          'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
          longitude +
          ',' +
          latitude +
          `.json?access_token=${environment.apiMapBox}`,
      });

      return response.data.features[0].place_name;
    } catch (error) {
      return 'Endereço não localizado';
    }
  }

  localizar(): void {
    this.dialogRef.close(this.selection);
  }
}
