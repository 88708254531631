import { STORAGE_KEY_CD_USUARIO } from './../../storageCore/constStorageKeys';
import { ToolsService } from './../../services/migrate/tools.service';
import { Component, OnInit } from '@angular/core';
import storageX from 'src/app/storageCore/storageX';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-redirect-load-user',
  templateUrl: './redirect-load-user.component.html',
  styleUrls: ['./redirect-load-user.component.scss']
})
export class RedirectLoadUserComponent implements OnInit {

  constructor(private tools: ToolsService) { }

  ngOnInit() {
    this.tools.builder()
              .api
              .get(`${environment.apiUrl}/usuario/find`, {cdUsuario: storageX.whereKeyIs(STORAGE_KEY_CD_USUARIO).get()})
              .subscribe((r: any) => {
                    console.log(r);
              });
  }

}
