/* eslint-disable @typescript-eslint/camelcase */
import { Component, ViewChild, AfterViewInit, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { EletronicFence } from "../../models/EletronicFence.model";
import { ApiService } from "../../services/api.service";
import { EletronicFenceInterface } from "../../contracts/EletronicFence.interface";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CoreService } from "src/app/services/migrate/api.core.service";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-eletronic-fences",
  templateUrl: "./eletronic-fences.component.html",
  styleUrls: ["./eletronic-fences.component.scss"],
})
export class EletronicFencesComponent implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public filter;
  public typeFilter;

  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[] = [
    "nmNome",
    "cercaEletronicaLocalProcessamento.nmNome",
    "cercaEletronicaTipo.nmNome",
    "nrVelocidadeAlarmeVeiculo",
    "nrVelocidadeAlarmePainel",
    "sendConfig",
    "update",
    "delete",
    "view",
  ];

  public filtros = [{ campo: "nmNome", valor: "Nome Cerca Eletrônica" }];

  private service: ApiService;
  private coreService: CoreService;

  public eletronicFences: MatTableDataSource<EletronicFenceInterface>;

  constructor(
    apiCoreService: CoreService,
    private spinner: NgxSpinnerService,
    apiService: ApiService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.service = apiService;
    this.coreService = apiCoreService;

    this.eletronicFences = new MatTableDataSource<EletronicFenceInterface>([]);
  }

  ngOnInit(): void {
    this.loadEletronicFences();
  }

  //public handleFilter = (value: string): void => {
  //this.routes.filter = value.trim().toLocaleLowerCase();
  //};

  public handleSort = (sort): void => {
    this.loadEletronicFences(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadEletronicFences(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadEletronicFences();
  };
  public handleDelete = (id: string): void => {
    this.deleteEletronicFence(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/eletronicFences/${id}`]);
  };

  ngAfterViewInit(): void {
    this.eletronicFences.sort = this.sort;
    this.eletronicFences.paginator = this.paginator;
  }

  public async deleteEletronicFence(id): Promise<void> {
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        await this.service.delete<EletronicFence>(id, "cerca-eletronica");

        this.spinner.hide();

        this.loadEletronicFences();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public async loadEletronicFences(pageIndex = 0, sort?): Promise<void> {
    try {
      this.spinner.show();

      const params = {
        size: this.pageSize,
        page: pageIndex,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
        ativo: true,
      };

      const result = await this.service.get<any>({
        url: "cerca-eletronica",
        params,
      });

      this.eletronicFences.data = result.content;
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.eletronicFences.filter = filterValue.trim().toLowerCase();

    if (this.eletronicFences.paginator) {
      this.eletronicFences.paginator.firstPage();
    }
  }

  public handSendConfig = (id: string): void => {
    this.sendConfig(id);
  };

  public async sendConfig(id): Promise<void> {
    if (
      confirm(
        "Tem certeza que deseja enviar dados de Cerca Eletrônica ao terminal?"
      )
    ) {
      try {
        this.spinner.show();

        const post = {
          id,
        };

        await this.service.put<EletronicFence>(
          id,
          "cerca-eletronica/send-config",
          post
        );

        this.spinner.hide();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }
}
