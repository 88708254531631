<div class="main-page cadastros">
  <div class="container-fluid">

    <div class="box-title">
      <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

    <!--   <button class="button-box-title" type="button" mat-button>
        <a style="padding: 11px;">
          Inclusão em Lote
        </a>
      </button> -->
    </div>

    <div class="row mt-3 no-gutters p-4 row-form">
      <div class="col-12">
        <form [formGroup]="terminalForm" (ngSubmit)="submitForm()">
          <h2 class="mb-3" *ngIf="title == 'Cadastrar Terminal' || title == 'Duplicar Terminal'">Novo Terminal</h2>
          <h2 class="mb-3" *ngIf="title == 'Atualizar Terminal'">{{ titleVar }} - Atual</h2>
          <div class="form-row">
            <div class="form-group col-md-8" *ngIf="title == 'Atualizar Terminal'">
              <mat-form-field>
                <mat-label>{{ titleVar }}</mat-label>
                <mat-select formControlName="idEmpresa" [(value)]="empresaSelected" [disabled]=true
                  (selectionChange)="this.changeCompany()">
                  <mat-option *ngFor="let type of this.companys" [value]="type.id">
                    {{ type.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group col-md-4" *ngIf="title == 'Atualizar Terminal'">
              <mat-slide-toggle name="atualizarEmpresa" formControlName="atualizarEmpresa" color="primary">
                Alterar Empresa</mat-slide-toggle>
            </div>
          </div>
          <div class="form-row" *ngIf="terminalForm.get('atualizarEmpresa').value == true">
            <div class="form-group col-md-4">
              <mat-form-field>
                <mat-label>Tipo de Cadastro</mat-label>
                <mat-select formControlName="selecaoEmpresa" [(value)]="empresaFilterSelected" [(ngModel)]="empresaFilterSelected" 
                (selectionChange)="this.filterCompany()">
                  <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <validation-errors [form]="terminalForm" controlLabel="Tipo de Cadastro" controlName="selecaoEmpresa">
              </validation-errors>
            </div>
            <div class="form-group col-md-8" *ngIf="title == 'Atualizar Terminal' && terminalForm.get('atualizarEmpresa').value == true">
              <mat-form-field>
                <mat-label *ngIf="terminalForm.get('selecaoEmpresa').value == null">Selecione um tipo de cadastro</mat-label>
                <mat-label *ngIf="terminalForm.get('selecaoEmpresa').value == 'Matriz'">Matrizes</mat-label>
                <mat-label *ngIf="terminalForm.get('selecaoEmpresa').value == 'Filial'">Filiais</mat-label>
                <mat-label *ngIf="terminalForm.get('selecaoEmpresa').value == 'Divisão'">Divisões</mat-label>
                <mat-select formControlName="empresaEdit" [(value)]="empresaSelectedEdit" 
                  (selectionChange)="this.changeCompanyEdit()">
                  <mat-option *ngFor="let empresaEdit of this.companiesFilter" [value]="empresaEdit">
                    {{ empresaEdit.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row" *ngIf="title == 'Cadastrar Terminal' || title == 'Duplicar Terminal'">
            <div class="form-group col-md-4">
              <mat-form-field>
                <mat-label>Tipo de Cadastro</mat-label>
                <mat-select formControlName="selecaoEmpresa" [(value)]="empresaFilterSelected" [(ngModel)]="empresaFilterSelected" 
                (selectionChange)="this.filterCompany()">
                  <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <validation-errors [form]="terminalForm" controlLabel="Tipo de Cadastro" controlName="selecaoEmpresa">
              </validation-errors>
            </div>
            <div class="form-group col-md-8">
              <mat-form-field>
                <mat-label *ngIf="terminalForm.get('selecaoEmpresa').value == null">Selecione um tipo de cadastro</mat-label>
                <mat-label *ngIf="terminalForm.get('selecaoEmpresa').value == 'Matriz'">Matrizes</mat-label>
                <mat-label *ngIf="terminalForm.get('selecaoEmpresa').value == 'Filial'">Filiais</mat-label>
                <mat-label *ngIf="terminalForm.get('selecaoEmpresa').value == 'Divisão'">Divisões</mat-label>
                <mat-select formControlName="idEmpresa" [(value)]="empresaSelected" required
                  (selectionChange)="this.changeCompany()">
                  <mat-option *ngFor="let type of this.companiesFilter" [value]="type">
                    {{ type.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            
            
          </div>
          <div class="form-row">
            <div class="form-group col-md-8">
              <mat-form-field>
                <mat-label>Veículo</mat-label>
                <mat-select formControlName="idVeiculo" [(value)]="veiculoSelected" required>
                <mat-option *ngFor="let type of this.vehiclesForm" [value]="type.id">
                    {{ type.cdPlaca }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group col-md-4">
                <mat-form-field>
                  <mat-label>Modelo</mat-label>
                  <mat-select formControlName="idTerminalProtocolo" [(value)]="trackerModelSelected" required>
                  <mat-option *ngFor="let trackerModel of this.trackerModelsPaginated?.content" [value]="trackerModel">
                     {{ trackerModel?.modelName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-3">
              <mat-slide-toggle name="possuiTeclado" formControlName="possuiTeclado" color="primary">
                Possui Teclado</mat-slide-toggle>
            </div>
            <div class="form-group col-lg-3">
              <mat-slide-toggle name="possuiTpms" formControlName="possuiTpms" color="primary">Possui
                TPMS</mat-slide-toggle>
            </div>
            <div class="form-group col-lg-3">
              <mat-slide-toggle name="possuiSensorFadiga" formControlName="possuiSensorFadiga" color="primary">
                Possui Sensor de Fadiga</mat-slide-toggle>
            </div>
            <div class="form-group col-lg-3">
              <mat-slide-toggle name="calcularHorimetroParado" formControlName="calcularHorimetroParado" color="primary">
                Calcular Horímetro Parado</mat-slide-toggle>
            </div>
          </div>

          <h2 class="mb-3">Dados Cadastrais </h2>
          <div class="form-row">
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Número Terminal</mat-label>
                <input formControlName="numeroTerminal" matInput placeholder="Número Terminal" maxlength="15"
                  minlength="15" (keypress)="numberOnly($event)" required />
              </mat-form-field>

              <validation-errors [form]="terminalForm" controlLabel="Número Terminal" controlName="numeroTerminal">
              </validation-errors>
            </div>
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>SIM</mat-label>
                <input formControlName="sim" matInput placeholder="SIM" maxlength="30" (keypress)="numberOnly($event)"
                  required />
              </mat-form-field>

              <validation-errors [form]="terminalForm" controlLabel="sim" controlName="sim">
              </validation-errors>
            </div>
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>IMEI</mat-label>
                <input formControlName="imei" matInput placeholder="Cad. IMEI" (keypress)="numberOnly($event)"
                  minlength="15" maxlength="15" required />
              </mat-form-field>
                            <validation-errors [form]="terminalForm" controlLabel="Cad. IMEI" controlName="imei">
                            </validation-errors>
							-
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-1">
              <mat-form-field appearance="outline">
                <mat-label>Conectado</mat-label>
                <input formControlName="conectado" type="date" matInput placeholder="Conectado" required />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-1">
              <mat-form-field appearance="outline">
                <mat-label>Instalado</mat-label>
                <input formControlName="instalado" type="date" matInput placeholder="Instalado" required />
              </mat-form-field>
            </div>
            <div class="form-group col-md-1">
              <mat-form-field>
                <mat-label>Voltagem</mat-label>
                <select matNativeControl formControlName="voltagem" required>
                  <option value="12">12V</option>
                  <option value="24">24V</option>
                </select>
              </mat-form-field>
            </div>
            <div class="form-group col-md-1">
              <mat-form-field>
                <mat-label>Velocidade</mat-label>
                <select matNativeControl formControlName="enumTipoVelocidade" required>
                  <option value="0">CAN</option>
                  <option value="1">GPS</option>
                </select>
              </mat-form-field>
            </div>
            <div class="form-group col-md-1">
              <mat-form-field appearance="outline">
                <mat-label>Fator Veloc. CAN</mat-label>
                <input formControlName="fatorConversaoVelocidadeCan" matInput placeholder="Fator Veloc. CAN"
                  (keypress)="numberOnly($event)" required value="10" ngModel="10" />
              </mat-form-field>
            </div>
            <div class="form-group col-md-2">
              <mat-form-field>
                <mat-label>Limite Excesso Veloc</mat-label>
                <input formControlName="limiteExcessoVeloc" matInput placeholder="Limite Excesso Veloc" (keypress)="numberOnly($event)" required />
              </mat-form-field>
            </div>
            <div class="form-group col-md-2">
              <mat-form-field>
                <mat-label>Limite Excesso Veloc Chuva</mat-label>
                <input formControlName="limiteExcessoVelocChuva" matInput placeholder="Limite Excesso Veloc Chuva" (keypress)="numberOnly($event)" required />
              </mat-form-field>
            </div>
            <div class="form-group col-md-2">
              <mat-form-field>
                <mat-label>Limite Excesso Rpm</mat-label>
                <input formControlName="limiteExcessoRpm" matInput placeholder="Limite Excesso Rpm" (keypress)="numberOnly($event)" required />
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome IN 1</mat-label>
                <input formControlName="nomeIn1" matInput placeholder="Nome IN 1" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome IN 2</mat-label>
                <input formControlName="nomeIn2" matInput placeholder="Nome IN 2" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome IN 3</mat-label>
                <input formControlName="nomeIn3" matInput placeholder="Nome IN 3" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome IN 4</mat-label>
                <input formControlName="nomeIn4" matInput placeholder="Nome IN 4" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome IN 5</mat-label>
                <input formControlName="nomeIn5" matInput placeholder="Nome IN 5" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome IN 6</mat-label>
                <input formControlName="nomeIn6" matInput placeholder="Nome IN 6" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome IN 7</mat-label>
                <input formControlName="nomeIn7" matInput placeholder="Nome IN 7" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome IN 8</mat-label>
                <input formControlName="nomeIn8" matInput placeholder="Nome IN 8" maxlength="20" />
              </mat-form-field>
            </div>
              <div class="form-group col-lg-2">
                  <mat-form-field appearance="outline">
                      <mat-label>Nome IN 9</mat-label>
                      <input formControlName="nomeIn9" matInput placeholder="Nome IN 9" maxlength="20" />
                  </mat-form-field>
              </div>
              <div class="form-group col-lg-2">
                  <mat-form-field appearance="outline">
                      <mat-label>Nome IN 10</mat-label>
                      <input formControlName="nomeIn10" matInput placeholder="Nome IN 10" maxlength="20" />
                  </mat-form-field>
              </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome OUT 1</mat-label>
                <input formControlName="nomeOut1" matInput placeholder="Nome OUT 1" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome OUT 2</mat-label>
                <input formControlName="nomeOut2" matInput placeholder="Nome OUT 2" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome OUT 3</mat-label>
                <input formControlName="nomeOut3" matInput placeholder="Nome OUT 3" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome OUT 4</mat-label>
                <input formControlName="nomeOut4" matInput placeholder="Nome OUT 4" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome OUT 5</mat-label>
                <input formControlName="nomeOut5" matInput placeholder="Nome OUT 5" maxlength="20" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome OUT 6</mat-label>
                <input formControlName="nomeOut6" matInput placeholder="Nome OUT 6" maxlength="20" />
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Minutos Evento Motor Ocioso</mat-label>
                <input formControlName="minutoAlarmePontoMorto" matInput placeholder="Minutos Evento Motor Ocioso"
                  (keypress)="numberOnly($event)" required value="10" ngModel="10" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Minutos Evento Ignição Ociosa</mat-label>
                <input formControlName="minutoAlarmeParada" matInput placeholder="Minutos Evento Ignição Ociosa"
                  (keypress)="numberOnly($event)" required value="10" ngModel="10" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Fuso Horário</mat-label>
                <select matNativeControl formControlName="fusoHorarioEnum" value="0" ngModel="0" required>
                  <option value="0" selected>GMT-3 Brasília</option>
                  <option value="1">GMT-4 Cuiabá</option>
                  <option value="2">GMT-5 Rio Branco</option>
                  <option value="3">GMT Londres</option>
                  <option value="4">GMT+1 Paris</option>
                  <option value="5">GMT+2 Atenas</option>
                  <option value="6">GMT+3 Moscou</option>
                </select>
              </mat-form-field>
            </div>
          </div>

      <!--     <div *ngIf="envioLote === true">
            <h2 class="my-3">Inclusão em lote</h2>
            <div class="form-row">
              <p>Campos com * são obrigatórios<br>
            </div>
            <div class="form-row">
              <label for="UploadCSV">Arquivo CSV com dados</label>
              <input type="file" #csvReader name="Upload CSV" class="form-control-file" id="txtFileUpload"
                (change)="uploadListener($event)" accept=".csv" />
                <br>
                <br>
              <button style="width: 105px;" type="button" name="Processar" class="form-control" id="btnProcessar"
                (click)="fncProcessar($event)" style="width: 130px;">{{btnText}}</button>
                <button type="button" mat-button>
                  <a style="padding: 11px;" (click)="this.downloadCsv()">
                    CSV Modelo
                  </a>
                </button>
            </div>
          </div> -->
          <h2 class="my-3">Observação</h2>
          <div class="form-row">
            <div class="form-group col-lg-12">
              <mat-form-field appearance="outline">
                <mat-label>Observação</mat-label>
                <input formControlName="observacao" matInput placeholder="Observação" maxlength="200" value="  "
                  ngModel="  " required />
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>

          <div class="form-group mt-2 mb-0 text-center">
            <back-button></back-button>
            <button type="submit" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>



<!-- <p-dialog header="Procurar Motorista" [(visible)]="this.openDialog" [modal]="true" 
[breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}" [baseZIndex]="10000"
[draggable]="false" [resizable]="false">

</p-dialog> -->
