import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { ToolsService } from "src/app/services/migrate/tools.service";
import { OperatorAnalyzeListModel } from "src/app/models/operator-analize-list-model";
import { OperatorAnalyzeModel } from "src/app/models/operator-analize-model";

import Swal from "sweetalert2";
import { MatDialog } from "@angular/material/dialog";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { ViewReportOperatorComponent } from "../view-report-operator/view-report-operator.component";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
@Component({
  selector: "app-treat-analysis",
  templateUrl: "./treat-analysis.component.html",
  styleUrls: ["./treat-analysis.component.scss"],
})
export class TreatAnalysisComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;

  public controlCompany = new FormControl();

  public companiesFilter: any = [];

  public reportOperationalForm: FormGroup;

  public companyFilterSelected;
  public typesReportSelected;
  public url = "";
  public analyzeReportUuid = "";
  public minioUuid = "";
  public analyzeEndDate = "";

  public treatmentOperatorsSelected;
  public treatmentOperators = [];

  previewUrl: string | ArrayBuffer | null = null;
  errorMessage: string;

  constructor(
    private dialog: MatDialog,
    private tools: ToolsService,
    private fb: FormBuilder,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    analyzeTripService: AnalyzeTripService
  ) {
    this.analyzeTripService = analyzeTripService;
  }

  dataSets: any[] = []; // Armazena os conjuntos de dados
  reportOperationalForms: FormGroup[] = [];
  previewUrls: (string | ArrayBuffer | null)[] = [];

  ngOnInit(): void {
    this.reportOperationalForm = this.fb.group({
      operatorHandlingList: this.fb.array([]),
    });

    this.analyzeReportUuid = this.activatedRoute.snapshot.params.uuid;
    this.minioUuid = this.activatedRoute.snapshot.params.uuidMinio;
    this.analyzeEndDate =
      this.activatedRoute.snapshot.params.dateAnalyze.replace(/NaN/g, "") +
      ":00";

    console.log(this.analyzeEndDate);

    this.loadDataElements(this.analyzeReportUuid);

    this.addSensorItem();
  }

  get operatorHandlingItems(): FormArray {
    return this.reportOperationalForm.get("operatorHandlingList") as FormArray;
  }

  addSensorItem(): void {
    this.operatorHandlingItems.push(
      this.fb.group({
        treatment: ["", Validators.required],
        operatorComment: ["", Validators.required],
        state: ["", Validators.required],
        city: ["", Validators.required],
        image: [null, [this.validateImage]],
        extension: [null],
      })
    );
  }

  onImageChange(event: any, index: number) {
    const file = (event.target as HTMLInputElement).files?.[0];
    const imageControl = this.operatorHandlingItems.at(index).get("image");
    const extensionControl = this.operatorHandlingItems
      .at(index)
      .get("extension");

    if (file) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
        const base64String = (e.target as any).result.toString();
        imageControl.setValue(btoa(base64String));
        extensionControl.setValue(file.name.split(".").pop().toLowerCase());
      };
    } else {
      imageControl.setValue(null);
      extensionControl.setValue(null);
    }
  }

  validateImage(control: FormControl) {
    const file: File = control.value;

    if (file instanceof File) {
      const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
      const extension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.indexOf(extension) === -1) {
        return { invalidImageFormat: true };
      }

      if (file.size > 5242880) {
        // 5MB
        return { invalidImageSize: true };
      }
    }

    return null;
  }

  deleteSensorItem(index): void {
    this.operatorHandlingItems.removeAt(index);
  }

  openReport(): void {
    const dialogRef = this.dialog.open(ViewReportOperatorComponent, {
      width: "100%",
      height: "100%",
      data: {
        analyzeReportUuid: this.minioUuid,
      },
      panelClass: "dialog-content-scrollable",
    });

    // Pode adicionar lógica aqui para manipular o resultado do modal, se necessário
    dialogRef.afterClosed().subscribe((result) => {
      console.log("Modal fechado", result);
      // Se desejar, você pode acessar o resultado retornado pelo modal aqui
    });
  }

  removeForm(index: number): void {
    this.reportOperationalForms.splice(index, 1);
    this.previewUrls.splice(index, 1);
  }

  public submitForm(): void {
    this.spinner.show();
    const values = this.reportOperationalForm.value;
    var operatorAnalyzeListModel: OperatorAnalyzeListModel[] = [];

    var userId = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;

    values.operatorHandlingList.forEach((element) => {
      var operatorAnalyzeModel: OperatorAnalyzeModel = {
        operator_dealings_uuid: element.treatment, // Certifique-se de inicializar essa propriedade ou ajustar conforme necessário
        analyze_report_uuid: this.analyzeReportUuid,
        description_analyze: element.operatorComment,
        file: element.image,
        extesion: element.extension,
        state: element.state,
        city: element.city,
        user_id: userId,
        last_date_analyze: this.analyzeEndDate,
      };
      operatorAnalyzeListModel.push(operatorAnalyzeModel);
    });

    console.log(operatorAnalyzeListModel);

    this.analyzeTripService
      .createAnalyzeOperator(operatorAnalyzeListModel)
      .subscribe((t) => {
        //Inicia o temporizador de 3 segundos
        setTimeout(() => {
          // Esconde o spinner
          this.spinner.hide();

          // Navega para a rota desejada
          this.router.navigate(["/analise-operacional"]);
        }, 3000); // 3000 milissegundos = 3 segundos
      });
  }

  public async loadDataElements(analyzeReportUuid: any) {
    try {
      this.tools.builder().spinner.show();

      this.analyzeTripService.getAllOperatorDealings().subscribe((t) => {
        this.treatmentOperators.push(t);
      });

      console.log(this.treatmentOperators);

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  public async handleReport(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Analise iniciada!",
      html: `<h6>Deseja ir para tela de consulta de análises?</h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.router.navigate(["/analise-operacional"]);
      }
    });
  }
}
