import { Component, OnInit } from '@angular/core';
import {TransporterCore} from './transporter.core';
import {FormBuilder} from '@angular/forms';
import {ApiBaseService} from '../../services/migrate/api.base.service';
import {VehiclesMenuService} from '../../services/vehicles-menu.service';
import {TransporterService} from '../../services/transporter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Transporter} from '../../models/transporter.model';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../models/User.model';
import storageX from '../../storageCore/storageX';
import {STORAGE_KEY_CURRENT_USER} from '../../storageCore/constStorageKeys';
import ApiService from '../../services/api.service';
import {PerfilUsuario} from '../../models/PerfilUsuario.model';

@Component({
  selector: 'app-edit-transporter',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class EditTransporterComponent extends TransporterCore implements OnInit {
  public title = 'Atualizar Transportador/Agregado';
  public formMode: any;
  public titleVar = '';
  public registrationTypes: Array<any>;
  public companies: Array<any>;
  public companiesFiltered: Array<any>;
  public transporterService: TransporterService;
  public hide = true;
  public transporterUuid = '';
  public transporter: Transporter;
  public userOfTransporter: User;
  public empresaSelected: any;
  public userProfiles;


  constructor(formBuilder: FormBuilder,
              apiBaseService: ApiBaseService,
              service: ApiService,
              vehicleService: VehiclesMenuService,
              transporterService: TransporterService,
              spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute,
              router: Router) {
    super(formBuilder, apiBaseService, vehicleService, service, transporterService, spinner, router);
    this.transporterService = transporterService;
    this.service = service;
    this.transporterFormGroup = super.loadFormDefinition();
    this.userFormGroup = super.loadUserFormDefinition();
  }

  ngOnInit(): void {
    console.log('Iniciando o componente de edição do transportador');
    this.transporterUuid = this.activatedRoute.snapshot.params.uuid;
    this.formMode = 'edit';
    this.userFormGroup.removeControl('password');
    this.registrationTypes = this.getRegistrationType();
    this.loadTransporter(this.transporterUuid, false);
  }
  submitForm(): void {
    this.updateTransporter();
  }

  private updateTransporter(): void {
    this.spinner.show();
    const user = {
      ativo: true,
      cdUsuario: this.userFormGroup.get('login').value,
      nmUsuario: this.userFormGroup.get('userName').value,
      dsEmail: this.userFormGroup.get('email').value,
      nrMatricula: this.userFormGroup.get('matriculation').value,
      idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id
    };

    this.apiBaseService.put<User>(
        this.transporter.userId,
        new User().nomeConsultaApiBase,
        user
    ).then(userUpdated => {
      console.log('Usuário atualizado com sucesso' + userUpdated);
      if (this.userProfiles.some(up => (up === this.userFormGroup.get('profile').value))){
        console.log('Usuario já tem o perfil selecionado');
      } else {
        console.log('persistindo o novo perfil');
        console.log('Criando o vinculo do usuario com o perfil');
        const perfilUsuario = {
          ativo: true,
          perfil: { id: this.userFormGroup.get('profile').value.id },
          usuario: { id: this.transporter.userId },
          flLeitura: true,
          flInsercao: true,
          flAtualizacao: true,
          flDelecao: true,
        };

        this.apiBaseService.post<PerfilUsuario>(
            'usuario-perfil',
            perfilUsuario
        ).then(createdProfile => {
          console.log('Profile atualizado com sucesso' + createdProfile);
          console.log('Atualizando o transporter...');

          const transporter = new Transporter(this.transporterFormGroup.value);
          transporter.userId = this.transporter.userId;

          const registrationType = this.transporterFormGroup.controls.registrationType.value;
          if (registrationType === 'Matriz' || registrationType === 'Filial') {
            transporter.companyId = this.transporterFormGroup.controls.company.value.id;
          }
          if (registrationType === 'Divisão') {
            transporter.divisionId = this.transporterFormGroup.controls.company.value.id;
          }

          transporter.vehicles = this.userFormGroup.get('vehicles').value.map(vehicle => vehicle.id_veiculo);
          this.transporterService.update(transporter, this.transporter.uuid).subscribe((persistedTransporter) => {
            console.log(persistedTransporter);
            this.spinner.hide();
            this.router.navigate(['/transporters']);
          }, (error) => {
            console.log(error);
            this.spinner.hide();
          });
        });

      }
      this.spinner.hide();
    });
  }


}
