import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Funcionalidade } from "../../models/Funcionalidade.model";
import { Validation } from "./validation";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { FuncionalidadeTipo } from "src/app/models/FuncionalidadeTipo.model";
import { Modulo } from "src/app/models/Modulo.model";

@Component({
  selector: "app-create-funcionalidade",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CreateFuncionalidadeComponent implements OnInit {
  public funcionalidadeForm: FormGroup;
  public title = "Cadastrar Funcionalidade";
  public errorMessage;
  private validation: Validation;
  public funcionalidadeTipoSelected;
  public moduloSelected;
  private apiBaseService: ApiBaseService;

  public modulo: any = [];
  public types: any = [];

  ngOnInit(): void {
    this.funcionalidadeForm = this.formBuilder.group(this.validation.rules());
    this.setup();
  }
  async setup(): Promise<void> {
    const params = {
      size: 100,
      page: 0,
    };
    try {
      this.spinner.show();

      const types = await this.apiBaseService.get<any>({
        url: new FuncionalidadeTipo()["nomeConsultaApiBase"],
        params,
      });
      this.types = types["content"];

      const modulo = await this.apiBaseService.get<any>({
        url: new Modulo()["nomeConsultaApiBase"],
        params,
      });
      this.modulo = modulo["content"];
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();
    try {
      this.coverterUpperCase(this.funcionalidadeForm);
      const values = this.funcionalidadeForm.value;
      const post = {
        ...values,
        funcionalidadeTipo: { id: parseInt(values.funcionalidadeTipo) },
        modulo: { id: parseInt(values.modulo) },
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      await this.apiBaseService.post<Funcionalidade>(
        new Funcionalidade()["nomeConsultaApiBase"],
        post
      );
      this.spinner.hide();
      this.router.navigate(["/funcionalidade"]);
    } catch (error) {
      this.errorMessage = error.message;
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public coverterUpperCase(funcionalidadeForm: FormGroup) {
    console.log(funcionalidadeForm);

    funcionalidadeForm
      .get("nmFuncionalidade")
      .setValue(funcionalidadeForm.get("nmFuncionalidade").value.toUpperCase());
    funcionalidadeForm
      .get("dsFuncionalidade")
      .setValue(funcionalidadeForm.get("dsFuncionalidade").value.toUpperCase());
    funcionalidadeForm
      .get("cdFuncionalidade")
      .setValue(funcionalidadeForm.get("cdFuncionalidade").value.toUpperCase());
    funcionalidadeForm
      .get("sgFuncionalidade")
      .setValue(funcionalidadeForm.get("sgFuncionalidade").value.toUpperCase());
  }
}
