import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-axle-four-simple-view",
  templateUrl: "./axle-four-simple-view.component.html",
  styleUrls: ["./axle-four-simple-view.component.scss"],
})
export class AxleFourSimpleViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorFourLeftSelect: any = [];
  public sensorFourRightSelect: any = [];

  public sensorFourRightCombo: any = [];
  public sensorFourLeftCombo: any = [];

  public axisFourSimpleLeftForm: FormGroup;
  public axisFourSimpleRightForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisFourSimpleLeftForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisFourSimpleRightForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisFourSimpleLeftForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 4 ESQ"
      );
      this.axisFourSimpleRightForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 4 DIR"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo4Direito;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 42) {
              eixo4Direito = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 42) {
              eixo4Direito = filter;
            }
          }
        });

        var eixo4Esquerdo;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 41) {
              eixo4Esquerdo = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 41) {
              eixo4Esquerdo = filter;
            }
          }
        });

        // EIXO 4 DIREITO
        if (eixo4Direito != null) {
          this.axisFourSimpleRightForm.controls["id"].setValue(eixo4Direito.id);

          if (eixo4Direito.sensor != null) {
            this.sensorFourRightCombo.push(eixo4Direito.sensor);
            this.sensorFourRightSelect = eixo4Direito.sensor.id;
          }

          if (eixo4Direito.pneu != null) {
            this.axisFourSimpleRightForm.controls["pneu"].setValue(
              eixo4Direito.pneu.numeroFogo
            );
          }

          this.axisFourSimpleRightForm.controls["nrPressaoMinima"].setValue(
            eixo4Direito.nrPressaoMinima
          );

          this.axisFourSimpleRightForm.controls["nrPressaoMaxima"].setValue(
            eixo4Direito.nrPressaoMaxima
          );

          this.axisFourSimpleRightForm.controls["nrTemperaturaMaxima"].setValue(
            eixo4Direito.nrTemperaturaMaxima
          );
        }

        // EIXO 4 ESQUERDO
        if (eixo4Esquerdo != null) {
          this.axisFourSimpleLeftForm.controls["id"].setValue(eixo4Esquerdo.id);

          if (eixo4Esquerdo.sensor != null) {
            this.sensorFourLeftCombo.push(eixo4Esquerdo.sensor);
            this.sensorFourLeftSelect = eixo4Esquerdo.sensor.id;
          }

          if (eixo4Esquerdo.pneu != null) {
            this.axisFourSimpleLeftForm.controls["pneu"].setValue(
              eixo4Esquerdo.pneu.numeroFogo
            );
          }

          this.axisFourSimpleLeftForm.controls["nrPressaoMinima"].setValue(
            eixo4Esquerdo.nrPressaoMinima
          );

          this.axisFourSimpleLeftForm.controls["nrPressaoMaxima"].setValue(
            eixo4Esquerdo.nrPressaoMaxima
          );

          this.axisFourSimpleLeftForm.controls["nrTemperaturaMaxima"].setValue(
            eixo4Esquerdo.nrTemperaturaMaxima
          );
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
