import { ApiService } from "../../../services/api.service";
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable consistent-this */
import { Router } from "@angular/router";
import { TerminalComponent } from "../terminal/terminal.component";
import { SearchComponent } from "../search/search.component";
import { Component, Input, OnInit } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";

import { PathComponent } from "../path/path.component";
import { RouteComponent } from "../route/route.component";
import { StreetviewComponent } from "../streetview/streetview.component";

import { MapToolboxService } from "../../../services/map.toolbox.service";
import FileSaver from "file-saver";
import { MarkerService } from "src/app/services/marker.service";
import GOOGLE_LOCATE_LBS$ from "../../main/events$";
import { NgxSpinnerService } from "ngx-spinner";
import REMOVE_MARKER$ from "../../main/remove_marker$";
import { BehaviorSubject, interval, Subscription } from "rxjs";
import { ToolboxControl } from "../toolbox/toolbox_control";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { CurrentPosition } from "../../../models/CurrentPosition";
import { TerminalMarker } from "../../main/terminal-marker";
import { Console } from "console";

@Component({
  selector: "app-marker",
  templateUrl: "./marker.component.html",
  styleUrls: ["./marker.component.scss"],
})
export class MarkerComponent implements OnInit {
  permissions: [];

  @Input() $this;
  @Input() clickable = true;
  private mapService: MapToolboxService;
  public dsObservacao = "";
  public clicked: boolean;
  public markerInterval = new Subscription();
  public more_info = false;
  public lbsClicked;
  addressDisplay = null;
  loading = false;

  public previousInfoWidow;
  public linkMap;

  /** Novas variaveis refactoring **/
  @Input() showMarkerLabel: boolean;
  @Input() terminal: CurrentPosition;
  cloneMarker: boolean;
  currentPositionSubject: BehaviorSubject<CurrentPosition>;

  /** fim das novas variaveis **/

  constructor(
    public toolboxControl: ToolboxControl,
    private service: ApiService,
    private dialog: MatDialog,
    mapService: MapToolboxService,
    private router: Router,
    public terminalMarker: TerminalMarker,
    private markerService: MarkerService,
    private spinner: NgxSpinnerService,
    public permissionService: PermissionsService
  ) {
    this.mapService = mapService;
    this.addressDisplay = null;
    this.loading = false;
  }

  ngOnInit(): void {
    this.permissions = this.permissionService.getPermissions();
    this.currentPositionSubject = this.terminal.terminalSubject;
    this.cloneMarker = this.terminal.clone;
  }

  private getId(): any {
    return this.terminalMarker.id;
  }

  public afterClose(): void {
    this.closeIntervalpopUp();
    this.toolboxControl.toggleSideNav();
  }

  public beforeOpenModal(): void {
    if (this.currentPositionSubject.getValue().clone) {
      this.terminalMarker.clone = true;
      return;
    }

    console.log(
      "Iniciando listener do terminal. marker.componet > beforeOpenModal terminal Id = " +
      this.terminalMarker.id_terminal
    );
    this.terminalMarker.load = false;

    if (!this.terminalMarker.historic) {
      this.getPosicaoAtualTerminalId(this.currentPositionSubject.value.id);
      this.startsetIntervalMarkers();
    }

    this.toolboxControl.toggleSideNav();

    if (this.removeMarker()) {
      return;
    }
  }

  removeMarker(): boolean {
    if (this.terminalMarker.tag == "lbs") {
      REMOVE_MARKER$.emit(this.getId());
      return true;
    }
  }

  setObservacao(): void {
    this.dsObservacao = this.terminalMarker.dsObservacao;
  }

  closeIntervalpopUp(): void {
    this.markerInterval.unsubscribe();
  }

  /*
   * Inicia o listener de dados para o popup do terminal
   * TODO: Verificar possibilidade de migrar para o novo endpoint e otimizar a quantidade de dados trafegados*
   *  */
  private startsetIntervalMarkers(): void {
    this.markerInterval = interval(11000).subscribe(
      () => {
        console.log(
          `Executando listener do popup para a posição ${this.currentPositionSubject.value.id} do terminal ${this.currentPositionSubject.value.id_terminal}`
        );
        this.getPosicaoAtualTerminalId(this.currentPositionSubject.value.id);
      },
      (error) => console.log("error on interval", error)
    );
  }

  private getPosicaoAtualTerminalId(id): void {
    this.markerService.getPosicaoAtualTerminalId(id).subscribe(
      (r) => {
        this.terminalMarker.build(this.currentPositionSubject.value);
        this.terminalMarker.atividade = r.atividade?.nmNome;
        this.terminalMarker.nrEnumTipoVelocidade =
          r.terminal.nrEnumTipoVelocidade;
        this.terminalMarker.velocidade = r.velocidade;
        this.terminalMarker.velocidadeObd = r.velocidadeObd;
        this.terminalMarker.odometro = r.odometro;
        this.terminalMarker.odometroVeiculo = r.odometroVeiculo;
        this.terminalMarker.combustivelConsumido = r.combustivelConsumido;
        this.terminalMarker.tempoHorimetroMotorTexto =
          r.tempoHorimetroMotorTexto;
        this.terminalMarker.horimetroMotor = r.horimetroMotor;
        this.terminalMarker.nivelBateria = r.nivelBateria;
        this.terminalMarker.voltagemBateriaVeiculo = r.voltagemBateriaVeiculo;
        this.terminalMarker.categoriaVeiculo = r.categoriaVeiculo;
        this.terminalMarker.terminal_imei = r.terminal.cdImei;
        this.terminalMarker.terminal_simCard = r.simCard;
        this.terminalMarker.terminalAlarme1 = r.terminalAlarme1;
        this.terminalMarker.terminalAlarme2 = r.terminalAlarme2;
        this.terminalMarker.terminalAlarme3 = r.terminalAlarme3;
        this.terminalMarker.terminalAlarme4 = r.terminalAlarme4;
        this.terminalMarker.terminalAlarme5 = r.terminalAlarme5;
        this.terminalMarker.versaoFirmware = r.versaoFirmware;
        this.terminalMarker.enumStatusGps = r.enumStatusGps;
        this.terminalMarker.lacCelulaGsm = r.lacCelulaGsm;
        this.terminalMarker.latitudeLongitudeGsm = r.latitudeLongitudeGsm;
        this.terminalMarker.mccMncCelula = r.mccMncCelula;
        this.terminalMarker.mncCelula = r.mncCelula;
        this.terminalMarker.idCelulaGsm = r.idCelulaGsm;
        this.terminalMarker.eventos = r.eventos;
        this.terminalMarker.dsObservacao = r.dsObservacao;
        this.terminalMarker.dataAtualizacao = r.dataAtualizacao;
        this.terminalMarker.nivelCombustivel = r.nivelCombustivel;

        this.terminalMarker.markerTelemetria = r;

        this.setObservacao();

        this.terminalMarker.sendMarker({
          data: { marker: this.terminalMarker },
        });
        this.clicked = false;
        this.terminalMarker.load = true;
        console.log("terminalMarker Carregado ->" + this.terminalMarker.placa);
        // this.changeDetectorRef.detectChanges();
      },
      () => {
        this.clicked = false;
        this.terminalMarker.load = true;
        //this.spinner.hide();
      }
    );
  }

  public posicaoLbs(marker, mccV, mncV, lacV, idV, infoWindow): void {
    this.lbsClicked = true;
    this.previousInfoWidow = infoWindow;

    GOOGLE_LOCATE_LBS$.emit({
      id: marker.id,
      placa: marker.placa,
      nomeEmpresa: marker.nomeEmpresa,
      dataOnline: marker.dataOnline,
      nivelBateria: marker.nivelBateria,
      mccV,
      mncV,
      lacV,
      idV,
    });

    infoWindow._closeInfoWindow();
  }

  public removeMarkerLbs(): void {
    // const i = this.markerMap.markersMap.indexOf(this.terminalMarker.id);
    // this.markerMap.markersMap.splice(i, 1);
    // this.markerMap.center();
  }

  async searchAddress(): Promise<void> {
    const self = this;
    this.loading = true;
    const convert = this.toDegreesMinutesAndSeconds;
    try {
      const { latitude, longitude } = this.terminalMarker;
      let linkMap;
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { location: new google.maps.LatLng(latitude, longitude) },
        function (results, status) {
          self.loading = false;
          if (status == google.maps.GeocoderStatus.OK) {
            const [address] = results;
            if (address && address.formatted_address) {
              self.addressDisplay = address.formatted_address;
              console.log(results);
              self.linkMap = `${"https://www.google.com/maps/place/"}${convert([
                { cord: latitude, v: "S" },
                { cord: longitude, v: "W" },
              ])}/@${latitude}/${longitude},674m/data=!3m1!1e3!4m5!3m4!1s0x0:0x0!8m2!3d${latitude}!4d${longitude}`;
              console.log(self.linkMap);
            }
          } else {
            self.loading = false;
            self.addressDisplay = "Endereço não localizado";
          }
        }
      );
    } catch (error) {
      console.log("searchAddress()", error);
    }
  }

  async searchAddressHistoric(
    latitudeHistorico: number,
    longitudeHistorico: number
  ): Promise<void> {
    const self = this;
    this.loading = true;
    const convert = this.toDegreesMinutesAndSeconds;
    try {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: new google.maps.LatLng(
            latitudeHistorico,
            longitudeHistorico
          ),
        },
        function (results, status) {
          self.loading = false;
          if (status == google.maps.GeocoderStatus.OK) {
            const [address] = results;
            if (address && address.formatted_address) {
              self.addressDisplay = address.formatted_address;
              console.log(results);
              self.linkMap = `${"https://www.google.com/maps/place/"}${convert([
                { cord: latitudeHistorico, v: "S" },
                { cord: longitudeHistorico, v: "W" },
              ])}/@${latitudeHistorico}/${longitudeHistorico},674m/data=!3m1!1e3!4m5!3m4!1s0x0:0x0!8m2!3d${latitudeHistorico}!4d${longitudeHistorico}`;
              console.log(self.linkMap);
            }
          } else {
            self.loading = false;
            self.addressDisplay = "Endereço não localizado";
          }
        }
      );
    } catch (error) {
      console.log("searchAddressHistoric()", error);
    }
  }

  openTelemetriaDialog(): void {
    this.toolboxControl.popupTelemetria = true;
    this.terminalMarker.openTelemetria(this.terminalMarker.markerTelemetria);
  }

  openStreetViewDialog(): void {
    this.dialog.open(StreetviewComponent, {
      data: { marker: this.terminalMarker },
      width: "650px",
      height: "350px",
      disableClose: false,
    });
  }

  openRouteDialog(): void {
    this.dialog.open(RouteComponent, {
      data: { marker: this.terminalMarker },
      width: "650px",
      height: "650px",
    });
  }

  openPathDialog(infoWindow): void {
    this.mapService.setToolboxCommand(
      false ? "button_path" : "button_path_reset"
    );

    if (!this.more_info) {
      this.getPosicaoAtualTerminalId(this.getId());
    }

    this.terminalMarker.id = 0;
    this.dialog.open(PathComponent, {
      data: { marker: this.terminalMarker },
      width: "650px",
      minHeight: "36vw",
    });
  }

  openAddElectronicFenceDialog(): void {
    const data = {
      action: "electronic_fence",
      values: this.terminalMarker,
    };
    this.mapService.setCommand(data);
    this.terminalMarker.id = 0;
  }

  traking(infoWindow): void {
    this.closeIntervalpopUp();
    //this.terminalMarker.id = 0;

    const data = {
      action: "traking",
      values: this.terminalMarker,
    };

    this.mapService.setCommand(data);
  }

  trakingAndPath(): void {
    if (this.previousInfoWidow) {
      this.previousInfoWidow.close();
    }
    const data = {
      action: "traking_and_path",
      values: this.terminalMarker,
    };

    this.mapService.setCommand(data);
  }

  openPathToDialog(): void {
    if (this.previousInfoWidow) {
      this.previousInfoWidow.close();
    }

    this.dialog.open(SearchComponent, {
      data: { marker: this.terminalMarker, action: "path_to" },
      width: "650px",
    });
  }

  openTerminalDialog(): void {
    if (this.previousInfoWidow) {
      this.previousInfoWidow.close();
    }
    const options = {
      width: "800px",
      height: "600px",
    };

    const markers = [this.terminalMarker];
    const modalRef = this.dialog.open(TerminalComponent, options);
    (modalRef.componentInstance as TerminalComponent).data = markers;
    (modalRef.componentInstance as TerminalComponent).selecteds = markers;
  }

  convertSecondsInHours(second: number): String {
    if (!second || second == 0) {
      return "00:00:00";
    }

    let totalSeconds = second;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // If you want strings with leading zeroes:
    const resMinutes = String(minutes).padStart(2, "0");
    const resHours = String(hours).padStart(2, "0");
    const resSeconds = String(seconds).padStart(2, "0");

    return resHours + ":" + resMinutes + ":" + resSeconds.substring(0, 2);
  }

  downloadKml(): void {
    const data =
      '<?xml version="1.0" encoding="utf-8"?>' +
      '   <kml xmlns="http://www.opengis.net/kml/2.2">' +
      "        <Placemark>" +
      "         <name>" +
      this.terminalMarker.placa +
      "</name>" +
      "         <description>" +
      /* this.marker.terminal.dsObservacao */ "" +
      "</description>" +
      "           <Point>" +
      "             <coordinates>" +
      this.terminalMarker?.longitude +
      ", " +
      this.terminalMarker?.latitude +
      "</coordinates>" +
      "           </Point>" +
      "        </Placemark>" +
      "    </kml>";

    // const blob = new Blob([data], { type: 'application/octet-stream' });
    // const url = window.URL.createObjectURL(blob);
    // window.open(url);
    const blob = new Blob([data], {
      type: "text/plain;charset=utf-8",
    });
    FileSaver.saveAs(blob, this.terminalMarker.placa + ".kml");
  }

  openCercaEletronica() {
    this.router.navigateByUrl("/electric-fence");
  }

  async saveObservacao() {
    this.spinner.show();

    try {
      const res = await this.service.patch<any>(
        this.terminalMarker.id_terminal,
        "terminal",
        {
          dsObservacao: this.dsObservacao,
        }
      );

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
    }
  }

  private toDegreesMinutesAndSeconds(coordinates) {
    let v = "";

    coordinates.forEach((element) => {
      const absolute = Math.abs(element.cord);
      const degrees = Math.floor(absolute);
      const minutesNotTruncated = (absolute - degrees) * 60;
      const minutes = Math.floor(minutesNotTruncated);
      const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
      v = v + degrees + "º" + minutes + "'" + seconds + '"' + element.v + " ";
    });
    return v;
  }

  public moreInfo(): void {
    this.more_info = !this.more_info;
  }
}
