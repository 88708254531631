<div class="main-page cadastros">
    <div class="container-fluid">

        <div class="box-title">
            <app-title>{{ title ? title : 'Inserir novo' }} </app-title>
        </div>
        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="transporterFormGroup" (ngSubmit)="submitForm()">
                    <h2 class="mb-3" *ngIf="formMode=='create' || formMode=='clone'">
                        Novo Transportador/Agregado</h2>
                    <div class="form-row">
                        <div class="form-group col-md-8">
                            <mat-form-field>
                                <mat-label>Tipo de Cadastro</mat-label>
                                <mat-select formControlName="registrationType"
                                            (selectionChange)="this.filterCompany($event)">
                                    <mat-option *ngFor="let type of registrationTypes" [value]="type.name">
                                        {{ type.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <validation-errors [form]="transporterFormGroup" controlLabel="Tipo de Cadastro"
                                               controlName="registrationType">
                            </validation-errors>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-8">
                            <mat-form-field>
                                <mat-label *ngIf="transporterFormGroup.get('registrationType').value == null">Selecione
                                    um tipo de cadastro
                                </mat-label>
                                <mat-label *ngIf="transporterFormGroup.get('registrationType').value == 'Matriz'">
                                    Matrizes
                                </mat-label>
                                <mat-label *ngIf="transporterFormGroup.get('registrationType').value == 'Filial'">
                                    Filiais
                                </mat-label>
                                <mat-label *ngIf="transporterFormGroup.get('registrationType').value == 'Divisão'">
                                    Divisões
                                </mat-label>
                                <mat-select formControlName="company" (selectionChange)="this.onChangeCompany($event)"
                                            required>
                                    <mat-option *ngFor="let company of companiesFiltered" [value]="company">
                                        {{ company.nome }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <h2 class="mb-3">Dados cadastrais</h2>
                    <div class="form-row">
                        <div class="form-group col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input formControlName="name" matInput placeholder="Nome" required/>
                            </mat-form-field>

                            <validation-errors [form]="transporterFormGroup" controlLabel="Nome" controlName="name">
                            </validation-errors>

                        </div>
                        <div class="form-group col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label>CPF</mat-label>
                                <input formControlName="cpf" matInput placeholder="CPF" (keyup)="numberOnly($event)"
                                       maxlength="11" required/>
                            </mat-form-field>
                            <validation-errors [form]="transporterFormGroup" controlLabel="cpf" controlName="cpf">
                            </validation-errors>
                        </div>
                        <div class="form-group col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label>RG</mat-label>
                                <input formControlName="registration" matInput placeholder="RG" required/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Nascimento</mat-label>
                                <input type="date" formControlName="birthDate" matInput
                                       placeholder="Data de Nascimento"/>
                            </mat-form-field>
                        </div>
                    </div>
                    <h2 class="my-3">Contato</h2>
                    <div class="form-row">
                        <div class="form-group col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Telefone</mat-label>
                                <input formControlName="phone" required matInput placeholder="Telefone" maxlength="40"/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label>E-mail</mat-label>
                                <input formControlName="email" required matInput placeholder="E-mail" maxlength="80"/>
                            </mat-form-field>
                        </div>
                    </div>
                    <h2 class="my-3">Endereço</h2>
                    <div class="form-row">
                        <div class="form-group col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label>CEP</mat-label>
                                <input formControlName="postalCode" matInput placeholder="CEP" maxlength="10"/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-1">
                            <a (click)="searchCep()" mat-icon-button class="btn-cep-search">
                                <mat-icon>search</mat-icon>
                            </a>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-8">
                            <mat-form-field appearance="outline">
                                <mat-label>Logradouro</mat-label>
                                <input formControlName="street" matInput placeholder="Logradouro" maxlength="80"/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-1">
                            <mat-form-field appearance="outline">
                                <mat-label>Número</mat-label>
                                <input formControlName="number" matInput placeholder="Número" maxlength="20"/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Complemento</mat-label>
                                <input formControlName="complement" matInput placeholder="Complemento" maxlength="40"/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Referência</mat-label>
                                <input formControlName="reference" matInput placeholder="Referencia" maxlength="500"/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Bairro</mat-label>
                                <input formControlName="district" matInput placeholder="Bairro" maxlength="100"/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Cidade</mat-label>
                                <input formControlName="city" matInput placeholder="Cidade" maxlength="100"/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Estado</mat-label>
                                <input formControlName="state" matInput placeholder="Estado" maxlength="100"/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-3">
                            <mat-form-field appearance="outline">
                                <mat-label>País</mat-label>
                                <input formControlName="country" matInput placeholder="País" maxlength="100"/>
                            </mat-form-field>
                        </div>
                    </div>
                    <h2 class="mb-3">Dados de acesso</h2>
                    <div [formGroup]="userFormGroup">
                        <div class="form-row">
                            <div class="form-group col-lg-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Login*</mat-label>
                                    <input autocomplete="off" autofocus pattern="[0-9a-zA-Z]*" formControlName="login"
                                           matInput placeholder="Login"
                                           maxlength="100"/>
                                </mat-form-field>

                                <validation-errors [form]="userFormGroup" controlLabel="Login" controlName="login">
                                </validation-errors>
                            </div>
                            <div class="form-group col-lg-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Nome do usuário*</mat-label>
                                    <input formControlName="userName" matInput placeholder="Nome do usuário"
                                           maxlength="100"
                                    />
                                </mat-form-field>
                                <validation-errors [form]="userFormGroup" controlLabel="Nome do usuário"
                                                   controlName="userName">
                                </validation-errors>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-lg-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>E-mail*</mat-label>
                                    <input formControlName="email" matInput placeholder="E-email" maxlength="100"/>
                                </mat-form-field>
                                <validation-errors [form]="userFormGroup" controlLabel="E-mail" controlName="email">
                                </validation-errors>
                            </div>

                            <div class="form-group col-lg-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>Matricula</mat-label>
                                    <input formControlName="matriculation" matInput maxlength="4" placeholder="Matricula"
                                           pattern="[0-9]*" required/>
                                </mat-form-field>
                                <span class="alert-danger" *ngIf="!userFormGroup.get('matriculation').valid">
                      Matricula é um campo numérico e obrigatório!
                    </span>
                            </div>

                            <div class="form-group col-lg-3" *ngIf="formMode === 'create' || formMode === 'clone'">
                                <mat-form-field appearance="outline">
                                    <mat-label>Senha*</mat-label>
                                    <input autocomplete="off" [type]="hide ? 'password' : 'text'"
                                           formControlName="password" matInput placeholder="Senha" maxlength="100"/>
                                    <mat-icon matSuffix
                                              (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </mat-form-field>
                                <validation-errors [form]="userFormGroup" controlLabel="Senha" controlName="password">
                                </validation-errors>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-8">
                                <mat-form-field>
                                    <mat-label>Selecione um perfil</mat-label>
                                    <mat-select formControlName="profile" required (selectionChange)="this.onChangeProfile($event)">
                                        <mat-option *ngFor="let profile of transporterProfilesOfCompany"
                                                    [value]="profile">
                                            {{ profile.nmPerfil }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-8">
                                <mat-form-field>
                                    <mat-label>Selecione os veículos deste transportador</mat-label>
                                    <mat-select formControlName="vehicles" required multiple>
                                        <mat-option *ngFor="let vehicle of vehiclesOfProfile"
                                                    [value]="vehicle">
                                            {{ vehicle.placa }} - {{vehicle.frota}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center" *ngIf="formMode !== 'view'">
                        <back-button></back-button>
                        <button type="submit" [disabled]="!transporterFormGroup.valid || !userFormGroup.valid"
                                class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Salvar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
