import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-axle-five-dual-view",
  templateUrl: "./axle-five-dual-view.component.html",
  styleUrls: ["./axle-five-dual-view.component.scss"],
})
export class AxleFiveDualViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorFiveIntLeftSelect: any = [];
  public sensorFiveIntRightSelect: any = [];

  public sensorFiveExtLeftSelect: any = [];
  public sensorFiveExtRightSelect: any = [];

  public sensorFiveIntRightCombo: any = [];
  public sensorFiveIntLeftCombo: any = [];

  public sensorFiveExtRightCombo: any = [];
  public sensorFiveExtLeftCombo: any = [];

  public axisFiveDoubleLeftIntForm: FormGroup;
  public axisFiveDoubleLeftExtForm: FormGroup;
  public axisFiveDoubleRightIntForm: FormGroup;
  public axisFiveDoubleRightExtForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisFiveDoubleLeftIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisFiveDoubleLeftExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisFiveDoubleRightIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisFiveDoubleRightExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisFiveDoubleLeftIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 5 ESQ INT"
      );
      this.axisFiveDoubleLeftExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 5 ESQ EXT"
      );

      this.axisFiveDoubleRightIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 5 DIR INT"
      );
      this.axisFiveDoubleRightExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 5 DIR EXT"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo5DireitoExt;

        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 12) {
              eixo5DireitoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 12) {
              eixo5DireitoExt = filter;
            }
          }
        });
        var eixo5EsquerdoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 11) {
              eixo5EsquerdoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 11) {
              eixo5EsquerdoExt = filter;
            }
          }
        });
        var eixo5DireitoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 28) {
              eixo5DireitoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 28) {
              eixo5DireitoInt = filter;
            }
          }
        });
        var eixo5EsquerdoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 27) {
              eixo5EsquerdoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 27) {
              eixo5EsquerdoInt = filter;
            }
          }
        });

        // EIXO 5 DIREITO EXTERNO
        if (eixo5DireitoExt != null) {
          this.axisFiveDoubleRightExtForm.controls["id"].setValue(
            eixo5DireitoExt.id
          );

          if (eixo5DireitoExt.sensor != null) {
            this.sensorFiveExtRightCombo.push(eixo5DireitoExt.sensor);
            this.sensorFiveExtRightSelect = eixo5DireitoExt.sensor.id;
          }

          if (eixo5DireitoExt.pneu != null) {
            this.axisFiveDoubleRightExtForm.controls["pneu"].setValue(
              eixo5DireitoExt.pneu.numeroFogo
            );
          }

          this.axisFiveDoubleRightExtForm.controls["nrPressaoMinima"].setValue(
            eixo5DireitoExt.nrPressaoMinima
          );

          this.axisFiveDoubleRightExtForm.controls["nrPressaoMaxima"].setValue(
            eixo5DireitoExt.nrPressaoMaxima
          );

          this.axisFiveDoubleRightExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo5DireitoExt.nrTemperaturaMaxima);
        }

        // EIXO 5 ESQUERDO EXTERNO
        if (eixo5EsquerdoExt != null) {
          this.axisFiveDoubleLeftExtForm.controls["id"].setValue(
            eixo5EsquerdoExt.id
          );

          if (eixo5EsquerdoExt.sensor != null) {
            this.sensorFiveExtLeftCombo.push(eixo5EsquerdoExt.sensor);
            this.sensorFiveExtLeftSelect = eixo5EsquerdoExt.sensor.id;
          }

          if (eixo5EsquerdoExt.pneu != null) {
            this.axisFiveDoubleLeftExtForm.controls["pneu"].setValue(
              eixo5EsquerdoExt.pneu.numeroFogo
            );
          }

          this.axisFiveDoubleLeftExtForm.controls["nrPressaoMinima"].setValue(
            eixo5EsquerdoExt.nrPressaoMinima
          );

          this.axisFiveDoubleLeftExtForm.controls["nrPressaoMaxima"].setValue(
            eixo5EsquerdoExt.nrPressaoMaxima
          );

          this.axisFiveDoubleLeftExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo5EsquerdoExt.nrTemperaturaMaxima);
        }

        // EIXO 5 DIREITO INTERNO
        if (eixo5DireitoInt != null) {
          this.axisFiveDoubleRightIntForm.controls["id"].setValue(
            eixo5DireitoInt.id
          );

          if (eixo5DireitoInt.sensor != null) {
            this.sensorFiveIntRightCombo.push(eixo5DireitoInt.sensor);
            this.sensorFiveIntRightSelect = eixo5DireitoInt.sensor.id;
          }

          if (eixo5DireitoInt.pneu != null) {
            this.axisFiveDoubleRightIntForm.controls["pneu"].setValue(
              eixo5DireitoInt.pneu.numeroFogo
            );
          }

          this.axisFiveDoubleRightIntForm.controls["nrPressaoMinima"].setValue(
            eixo5DireitoInt.nrPressaoMinima
          );

          this.axisFiveDoubleRightIntForm.controls["nrPressaoMaxima"].setValue(
            eixo5DireitoInt.nrPressaoMaxima
          );

          this.axisFiveDoubleRightIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo5DireitoInt.nrTemperaturaMaxima);
        }

        // EIXO 5 ESQUERDO INTERNO
        if (eixo5EsquerdoInt != null) {
          this.axisFiveDoubleLeftIntForm.controls["id"].setValue(
            eixo5EsquerdoInt.id
          );

          if (eixo5EsquerdoInt.sensor != null) {
            this.sensorFiveIntLeftCombo.push(eixo5EsquerdoInt.sensor);
            this.sensorFiveIntLeftSelect = eixo5EsquerdoInt.sensor.id;
          }

          if (eixo5EsquerdoInt.pneu != null) {
            this.axisFiveDoubleLeftIntForm.controls["pneu"].setValue(
              eixo5EsquerdoInt.pneu.numeroFogo
            );
          }

          this.axisFiveDoubleLeftIntForm.controls["nrPressaoMinima"].setValue(
            eixo5EsquerdoInt.nrPressaoMinima
          );

          this.axisFiveDoubleLeftIntForm.controls["nrPressaoMaxima"].setValue(
            eixo5EsquerdoInt.nrPressaoMaxima
          );

          this.axisFiveDoubleLeftIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo5EsquerdoInt.nrTemperaturaMaxima);
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
