import { BehaviorSubject } from "rxjs";
import { CurrentPosition } from "../../../models/CurrentPosition";
import { TerminalMarker } from "../terminal-marker";
import { MapPin } from "../../../models/MapPin.model";

export class PathControl {

  constructor() { }

  /** TODO: Revisar este método de acordo com a nova estrutura **/
  private buildMarkersHistory(componet, waypoints, index, id): void {
    const element = waypoints[index];
    let historicPosition;
    historicPosition = { ...element };
    const lat = parseFloat(element.latitude);
    const lng = parseFloat(element.longitude);

    console.log('Latitude:', lat, 'Longitude:', lng);

    if (!isNaN(lat) && !isNaN(lng)) {
      // Definir a cor do pino e, se necessário, adicionar o círculo
      if (element.positionStop == true) {
        historicPosition.pin = new MapPin(
          element.direcao,
          'purple',
          componet.map,
          { lat: lat, lng: lng },
          true // Cria o círculo ao redor do marcador
          , element.stopData
        );
      } else {
        historicPosition.pin = new MapPin(
          element.direcao,
          'green',
          componet.map,
          { lat: lat, lng: lng },
          false // Não cria o círculo ao redor do marcador
        );
      }

      console.log('Marcador criado com a posição:', lat, lng);
    } else {
      console.error('Valores inválidos de latitude ou longitude:', lat, lng);
    }

    historicPosition.clone = true;
    historicPosition.tag = 'red';
    historicPosition.cloneTerminalData = historicPosition;
    historicPosition.cloneTerminalData.nome_empresa = element.nomeEmpresa;
    historicPosition.cloneTerminalData.terminal_imei = element.imei;
    historicPosition.cloneTerminalData.versaoFirmware = element.firmware;
    historicPosition.cloneTerminalData.terminal_simCard = element.simCard;
    historicPosition.cloneTerminalData.dataAtualizacao = element.dataAtualizacao;
    historicPosition.cloneTerminalData.nome_motorista = element.nomeMotorista;
    historicPosition.terminalSubject = new BehaviorSubject<CurrentPosition>(historicPosition);

    /**const m = {firstId: '',
             clone: true,
             ...element,
             tag: 'red',
             position: {},
             pin: {
              anchor: {x: 0, y: 0},
              fillColor: "white",
              path: 1,
              rotation: element.direcao,
              scale: 3,
              strokeColor: 'green',
              strokeWeight: 2
             }};
    //m.firstId = id; // inserir id do terminal
    //m.position = {latitude: element.latitude, longitude: element.longitude }
    m.latitude = element.latitude;
    m.longitude = element.longitude;
    m.cloneTerminalData = m;
    m.terminalSubject = new BehaviorSubject<CurrentPosition>(m);**/
    componet.terminalsData.push(historicPosition);

    //componet.terminalsData.forEach(t => {
    // Cria a subscription do historico
    //t.terminalSubject = new BehaviorSubject<CurrentPosition>(t);
    // });
    //componet.markerMap.markersMapSotrePath.push(m);
  }

  public removerMarkerHistoryClone(component): void {
    const noClones = component.terminalsData.filter(({ clone }) => clone !== true);
    component.terminalsData = noClones;
  }

  public pathControlRouteInMap(component, values): void {

    const { origin, destination, waypoints = [] } = values;

    component.mapButtonService.setPathButton(true);
    component.mapButtonService.setPathButtonDisabled(false);
    const pontos = new google.maps.MVCArray();
    const bounds = new google.maps.LatLngBounds();

    const originLatLng = new google.maps.LatLng(origin.lat, origin.lng);
    pontos.push(originLatLng);
    bounds.extend(originLatLng);
    for (let i = 0; i < waypoints.length; i++) {

      const latlngParser = new google.maps.LatLng(waypoints[i].latitude, waypoints[i].longitude);

      pontos.push(latlngParser);
      bounds.extend(latlngParser);
    }
    const destinationLatLng = new google.maps.LatLng(
      destination.lat,
      destination.lng,
    );
    pontos.push(destinationLatLng);
    bounds.extend(destinationLatLng);

    const polylineOptions = {
      strokeColor: '#FF0000',
      strokeOpacity: 1,
      strokeWeight: 1,
      zIndex: 5,
    };

    component.poly = new google.maps.Polyline(polylineOptions);
    component.poly.setPath(pontos);
    component.poly.setMap(component.map);

    //Mostra todos os pontos no mapa
    component.map.fitBounds(bounds);
  }

  public setPathControlMarkerHistory(componet, command, values): void {

    const { waypoints = [] } = values;

    for (let index = 0; index < waypoints.length; index++) {
      this.buildMarkersHistory(componet, waypoints, index, command.id);
    }
  }

  public button_path_reset(component): void {
    component.displayDirections = false;
    component.routeOrigin = null;
    component.routeDestination = null;
    component.isFitMap = true;
    component.setBounds(null);
    if (component.poly) {
      component.poly.setMap(null);
      component.poly = null;
    }
    component.mapButtonService.setPathButtonDisabled(true);

    this.removerMarkerHistoryClone(component);
  }
}

export default new PathControl();
