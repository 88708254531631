import { ToolsService } from "./../services/migrate/tools.service";

import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private tools: ToolsService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.tools.builder().spinner.hide();
        this.tools
          .builder()
          .openToast.emit(
            error?.error?.message
              ? error?.error?.message
              : "Desculpe ocorreu um erro inesperado"
          );
        return throwError(error);
      })
    );
  }
}
