import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { User } from "../../models/User.model";
import { NgxSpinnerService } from "ngx-spinner";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-create-user",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class EditUserComponent implements OnInit {
  public userForm: FormGroup;
  public user: User;
  public userId: number;
  public title = "Atualizar Usuário";
  public titleVar = "";
  public errorMessage;
  public empresaFilterSelected;
  public types: any = [];
  public situations: any = [];

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      cdUsuario: ["", Validators.required],
      nmUsuario: ["", Validators.required],
      dsEmail: ["", Validators.required],
      nrMatricula: [""],
      generalFilterPermission: [""],
    });
    this.userId = this.activatedRoute.snapshot.params["id"];
    this.loadUser(this.userId);
  }

  exibevalor(): void {}

  public async loadUser(id): Promise<void> {
    try {
      this.spinner.show();

      const response = await this.apiService.get<User>({
        url: new User()["nomeConsultaApiBase"] + "/" + this.userId,
      });

      console.log(response);

      this.user = response as User;

      console.log(this.user);

      this.fillUserData();

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  constructor(
    private apiService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {}

  fillUserData(): void {
    const fields = [
      "cdUsuario",
      "nrMatricula",
      "nmUsuario",
      "dsEmail",
      "generalFilterPermission",
    ];
    fields.forEach((element) => {
      this.userForm.controls[element].setValue(this.user[element]);
    });
  }

  public async submitForm(): Promise<void> {
    try {
      this.spinner.show();

      const values = this.userForm.value;
      const id = Math.floor(this.userId);
      var post;

      post = {
        ...values,
        ativo: true,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      if (values.cdSenha === "") {
        delete post.cdSenha;
      }
      await this.apiService.put<User>(
        this.userId,
        new User()["nomeConsultaApiBase"],
        post
      );

      this.spinner.hide();

      this.router.navigate(["/consulta/USER_FUNCIONALIDADE"]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
