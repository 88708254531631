import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "./../../storageCore/constStorageKeys";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { VisualizarDialogComponent } from "./visualizar-dialog/visualizar-dialog.component";
import { Page } from "src/app/utils/paginate";

declare let google: any;

import { EventService } from "src/app/services/migrate/Event.service";
import { CockpitService } from "../cockpit/cockpit.service";
import storageX from "src/app/storageCore/storageX";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/services/api.service";
import { MatTableDataSource } from "@angular/material/table";
const INTERVAL_IN_MINUTES = 10 * 1000;
const INTERVAL_TRAKING_MARKER_IN_SECONDS = 1 * 10 * 1000;

@Component({
  selector: "app-events-alarm",
  templateUrl: "./events-alarm.component.html",
  styleUrls: ["./events-alarm.component.scss"],
})
export class EventsAlarmComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public title;
  public errorMessage;
  public length = 0;
  public pageSize = 5000;
  public page = new Page();
  public interval;
  public events: MatTableDataSource<any>;
  public terminalAlarmeId: number;
  public alarms = [];
  public snackBar: MatSnackBar;

  public displayedColumns: string[] = [
    "dhVisualizacao",
    "id",
    "dhAlarme",
    "nome",
    "nmEmpresa",
    "nmDivisao",
    "placa",
    "flFinalizado",
    "visualizar",
  ];
  id: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public cockpitService: CockpitService,
    private serviceEvents: EventService
  ) {}

  ngOnInit(): void {
    this.terminalAlarmeId = this.activatedRoute.snapshot.params["terminalId"];
    this.loadTerminlsAlarm();
  }

  public async loadTerminlsAlarm(pageIndex = 0, sort?) {
    const params = {
      size: this.pageSize,
      page: pageIndex,
      profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
    };

    try {
      this.spinner.show();
      var result;

      result = await this.serviceEvents.get<any>({
        url: "/v2/events-panel/" + this.terminalAlarmeId,
        params,
      });

      this.events = new MatTableDataSource<any>(result.content);
      setTimeout(() => {
        this.events.sort = this.sort;
        this.events.paginator = this.paginator;
        this.spinner.hide();
      }, 300);

      this.title = result.content[0].mensagem;

      console.log(this.title);
      console.log(this.events);

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public handlePageChanged = (value): void => {
    this.spinner.show();

    this.cockpitService
      .loadEvents(
        this.terminalAlarmeId,
        String(value.pageSize),
        String(value.pageIndex)
      )
      .subscribe((r) => {
        this.spinner.hide();
        this.events = r.elements;
        this.page = r.page;
      });
  };

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.events.filter = filterValue.trim().toLowerCase();

    if (this.events.paginator) {
      this.events.paginator.firstPage();
    }
  }

  public visualizar(row): void {
    if (!row.dhVisualizacao) {
      this.cockpitService
        .update(row.id, {
          flFinalizado: false,
          idUsuarioVisualizacao: storageX
            .whereKeyIs(STORAGE_KEY_CURRENT_USER)
            .get()?.id,
        })
        .subscribe();
    }

    const event = this.events.data.find((option) => option.id == row.id);
    event["position"] = null;

    this.id = event.id;
    this.dialog
      .open(VisualizarDialogComponent, {
        width: "1200px",
        data: {
          ...event,
          position: event.position,
          terminalAlarmeId: this.terminalAlarmeId,
        },
        panelClass: "custom-dialog",
      })
      .afterClosed()
      .subscribe(async (dataAfterClose) => {
        const index = this.events.data.findIndex(({ id }) => id == row.id);
        this.events.data[index].position = dataAfterClose.position;

        if (dataAfterClose.payload) {
          this.spinner.show();
          await this.serviceEvents
            .patch(row.id, "v2/events-panel", {
              flFinalizado: dataAfterClose.payload?.flFinalizado,
              idUsuarioFinalizacao: storageX
                .whereKeyIs(STORAGE_KEY_CURRENT_USER)
                .get()?.id,
              idUsuarioVisualizacao: row.idUsuarioVisualizacao,
              dsResposta: dataAfterClose.payload?.dsResposta,
            })
            .then(() => {})
            .catch(() => {
              this.spinner.hide();
            });
        }

        this.loadTerminlsAlarm();
      });
  }

  ngOnDestroy() {}
}
