<div class="main-page cadastros">
  <div class="container-fluid">
    <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

    <mat-tab-group>
      <mat-tab label="Dados">
        <div class="row mt-3 no-gutters p-4 row-form">
          <div class="col-12">
            <form [formGroup]="vehicleForm" (ngSubmit)="submitForm()">
              <h2 class="mb-3">Novo Veículo</h2>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo de Cadastro</mat-label>
                    <mat-select formControlName="selecaoEmpresa" [(value)]="empresaFilterSelected"
                      [(ngModel)]="empresaFilterSelected" (selectionChange)="this.filterCompany()">
                      <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                        {{ type.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <validation-errors [form]="vehicleForm" controlLabel="Tipo de Cadastro" controlName="selecaoEmpresa">
                  </validation-errors>
                </div>
                <div class="form-group col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label *ngIf="vehicleForm.get('selecaoEmpresa').value == null">Selecione um tipo de
                      cadastro</mat-label>
                    <mat-label *ngIf="vehicleForm.get('selecaoEmpresa').value == 'Matriz'">Matrizes</mat-label>
                    <mat-label *ngIf="vehicleForm.get('selecaoEmpresa').value == 'Filial'">Filiais</mat-label>
                    <mat-label *ngIf="vehicleForm.get('selecaoEmpresa').value == 'Divisão'">Divisões</mat-label>
                    <mat-select formControlName="idEmpresa" [(value)]="empresaSelected" [(ngModel)]="empresaSelected"
                      required>
                      <mat-option *ngFor="let type of companiesFilter" [value]="type.id">
                        {{ type.nome }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <validation-errors [form]="vehicleForm" controlLabel="Matriz/Divisão/Filial" controlName="idEmpresa">
                  </validation-errors>
                </div>
                <div class="form-group col-lg-2" *ngIf="title == 'Atualizar Veículo'">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo de Rodagem</mat-label>
                    <mat-select formControlName="tipoRodagem" [(value)]="tipoRodagemSelect" [readonly]=true
                      [disabled]=true>
                      <mat-option *ngFor="let type of tipoRodagem" [value]="type.value">
                        {{ type.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-2" *ngIf="title != 'Atualizar Veículo'">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo de Rodagem</mat-label>
                    <mat-select formControlName="tipoRodagem" [(value)]="tipoRodagemSelect">
                      <mat-option *ngFor="let type of tipoRodagem" [value]="type.value">
                        {{ type.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="form-group col-md-2">
                  <mat-form-field appearance="outline">
                    <mat-label>Câmbio automático</mat-label>

                    <mat-select formControlName="cambioAutomatico" [(value)]="cambioAutomaticoSelect" required>
                      <mat-option *ngFor="let type of cambioAutomatico" [value]="type.value">
                        {{ type.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <validation-errors [form]="vehicleForm" controlLabel="Cambio Automatico"
                    controlName="cambioAutomatico">
                  </validation-errors>
                </div>
              </div>

              <div class="form-row">

                <div class="form-group col-md-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo de Veículo</mat-label>
                    <mat-select formControlName="idVeiculoTipo" [(value)]="veiculoTipoSelected" required>
                      <mat-option *ngFor="let type of vehicleTypes" [value]="type.id">
                        {{ type.nmNome }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <validation-errors [form]="vehicleForm" controlLabel="Tipo de Veículo" controlName="idVeiculoTipo">
                  </validation-errors>
                </div>

                <div class="form-group col-md-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo de Combustível</mat-label>

                    <mat-select formControlName="idVeiculoCombustivelTipo" [(value)]="veiculoCombustivelTipoSelected"
                      required>
                      <mat-option *ngFor="let type of fuelTypes" [value]="type.id">
                        {{ type.nmNome }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <validation-errors [form]="vehicleForm" controlLabel="Tipo de Combustível"
                    controlName="idVeiculoCombustivelTipo">
                  </validation-errors>
                </div>

                <div class="form-group col-md-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Categoria Veiculo</mat-label>

                    <mat-select formControlName="idCategoriaVeiculo" [(value)]="categoriaVeiculoSelected" required>
                      <mat-option *ngFor="let type of categoriaVeiculos" [value]="type.id">
                        {{ type.nmNome }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <validation-errors [form]="vehicleForm" controlLabel="Categoria Veiculo"
                    controlName="idCategoriaVeiculo">
                  </validation-errors>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Quantidade de Eixo</mat-label>
                    <mat-select formControlName="nrQuantidadeEixo" [(value)]="axisSelect">
                      <mat-option *ngFor="let type of quantidadeEixo" [value]="type.value">
                        {{ type.name }} (Exceto Eixo Direcional)
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>


              <div class="form-row">
                <div class="form-group col-lg-2">
                  <mat-form-field appearance="outline">
                    <mat-label>Placa</mat-label>
                    <input formControlName="cdPlaca" matInput placeholder="Placa" maxlength="10" required
                      pattern="[A-Za-z0-9]*"
                      (input)="vehicleForm.get('cdPlaca')?.setValue($event.target.value.toUpperCase())"
                      (keypress)="allowOnlyLettersAndNumbers($event)" />

                    <!-- Botão de busca dentro do campo de input -->
                    <button mat-icon-button matSuffix (click)="searchPlate()" type="button"
                      [disabled]="vehicleForm.get('cdPlaca')?.invalid || vehicleForm.get('cdPlaca')?.value === ''">
                      <mat-icon>search</mat-icon>
                    </button>
                  </mat-form-field>
                  <validation-errors [form]="vehicleForm" controlLabel="Placa" controlName="cdPlaca">
                  </validation-errors>
                </div>



                <div class="form-group col-lg-2">
                  <mat-form-field appearance="outline">
                    <mat-label>Frota</mat-label>
                    <input formControlName="frota" matInput placeholder="Frota" maxlength="10" />
                  </mat-form-field>
                  <validation-errors [form]="vehicleForm" controlLabel="frota" controlName="frota">
                  </validation-errors>
                </div>

                <div class="form-group col-lg-2">
                  <mat-form-field appearance="outline">
                    <mat-label>Renavam</mat-label>
                    <input formControlName="cdRenavam" pattern="[0-9]*" matInput placeholder="Renavam" maxlength="40"
                      (keypress)="numberOnly($event)" />
                  </mat-form-field>
                </div>

                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Chassi</mat-label>
                    <input formControlName="cdChassi" matInput placeholder="Chassi" maxlength="100" />
                  </mat-form-field>
                </div>

                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>ANTT</mat-label>
                    <input formControlName="cdAntt" matInput placeholder="ANTT" maxlength="100" />
                  </mat-form-field>
                </div>
              </div>

              <div class="form-row">

                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>PBT</mat-label>
                    <input formControlName="cdPbt" matInput placeholder="PBT" maxlength="100"
                      (keypress)="numberOnly($event)" />
                  </mat-form-field>
                </div>

                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Marca</mat-label>
                    <input formControlName="nmMarca" matInput placeholder="Marca" maxlength="100" />
                  </mat-form-field>
                </div>

                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Modelo</mat-label>
                    <input formControlName="nmModelo" matInput placeholder="Modelo" maxlength="100" />
                  </mat-form-field>
                </div>

                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Cor</mat-label>
                    <input formControlName="cdCor" matInput placeholder="Cor" maxlength="50" />
                  </mat-form-field>
                </div>


              </div>

              <div class="form-row">
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Ano de Fabricação</mat-label>
                    <input formControlName="nrAnoFabricacao" pattern="[0-9]*" matInput placeholder="Ano de Fabricação"
                      (keypress)="numberOnly($event)" />
                  </mat-form-field>
                  <span class="alert-danger" *ngIf="!vehicleForm.get('nrAnoFabricacao').valid">
                    Ano de Fabricação é um campo numérico
                  </span>
                </div>

                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Ano Modelo</mat-label>
                    <input formControlName="nrAnoModelo" pattern="[0-9]*" matInput placeholder="Ano do Modelo"
                      (keypress)="numberOnly($event)" />
                  </mat-form-field>
                  <span class="alert-danger" *ngIf="!vehicleForm.get('nrAnoModelo').valid">
                    Ano Modelo é um campo numérico
                  </span>
                </div>


                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Cidade</mat-label>
                    <input formControlName="nmCidade" matInput placeholder="Cidade" maxlength="100" />
                  </mat-form-field>
                </div>

                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>País</mat-label>
                    <input formControlName="nmPais" matInput placeholder="País" maxlength="100" />
                  </mat-form-field>
                </div>
              </div>


              <div class="form-row">

                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Estado</mat-label>
                    <input formControlName="nmEstado" matInput placeholder="Estado" maxlength="100" />
                  </mat-form-field>
                </div>

                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Coeficiente de Consumo</mat-label>
                    <input formControlName="nrCoeficienteConsumo" pattern="[0-9]*" matInput
                      placeholder="Coeficiente de Consumo" (keypress)="numberOnly($event)" value="0" ngModel="0" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Odômetro</mat-label>
                    <input formControlName="nrOdometro" pattern="[0-9]*" matInput placeholder="Odômetro atual"
                      (keypress)="numberOnly($event)" value="0" ngModel="0" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Capacidade do Tanque</mat-label>
                    <input formControlName="nrCapacidadeTanque" pattern="[0-9]*" matInput
                      placeholder="Capacidade do Tanque" (keypress)="numberOnly($event)" value="0" ngModel="0" />
                  </mat-form-field>
                </div>

              </div>


              <div *ngIf="envioLote === true">
                <h2 class="my-3">Inclusão em lote</h2>
                <div class="form-row">
                  <p>Para inclusão em lote, tenha certeza de ter preenchido os seguintes campos:<br>
                    - Empresa<br>
                    - Tipo de veículo<br>
                    - Tipo de combustível</p>
                </div>
                <div class="form-row">
                  <label for="UploadCSV">Esses campos são obrigatórios e inseridos manualmente,
                    o restante dos campo o arquivo CSV preenchera automaticamente!</label>
                  <input type="file" #csvReader name="Upload CSV" class="form-control-file" id="txtFileUpload"
                    (change)="uploadListener($event)" accept=".csv" />
                  <!-- <button type="button" name="Processar" class="form-control" id="btnProcessar"
                  (click)="fncProcessar($event)" style="width: 130px;">{{btnText}}</button> -->
                </div>
              </div>

              <h2 class="my-3">Contato</h2>
              <div class="form-row">
                <div class="form-group col-lg-4">
                  <mat-form-field appearance="outline">
                    <mat-label>Nome Proprietário</mat-label>
                    <input formControlName="nmNomeProprietario" matInput placeholder="Nome Proprietário"
                      maxlength="80" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-4">
                  <mat-form-field appearance="outline">
                    <mat-label>Telefone Proprietário</mat-label>
                    <input formControlName="nrTelefoneProprietario" matInput placeholder="Núm. Telefone Proprietário"
                      maxlength="20" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-4">
                  <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input formControlName="dsEmail" matInput placeholder="Email" maxlength="80" />
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="errorMessage" class="alert alert-danger">
                {{ errorMessage }}
              </div>

              <div class="form-group mt-2 mb-0 text-center">
                <back-button></back-button>
                <button type="submit" [disabled]="!vehicleForm.valid"
                  class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                  Salvar
                </button>
                <button type="button" mat-button>
                  <a style="padding: 11px;" class="btn ml-0 ml-sm-2 ml-md-3 btn-info my-2 my-md-0"
                    (click)="this.downloadCsv()">
                    CSV Modelo
                  </a>
                </button>
              </div>
            </form>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="TPMS & Sensores"
        *ngIf="axisQuantity != null && veiculoId != null && title == 'Atualizar Veículo' && veiculo.tipoRodagem != null">
        <mat-horizontal-stepper #stepper>
          <mat-step label="CA">
            <ng-template matStepLabel>DIRECIONAL</ng-template>
            <div *ngIf="veiculoId">
              <app-directional (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-directional>
            </div>
          </mat-step>

          <mat-step *ngIf="idVehicleCategory == 5">
            <ng-template matStepLabel>DIRECIONAL 2</ng-template>
            <div *ngIf="veiculoId">
              <app-directional-two (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-directional-two>
            </div>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>ESTEPES</ng-template>
            <div *ngIf="veiculoId">
              <app-steppe (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-steppe>
            </div>
          </mat-step>

          <mat-step *ngIf="axisQuantity >= 1">
            <ng-template matStepLabel>EIXO 1</ng-template>

            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'S'">
              <app-axle-one-simple (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-one-simple>
            </div>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'D'">
              <app-axle-one-dual (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-one-dual>
            </div>

          </mat-step>

          <mat-step *ngIf="axisQuantity >= 2">
            <ng-template matStepLabel>EIXO 2</ng-template>

            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'S'">
              <app-axle-two-simple (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-two-simple>
            </div>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'D'">
              <app-axle-two-dual (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-two-dual>
            </div>

          </mat-step>

          <mat-step *ngIf="axisQuantity >= 3">
            <ng-template matStepLabel>EIXO 3</ng-template>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'S'">
              <app-axle-three-simple (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-three-simple>
            </div>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'D'">
              <app-axle-three-dual (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-three-dual>
            </div>

          </mat-step>
          <mat-step *ngIf="axisQuantity >= 4">
            <ng-template matStepLabel>EIXO 4</ng-template>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'S'">
              <app-axle-four-simple (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-four-simple>
            </div>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'D'">
              <app-axle-four-dual (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-four-dual>
            </div>

          </mat-step>
          <mat-step *ngIf="axisQuantity >= 5">
            <ng-template matStepLabel>EIXO 5</ng-template>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'S'">
              <app-axle-five-simple (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-five-simple>
            </div>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'D'">
              <app-axle-five-dual (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-five-dual>
            </div>

          </mat-step>
          <mat-step *ngIf="axisQuantity >= 6">
            <ng-template matStepLabel>EIXO 6</ng-template>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'S'">
              <app-axle-six-simple (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-six-simple>
            </div>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'D'">
              <app-axle-six-dual [veiculoId]="veiculoId" [veiculoSensores]="veiculoSensores"
                [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-six-dual>
            </div>

          </mat-step>
          <mat-step *ngIf="axisQuantity >= 7">
            <ng-template matStepLabel>EIXO 7</ng-template>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'S'">
              <app-axle-seven-simple (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-seven-simple>
            </div>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'D'">
              <app-axle-seven-dual (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-seven-dual>
            </div>

          </mat-step>
          <mat-step *ngIf="axisQuantity >= 8">
            <ng-template matStepLabel>EIXO 8</ng-template>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'S'">
              <app-axle-eight-simple (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-eight-simple>
            </div>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'D'">
              <app-axle-eight-dual (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-eight-dual>
            </div>
          </mat-step>

          <mat-step *ngIf="axisQuantity >= 9">
            <ng-template matStepLabel>EIXO 9</ng-template>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'S'">
              <app-axle-nine-simple (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-nine-simple>
            </div>
            <div *ngIf="veiculoId && veiculo.tipoRodagem == 'D'">
              <app-axle-nine-dual (atualizarSensores)="atualizarSensores($event)" [veiculoId]="veiculoId"
                [veiculoSensores]="veiculoSensores" [sensoresDisponiveis]="sensoresDisponiveis">
              </app-axle-nine-dual>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>