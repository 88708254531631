<div class="main-page cadastros styleTable styleListaTerminal">
    <div class="margin-teste">
        <div fxLayout fxLayoutAlign="center center">
            <mat-form-field fxFlex="40%" >
                <input autofocus type="text" matInput placeholder="Mensagem" [(ngModel)]="mensagemFiltro" />
            </mat-form-field>
            <mat-form-field class="ml-2">
                <input autofocus type="text" matInput placeholder="Id Terminal" [(ngModel)]="idFiltro" />
            </mat-form-field>
            <button type="button" mat-icon-button (click)="handleFilter()">
                <mat-icon>search</mat-icon>
            </button>
        </div>
    </div>

    <div class="container-fluid" [ngClass]="this.classCSSHoverBody">

        <p-menu #menu [popup]="true" [model]="items"></p-menu>
        <p-table [value]="terminaisMensagemProcessada">
            <ng-template pTemplate="caption">

                <div class="p-d-flex">
                    <span style="font-size: 12px;
                    font-weight: 800;
                    color: #ffffffd4;" *ngIf="!this.driverName">
                        Lista de terminais
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Data/Hora</th>
                    <th colspan="2">Mensagem</th>
                    <th>Id Terminal</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-terminal let-expanded="expanded">
                <tr>
                    <td>{{convertToDate(terminal.dtRecebimentoMensagem)}}</td>
                    <td colspan="2">{{terminal.dsMensagem}}</td>
                    <td>{{terminal.idTerminal}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer" let-columns>
                <tr>
                    <td colspan="4">
                        <mat-paginator [pageSize]="20" (page)="handlePageChanged($event)" [length]="pageSize" showFirstLastButtons>
                        </mat-paginator>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>