<div class="main-page consultas">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 my-2">
        <h1>
          <a
            class="btn btn-voltar text-center mr-2"
            href="/vehicle-types"
            data-title="Voltar"
            ><i class="fas fa-arrow-left"></i
          ></a>
          Consultar Tipo de Veículo
        </h1>
      </div>
    </div>

    <div *ngIf="vehicleTypes.data.length === 0 && !loader">
      <div class="row no-gutters mt-3">
        <p>Nenhum registro até o momento...</p>
      </div>
      <div class="row no-gutters mt-3">
        <a
          href="/vehicle-types/create"
          mat-button
          color="primary"
          class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0"
        >
          Criar um tipo de veículo
        </a>
      </div>
    </div>

    <div class="row no-gutters mt-3" *ngIf="vehicleTypes.data.length > 0">
      <div class="mat-elevation table-content">
        <div fxLayout fxLayoutAlign="center center">
          <mat-form-field fxFlex="40%">
            <input
              matInput
              type="text"
              (keyup)="handleFilter($event.target.value)"
              placeholder="Filtro"
            />
          </mat-form-field>
        </div>

        <table
          mat-table
          [dataSource]="vehicleTypes"
          matSort
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <ng-container matColumnDef="nmNome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
            <td mat-cell *matCellDef="let element">{{ element.nmNome }}</td>
          </ng-container>

          <ng-container matColumnDef="update">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <span class="alerta mr-2" data-title="Editar">
                <a
                  href="/vehicle-types/edit/{{ element.id }}"
                  class="btn btn-link"
                >
                  <img height="20" src="assets/img/edit.svg" alt="" />
                </a>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button (click)="handleDelete(element.id)" class="btn btn-link">
                <img height="20" src="assets/img/trash-cadastro.svg" alt="" />
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator
          *ngIf="vehicleTypes.data.length !== 0"
          [pageSize]="pageSize"
          (page)="handlePageChanged($event)"
          [length]="totalElements"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
