import { ApiService } from "./api.service";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";

declare var $;

@Injectable({
  providedIn: "root",
})
export class GoogleService {
  constructor(private service: ApiService) {}

  public postGeolocation(payload: any): Promise<any> {
    return this.service.post(
      `${environment.apiUrl}/${"evento-alarme/position"}`,
      payload
    );
  }
}
