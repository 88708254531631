import { DestinyAnalysisModel } from "./destiny-analysis-model";
import { BaseModel } from "./hateoas/base-model";

export class AnalyzeReportModel extends BaseModel {
  analyzeStartDate?: string;
  analyzeEndDate?: string;
  idDriver?: number;
  idTerminal?: number;
  idCompany?: number;
  idDivision?: number;
  packageNumber?: string;
  description?: string;
  jumpGps?: Boolean;
  managerAnalyzeUuid?: string;
  operationalBaseUuid?: string;
  supervisorUuid?: string;
  operatorAnalyzeId?: number;
  userId?: number;
  nameDriver?: string;
  typeReportAnalyzeEnum?: string;
  destinyAnalysisSupervisorList?: DestinyAnalysisModel[];
  destinyAnalysisManagerList?: DestinyAnalysisModel[];
}
