import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
  })
 export class Params {
    private params = {};
  
    setParam(key: string, value: string): void {
      this.params[key] = value;
    }
  
    public build(): any {
      return this.params;
    }
  
    public resetParam() {
       this.params = {};
    }
  }