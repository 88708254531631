import { Component, ViewChild, OnInit } from "@angular/core";
import { MatButtonToggle } from "@angular/material/button-toggle";

import { MapToolboxService } from "./../../../../services/map.toolbox.service";
import { MapButtonService } from "./../../../../services/map.buttom.service";
import {PermissionsService} from "../../../../helpers/permissions.service";

@Component({
  selector: "toolbox-button-distance",
  templateUrl: "./button-distance.component.html",
  styleUrls: ["./button-distance.component.scss"],
})
export class ButtonDistanceComponent implements OnInit {
  @ViewChild(MatButtonToggle) btnCalcDistance: MatButtonToggle;
  permissions: [];

  selected = false;
  disabled = false;

  public action: string;
  private service: MapToolboxService;
  private mapButtonService: MapButtonService;

  constructor(
    service: MapToolboxService,
    private _mapButtonService: MapButtonService,
    public permissionService: PermissionsService
  ) {
    this.service = service;
    this.mapButtonService = _mapButtonService;
  }
  ngOnInit(): void {
    this.permissions = this.permissionService.getPermissions();
    this.service.currentToolboxCommand.subscribe((action) => {
      this.action = action;
    });

    this.mapButtonService.currentDistanceButton.subscribe((action) => {
      if (action && action !== this.selected) {
        this.selected = action;
      }
    });
    this.mapButtonService.currentDistanceButtonDisabled.subscribe((action) => {
      this.disabled = action;
    });
  }

  onChangeValue(): void {
    if (this.disabled) {
      return;
    }
    const value = !this.selected;
    this.selected = value;
    this.mapButtonService.setDistanceButton(value);
    if (!value) {
      this.disabled = true;
    }
    this.service.setToolboxCommand("calc_distance");
  }
}
