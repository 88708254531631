<mat-dialog-content class="mat-typography">
  <div class="container">
    <h2>Criar uma nova Cerca Eletrônica</h2>
    <div class="form-row">
      <mat-form-field>
        <mat-label>Tipo de Cadastro</mat-label>
        <mat-select [(value)]="empresaFilterSelected" [(ngModel)]="empresaFilterSelected" 
        (selectionChange)="this.filterCompany()">
          <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <mat-label *ngIf="this.empresaSelected == null">Selecione um tipo de cadastro</mat-label>
        <mat-label *ngIf="this.empresaSelected == 'Matriz'">Matrizes</mat-label>
        <mat-label *ngIf="this.empresaSelected == 'Filial'">Filiais</mat-label>
        <mat-label *ngIf="this.empresaSelected == 'Divisão'">Divisões</mat-label>
        <mat-select [(value)]="empresaValue" [(ngModel)]="empresaSelected" (selectionChange)="this.loadMarkersFilter(empresaSelected)" required>
          <mat-option *ngFor="let type of companiesFilter" [value]="type">
            {{ type.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field>
        <mat-label>Local Processamento</mat-label>
        <mat-select [(value)]="cercaEletronicaLocalProcessamentoSelected" required>
          <mat-option *ngFor="let type of cercaEletronicaLocalProcessamento" [value]="type.id">
            {{ type.nmNome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field>
        <mat-label>Tipo</mat-label>
        <mat-select [(value)]="cercaEletronicaTipoSelected" required>
          <mat-option *ngFor="let type of cercaEletronicaTipo" [value]="type.id">
            {{ type.nmNome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  <div class="row">
    <mat-form-field appearance="outline">
      <mat-label>Nome da Cerca Eletrônica</mat-label>
      <input
        matInput
        [(ngModel)]="electronicFenceName"
        placeholder="Nome da Cerca Eletrônica"
      />
    </mat-form-field>
  </div>
    <label>Selecionar veículos</label>
    <br>
    <section class="example-section">
    <div class="form-group">
      <input type="text" class="form-control" (keyup)="handleFilter($event.target.value)" placeholder="pesquisar..."
        autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search />
    </div>   
    <div class="box-placas">
      <span class="example-list-section">
        <mat-checkbox class="example-margin"
                      [checked]="allComplete"
                      [indeterminate]="someComplete()"
                      (change)="setAll($event.checked)">
          {{task.name}}
        </mat-checkbox>
      </span>
      <span class="example-list-section">
        <ul>
          <li *ngFor="let subtask of task.subtasks">
            <mat-checkbox [(ngModel)]="subtask.completed"
                          [color]="subtask.color"
                          (ngModelChange)="updateAllComplete()">
              {{subtask.name}} 
            </mat-checkbox>
          </li>
        </ul>
      </span>
    </div>
  </section>
  <div class="row">
    <div class="form-group">
      <label>Velocidade Alerta Sonoro: </label>
        <input
          type="text"
          class="form-control distance"
          (change)="velocidadeAlarmeVeiculoChanged($event)"
          (keyup)="velocidadeAlarmeVeiculoChanged($event)"
          [disabled]="this.cercaEletronicaLocalProcessamentoSelected == 2"     
          type="number"
        />
    </div>
  </div>

  <div class="row">
    <div class="form-group">
      <label>Velocidade Evento Plataforma: </label>
        <input
          type="text"
          class="form-control distance"
          (change)="velocidadeAlarmePainelChanged($event)"
          (keyup)="velocidadeAlarmePainelChanged($event)"
          type="number"
        />
    </div>
  </div>
  </div>  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Fechar</button>
  <button
    [disabled]="(!empresaSelected  || !cercaEletronicaLocalProcessamentoSelected || !cercaEletronicaTipoSelected || !electronicFenceName || !velocidadeAlarmePainel || velocidadeAlarmePainel <= 0 || ((!velocidadeAlarmeVeiculo || velocidadeAlarmeVeiculo <= 0) && this.cercaEletronicaLocalProcessamentoSelected != 2))"
    mat-button
    (click)="submit()"
  >
    Desenhar Cerca Eletrônica
  </button>
</mat-dialog-actions>
