/* tslint:disable:variable-name */
import {VehicleMenuItem} from './VehicleMenuItem.model';

export class CompanyMenuItem {
    id: number;
    parent_id?: number;
    company_name: string;
    company_type?: string;
    childrens?: Array<CompanyMenuItem>;
    vehicles?: Array<VehicleMenuItem>;


    constructor() {
        this.id = -1;
        this.parent_id = -1;
        this.company_name = '';
        this.company_type = '';
        this.childrens = [];
        this.vehicles = [];
    }
}
