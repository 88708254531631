import { Page } from "./../../../utils/paginate";
import { NgxSpinnerService } from "ngx-spinner";
/* eslint-disable @typescript-eslint/camelcase */
import { Component, ViewChild, AfterViewInit, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { Driver } from "../../../models/Driver.model";
import { ApiService } from "../../../services/api.service";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { CoreService } from "src/app/services/migrate/api.core.service";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-drivers",
  templateUrl: "./drivers.component.html",
  styleUrls: ["./drivers.component.scss"],
})
export class DriversComponent implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('filter') filter: ElementRef;
  // @ViewChild('typeFilter') typeFilter: ElementRef;
  public filter;
  public typeFilter;

  public page = new Page();
  public errorMessage;
  public length = 0;
  public pageSize = 5000;
  public totalElements = 0;
  public totalPages = 0;

  /* OCULTADO CAMPO ID DA TABELA - 20102020 - MARCOS TAVARES
  public displayedColumns: string[] = [
    'id',
    'empresa',
    'cdCodigo',
    'nmNome',
    'dtValidadeCnh',
    'cdCategoriaCnh',
    'nrTelefone',
    'update',
    'delete',
  ];
  */

  public displayedColumns: string[] = [
    "nmNome",
    "nmempresa",
    "cdCodigo",
    "dtValidadeCnh",
    "cdCategoriaCnh",
    "nrTelefone",
    "update",
    "clone",
    "view",
    "delete",
  ];

  /* OCULTADO CAMPO ID DA TABELA - 20102020 - MARCOS TAVARES
  public filtros = [
    { campo: 'id', valor: 'ID' },
    { campo: 'nmNome', valor: 'Nome Motorista' },
    { campo: 'cdCodigo', valor: 'Codigo Motorista' },
  ];
  */

  public filtros = [
    { campo: "nmempresa", valor: "Empresa" },
    { campo: "nmNome", valor: "Nome Motorista" },
    { campo: "cdCodigo", valor: "Codigo Motorista" },
  ];

  private service: ApiService;
  private coreService: CoreService;

  public drivers: MatTableDataSource<any>;

  constructor(
    apiCoreService: CoreService,
    apiService: ApiService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.service = apiService;
    this.coreService = apiCoreService;

    this.loadDrivers();
    /*     this.drivers = new MatTableDataSource<DriverInterface>([]);
     */
  }

  ngOnInit(): void {}

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.drivers.filter = filterValue.trim().toLowerCase();

    if (this.drivers.paginator) {
      this.drivers.paginator.firstPage();
    }
  }

  public handleSort = (sort): void => {
    this.loadDrivers(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadDrivers(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadDrivers();
  };

  public handleDelete = (id: string): void => {
    this.deleteDriver(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/drivers/${id}`]);
  };

  ngAfterViewInit(): void {
    this.drivers.sort = this.sort;
    this.drivers.paginator = this.paginator;
  }

  public async deleteDriver(id): Promise<void> {
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        await this.service.delete<Driver>(id, "motorista");

        this.spinner.hide();

        this.loadDrivers();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public async loadDrivers(pageIndex = 0, sort?): Promise<void> {
    const params = {
      size: this.pageSize,
      page: pageIndex,
      profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
    };

    try {
      this.spinner.show();

      const result = await this.coreService.get<any>({
        url: "v2/drivers",
        params,
      });

      this.drivers = new MatTableDataSource<any>(result.elements);

      setTimeout(() => {
        this.drivers.sort = this.sort;
        this.drivers.paginator = this.paginator;
        this.spinner.hide();
      }, 300);

      /*       this.drivers.data = result.elements;
      this.page = result.page; */

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
