import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { ControlItem } from '../../models/ControlItem.model';
import { ContentInterface } from './../../contracts/Content.interface';
import { ApiService } from '../../services/api.service';
import { ControlItemInterface } from '../../contracts/ControlItem.interface';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-control-item',
  templateUrl: './control-item.component.html',
  styleUrls: ['./control-item.component.scss']
})
export class ControlItemComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filter') filter: ElementRef;
  @ViewChild('typeFilter') typeFilter: ElementRef;

  public errorMessage;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[] = [
    'id',
    'nmNome',
    'cdCodigo',
    'cdNumeroRegistro',
    'ativo',
    'update',
    'delete',
  ];

  private service: ApiService;
  public controlitem: MatTableDataSource<ControlItemInterface>;

  constructor(private spinner: NgxSpinnerService, apiService: ApiService, private router: Router) {
    this.service = apiService;
    this.controlitem = new MatTableDataSource<ControlItemInterface>([]);
  }

  ngOnInit(): void {
    this.loadControlItens();
  }
  // public handleFilter = (value: string): void => {
  //   this.controlitem.filter = value.trim().toLocaleLowerCase();
  // };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadControlItens(pageIndex);
  };
  public handleFilter = (): void => {
    this.loadControlItens(
      0,
      this.typeFilter.nativeElement.value,
      this.filter.nativeElement.value
    );
  };

  public handleDelete = (id: string): void => {
    this.deleteControlItem(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/control-item/${id}`]);
  };

  ngAfterViewInit(): void {
    this.controlitem.sort = this.sort;
    this.controlitem.paginator = this.paginator;
  }

  public async deleteControlItem(id): Promise<void> {
    if (confirm('Tem certeza que deseja deletar esse registro?')) {
      try {
        await this.service.delete<ControlItem>(id, 'control-item');
        this.loadControlItens();
      } catch (exception) {
        this.errorMessage = exception.message;
        console.error(exception);
      }
    }
  }

  public async loadControlItens(pageIndex = 0, campo?, filter?): Promise<void> {
    this.spinner.show();
    const params = {
      size: this.pageSize,
    };
    if (pageIndex) {
      params['page'] = pageIndex;
    }
    if (campo && filter) {
      params[campo] = filter;
    }

    try {
      const result = await this.service.get<ContentInterface[]>({
        url: 'item-controle',
        params,
      });

      this.controlitem.data = result['content'];
      this.totalElements = result['totalElements'];
      this.totalPages = result['totalPages'];

      this.spinner.hide();
    } catch (error) {
      console.error('loadControlItens', error);
      this.spinner.hide();
    }
  }
}
