import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { ToolsService } from "./migrate/tools.service";

@Injectable({ providedIn: "root" })
export class UserService {
  private service: ApiService;

  constructor(apiService: ApiService, private tools: ToolsService) {
    this.service = apiService;
  }

  async login(username: string, senha: string): Promise<any> {
    const payload = {
      cdUsuario: username,
      cdSenha: senha,
    };

    try {
      const user = await this.service.post("usuario/login", payload);

      if (!user) {
        Promise.reject(Error("Invalid user"));
      }

      return user;
    } catch (error) {
      this.tools
        .builder()
        .openToast.emit(
          error.message ? error.message : "Desculpe, ocorreu algum erro!"
        );
    }
  }

  async me(username: string): Promise<any> {
    const params = {
      cdUsuario: username,
    };
    try {
      const user = await this.service.get<any[]>({
        url: "usuario/find",
        params,
      });

      if (!user) {
        Promise.reject(Error("Invalid user"));
      }

      return user;
    } catch (error) {
      console.error("me", error);
      return null;
    }
  }
}
