import { Validators } from '@angular/forms';

export class Validation {
  public rules = (): object => {
    return {
      nmNome: ['', Validators.required],
      nrDistanciaTolerada: ['', Validators.required],
      nrLimiteVelocidade: ['', Validators.required],
      flAtivoPontoControle: ['', Validators.required],
      empresa: ['', Validators.required],      
    };
  };
}
