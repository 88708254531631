import { FuncionalidadeInterface } from "../contracts/Funcionalidade.interface";
import { PerfilInterface } from "../contracts/Perfil.interface";
import { PerfilFuncionalidadeInterface } from "../contracts/PerfilFuncionalidade.interface";

export class PerfilFuncionalidade implements PerfilFuncionalidadeInterface {
  nomeConsultaApiBase = "perfil-funcionalidade";
  listConsultaApiBase = "perfil-funcionalidade/list";

  id: number;
  ativo: boolean;
  perfil?: PerfilInterface;
  funcionalidade?: FuncionalidadeInterface;
  flLeitura: boolean;
  flInsercao: boolean;
  flAtualizacao: boolean;
  flDelecao: boolean;
}
