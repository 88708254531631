import { Pipe, PipeTransform } from "@angular/core";
import { MESSAGES } from "./messages.traslate";

@Pipe({
  name: "translatePipe",
})
export class TranslatePipe implements PipeTransform {
  transform(message: String) {
    console.log(message);
    if (message == undefined) return;
    else return MESSAGES[message.toString()];
  }
}
