import { Validators } from '@angular/forms';

export class Validation {
    public rules = (): object => {
        return {
            nmPerfil: ['', Validators.required],
            dsPerfil: ['', Validators.required],
            perfilTipo: ['', Validators.required],
        };
    };
}
