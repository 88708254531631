import { Validators } from "@angular/forms";

export class Validation {
  public rules = (): object => {
    return {
      cdCodigo: ["", Validators.required],
      nmNome: ["", Validators.required],
      dsObservacao: [""],
      ativo: [true],
    };
  };
}
