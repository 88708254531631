import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-axle-two-simple-view",
  templateUrl: "./axle-two-simple-view.component.html",
  styleUrls: ["./axle-two-simple-view.component.scss"],
})
export class AxleTwoSimpleViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorTwoLeftSelect: any = [];
  public sensorTwoRightSelect: any = [];

  public sensorTwoRightCombo: any = [];
  public sensorTwoLeftCombo: any = [];

  public axisTwoSimpleLeftForm: FormGroup;
  public axisTwoSimpleRightForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisTwoSimpleLeftForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisTwoSimpleRightForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisTwoSimpleLeftForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 2 ESQ"
      );
      this.axisTwoSimpleRightForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 2 DIR"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo2Direito;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 38) {
              eixo2Direito = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 38) {
              eixo2Direito = filter;
            }
          }
        });
        var eixo2Esquerdo;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 37) {
              eixo2Esquerdo = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 37) {
              eixo2Esquerdo = filter;
            }
          }
        });

        // EIXO 2 DIREITO
        if (eixo2Direito != null) {
          this.axisTwoSimpleRightForm.controls["id"].setValue(eixo2Direito.id);

          if (eixo2Direito.sensor != null) {
            this.sensorTwoRightCombo.push(eixo2Direito.sensor);
            this.sensorTwoRightSelect = eixo2Direito.sensor.id;
          }

          if (eixo2Direito.pneu != null) {
            this.axisTwoSimpleRightForm.controls["pneu"].setValue(
              eixo2Direito.pneu.numeroFogo
            );
          }

          this.axisTwoSimpleRightForm.controls["nrPressaoMinima"].setValue(
            eixo2Direito.nrPressaoMinima
          );

          this.axisTwoSimpleRightForm.controls["nrPressaoMaxima"].setValue(
            eixo2Direito[0].nrPressaoMaxima
          );

          this.axisTwoSimpleRightForm.controls["nrTemperaturaMaxima"].setValue(
            eixo2Direito[0].nrTemperaturaMaxima
          );
        }

        // EIXO 2 ESQUERDO
        if (eixo2Esquerdo != null) {
          this.axisTwoSimpleLeftForm.controls["id"].setValue(eixo2Esquerdo.id);

          if (eixo2Esquerdo.sensor != null) {
            this.sensorTwoLeftCombo.push(eixo2Esquerdo.sensor);
            this.sensorTwoLeftSelect = eixo2Esquerdo.sensor.id;
          }

          if (eixo2Esquerdo.pneu != null) {
            this.axisTwoSimpleLeftForm.controls["pneu"].setValue(
              eixo2Esquerdo.pneu.numeroFogo
            );
          }

          this.axisTwoSimpleLeftForm.controls["nrPressaoMinima"].setValue(
            eixo2Esquerdo.nrPressaoMinima
          );

          this.axisTwoSimpleLeftForm.controls["nrPressaoMaxima"].setValue(
            eixo2Esquerdo.nrPressaoMaxima
          );

          this.axisTwoSimpleLeftForm.controls["nrTemperaturaMaxima"].setValue(
            eixo2Esquerdo.nrTemperaturaMaxima
          );
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
