import { Validators } from '@angular/forms';

export class Validation {
    public rules = (): object => {
        return {
            cdAtributo1: [''],
            cdAtributo2: [''],
            nmNomeIconeCss: [''],
            cdCodigo: ['', Validators.required],
            dsMensagem: ['', Validators.required],
            cdCor: [''],
            nmFator: [''],
            ativo: [false],
            flMarkerRelatorioGerencial: [false],
            flExibePainelEvento: [false],
            dsProcedimentoOperador: [''],
            nrOrdemPrioridade: [''],
        };
    };
}