<div class="main-page cadastros">
  <div class="container-fluid">
    <app-title>{{ title ? title : 'Inserir novo' }}</app-title>

    <!-- ITEM CARDS -->
    <div class="row mt-3 no-gutters p-4 row-form" *ngIf="!companyId || (companyId && !loader)">
      <div class="col-12">
        <form [formGroup]="driverForm" >
          <h2 class="my-3">{{ titleVar }}</h2>
          <div class="form-row">
            <div class="form-group col-md-12">
              <mat-form-field>
                <mat-label>{{ titleVar }}</mat-label>
                <mat-select formControlName="empresa" [(value)]="empresaSelected" [readonly]=true [disabled]=true>
                  <mat-option *ngFor="let empresa of companies" [value]="empresa.id">
                    {{ empresa.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <h2 class="mb-3">Dados cadastrais</h2>
          <div class="form-row">
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Matrícula</mat-label>
                <input formControlName="cdCodigo" matInput placeholder="Código" maxlength="5" minlength="5"  (keypress)="numberOnly($event)" required [readonly]=true [disabled]=true/>
              </mat-form-field>

              <validation-errors [form]="driverForm" controlLabel="Código" controlName="cdCodigo">
              </validation-errors>
            </div>
            <div class="form-group col-md-8">
              <mat-form-field appearance="outline">
                <mat-label>Nome</mat-label>
                <input formControlName="nmNome" matInput placeholder="Nome" maxlength="100" [readonly]=true [disabled]=true>
              </mat-form-field>

              <validation-errors [form]="driverForm" controlLabel="Nome" controlName="nmNome" [readonly]=true [disabled]=true>
              </validation-errors>
            </div>

            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>iButton</mat-label>
                <input formControlName="cdIbutton" matInput placeholder="iButton" maxlength="8" minlength="8"  [readonly]=true [disabled]=true>
              </mat-form-field>
              <validation-errors [form]="driverForm" controlLabel="iButton" controlName="cdIbutton" [readonly]=true [disabled]=true>
              </validation-errors>
            </div>

              <div class="form-group col-lg-2">
                  <mat-form-field appearance="outline">
                      <mat-label>RFID</mat-label>
                      <input formControlName="rfid" matInput placeholder="RFID" maxlength="11" minlength="11"  [readonly]=true [disabled]=true>
                  </mat-form-field>
                  <validation-errors [form]="driverForm" controlLabel="RFID" controlName="rfid" [readonly]=true [disabled]=true>
                  </validation-errors>
              </div>

            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Senha</mat-label>
                <input formControlName="cdSenha" matInput placeholder="Senha" maxlength="4" minlength="4"  (keypress)="numberOnly($event)" required [readonly]=true [disabled]=true/>
              </mat-form-field>

              <validation-errors [form]="driverForm" controlLabel="Senha" controlName="cdSenha" [readonly]=true [disabled]=true>
              </validation-errors>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Número de Registro</mat-label>
                <input formControlName="cdNumeroRegistro" matInput placeholder="Número de Registro" maxlength="50"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-2">
              <mat-form-field appearance="outline">
                <mat-label>Nascimento</mat-label>
                <input formControlName="dtNascimento" type="date" matInput placeholder="Data Nascimento" required  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>CPF</mat-label>
                <input formControlName="cdCpf" matInput placeholder="CPF"  (keypress)="numberOnly($event)" maxlength="11" required [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>RG</mat-label>
                <input formControlName="cdRG" matInput placeholder="RG" required [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Órgão Emissor</mat-label>
                <input formControlName="nmOrgaoEmissor" matInput placeholder="Orgão Emissor" required [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
          </div>
          <h2 class="mb-3">Habilitação</h2>
          <div class="form-row">
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Número CNH</mat-label>
                <input formControlName="cdNumeroCnh" matInput placeholder="Número CNH" maxlength="50"  (keypress)="numberOnly($event)" required [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Categoria CNH</mat-label>
                <input formControlName="cdCategoriaCnh" matInput placeholder="Categoria CNH" maxlength="10" required [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Validade CNH</mat-label>
                <input formControlName="dtValidadeCnh" type="date" matInput placeholder="Validade CNH" required [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-6">
              <mat-slide-toggle name="flAlarmeVencimentoCnhEmail" formControlName="flAlarmeVencimentoCnhEmail"
                color="primary" [readonly]=true [disabled]=true>Relatório Vencimento CNH Email
              </mat-slide-toggle>
            </div>

            <div class="form-group col-lg-6">
              <mat-slide-toggle name="flAlarmeVencimentoCnhSms" formControlName="flAlarmeVencimentoCnhSms"
                color="primary" [readonly]=true [disabled]=true>
                Relatório Vencimento CNH SMS
              </mat-slide-toggle>
            </div>

          </div>

          <h2 class="my-3">Endereço</h2>
          <div class="form-row">
            <div class="form-group col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>CEP</mat-label>
                <input formControlName="cdCep" matInput placeholder="CEP" maxlength="10"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-1">

            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-8">
              <mat-form-field appearance="outline">
                <mat-label>Logradouro</mat-label>
                <input formControlName="txEndereco" matInput placeholder="Logradouro" maxlength="80"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-1">
              <mat-form-field appearance="outline">
                <mat-label>Número</mat-label>
                <input formControlName="cdNumero" matInput placeholder="Número" maxlength="20"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Complemento</mat-label>
                <input formControlName="dsComplemento" matInput placeholder="Complemento" maxlength="40"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Bairro</mat-label>
                <input formControlName="nmBairro" matInput placeholder="Bairro" maxlength="100"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Cidade</mat-label>
                <input formControlName="nmCidade" matInput placeholder="Cidade" maxlength="100"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Estado</mat-label>
                <input formControlName="nmEstado" matInput placeholder="Estado" maxlength="100"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>País</mat-label>
                <input formControlName="nmPais" matInput placeholder="País" maxlength="100"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
          </div>
          <h2 class="my-3">Contato</h2>
          <div class="form-row">
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Telefone</mat-label>
                <input formControlName="nrTelefone" matInput placeholder="Telefone" maxlength="40"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>E-mail</mat-label>
                <input formControlName="dsEmail" matInput placeholder="E-mail" maxlength="80"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
          </div>

          <h2 class="my-3">Observações</h2>
          <div class="form-row">
            <div class="form-group col-lg-12">
              <mat-form-field appearance="outline">
                <mat-label>Observações</mat-label>
                <input formControlName="dsObservacao" matInput placeholder="Informe observações" maxlength="500"  [readonly]=true [disabled]=true/>
              </mat-form-field>
            </div>
          </div>

          <hr />

          <h2 class="my-3">Autorizações ANTT</h2>

          <div formArrayName="motoristaAutorizacaoAnttList">
            <div *ngFor="
                let item of motoristaAutorizacaoAnttItems.controls;
                let itemIndex = index
              " [formGroupName]="itemIndex">
              <input *ngIf="driverId" type="hidden" formControlName="autorizacaoAnttId" />

              <div class="form-row">
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo de autorização</mat-label>
                    <input formControlName="nmTipoAutorizacao" matInput placeholder="Tipo de autorização"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Número</mat-label>
                    <input formControlName="nrNumero" matInput placeholder="Número"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de cadastro </mat-label>
                    <input type="date" formControlName="dtCadastro" matInput placeholder="Data de cadastro"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de vencimento</mat-label>
                    <input type="date" formControlName="dtVencimento" matInput placeholder="Data de vencimento"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-lg-4">
                  <span>Gerar alerta de vencimento?</span>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" formControlName="flGerarAlertaVencimento" type="radio"
                      id="inlineAnttRadio1" [value]="true"  [readonly]=true [disabled]=true/>
                    <label class="form-check-label" for="inlineAnttRadio1">Sim</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="flGerarAlertaVencimento"
                      id="inlineAnttRadio2" [value]="false"  [readonly]=true [disabled]=true/>
                    <label class="form-check-label" for="inlineAnttRadio2">Não</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <h2 class="my-3">Outras Autorizações</h2>

          <div formArrayName="motoristaAutorizacaoList">
            <div *ngFor="
                let item of motoristaAutorizacaoItems.controls;
                let itemIndex = index
              " [formGroupName]="itemIndex">
              <input *ngIf="driverId" type="hidden" formControlName="autorizacaoId"  [readonly]=true [disabled]=true/>

              <div class="form-row">
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo de autorização</mat-label>
                    <input formControlName="nmTipoAutorizacao" matInput placeholder="Tipo de autorização"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Número</mat-label>
                    <input formControlName="nrNumero" matInput placeholder="Número"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de cadastro </mat-label>
                    <input type="date" formControlName="dtCadastro" matInput placeholder="Data de cadastro"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de vencimento</mat-label>
                    <input type="date" formControlName="dtVencimento" matInput placeholder="Data de vencimento"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-lg-4">
                  <span>Gerar alerta de vencimento?</span>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" formControlName="flGerarAlertaVencimento" type="radio"
                      id="inlineRadio1" [value]="true"  [readonly]=true [disabled]=true/>
                    <label class="form-check-label" for="inlineRadio1">Sim</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="flGerarAlertaVencimento"
                      id="inlineRadio2" [value]="false"  [readonly]=true [disabled]=true/>
                    <label class="form-check-label" for="inlineRadio2">Não</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <!-- <h2 class="my-3">Jornada de Trabalho</h2>

          <div formArrayName="motoristaJornadaTrabalhoList">
            <div *ngFor="
                let item of motoristaJornadaTrabalhoItems.controls;
                let itemIndex = index
              " [formGroupName]="itemIndex">
              <!-- <input
                type="hidden"
                formControlName="id"
                [value]="days[itemIndex].id"
              /> -->

              <!-- <div class="form-row">
                <div class="form-group col-lg-2">
                  <section class="checkbox">
                    <mat-checkbox formControlName="selected" [checked]="days[itemIndex].cdDiaSemana"
                      [value]="days[itemIndex].cdDiaSemana" [readonly]=true [disabled]=true>
                      {{ days[itemIndex].name }}
                    </mat-checkbox>
                  </section>
                </div>
                <div class="form-group col-lg-1">
                  <mat-label>De</mat-label>
                  <mat-form-field appearance="outline">
                    <input formControlName="cdHoraInicio" matInput type="time" placeholder="00:00"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-1">
                  <mat-label>às</mat-label>
                  <mat-form-field appearance="outline">
                    <input formControlName="cdHoraInicioIntervalo" matInput type="time" placeholder="00:00"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-1"></div>
                <div class="form-group col-lg-1">
                  <mat-label>Intervalo de</mat-label>
                  <mat-form-field appearance="outline">
                    <input formControlName="cdHoraFim" matInput type="time" placeholder="00:00"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-1">
                  <mat-label>às</mat-label>
                  <mat-form-field appearance="outline">
                    <input formControlName="cdHoraFimIntervalo" matInput type="time" placeholder="00:00"  [readonly]=true [disabled]=true/>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div> --> 
        </form>
      </div>
    </div>
  </div>
</div>
