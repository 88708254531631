import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { TerminalAlarme } from "../../models/TerminalAlarme.model";
import { ApiService } from "../../services/api.service";
import { TerminalAlarmeInterface } from "../../contracts/TerminalAlarme.interface";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { Page } from "src/app/utils/paginate";
import { EventService } from "src/app/services/migrate/Event.service";

@Component({
  selector: "app-terminal-alarme",
  templateUrl: "./terminal-alarme.component.html",
  styleUrls: ["./terminal-alarme.component.scss"],
})
export class TerminalAlarmeComponent implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('filter') filter: ElementRef;
  // @ViewChild('typeFilter') typeFilter: ElementRef;
  public filter;
  public typeFilter;

  public page = new Page();
  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 1000;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[] = [
    //'id', OCULTADO CAMPO ID DA TABELA - 20102020 - MARCOS TAVARES
    /*  'cdCodigo', */

    "dsMensagem",
    "ativo",
    "flMarkerRelatorioGerencial",
    "flExibePainelEvento",
    "nmFator",
    "update",
    "clone",
    "view",
    "delete",

    //'cdAtributo1',
    //'cdAtributo2',
    //'cdCor',
    //'nmNomeIconeCss',
  ];

  public filtros = [
    //{ campo: 'id', valor: 'ID' },
    { campo: "dsMensagem", valor: "Nome" },
    { campo: "cdCodigo", valor: "Codigo" },
  ];

  private service: ApiService;
  private serviceEvent: EventService;

  public terminalalarme: MatTableDataSource<TerminalAlarmeInterface>;

  constructor(
    apiServiceEvent: EventService,
    private spinner: NgxSpinnerService,
    apiService: ApiService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.service = apiService;
    this.serviceEvent = apiServiceEvent;
    this.terminalalarme = new MatTableDataSource<TerminalAlarmeInterface>([]);
  }

  ngOnInit(): void {
    this.loadTerminalAlarme();
  }
  //public handleFilter = (value: string): void => {
  //this.terminalalarme.filter = value.trim().toLocaleLowerCase();
  //};

  public handleSort = (sort): void => {
    this.loadTerminalAlarme(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadTerminalAlarme(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadTerminalAlarme();
  };

  public handleDelete = (id: string): void => {
    this.deleteTerminalAlarme(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/terminal-alarme/${id}`]);
  };

  ngAfterViewInit(): void {
    this.terminalalarme.sort = this.sort;
    this.terminalalarme.paginator = this.paginator;
  }

  public async deleteTerminalAlarme(id): Promise<void> {
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        await this.service.delete<TerminalAlarme>(id, "terminal-alarme");

        this.spinner.hide();

        this.loadTerminalAlarme();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.terminalalarme.filter = filterValue.trim().toLowerCase();

    if (this.terminalalarme.paginator) {
      this.terminalalarme.paginator.firstPage();
    }
  }

  public async loadTerminalAlarme(pageIndex = 0, sort?): Promise<void> {
    const params = {
      size: this.pageSize,
      page: pageIndex,
    };

    try {
      this.spinner.show();

      const result = await this.serviceEvent.get<any>({
        url: "/v2/events",
        params,
      });

      this.terminalalarme = new MatTableDataSource<any>(result.elements);

      setTimeout(() => {
        this.terminalalarme.sort = this.sort;
        this.terminalalarme.paginator = this.paginator;
        this.spinner.hide();
      }, 300);

      /* this.terminalalarme.data = result.elements;
      this.page = result.page; */

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
