import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HttpUtil {
  token: any;
  clientId: any;

  buildUrlOauth(uri: string): string {
    return environment.apisConfig.gateway.baseOauthUrl.concat(uri);
  }

  buildHeadersGateway(): HttpHeaders {
    let headers = new HttpHeaders();

    if (this.token !== "" && this.clientId !== "") {
      headers = headers.append("access_token", this.token);
      headers = headers.append("client_id", this.clientId);
    }

    headers = headers.set("Accept", "application/hal+json, application/json");

    return headers;
  }
}
