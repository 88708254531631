<div class="main-page cadastros" [ngClass]="this.ngClassStepper">
  <div class="container-fluid">
    <div class="box-title">
      <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

      <button routerLink="/perfil" class="button-box-title" type="button" mat-button>
        <a style="padding: 11px;">
           Consultar Perfis
        </a>
      </button> 
    </div>
    <br>
    <br>
    <mat-horizontal-stepper #stepper>

      <mat-step>
        <ng-template matStepLabel>Dados</ng-template>
          <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
              <form [formGroup]="perfilForm" (ngSubmit)="submitForm(stepper)">
                <h2 class="mb-3">Novo Perfil</h2>
                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Nome do Perfil</mat-label>
                      <input autofocus formControlName="nmPerfil" required matInput placeholder="Nome do Perfil"
                        maxlength="200" />
                    </mat-form-field>

                    <validation-errors [form]="perfilForm" controlLabel="Perfil" controlName="nmPerfil">
                    </validation-errors>
                  </div>
                  <div class="form-group col-lg-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Desc. Perfil</mat-label>
                      <input formControlName="dsPerfil" required matInput placeholder="Descrição do Perfil" maxlength="200" />
                    </mat-form-field>

                    <validation-errors [form]="perfilForm" controlLabel="Perfil" controlName="dsPerfil">
                    </validation-errors>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-lg-12">
                    <mat-form-field>
                      <mat-label>Tipo Perfil *</mat-label>

                      <mat-select formControlName="perfilTipo" [(value)]="perfilTipoSelected">
                        <mat-option *ngFor="let type of types" [value]="type.id">
                          {{ type.nmPerfilTipo }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="errorMessage" class="alert alert-danger">
                  {{ errorMessage }}
                </div>

                <div class="form-group mt-2 mb-0 text-center">
                  <back-button></back-button>
                  <button [disabled]="this.perfilForm.invalid" type="submit"
                    class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                    Salvar
                  </button>
                </div>
              </form>
            </div>
          </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Funcionalidade</ng-template>

          <div *ngIf="perfilId">
            <app-form-aba-funcionalidade [perfilId]="perfilId">
            </app-form-aba-funcionalidade>
          </div>

          <div *ngIf="!perfilId">
          <h5>
            <small>
              Crie um perfil para continuar
            </small> 
          </h5>
          </div>

      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Empresa</ng-template>

          <div *ngIf="perfilId">
            <app-form-aba-empresa  [perfilId]="perfilId">
            </app-form-aba-empresa>
          </div>

          <div *ngIf="!perfilId">
            <h5>
              <small>
                Crie um perfil para continuar
              </small> 
            </h5>
          </div>

      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
