import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-vinculo-dialog",
  templateUrl: "./vinculo-dialog.component.html",
  styleUrls: ["./vinculo-dialog.component.scss"],
})
export class VinculoDialogComponent implements OnInit {
  public dataValue = {};
  public dataValueOriginal = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>
  ) {
    this.dataValue = data.dataValue;
    this.dataValueOriginal = Object.assign({}, this.dataValue);
  }

  ngOnInit() {}

  onCancel = (): void => this.dialogRef.close(this.dataValueOriginal);

  onSave = (): void => this.dialogRef.close(this.dataValue);
}
