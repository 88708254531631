<div class="toolbox_add_marker"
     *ngIf="this.permissionService.hasPermission('PONTO_BOTAO_FUNCAO', this.permissions)">
  <mat-button-toggle-group
    name="add_marker"
    [(ngModel)]="action"
    (change)="toggleView($event)"
  >
    <mat-button-toggle value="add_marker">
      <mat-icon>add_location</mat-icon>
      <span class="btn-icon-text">Ponto</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
