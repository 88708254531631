<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])"
                        data-title="Voltar"><i class="fas fa-arrow-left"></i></a>
                    Consultar {{title}}
                </h1>
            </div>
        </div>

        <div *ngIf="retornoEntidades?.data?.length === 0">
            <div class="row no-gutters mt-3">
                <p>Nenhum registro at&eacute; o momento...</p>
            </div>
        </div>

        <div class="row no-gutters mt-3" *ngIf="retornoEntidades?.data?.length > 0">
            <div class="mat-elevation table-content">
                <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field fxFlex="40%" class="ml-2">
                        <mat-label>Filtrar</mat-label>
                        <input matInput autocomplete="off" (keyup)="this.applyFilter($event)" placeholder="" #input>
                    </mat-form-field>
                </div>

                <table mat-table [dataSource]="retornoEntidades" matSort class="mat-elevation-z8">

                    <item *ngFor="let item of displayedColumns" [item]="item">
                        <ng-container matColumnDef={{item}}>
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{item | translatePipe}}</th>
                            <td mat-cell *matCellDef="let element">{{ element[item] }}</td>
                        </ng-container>
                    </item>

                    <!-- *ngIf="element.possuiTpms"     -->
                    <ng-container matColumnDef="sendTpms" *ngIf="this.title == 'Veículos'">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Enviar dados de TPMS ao Terminal"
                                *ngIf="element.possuiTpms" matTooltip="Enviar dados de TPMS ao Terminal">
                                <a (click)="handSendTpms(element.id)" class="btn btn-link">
                                    <img height="40" src="assets/img/icon-configurar.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="odometer" *ngIf="this.title == 'Veículos'">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Enviar dados de odômetro ao Terminal"
                                *ngIf="element.terminalProtocoloId == 39 || element.terminalProtocoloId == 40"
                                matTooltip="Enviar comando de odômetro ao Terminal">
                                <a (click)="handleSentOdometer(element)" class="btn btn-link">
                                    <img height="30" src="assets/img/gauge.png" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="update" *ngIf="this.title == 'Empresas'">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Editar"
                                *ngIf="this.permissionService.hasPermission(this.objetoBase['permissaoVisualizacao'], this.permissions)"
                                matTooltip="Editar">
                                <a (click)="this.router.navigate(['/' + this.objetoBase['enderecoRoute'] + '/edit/' + element.id + '&' + element.cnpj])"
                                    class="btn btn-link">
                                    <img height="20" src="assets/img/edit.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="update" *ngIf="this.title != 'Empresas'">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Editar"
                                *ngIf="this.permissionService.hasPermission(this.objetoBase['permissaoVisualizacao'], this.permissions)"
                                matTooltip="Editar">
                                <a (click)="this.router.navigate(['/' + this.objetoBase['enderecoRoute'] + '/edit/' + element.id ])"
                                    class="btn btn-link">
                                    <img height="20" src="assets/img/edit.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="clone" *ngIf="this.title == 'Empresas'">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Duplicar"
                                *ngIf="this.permissionService.hasPermission(this.objetoBase['permissaoVisualizacao'], this.permissions)"
                                matTooltip="Duplicar">
                                <a (click)="this.router.navigate(['/' + this.objetoBase['enderecoRoute'] + '/clone/' + element.id + '&' + element.cnpj ])"
                                    class="btn btn-link">
                                    <img height="20" src="assets/img/duplic.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="clone" *ngIf="this.title != 'Empresas'">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Duplicar"
                                *ngIf="this.permissionService.hasPermission(this.objetoBase['permissaoVisualizacao'], this.permissions)"
                                matTooltip="Duplicar">
                                <a (click)="this.router.navigate(['/' + this.objetoBase['enderecoRoute'] + '/clone/' + element.id])"
                                    class="btn btn-link">
                                    <img height="20" src="assets/img/duplic.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="view" *ngIf="this.title == 'Empresas'">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Visualizar"
                                *ngIf="this.permissionService.hasPermission(this.objetoBase['permissaoVisualizacao'], this.permissions)"
                                matTooltip="Visualizar">
                                <a (click)="this.router.navigate(['/' + this.objetoBase['enderecoRoute'] + '/view/' + element.id + '&' + element.cnpj])"
                                    class="btn btn-link">
                                    <img height="20" src="assets/img/view.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="view" *ngIf="this.title != 'Empresas'">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Visualizar"
                                *ngIf="this.permissionService.hasPermission(this.objetoBase['permissaoVisualizacao'], this.permissions)"
                                matTooltip="Visualizar">
                                <a (click)="this.router.navigate(['/' + this.objetoBase['enderecoRoute'] + '/view/' + element.id])"
                                    class="btn btn-link">
                                    <img height="20" src="assets/img/view.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="handleDelete(element)" class="btn btn-link"
                                *ngIf="this.permissionService.hasPermission(this.objetoBase['permissaoDelecao'], this.permissions)"
                                matTooltip="Apagar">
                                <img height="20" src="assets/img/trash-cadastro.svg" alt="" />
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsFull"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsFull"></tr>

                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 40, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>