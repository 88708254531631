<div [ngStyle]="{'top': this.toolboxControl.positionBarPx ? '5px' : '66px'}" class="toolbox">
  <toolbox-zoom></toolbox-zoom>
  <toolbox-search></toolbox-search>
  <app-button-general-filter></app-button-general-filter>
  <toolbox-button-distance></toolbox-button-distance>
  <toolbox-proximity></toolbox-proximity>
  <toolbox-select></toolbox-select>
  <toolbox-button-path></toolbox-button-path>
  <toolbox-add-marker></toolbox-add-marker>
  <toolbox-button-plate></toolbox-button-plate>
  <toolbox-electronic-fence></toolbox-electronic-fence>
  <toolbox-route></toolbox-route>
</div>
