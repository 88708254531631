import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapToolboxService {
  public toolboxCommand$ = new BehaviorSubject<any>(null);
  currentToolboxCommand = this.toolboxCommand$.asObservable();

  public Command$ = new BehaviorSubject<any>(null);
  currentCommand = this.Command$.asObservable();

  setToolboxCommand(action: string): void {
    this.toolboxCommand$.next(action);
  }
  setCommand(command: any): void {
    this.Command$.next(command);
  }
}
