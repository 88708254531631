import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CompanyInterface } from "src/app/contracts/Company.interface";
import { PerfilEmpresaInterface } from "src/app/contracts/PerfilEmpresa.interface";
import { PerfilEmpresa } from "src/app/models/PerfilEmpresa.model";
import { CoreService } from "src/app/services/migrate/api.core.service";
import { Page } from "src/app/utils/paginate";
import storageX from "src/app/storageCore/storageX";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-form-aba-empresa",
  templateUrl: "./form-aba-empresa.component.html",
  styleUrls: ["./form-aba-empresa.component.scss"],
})
export class FormAbaEmpresaComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("filter") filter: ElementRef;
  @ViewChild("typeFilter") typeFilter: ElementRef;

  @Input()
  public perfilId: number;
  public hideMasterToggle = false;

  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 5000;
  public totalElements = 0;
  public totalPages = 0;
  public page = new Page();

  public displayedColumns: string[] = ["id", "nmNome", "vincular"];

  private apiBaseService: ApiBaseService;
  private coreService: CoreService;
  public empresa: MatTableDataSource<CompanyInterface>;
  public perfilEmpresas: PerfilEmpresaInterface[];

  constructor(apiCoreService: CoreService, apiBaseService: ApiBaseService) {
    this.apiBaseService = apiBaseService;
    this.coreService = apiCoreService;
    this.empresa = new MatTableDataSource<any>([]);
  }

  ngOnInit(): void {
    this.findPerfilEmpresas();
    this.loadData();
  }

  async findPerfilEmpresas(): Promise<void> {
    const perfilId = this.perfilId;

    const params = {
      perfilId,
    };

    this.perfilEmpresas = await this.apiBaseService.get<any>({
      url: new PerfilEmpresa()["listConsultaApiBase"],
      params,
    });

    this.perfilEmpresas.forEach((empresas) => {
      if (empresas.divisao != null) {
        var teste: CompanyInterface = {
          id: empresas.divisao.id,
          nmNome: empresas.divisao.nmNome,
          nmRazaoSocial: null,
          cdCnpj: null,
          cdInscricaoEstadual: null,
          cdInscricaoMunicipal: null,
          empresaMatriz: null,
          empresaTipo: null,
          nmContato: empresas.divisao.nmContato,
          nrFax: empresas.divisao.nrFax,
          nrTelefone: empresas.divisao.nrTelefone,
          dsEmail: empresas.divisao.dsEmail,
          cdCep: empresas.divisao.cdCep,
          txEndereco: empresas.divisao.txEndereco,
          cdNumero: empresas.divisao.cdNumero,
          dsComplemento: empresas.divisao.dsComplemento,
          nmBairro: empresas.divisao.nmBairro,
          nmCidade: empresas.divisao.nmCidade,
          nmEstado: empresas.divisao.nmEstado,
          nmPais: empresas.divisao.nmPais,
          dsSegmento: null,
          txObservacao: empresas.divisao.txObservacao,
          nrCreditoSms: null,
        };

        empresas.empresa = teste;
      }
    });
  }

  public hasVinculo(row): boolean {
    return this.perfilEmpresas?.some(
      (pf) => pf.empresa.id === row.id && pf.ativo
    );
  }

  public onMasterToggleChange = (e): void => {
    this.empresa.data.forEach((func) => {
      this.updateData(func, e.checked);
    });
  };

  public handleMasterToggle = (): boolean => {
    return this.empresa.data.every((func) => this.hasVinculo(func));
  };

  public onCheckboxChange = (e, row): void => {
    this.updateData(row, e.checked);
  };

  public isVinculosDisabled = (row): boolean => {
    return this.loader;
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadData(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadData(
      0,
      this.typeFilter.nativeElement.value,
      this.filter.nativeElement.value
    );
  };

  private async updateData(data, check) {
    this.loader = true;
    const vinculo = this.perfilEmpresas.find((pf) => pf.empresa.id === data.id);
    console.log(this.perfilEmpresas);

    const post = {
      ativo: check,
      perfilId: this.perfilId,
    };

    if (vinculo) {
      vinculo.ativo = check;
      await this.apiBaseService.patch<PerfilEmpresa>(
        vinculo.id,
        new PerfilEmpresa()["nomeConsultaApiBase"],
        post
      );
    } else {
      var perfilEmpresa;
      var novoperfilEmpresa;

      if (data.cnpj != null) {
        perfilEmpresa = {
          ativo: true,
          perfil: { id: this.perfilId },
          empresa: { id: data.id },
          idUsuarioCriacao: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()
            ?.id,
        };

        novoperfilEmpresa = await this.apiBaseService.post<PerfilEmpresa>(
          new PerfilEmpresa()["nomeConsultaApiBase"],
          perfilEmpresa
        );
        this.perfilEmpresas.push(novoperfilEmpresa);
      } else {
        perfilEmpresa = {
          ativo: true,
          perfil: { id: this.perfilId },
          divisao: { id: data.id },
          idUsuarioCriacao: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()
            ?.id,
        };

        novoperfilEmpresa = await this.apiBaseService.post<PerfilEmpresa>(
          new PerfilEmpresa()["nomeConsultaApiBase"],
          perfilEmpresa
        );
        this.perfilEmpresas;
        this.perfilEmpresas.push(novoperfilEmpresa);
      }
    }
    this.loader = false;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.empresa.filter = filterValue.trim().toLowerCase();

    if (this.empresa.paginator) {
      this.empresa.paginator.firstPage();
    }
  }

  public async loadData(pageIndex = 0, campo?, filter?): Promise<void> {
    this.loader = true;

    const params = {
      size: this.pageSize,
      profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
    };
    if (pageIndex) {
      params["page"] = pageIndex;
    }

    try {
      const result = await this.coreService.get<any>({
        url: "v2/companys",
        params,
      });

      this.empresa = new MatTableDataSource<any>(result.elements);

      setTimeout(() => {
        this.empresa.sort = this.sort;
        this.empresa.paginator = this.paginator;
      }, 300);

      this.loader = false;
    } catch (error) {
      console.error("loadPerfil", error);
      this.loader = false;
    }
  }
}
