import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { ApiService } from "../../services/api.service";
import { TerminalInterface } from "../../contracts/Terminal.interface";
import { PermissionsService } from "src/app/helpers/permissions.service";
import storageX from "src/app/storageCore/storageX";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import { Page } from "src/app/utils/paginate";
import { CoreService } from "src/app/services/migrate/api.core.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-terminal",
  templateUrl: "./terminal.component.html",
  styleUrls: ["./terminal.component.scss"],
})
export class TerminalComponentTerminal implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('filter') filter: ElementRef;
  // @ViewChild('typeFilter') typeFilter: ElementRef;
  public filter;
  public typeFilter;

  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 5000;
  public totalElements = 0;
  public totalPages = 0;
  public page = new Page();

  public displayedColumns: string[] = [
    "id",
    "nmempresa",
    "cdNumeroTerminal",
    "cdSim",
    "nmprotocolo",
    "cdPlaca",
    "update",
    "clone",
    "view",
    "delete",

    // 'cdPlaca',
    // 'dsEmail',
    //'cdImei',
    //'cdImsi',
    //'cdSenha',
    //'dsObservacao',
    //'dtConectado',
    //'dtInstalado',
    //'flPossuiFuncaoMotorista',
    //'nmNomeProprietario',
    //'nrTelefoneCentral',
    //'nrTelefoneProprietario',
    //'terminalProtocolo',
    //'terminalIcone',
  ];

  public filtros = [
    { campo: "id", valor: "ID" },
    { campo: "empresa.nmNome", valor: "Empresa" },
    { campo: "cdSim", valor: "SIM" },
    { campo: "cdNumeroTerminal", valor: "Numero Terminal" },
    //{ campo: 'terminalProtocolo', valor: 'Modelo' },
  ];

  private service: ApiService;
  private coreService: CoreService;
  public terminal: MatTableDataSource<TerminalInterface>;

  constructor(
    apiCoreService: CoreService,
    apiService: ApiService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.service = apiService;
    this.coreService = apiCoreService;

    // this.terminal = new MatTableDataSource<TerminalInterface>([]);

    this.loadTerminal();
  }

  ngOnInit(): void {}
  //public handleFilter = (value: string): void => {
  //this.terminal.filter = value.trim().toLocaleLowerCase();
  //};

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.terminal.filter = filterValue.trim().toLowerCase();

    if (this.terminal.paginator) {
      this.terminal.paginator.firstPage();
    }
  }

  public handleSort = (sort): void => {
    this.loadTerminal(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadTerminal(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadTerminal();
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/terminal/${id}`]);
  };

  ngAfterViewInit(): void {}

  public async deleteTerminal(element): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Excluir Terminal",
      html: `<h6>Tem certeza que você deseja excluir o terminal
       <small><b> Nº ${element?.cdNumeroTerminal}</b></small> no veículo placa <small><b>${element.cdPlaca}</b></small>? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.onDelete(element.id);
      }
    });
  }

  private async onDelete(id) {
    const uderId = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;

    try {
      this.spinner.show();

      await this.coreService.delete<any>(
        `${id}?userId=${uderId}`,
        "v2/terminals"
      );

      this.spinner.hide();

      this.loadTerminal();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public async loadTerminal(pageIndex = 0, sort?): Promise<void> {
    const params = {
      size: this.pageSize,
      page: pageIndex,
      sort: "dhUltimaAlteracao",
      sortDirections: "DESC",
      profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      userId: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
    };

    try {
      this.spinner.show();

      const result = await this.coreService.get<any>({
        url: "/v2/terminals/find-terminal",
        params,
      });

      this.terminal = new MatTableDataSource<TerminalInterface>(
        result.elements
      );

      setTimeout(() => {
        this.terminal.sort = this.sort;
        this.terminal.paginator = this.paginator;
      }, 300);

      /*       this.terminal.data = result.elements;
       this.page = result.page; */

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
