<div class="main-page cadastros">
  <div class="container-fluid">
    <app-title>Tratativa Operador</app-title>

    <td>
      <span class="alerta mr-2" data-title="Visualizar" matTooltip="Visualizar" (click)="openReport()">
        <a class="btn btn-link">
          <h6>Visualizar Relatorio</h6>
        </a>
      </span>
    </td>

    <div class="row mt-3 no-gutters p-4 row-form">
      <div class="col-12">
        <form [formGroup]="reportOperationalForm" (ngSubmit)="submitForm()">

          <h2 class="my-3">Dados da tratativa</h2>

          <div formArrayName="operatorHandlingList">
            <div *ngFor="let item of operatorHandlingItems.controls; 
                              let itemIndex = index" [formGroupName]="itemIndex">
              <input *ngIf="veiculoId" type="hidden" formControlName="id" />

              <div class="form-row">
                <div class="form-group col-lg-2">
                  <mat-form-field appearance="outline">
                    <mat-label>Tratativa Operador</mat-label>
                    <mat-select formControlName="treatment" required>
                      <mat-option *ngFor="let type of treatmentOperators[0]" [value]="type.uuid">
                        {{ type.treatment_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-5">
                  <mat-form-field appearance="outline">
                    <mat-label>Comentário</mat-label>
                    <textarea formControlName="operatorComment" required matInput placeholder="Comentário"></textarea>
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-2">
                  <mat-form-field appearance="outline">
                    <mat-label>UF</mat-label>
                    <input formControlName="state" required matInput placeholder="Estado">
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Cidade</mat-label>
                    <input formControlName="city" required matInput placeholder="Cidade">
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3" style="margin-top: 15px;">
                  <input type="file" (change)="onImageChange($event, itemIndex)" accept="image/*" />
                  <div
                    *ngIf="reportOperationalForm.get('operatorHandlingList').get(itemIndex.toString()).get('image').hasError('invalidImageFormat')">
                    Formato de imagem inválido. Por favor, escolha uma imagem válida.
                  </div>
                  <div
                    *ngIf="reportOperationalForm.get('operatorHandlingList').get(itemIndex.toString()).get('image').hasError('invalidImageSize')">
                    Tamanho da imagem excede o limite. Por favor, escolha uma imagem menor.
                  </div>
                </div>
              </div>


              <div class="form-row">
                <div class="form-group col-lg-6 text-left text-lg-right">
                  <a (click)="deleteSensorItem(itemIndex)">Excluir
                    <img src="assets/img/trash.svg" class="mb-1" height="20" alt="" /></a>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-12 text-center">
            <a style="cursor: pointer;" class="repeat-line" (click)="addSensorItem()">
              <img src="assets/img/plus.svg" class="mb-0" height="20" alt="" />
              Adicionar</a>
          </div>


          <div class="form-group mt-2 mb-0 text-center">
            <back-button></back-button>
            <button type="submit" [disabled]="!reportOperationalForm.valid"
              class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
              Gerar Tratativa
            </button>
          </div>

        </form>
      </div>
    </div>

  </div>
</div>