import { ToolsService } from "src/app/services/migrate/tools.service";

import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { MatTableDataSource } from "@angular/material/table";
import { DatePipe } from "@angular/common";
import { MinioService } from "src/app/services/minio.service";
@Component({
  selector: "app-supervisor-view-treat",
  templateUrl: "./supervisor-view-treat.component.html",
  styleUrls: ["./supervisor-view-treat.component.scss"],
})
export class SupervisorViewTreatComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public minioService: MinioService;
  public datePipe: DatePipe;

  public dataSource: MatTableDataSource<any>;

  public analyzeReportUuid = "";
  public tableColumns: string[];

  public errorMessage;

  ngOnInit(): void {
    this.analyzeReportUuid = this.activatedRoute.snapshot.params.uuid;
    this.loadTableDefinitions();
    this.loadDataElements();
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "treatmentDate",
      "operatorDealings",
      "nameOperator",
      "descriptionAnalyze",
      "state",
      "city",
      "minioObject",
    ];
  }

  exportFile(element): void {
    this.minioService.exportReport(element).subscribe();
  }

  formatDate(dateString: string): string {
    // Converte a string de data para um objeto Date
    const date = new Date(dateString);

    // Formata a data usando o DatePipe
    return this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
  }

  constructor(
    datePipe: DatePipe,
    private tools: ToolsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    analyzeTripService: AnalyzeTripService,
    minioService: MinioService
  ) {
    this.datePipe = datePipe;
    this.analyzeTripService = analyzeTripService;
    this.minioService = minioService;
  }

  public async loadDataElements() {
    this.spinner.show();
    try {
      this.analyzeTripService
        .getOperatorTreat(this.analyzeReportUuid, "false")
        .subscribe((t) => {
          this.dataSource = t;
        });

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }
}
