<div class="main-page consultas">
    <div class="container-fluid">
        <!-- LINHA TITULO + PESQUISA -->

        <div fxLayout fxLayoutAlign="center center">
            <mat-form-field fxFlex="40%" class="ml-2">
             <mat-label>Filtrar por Nome</mat-label>
             <input matInput autocomplete="off"
             (keyup)="this.applyFilter($event)" style="text-transform: uppercase;" placeholder="" #input>
           </mat-form-field>
   
        </div>  

        <div class="row no-gutters mt-3" *ngIf="routes.data.length > 0">
            <div class="mat-elevation table-content">                
                <table mat-table [dataSource]="routes" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                    </ng-container>

                    <ng-container matColumnDef="nmNome">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Nome
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.nmNome }}
                        </td>
                    </ng-container>                  

                    <ng-container matColumnDef="empresa.nmNome">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Empresa
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.empresa.nmNome }}
                        </td>
                    </ng-container>    
                    
                    <ng-container matColumnDef="nrLimiteVelocidade">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Veloc.
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.nrLimiteVelocidade }}
                        </td>
                    </ng-container>    

                    <ng-container matColumnDef="nrDistanciaTolerada">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Dist.(m)
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.nrDistanciaTolerada }}
                        </td>
                    </ng-container>    
                    
                    <ng-container matColumnDef="flAtivoPontoControle">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Gera Alarme
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.flAtivoPontoControle ? "Sim" : "Não" }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Visualizar" matTooltip="Visualizar">
                                <a
                                (click)="this.router.navigate(['/route-view/' + element.id])"
                                class="btn btn-link"
                                >
                                <img height="20" src="assets/img/view.svg" alt="" />
                                </a>
                            </span>
                            
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="update">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Editar"
                                *ngIf="this.permissionService.hasPermission('ROUTE_ATUALIZACAO_FUNCIONALIDADE', this.permissions)" matTooltip="Editar">
                                <a (click)="this.router.navigate(['/routes/edit/' + element.id])" class="btn btn-link">
                                    <img height="20" src="assets/img/edit.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="handleDelete(element.id)" class="btn btn-link"
                                *ngIf="this.permissionService.hasPermission('ROUTE_DELECAO_FUNCIONALIDADE', this.permissions)" matTooltip="Apagar">
                                <img height="20" src="assets/img/trash-cadastro.svg" alt="" />
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <mat-paginator *ngIf="routes.data.length" [pageSize]="this.page.size" (page)="handlePageChanged($event)"
                    [length]="this.page.totalElements" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>
