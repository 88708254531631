 import { STORAGE_KEY_KEEP_LOGGEDIN } from './constStorageKeys';
import TypeStorage from './typeStorage'

 class KeepLoggedIn{

    public keepLoggedIn( keepLoggedIn: boolean ): void{

         if(keepLoggedIn){

            TypeStorage.loadStorage( keepLoggedIn );

            localStorage.setItem( STORAGE_KEY_KEEP_LOGGEDIN, String(keepLoggedIn) );

         }else{
            localStorage.removeItem( STORAGE_KEY_KEEP_LOGGEDIN );

            TypeStorage.loadStorage( false );

         }
    }

    public statusKeepLoggedIn(): boolean{
       
        const value = localStorage.getItem( STORAGE_KEY_KEEP_LOGGEDIN );
        
        if(!value){
           return false;
        }

         return true;
    }
}

export default new KeepLoggedIn();

