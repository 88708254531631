<div class="main-page cockpit">
  <div class="container-fluid">   
    <div class="row align-items-center">
      <div class="col-12 my-2">
        <h1>
          <a class="btn btn-voltar text-center mr-2" routerLink="/painel-evento" data-title="Voltar">
            <i class="fas fa-arrow-left"></i>
          </a>
        </h1>
      </div>
    </div>

    <!-- <p-menubar #menu class="p-menubar" [model]="items"></p-menubar> -->
    
    <!-- <table mat-table id="table" [dataSource]="this.listResultado" class="mat-elevation-z8" style="width: 100%" tableStyleClass="table" >  
      <ng-container matColumnDef="motorista">
        <th mat-header-cell *matHeaderCellDef> Motorista </th>
        <td mat-cell *matCellDef="let element">         
          {{element.nmMotorista}} 
        </td>
      </ng-container>

      <ng-container matColumnDef="empresa" >
        <th mat-header-cell *matHeaderCellDef> Empresa </th>
        <td mat-cell *matCellDef="let element">         
          {{element.nomeEmpresa}} 
        </td>
      </ng-container>

            
      <ng-container matColumnDef="placa">
        <th mat-header-cell *matHeaderCellDef> Placa </th>
        <td mat-cell *matCellDef="let element">
          {{element.placa}}
        </td>
      </ng-container>

      <ng-container matColumnDef="dtHrUltimaMensagem">
        <th mat-header-cell *matHeaderCellDef> Data Ultima Mensagem </th>
        <td mat-cell *matCellDef="let element"
         >
          {{element.dataOnline}}
        </td>
      </ng-container>

      <ng-container matColumnDef="dtHrUltimaLocalizacao">
        <th mat-header-cell *matHeaderCellDef> Data da posição </th>
        <td mat-cell *matCellDef="let element"
        >
          {{element.dataAtualizacao}}
        </td>
      </ng-container>

      <ng-container matColumnDef="ultimaLocalizacao">
        <th mat-header-cell *matHeaderCellDef> Ultima Localização </th>
        <td mat-cell *matCellDef="let element">
          <span >
            <a class="btn btn-link">
                <img (click)="this.openMap(element.latitude, element.longitude)" src="assets/img/view.svg" alt="" />
            </a>
          </span>
        </td>
      </ng-container>          

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
          {{this.status == 0 ? "Offline" : "Online"}}
        </td>
      </ng-container>
     
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>    -->

    <p-menubar #menu [model]="items"></p-menubar>     
    
    <p-table #journeyheader [value]="this.resultadoAnalitico" tableStyleClass="tableAnalitico">    
      <ng-template pTemplate="header" let-columns>            
          <tr [hidden]="this.resultadoAnalitico.length == 0" >
              <th style="text-align:center !important;border:none">Motorista</th>
              <th style="text-align:center !important;border:none">Empresa</th>
              <th style="text-align:center !important;border:none">Placa</th>
              <th style="text-align:center !important;border:none">Data Ultima Mensagem</th>
              <th style="text-align:center !important;border:none">Data da posição</th>
              <th style="text-align:center !important;border:none">Status</th>
              <th style="text-align:center !important;border:none">Ultima Localização</th>    
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowdata>          
          <tr>
              <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["nmMotorista"]}}</td>
              <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["nomeEmpresa"]}}</td>
              <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["placa"]}}</td>
              <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["dataOnline"]}}</td>
              <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["dataAtualizacao"]}}</td>
              <td *ngIf="this.status == 0" style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{'Offline'}}</td>
              <td *ngIf="this.status == 1" style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{'Online'}}</td>
              <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">                   
                  <span class="alerta mr-2" data-title="Visualizar" matTooltip="Visualizar">
                      <a class="btn btn-link">
                          <img (click)="this.openMap(rowdata['latitude'], rowdata['longitude'])" height="15" src="assets/img/view.svg" alt="" />
                      </a>
                  </span>
              </td>            
            </tr>    
      </ng-template>
  </p-table>


  </div>
</div>
