import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToolsService } from "../services/migrate/tools.service";
import { STORAGE_KEY_ACCESS_TOKEN_WSO2 } from "../storageCore/constStorageKeys";
import storageX from "../storageCore/storageX";
import { PageableTerminal } from "./model/ListaTerminal";

const API = "/ms-setera-api/v1/terminal-mensagem-processada/lista";

@Injectable({ providedIn: "root" })
export class ListaTerminalService {
  private header = new HttpHeaders();

  constructor(private http: HttpClient, private tools: ToolsService) {
    this.header = this.header.set(
      "Content-Type",
      "application/json; charset=utf-8"
    );
    this.header = this.header.set(
      "Authorization",
      "Bearer " + storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get()
    );
  }

  public getMensagensTerminais(page: Number) {
    let params = new HttpParams();
    params = params.append("page", page.toString());
    params = params.append("size", "20");
    params = params.append("idTerminal", "0");

    return this.http.get<PageableTerminal>(
      this.tools.builder().api.getApiUrl().api + API,
      { params: params, headers: this.header }
    );
  }

  public getMensagensTerminaisByMensagem(page: Number, mensagem: string) {
    let params = new HttpParams();
    params = params.append("page", page.toString());
    params = params.append("size", "20");
    params = params.append("idTerminal", "0");
    params = params.append("mensagem", mensagem);

    return this.http.get<PageableTerminal>(
      this.tools.builder().api.getApiUrl().api + API,
      { params: params, headers: this.header }
    );
  }

  public getMensagensTerminaisByIdTerminal(page: Number, idTerminal: Number) {
    let params = new HttpParams();
    params = params.append("page", page.toString());
    params = params.append("size", "20");
    params = params.append("idTerminal", idTerminal.toString());
    params = params.append("buscaById", "true");

    return this.http.get<PageableTerminal>(
      this.tools.builder().api.getApiUrl().api + API,
      { params: params, headers: this.header }
    );
  }

  public getMensagensTerminaisByMensagemAndIdTerminal(
    page: Number,
    idTerminal: Number,
    mensagem: string
  ) {
    let params = new HttpParams();
    params = params.append("page", page.toString());
    params = params.append("size", "20");
    params = params.append("idTerminal", idTerminal.toString());
    params = params.append("buscaById", "true");
    params = params.append("mensagem", mensagem);

    return this.http.get<PageableTerminal>(
      this.tools.builder().api.getApiUrl().api + API,
      { params: params, headers: this.header }
    );
  }
}
