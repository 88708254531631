import { STORAGE_KEY_ACCESS_TOKEN_WSO2 } from "./../../storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, timeout } from "rxjs/operators";
import { OAuthService } from "./o-auth.service";

@Injectable({
  providedIn: "root",
})
export class ApiHttpCService extends OAuthService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public get<T>(url: string, params?: any): Observable<T> {
    return this.httpClient
      .get<T>(`${url}`, {
        params: this.handleParams(params),
        headers: this.buidProvisoryHeader(),
      })
      .pipe(
        timeout(500000),
        map((r) => {
          return r;
        })
      );
  }

  public getById<T>(url: string, params?: any): Observable<T> {
    return this.httpClient
      .get<T>(`${url}/${url}`, {
        params: this.handleParams(params),
        headers: this.buidProvisoryHeader(),
      })
      .pipe(
        map((r) => {
          return r;
        })
      );
  }

  public post<T>(url: string, payload: any, params?: any): Observable<T> {
    console.log(url, payload, params);

    return this.httpClient
      .post<T>(`${url}`, payload, {
        params: this.handleParams(params),
        headers: this.buidProvisoryHeader(),
      })
      .pipe(
        map((r) => {
          return r;
        })
      );
  }

  public patch<T>(url: string, payload, params?: any): Observable<T> {
    return this.httpClient
      .patch<T>(`${url}`, payload, {
        params: this.handleParams(params),
        headers: this.buidProvisoryHeader(),
      })
      .pipe(
        map((r) => {
          return r;
        })
      );
  }

  public put<T>(url: string, payload, params?: any): Observable<T> {
    return this.httpClient
      .put<T>(`${url}`, payload, {
        params: this.handleParams(params),
        headers: this.buidProvisoryHeader(),
      })
      .pipe(
        map((r) => {
          return r;
        })
      );
  }

  public delete<T>(url: string, params?: any): Observable<T> {
    return this.httpClient
      .delete<T>(`${url}`, {
        params: this.handleParams(params),
        headers: this.buidProvisoryHeader(),
      })
      .pipe(
        map((r) => {
          return r;
        })
      );
  }

  public search(url: string, params?: any): Observable<any> {
    return this.httpClient
      .get<any>(`${url}`, {
        params: this.handleParams(params),
        headers: this.buidProvisoryHeader(),
      })
      .pipe(
        map((r) => {
          return r;
        })
      )
      .pipe(
        map((r) => {
          return r;
        })
      );
  }

  private handleParams(params) {
    if (params == undefined || params == null || params == "") {
      return Object();
    }

    return params;
  }

  public getApiUrl() {
    return environment.othersUrl;
  }

  private buidProvisoryHeader() {
    return {
      Authorization:
        "Bearer " + storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get(),
    };
  }
}
