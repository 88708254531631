import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { AnalyzeReportOperatorModel } from "src/app/models/analyze-report-operator-model";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { AnalyzeReportOperatorModelPaginated } from "src/app/models/analyze-report-operator-model-paginated";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";

import Swal from "sweetalert2";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { MinioService } from "src/app/services/minio.service";

@Component({
  selector: "app-analyze-report-operator",
  templateUrl: "./analyze-report-operator.component.html",
  styleUrls: ["./analyze-report-operator.component.scss"],
})
export class AnalyzeReportOperatorComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public minioService: MinioService;

  public datePipe: DatePipe;
  public actualPage: PageEvent;

  public analyzeReportOperatorModelsPaginated: AnalyzeReportOperatorModelPaginated;
  public analyzeReportOperatorModel: AnalyzeReportOperatorModel;
  public analyzeReportOperatorsPage: AnalyzeReportOperatorModelPaginated;

  public analyzesOperators: MatTableDataSource<any> =
    new MatTableDataSource<any>();

  public tableColumns: string[];
  public typeFieldSelect = [
    { name: "Número Pacote", value: "PACKAGE" },
    { name: "Motorista", value: "DRIVER" },
    { name: "Placa", value: "PLATE" },
    { name: "Base Opera.", value: "BASE" },
  ];

  public permissions: [];
  private intervalId: any;

  public filter = "";
  public field = "";
  public typeFieldFilterSelected;
  public errorMessage;

  constructor(
    datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    analyzeTripService: AnalyzeTripService,
    minioService: MinioService
  ) {
    this.datePipe = datePipe;
    this.analyzeTripService = analyzeTripService;
    this.minioService = minioService;
  }

  ngOnInit(): void {
    this.typeFieldFilterSelected = this.typeFieldSelect[0].value;
    this.field = this.typeFieldFilterSelected;
    this.initialPage();
    this.loadReports(this.actualPage, null, null);
    this.loadTableDefinitions();
    this.startCallingLoadReports();
  }

  ngOnDestroy(): void {
    this.stopCallingLoadReports();
  }

  private startCallingLoadReports(): void {
    console.log("Data atual:", new Date());
    this.intervalId = setInterval(() => {
      this.loadReports(this.actualPage, this.filter, this.field);
    }, 30000); // Chama o método loadReports a cada 30 segundos
  }

  private stopCallingLoadReports(): void {
    clearInterval(this.intervalId); // Para de chamar loadReports
  }

  private loadReports(page: PageEvent, name: string, field: string): void {
    this.spinner.show();

    var profile: string = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
    this.analyzeTripService
      .getAllAnalyzeReportOperator(page, profile, name, field)
      .subscribe(
        (t) => {
          this.analyzeReportOperatorsPage = t;
          this.analyzesOperators =
            new MatTableDataSource<AnalyzeReportOperatorModel>(
              this.analyzeReportOperatorsPage.content
            );
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter as analises do operador");
          this.spinner.hide();
        }
      );
  }

  openInNewTab(uuid: string) {
    const url = "/analise-operacional/view-tratamento-operador/" + uuid;
    window.open(url, "_blank");
  }

  openToTreatment(uuid: string) {
    const url = "/analise-operacional/treatment-select/" + uuid;
    window.open(url, "_blank");
  }

  openToUpdate(uuid: string) {
    const url = "/analise-operacional/edit/" + uuid;
    window.open(url, "_blank");
  }

  fieldFilter(): void {
    this.field = this.typeFieldFilterSelected;
    this.filter = "";
  }

  applyFilter(): void {
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "packageNumber",
      "operationalBase",
      "dateStart",
      "dateEnd",
      "driverName",
      "plate",
      "statusReport",
      "state",
      "filePdfAnalytical",
      "filePdfSynthetic",
      "operatorHandling",
      "viewOperatorHandling",
      "sentManager",
      "finishReport",
      "update",
      "delete",
    ];
  }

  public handleSentManager(element) {
    const url = "/analise-operacional/finalize-analysis/" + element;
    window.open(url, "_blank");
  }

  public async handleSentFinishReporth(element): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Finalizar Relatório",
      html: `<h6>Tem certeza que você deseja finalizar o relatório? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.SentFinish(element);
      }
    });
  }

  public async handleDelete(element): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Excluir Analise",
      html: `<h6>Tem certeza que você deseja excluir essa analise? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.deleteReports(element);
      }
    });
  }

  SentFinish(element): void {
    this.spinner.show();

    var operator = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;
    this.analyzeTripService.finishReport(operator, element.uuid).subscribe(
      (v) => {
        console.log("Analise finalizada com sucesso");
        this.loadReports(this.actualPage, this.filter, this.field);
        this.spinner.hide();
      },
      (e) => {
        console.log("Erro ao finalizar analise");
        this.spinner.hide();
        this.errorMessage = e.message;
        throw new EvalError(e);
      }
    );
  }

  deleteReports(element): void {
    this.spinner.show();
    this.analyzeTripService.deleteAnalyzeReport(element.uuid).subscribe(
      (v) => {
        console.log("Exclusão realizada com sucesso");
        this.loadReports(this.actualPage, this.filter, this.field);
        this.spinner.hide();
      },
      (e) => {
        console.log("Erro ao excluir o transportador");
        this.spinner.hide();
        this.errorMessage = e.message;
        throw new EvalError(e);
      }
    );
  }

  exportReport(element): void {
    this.minioService.exportReport(element).subscribe();
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }

  getStatusStyle(status: string): { [key: string]: string } {
    switch (status) {
      case "ERROR":
        return { color: "red" };
      case "FINISHED":
        return { color: "green" };
      case "PROCESSING":
        return { color: "blue" };
      case "REPORT_EMPTY":
        return { color: "yellon" };
      default:
        return {}; // Estilo padrão, se o status não corresponder a nenhum caso
    }
  }
}
