import { ModuloInterface } from "../contracts/Modulo.interface";

export class Modulo implements ModuloInterface {
  nomeConsultaApiBase = "modulo";
  id: number;
  nmModulo: string;
  dsModulo: string;
  cdPath: string;
  cdIcone: string;
}
