import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-distance-dialog',
  templateUrl: './distance-dialog.component.html',
  styleUrls: ['./distance-dialog.component.scss'],
})
export class DistanceDialogComponent implements OnInit {
  isLoader = true;
  constructor(
    private dialogRef: MatDialogRef<DistanceDialogComponent>,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public distance: any = null
  ) {}

  ngOnInit(): void {
    this.isLoader = !!this.distance;
  }

  close = (): void => {
    this.dialogRef.close();
  };
}
