import { DatePipe } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";

@Component({
  selector: "app-penalty-form",
  templateUrl: "./penalty-form.component.html",
  styleUrls: ["./penalty-form.component.scss"],
})
export class PenaltyFormComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  @ViewChild("printContent", { static: false }) printContent: ElementRef;

  public datePipe: DatePipe;

  public analyzeReportUuid = "";
  public nameManager = "";
  public nameSupervisor = "";
  public nameDriver = "";
  public namePackage = "";
  public managerTreatment = "";
  public operatorSummary = "";
  public infractions = "";
  public currentDate = "";

  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    analyzeTripService: AnalyzeTripService
  ) {
    this.analyzeTripService = analyzeTripService;
  }

  ngOnInit(): void {
    this.analyzeReportUuid = this.activatedRoute.snapshot.params.uuid;

    this.loadDataElements();
  }

  public async loadDataElements() {
    this.spinner.show();
    try {
      this.analyzeTripService
        .getTempleteInformation(this.analyzeReportUuid)
        .subscribe((t) => {
          this.currentDate = t.current_date;
          this.nameManager = t.name_manager;
          this.nameSupervisor = t.name_supervisor;
          this.nameDriver = t.name_driver;
          this.namePackage = t.name_package;
          this.managerTreatment = t.manager_treatment;
          this.operatorSummary = t.operator_summary;
          this.infractions = t.infractions;
        });

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  formatDate(dateString: string): string {
    // Converte a string de data para um objeto Date
    const date = new Date(dateString);

    // Formata a data usando o DatePipe
    return this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
  }

  public print() {
    const content = this.printContent.nativeElement.innerHTML;
    const newWindow = window.open("", "_blank");
    newWindow.document.open();
    newWindow.document.write(`
      <html>
        <head>
          <title>INTERNO</title>
          <style>
              .title-container {
                text-align: center;
            }
            
            .title-circle {
                border: 5px solid #333;
                padding: 10px;
                border-radius: 20px;
            }
            
            .subtitle-circle,
            .paragraph-container p {
                border: 3px solid #333;
                border-radius: 10px;
                padding: 5px 10px;
                margin: 10px;
            }
            
            .paragraph-description-container {
                border: 3px solid #333;
                border-radius: 10px;
                padding: 5px 10px;
                margin: 10px;
            }
            
            h3 {
                font-family: Arial, sans-serif;
                font-size: 24px;
                font-weight: bold;
                color: #333;
                margin: 0; /* Remover margem padrão do h3 */
            }
            
            .paragraph-container {
                width: 100%;
                text-align: start;
            }
            
            .paragraph-container p {
                display: inline-block;
                width: 45%; /* Define a largura de 45% para cada parágrafo */
                vertical-align: top; /* Alinha os parágrafos ao topo */
            }
            
            table,
            .table-content {
              width: 100%;
            }
            
            th.mat-sort-header-sorted {
              color: black;
            }
          </style>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.print();
  }
}
