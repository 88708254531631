 <ng-template [ngIf]="currentUser">
  <app-header></app-header>
  <app-menu></app-menu>
</ng-template> 

<router-outlet (activate)="this.actived($event)"> </router-outlet>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
             size="medium" color="#fff" type="ball-spin-fade"
             [fullScreen]="true">
  <p style="color: white"> carregando...</p>
</ngx-spinner>
<p-toast [baseZIndex]="999999" position="top-right"></p-toast> 

<!-- outlet implementado durante o desenvolvimento 
     e migração para realizar testes de 
     componentes externos e implementações em gerais.
    sera removido ao final desse processo.-->

<!-- <router-outlet name="teste"> </router-outlet>
 -->