import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { EventEmitterSensorService } from "src/app/services/event-emitter-sensor.service";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-directional-view",
  templateUrl: "./directional-view.component.html",
  styleUrls: ["./directional-view.component.scss"],
})
export class DirectionalViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorLeftSelect: any = [];
  public sensorRightSelect: any = [];

  public sensorRightCombo: any = [];
  public sensorLeftCombo: any = [];

  public direcionalLeftForm: FormGroup;
  public direcionalRightForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.direcionalLeftForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: ["", Validators.required],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.direcionalRightForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: ["", Validators.required],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.direcionalLeftForm.controls["cdPosicaoPneu"].setValue(
        "DIRECIONAL ESQ"
      );
      this.direcionalRightForm.controls["cdPosicaoPneu"].setValue(
        "DIRECIONAL DIR"
      );

      if (this.veiculoSensores.length > 0) {
        var direcionalDireito;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 1) {
              direcionalDireito = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 1) {
              direcionalDireito = filter;
            }
          }
        });

        var direcionalEsquerdo;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 2) {
              direcionalEsquerdo = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 2) {
              direcionalEsquerdo = filter;
            }
          }
        });

        //DIRECIONAL DIREITO SET
        if (direcionalDireito) {
          this.direcionalRightForm.controls["id"].setValue(
            direcionalDireito.id
          );

          if (direcionalDireito.sensor != null) {
            this.sensorRightCombo.push(direcionalDireito.sensor);
            this.sensorRightSelect = direcionalDireito.sensor.id;
          }

          if (direcionalDireito.pneu != null) {
            this.direcionalRightForm.controls["pneu"].setValue(
              direcionalDireito.pneu.numeroFogo
            );
          }

          this.direcionalRightForm.controls["nrPressaoMinima"].setValue(
            direcionalDireito.nrPressaoMinima
          );

          this.direcionalRightForm.controls["nrPressaoMaxima"].setValue(
            direcionalDireito.nrPressaoMaxima
          );

          this.direcionalRightForm.controls["nrTemperaturaMaxima"].setValue(
            direcionalDireito.nrTemperaturaMaxima
          );
        }

        //DIRECIONAL ESQUERDO SET
        if (direcionalEsquerdo != null) {
          this.direcionalLeftForm.controls["id"].setValue(
            direcionalEsquerdo.id
          );

          if (direcionalEsquerdo.sensor != null) {
            this.sensorLeftCombo.push(direcionalEsquerdo.sensor);
            this.sensorLeftSelect = direcionalEsquerdo.sensor.id;
          }

          if (direcionalEsquerdo.pneu != null) {
            this.direcionalLeftForm.controls["pneu"].setValue(
              direcionalEsquerdo.pneu.numeroFogo
            );
          }

          this.direcionalLeftForm.controls["nrPressaoMinima"].setValue(
            direcionalEsquerdo.nrPressaoMinima
          );

          this.direcionalLeftForm.controls["nrPressaoMaxima"].setValue(
            direcionalEsquerdo.nrPressaoMaxima
          );

          this.direcionalLeftForm.controls["nrTemperaturaMaxima"].setValue(
            direcionalEsquerdo.nrTemperaturaMaxima
          );
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
