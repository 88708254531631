<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="funcionalidadeTipoForm" (ngSubmit)="submitForm()">
                    <h2 class="mb-3">Novo Tipo de Funcionalidade</h2>
                    <div class="form-row">

                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome Tipo de Funcionalidade</mat-label>
                                <input formControlName="nmFuncionalidadeTipo" matInput
                                    required
                                    placeholder="Nome Tipo de Funcionalidade" maxlength="200" />
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Descrição Tipo de Funcionalidade</mat-label>
                                <input required formControlName="dsFuncionalidadeTipo" matInput
                                    placeholder="Descrição Tipo de Funcionalidade" maxlength="200" />
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Salvar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
