<div class="main-page cadastros">
  <div class="container-fluid">
    <app-title>{{ title ? title : 'Inserir novo' }} </app-title>
    <mat-tab-group>
      <mat-tab label="Dados">
        <ng-template matTabContent>
          <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
              <form [formGroup]="userForm" >
                <h2 class="mb-3">Visualização do Usuário</h2>
                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Login</mat-label>
                      <input [disabled]=true formControlName="cdUsuario" matInput placeholder="Identificação do usuário"
                        maxlength="100" required  [readonly]=true />
                    </mat-form-field>

                    <validation-errors [form]="userForm" controlLabel="Cód. Usuário" controlName="cdUsuario">
                    </validation-errors>
                  </div>
                  <div class="form-group col-lg-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Nome do usuário</mat-label>
                      <input formControlName="nmUsuario" matInput placeholder="Nome do usuário" maxlength="100"
                        required  [readonly]=true [disabled]=true/>
                    </mat-form-field>

                    <validation-errors [form]="userForm" controlLabel="Nome do usuário" controlName="nmUsuario">
                    </validation-errors>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <mat-form-field appearance="outline">
                      <mat-label>E-mail</mat-label>
                      <input formControlName="dsEmail" matInput placeholder="E-email" maxlength="254"  [readonly]=true [disabled]=true/>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-lg-3">
                    <mat-form-field appearance="outline">
                      <mat-label>Matricula</mat-label>
                      <input formControlName="nrMatricula" matInput placeholder="Matricula"  [readonly]=true [disabled]=true/>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Perfil" [disabled]="!userId">
        <ng-template matTabContent>
          <app-form-aba-perfil-visualizacao [userId]="userId">
          </app-form-aba-perfil-visualizacao>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>