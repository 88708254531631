import { PerfilUsuario } from "./../../../models/PerfilUsuario.model";
import { PerfilUsuarioInterface } from "./../../../contracts/PerfilUsuario.interface";
import { PerfilInterface } from "./../../../contracts/Perfil.interface";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ContentInterface } from "src/app/contracts/Content.interface";
import ApiService from "src/app/services/api.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-form-aba-perfil",
  templateUrl: "./form-aba-perfil.component.html",
  styleUrls: ["./form-aba-perfil.component.scss"],
})
export class FormAbaPerfilComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("filter") filter: ElementRef;
  @ViewChild("typeFilter") typeFilter: ElementRef;

  public userId: number;

  @Input()
  public perfilId: number;
  public hideMasterToggle = false;

  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[] = [
    "id",
    "nmPerfil",
    "dsPerfil",
    "vincular",
  ];

  private service: ApiService;
  public perfil: MatTableDataSource<PerfilInterface>;
  public perfilUsuarios: PerfilUsuarioInterface[];
  // private currentUserSubject: BehaviorSubject<User>;

  constructor(
    apiService: ApiService,
    private changeDetectorRefs: ChangeDetectorRef,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    this.service = apiService;
    this.perfil = new MatTableDataSource<PerfilInterface>([]);

    // this.currentUserSubject = new BehaviorSubject<User>(
    //   JSON.parse(localStorage.getItem('currentUser'))
    // );
  }

  ngOnInit(): void {
    this.userId = this.activatedRoute.snapshot.params["id"];
    this.findPerfilFuncionalidades();
    this.loadData();
  }

  async findPerfilFuncionalidades(): Promise<void> {
    this.perfilUsuarios = await this.service.get<PerfilUsuario[]>({
      url: "usuario-perfil/query",
      params: {
        usuario: this.userId,
      },
    });
  }

  // public get currentUserValue(): User {
  //   return this.currentUserSubject.value;
  // }

  public hasVinculo(row): boolean {
    return this.perfilUsuarios?.some(
      (pf) => pf.perfil.id === row.id && pf.ativo
    );
  }

  public onMasterToggleChange = (e): void => {
    this.perfil.data.forEach((func) => {
      this.updateData(func, e.checked);
    });
  };

  public handleMasterToggle = (): boolean => {
    return this.perfil.data.every((func) => this.hasVinculo(func));
  };
  public onCheckboxChange = (e, row): void => {
    this.updateData(row, e.checked);
  };

  public isVinculosDisabled = (row): boolean => {
    return this.loader;
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadData(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadData(
      0,
      this.typeFilter.nativeElement.value,
      this.filter.nativeElement.value
    );
  };

  private async updateData(data, check) {
    this.loader = true;

    const vinculo = this.perfilUsuarios.find((pf) => pf.perfil.id === data.id);

    if (vinculo) {
      vinculo.ativo = check;
      await this.service.patch<PerfilUsuario>(vinculo.id, "usuario-perfil", {
        ativo: check,
      });
    } else {
      const perfilUsuario = {
        ativo: true,
        perfil: { id: data.id },
        usuario: { id: this.userId },
        flLeitura: true,
        flInsercao: true,
        flAtualizacao: true,
        flDelecao: true,
      };
      const novoperfilUsuario = await this.service.post<PerfilUsuario>(
        "usuario-perfil",
        perfilUsuario
      );
      this.perfilUsuarios.push(novoperfilUsuario);
    }
    this.loader = false;
  }

  public async loadData(pageIndex = 0, campo?, filter?): Promise<void> {
    this.loader = true;
    const params = {
      size: this.pageSize,
    };
    if (pageIndex) {
      params["page"] = pageIndex;
    }

    if (campo && filter) {
      params[campo] = filter;
    }

    try {
      const result = await this.service.get<ContentInterface[]>({
        url: "perfil",
        params,
      });
      console.log(result);

      this.perfil.data = result["content"];
      this.totalElements = result["totalElements"];
      this.totalPages = result["totalPages"];

      this.changeDetectorRefs.detectChanges();

      this.loader = false;
    } catch (error) {
      console.error("loadPerfil", error);
      this.loader = false;
    }
  }
}
