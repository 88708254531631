 const PRIME_TRANSLATE = {
    startsWith: "Começa com",
    contains: "Contém",
    notContains: "Não Contém",
    endsWith: "Termina com",
    equals: "É igual",
    notEquals: "Diferente",
    lt: "Menor que",
    lte: "Menor que ou igual a",
    gt: "Maior que",
    gte: "Maior que ou igual",
    is: "É",
    isNot: "Não é",
    before: "Antes",
    after: "Depois",
    clear: "Limpar",
    apply: "Aplicar",
    matchAll: "Combinar Tudo",
    matchAny: "Corresponder a qualquer",
    addRule: "Adicionar Regra",
    removeRule: "Remover Regra",
    accept: "Sim",
    reject: "Não",
    today: "Hoje",
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ],
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado"
    ],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"]
  };

export default PRIME_TRANSLATE;