import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AnalyzeTripService } from "../../../services/analyze-trip.service";
import { toCamel } from "snake-camel";
import { CoreService } from "src/app/services/migrate/api.core.service";
import { ToolsService } from "src/app/services/migrate/tools.service";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

import { AnalyzeReportModel } from "src/app/models/analyze-report-model";

@Component({
  selector: "app-edit-analysis",
  templateUrl: "./edit-analysis.component.html",
  styleUrls: ["./edit-analysis.component.scss"],
})
export class EditAnalysisComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public serviceCore: CoreService;

  public analyzeReportModel: AnalyzeReportModel;

  public reportOperationalForm: FormGroup;

  public analyzeReportOld: any;
  public analyzeReportUuid = "";

  public typeAnalyzeSelect: any = [];

  public typeAnalyzeFilterSelected;

  public errorMessage;

  ngOnInit(): void {
    this.reportOperationalForm = this.formBuilder.group(
      {
        packageNumber: ["", Validators.required],
        description: ["", Validators.required],
      },
      { updateOn: "blur" }
    );

    this.analyzeReportUuid = this.activatedRoute.snapshot.params.uuid;

    this.loadDataElements(this.analyzeReportUuid);
  }

  constructor(
    private tools: ToolsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    analyzeTripService: AnalyzeTripService,
    apiserviceCore: CoreService
  ) {
    this.serviceCore = apiserviceCore;
    this.analyzeTripService = analyzeTripService;
  }

  public async loadDataElements(reportUuid: string) {
    try {
      this.typeAnalyzeSelect = [
        { name: "Análise Operacional Motorista", value: "DRIVER_OPERATIONAL" },
        { name: "Análise Operacional Veiculo", value: "VEHICLE_OPERATIONAL" },
      ];

      this.analyzeTripService
        .getByUuidAnalyzeReport(reportUuid)
        .subscribe((resp) => {
          this.analyzeReportOld = toCamel(resp);
          this.fillTransporterData();
        });

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  private fillTransporterData(): void {
    console.log(this.analyzeReportOld);

    this.reportOperationalForm
      .get("packageNumber")
      .setValue(this.analyzeReportOld.packageNumber);
    this.reportOperationalForm
      .get("description")
      .setValue(this.analyzeReportOld.description);
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();
    const values = this.reportOperationalForm.value;

    var analyzeReport: AnalyzeReportModel = new AnalyzeReportModel();

    this.typeAnalyzeFilterSelected =
      this.analyzeReportOld.typeReportAnalyzeEnum;

    analyzeReport.analyzeStartDate =
      this.analyzeReportOld.analyzeStartDate + ":00";
    analyzeReport.analyzeEndDate = this.analyzeReportOld.analyzeEndDate + ":00";
    analyzeReport.description = values.description;
    analyzeReport.packageNumber = values.packageNumber;
    analyzeReport.operationalBaseUuid =
      this.analyzeReportOld.operationalBase.uuid;
    if (this.analyzeReportOld.driver != null) {
      analyzeReport.idDriver = this.analyzeReportOld.driver.id;
    }

    analyzeReport.typeReportAnalyzeEnum = this.typeAnalyzeFilterSelected;
    analyzeReport.jumpGps = this.analyzeReportOld.jumpGps;
    analyzeReport.managerAnalyzeUuid = null;
    analyzeReport.supervisorUuid = null;
    analyzeReport.operatorAnalyzeId = storageX
      .whereKeyIs(STORAGE_KEY_CURRENT_USER)
      .get()?.id;
    analyzeReport.userId = storageX
      .whereKeyIs(STORAGE_KEY_CURRENT_USER)
      .get()?.id;

    if (this.analyzeReportOld.company != null) {
      analyzeReport.idCompany = this.analyzeReportOld.company.id;
      analyzeReport.idDivision = null;
    } else {
      analyzeReport.idDivision = this.analyzeReportOld.division.id;
      analyzeReport.idCompany = null;
    }

    if (this.analyzeReportOld.terminal != null) {
      analyzeReport.nameDriver = this.analyzeReportOld.nameDriverManual;
      analyzeReport.idTerminal = this.analyzeReportOld.terminal.id;
    }

    console.log(analyzeReport);

    this.analyzeTripService
      .updateAnalyzeReport(analyzeReport, this.analyzeReportUuid)
      .subscribe((t) => {
        this.analyzeReportModel = toCamel(t) as AnalyzeReportModel;
      });

    // Inicia o temporizador de 3 segundos
    setTimeout(() => {
      // Esconde o spinner
      this.spinner.hide();

      // Navega para a rota desejada
      this.router.navigate(["/analise-operacional"]);
    }, 3000); // 3000 milissegundos = 3 segundos
  }
}
