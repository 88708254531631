import { UserInterface } from "../contracts/User.interface";
import { PerfilInterface } from "../contracts/Perfil.interface";

export class User implements UserInterface {
  nomeConsultaApiBase = "usuarios";
  listCamposConsulta = ["cdUsuario", "nmUsuario", "dsEmail"];
  nomeConsultaApiBaseGetAll = "usuarios/list";
  permissaoVisualizacao = "USER_ATUALIZACAO_FUNCIONALIDADE";
  permissaoDelecao = "USER_DELECAO_FUNCIONALIDADE";
  enderecoRoute = "users";

  id: number;
  empresaSelect: number;
  idEmpresa: number;
  idDivisao: number;
  ativo: boolean;
  cdUsuario: string;
  dhUltimoAcesso: string;
  dsEmail: string;
  nmUsuario: string;
  nrMatricula: string;
  perfis?: PerfilInterface[];
  token?: string;
  refreshToken?: string;
}
