export class MapPin {
    path: google.maps.SymbolPath;
    fillColor: string;
    anchor: google.maps.Point;
    scale: number;
    strokeWeight: number;
    rotation: number;
    strokeColor: string;
    iconUrl: string;
    circle: google.maps.Circle;
    infoWindow: google.maps.InfoWindow;

    constructor(rotation: number, strokeColor: string, map: google.maps.Map, position: google.maps.LatLngLiteral, showCircle: boolean = false, stopData: any = null) {
        this.path = google.maps.SymbolPath.FORWARD_CLOSED_ARROW;
        this.fillColor = 'black';
        this.anchor = new google.maps.Point(0, 0);
        this.scale = 4;
        this.strokeWeight = 2;
        this.rotation = rotation;
        this.strokeColor = strokeColor ? strokeColor : 'gray';
        this.iconUrl = null;

        console.log('MapPin criado com a cor:', strokeColor);

        // Se showCircle for true, cria um círculo ao redor do marcador
        if (showCircle) {
            this.circle = new google.maps.Circle({
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                map: map,
                center: position,
                radius: 3,
                zIndex: 1
            });

            console.log('Círculo criado ao redor do marcador:', this.circle);

            // Criar a InfoWindow com base nos dados de stopData
            if (stopData) {
                this.infoWindow = new google.maps.InfoWindow({
                    content: `<div style="font-size: 20px;">
                                ${stopData}
                              </div>`
                });

                // Mostrar InfoWindow ao passar o mouse sobre o círculo
                this.circle.addListener('mouseover', () => {
                    this.infoWindow.setPosition(position);
                    this.infoWindow.open(map);
                });

                // Fechar InfoWindow quando o mouse sair do círculo
                this.circle.addListener('mouseout', () => {
                    this.infoWindow.close();
                });
            }
        } else {
            console.log('Círculo não criado.');
        }
    }
}
