import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { Atividade } from "../../models/Atividade.model";
import { ApiService } from "../../services/api.service";
import { AtividadeInterface } from "../../contracts/Atividade.interface";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { Page } from "src/app/utils/paginate";
import { CoreService } from "src/app/services/migrate/api.core.service";

@Component({
  selector: "app-list",
  templateUrl: "./atividade.component.html",
  styleUrls: ["./atividade.component.scss"],
})
export class AtividadeComponent implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public filter;
  public typeFilter;

  public page = new Page();
  public errorMessage;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[] = [
    //'id', OCULTADO CAMPO ID DA TABELA - 20102020 - MARCOS TAVARES
    "nmNome",
    "cdCodigo",
    "update",
    "clone",
    "view",
    "delete",
  ];

  public filtros = [
    //{ campo: 'id', valor: 'ID' },
    { campo: "cdCodigo", valor: "Codigo" },
    { campo: "nmNome", valor: "Nome" },
  ];

  private service: ApiService;
  private coreService: CoreService;

  public atividades: MatTableDataSource<AtividadeInterface>;

  constructor(
    private apiCoreService: CoreService,
    private spinner: NgxSpinnerService,
    apiService: ApiService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.service = apiService;
    this.coreService = apiCoreService;
    this.atividades = new MatTableDataSource<AtividadeInterface>([]);
  }

  ngOnInit(): void {
    this.loadAtividade();
  }
  // public handleFilter = (value: string): void => {
  //    this.atividades.filter = value.trim().toLocaleLowerCase();
  // };

  public handleSort = (sort): void => {
    this.loadAtividade(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadAtividade(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadAtividade();
  };

  public handleDelete = (id: string): void => {
    this.deleteAtividade(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/atividade/${id}`]);
  };

  ngAfterViewInit(): void {
    this.atividades.sort = this.sort;
    this.atividades.paginator = this.paginator;
  }

  public async deleteAtividade(id): Promise<void> {
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        await this.service.delete<Atividade>(id, "atividade");

        this.spinner.hide();

        this.loadAtividade();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public async loadAtividade(pageIndex = 0, sort?): Promise<void> {
    const params = {
      size: this.pageSize,
      page: pageIndex,
    };

    try {
      this.spinner.show();

      const result = await this.coreService.get<any>({
        url: "v2/activitys",
        params,
      });

      this.atividades.data = result.elements;
      this.page = result.page;

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
