import { TerminalCore } from "./terminal.core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder } from "@angular/forms";

import { ApiService } from "../../services/api.service";
import { CoreService } from "src/app/services/migrate/api.core.service";
import { ToolsService } from "src/app/services/migrate/tools.service";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { TrackerModelService } from "../../services/tracker-model.service";

@Component({
  selector: "app-create-terminal",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ViewTerminalComponent extends TerminalCore implements OnInit {
  public title = "Visualizar Terminal";

  constructor(
    baseService: ApiBaseService,
    public activatedRoute: ActivatedRoute,
    private apiserviceCore: CoreService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    public tools: ToolsService,
    trackerModelService: TrackerModelService
  ) {
    super(apiserviceCore, apiService, formBuilder, tools, baseService, trackerModelService);
  }

  ngOnInit(): void {
    this.activedView = true;
    this.terminalForm = this.formBuilder.group(this.validation.rules());
    this.terminalForm.disable({ onlySelf: true });
    this.terminalId = this.activatedRoute.snapshot.params["id"];
    this.loadDataElements();
  }
}
