import { ManegerStorage } from './manegerStorage';
import TypeStorage from './typeStorage'
import HandleElementStorage from './handleElementStorage'
import keepLoggedIn from './keepLoggedIn';

class StorageCore extends ManegerStorage{

     private key: string;

     constructor( key: string ){
         super();
         this.key = key;
     }

    get() {
  
        const value = TypeStorage.storage.getItem( this.key );

        if( value ){
 
          return HandleElementStorage.buildOut( value );
        }

       return null;
    }

    set( value ): void {
        TypeStorage.storage.setItem( this.key, HandleElementStorage.buildIn( value ) );
    }

    remove(): void {
         TypeStorage.storage.removeItem( this.key );
    }

    removeAll(): void {
        TypeStorage.storage.clear();
        TypeStorage.loadStorage( keepLoggedIn.statusKeepLoggedIn() );
    }
}

export default StorageCore;