import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { AccessTokenResponse } from "./models/gateway/access-token-response.model";
import { OauthTokenService } from "./services/ouath-token.service";
import { Router } from "@angular/router";
import { HttpUtil } from "./_util/http-util";
import { BehaviorSubject, Observable, Subject, interval } from "rxjs";
import { UserService } from "./services/user.service";
import storageX from "./storageCore/storageX";
import { STORAGE_KEY_ACCESS_TOKEN_WSO2 } from "./storageCore/constStorageKeys";

const FIFTY_MIN = 3000000; // Alterado para 50 minutos em milissegundos
@Injectable({
  providedIn: "root",
})
export class StartAppService {
  public intervalTimeInMilliseconds = FIFTY_MIN;
  static reload$ = new EventEmitter<any>();
  loaded: boolean = false;
  refresh$ = new EventEmitter<any>();
  oAuth = new AccessTokenResponse();
  clientId = "";

  constructor(
    public oAuthService: OauthTokenService,
    public http: HttpClient,
    public router: Router,
    public httpUtil: HttpUtil,
    public userService: UserService
  ) {
    console.info("REFRESH DO TOKEN INICIADO");
    this.initListenerToken();
  }

  public initApp(): Promise<any> {
    return new Promise<any>((resolve) => {
      console.info("Inicializando aplicação");
      console.info("Realizando autenticação junto ao gateway...");
      this.loadGatewayCredentials().subscribe({
        next: () => {},
        error: () => {},
        complete: () => {
          console.info("Autenticação realizada...");
          resolve(true);
        },
      });
    });
  }

  public initListenerToken(): void {
    interval(this.intervalTimeInMilliseconds).subscribe(
      (x) => {
        console.info("----> TOKEN ATUALIZADO: " + new Date() + " <----");
        this.loadGatewayCredentials();
      },
      (error) => {
        throw new Error("Erro ao executar o listener do token");
      }
    );
  }

  private loadGatewayCredentials(): Observable<boolean> {
    const subject: Subject<any> = new BehaviorSubject(false);

    this.oAuthService.getGatewayCredentials().subscribe(
      (gatewayCredentials) => {
        this.clientId = gatewayCredentials.clientId;
        this.httpUtil.clientId = gatewayCredentials.clientId;
        this.oAuthService.generateGrantToken().subscribe(
          (grantCodeResponse) => {
            console.log(grantCodeResponse);
            storageX
              .whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2)
              .set(grantCodeResponse.access_token);
            subject.next(true);
            subject.complete();
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (error) => {
        console.error(error);
      }
    );

    return subject.asObservable();
  }
}
