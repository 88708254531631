<div class="toolbox_general_filter" *ngIf="this.permissionService.hasPermission('FILTRO_GERAL_FUNCIONALIDADE', this.permissions)">
    <mat-button-toggle-group
            name="general_filter"
            [selected] = "selected"
            (click)="onClick()"
    >
        <mat-button-toggle value="general_filter" [ngClass]="{ btnSelected: selected }">
            <mat-icon>search</mat-icon>
            <span class="btn-icon-text">Buscar Veículo</span>
        </mat-button-toggle>
    </mat-button-toggle-group>
</div>
