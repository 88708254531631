<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="sensorForm" (ngSubmit)="submitForm()">
                    <h2 class="mb-3" *ngIf="this.title == 'Atualizar Sensor'">Editar Sensor</h2>
                    <h2 class="mb-3" *ngIf="this.title == 'Cadastrar Sensor' || this.title == 'Duplicar Sensor' ">Novo Sensor</h2>
                      <div class="form-row" >
                        <div class="form-group col-md-3">
                          <mat-form-field appearance="outline">
                            <mat-label>Tipo de Cadastro</mat-label>
                            <mat-select formControlName="selecaoEmpresa" [(value)]="empresaFilterSelected" [(ngModel)]="empresaFilterSelected"
                            (selectionChange)="this.filterCompany()" required>
                              <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                                {{ type.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <validation-errors [form]="sensorForm" controlLabel="Tipo de Cadastro" controlName="selecaoEmpresa">
                          </validation-errors>
                        </div>
                        <div class="form-group col-md-4">
                          <mat-form-field appearance="outline">
                            <mat-label *ngIf="sensorForm.get('selecaoEmpresa').value == null">Selecione um tipo de cadastro</mat-label>
                            <mat-label *ngIf="sensorForm.get('selecaoEmpresa').value == 'Matriz'">Matrizes</mat-label>
                            <mat-label *ngIf="sensorForm.get('selecaoEmpresa').value == 'Filial'">Filiais</mat-label>
                            <mat-label *ngIf="sensorForm.get('selecaoEmpresa').value == 'Divisão'">Divisões</mat-label>
                            <mat-select formControlName="idEmpresa" [(value)]="empresaSelected" [(ngModel)]="empresaSelected" required>
                              <mat-option *ngFor="let type of companiesFilter" [value]="type.id">
                                {{ type.nome }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <validation-errors [form]="sensorForm" controlLabel="Matriz/Divisão/Filial" controlName="idEmpresa">
                          </validation-errors>
                        </div>
                        <div class="form-group col-lg-5">
                          <mat-form-field appearance="outline">
                              <mat-label>Código Sensor</mat-label>
                              <input autofocus required formControlName="codigoSensor" matInput
                                  placeholder="Código Sensor" maxlength="12" style="text-transform: uppercase;" />
                          </mat-form-field>
                        </div>
                      </div>


                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit" [disabled]="!sensorForm.valid"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Salvar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
