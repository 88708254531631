import { ContentInterface } from "./../../contracts/Content.interface";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Maintenance } from "../../models/Maintenance.model";
import { ApiService } from "../../services/api.service";
import { Validation } from "./validation";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-create-maintenance",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ViewMaintenanceComponent implements OnInit {
  public manutencaoForm: FormGroup;
  public veiculoSelected;
  public manutencao: Maintenance;
  public manutencaoId: number;
  public title = "Visualizar Manutenção";
  public errorMessage;
  private service: ApiService;
  private validation: Validation;
  public vehicle: any = [];
  public loader = false;

  ngOnInit(): void {
    this.manutencaoForm = this.formBuilder.group(this.validation.rules());
    this.manutencaoId = this.activatedRoute.snapshot.params["id"];
    this.loadMaintenance(this.manutencaoId);
    this.setup();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  async setup(): Promise<void> {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(); //localStorage.getItem('idPerfil');
    const vehicles = await this.service.get<ContentInterface[]>({
      url: "veiculo/perfil/" + perfil + "?size=2000",
    });
    this.vehicle = vehicles["content"];
  }

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.service = apiService;
    this.validation = new Validation();
  }

  fillMaintenanceData(): void {
    this.veiculoSelected = this.manutencao.veiculo.id;

    if (this.veiculoSelected) {
      this.manutencaoForm.controls["veiculo"].setValue(this.veiculoSelected);
    }

    const fields = [
      "dtManutencao",
      "nrQuilometragem",
      "dsDescricao",
      "flAlarmeGerado",
    ];
    fields.forEach((element) => {
      this.manutencaoForm.controls[element].setValue(this.manutencao[element]);
    });
  }

  public async loadMaintenance(id): Promise<void> {
    try {
      const response = await this.service.get<Maintenance>({
        url: `veiculo-manutencao/${id}`,
      });
      this.manutencao = response as Maintenance;
      this.fillMaintenanceData();
    } catch (error) {
      console.error(error);
    }
  }
}
