import { ModuloInterface } from "../contracts/Modulo.interface";
import { FuncionalidadeTipoInterface } from "../contracts/FuncionalidadeTipo.interface";
import { FuncionalidadeInterface } from "../contracts/Funcionalidade.interface";

export class Funcionalidade implements FuncionalidadeInterface {
  nomeConsultaApiBase = "funcionalidade";
  listConsultaApiBase = "funcionalidade/list";

  id: number;
  ativo: boolean;
  modulo: ModuloInterface;
  funcionalidadeTipo: FuncionalidadeTipoInterface;
  nmFuncionalidade: string;
  dsFuncionalidade: string;
  cdPath: string;
  pathCompleto: string;
  cdFuncionalidade: string;
  sgFuncionalidade: string;
}
