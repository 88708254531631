import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { CambusDataModel } from "src/app/models/cambus-data-model";

import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { AnalyzeReportSupervisorModelPaginated } from "src/app/models/analyze-report-supervisor-model-paginated";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ApiBaseService } from "src/app/services/migrate/api.base.service"

@Component({
  selector: 'app-cambus',
  templateUrl: './cambus.component.html',
  styleUrls: ['./cambus.component.scss']
})
export class CambusComponent implements OnInit {
  public apiBaseService: ApiBaseService

  public analyzeReportSupervisorModelsPaginated: AnalyzeReportSupervisorModelPaginated;
  public analyzeReportSupervisorModel: CambusDataModel;
  public errorMessage;
  public analyzeReportSupervisorsPage: AnalyzeReportSupervisorModelPaginated;
  public analyzesManagers: MatTableDataSource<any> =
    new MatTableDataSource<any>();
  public tableColumns: string[];

  public brandsSelect = [];
  public modelsSelect = [];
  public yearCambusSelect = [];

  public actualPage: PageEvent;
  public permissions: [];
  public field = "";
  public filterBrandsSelected = "";
  public filterModelsSelected = "";
  public filterYearsSelected = "";

  constructor(
    private spinner: NgxSpinnerService,
    public router: Router,
    apiBaseService: ApiBaseService,
    public dialog: MatDialog
  ) {
    this.apiBaseService = apiBaseService;
  }

  ngOnInit(): void {
    this.initialPage();
    this.loadReports(this.actualPage);
    this.loadTableDefinitions();
  }

  resetFilters() {
    this.filterModelsSelected = '';
    this.filterBrandsSelected = '';
    this.filterYearsSelected = '';

    this.loadReports(this.actualPage);
  }


  private async loadReports(page: PageEvent): Promise<void> {
    this.spinner.show();
    const params = {
      size: page.pageSize,
      page: page.pageIndex,
      brand: this.filterBrandsSelected,
      model: this.filterModelsSelected,
      year: this.filterYearsSelected
    };

    const paramsAttributes = {
      brand: this.filterBrandsSelected,
      model: this.filterModelsSelected,
      year: this.filterYearsSelected
    };

    const resultAttributes = await this.apiBaseService.get<any>({
      url: "cambus-data/get-attributes-filter",
      params,
    });

    this.brandsSelect = resultAttributes["brands"]
    this.modelsSelect = resultAttributes["models"]
    this.yearCambusSelect = resultAttributes["yearCambus"]

    const result = await this.apiBaseService.get<any>({
      url: "cambus-data/get-all",
      params,
    });

    this.analyzeReportSupervisorsPage = result;
    this.analyzesManagers =
      new MatTableDataSource<CambusDataModel>(
        this.analyzeReportSupervisorsPage.content
      );

    this.spinner.hide();
  }

  applyFilter(): void {
    this.loadReports(this.actualPage);
  }


  private loadTableDefinitions(): void {
    this.tableColumns = [
      "brand",
      "model",
      "yearCambus",
      "acceleration",
      "brakes",
      "consumptionInst",
      "consumptionStopped",
      "deepAcceleration",
      "movementHourmeter",
      "odometer",
      "rpm",
      "speed",
      "stoppedHourmeter",
      "tank",
      "temperature",
      "torque",
      "totalCons",
      "totalHourmeter",
      "turbo",
    ];
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }
}