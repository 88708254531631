import { Validators } from '@angular/forms';

export class Validation {
  public rules = (): object => {
    return {
      nmNome: ['', Validators.required],
      nrVelocidadeAlarmePainel: ['', Validators.required],
      nrVelocidadeAlarmeVeiculo: ['', Validators.required],
      empresa: ['', Validators.required],      
    };
  };
}
