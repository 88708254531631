import { NgxSpinnerService } from "ngx-spinner";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { PerfilInterface } from "../../contracts/Perfil.interface";
import { Perfil } from "../../models/Perfil.model";
import { Page } from "src/app/utils/paginate";
import { CoreService } from "src/app/services/migrate/api.core.service";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-perfil",
  templateUrl: "./perfil.component.html",
  styleUrls: ["./perfil.component.scss"],
})
export class PerfilComponent implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild('filter') filter: ElementRef;
  //@ViewChild('typeFilter') typeFilter: ElementRef;
  public filter;
  public typeFilter;

  public page = new Page();
  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;
  public objetoBase: Object;

  public displayedColumns: string[] = [
    //'id', OCULTADO CAMPO ID DA TABELA - 20102020 - MARCOS TAVARES
    "dsPerfil",
    "nmPerfil",
    "update",
    "clone",
    "view",
    "delete",
  ];

  public filtros = [
    //{ campo: 'id', valor: 'ID' },
    { campo: "nmPerfil", valor: "Nome Perfil" },
    { campo: "dsPerfil", valor: "Descrição Perfil" },
  ];

  private coreService: CoreService;
  private apiBaseService: ApiBaseService;

  public perfil: MatTableDataSource<PerfilInterface>;

  constructor(
    apiBaseService: ApiBaseService,
    private spinner: NgxSpinnerService,
    apiCoreService: CoreService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.coreService = apiCoreService;
    this.apiBaseService = apiBaseService;
    this.perfil = new MatTableDataSource<PerfilInterface>([]);
  }

  ngOnInit(): void {
    this.loadPerfil();
  }

  public handleSort = (sort): void => {
    this.loadPerfil(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadPerfil(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadPerfil();
  };

  public handleDelete = (id: string): void => {
    this.deletePerfil(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/perfil/${id}`]);
  };

  ngAfterViewInit(): void {
    this.perfil.sort = this.sort;
    this.perfil.paginator = this.paginator;
  }

  public async deletePerfil(id): Promise<void> {
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        var idUsuario = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;

        await this.apiBaseService.delete(
          idUsuario,
          id,
          new Perfil()["nomeConsultaApiBase"]
        );

        this.spinner.hide();

        this.loadPerfil();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public async loadPerfil(pageIndex = 0, sort?): Promise<void> {
    const params = {
      size: this.pageSize,
      page: pageIndex,
    };

    try {
      this.spinner.show();

      const result = await this.apiBaseService.get<any>({
        url: new Perfil()["nomeConsultaApiBase"],
        params,
      });

      this.perfil.data = result.content;
      this.page = result.page;

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
