/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

import { LatLngBounds } from '@agm/core';

import { ContentInterface } from './../contracts/Content.interface';

declare let google: any;

@Injectable({
  providedIn: 'root',
})
export class EletricFencesService {
  private service: ApiService;
  protected instanceMap: any;

  protected electronicFence = {
    name: '',
    type: '',
    limit: '',
  };

  constructor(apiService: ApiService) {
    this.service = apiService;
  }

  setMap(map: any): void {
    this.instanceMap = map;
  }

  async loadEletricFences(id: number, map: any): Promise<any> {
    if (!id) {
      return;
    }

    try {
      const result = await this.service.get<ContentInterface[]>({
        url: 'cerca-eletronica',
        params: {
          id,
        },
      });

      const [res] = result['content'];
      if (!res || res.length === 0 || !res['dsPosicoes']) {
        return;
      }
      const coords = res['dsPosicoes'].split('|');

      const lines = coords.map((e) => {
        const parties = e.split('!');
        const [latitude, longitude] = parties;
        return {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        };
      });

      return lines;
    } catch (error) {
      console.log('cerca erro', error);
    }
  }

  getPolygon(lines): google.maps.Polygon {
    const bounds: LatLngBounds = new google.maps.LatLngBounds();
    const latlng = [];
    for (const e of lines) {
      latlng.push(new google.maps.LatLng(e.lat, e.lng));
      bounds.extend(new google.maps.LatLng(e.lat, e.lng));
    }

    const polygonElectronicFence = new google.maps.Polygon({
      paths: latlng,
      strokeColor: '#FF8800',
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: '#FF8800',
      fillOpacity: 0.35,
    });

    return polygonElectronicFence;
  }
}
