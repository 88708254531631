import { Validators } from '@angular/forms';

export class Validation {
    public rules = (): object => {
        return {
            nmFuncionalidadeTipo: ['', Validators.required],
            dsFuncionalidadeTipo: ['', Validators.required],
        };
    };
}
