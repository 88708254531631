import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {EventAlarmService} from 'src/app/services/EventAlarm.service';
import {Address, BUILD_LINK_GOOGLE_MAPS,} from 'src/app/utils/build_link_google_maps';
import {EventService} from 'src/app/services/migrate/Event.service';
import storageX from 'src/app/storageCore/storageX';
import {STORAGE_KEY_CURRENT_USER, STORAGE_KEY_ID_PERFIL,} from 'src/app/storageCore/constStorageKeys';
import ApiService from 'src/app/services/api.service';
import {AdditionalDataType} from '../../../enums/additional-data-type.enum';

@Component({
  selector: "app-visualizar-dialog",
  templateUrl: "./visualizar-dialog.component.html",
  styleUrls: ["./visualizar-dialog.component.scss"],
})
export class VisualizarDialogComponent implements OnInit {
  //@ViewChild('solucao') solucao: ElementRef;
  public events = new Array<any>();
  public dataValue = {};
  public finalizar = false;
  public orientacao = "";
  public solucao;
  public lastAnswers = [];
  public position: any;
  public linkMap: any;
  public addressDisplay: any;
  private serviceApi: ApiService;
  private serviceEvent: EventService;
  public loadeEnd: boolean;
  public displayedColumns: string[] = [
    "dhFinalizacao",
    "usuarioFinalizacao",
    "dsResposta",
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private serviceEvents: EventService,
    private serviceApiI: ApiService,
    private serviceUser: AuthenticationService,
    private service: EventAlarmService
  ) {
    this.serviceApi = serviceApiI;
  }

  ngOnInit(): void {
    this.buildAnswer();
    this.finalizar = this.data?.flFinalizado ? true : false;
    this.orientacao = this.data?.dsProcedimentoOperador;

    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(); //localStorage.getItem('idPerfil');

    this.load();
  }

  private load() {
    this.loadeEnd = true;
    this.serviceApi
      .get<any>({
        url:
          "/v2/evento-alarme/all-finished-today" +
          "?idTerminalAlarm=" +
          this.data.idTerminalAlarme +
          "&idTerminal=" +
          this.data.idTerminal,
      })
      .then((result) => {
        this.loadeEnd = false;
        this.events = result;
        this.buildAnswersFinishedAlarm();

        this.loadPosition();
      })
      .catch(() => {
        this.loadeEnd = false;
      });
  }

  private loadPosition() {
    if (this.data.position) {
      this.addressDisplay = this.data.position.adress;
      this.linkMap = this.data.position.linkMap;

      return;
    }

    this.loadeEnd = true;
    this.service
      .get<any>({
        url: "/v2/position-terminal/" + this.data?.idTerminal,
      })
      .then((result) => {
        this.loadeEnd = true;
        this.position = result;

        this.searchAddress();
      })
      .catch(() => {
        this.loadeEnd = false;
      });
  }

  private buildAnswer() {
    let id = 1;

    if (this.data?.dsResposta) {
      const a = this.data.dsResposta.split(/\r?\n|\r/);
      for (let i of a) {
        id++;
        if (i) {
          this.lastAnswers.push({ id: id, msg: i });
        }
      }
      this.lastAnswers.push({ id: id++, msg: null });
    } else {
      this.lastAnswers.push({ id: id, msg: null });
    }
  }

  private buildAnswersFinishedAlarm() {
    const newEvents = [];
    let id = 1;

    this.events.forEach((action) => {
      if (action?.dsResposta) {
        const a = action.dsResposta.split(/\r?\n|\r/);
        const msgs = [];

        for (let i of a) {
          id++;
          if (i) {
            msgs.push({ id: id, msg: i });
          }
        }

        newEvents.push({ ...action, msgs: msgs });
      }
    });

    this.events = newEvents;
  }

  public goGoogleMapsDriverAddress() {
    let address = new Address();
    address.street = this.data.motorista.txEndereco;
    address.state = this.data.motorista.nmEstado;
    address.postalCode = this.data.motorista.cdCep;
    address.number = this.data.motorista.cdNumero;
    address.neighborhood = this.data.motorista.nmBairro;
    address.city = this.data.motorista.nmCidade;

    BUILD_LINK_GOOGLE_MAPS(address);
  }

  async searchAddress(): Promise<void> {
    const self = this;
    const convert = this.toDegreesMinutesAndSeconds;
    try {
      const { latitude, longitude } = this.position[0];

      let linkMap;
      self.loadeEnd = true;
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode(
        { location: new google.maps.LatLng(latitude, longitude) },
        function (results, status) {
          self.loadeEnd = false;

          if (status == google.maps.GeocoderStatus.OK) {
            const [address] = results;
            if (address && address.formatted_address) {
              self.addressDisplay = address.formatted_address;
              self.linkMap = `${"https://www.google.com/maps/place/"}${convert([
                { cord: latitude, v: latitude > 0 ? "N" : "S" },
                { cord: longitude, v: "W" },
              ])}/@${latitude}/${longitude},674m/data=!3m1!1e3!4m5!3m4!1s0x0:0x0!8m2!3d${latitude}!4d${longitude}`;
            }
          } else {
            self.loadeEnd = false;
            self.addressDisplay = "Endereço não localizado";
          }
        }
      );
    } catch (error) {
      self.loadeEnd = false;
      console.error(error);
    }
  }

  onCancel(): void {
    this.dialogRef.close({
      payload: null,
      position: {
        adress: this.addressDisplay,
        linkMap: this.linkMap,
      },
    });
  }

  onSave = (): void => {
    let msg = this.lastAnswers[this.lastAnswers.length - 1].msg;
    let m = this.data?.dsResposta ? this.data?.dsResposta : null;

    if (m && msg) {
      m = m + " " + msg.replace(/\r?\n|\r/, " ") + "\n";
    } else {
      if (msg) {
        m = msg.replace(/\r?\n|\r/, " ") + "\n";
      }
    }

    return this.dialogRef.close({
      payload: {
        idUsuarioFinalizacao: this.finalizar
          ? storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id
          : null,
        dsResposta: m,
        flFinalizado: this.finalizar,
      },
      position: {
        adress: this.addressDisplay,
        linkMap: this.linkMap,
      },
    });
  };

  private toDegreesMinutesAndSeconds(coordinates) {
    let v = "";

    coordinates.forEach((element) => {
      var absolute = Math.abs(element.cord);
      var degrees = Math.floor(absolute);
      var minutesNotTruncated = (absolute - degrees) * 60;
      var minutes = Math.floor(minutesNotTruncated);
      var seconds = Math.floor((minutesNotTruncated - minutes) * 60);
      v = v + degrees + "º" + minutes + "'" + seconds + '"' + element.v + " ";
    });

    return v;
  }

  public getAdditionalDataType(additionalDataType: any) {
      return AdditionalDataType[additionalDataType];
  }

  public getAdditionalValue(additionalDataType: any, dataValue: number): string {
    switch (additionalDataType) {
      case 'A': return dataValue + ' segundos';
      case 'F': return 'Código da cerca: ' + dataValue;
      case 'I': return dataValue + 'º';
      case 'L': return dataValue + ' %';
      case 'R': return dataValue + ' RPM';
      case 'S': return dataValue === 1 ? 'Ativada' : 'Desativada';
      case 'P': return dataValue + ' bar';
      case 'T': return dataValue + ' C';
      case 'V': return dataValue + ' km/h';

    }
  }
}
