import { NgxSpinnerService } from "ngx-spinner";
import {
  STORAGE_KEY_CURRENT_PERFIL,
  STORAGE_KEY_CURRENT_VEHICLES,
  STORAGE_KEY_ID_MANAGER,
  STORAGE_KEY_ID_SUPERVISOR,
  STORAGE_KEY_PATHS,
} from "../storageCore/constStorageKeys";
import {
  STORAGE_KEY_ID_PERFIL,
  STORAGE_KEY_PERMISSIONS,
} from "../storageCore/constStorageKeys";
import { Component, OnInit, Injector } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "./../services/authentication.service";
import { AppPerfilSelectorComponent } from "./app-perfil-selector/app-perfil-selector.component";
import storageX from "../storageCore/storageX";
import keepLoggedIn from "../storageCore/keepLoggedIn";
import { User } from "../models/User.model";
import { TransporterService } from "../services/transporter.service";
import { AppUserSelectorComponent } from "./app-user-selector/app-user-selector.component";

@Component({
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  returnUrlPrivate: string;
  dialog: MatDialog;
  public errorMessage = false;
  public checkBox = {
    checked: false,
    color: "primary",
  };

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private spinner: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    public injector: Injector,
    private transporterService: TransporterService
  ) {
    this.verifyKeepLoggedIn();

    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["/"]);
    }
    this.dialog = this.injector.get(MatDialog);
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });

    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";

    this.returnUrlPrivate =
      this.route.snapshot.queryParams["returnUrl"] || "/settings";
  }

  private verifyKeepLoggedIn(): void {
    console.log(keepLoggedIn.statusKeepLoggedIn());
    if (keepLoggedIn.statusKeepLoggedIn()) {
      this.checkBox.checked = true;
      return;
    }

    this.checkBox.checked = false;
  }

  public change(value) {
    this.checkBox.checked = value;
    keepLoggedIn.keepLoggedIn(value);
  }

  get f() {
    return this.loginForm.controls;
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;

    this.errorMessage = false;

    if (this.loginForm.invalid) {
      return;
    }

    try {
      this.spinner.show();

      const login = await this.authenticationService.login(
        this.f.username.value,
        this.f.password.value
      );

      var managerId = storageX.whereKeyIs(STORAGE_KEY_ID_MANAGER).get();
      var supervisorId = storageX.whereKeyIs(STORAGE_KEY_ID_SUPERVISOR).get();

      if (managerId != null && supervisorId != null) {
        this.spinner.hide();
        await this.openSelector();
      }

      this.spinner.show();

      if (login.perfis.length === 1) {
        storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).set(login.perfis[0].id);
        storageX.whereKeyIs(STORAGE_KEY_CURRENT_PERFIL).set(login.perfis[0]);

        this.currentFuncionalidades(login.perfis[0]);
        this.currentVehicles(login);

        this.spinner.hide();

        this.router.navigate([this.returnUrl]);
      } else if (login.perfis.length > 1) {
        this.spinner.hide();
        await this.openPerfilSelector();
      }
    } catch (exception) {
      this.spinner.hide();
      this.errorMessage = true; //exception.message;
    }
  }
  openPerfilSelector = () => {
    return new Promise((resolve, reject) => {
      this.dialog
        .open(AppPerfilSelectorComponent, {
          disableClose: true,
          panelClass: "custom-dialog",
          // maxWidth: 700,
        })
        .afterClosed()
        .subscribe((data) => {
          this.dialog.closeAll();
          //this.openFirstPage(this.returnUrl);
          this.router.navigate([""]);
          resolve(0);
        });
    });
  };

  openSelector = () => {
    return new Promise((resolve, reject) => {
      this.dialog
        .open(AppUserSelectorComponent, {
          disableClose: true,
          panelClass: "custom-dialog",
          // maxWidth: 700,
        })
        .afterClosed()
        .subscribe((data) => {
          this.dialog.closeAll();
          resolve(0);
        });
    });
  };

  private currentFuncionalidades(perfil): void {
    const permissions = [];
    const paths = [];
    const { funcionalidades = [] } = perfil;

    funcionalidades.forEach((permissao) => {
      const {
        cdFuncionalidade: cdPermissao,
        nmFuncionalidadeTipo: cdModulo,
        cdPath,
      } = permissao;

      const atualizacao = cdPermissao + "_ATUALIZACAO_" + cdModulo;
      const leitura = cdPermissao + "_LEITURA_" + cdModulo;
      const delecao = cdPermissao + "_DELECAO_" + cdModulo;
      const insercao = cdPermissao + "_INSERCAO_" + cdModulo;

      if (permissao.ativo) {
        permissions.push(cdPermissao + "_" + cdModulo);
        paths.push(cdPath);
      }

      if (permissao.flAtualizacao) {
        permissions.push(atualizacao);
      }

      if (permissao.flDelecao) {
        permissions.push(delecao);
      }

      if (permissao.flInsercao) {
        permissions.push(insercao);
      }

      if (permissao.flLeitura) {
        permissions.push(leitura);
      }
    });

    if (permissions.length > 0) {
      storageX.whereKeyIs(STORAGE_KEY_PERMISSIONS).set(permissions);

      //localStorage.setItem('permissions', JSON.stringify(permissions));
    }
    if (permissions.length > 0) {
      storageX.whereKeyIs(STORAGE_KEY_PATHS).set(paths);

      // localStorage.setItem('paths', JSON.stringify(paths));
    }
  }

  private currentVehicles(login: User): void {
    if (login.perfis[0].tipoPerfil === "AGREGADO_TRANSPORTADOR") {
      this.transporterService.search(login.id).subscribe((v) => {
        storageX.whereKeyIs(STORAGE_KEY_CURRENT_VEHICLES).set(v.vehicles);
      });
    }
  }
}
