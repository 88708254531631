/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  NgZone,
} from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MapToolboxService } from './../../../services/map.toolbox.service';
import { MatDialogRef } from '@angular/material/dialog';

declare let google: any;

@Component({
  selector: 'app-proximity',
  templateUrl: './proximity.component.html',
  styleUrls: ['./proximity.component.scss'],
})
export class ProximityComponent implements OnInit {
  private geoCoder;

  tabSelected = 0;

  latitude: number;
  longitude: number;
  distance: number;
  command: any;
  address: any;

  private service: MapToolboxService;

  @ViewChild('search_proximity') searchElementRef: ElementRef;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    service: MapToolboxService,
    public dialogRef: MatDialogRef<any>
  ) {
    this.service = service;
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tabSelected = tabChangeEvent.index;
  }

  setTab(index: number): void {
    console.log('index', index);
  }

  distanceChanged(event: any): void {
    this.distance = event.target.value;
  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();

      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log('place', place);

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.address = place.formatted_address;
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
        });
      });
    });
  }

  submit(): void {
    if (!this.distance) { return; }

    const distance = this.distance;

    if (this.tabSelected === 0) {
      if (!this.latitude || !this.longitude) { return; }

      const data = {
        action: 'proximity_location',
        values: {
          latitude: this.latitude,
          longitude: this.longitude,
          distance,
        },
      };
      this.service.setCommand(data);
    }
    if (this.tabSelected === 1) {
      const data = {
        action: 'proximity_map',
        values: {
          distance,
        },
      };
      this.service.setCommand(data);
      this.dialogRef.close();
    }
  }
}
