import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { Maintenance } from "../../models/Maintenance.model";
import { ApiService } from "../../services/api.service";
import { MaintenanceInterface } from "../../contracts/Maintenance.interface";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Page } from "src/app/utils/paginate";
import { CoreService } from "src/app/services/migrate/api.core.service";

@Component({
  selector: "app-maintenance",
  templateUrl: "./maintenance.component.html",
  styleUrls: ["./maintenance.component.scss"],
})
export class MaintenanceComponent implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('filter') filter: ElementRef;
  // @ViewChild('typeFilter') typeFilter: ElementRef;
  public filter;
  public typeFilter;

  public visible;

  public page = new Page();

  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[] = [
    //'id',
    "empresa",
    "veiculo",
    "dtManutencao",
    "nrQuilometragem",
    "dsDescricao",
    "flAlarmeGerado",
    "update",
    "clone",
    "view",
    "delete",
  ];

  public filtros = [
    //{ campo: 'id', valor: 'ID' },
    { campo: "veiculo.empresa.nmNome", valor: "Empresa" },
    { campo: "nrQuilometragem", valor: "KM" },
    { campo: "dsDescricao", valor: "Descrição" },
  ];

  private service: ApiService;
  private coreService: CoreService;

  public maintenances: MatTableDataSource<MaintenanceInterface>;

  constructor(
    apiCoreService: CoreService,
    apiService: ApiService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.service = apiService;
    this.coreService = apiCoreService;

    this.maintenances = new MatTableDataSource<MaintenanceInterface>([]);
  }

  ngOnInit(): void {
    this.loadMaintenances();
  }
  // public handleFilter = (value: string): void => {
  //   this.users.filter = value.trim().toLocaleLowerCase();
  // };

  public handleSort = (sort): void => {
    this.loadMaintenances(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadMaintenances(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadMaintenances();
  };

  public handleDelete = (id: string): void => {
    this.deleteMaintenance(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/maintenance/${id}`]);
  };

  ngAfterViewInit(): void {
    this.maintenances.sort = this.sort;
    this.maintenances.paginator = this.paginator;
  }

  public async deleteMaintenance(id): Promise<void> {
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        await this.service.delete<Maintenance>(id, "veiculo-manutencao");

        this.spinner.hide();

        this.loadMaintenances();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public async loadMaintenances(pageIndex = 0, sort?): Promise<void> {
    this.spinner.show();

    const params = {
      size: this.pageSize,
      page: pageIndex,
    };

    try {
      const result = await this.service.get<any>({
        url: "v2/maintenance",
        params,
      });

      this.spinner.hide();

      this.maintenances.data = result.elements;
      this.page = result.page;
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
