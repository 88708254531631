
<form [formGroup]="axisFiveDoubleLeftIntForm">
    <div class="form-row">
        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Posição Pneu</mat-label>
              <input formControlName="cdPosicaoPneu"  placeholder="Posição Pneu" matInput [readonly]=true [disabled]=true/>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Pneu(nr de fogo)</mat-label>
                    <input  formControlName="pneu"  
                    placeholder="Número de fogo" matInput [readonly]=true [disabled]=true/>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Sensor</mat-label>
              <mat-select formControlName="cdSensor" [(value)]="sensorFiveIntLeftSelect" [(ngModel)]="sensorFiveIntLeftSelect">
                <mat-option *ngFor="let type of sensorFiveIntLeftCombo" [value]="type.id">
                  {{ type.codigoSensor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Min (PSI)</mat-label>
                <input required formControlName="nrPressaoMinima" pattern="[0-9]*" matInput
                 placeholder="Pressão Min (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Máx (PSI)*</mat-label>
                <input required formControlName="nrPressaoMaxima" pattern="[0-9]*" matInput
                placeholder="Pressão Max (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Temp. Máx (°C)*</mat-label>
                <input required formControlName="nrTemperaturaMaxima" required pattern="[0-9]*"  matInput
                placeholder="Temp. Máx (°C)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>
        <div class="form-group col-lg-3">
            <button type="submit" [disabled]="axisFiveDoubleLeftIntForm.get('id').value == '' || axisFiveDoubleLeftIntForm.get('cdSensor').value == ''" style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDelete(axisFiveDoubleLeftIntForm)">
                        Desvincular Sensor
            </button>
        </div>
    </div>
</form>
<form [formGroup]="axisFiveDoubleLeftExtForm">
            <div class="form-row">

                <div class="form-group col-lg-2">
                    <mat-form-field appearance="outline">
                      <mat-label>Posição Pneu</mat-label>
                      <input formControlName="cdPosicaoPneu"  placeholder="Posição Pneu" matInput [readonly]=true [disabled]=true/>
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-2">
                    <mat-form-field appearance="outline">
                            <mat-label>Pneu(nr de fogo)</mat-label>
                            <input  formControlName="pneu"  
                            placeholder="Número de fogo" matInput [readonly]=true [disabled]=true/>
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-2">
                    <mat-form-field appearance="outline">
                      <mat-label>Sensor</mat-label>
                      <mat-select formControlName="cdSensor" [(value)]="sensorFiveExtLeftSelect" [(ngModel)]="sensorFiveExtLeftSelect">
                        <mat-option *ngFor="let type of sensorFiveExtLeftCombo" [value]="type.id">
                          {{ type.codigoSensor }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-1">
                    <mat-form-field appearance="outline">
                            <mat-label>Pressão Min (PSI)</mat-label>
                        <input required formControlName="nrPressaoMinima" pattern="[0-9]*" matInput
                         placeholder="Pressão Min (PSI)" (keypress)="numberOnly($event)" />
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-1">
                    <mat-form-field appearance="outline">
                            <mat-label>Pressão Máx (PSI)*</mat-label>
                        <input required formControlName="nrPressaoMaxima" pattern="[0-9]*" matInput
                        placeholder="Pressão Max (PSI)" (keypress)="numberOnly($event)" />
                    </mat-form-field>
                </div>
        
                <div class="form-group col-lg-1">
                    <mat-form-field appearance="outline">
                            <mat-label>Temp. Máx (°C)*</mat-label>
                        <input required formControlName="nrTemperaturaMaxima" required pattern="[0-9]*"  matInput
                        placeholder="Temp. Máx (°C)" (keypress)="numberOnly($event)" />
                    </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                    <button type="submit" [disabled]="axisFiveDoubleLeftExtForm.get('id').value == '' || axisFiveDoubleLeftExtForm.get('cdSensor').value == ''" style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDelete(axisFiveDoubleLeftExtForm)">
                                Desvincular Sensor
                    </button>
                </div>
            </div>
    </form>

    <form [formGroup]="axisFiveDoubleRightIntForm">
        <div class="form-row">

            <div class="form-group col-lg-2">
                <mat-form-field appearance="outline">
                  <mat-label>Posição Pneu</mat-label>
                  <input formControlName="cdPosicaoPneu"  placeholder="Posição Pneu" matInput [readonly]=true [disabled]=true/>
                </mat-form-field>
            </div>
    
            <div class="form-group col-lg-2">
                <mat-form-field appearance="outline">
                        <mat-label>Pneu(nr de fogo)</mat-label>
                        <input  formControlName="pneu"  
                        placeholder="Número de fogo" matInput [readonly]=true [disabled]=true/>
                </mat-form-field>
            </div>
    
            <div class="form-group col-lg-2">
                <mat-form-field appearance="outline">
                  <mat-label>Sensor</mat-label>
                  <mat-select formControlName="cdSensor" [(value)]="sensorFiveIntRightSelect" [(ngModel)]="sensorFiveIntRightSelect">
                    <mat-option *ngFor="let type of sensorFiveIntRightCombo" [value]="type.id">
                      {{ type.codigoSensor }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
    
            <div class="form-group col-lg-1">
                <mat-form-field appearance="outline">
                        <mat-label>Pressão Min (PSI)</mat-label>
                    <input required formControlName="nrPressaoMinima" pattern="[0-9]*" matInput
                     placeholder="Pressão Min (PSI)" (keypress)="numberOnly($event)" />
                </mat-form-field>
            </div>
    
            <div class="form-group col-lg-1">
                <mat-form-field appearance="outline">
                        <mat-label>Pressão Máx (PSI)*</mat-label>
                    <input required formControlName="nrPressaoMaxima" pattern="[0-9]*" matInput
                    placeholder="Pressão Max (PSI)" (keypress)="numberOnly($event)" />
                </mat-form-field>
            </div>
    
            <div class="form-group col-lg-1">
                <mat-form-field appearance="outline">
                        <mat-label>Temp. Máx (°C)*</mat-label>
                    <input required formControlName="nrTemperaturaMaxima" required pattern="[0-9]*"  matInput
                    placeholder="Temp. Máx (°C)" (keypress)="numberOnly($event)" />
                </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
                <button type="submit" [disabled]="axisFiveDoubleRightIntForm.get('id').value == '' || axisFiveDoubleRightIntForm.get('cdSensor').value == ''" style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDelete(axisFiveDoubleRightIntForm)">
                            Desvincular Sensor
                </button>
            </div>
        </div>
</form>

<form [formGroup]="axisFiveDoubleRightExtForm">
    <div class="form-row">

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Posição Pneu</mat-label>
              <input formControlName="cdPosicaoPneu"  placeholder="Posição Pneu" matInput [readonly]=true [disabled]=true/>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
                    <mat-label>Pneu(nr de fogo)</mat-label>
                    <input  formControlName="pneu"  
                    placeholder="Número de fogo" matInput [readonly]=true [disabled]=true/>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Sensor</mat-label>
              <mat-select formControlName="cdSensor" [(value)]="sensorFiveExtRightSelect" [(ngModel)]="sensorFiveExtRightSelect">
                <mat-option *ngFor="let type of sensorFiveExtRightCombo" [value]="type.id">
                  {{ type.codigoSensor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Min (PSI)</mat-label>
                <input required formControlName="nrPressaoMinima" pattern="[0-9]*" matInput
                 placeholder="Pressão Min (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Pressão Máx (PSI)*</mat-label>
                <input required formControlName="nrPressaoMaxima" pattern="[0-9]*" matInput
                placeholder="Pressão Max (PSI)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>

        <div class="form-group col-lg-1">
            <mat-form-field appearance="outline">
                    <mat-label>Temp. Máx (°C)*</mat-label>
                <input required formControlName="nrTemperaturaMaxima" required pattern="[0-9]*"  matInput
                placeholder="Temp. Máx (°C)" (keypress)="numberOnly($event)" />
            </mat-form-field>
        </div>
        <div class="form-group col-lg-3">
            <button type="submit" [disabled]="axisFiveDoubleRightExtForm.get('id').value == '' || axisFiveDoubleRightExtForm.get('cdSensor').value == ''" style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDelete(axisFiveDoubleRightExtForm)">
                        Desvincular Sensor
            </button>
        </div>
        <div class="form-group col-lg-12" style="text-align: center;">
            <button type="submit"  style="font-size: 11px;padding: 13px 13px;background: #dc3545;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormDeleteAll()">
                Desvincular Sensores (EIXO 5)
            </button>
            <button type="submit"  style="font-size: 11px;padding: 13px 13px;" class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" (click)="this.submitFormAddAll()">
                Vincular Sensores (EIXO 5)
            </button>
        </div>
    </div>
</form>