<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>Novo Gestor</app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="managerForm" (ngSubmit)="submitForm()">
                    <h2 class="mb-3">Dados do novo gestor</h2>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Usuarios Disponiveis</mat-label>

                                <mat-select formControlName="selectUser" [(value)]="managerSelect" required>
                                    <mat-option *ngFor="let type of managersFree" [value]="type.userId">
                                        {{ type.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit" [disabled]="!managerForm.valid"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Salvar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>