// tslint:disable:variable-name
export class GrantCodeRequest {
  grant_type: string;
  scope: string;

  constructor() {
    this.grant_type = "";
    this.scope = "";
  }
}
