<button class="close" mat-button (click)="onCancel()">X</button>
<h2 mat-dialog-title>Tratamento de Eventos</h2>
<mat-dialog-content class="mat-typography">
  <div fxLayout="row" fxLayoutGap="10">
    <div fxFlex="60%">
      <mat-card style="margin-right: 16px; margin-bottom: 10px;">
        <mat-card-content *ngIf="this.data?.nome">
          <div fxLayout="row" style="margin-bottom: 10px;">
            <div fxFlex="40%">
              <small>Nome do Motorista:</small> <br />
              {{ this.data?.nome }}
            </div>
            <div fxFlex="30%">
              <small> Telefone: </small> <br />
              {{ this.data?.nrTelefone }}
            </div>
          </div>
          <div *ngIf="this.data?.endereco " fxLayout="row" style="margin-top: 10px;">
            <div fxFlex="70%">
              <small> Endereço: </small> <br />
              <a (click)="this.goGoogleMapsDriverAddress()" [routerLink]="'/events/' + this.data.idTerminalAlarme">
                {{ this.data?.endereco }} -
                {{ this.data?.cdNumero }} -
                {{ this.data?.nmBairro }} -
                {{ this.data?.nmCidade }} -
                {{ this.data?.nmEstado }} -
                {{ this.data?.nmPais }}.
              </a>
            </div>
          </div>

        </mat-card-content>
        <br>
        <mat-card-content>
          <div fxLayout="row" style="margin-top: 10px;">
            <div fxFlex="40%">
              <small> Empresa/Filial: </small> <br />
              {{ this.data.nmEmpresa != null ? this.data.nmEmpresa : "N/D"}}
            </div>
            <div fxFlex="40%" style="margin-left: 10%;">
              <small> Divisão: </small> <br />
              {{ this.data.nmDivisao != null ? this.data.nmDivisao : "N/D"}}
            </div>
          </div>
        </mat-card-content>
        <mat-divider></mat-divider>
        <br>
        <mat-card-content *ngIf="this.addressDisplay">
          <div fxLayout="row" style="margin-top: 10px;">
            <div fxFlex="60%">
              <small> Placa: </small> <br />
              {{ this.data.placa }}
            </div>
            <div fxFlex="30%">
              <small> Horário: </small> <br />
              {{ this.data.dhAlarme | date: 'dd/MM/yyyy HH:mm:ss' }}
            </div>
          </div>
          <div fxLayout="row" style="margin-top: 10px;">
            <div fxFlex="70%">
              <small> Localização do evento:
                <span style="
                color: #f44336;
                position: relative;
                top: 3px;" class="material-icons">
                location_on
                </span>
               </small> <br />
              <a  [href]="this.linkMap" target="blank" >{{ addressDisplay }}</a>
            </div>
          </div>
        </mat-card-content>

        <mat-card-content *ngIf="!this.addressDisplay && !this.loadeEnd">
          <div fxLayout="row" style="margin-top: 10px;">
            <div fxFlex="60%">
              <small> Placa: </small> <br />
              {{ this.data.placa }}
            </div>
            <div fxFlex="30%">
              <small> Horário: </small> <br />
              {{ this.data.dhAlarme | date: 'dd/MM/yyyy HH:mm:ss' }}
            </div>
          </div>
          <div fxLayout="row" style="margin-top: 10px;">
            <div fxFlex="100%">
              <small> Erro ao acessar api google </small> 
            </div>
          </div>
        </mat-card-content>
        <mat-card-content *ngIf="!this.data?.nome">
          <div fxLayout="row" style="margin-top: 10px;">
            <div fxFlex="70%">
           <br>
              <a>
                {{this.data?.dsResposta}}
              </a>

            </div>
            <div fxFlex="30%">
              <small> Horário: </small> <br />
              {{ this.data.dhAlarme | date: 'dd/MM/yyyy HH:mm:ss' }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>

        <mat-card style="margin-bottom: 17px; margin-right: 16px">
            <mat-card-content>
                <h3>Dados adicionais do alarme</h3>
                <div fxLayout="row" style="margin-top: 10px;">
                    <div fxFlex="60%">
                        <small> Tipo: </small> <br />
                        {{ this.getAdditionalDataType(this.data.tipoDadoComplementar)}}
                    </div>
                    <div fxFlex="40%">
                        <small> Valor: </small> <br />
                        {{ this.getAdditionalValue(this.data.tipoDadoComplementar, this.data.dadoComplementar)}}
                    </div>
                </div>

            </mat-card-content>
        </mat-card>

    <mat-card style="margin-bottom: 17px;" *ngIf="this.lastAnswers.length > 1">

      <h3 class="title-msg">Mensagens</h3>

      <section class="page-container">
        <section class="page">
          <div class="row">
            <ul id="timeline" class="timeline">
              <div style="visibility: hidden;" class="arrowhead"></div>
              <li *ngFor="let i of this.lastAnswers;" [ngClass]="(i.id % 2 == 0) ? ' ' : 'timeline-inverted'" >
                <div *ngIf="i.msg" class="timeline-badge">Msg {{i.id - 1}}</div>
                <div *ngIf="i.msg" class="timeline-panel">

                  <div class="timeline-heading">
                    <h3 class="timeline-title">{{i?.msg}}</h3>
                  </div>
                  <div class="timeline-body">

                  </div>
                </div>
              </li>
              <li style="visibility: hidden;" *ngIf="this.lastAnswers.length == 2" class='timeline-inverted' >
                <div class="timeline-badge"></div>
                <div class="timeline-panel">

                  <div class="timeline-heading">
                    <h3 class="timeline-title">
                      hidden as long as there is only one message
                    </h3>
                  </div>
                  <div class="timeline-body">

                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </section>

    </mat-card>


      <mat-form-field appearance="outline" style="width: 98%;">
        <mat-label>Solução</mat-label>
        <textarea rows="3" cols="300" maxlength="5000" matInput placeholder="Digite a resposta"
          [(ngModel)]="this.lastAnswers[this.lastAnswers.length -1].msg"></textarea>
      </mat-form-field>

      <div fxLayout="row">
        <div fxFlex="100%">
          <mat-form-field appearance="outline" style="width: 98%;">
            <mat-label>Orientações</mat-label>
            <textarea rows="3" cols="300" maxlength="5000" matInput placeholder="Digite a resposta"
              [(ngModel)]="orientacao" readonly></textarea>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="50%">
          <button [disabled]="!this.lastAnswers[this.lastAnswers.length -1].msg && !this.finalizar" class="ml-1" mat-flat-button color="primary" (click)="onSave()" cdkFocusInitial>
            Salvar
          </button>
        </div>
        <div fxFlex="50%">
          <div style="text-align: right; padding-right: 10px;">
            <mat-slide-toggle [(ngModel)]="finalizar" color="primary">
              Selecione para finalizar
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="40%" style="padding-left: 20px; background: #f2f2f2;">
      <div style="text-align: center;">
        <h6 class="my-3" style="font-size: 15px;">Histórico de Eventos no mesmo dia</h6>
        <small *ngIf="events.length === 0">Nenhum evento até o momento...</small>
      </div>

      <mat-card *ngFor="let element of events" class="card-rigth">
        <span
        style="
        font-size: 16px;
        float: right;
        color: #2d9e36;"
        title="Finalizado"
        class="material-icons destacarResolvido">
        verified
        </span>

        <mat-card-header>
          <mat-card-title *ngIf="element?.nmUsuario">{{
            element?.nmUsuario
            }}</mat-card-title>
          <mat-card-subtitle *ngIf="element?.dhVisualizacao">
           <span class="title-r-card">Início do Tratamento:</span>
           <span class="title-r-card-v">
            {{ element?.dhVisualizacao | date: 'dd/MM/yyyy HH:mm:ss' }}
           </span>

          </mat-card-subtitle>

          <mat-card-subtitle *ngIf="element?.dhFinalizacao">
              <span class="title-r-card">Finalização:</span>
              <span class="title-r-card-v">
                {{ element?.dhFinalizacao | date: 'dd/MM/yyyy HH:mm:ss' }}
              </span>
          </mat-card-subtitle>
        </mat-card-header>
        <br />
        <mat-divider></mat-divider>

        <mat-card-content>
          <br/>
          <small style="padding-top: 10px;">Conclusão</small>
          <br/>
          <section class="page-container">
            <section class="page">
              <div class="row t2">
                <ul id="timeline" class="timeline">
                  <div style="visibility: hidden;" class="arrowhead"></div>
                  <li *ngFor="let i of element.msgs;" [ngClass]="(i.id % 2 == 0) ? ' ' : 'timeline-inverted'" >
                    <div *ngIf="i.msg" class="timeline-badge">Msg {{i.id - 1}}</div>
                    <div *ngIf="i.msg" class="timeline-panel">

                      <div class="timeline-heading">
                        <h3 style="font-size: 11.7px;" class="timeline-title">{{i.msg}}</h3>
                      </div>
                      <div class="timeline-body">

                      </div>
                    </div>
                  </li>
                  <li style="visibility: hidden;" *ngIf="element.msgs.length <= 2" class='timeline-inverted' >
                    <div class="timeline-badge"></div>
                    <div class="timeline-panel">

                      <div class="timeline-heading">
                        <h3 class="timeline-title">
                          hidden as long as there is only one message
                        </h3>
                      </div>
                      <div class="timeline-body">

                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </section>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-dialog-content>
