import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Sensor } from "../../models/Sensor.model";
import { Validation } from "./validation";
import { ApiBaseService } from "../../services/migrate/api.base.service";
import { Company } from "src/app/models/Company.model";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

@Component({
  selector: "app-create-sensor",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ViewSensorComponent implements OnInit {
  public sensorForm: FormGroup;
  public sensor: Sensor;
  public sensorId: number;
  public title = "Visualizar Sensor";
  public errorMessage;
  private apiBaseService: ApiBaseService;
  validation: Validation;

  public empresaSelected;
  public empresaFilterSelected;

  public companies: any = [];
  public companiesFilter: any = [];
  public companiesSelect: any = [
    { name: "Divisão", value: 1 },
    { name: "Matriz", value: 2 },
    { name: "Filial", value: 3 },
  ];

  ngOnInit(): void {
    this.sensorForm = this.formBuilder.group(this.validation.rules());
    this.sensorId = this.activatedRoute.snapshot.params["id"];
    this.loadSensor(this.sensorId);
  }
  public async loadSensor(id): Promise<void> {
    try {
      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      const result = await this.apiBaseService.get<any>({
        url: new Company()["nomeConsultaApiBaseGetAll"],
        params,
      });

      this.companies = result["content"];

      const response = await this.apiBaseService.get<Sensor>({
        url: new Sensor()["nomeConsultaApiBase"] + "/" + id,
      });
      this.sensor = response as Sensor;

      if (this.sensor.idEmpresa != null) {
        if (this.sensor.idEmpresaMatriz == null) {
          this.empresaFilterSelected = "Matriz";
        } else {
          this.empresaFilterSelected = "Filial";
        }
      } else {
        this.empresaFilterSelected = "Divisão";
      }

      this.filterCompany();

      if (this.sensor.idEmpresa != null) {
        this.sensorForm.controls["idEmpresa"].setValue(this.sensor.idEmpresa);
      } else {
        this.sensorForm.controls["idEmpresa"].setValue(this.sensor.idDivisao);
      }

      this.fillUserData();
    } catch (error) {
      console.error(error);
    }
  }
  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }

  public filterCompany() {
    var idEmpresa = this.empresaFilterSelected;
    this.sensorForm.controls["idEmpresa"].setValue(null);
    var empresas = [];

    if (idEmpresa == "Divisão") {
      this.companies.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Matriz") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Filial") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    console.log(this.companiesFilter);
  }

  fillUserData(): void {
    const fields = ["codigoSensor"];
    fields.forEach((element) => {
      this.sensorForm.controls[element].setValue(this.sensor[element]);
    });
  }
}
