import { ToolsService } from "./../../services/migrate/tools.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { REG_BLOCKSPACE } from "src/app/services/migrate/regex";
import Swal from "sweetalert2";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-validate-code",
  templateUrl: "./validate-code.component.html",
  styleUrls: ["./validate-code.component.scss"],
})
export class ValidateCodeComponent implements OnInit {
  public validateCodeForm: FormGroup;
  public token: string;
  public insert: string;
  public recover = true;

  public blockSpace = REG_BLOCKSPACE;
  private id;

  constructor(
    private formBuilder: FormBuilder,
    public tools: ToolsService,
    private route: ActivatedRoute
  ) {
    // this.tools.builder().api.oAuth();
    // this.setToken();
  }

  ngOnInit() {
    this.validateCodeForm = this.formBuilder.group({
      input: ["", Validators.required],
    });
  }

  private setToken(): void {
    const token = this.route.snapshot.queryParams["token"];

    const index = token.indexOf(")");
    this.id = token.substring(index + 1, 0).substring(index, 1);
    this.token = token.substring(index + 1, token.length);
  }

  public valueUperCase(value) {
    if (value.target.value) {
      value.target.value = value.target.value.toUpperCase();
    }
  }

  public validate() {
    const stringsArray: Array<string> = this.token.split(" ");
    this.token = stringsArray.toString().replace(new RegExp(",", "g"), "+");

    const payload = {
      token: this.token,
      code: this.validateCodeForm.value.input,
    };

    this.tools.builder().spinner.show();

    this.tools
      .builder()
      .api.post(
        `${this.tools.builder().api.getApiUrl().notify}/token/checks-codes`,
        payload
      )
      .subscribe(() => {
        this.tools.builder().spinner.hide();

        Swal.fire(
          "Tudo certo",
          "Agora vamos criar sua nova senha",
          "success"
        ).then(() => {
          this.tools
            .builder()
            .router.navigate([`/create-new-password/${this.id}`]);
        });
      });
  }
}
