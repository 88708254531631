import { EmpresaSeguroRctrc } from "./EmpresaSeguroRctrc.model";
import { EmpresaAutorizacaoAntt } from "./EmpresaAutorizacaoAntt.model";

export class Company {
  nomeConsultaApiBase = "empresas";
  nomeConsultaApiBaseDelete = "empresas/divisao";
  nomeConsultaApiBaseGet = "empresas/divisao";
  nomeConsultaApiBaseGetAll = "empresas/list";
  listCamposConsulta = ["nome", "email", "contato"];
  permissaoVisualizacao = "COMPANY_ATUALIZACAO_FUNCIONALIDADE";
  permissaoDelecao = "COMPANY_DELECAO_FUNCIONALIDADE";
  enderecoRoute = "companies";

  ativo?: boolean;
  id?: number;
  nome: string;
  razaoSocial: string;
  cnpj: string;
  inscricaoEstadual: string;
  instricalMunicipal: string;
  idEmpresaMatriz: number;
  idEmpresaTipo: number;
  empresaTipoNome: string;
  contato: string;
  fax: string;
  telefone: string;
  email: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  pais: string;
  observacao: string;
  creditoSms: number;
  exibePainel: boolean;
  empresaMatriz: Company;
  listEmpresaAutorizacaoAnttResponse: EmpresaAutorizacaoAntt[];
  listEmpresaSeguroRctrcResponse: EmpresaSeguroRctrc[];
}
