import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Modulo } from "../../models/Modulo.model";
import { Validation } from "./validation";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-create-modulo",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CreateModuloComponent implements OnInit {
  public moduloForm: FormGroup;
  public title = "Cadastrar Módulo";
  public errorMessage;
  private validation: Validation;
  private apiBaseService: ApiBaseService;

  ngOnInit(): void {
    this.moduloForm = this.formBuilder.group(this.validation.rules());
  }
  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();
    try {
      this.coverterUpperCase(this.moduloForm);
      const values = this.moduloForm.value;
      const post = {
        ...values,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };
      await this.apiBaseService.post<Modulo>(
        new Modulo()["nomeConsultaApiBase"],
        post
      );
      this.spinner.hide();
      this.router.navigate(["/modulo"]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public coverterUpperCase(moduloForm: FormGroup) {
    console.log(moduloForm);

    moduloForm
      .get("nmModulo")
      .setValue(moduloForm.get("nmModulo").value.toUpperCase());
    moduloForm
      .get("dsModulo")
      .setValue(moduloForm.get("dsModulo").value.toUpperCase());
    moduloForm
      .get("cdPath")
      .setValue(moduloForm.get("cdPath").value.toUpperCase());
    moduloForm
      .get("cdIcone")
      .setValue(moduloForm.get("cdIcone").value.toUpperCase());
  }
}
