<h2 mat-dialog-title>Criar Ponto</h2>

<div class="container container-body">
  <div *ngIf="isLoader" style="justify-items: center; width: 100%;">
    <mat-spinner
      value="10"
      style="margin: 0 auto;"
      class="loader"
    ></mat-spinner>
  </div>

  <div class="row">
    <div class="col-sm-12" *ngIf="!isLoader">
      <mat-form-field appearance="outline">
        <mat-label>Nome do Ponto:</mat-label>
        <input
          matInput
          [(ngModel)]="markerName"
          placeholder="informe o nome do ponto"
        />
      </mat-form-field>
    </div>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fechar</button>
  <button
    mat-flat-button
    (click)="onSubmit()"
    color="primary"
    [disabled]="!markerName || isLoader"
  >
    CONFIRMAR
  </button>
</mat-dialog-actions>
