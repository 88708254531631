import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AnalyzeTripService } from "../../../services/analyze-trip.service";
import { toCamel } from "snake-camel";
import { ToolsService } from "src/app/services/migrate/tools.service";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

import { ManagerModel } from "src/app/models/manager-model";

@Component({
  selector: 'app-create-manager',
  templateUrl: './create-manager.component.html',
  styleUrls: ['./create-manager.component.scss']
})
export class CreateManagerComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public managerSelect;
  public managersFree: any = [];

  public managerForm: FormGroup;

  public errorMessage;

  ngOnInit(): void {
    this.managerForm = this.formBuilder.group(
      {
        selectUser: ["", Validators.required],
      },
    );

    this.loadDataElements();
  }

  constructor(
    private tools: ToolsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    analyzeTripService: AnalyzeTripService
  ) {
    this.analyzeTripService = analyzeTripService;
  }


  public async loadDataElements() {
    try {
      this.analyzeTripService
        .getByManagerFree()
        .subscribe((resp) => {
          this.managersFree = toCamel(resp);
        });

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }


  public async submitForm(): Promise<void> {
    this.spinner.show();
    const values = this.managerForm.value;

    var manager: ManagerModel = new ManagerModel();
    manager.idUser = values.selectUser;
    manager.userCreateId = storageX
      .whereKeyIs(STORAGE_KEY_CURRENT_USER)
      .get()?.id;


    this.analyzeTripService
      .createManager(
        manager
      )
      .subscribe((t) => {
        this.router.navigate(["/manager"]);
      });
  }
}
