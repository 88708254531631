
<div class="telemrtria-acessorios-mn">
  <p-menu #menu [popup]="true" [model]="items"></p-menu>
</div>

<div style="height: 100%;">

  <div class="body-telemetria-in">

    <span class="title-telemetria">

      <img class="img-tile" src="assets/img/logo-login.svg" />

      Telemetria

    </span>

    <mat-tab-group *ngIf="marker">
      <mat-tab label="Dados de Telemetria">
        <ng-template matTabContent>
          <br>
          <div>
            <table class="table">
              <tbody>
                <tr>
                  <td>Od&ocirc;metro (Km): <b>{{ this.marker.nrEnumTipoVelocidade == 1 ? this.marker.odometro: this.marker.odometroVeiculo }}</b></td>
                  <td colspan="3">
                    Hor&iacute;metro Movimento: <b>{{convertSecondsInHours(marker.horimetroMovimento) ?
                      convertSecondsInHours(marker.horimetroMovimento) : '--' }}</b>
                  </td>
                  <td colspan="2">Torque Motor (%): <b>{{ marker.torqueMotor }}</b></td>
                </tr>
                <tr>
                  <td>Consumo (L): <b>{{ marker.combustivelConsumido }}</b> </td>
                  <td colspan="3">Hor&iacute;metro Parado: <b>{{ convertSecondsInHours(marker.horimetroParado) ?
                      convertSecondsInHours(marker.horimetroParado) : '--' }}</b></td>
                  <td colspan="2">Tempo RPM Verde: <b>{{ convertSecondsInHours(marker.tempo_RPM_verde) ?
                      convertSecondsInHours(marker.tempo_RPM_verde) : '--'}}</b></td>
                </tr>
                <tr>
                  <td>N&iacute;vel Tanque (%): <b>{{ marker.nivelCombustivel }} </b></td>
                  <td colspan="3">Consumo Parado (L): <b>{{ marker.combustivelConsumidoParado }}</b></td>
                  <td colspan="2">Tempo RPM Amarelo: <b>{{convertSecondsInHours(marker.tempo_RPM_amarelo) ?
                      convertSecondsInHours(marker.tempo_RPM_amarelo) : '--' }}</b></td>
                </tr>
                <tr>
                  <td>Velocidade:
                    <span>
                      {{
                        this.marker.nrEnumTipoVelocidade == 1
                          ? this.marker.velocidade
                          : this.marker.velocidadeObd + ' Km/h'
                      }}
                    </span>                    
                  </td>
                  <td colspan="3">Tempo Excesso Vel: <b>{{ convertSecondsInHours(marker.tempoExcessoVelocidade) ?
                      convertSecondsInHours(marker.tempoExcessoVelocidade) : '--'}}</b></td>
                  <td colspan="2">Tempo RPM Vermelho: <b>{{ convertSecondsInHours(marker.tempo_RPM_vermelho) ?
                      convertSecondsInHours(marker.tempo_RPM_vermelho) : '--'}}</b></td>
                </tr>
                <tr>
                  <td>RPM: <b>{{ marker.rpmMotor }}</b></td>
                  <td colspan="3">Tempo Excesso RPM: <b>{{ convertSecondsInHours(marker.tempoExcessoRpm) ?
                      convertSecondsInHours(marker.tempoExcessoRpm) : '--'}}</b></td>
                  <td colspan="2">Tempo Banguela: <b>{{ convertSecondsInHours(marker.tempoBanguela) ?
                      convertSecondsInHours(marker.tempoBanguela): '--'}}</b>
                  </td>
                </tr>
                <tr>
                  <td>Pos. Acelerador (%): <b>{{ marker.posicaoAcelerador }}</b></td>
                  <td colspan="3">Consumo Instant. (Km/L): <b>{{ marker.consumoInstantaneo ? marker.consumoInstantaneo :
                      '--'}}</b></td>
                  <td>Pressão do Turbo: <b>{{ marker.pressaoTurbo }}</b></td>
                </tr>
                <tr>
                  <td>Temp. Motor (⁰C): <b>{{ marker.temperaturaMotor ? marker.temperaturaMotor : '--'}}</b></td>
                  <td colspan="3">Frenagens Totais: <b>{{ marker.totalFrenagens ? marker.totalFrenagens : 0 }}</b></td>
                </tr>
                <tr>
                  <td>Hor&iacute;metro Geral: <b>{{ convertSecondsInHours(marker.horimetroMotor) ?
                      convertSecondsInHours(marker.horimetroMotor): '--'}}</b></td>
                  <td colspan="3">Acelera&ccedil;&otilde;es Fundas: <b>{{ marker.aceleracoesFundo ?
                      marker.aceleracoesFundo : '--'}}</b></td>
                </tr>
              </tbody>
            </table>

          </div>
          <br>
        </ng-template>
      </mat-tab>

      <mat-tab label="Acessórios Ativados">
        <ng-template matTabContent>
          <br>
          <div>
            <div>

   <!--            <mat-menu #appMenu="matMenu">

                <button [disabled]="true" style="color: rgba(0,0,0,.87) !important;"
                  *ngFor="let i of this.getEquipamentosDesativados(marker.enumIndicadorVeiculo)" mat-menu-item>
                  {{i.value}}
                </button>

              </mat-menu> -->

            </div>
<!-- 
            <button style="float: right; margin-right: 207px;" mat-icon-button [matMenuTriggerFor]="appMenu">
              <small style="font-weight: 500;"> Exibir acessórios não ativados </small>
            </button> -->

            <br>
            <br>

            <mat-list role="list">
              <mat-list-item>
                <button type="button" pButton icon="pi pi-eye" label="Não ativados" (click)="menu.toggle($event)"></button>
              </mat-list-item>

              <mat-list-item *ngIf="marker.enumIndicadorVeiculo">
                <span style="font-weight: bolder;  font-size: 15px;">
                    Ativados
                </span>
              </mat-list-item>

              <mat-list-item *ngFor="let i of this.getEquipamentosAtivados(marker.enumIndicadorVeiculo)"
                role="listitem">
                <span style="font-weight: 200;  font-size: 15px;">
                  {{i.value}}
                </span>
              </mat-list-item>
            </mat-list>

            <mat-card style="width: 100%; text-align: center;"
              *ngIf="!this.getEquipamentosAtivados(marker.enumIndicadorVeiculo)" role="listitem">
              <div>
                <img style="width: 113px; margin-bottom: 15px;" src="assets/img/logo-login.svg" />
                <br>
                <span style="font-weight: 500;
                            font-size: 19px;
                            color: #4c4c4c;">
                  Nenhum equipamento foi ativado!
                </span>
              </div>

            </mat-card>

          </div>
          <br>
        </ng-template>
      </mat-tab>

      <!-- RODAGEM SIMPLES -->
      <mat-tab label="Pneus" *ngIf="quantidadeEixo != null && tipoRodagem == 'S'">

        
        
        <ng-template matTabContent>

          <div style="text-align: center;">
            <b>PLACA:</b> {{placa}}
            <br>
            <b>TIPO DE RODAGEM:</b> {{rodagem}}
            <br>
            <b>QUANTIDADE DE EIXOS:</b> {{quantidadeEixo}}
          </div>

          <div id="fade" class="hide"></div>
          <div id="modal" class="hide">
            <div class="modal-header">
              <h2>Informações do Pneu</h2>
              <button id="close-modal" (click)="fecharModal()">Fechar</button>
            </div>
            <div class="modal-body">
              <p>
                <b>POSIÇÃO DO PNEU:</b> {{nomePosicao}}
                <br>
                <b>CODIGO SENSOR:</b> {{codigoSensor}}
                <br>
                <b>NÚMERO DE FOGO:</b> {{numeroFogo}}
                <br>
                <b>PRESSÃO MAXIMA:</b> {{nrPressaoMaxima}}
                <br>
                <b>PRESSÃO MINIMA:</b> {{nrPressaoMinima}}
                <br>
                <b>TEMPERATURA MAXIMA:</b> {{nrTemperaturaMaxima}}
                <br>
                <b>STATUS DO PNEU:</b> {{statusPneu}}
              </p>
            </div>
          </div>

          <!-- ESTEPE 1 -->
          <div id="pneus-estepe1">
            <img *ngIf="pneuSelect[50].ativo == true && pneuSelect[51].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[50], 'pneus-estepe1')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[50].ativo == true && pneuSelect[51].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[50], 'pneus-estepe1')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[50].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
            <div *ngIf="pneuSelect[50].ativo == true" style=" background: lightblue; border-radius: 6px;
            font-size: 11px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[50].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[50].temperaturaAtual}}
            </div>
          </div>

          <!-- eixo 9 simples direito INICIO -->
          <div id="pneus-eixo9-simple-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[53].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[53].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[53].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[53].ativo == true && pneuSelect[53].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[53], 'pneus-eixo9-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[53].ativo == true && pneuSelect[53].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[53], 'pneus-eixo9-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[53].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div> 
          <!-- eixo 9 simples direito FIM --> 


          <!-- eixo 8 simples direito INICIO -->
          <div id="pneus-eixo8-simple-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[49].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[49].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[49].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[49].ativo == true && pneuSelect[49].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[49], 'pneus-eixo8-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[49].ativo == true && pneuSelect[49].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[49], 'pneus-eixo8-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[49].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo8-simple-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[49].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[49].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[49].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[49].ativo == true && pneuSelect[49].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[49], 'pneus-eixo8-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[49].ativo == true && pneuSelect[49].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[49], 'pneus-eixo8-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[49].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <!-- eixo 8 simples direito FIM -->

          <!-- eixo 7 simples direito INICIO -->
          <div id="pneus-eixo7-simple-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[47].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[47].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[47].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[47].ativo == true && pneuSelect[47].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[47], 'pneus-eixo7-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[47].ativo == true && pneuSelect[47].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[47], 'pneus-eixo7-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[47].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo7-simple-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[47].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[47].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[47].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[47].ativo == true && pneuSelect[47].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[47], 'pneus-eixo7-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[47].ativo == true && pneuSelect[47].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[47], 'pneus-eixo7-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[47].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo7-simple-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[47].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[47].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[47].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[47].ativo == true && pneuSelect[47].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[47], 'pneus-eixo7-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[47].ativo == true && pneuSelect[47].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[47], 'pneus-eixo7-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[47].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <!-- eixo 7 simples direito FIM -->

          <!-- eixo 6 simples direito INICIO -->
          <div id="pneus-eixo6-simple-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[45].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[45].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[45].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[45].ativo == true && pneuSelect[45].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[45], 'pneus-eixo6-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[45].ativo == true && pneuSelect[45].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[45], 'pneus-eixo6-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[45].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo6-simple-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[45].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[45].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[45].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[45].ativo == true && pneuSelect[45].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[45], 'pneus-eixo6-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[45].ativo == true && pneuSelect[45].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[45], 'pneus-eixo6-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[45].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo6-simple-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[45].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[45].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[45].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[45].ativo == true && pneuSelect[45].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[45], 'pneus-eixo6-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[45].ativo == true && pneuSelect[45].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[45], 'pneus-eixo6-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[45].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo6-simple-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[45].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[45].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[45].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[45].ativo == true && pneuSelect[45].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[45], 'pneus-eixo6-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[45].ativo == true && pneuSelect[45].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[45], 'pneus-eixo6-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[45].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <!-- eixo 6 simples direito FIM -->

          <!-- eixo 5 simples direito INICIO -->
          <div id="pneus-eixo5-simple-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[43].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[43].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[43].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[43].ativo == true && pneuSelect[43].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[43], 'pneus-eixo5-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[43].ativo == true && pneuSelect[43].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[43], 'pneus-eixo5-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[43].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo5-simple-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[43].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[43].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[43].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[43].ativo == true && pneuSelect[43].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[43], 'pneus-eixo5-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[43].ativo == true && pneuSelect[43].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[43], 'pneus-eixo5-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[43].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo5-simple-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[43].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[43].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[43].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[43].ativo == true && pneuSelect[43].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[43], 'pneus-eixo5-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[43].ativo == true && pneuSelect[43].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[43], 'pneus-eixo5-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[43].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo5-simple-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[43].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[43].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[43].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[43].ativo == true && pneuSelect[43].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[43], 'pneus-eixo5-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[43].ativo == true && pneuSelect[43].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[43], 'pneus-eixo5-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[43].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo5-simple-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[43].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[43].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[43].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[43].ativo == true && pneuSelect[43].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[43], 'pneus-eixo5-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[43].ativo == true && pneuSelect[43].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[43], 'pneus-eixo5-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[43].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <!-- eixo 5 simples direito FIM -->

          <!-- eixo 4 simples direito INICIO -->
          <div id="pneus-eixo4-simple-direito" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[41].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[41].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[41].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[41].ativo == true && pneuSelect[41].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[41], 'pneus-eixo4-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[41].ativo == true && pneuSelect[41].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[41], 'pneus-eixo4-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[41].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo4-simple-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[41].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[41].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[41].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[41].ativo == true && pneuSelect[41].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[41], 'pneus-eixo4-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[41].ativo == true && pneuSelect[41].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[41], 'pneus-eixo4-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[41].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo4-simple-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[41].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[41].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[41].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[41].ativo == true && pneuSelect[41].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[41], 'pneus-eixo4-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[41].ativo == true && pneuSelect[41].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[41], 'pneus-eixo4-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[41].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo4-simple-direito"*ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[41].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[41].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[41].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[41].ativo == true && pneuSelect[41].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[41], 'pneus-eixo4-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[41].ativo == true && pneuSelect[41].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[41], 'pneus-eixo4-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[41].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo4-simple-direito"*ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[41].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
            font-size: 12px; text-align: center;">
              <b>PSI :</b> {{pneuSelect[41].pressaoAtual}} <br>
              <b>ºC :</b> {{pneuSelect[41].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[41].ativo == true && pneuSelect[41].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[41], 'pneus-eixo4-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[41].ativo == true && pneuSelect[41].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[41], 'pneus-eixo4-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[41].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo4-simple-direito"*ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[41].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[41].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[41].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[41].ativo == true && pneuSelect[41].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[41], 'pneus-eixo4-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[41].ativo == true && pneuSelect[41].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[41], 'pneus-eixo4-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[41].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <!-- eixo 4 simples direito FIM -->

          <!-- eixo 3 simples direito INICIO -->
          <div id="pneus-eixo3-simple-direito" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[39].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[39].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[39].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo3-simple-direito" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[39].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[39].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[39].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo3-simple-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[39].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[39].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[39].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo3-simple-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[39].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[39].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[39].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo3-simple-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[39].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[39].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[39].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo3-simple-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[39].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[39].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[39].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo3-simple-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[39].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[39].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[39].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == true && pneuSelect[39].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[39], 'pneus-eixo3-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[39].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <!-- eixo 3 simples direito FIM -->

          <!-- eixo 2 simples direito INICIO -->
          <div id="pneus-eixo2-simple-direito" *ngIf="quantidadeEixo == 2" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[37].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[37].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[37].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo2-simple-direito" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[37].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[37].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[37].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo2-simple-direito" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[37].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[37].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[37].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo2-simple-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[37].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[37].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[37].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo2-simple-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[37].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[37].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[37].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo2-simple-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[37].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[37].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[37].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo2-simple-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[37].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[37].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[37].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo2-simple-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 43.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[37].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[37].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[37].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == true && pneuSelect[37].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[37], 'pneus-eixo2-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[37].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <!-- eixo 2 simples direito FIM -->

          <!-- eixo 1 simples direito INICIO -->
          <div id="pneus-eixo1-simple-direito" *ngIf="quantidadeEixo == 1" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;" >
            <div *ngIf="pneuSelect[35].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[35].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[35].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo1-simple-direito" *ngIf="quantidadeEixo == 2" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[35].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[35].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[35].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo1-simple-direito" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[35].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[35].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[35].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo1-simple-direito" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[35].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[35].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[35].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo1-simple-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[35].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[35].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[35].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo1-simple-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[35].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[35].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[35].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo1-simple-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[35].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[35].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[35].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo1-simple-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 43.5%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[35].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[35].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[35].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <div id="pneus-eixo1-simple-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 39%; margin-top: 5.8%;">
            <div *ngIf="pneuSelect[35].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[35].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[35].temperaturaAtual}}
            </div>
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_alarmado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == true && pneuSelect[35].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[35], 'pneus-eixo1-simple-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
            <img *ngIf="pneuSelect[35].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
          </div>
          <!-- eixo 1 simples direito FIM -->

            <!-- direcional direito 2-->
            <div id="pneus-direcional-2-direito" *ngIf="categoriaVeiculo == 'BI-TRUCK'">
              <div *ngIf="pneuSelect[58].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[58].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[58].temperaturaAtual}}
              </div>
              <img *ngIf="pneuSelect[58].ativo == true && pneuSelect[58].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[58], 'pneus-direcional-2-direito')" src="assets/img/pneu_alarmado.png" height="30" />
              <img *ngIf="pneuSelect[58].ativo == true && pneuSelect[58].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[58], 'pneus-direcional-2-direito')" src="assets/img/pneu_sensor_ativado.png" height="30" />
              <img *ngIf="pneuSelect[58].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="30" />
            </div>
            <!-- direcional direito -->
            <div id="pneus-direcional-direito">
              <div *ngIf="pneuSelect[0].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[0].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[0].temperaturaAtual}}
              </div>
              <img *ngIf="pneuSelect[0].ativo == true && pneuSelect[0].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[0], 'pneus-direcional-direito')" src="assets/img/pneu_alarmado.png" height="30" />
              <img *ngIf="pneuSelect[0].ativo == true && pneuSelect[0].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[0], 'pneus-direcional-direito')" src="assets/img/pneu_sensor_ativado.png" height="30" />
              <img *ngIf="pneuSelect[0].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="30" />
            </div>
              <!-- caminhao -->
              <div class="telemetria-caminhao">
                <img src="assets/img/caminhao_telemetria.png" height="100%" width="70%" alt="" />
              </div>
              <!-- TEXTO ESTEPE -->
              <div id="estepes-texto">
                <h3>ESTEPES</h3>
              </div>
            <!-- direcional esquerdo -->  
            <div id="pneus-direcional-esquerdo">
              <img *ngIf="pneuSelect[1].ativo == true && pneuSelect[1].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[1], 'pneus-direcional-esquerdo')" src="assets/img/pneu_alarmado.png" height="30" />
              <img *ngIf="pneuSelect[1].ativo == true && pneuSelect[1].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[1], 'pneus-direcional-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="30" />
              <img *ngIf="pneuSelect[1].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="30" />
              <div *ngIf="pneuSelect[1].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[1].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[1].temperaturaAtual}}
              </div>
            </div>
            <!-- direcional esquerdo 2 -->  
            <div id="pneus-direcional-2-esquerdo" *ngIf="categoriaVeiculo == 'BI-TRUCK'">
              <img *ngIf="pneuSelect[59].ativo == true && pneuSelect[59].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[59], 'pneus-direcional-2-esquerdo')" src="assets/img/pneu_alarmado.png" height="30" />
              <img *ngIf="pneuSelect[59].ativo == true && pneuSelect[59].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[59], 'pneus-direcional-2-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="30" />
              <img *ngIf="pneuSelect[59].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="30" />
              <div *ngIf="pneuSelect[59].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[59].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[59].temperaturaAtual}}
              </div>
            </div>


            <!-- eixo 1 simples esquerdo INICIO -->
            <div id="pneus-eixo1-simple-esquerdo" *ngIf="quantidadeEixo == 1" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[34].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[34].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[34].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo1-simple-esquerdo" *ngIf="quantidadeEixo == 2" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;">
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[34].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[34].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[34].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo1-simple-esquerdo" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%;">
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[34].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[34].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[34].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo1-simple-esquerdo" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -8%;">
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[34].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[34].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[34].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo1-simple-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -8%;">
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[34].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[34].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[34].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo1-simple-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: -8%;">
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[34].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[34].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[34].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo1-simple-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: -8%;">
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[34].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[34].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[34].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo1-simple-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 43.5%; margin-top: -8%;">
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[34].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[34].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[34].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo1-simple-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 39%; margin-top: -8%;">
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == true && pneuSelect[34].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[34], 'pneus-eixo1-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[34].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[34].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[34].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[34].temperaturaAtual}}
              </div>
            </div>
            <!-- eixo 1 simples esquerdo FIM -->
            
            <!-- eixo 2 simples esquerdo INICIO-->
            <div id="pneus-eixo2-simple-esquerdo" *ngIf="quantidadeEixo == 2" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;">
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[36].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[36].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[36].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo2-simple-esquerdo" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;">
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[36].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[36].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[36].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo2-simple-esquerdo" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%;">
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[36].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[36].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[36].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo2-simple-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -8%;">
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[36].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[36].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[36].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo2-simple-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -8%">
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[36].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[36].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[36].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo2-simple-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: -8%">
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[36].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[36].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[36].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo2-simple-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: -8%">
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[36].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[36].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[36].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo2-simple-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 43.5%; margin-top: -8%">
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == true && pneuSelect[36].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[36], 'pneus-eixo2-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[36].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[36].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[36].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[36].temperaturaAtual}}
              </div>
            </div>
            <!-- eixo 2 simples esquerdo FIM-->


            <!-- eixo 3 simples esquerdo INICIO-->
            <div id="pneus-eixo3-simple-esquerdo" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%">
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[38].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[38].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[38].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo3-simple-esquerdo" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%">
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[38].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[38].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[38].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo3-simple-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%">
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[38].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[38].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[38].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo3-simple-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -8%">
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[38].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[38].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[38].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo3-simple-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -8%">
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[38].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[38].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[38].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo3-simple-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: -8%">
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[38].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[38].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[38].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo3-simple-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: -8%">
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == true && pneuSelect[38].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[38], 'pneus-eixo3-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[38].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[38].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[38].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[38].temperaturaAtual}}
              </div>
            </div>
            <!-- eixo 3 simples esquerdo FIM-->

            <!-- eixo 4 simples esquerdo INICIO-->
            <div id="pneus-eixo4-simple-esquerdo" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;">
              <img *ngIf="pneuSelect[40].ativo == true && pneuSelect[40].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[40], 'pneus-eixo4-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[40].ativo == true && pneuSelect[40].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[40], 'pneus-eixo4-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[40].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[40].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[40].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[40].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo4-simple-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;">
              <img *ngIf="pneuSelect[40].ativo == true && pneuSelect[40].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[40], 'pneus-eixo4-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[40].ativo == true && pneuSelect[40].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[40], 'pneus-eixo4-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[40].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[40].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[40].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[40].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo4-simple-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%;">
              <img *ngIf="pneuSelect[40].ativo == true && pneuSelect[40].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[40], 'pneus-eixo4-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[40].ativo == true && pneuSelect[40].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[40], 'pneus-eixo4-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[40].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[40].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[40].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[40].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo4-simple-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -8%;">
              <img *ngIf="pneuSelect[40].ativo == true && pneuSelect[40].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[40], 'pneus-eixo4-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[40].ativo == true && pneuSelect[40].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[40], 'pneus-eixo4-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[40].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[40].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[40].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[40].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo4-simple-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -8%;">
              <img *ngIf="pneuSelect[40].ativo == true && pneuSelect[40].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[40], 'pneus-eixo4-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[40].ativo == true && pneuSelect[40].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[40], 'pneus-eixo4-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[40].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[40].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[40].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[40].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo4-simple-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: -8%;">
              <img *ngIf="pneuSelect[40].ativo == true && pneuSelect[41].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[40], 'pneus-eixo4-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[40].ativo == true && pneuSelect[41].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[40], 'pneus-eixo4-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[40].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[40].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[40].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[40].temperaturaAtual}}
              </div>
            </div>
            <!-- eixo 4 simples esquerdo FIM-->


            <!-- eixo 5 simples esquerdo INICIO-->
            <div id="pneus-eixo5-simple-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[42].ativo == true && pneuSelect[42].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[42], 'pneus-eixo5-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[42].ativo == true && pneuSelect[42].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[42], 'pneus-eixo5-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[42].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[42].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[42].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[42].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo5-simple-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[42].ativo == true && pneuSelect[42].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[42], 'pneus-eixo5-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[42].ativo == true && pneuSelect[42].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[42], 'pneus-eixo5-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[42].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[42].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[42].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[42].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo5-simple-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[42].ativo == true && pneuSelect[42].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[42], 'pneus-eixo5-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[42].ativo == true && pneuSelect[42].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[42], 'pneus-eixo5-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[42].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[42].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[42].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[42].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo5-simple-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[42].ativo == true && pneuSelect[42].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[42], 'pneus-eixo5-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[42].ativo == true && pneuSelect[42].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[42], 'pneus-eixo5-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[42].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[42].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[42].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[42].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo5-simple-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[42].ativo == true && pneuSelect[42].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[42], 'pneus-eixo5-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[42].ativo == true && pneuSelect[42].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[42], 'pneus-eixo5-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[42].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[42].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[42].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[42].temperaturaAtual}}
              </div>
            </div>
            <!-- eixo 5 simples esquerdo FIM-->

            <!-- eixo 6 simples esquerdo INICIO-->
            <div id="pneus-eixo6-simple-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[44].ativo == true && pneuSelect[44].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[44], 'pneus-eixo6-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[44].ativo == true && pneuSelect[44].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[44], 'pneus-eixo6-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[44].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[44].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[44].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[44].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo6-simple-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[44].ativo == true && pneuSelect[44].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[44], 'pneus-eixo6-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[44].ativo == true && pneuSelect[44].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[44], 'pneus-eixo6-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[44].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[44].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[44].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[44].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo6-simple-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[44].ativo == true && pneuSelect[44].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[44], 'pneus-eixo6-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[44].ativo == true && pneuSelect[44].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[44], 'pneus-eixo6-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[44].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[44].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[44].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[44].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo6-simple-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[44].ativo == true && pneuSelect[44].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[44], 'pneus-eixo6-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[44].ativo == true && pneuSelect[44].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[44], 'pneus-eixo6-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[44].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[44].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[44].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[44].temperaturaAtual}}
              </div>
            </div>
            <!-- eixo 6 simples esquerdo FIM-->

            <!-- eixo 7 simples esquerdo INICIO-->
            <div id="pneus-eixo7-simple-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[46].ativo == true && pneuSelect[46].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[46], 'pneus-eixo7-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[46].ativo == true && pneuSelect[46].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[46], 'pneus-eixo7-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[46].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[46].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[46].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[46].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo7-simple-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[46].ativo == true && pneuSelect[46].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[46], 'pneus-eixo7-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[46].ativo == true && pneuSelect[46].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[46], 'pneus-eixo7-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[46].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[46].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[46].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[46].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo7-simple-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[46].ativo == true && pneuSelect[46].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[46], 'pneus-eixo7-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[46].ativo == true && pneuSelect[46].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[46], 'pneus-eixo7-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[46].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[46].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[46].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[46].temperaturaAtual}}
              </div>
            </div>
            <!-- eixo 7 simples esquerdo FIM-->

            <!-- eixo 8 simples esquerdo INICIO-->
            <div id="pneus-eixo8-simple-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[48].ativo == true && pneuSelect[48].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[48], 'pneus-eixo8-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[48].ativo == true && pneuSelect[48].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[48], 'pneus-eixo8-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[48].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[48].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[48].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[48].temperaturaAtual}}
              </div>
            </div>
            <div id="pneus-eixo8-simple-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[48].ativo == true && pneuSelect[48].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[48], 'pneus-eixo8-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[48].ativo == true && pneuSelect[48].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[48], 'pneus-eixo8-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[48].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[48].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[48].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[48].temperaturaAtual}}
              </div>
            </div>
            <!-- eixo 8 simples esquerdo FIM-->

            <!-- eixo 9 simples esquerdo INICIO-->
            <div id="pneus-eixo9-simple-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;" >
              <img *ngIf="pneuSelect[52].ativo == true && pneuSelect[52].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[52], 'pneus-eixo9-simple-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[52].ativo == true && pneuSelect[52].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[52], 'pneus-eixo9-simple-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[52].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[52].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[52].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[52].temperaturaAtual}}
              </div>
            </div> 
            <!-- eixo 9 simples esquerdo FIM-->

             <!-- ESTEPE 2 -->
             <div id="pneus-estepe2">
              <img *ngIf="pneuSelect[51].ativo == true && pneuSelect[51].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[51], 'pneus-estepe2')" src="assets/img/pneu_alarmado.png" height="26" />
              <img *ngIf="pneuSelect[51].ativo == true && pneuSelect[51].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[51], 'pneus-estepe2')" src="assets/img/pneu_sensor_ativado.png" height="26" />
              <img *ngIf="pneuSelect[51].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              <div *ngIf="pneuSelect[51].ativo == true" style=" background: lightblue; border-radius: 6px;
              font-size: 11px; text-align: center;">
                <b>PSI :</b> {{pneuSelect[51].pressaoAtual}} <br>
                <b>ºC :</b> {{pneuSelect[51].temperaturaAtual}}
              </div>
            </div>
          </ng-template>
        </mat-tab>

      <!-- RODAGEM DUPLA -->
        <mat-tab label="Pneus" *ngIf="quantidadeEixo != null && tipoRodagem == 'D'">

          <ng-template matTabContent>
              <div style="text-align: center;">
                <b>PLACA:</b> {{placa}}
                <br>
                <b>TIPO DE RODAGEM:</b> {{rodagem}}
                <br>
                <b>QUANTIDADE DE EIXOS:</b> {{quantidadeEixo}}
              </div>

              <div id="fade" class="hide"></div>
              <div id="modal" class="hide">
                <div class="modal-header">
                  <h2>Informações do Pneu</h2>
                  <button (click)="fecharModal()" id="close-modal">Fechar</button>
                </div>
                <div class="modal-body">
                  <p>
                    <b>POSIÇÃO DO PNEU:</b> {{nomePosicao}}
                    <br>
                    <b>CODIGO SENSOR:</b> {{codigoSensor}}
                    <br>
                    <b>NÚMERO DE FOGO:</b> {{numeroFogo}}
                    <br>
                    <b>PRESSÃO MAXIMA:</b> {{nrPressaoMaxima}}
                    <br>
                    <b>PRESSÃO MINIMA:</b> {{nrPressaoMinima}}
                    <br>
                    <b>TEMPERATURA MAXIMA:</b> {{nrTemperaturaMaxima}}
                    <br>
                    <b>STATUS DO PNEU:</b> {{statusPneu}}
                  </p>
                </div>
              </div>
              
              <!-- ESTEPE 1 -->
              <div ng id="pneus-estepe1">
                <img *ngIf="pneuSelect[50].ativo == true && pneuSelect[51].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[50], 'pneus-estepe1')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[50].ativo == true && pneuSelect[51].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[50], 'pneus-estepe1')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[50].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[50].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[50].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[50].temperaturaAtual}}
                </div>
              </div>

              <!-- eixo 9 duplo externo direito INICIO-->
                <div id="pneus-eixo9-duplo-externo-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 3.6%;" >
                  <div *ngIf="pneuSelect[55].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                  font-size: 11px; text-align: center;">
                    <b>PSI :</b> {{pneuSelect[55].pressaoAtual}} <br>
                    <b>ºC :</b> {{pneuSelect[55].temperaturaAtual}}
                  </div>
                  <img *ngIf="pneuSelect[55].ativo == true && pneuSelect[55].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[55], 'pneus-eixo9-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                  <img *ngIf="pneuSelect[55].ativo == true && pneuSelect[55].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[55], 'pneus-eixo9-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                  <img *ngIf="pneuSelect[55].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                </div> 
              <!-- eixo 9 duplo externo direito FIM-->

              <!-- eixo 9 duplo interno direito INICIO-->
              <div id="pneus-eixo9-duplo-interno-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;" >
                <img *ngIf="pneuSelect[57].ativo == true && pneuSelect[57].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[57], 'pneus-eixo9-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[57].ativo == true && pneuSelect[57].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[57], 'pneus-eixo9-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[57].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[57].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[57].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[57].temperaturaAtual}}
                </div>
              </div> 
              <!-- eixo 9 duplo interno direito FIM-->

              <!-- eixo 8 duplo externo direito INICIO-->
              <div id="pneus-eixo8-duplo-externo-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 3.6%;" >
                <div *ngIf="pneuSelect[17].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[17].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[17].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[17].ativo == true && pneuSelect[17].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[17], 'pneus-eixo8-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[17].ativo == true && pneuSelect[17].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[17], 'pneus-eixo8-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[17].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo8-duplo-externo-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 3.6%;" >
                <div *ngIf="pneuSelect[17].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[17].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[17].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[17].ativo == true && pneuSelect[17].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[17], 'pneus-eixo8-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[17].ativo == true && pneuSelect[17].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[17], 'pneus-eixo8-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[17].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 8 duplo externo direito FIM-->

              <!-- eixo 8 duplo interno direito INICIO-->
              <div id="pneus-eixo8-duplo-interno-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;" >
                <img *ngIf="pneuSelect[33].ativo == true && pneuSelect[33].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[33], 'pneus-eixo8-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[33].ativo == true && pneuSelect[33].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[33], 'pneus-eixo8-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[33].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[33].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[33].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[33].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo8-duplo-interno-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;" >
                <img *ngIf="pneuSelect[33].ativo == true && pneuSelect[33].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[33], 'pneus-eixo8-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[33].ativo == true && pneuSelect[33].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[33], 'pneus-eixo8-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[33].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[33].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[33].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[33].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 8 duplo externo direito FIM-->

              <!-- eixo 7 duplo externo direito INICIO-->
              <div id="pneus-eixo7-duplo-externo-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 3.6%;" >
                <div *ngIf="pneuSelect[15].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[15].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[15].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[15].ativo == true && pneuSelect[15].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[15], 'pneus-eixo7-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[15].ativo == true && pneuSelect[15].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[15], 'pneus-eixo7-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[15].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo7-duplo-externo-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 3.6%;" >
                <div *ngIf="pneuSelect[15].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[15].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[15].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[15].ativo == true && pneuSelect[15].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[15], 'pneus-eixo7-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[15].ativo == true && pneuSelect[15].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[15], 'pneus-eixo7-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[15].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo7-duplo-externo-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 3.6%;" >
                <div *ngIf="pneuSelect[15].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[15].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[15].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[15].ativo == true && pneuSelect[15].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[15], 'pneus-eixo7-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[15].ativo == true && pneuSelect[15].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[15], 'pneus-eixo7-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[15].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 7 duplo externo direito FIM-->

              <!-- eixo 7 duplo interno direito INICIO-->
              <div id="pneus-eixo7-duplo-interno-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;" >
                <img *ngIf="pneuSelect[31].ativo == true && pneuSelect[31].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[31], 'pneus-eixo7-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[31].ativo == true && pneuSelect[31].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[31], 'pneus-eixo7-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[31].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[31].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[31].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[31].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo7-duplo-interno-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;" >
                <img *ngIf="pneuSelect[31].ativo == true && pneuSelect[31].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[31], 'pneus-eixo7-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[31].ativo == true && pneuSelect[31].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[31], 'pneus-eixo7-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[31].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[31].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[31].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[31].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo7-duplo-interno-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%;" >
                <img *ngIf="pneuSelect[31].ativo == true && pneuSelect[31].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[31], 'pneus-eixo7-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[31].ativo == true && pneuSelect[31].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[31], 'pneus-eixo7-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[31].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[31].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[31].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[31].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 7 duplo interno direito FIM-->

              <!-- eixo 6 duplo externo direito INICIO-->
              <div id="pneus-eixo6-duplo-externo-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 3.6%" >
                <div *ngIf="pneuSelect[13].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[13].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[13].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[13].ativo == true && pneuSelect[13].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[13], 'pneus-eixo6-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[13].ativo == true && pneuSelect[13].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[13], 'pneus-eixo6-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[13].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo6-duplo-externo-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 3.6%" >
                <div *ngIf="pneuSelect[13].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[13].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[13].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[13].ativo == true && pneuSelect[13].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[13], 'pneus-eixo6-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[13].ativo == true && pneuSelect[13].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[13], 'pneus-eixo6-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[13].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo6-duplo-externo-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 3.6%" >
                <div *ngIf="pneuSelect[13].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[13].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[13].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[13].ativo == true && pneuSelect[13].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[13], 'pneus-eixo6-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[13].ativo == true && pneuSelect[13].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[13], 'pneus-eixo6-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[13].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo6-duplo-externo-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 3.6%" >
                <div *ngIf="pneuSelect[13].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[13].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[13].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[13].ativo == true && pneuSelect[13].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[13], 'pneus-eixo6-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[13].ativo == true && pneuSelect[13].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[13], 'pneus-eixo6-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[13].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 6 duplo externo direito FIM-->

              <!-- eixo 6 duplo interno direito INICIO-->
              <div id="pneus-eixo6-duplo-interno-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%" >
                <img *ngIf="pneuSelect[29].ativo == true && pneuSelect[29].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[29], 'pneus-eixo6-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[29].ativo == true && pneuSelect[29].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[29], 'pneus-eixo6-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[29].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[29].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[29].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[29].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo6-duplo-interno-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%" >
                <img *ngIf="pneuSelect[29].ativo == true && pneuSelect[29].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[29], 'pneus-eixo6-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[29].ativo == true && pneuSelect[29].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[29], 'pneus-eixo6-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[29].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[29].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[29].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[29].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo6-duplo-interno-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%" >
                <img *ngIf="pneuSelect[29].ativo == true && pneuSelect[29].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[29], 'pneus-eixo6-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[29].ativo == true && pneuSelect[29].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[29], 'pneus-eixo6-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[29].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[29].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[29].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[29].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo6-duplo-interno-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 5.8%" >
                <img *ngIf="pneuSelect[29].ativo == true && pneuSelect[29].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[29], 'pneus-eixo6-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[29].ativo == true && pneuSelect[29].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[29], 'pneus-eixo6-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[29].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[29].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[29].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[29].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 6 duplo interno direito FIM-->

              <!-- eixo 5 duplo externo direito INICIO-->
              <div id="pneus-eixo5-duplo-externo-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 3.6%;" >
                <div *ngIf="pneuSelect[11].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[11].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[11].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[11].ativo == true && pneuSelect[11].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[11], 'pneus-eixo5-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[11].ativo == true && pneuSelect[11].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[11], 'pneus-eixo5-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[11].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo5-duplo-externo-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 3.6%;" >
                <div *ngIf="pneuSelect[11].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[11].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[11].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[11].ativo == true && pneuSelect[11].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[11], 'pneus-eixo5-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[11].ativo == true && pneuSelect[11].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[11], 'pneus-eixo5-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[11].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo5-duplo-externo-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 3.6%;" >
                <div *ngIf="pneuSelect[11].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[11].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[11].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[11].ativo == true && pneuSelect[11].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[11], 'pneus-eixo5-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[11].ativo == true && pneuSelect[11].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[11], 'pneus-eixo5-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[11].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo5-duplo-externo-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 3.6%;" >
                <div *ngIf="pneuSelect[11].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[11].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[11].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[11].ativo == true && pneuSelect[11].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[11], 'pneus-eixo5-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[11].ativo == true && pneuSelect[11].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[11], 'pneus-eixo5-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[11].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo5-duplo-externo-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 3.6%;" >
                <div *ngIf="pneuSelect[11].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[11].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[11].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[11].ativo == true && pneuSelect[11].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[11], 'pneus-eixo5-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[11].ativo == true && pneuSelect[11].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[11], 'pneus-eixo5-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[11].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 5 duplo externo direito FIM-->

              <!-- eixo 5 duplo interno direito INICIO-->
              <div id="pneus-eixo5-duplo-interno-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%" >
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[26].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo5-duplo-interno-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%" >
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[26].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo5-duplo-interno-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%" >
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[26].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo5-duplo-interno-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 5.8%" >
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[26].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo5-duplo-interno-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 5.8%" >
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[26].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 5 duplo interno direito FIM-->

              <!-- eixo 4 duplo externo direito INICIO-->
              <div id="pneus-eixo4-duplo-externo-direito" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[9].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[9].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[9].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[9].ativo == true && pneuSelect[9].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[9], 'pneus-eixo4-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[9].ativo == true && pneuSelect[9].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[9], 'pneus-eixo4-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[9].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo4-duplo-externo-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[9].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[9].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[9].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[9].ativo == true && pneuSelect[9].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[9], 'pneus-eixo4-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[9].ativo == true && pneuSelect[9].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[9], 'pneus-eixo4-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[9].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo4-duplo-externo-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[9].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[9].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[9].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[9].ativo == true && pneuSelect[9].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[9], 'pneus-eixo4-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[9].ativo == true && pneuSelect[9].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[9], 'pneus-eixo4-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[9].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo4-duplo-externo-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[9].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[9].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[9].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[9].ativo == true && pneuSelect[9].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[9], 'pneus-eixo4-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[9].ativo == true && pneuSelect[9].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[9], 'pneus-eixo4-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[9].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo4-duplo-externo-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[9].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[9].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[9].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[9].ativo == true && pneuSelect[9].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[9], 'pneus-eixo4-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[9].ativo == true && pneuSelect[9].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[9], 'pneus-eixo4-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[9].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo4-duplo-externo-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[9].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[9].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[9].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[9].ativo == true && pneuSelect[9].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[9], 'pneus-eixo4-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[9].ativo == true && pneuSelect[9].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[9], 'pneus-eixo4-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[9].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 4 duplo externo direito FIM-->

              <!-- eixo 4 duplo interno direito INICIO-->
              <div id="pneus-eixo4-duplo-interno-direito" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[25].ativo == true && pneuSelect[25].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[25], 'pneus-eixo4-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[25].ativo == true && pneuSelect[25].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[25], 'pneus-eixo4-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[25].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[25].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[25].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[25].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo4-duplo-interno-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[25].ativo == true && pneuSelect[25].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[25], 'pneus-eixo4-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[25].ativo == true && pneuSelect[25].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[25], 'pneus-eixo4-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[25].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[25].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[25].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[25].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo4-duplo-interno-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[25].ativo == true && pneuSelect[25].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[25], 'pneus-eixo4-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[25].ativo == true && pneuSelect[25].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[25], 'pneus-eixo4-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[25].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[25].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[25].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[25].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo4-duplo-interno-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[25].ativo == true && pneuSelect[25].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[25], 'pneus-eixo4-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[25].ativo == true && pneuSelect[25].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[25], 'pneus-eixo4-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[25].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[25].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[25].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[25].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo4-duplo-interno-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[25].ativo == true && pneuSelect[25].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[25], 'pneus-eixo4-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[25].ativo == true && pneuSelect[25].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[25], 'pneus-eixo4-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[25].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[25].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[25].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[25].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo4-duplo-interno-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[25].ativo == true && pneuSelect[25].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[25], 'pneus-eixo4-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[25].ativo == true && pneuSelect[25].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[25], 'pneus-eixo4-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[25].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[25].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[25].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[25].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 4 duplo interno direito FIM-->

              <!-- eixo 3 duplo externo direito INICIO-->
              <div id="pneus-eixo3-duplo-externo-direito" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[7].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[7].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[7].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo3-duplo-externo-direito" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[7].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[7].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[7].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo3-duplo-externo-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[7].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[7].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[7].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo3-duplo-externo-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[7].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[7].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[7].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo3-duplo-externo-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[7].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[7].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[7].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo3-duplo-externo-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[7].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[7].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[7].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo3-duplo-externo-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[7].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[7].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[7].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == true && pneuSelect[7].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[7], 'pneus-eixo3-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[7].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 3 duplo externo direito FIM-->

              <!-- eixo 3 duplo interno direito INICIO-->
              <div id="pneus-eixo3-duplo-interno-direito" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[23].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[23].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[23].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo3-duplo-interno-direito" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[23].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[23].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[23].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo3-duplo-interno-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[23].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[23].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[23].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo3-duplo-interno-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[23].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[23].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[23].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo3-duplo-interno-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[23].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[23].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[23].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo3-duplo-interno-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[23].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[23].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[23].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo3-duplo-interno-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == true && pneuSelect[23].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[23], 'pneus-eixo3-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[23].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[23].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[23].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[23].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 3 duplo interno direito FIM-->

              <!-- eixo 2 duplo externo direito INICIO-->
              <div id="pneus-eixo2-duplo-externo-direito" *ngIf="quantidadeEixo == 2" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[5].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[5].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[5].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-externo-direito" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[5].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[5].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[5].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-externo-direito" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[5].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[5].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[5].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-externo-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[5].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[5].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[5].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-externo-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[5].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[5].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[5].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-externo-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[5].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[5].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[5].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-externo-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[5].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[5].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[5].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-externo-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 43.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[5].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[5].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[5].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == true && pneuSelect[5].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[5], 'pneus-eixo2-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[5].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 2 duplo externo direito FIM-->

              <!-- eixo 2 duplo interno direito INICIO-->
              <div id="pneus-eixo2-duplo-interno-direito" *ngIf="quantidadeEixo == 2" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[21].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[21].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[21].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-interno-direito" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[21].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[21].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[21].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-interno-direito" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[21].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[21].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[21].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-interno-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[21].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[21].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[21].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-interno-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[21].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[21].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[21].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-interno-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[21].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[21].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[21].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-interno-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[21].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[21].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[21].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-interno-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 43.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == true && pneuSelect[21].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[21], 'pneus-eixo2-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[21].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[21].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[21].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[21].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 2 duplo interno direito FIM-->

              <!-- eixo 1 duplo externo direito INICIO-->
              <div id="pneus-eixo1-duplo-externo-direito" *ngIf="quantidadeEixo == 1" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 3.6%;" >
                <div *ngIf="pneuSelect[3].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[3].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[3].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-externo-direito" *ngIf="quantidadeEixo == 2" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[3].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[3].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[3].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-externo-direito" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[3].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[3].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[3].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-externo-direito" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[3].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[3].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[3].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-externo-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[3].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[3].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[3].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-externo-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[3].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[3].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[3].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-externo-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[3].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[3].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[3].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-externo-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 43.5%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[3].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[3].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[3].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-externo-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 39%; margin-top: 3.6%;">
                <div *ngIf="pneuSelect[3].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[3].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[3].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == true && pneuSelect[3].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[3], 'pneus-eixo1-duplo-externo-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[3].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 1 duplo externo direito FIM-->

              <!-- eixo 1 duplo interno direito INICIO-->
              <div id="pneus-eixo1-duplo-interno-direito" *ngIf="quantidadeEixo == 1" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: 5.8%;" >
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[19].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[19].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[19].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-interno-direito" *ngIf="quantidadeEixo == 2" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[19].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[19].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[19].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-interno-direito" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[19].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[19].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[19].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-interno-direito" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[19].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[19].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[19].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-interno-direito" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[19].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[19].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[19].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-interno-direito" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[19].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[19].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[19].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-interno-direito" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[19].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[19].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[19].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-interno-direito" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 43.5%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[19].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[19].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[19].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-interno-direito" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 39%; margin-top: 5.8%;">
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == true && pneuSelect[19].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[19], 'pneus-eixo1-duplo-interno-direito')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[19].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[19].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[19].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[19].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 1 duplo interno direito FIM-->

              <!-- direcional direito 2-->
              <div id="pneus-direcional-2-direito" *ngIf="categoriaVeiculo == 'BI-TRUCK'">
                <div *ngIf="pneuSelect[58].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[58].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[58].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[58].ativo == true && pneuSelect[58].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[58], 'pneus-direcional-2-direito')" src="assets/img/pneu_alarmado.png" height="30" />
                <img *ngIf="pneuSelect[58].ativo == true && pneuSelect[58].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[58], 'pneus-direcional-2-direito')" src="assets/img/pneu_sensor_ativado.png" height="30" />
                <img *ngIf="pneuSelect[58].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="30" />
              </div>

              <!-- direcional direito -->
              <div id="pneus-direcional-direito">
                <div *ngIf="pneuSelect[0].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[0].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[0].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[0].ativo == true && pneuSelect[0].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[0], 'pneus-direcional-direito')" src="assets/img/pneu_alarmado.png" height="30" />
                <img *ngIf="pneuSelect[0].ativo == true && pneuSelect[0].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[0], 'pneus-direcional-direito')" src="assets/img/pneu_sensor_ativado.png" height="30" />
                <img *ngIf="pneuSelect[0].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="30" />
              </div>

                <!-- caminhao -->
                <div class="telemetria-caminhao">
                  <img src="assets/img/caminhao_telemetria.png" height="100%" width="70%" alt="" />
                </div>

              <!-- direcional -->  
              <div id="pneus-direcional-esquerdo">
                <img *ngIf="pneuSelect[1].ativo == true && pneuSelect[1].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[1], 'pneus-direcional-esquerdo')" src="assets/img/pneu_alarmado.png" height="30" />
                <img *ngIf="pneuSelect[1].ativo == true && pneuSelect[1].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[1], 'pneus-direcional-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="30" />
                <img *ngIf="pneuSelect[1].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="30" />
                <div *ngIf="pneuSelect[1].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[1].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[1].temperaturaAtual}}
                </div>
              </div>

              <!-- direcional esquerdo 2 -->  
              <div id="pneus-direcional-2-esquerdo" *ngIf="categoriaVeiculo == 'BI-TRUCK'">
                <img *ngIf="pneuSelect[59].ativo == true && pneuSelect[59].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[59], 'pneus-direcional-2-esquerdo')" src="assets/img/pneu_alarmado.png" height="30" />
                <img *ngIf="pneuSelect[59].ativo == true && pneuSelect[59].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[59], 'pneus-direcional-2-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="30" />
                <img *ngIf="pneuSelect[59].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="30" />
                <div *ngIf="pneuSelect[59].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[59].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[59].temperaturaAtual}}
                </div>
              </div>

              <!-- TEXTO ESTEPE -->
              <div id="estepes-texto">
                <h3>ESTEPES</h3>
              </div>

              <!-- eixo 1 duplo interno esquerdo INICIO-->
              <div id="pneus-eixo1-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 1" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[18].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[18].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[18].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 2" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;">
                <div *ngIf="pneuSelect[18].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[18].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[18].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%;">
                <div *ngIf="pneuSelect[18].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[18].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[18].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -8%;">
                <div *ngIf="pneuSelect[18].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[18].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[18].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -8%;">  
                <div *ngIf="pneuSelect[18].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[18].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[18].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: -8%;">
                <div *ngIf="pneuSelect[18].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[18].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[18].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: -8%;">
                <div *ngIf="pneuSelect[18].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[18].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[18].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 43.5%; margin-top: -8%;">
                <div *ngIf="pneuSelect[18].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[18].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[18].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo1-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 39%; margin-top: -8%;">
                <div *ngIf="pneuSelect[18].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[18].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[18].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == true && pneuSelect[18].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[18], 'pneus-eixo1-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[18].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 1 duplo interno esquerdo FIM-->

              <!-- eixo 1 duplo externo esquerdo INICIO-->
              <div id="pneus-eixo1-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 1" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[2].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[2].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[2].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 2" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[2].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[2].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[2].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[2].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[2].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[2].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[2].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[2].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[2].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[2].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[2].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[2].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[2].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[2].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[2].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[2].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[2].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[2].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 43.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[2].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[2].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[2].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo1-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 39%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == true && pneuSelect[2].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[2], 'pneus-eixo1-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[2].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[2].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[2].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[2].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 1 duplo externo esquerdo FIM-->

              <!-- eixo 2 duplo interno esquerdo INICIO-->
              <div id="pneus-eixo2-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 2" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;">
                <div *ngIf="pneuSelect[20].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[20].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[20].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;">
                <div *ngIf="pneuSelect[20].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[20].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[20].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%;">
                <div *ngIf="pneuSelect[20].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[20].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[20].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -8%;">
                <div *ngIf="pneuSelect[20].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[20].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[20].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -8%;">
                <div *ngIf="pneuSelect[20].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[20].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[20].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: -8%;">
                <div *ngIf="pneuSelect[20].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[20].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[20].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: -8%;">
                <div *ngIf="pneuSelect[20].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[20].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[20].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo2-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 43.5%; margin-top: -8%;">
                <div *ngIf="pneuSelect[20].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[20].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[20].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == true && pneuSelect[20].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[20], 'pneus-eixo2-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[20].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 2 duplo interno esquerdo FIM-->

              <!-- eixo 2 duplo externo esquerdo INICIO-->
              <div id="pneus-eixo2-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 2" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[4].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[4].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[4].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[4].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[4].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[4].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[4].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[4].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[4].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[4].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[4].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[4].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[4].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[4].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[4].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[4].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[4].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[4].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[4].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[4].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[4].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo2-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 43.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == true && pneuSelect[4].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[4], 'pneus-eixo2-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[4].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[4].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[4].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[4].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 2 duplo externo esquerdo FIM-->

              <!-- eixo 3 duplo interno esquerdo INICIO-->
              <div id="pneus-eixo3-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%">
                <div *ngIf="pneuSelect[22].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[22].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[22].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo3-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%">
                <div *ngIf="pneuSelect[22].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[22].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[22].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo3-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%">
                <div *ngIf="pneuSelect[22].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[22].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[22].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo3-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -8%">
                <div *ngIf="pneuSelect[22].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[22].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[22].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo3-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -8%">
                <div *ngIf="pneuSelect[22].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[22].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[22].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo3-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: -8%">
                <div *ngIf="pneuSelect[22].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[22].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[22].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo3-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: -8%">
                <div *ngIf="pneuSelect[22].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[22].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[22].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == true && pneuSelect[22].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[22], 'pneus-eixo3-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[22].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 3 duplo interno esquerdo FIM-->

              <!-- eixo 3 duplo externo esquerdo INICIO-->
              <div id="pneus-eixo3-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 3" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[6].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[6].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[6].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo3-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[6].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[6].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[6].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo3-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[6].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[6].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[6].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo3-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[6].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[6].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[6].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo3-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[6].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[6].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[6].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo3-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[6].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[6].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[6].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo3-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 48%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == true && pneuSelect[6].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[6], 'pneus-eixo3-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[6].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[6].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[6].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[6].temperaturaAtual}}
                </div>
              </div>
                <!-- eixo 3 duplo externo esquerdo FIM-->

              <!-- eixo 4 duplo interno esquerdo INICIO-->
              <div id="pneus-eixo4-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;">
                <div *ngIf="pneuSelect[24].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[24].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[24].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[24].ativo == true && pneuSelect[24].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[24], 'pneus-eixo4-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[24].ativo == true && pneuSelect[24].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[24], 'pneus-eixo4-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[24].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo4-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;">
                <div *ngIf="pneuSelect[24].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[24].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[24].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[24].ativo == true && pneuSelect[24].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[24], 'pneus-eixo4-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[24].ativo == true && pneuSelect[24].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[24], 'pneus-eixo4-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[24].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo4-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%;">
                <div *ngIf="pneuSelect[24].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[24].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[24].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[24].ativo == true && pneuSelect[24].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[24], 'pneus-eixo4-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[24].ativo == true && pneuSelect[24].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[24], 'pneus-eixo4-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[24].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo4-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -8%;">
                <div *ngIf="pneuSelect[24].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[24].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[24].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[24].ativo == true && pneuSelect[24].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[24], 'pneus-eixo4-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[24].ativo == true && pneuSelect[24].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[24], 'pneus-eixo4-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[24].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo4-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -8%;">
                <div *ngIf="pneuSelect[24].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[24].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[24].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[24].ativo == true && pneuSelect[24].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[24], 'pneus-eixo4-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[24].ativo == true && pneuSelect[24].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[24], 'pneus-eixo4-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[24].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo4-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: -8%;">
                <div *ngIf="pneuSelect[24].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[24].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[24].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[24].ativo == true && pneuSelect[41].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[24], 'pneus-eixo4-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[24].ativo == true && pneuSelect[41].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[24], 'pneus-eixo4-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[24].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 4 duplo interno esquerdo FIM-->

              <!-- eixo 4 duplo externo esquerdo INICIO-->
              <div id="pneus-eixo4-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 4" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[8].ativo == true && pneuSelect[8].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[8], 'pneus-eixo4-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[8].ativo == true && pneuSelect[8].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[8], 'pneus-eixo4-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[8].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[8].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[8].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[8].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo4-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[8].ativo == true && pneuSelect[8].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[8], 'pneus-eixo4-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[8].ativo == true && pneuSelect[8].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[8], 'pneus-eixo4-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[8].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[8].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[8].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[8].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo4-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[8].ativo == true && pneuSelect[8].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[8], 'pneus-eixo4-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[8].ativo == true && pneuSelect[8].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[8], 'pneus-eixo4-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[8].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[8].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[8].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[8].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo4-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[8].ativo == true && pneuSelect[8].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[8], 'pneus-eixo4-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[8].ativo == true && pneuSelect[8].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[8], 'pneus-eixo4-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[8].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[8].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[8].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[8].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo4-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[8].ativo == true && pneuSelect[8].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[8], 'pneus-eixo4-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[8].ativo == true && pneuSelect[8].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[8], 'pneus-eixo4-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[8].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[8].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[8].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[8].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo4-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 52.5%; margin-top: -5.8%;">
                <img *ngIf="pneuSelect[8].ativo == true && pneuSelect[8].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[8], 'pneus-eixo4-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[8].ativo == true && pneuSelect[8].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[8], 'pneus-eixo4-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[8].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[8].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[8].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[8].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 4 duplo externo esquerdo FIM-->

              <!-- eixo 5 duplo interno esquerdo INICIO-->
              <div id="pneus-eixo5-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[26].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo5-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[26].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[26].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo5-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[26].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo5-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[26].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo5-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 5 duplo interno esquerdo FIM-->

              <!-- eixo 5 duplo externo esquerdo INICIO-->
              <div id="pneus-eixo5-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 5" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[10].ativo == true && pneuSelect[10].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[10], 'pneus-eixo5-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[10].ativo == true && pneuSelect[10].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[10], 'pneus-eixo5-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[10].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[10].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[10].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[10].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo5-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[10].ativo == true && pneuSelect[10].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[10], 'pneus-eixo5-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[10].ativo == true && pneuSelect[10].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[10], 'pneus-eixo5-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[10].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[10].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[10].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[10].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo5-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[10].ativo == true && pneuSelect[10].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[10], 'pneus-eixo5-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[10].ativo == true && pneuSelect[10].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[10], 'pneus-eixo5-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[10].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[10].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[10].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[10].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo5-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[10].ativo == true && pneuSelect[10].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[10], 'pneus-eixo5-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[10].ativo == true && pneuSelect[10].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[10], 'pneus-eixo5-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[10].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[10].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[10].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[10].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo5-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 57%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[10].ativo == true && pneuSelect[10].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[10], 'pneus-eixo5-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[10].ativo == true && pneuSelect[10].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[10], 'pneus-eixo5-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[10].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[10].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[10].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[10].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 5 duplo externo esquerdo FIM-->

              <!-- eixo 6 duplo interno esquerdo INICIO-->
              <div id="pneus-eixo6-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[28].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[28].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[28].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[28].ativo == true && pneuSelect[28].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[28], 'pneus-eixo6-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[28].ativo == true && pneuSelect[28].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[28], 'pneus-eixo6-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[28].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo6-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[28].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[28].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[28].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[28].ativo == true && pneuSelect[28].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[28], 'pneus-eixo6-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[28].ativo == true && pneuSelect[28].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[28], 'pneus-eixo6-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[28].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo6-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[28].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[28].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[28].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[28].ativo == true && pneuSelect[28].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[28], 'pneus-eixo6-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[28].ativo == true && pneuSelect[28].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[28], 'pneus-eixo6-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[28].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo6-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[28].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[28].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[28].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[28].ativo == true && pneuSelect[28].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[28], 'pneus-eixo6-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[28].ativo == true && pneuSelect[28].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[28], 'pneus-eixo6-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[28].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 6 duplo interno esquerdo FIM-->

              <!-- eixo 6 duplo externo esquerdo INICIO-->
              <div id="pneus-eixo6-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 6" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[12].ativo == true && pneuSelect[12].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[12], 'pneus-eixo6-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[12].ativo == true && pneuSelect[12].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[12], 'pneus-eixo6-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[12].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[12].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[12].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[12].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo6-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[12].ativo == true && pneuSelect[12].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[12], 'pneus-eixo6-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[12].ativo == true && pneuSelect[12].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[12], 'pneus-eixo6-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[12].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[12].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[12].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[12].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo6-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[12].ativo == true && pneuSelect[12].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[12], 'pneus-eixo6-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[12].ativo == true && pneuSelect[12].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[12], 'pneus-eixo6-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[12].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[12].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[12].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[12].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo6-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 61.5%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[12].ativo == true && pneuSelect[12].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[12], 'pneus-eixo6-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[12].ativo == true && pneuSelect[12].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[12], 'pneus-eixo6-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[12].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[12].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[12].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[12].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 6 duplo externo esquerdo FIM-->

              <!-- eixo 7 duplo interno esquerdo INICIO-->
              <div id="pneus-eixo7-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[26].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo7-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo7-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo7-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[26].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo7-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo7-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo7-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[26].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[26].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[26].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo7-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == true && pneuSelect[26].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[26], 'pneus-eixo7-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[26].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
               <!-- eixo 7 duplo interno esquerdo FIM-->

              <!-- eixo 7 duplo externo esquerdo INICIO-->
              <div id="pneus-eixo7-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 7" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[14].ativo == true && pneuSelect[14].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[14], 'pneus-eixo7-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[14].ativo == true && pneuSelect[14].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[14], 'pneus-eixo7-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[14].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[14].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[14].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[14].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo7-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[14].ativo == true && pneuSelect[14].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[14], 'pneus-eixo7-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[14].ativo == true && pneuSelect[14].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[14], 'pneus-eixo7-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[14].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[14].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[14].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[14].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo7-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 66%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[14].ativo == true && pneuSelect[14].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[14], 'pneus-eixo7-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[14].ativo == true && pneuSelect[14].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[14], 'pneus-eixo7-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[14].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[14].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[14].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[14].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 7 duplo externo esquerdo FIM-->

              <!-- eixo 8 duplo interno esquerdo INICIO-->
              <div id="pneus-eixo8-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[32].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[32].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[32].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[32].ativo == true && pneuSelect[32].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[32], 'pneus-eixo8-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[32].ativo == true && pneuSelect[32].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[32], 'pneus-eixo8-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[32].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <div id="pneus-eixo8-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[32].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[32].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[32].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[32].ativo == true && pneuSelect[32].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[32], 'pneus-eixo8-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[32].ativo == true && pneuSelect[32].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[32], 'pneus-eixo8-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[32].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div>
              <!-- eixo 8 duplo interno esquerdo FIM-->

              <!-- eixo 8 duplo externo esquerdo INICIO-->
              <div id="pneus-eixo8-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 8" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[16].ativo == true && pneuSelect[16].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[16], 'pneus-eixo8-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[16].ativo == true && pneuSelect[16].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[16], 'pneus-eixo8-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[16].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[16].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[16].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[16].temperaturaAtual}}
                </div>
              </div>
              <div id="pneus-eixo8-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 70.5%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[16].ativo == true && pneuSelect[16].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[16], 'pneus-eixo8-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[16].ativo == true && pneuSelect[16].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[16], 'pneus-eixo8-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[16].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[16].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[16].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[16].temperaturaAtual}}
                </div>
              </div>
              <!-- eixo 8 duplo externo esquerdo FIM-->

              <!-- eixo 9 duplo interno esquerdo INICIO-->
              <div id="pneus-eixo9-duplo-interno-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -8%;" >
                <div *ngIf="pneuSelect[56].ativo == true" style="margin-top: -65%; background: lightblue; border-radius: 6px;
                font-size: 12px; text-align: center;">
                 <b>PSI :</b> {{pneuSelect[56].pressaoAtual}} <br>
                 <b>ºC :</b> {{pneuSelect[56].temperaturaAtual}}
                </div>
                <img *ngIf="pneuSelect[56].ativo == true && pneuSelect[56].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[56], 'pneus-eixo9-duplo-interno-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[56].ativo == true && pneuSelect[56].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[56], 'pneus-eixo9-duplo-interno-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[56].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
              </div> 
              <!-- eixo 9 duplo interno esquerdo FIM-->

              <!-- eixo 9 duplo externo esquerdo INICIO-->
              <div id="pneus-eixo9-duplo-externo-esquerdo" *ngIf="quantidadeEixo == 9" style="position: absolute; display: inline-grid; margin-left: 75%; margin-top: -5.8%;" >
                <img *ngIf="pneuSelect[54].ativo == true && pneuSelect[54].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[54], 'pneus-eixo9-duplo-externo-esquerdo')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[54].ativo == true && pneuSelect[54].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[54], 'pneus-eixo9-duplo-externo-esquerdo')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[54].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[54].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[54].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[54].temperaturaAtual}}
                </div>
              </div> 
              <!-- eixo 9 duplo externo esquerdo FIM-->

              <!-- ESTEPE 2 -->
              <div id="pneus-estepe2">
                <img *ngIf="pneuSelect[51].ativo == true && pneuSelect[51].statusPneu != 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[51], 'pneus-estepe2')" src="assets/img/pneu_alarmado.png" height="26" />
                <img *ngIf="pneuSelect[51].ativo == true && pneuSelect[51].statusPneu == 0" style="cursor: pointer;" (click)="openDialog(pneuSelect[51], 'pneus-estepe2')" src="assets/img/pneu_sensor_ativado.png" height="26" />
                <img *ngIf="pneuSelect[51].ativo == false" src="assets/img/pneu_sensor_desativado.png" height="26" />
                <div *ngIf="pneuSelect[51].ativo == true" style=" background: lightblue; border-radius: 6px;
                font-size: 11px; text-align: center;">
                  <b>PSI :</b> {{pneuSelect[51].pressaoAtual}} <br>
                  <b>ºC :</b> {{pneuSelect[51].temperaturaAtual}}
                </div>
              </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Entradas e Saídas">
            <ng-template matTabContent>
                <div>
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Entradas</th>
                            <th>Saídas</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{this.getInOutName('IN', 1)}}
                                <img *ngIf="this.marker.in1" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.in1" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" /></td>
                            <td>
                                {{this.getInOutName('OUT', 1)}}
                                <img *ngIf="this.marker.out1" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.out1" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{this.getInOutName('IN', 2)}}
                                <img *ngIf="this.marker.in2" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.in2" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" /></td>
                            <td>
                                {{this.getInOutName('OUT', 2)}}
                                <img *ngIf="this.marker.out2" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.out2" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{this.getInOutName('IN', 3)}}
                                <img *ngIf="this.marker.in3" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.in3" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" /></td>
                            <td>
                                {{this.getInOutName('OUT', 3)}}
                                <img *ngIf="this.marker.out3" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.out3" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{this.getInOutName('IN', 4)}}
                                <img *ngIf="this.marker.in4" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.in4" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" /></td>
                            <td>
                                {{this.getInOutName('OUT', 4)}}
                                <img *ngIf="this.marker.out4" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.out4" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{this.getInOutName('IN', 5)}}
                                <img *ngIf="this.marker.in5" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.in5" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" />
                            </td>
                            <td>
                                {{this.getInOutName('OUT', 5)}}
                                <img *ngIf="this.marker.out5" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.out5" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{this.getInOutName('IN', 6)}}
                                <img *ngIf="this.marker.in6" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.in6" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{this.getInOutName('IN', 7)}}
                                <img *ngIf="this.marker.in7" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.in7" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{this.getInOutName('IN', 8)}}
                                <img *ngIf="this.marker.in8" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.in8" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{this.getInOutName('IN', 9)}}
                                <img *ngIf="this.marker.in9" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.in9" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{this.getInOutName('IN', 10)}}
                                <img *ngIf="this.marker.in10" height="20" src="assets/img/green-circle.svg" alt="" />
                                <img *ngIf="!this.marker.in10" height="20" src="../../../../../assets/img/gray-circle.svg" alt="" />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </ng-template>

        </mat-tab>
    </mat-tab-group>
  </div>
</div>
