import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { ApiService } from "../../services/api.service";
import { DownloadCsv } from "src/app/services/download_csv.service";
import { CoreService } from "src/app/services/migrate/api.core.service";
import { ToolsService } from "src/app/services/migrate/tools.service";
import { TerminalCore } from "./terminal.core";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { TrackerModelService } from "../../services/tracker-model.service";

@Component({
  selector: "app-create-terminal",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class EditTerminalComponent extends TerminalCore implements OnInit {
  public title = "Atualizar Terminal";
  public titleVar = "";

  constructor(
    public activatedRoute: ActivatedRoute,
    private apiserviceCore: CoreService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    public tools: ToolsService,
    private doenload_cvs: DownloadCsv,
    private baseService: ApiBaseService,
    trackerModelService: TrackerModelService
  ) {
    super(apiserviceCore, apiService, formBuilder, tools, baseService, trackerModelService);
  }

  ngOnInit(): void {
    this.activedEdit = true;
    this.terminalForm = this.formBuilder.group(this.validation.rules());
    this.terminalId = this.activatedRoute.snapshot.params["id"];
    this.loadDataElements();
  }

  public async submitForm() {
    this.coverterUpperCase(this.terminalForm);
    this.update();
  }

  public coverterUpperCase(terminalForm: FormGroup) {
    if (terminalForm.get("observacao").value != null) {
      terminalForm
        .get("observacao")
        .setValue(terminalForm.get("observacao").value.toUpperCase());
    }
  }
}
