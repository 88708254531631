<div class="toolbox_search"
     *ngIf="this.permissionService.hasPermission('BUSCA_BOTAO_FUNCAO', this.permissions)">
  <mat-button-toggle-group
    name="search"
    [selected]="selected"
    (click)="onChangeValue()"
  >
    <mat-button-toggle value="search" [ngClass]="{ btnSelected: selected }">
      <mat-icon>search</mat-icon>
      <span class="btn-icon-text">Buscar</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
