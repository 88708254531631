import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AnalyzeReportManagerModel } from "src/app/models/analyze-report-manager-model";
import { STORAGE_KEY_UUID_MANAGER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { AnalyzeReportManagerModelPaginated } from "src/app/models/analyze-report-manager-model-paginated";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";

import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { MinioService } from "src/app/services/minio.service";

@Component({
  selector: "app-manager-analysis",
  templateUrl: "./manager-analysis.component.html",
  styleUrls: ["./manager-analysis.component.scss"],
})
export class ManagerAnalysisComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public minioService: MinioService;
  public datePipe: DatePipe;

  public analyzeReportManagerModelsPaginated: AnalyzeReportManagerModelPaginated;
  public analyzeReportOperatorModel: AnalyzeReportManagerModel;
  public errorMessage;
  public analyzeReportManagersPage: AnalyzeReportManagerModelPaginated;
  public analyzesManagers: MatTableDataSource<any> =
    new MatTableDataSource<any>();
  public tableColumns: string[];
  public typeFieldSelect = [
    { name: "Número Pacote", value: "PACKAGE" },
    { name: "Motorista", value: "DRIVER" },
    { name: "Placa", value: "PLATE" },
    { name: "Base Opera.", value: "BASE" },
  ];

  public typeFieldFilterSelected;
  public actualPage: PageEvent;
  public permissions: [];
  public filter = "";
  public field = "";
  public packageNumber = "";
  private intervalId: any;

  constructor(
    datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    analyzeTripService: AnalyzeTripService,
    minioService: MinioService
  ) {
    this.datePipe = datePipe;
    this.analyzeTripService = analyzeTripService;
    this.minioService = minioService;
  }

  ngOnInit(): void {
    this.typeFieldFilterSelected = this.typeFieldSelect[0].value;
    this.field = this.typeFieldFilterSelected;
    this.initialPage();
    this.loadReports(this.actualPage, null, null);
    this.loadTableDefinitions();
    this.startCallingLoadReports();
  }

  ngOnDestroy(): void {
    this.stopCallingLoadReports();
  }

  private startCallingLoadReports(): void {
    console.log("Data atual:", new Date());
    this.intervalId = setInterval(() => {
      this.loadReports(this.actualPage, this.filter, this.field);
    }, 30000); // Chama o método loadReports a cada 30 segundos
  }

  private stopCallingLoadReports(): void {
    clearInterval(this.intervalId); // Para de chamar loadReports
  }

  fieldFilter(): void {
    this.field = this.typeFieldFilterSelected;
    this.filter = "";
  }

  private loadReports(page: PageEvent, name: string, field: string): void {
    this.spinner.show();

    var uuidManager: string = storageX
      .whereKeyIs(STORAGE_KEY_UUID_MANAGER)
      .get();
    this.analyzeTripService
      .getAllAnalyzeReportManager(page, uuidManager, name, field)
      .subscribe(
        (t) => {
          this.analyzeReportManagersPage = t;
          this.analyzesManagers =
            new MatTableDataSource<AnalyzeReportManagerModel>(
              this.analyzeReportManagersPage.content
            );
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter as analises do operador");
          this.spinner.hide();
        }
      );
  }

  applyFilter(): void {
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "packageNumber",
      "dateStart",
      "dateEnd",
      "operationalBase",
      "companyDestiny",
      "driverName",
      "plate",
      "OperadorName",
      "filePdfAnalytical",
      "filePdfSynthetic",
      "operatorHandling",
    ];
  }

  exportReport(element): void {
    this.minioService.exportReport(element).subscribe();
  }

  openToTreatment(uuid: string) {
    this.router.navigate(["/analise-gestor/treatment/" + uuid]);
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }

  formatDate(dateString: string): string {
    // Converte a string de data para um objeto Date
    const date = new Date(dateString);

    // Formata a data usando o DatePipe
    return this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
  }
}
