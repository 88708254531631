import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { PermissionsService } from "src/app/helpers/permissions.service";
import storageX from "src/app/storageCore/storageX";
import {
  STORAGE_KEY_ID_MANAGER,
  STORAGE_KEY_ID_SUPERVISOR,
} from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-consultations",
  templateUrl: "./consultations.component.html",
  styleUrls: ["./consultations.component.scss"],
})
export class ConsultationsComponent implements OnInit {
  permissions: [];
  public gestor;
  public supervisor;
  constructor(
    public permissionService: PermissionsService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.gestor = storageX.whereKeyIs(STORAGE_KEY_ID_MANAGER).get();
    this.supervisor = storageX.whereKeyIs(STORAGE_KEY_ID_SUPERVISOR).get();
    this.permissions = this.permissionService.getPermissions();

    console.log(this.gestor);
    console.log(this.supervisor);
  }
}
