import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Atividade } from "../../models/Atividade.model";
import { ApiService } from "../../services/api.service";
import { Validation } from "./validation";

@Component({
  selector: "app-create-atividade",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ViewAtividadeComponent implements OnInit {
  public atividadeForm: FormGroup;
  public ativo;
  public atividadeSelected;
  public atividadeId: number;
  public title = "Visualizar Atividade";
  public errorMessage;
  private service: ApiService;
  private validation: Validation;

  ngOnInit(): void {
    this.atividadeForm = this.formBuilder.group(this.validation.rules());
    this.atividadeId = this.activatedRoute.snapshot.params["id"];
    this.loadAtividade(this.atividadeId);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.service = apiService;
    this.validation = new Validation();
  }

  fillData(): void {
    const fields = ["cdCodigo", "nmNome", "dsObservacao", "ativo"];
    fields.forEach((element) => {
      this.atividadeForm.controls[element].setValue(
        this.atividadeSelected[element]
      );
    });
  }

  public async loadAtividade(id): Promise<void> {
    try {
      const response = await this.service.get<Atividade>({
        url: `atividade/${id}`,
      });
      this.atividadeSelected = response as Atividade;
      this.fillData();
    } catch (error) {
      console.error(error);
    }
  }
}
