import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  NgZone,
  AfterViewInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { GoogleMapsAPIWrapper, AgmCircle, LatLngBounds } from "@agm/core";

import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";

import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";

import { LatLngInteface } from "../../contracts/LatLng.interface";
import { ContentInterface } from "../../contracts/Content.interface";

import { ApiService } from "../../services/api.service";
import { MapToolboxService } from "../../services/map.toolbox.service";
import { MapButtonService } from "../../services/map.buttom.service";

import { SearchComponent } from "../maps/search/search.component";
import { ProximityComponent } from "../maps/proximity/proximity.component";
import { DistanceDialogComponent } from "../maps/toolbox/distance-dialog/distance-dialog.component";
import { AddMarkerComponent } from "../maps/marker/add-marker/add-marker.component";
import { TerminalComponent } from "../maps/terminal/terminal.component";

import { EletricFencesService } from "../../services/eletric-fences.service";
import { GoogleService } from "src/app/services/google.service";
import GOOGLE_LOCATE_LBS$ from "./events$";
import { NgxSpinnerService } from "ngx-spinner";
import REMOVE_MARKER$ from "./remove_marker$";
import { MarkerCore } from "../maps/marker/markerCore";
import { MarkerMap } from "./markerMap";
import pathControl from "./controls/path.control";
import { ToolboxControl } from "../maps/toolbox/toolbox_control";
import {
  STORAGE_KEY_CURRENT_PERFIL,
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_MANAGER,
  STORAGE_KEY_ID_PERFIL,
  STORAGE_KEY_ID_SUPERVISOR,
  STORAGE_KEY_UUID_MANAGER,
  STORAGE_KEY_UUID_SUPERVISOR,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { RouteComponent } from "../maps/route/route.component";
import { ElectronicFenceComponent } from "../maps/electronic-fence/electronic-fence.component";
import { UserMarkerService } from "../../services/user-marker.service";
import { CurrentPositionService } from "../../services/current-position.service";
import { CurrentPosition } from "../../models/CurrentPosition";
import { MapPin } from "../../models/MapPin.model";
import { VehiclesMenuService } from "../../services/vehicles-menu.service";
import { TerminalExibitionControl } from "./terminal-exibition-control";
import { GeneralFilterComponent } from "../maps/general-filter/general-filter.component";
import Swal from "sweetalert2";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";

declare let google: any;

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  public analyzeTripService: AnalyzeTripService;
  public static subscription = null;
  @ViewChild(AgmCircle) child;

  isShowMarkerLabel = false;
  isMarkerClickable = true;

  protected isFitMap = true;
  protected isMapZoomChange = true;
  protected isFirstRequest = false;
  public map: any;
  protected radiusCircle: any;

  protected event: any;
  protected drawingManager: any;

  // Services
  private service: ApiService;
  /** Novos services refactoring **/
  private userMarkerService: UserMarkerService;
  private currentPositionService: CurrentPositionService;
  private vehicleMenuService: VehiclesMenuService;
  /** Fim dos services do refactoring **/

  private eletricFencesService: EletricFencesService;

  private mapButtonService: MapButtonService;
  private mapToolboxService: MapToolboxService;
  private toolboxAction: string = null;
  private command: any = null;
  public loader = false;
  public userMarkers = [];
  public markers = [];
  public companies = [];
  public marker = false;
  public searchMarker;
  public lat = -15.7801;
  public lng = -47.9292;
  public zoom = 3;

  public origin = null;
  public destination = null;
  public waypoints = [];
  public pointsPaths = [];

  routeOrigin: LatLngInteface = null;
  routeDestination: LatLngInteface = null;
  displayDirections = false;
  public radius = 30;

  public calcDistance = 0;
  public calcDistanceFisrt = true;
  public calcDistanceLatSource = null;
  public calcDistanceLatTarget = null;
  public calcDistanceLngSource = null;
  public calcDistanceLngTarget = null;
  public lines: any = [];
  public markerTracking;
  public markerTrackingInterval = null;
  public markerTrackingReposition = true;
  public markerTrackingTraceRoute = false;
  public loadAppTelemetria = false;

  public markersInterval = null;
  public dialogRef: MatDialogRef<any>;

  public poligonoRota = null;

  public renderOptions = {
    suppressMarkers: true,
  };
  public polyline = {
    editable: false,
    color: "red",
  };

  public showTrafficLayer = false;

  polygonElectronicFence;

  myLatLng;
  startLatitude;
  startLongetude;
  public poly;
  mouseMoveListener: any;
  settingDistance = false;

  horizontalPosition: MatSnackBarHorizontalPosition = "right";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  public subscribeCommand = false;

  /** Novas variaveis **/
  public terminalsOnMap: Array<number> = [];
  public listenerTerminalsInterval: number = 1 * 10 * 1000;
  public listenerTerminalOnTrackingInterval: number = 1 * 5 * 1000;
  /** Armazena os dados de posicionamento do terminal **/
  public terminalsData: Array<CurrentPosition> = [];
  public listenerTerminals: Subscription;
  terminalSubjects: Array<BehaviorSubject<CurrentPosition>> = [];
  /** Fim das novas váriaveis **/

  constructor(
    userMarkerService: UserMarkerService,
    currentPositionService: CurrentPositionService,
    apiService: ApiService,
    eletricFencesService: EletricFencesService,
    mapToolboxService: MapToolboxService,
    mapButtonService: MapButtonService,
    vehicleMenuService: VehiclesMenuService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private gmapsApi: GoogleMapsAPIWrapper,
    private activatedRoute: ActivatedRoute,
    public markerCore: MarkerCore,
    private router: Router,
    private zone: NgZone,
    public markerMap: MarkerMap,
    public toolboxControl: ToolboxControl,
    private googleService: GoogleService,
    private spinner: NgxSpinnerService,
    analyzeTripService: AnalyzeTripService
  ) {
    this.service = apiService;
    this.eletricFencesService = eletricFencesService;
    this.mapToolboxService = mapToolboxService;
    this.mapButtonService = mapButtonService;
    this.userMarkerService = userMarkerService;
    this.currentPositionService = currentPositionService;
    this.vehicleMenuService = vehicleMenuService;
    this.analyzeTripService = analyzeTripService;
  }

  ngOnInit(): void {
    this.toolboxControl.positionBarPx = false;
    this.toolboxControl.mapPosition = false;
    this.toolboxControl.showSidebar = true;
    this.toolboxControl.popupTelemetria = false;
    this.mapButtonService.setPathButtonDisabled(true);

    this.loadUserMarkers();
    this.initListenerTerminals();
    this.showTrafficLayer = false;

    this.mapToolboxServiceCurrentToolboxCommand();
    this.mapToolboxServiceCurrentToolboxCommand2();

    this.postGeolocation$();
    this.removeMarker();

    this.loadActivateRouteGetId();

    var managerId = storageX.whereKeyIs(STORAGE_KEY_ID_MANAGER).get();
    var supervisorId = storageX.whereKeyIs(STORAGE_KEY_ID_SUPERVISOR).get();
    var managerUuid = storageX.whereKeyIs(STORAGE_KEY_UUID_MANAGER).get();
    var supervisorUuid = storageX.whereKeyIs(STORAGE_KEY_UUID_SUPERVISOR).get();

    if (managerId != null) {
      this.loadNotificationManager(managerUuid);
    }

    console.log(supervisorId);
    if (supervisorId != null) {
      this.loadNotificationSupervisor(supervisorUuid);
    }
  }

  ngAfterViewInit(): void {
    this.setBounds(null);
  }

  /** Novos métodos Refactoring **/
  /**
   * Carrega os pontos do usuário (usuario-marker)
   * @param isReload
   */
  public loadUserMarkers(): void {
    this.userMarkerService.getUserMarkers().subscribe(
      (userMarkers) => {
        this.userMarkers = userMarkers;
      },
      (error) => {
        console.error("Erro ao obter os dados de user markers: " + error);
      }
    );
  }

  onChangeTerminals(terminals: Array<number>): void {
    console.log("Alterando terminais no componente principal");
    this.terminalsOnMap = terminals;
    this.loadTerminalsData();
  }
  public loadTerminalsData(): void {
    console.log("Buscando dados dos terminais...");
    if (this.terminalsOnMap.length > 0) {
      const idArray = this.terminalsOnMap.map((t) => t);
      this.currentPositionService
        .getCurrentPositionTerminals(idArray)
        .subscribe(
          (positions) => {
            console.log("Posições retornadas do backend...");
            console.log(positions);
            console.log(this.terminalsOnMap);
            this.processPositions(positions);
          },
          (error) => {
            throw new Error("Erro ao buscar posições dos terminais");
          }
        );
    } else {
      this.terminalsData = [];
      this.terminalSubjects = [];
    }
  }

  /**
   *
   * @param positions
   * @private
   */
  private processPositions(positions: Array<CurrentPosition>): void {
    positions.forEach((p) => {
      const indexToReplace = this.terminalsData.findIndex(
        (t) => t.id_terminal === p.id_terminal && !t.clone
      );
      p.pin = new MapPin(p.direcao, p.tag, null, { lat: p.latitude, lng: p.longitude }, false);
      p.clone = false;
      // Caso seja uma posição de um terminal que já está no array, atualiza os dados
      if (indexToReplace !== -1) {
        this.updateTerminalData(this.terminalsData[indexToReplace], p);
      } else {
        p.terminalSubject = new BehaviorSubject<CurrentPosition>(p);
        this.terminalsData.push(p);
      }
    });

    // Exclui as informações que não estão mais visiveis no mapa
    this.terminalsData.forEach((terminal, index) => {
      // Ignora as posições que não sejam de terminais reais
      if (terminal.clone) {
        return;
      }
      if (!this.terminalsOnMap.includes(terminal.id_terminal)) {
        this.terminalsData.splice(index, 1);
      }
    });

    console.log("Positions after process");
    console.log(this.terminalsData);
  }

  private updateTerminalData(
    position: CurrentPosition,
    newPosition: CurrentPosition
  ): void {
    position.id_terminal = newPosition.id_terminal;
    position.id = newPosition.id;
    position.id_empresa = newPosition.id_empresa;
    position.nome_empresa = newPosition.nome_empresa;
    position.id_divisao = newPosition.id_divisao;
    position.nome_divisao = newPosition.nome_divisao;
    position.id_motorista = newPosition.id_motorista;
    position.nome_motorista = newPosition.nome_motorista;
    position.direcao = newPosition.direcao;
    position.latitude = newPosition.latitude;
    position.longitude = newPosition.longitude;
    position.tag = newPosition.tag;
    position.placa = newPosition.placa;
    position.frota = newPosition.frota;
    position.data_online = newPosition.data_online;
    position.localizacao = newPosition.localizacao;
    position.pin = newPosition.pin;
    position.terminalSubject.next(newPosition);
  }

  public initListenerTerminals(): void {
    this.listenerTerminals = interval(this.listenerTerminalsInterval).subscribe(
      (x) => {
        console.log(
          "-->Listener de dados dos terminais. Execução " +
          x +
          " iniciada em " +
          new Date()
        );
        this.loadTerminalsData();
        console.log(
          "-->Listener de dados dos terminais. Execução " +
          x +
          " finalizada em " +
          new Date()
        );
      },
      (error) => {
        throw new Error("Erro ao executar o listener do mapa");
      }
    );
  }

  /**
   * Método responsavel por tratar o evento de centralização do mapa em um determinado terminal
   * @param terminalId: Id do terminal em qua o mapa deve ser centralizado
   */
  onChangeCenterMap(terminalId: number): void {
    let timeToWait = 300;
    console.log("Centralizando o mapa no terminal " + terminalId);
    let terminalToCenter;
    // Se os dados do terminal já existem, tira o timeout
    if (this.terminalsData.some((t) => t.id_terminal === terminalId)) {
      timeToWait = 0;
    }
    /**
     * O timeout é necessário para possibilitar a carga dos dados de possicionamento do
     * terminal, caso o mesmo ainda não esteja disposto no mapa
     */
    setTimeout(() => {
      console.log("verificando dados do terminal... Timeout: " + timeToWait);
      terminalToCenter = this.terminalsData.find(
        (t) => t.id_terminal === terminalId
      );
      const data = {
        action: "zoom_in_path",
        values: {
          bounds: [
            {
              lat: terminalToCenter.latitude,
              lng: terminalToCenter.longitude,
            },
          ],
        },
      };
      this.mapToolboxService.setCommand(data);
    }, timeToWait);
  }

  /**
   * Método responsavel pela exibição da identificação dos terminais no mapa
   */
  public showPlate(): void {
    console.log("Ativando legenda do mapa");
    this.toolboxControl.showMarkerLabel = true;
    /**this.terminalsData.forEach((terminal) => {
          const textPlate: string = isShowMarkerLabel ? terminal.placa : 'nenhuma placa';
          terminal.textPlate = textPlate;
          terminal.position = {latitude: terminal.latitude, longitude: terminal.longitude}
        }
    );**/
    this.resetToolboxCommand();
  }

  public hidePlate(): void {
    this.toolboxControl.showMarkerLabel = false;
  }

  private getIdPerfil(): number {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();

    if (!perfil) {
      throw new Error("Nenhum perfil encontrado");
    }
    return perfil;
  }

  markerClick(terminal: CurrentPosition): void {
    console.log("Click no marker -> " + terminal);
  }

  onChangeExibitionOffTerminal(
    terminalExibition: TerminalExibitionControl
  ): void {
    console.log("capturando a alteração de exibição no componente principal");
    console.log(terminalExibition.showInfoWindow);
    // let index = this.terminalsOnMap.findIndex(t => t.idTerminal === terminalExibition.idTerminal);
    // this.terminalsOnMap[index].showInfoWindow = terminalExibition.showInfoWindow;
    // this.terminalsOnMap.find(t => t.idTerminal === terminalExibition.idTerminal).showInfoWindow = terminalExibition.showInfoWindow;
  }

  /**
   * Método responsavel por obter a posição de todos os terminais que um usuário tem acesso
   * Utilizado temporariamento para o funcionamento das features de proximidade e seleção
   */
  loadAllTerminals(): Observable<Array<CurrentPosition>> {
    let idTerminals: Array<number> = [];
    return new Observable<Array<CurrentPosition>>((obs) => {
      this.vehicleMenuService
        .getVehiclesMenuByProfile(this.getIdPerfil())
        .subscribe(
          (vehicleMenu) => {
            vehicleMenu.full_tree.forEach((root) => {
              if (root.vehicles) {
                idTerminals = root.vehicles.map((v) => {
                  return v.id_terminal;
                });
              }

              if (root.childrens) {
                root.childrens.forEach((childrenOfRoot) => {
                  // Iterando as filiais e divisões das matrizes
                  let childrensVehicles = [];
                  childrensVehicles = childrenOfRoot.vehicles.map(
                    (v) => v.id_terminal
                  );
                  idTerminals.push(...childrensVehicles);

                  if (childrenOfRoot.childrens) {
                    childrenOfRoot.childrens.forEach((childrenOfChild) => {
                      // Iterando as divisões das filiais
                      let childrenOfChildVehicles = [];
                      childrenOfChildVehicles = childrenOfChild.vehicles.map(
                        (v) => v.id_terminal
                      );
                      idTerminals.push(...childrenOfChildVehicles);
                    });
                  }
                });
              }
            });

            this.currentPositionService
              .getCurrentPositionTerminals(idTerminals)
              .subscribe(
                (positions) => {
                  console.log("All Positions ...");
                  console.log(positions);
                  obs.next(positions);
                },
                (error) => "Erro ao obter as posições"
              );
          },
          (error) => {
            console.error("Erro ao obter os terminais");
          }
        );
    });
  }

  /** Fim dos novos métodos **/
  public onHideTelemetria(event): void {
    this.markerMap.popupTelemetria = false;
    this.loadAppTelemetria = false;
  }

  onShowTelemetria(event): void {
    this.spinner.show();
    setTimeout(() => {
      this.loadAppTelemetria = true;
    }, 3000);
  }

  showMessage(message: string): void {
    this.zone.run(() => {
      this.snackBar.open(message, "", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    });
  }
  trakingMarker(): void {
    if (this.markerTracking != null) {
      this.centerMarkerOnMap(this.markerTracking);
    }
    this.map.setZoom(15);
    this.markerTrackingReposition = true;
    this.markerTrackingInterval = interval(
      this.listenerTerminalOnTrackingInterval
    ).subscribe(
      () => {
        console.log(
          `Obtendo dados do terminal ${this.markerTracking.id_terminal}`
        );
        this.loadMarker();
      },
      (error) => console.log("error on interval", error)
    );
  }

  cancelTrakingMarker(): void {
    this.markerTrackingTraceRoute = false;
    this.pointsPaths = [];

    if (this.markerTrackingInterval) {
      this.markerTrackingInterval.unsubscribe();
      this.markerTrackingInterval = null;

      this.isFitMap = true;
      this.setBounds(null);
      this.mapToolboxService.setToolboxCommand("center");
    }
    if (this.poly) {
      this.poly.setMap(null);
    }
    this.setBounds(null);
    this.isFitMap = false;
    this.markerTracking = null;
  }

  /**
   * Função usada exclusivamente para o modo rastreamento
   * TODO: Verificar possibilidade de migrar para a nova view
   * TODO: Verificar a possibilidade de atualizar a posição do terminal no array de terminalData
   */
  async loadMarker(): Promise<void> {
    const marker = this.markerTracking;

    if (!marker || !marker.id) {
      return;
    }

    try {
      const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
      if (!perfil) {
        throw new Error("Nenhum perfil encontrado");
      }

      const url = `posicao-atual-terminal/${marker.id}`;
      const result = await this.service.get<ContentInterface[]>({
        url,
      });

      if (!this.markerTracking) {
        return;
      }

      const currentMarker = result;

      console.log(currentMarker);

      //return currentMarker;
      // if (this.markerTrackingReposition) {
      this.centerMarkerOnMap(currentMarker);
      // this.markerTrackingReposition = false;
      // }
    } catch (error) {
      console.log("Error: loadMarker()", error);
    }
  }

  centerMarkerOnMap(currentMarker): void {
    if (currentMarker) {
      // const { id } = this.markerTracking;
      const index = this.terminalsData.findIndex(
        (x) => x.id_terminal === this.markerTracking.id_terminal
      );

      const coords = new google.maps.LatLng(
        currentMarker.latitude,
        currentMarker.longitude
      );

      if (index) {
        this.terminalsData[index].latitude = currentMarker.latitude;
        this.terminalsData[index].longitude = currentMarker.longitude;
        this.terminalsData[index].direcao = currentMarker.direcao; // parseFloat(this.markers[index].direcao);
        this.terminalsData[index].terminalSubject.next(
          this.terminalsData[index]
        );
      }

      if (this.markerTrackingTraceRoute) {
        this.pointsPaths.push({
          lat: currentMarker.latitude,
          lng: currentMarker.longitude,
        });

        const polylineOptions = {
          strokeColor: "#FF0000",
          strokeOpacity: 1,
          strokeWeight: 2,
          zIndex: 5,
        };

        if (this.pointsPaths.length == 1) {
          this.poly = new google.maps.Polyline(polylineOptions);
        }

        if (this.poly && this.pointsPaths) {
          this.poly.setPath(this.pointsPaths);
          this.poly.setMap(this.map);
        }
      }

      if (this.map) {
        this.map.setCenter(coords);
      }
    }
  }

  mapReady(map): void {
    this.map = map;
  }

  addMarker(values): void {
    this.userMarkers.push({
      id: values.id,
      nmNome: values.nome,
      cdLatidude: values.latitude,
      cdLongitude: values.longitude,
    });
  }

  /**
   * Obtem os markers existentes em um determinado ponto do mapa
   * Revisado no refactoring do componente principal
   * Débito técnico: Mover a responsabilidade por calculo geométricos pra o backend
   * @param values
   */
  getMarkersFromRadius(values: any): void {
    this.terminalsOnMap = [];
    this.terminalsData = [];
    this.map.setOptions({ draggableCursor: null });
    this.isMarkerClickable = true;
    if (!values) {
      return;
    }

    this.radiusCircle?.setMap(null);

    const { distance, latitude, longitude } = values;

    this.radiusCircle = new google.maps.Circle({
      center: { lat: latitude, lng: longitude },
      radius: parseInt(distance) * 1000,
      clickable: false,
      map: this.map,
    });

    if (this.radiusCircle) {
      this.map.fitBounds(this.radiusCircle.getBounds());
    }

    const address = new google.maps.LatLng(
      parseFloat(latitude),
      parseFloat(longitude)
    );

    const selecteds = [];

    /** TODO: Revisar este ponto, levando a lógica para o backend **/
    // const allTerminals = this.loadAllTerminals();
    this.loadAllTerminals().subscribe((positions) => {
      for (let i = 0; i < positions.length; i++) {
        const marker = new google.maps.LatLng(
          positions[i].latitude,
          positions[i].longitude
        );

        const distanceFromLocation =
          google.maps.geometry.spherical.computeDistanceBetween(
            address,
            marker
          );

        if (distanceFromLocation <= parseInt(distance) * 1000) {
          selecteds.push(positions[i]);
          this.terminalsOnMap.push(positions[i].id_terminal);
        }
      }

      this.processPositions(selecteds);

      if (selecteds && selecteds.length > 0) {
        this.openTerminalDialog(selecteds);
      } else {
        this.dialog.closeAll();
        this.showMessage("Não há veículos dentro dos parâmetros escolhidos");
      }
      this.radiusCircle?.setMap(null);
    });
  }

  initDrawingManager(): void {
    const self = this;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["rectangle"],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.RECTANGLE,
    };

    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(this.map);

    google.maps.event.addListener(
      this.drawingManager,
      "rectanglecomplete",
      (event) => {
        self.zoomArea(event.bounds);
        event.setMap(null);
      }
    );
  }

  zoomArea(bounds: any): void {
    this.setBounds(bounds);
    this.drawingManager.setMap(null);
  }

  async loadRoute(id): Promise<void> {
    if (!id) {
      return;
    }
    try {
      const result = await this.service.get({
        url: "rota/buscar-posicoes",
        params: {
          id,
        },
      });

      if (!result || result.toString().length === 0) {
        return;
      }
      this.visualizarRota(result.toString());
      this.mapButtonService.setRouteButton(true);
    } catch (error) {
      console.log("Erro na visualização da Rota", error);
    }
  }

  async loadEletricFences(id): Promise<void> {
    if (!id) {
      return;
    }
    try {
      const result = await this.service.get({
        url: "cerca-eletronica/buscar-posicoes",
        params: {
          id,
        },
      });

      if (!result || result.toString().length === 0) {
        return;
      }
      this.visualizarCercaEletronica(result.toString());
      this.mapButtonService.setElectronicFenceButton(true);
    } catch (error) {
      console.log("Erro na visualização da Cerca Eletrônica", error);
    }
  }

  removePolygonElectronicFence(): void {
    this.polygonElectronicFence.setMap(null);
    this.polygonElectronicFence = null;
    this.router.navigate(["/"]);
  }

  public async handleMarkerDblClick(marker): Promise<void> {
    if (confirm("O Marker será removido. Confirmar?")) {
      try {
        const markerId = marker.id;
        await this.service.delete<any>(markerId, "usuario-marker");
        this.loadUserMarkers();
      } catch (error) {
        console.log("ocorreu um erro ao remover o  marker", error);
      }
    }
  }
  setBounds(bounds = null): void {
    if (!this.isFitMap || this.map == null) {
      return;
    }

    if (bounds) {
      this.map.fitBounds(bounds);
      return;
    }

    const boundsFromMarkers: LatLngBounds = new google.maps.LatLngBounds();
    for (const position of this.terminalsData) {
      if (position.latitude && position.longitude) {
        const latitude = position.latitude;
        const longitude = position.longitude;

        boundsFromMarkers.extend(new google.maps.LatLng(latitude, longitude));
      }
    }

    this.map.setCenter(boundsFromMarkers.getCenter());
    this.map.fitBounds(boundsFromMarkers);
  }

  openDialog(component: any, options: any = {}): any {
    const dialogRef = this.dialog.open(component, {
      width: "650px",
      ...options,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.resetToolboxCommand();
      if (this.radiusCircle) {
        this.radiusCircle.setMap(null);
      }
      this.loadUserMarkers();
    });

    return dialogRef;
  }

  openSearchDialog(): void {
    this.openDialog(SearchComponent);
  }

  openProximityDialog(): void {
    this.openDialog(ProximityComponent);
  }

  openRouteDialog(): void {
    this.openDialog(RouteComponent);
  }

  openElectronicFenceDialog(): void {
    this.openDialog(ElectronicFenceComponent);
  }
  openAddMarkerDialog(coords: any): void {
    const modalRef = this.openDialog(AddMarkerComponent);
    (modalRef.componentInstance as AddMarkerComponent).coords = coords;
  }

  openDistanceDialog(distance: any): void {
    this.zone.run(() => {
      if (!distance || distance === 0) {
        return;
      }
      const modalRef = this.openDialog(DistanceDialogComponent);
      (modalRef.componentInstance as DistanceDialogComponent).distance =
        distance;

      modalRef.afterClosed().subscribe(() => {
        this.calcDistance = 0;
      });
    });
  }

  /**
   * Método responsavel por abrir o dialogo de comandos do terminal
   * com algusn terminais já selecionados
   * @param selecteds
   */
  openTerminalDialog(selecteds?: any): void {
    const options = {
      width: "990px",
      height: "680px",
    };
    const modalRef = this.openDialog(TerminalComponent, options);
    (modalRef.componentInstance as TerminalComponent).data = this.terminalsData;
    if (selecteds) {
      (modalRef.componentInstance as TerminalComponent).selecteds = selecteds;
    }
  }

  public calculateDistanceAction(coords: any): void {
    if (this.calcDistanceFisrt) {
      this.calcDistanceLatSource = coords.lat;
      this.calcDistanceLngSource = coords.lng;
      this.calcDistanceFisrt = false;
      console.log("Fisrt", this.calcDistance);
      this.lines.push({ lat: coords.lat, lng: coords.lng });
    } else {
      this.calcDistanceLatTarget = coords.lat;
      this.calcDistanceLngTarget = coords.lng;
      this.calcDistanceFisrt = true;
      this.lines.push({ lat: coords.lat, lng: coords.lng });
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(
          this.calcDistanceLatSource,
          this.calcDistanceLngSource
        ),
        new google.maps.LatLng(
          this.calcDistanceLatTarget,
          this.calcDistanceLngTarget
        )
      );

      this.calcDistance = parseInt((distance / 1000).toFixed(2));

      this.map.setOptions({ draggableCursor: null });
      if (this.drawingManager) {
        this.drawingManager.setDrawingMode(null);
      }
      // this.openDistanceDialog(this.calcDistance);
      this.showMessage(this.calcDistance + " km");
      console.log("Second", this.calcDistance);
    }
  }

  toRad(Value): number {
    return (Value * Math.PI) / 180;
  }

  public zoomChange(event): void {
    if (this.markerTrackingReposition) {
      this.markerTrackingReposition = false;
    }
  }

  public handleMapClicked(event): void {
    this.event = event;
    const { coords } = event;
    console.log("handleMapClicked", event);

    const toolboxAction = this.toolboxAction;
    console.log("toolboxAction", toolboxAction);
    console.log("this.command", this.command);

    if (toolboxAction === "calc_distance" && !this.settingDistance) {
      // this.calculateDistanceAction(coords);

      console.log("chegou aqui?");

      this.isFitMap = false;

      this.settingDistance = true;
      this.startLatitude = coords.lat;
      this.startLongetude = coords.lng;

      const self = this;

      const pontos = new google.maps.MVCArray();
      const latlngParser = new google.maps.LatLng(
        this.startLatitude,
        this.startLongetude
      );
      const latlngParserFim = new google.maps.LatLng(0, 0);
      pontos.push(latlngParser);
      pontos.push(latlngParserFim);

      if (!this.poly) {
        this.resetToolboxCommand();
        this.isMarkerClickable = true;
        this.map.setOptions({
          draggableCursor: null,
        });
        this.settingDistance = false;
        this.isFitMap = true;
        return;
      }
      this.poly.setPath(pontos);
      this.poly.setMap(this.map);

      this.mouseMoveListener = google.maps.event.addListener(
        this.map,
        "mousemove",
        function (pt) {
          const pontos = new google.maps.MVCArray();
          const latlngParser = new google.maps.LatLng(
            self.startLatitude,
            self.startLongetude
          );
          pontos.push(latlngParser);
          pontos.push(pt.latLng);

          this.poly.setPath(pontos);

          google.maps.event.addListenerOnce(
            this.poly,
            "click",
            function (event) {
              if (self.settingDistance) {
                self.myLatLng = event.latLng;
                self.lat = self.myLatLng.lat();
                self.lng = self.myLatLng.lng();

                const R = 6371; // km
                const dLat = self.toRad(self.lat - self.startLatitude);
                const dLon = self.toRad(self.lng - self.startLongetude);
                const lat1 = self.toRad(self.startLatitude);
                const lat2 = self.toRad(self.lat);

                const a =
                  Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.sin(dLon / 2) *
                  Math.sin(dLon / 2) *
                  Math.cos(lat1) *
                  Math.cos(lat2);
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                const d = R * c;

                const distance = parseFloat(d.toFixed(2));
                if (distance > 0) {
                  self.calcDistance = distance;
                  self.showMessage(distance + " km");
                }
                this.poly.setMap(null);
                this.poly = null;
                self.isMarkerClickable = true;
                self.startLatitude = 0;
                self.isFitMap = true;
                self.startLongetude = 0;
                google.maps.event.removeListener(self.mouseMoveListener);
                this.map.setOptions({
                  draggableCursor: null,
                });

                self.mapButtonService.setDistanceButtonDisabled(false);
                self.settingDistance = false;
              }
            }
          );
        }
      );

      return;
    }

    if (!this.command) {
      return;
    }

    const { action, values } = this.command;

    if (action === "proximity_map") {
      this.getMarkersFromRadius({
        ...values,
        latitude: coords.lat,
        longitude: coords.lng,
      });
    } else {
      this.callCommand(coords);
    }
  }

  public callCommand(coords?: any): void {
    const action = this.toolboxAction;
    console.log(coords);

    if (action === "zoom_in") {
      this.zoom++;
    }
    if (action === "zoom_out") {
      this.zoom--;
    }
    if (action === "center") {
      this.setBounds(null);
    }

    if (action === "zoom_area") {
      this.initDrawingManager();
    }

    if (action === "add_marker") {
      this.openAddMarkerDialog(coords);
      this.map.setOptions({ draggableCursor: null });
    }
    if (action === "show_plate") {
      const isShowMarkerLabel = this.isShowMarkerLabel;
      this.isShowMarkerLabel = !isShowMarkerLabel;

      if (!isShowMarkerLabel) {
        return;
      }
    }

    if (action === "trafego") {
      /*
    if(this.showTrafficLayer == false){
      this.showTrafficLayer = true;
    }else{
      this.showTrafficLayer = false;
    }
    */
    }

    this.resetToolboxCommand();
  }

  resetToolboxCommand(): void {
    setTimeout(() => {
      this.mapToolboxService.setToolboxCommand("");
      this.calcDistance = 0;
      this.calcDistanceFisrt = true;
      this.calcDistanceLatSource = null;
      this.calcDistanceLatTarget = null;
      this.calcDistanceLngSource = null;
      this.calcDistanceLngTarget = null;
      this.lines = [];
    }, 1500);
  }

  openAddElectronicFenceDialog(): void {
    const dialogRef = this.dialog.open(ElectronicFenceComponent, {
      width: "650px",
    });
  }

  mapIdle(): void { }

  private loadNotificationSupervisor(profile): void {
    this.analyzeTripService.countAnalyzeSupervisor(profile).subscribe(
      (t) => {
        if (t > 0) {
          Swal.fire({
            icon: "warning",
            title: "Usuario Supervisor",
            html: `<h6>Indentificamos que você é um supervisor e tem ${t} nova analises para
            tratar, deseja ir para tela de analises do supervisor? </h6>`,
            showCloseButton: false,
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: "Não",
            showConfirmButton: true,
            confirmButtonText: "Sim",
            focusConfirm: false,
          }).then((action) => {
            if (action.isConfirmed) {
              this.router.navigate(["/analise-supervisor"]);
            }
          });
        }
      },
      (error) => {
        console.log("Erro ao obter a quantidade de analises");
        this.spinner.hide();
      }
    );
  }

  private loadNotificationManager(profile): void {
    console.log(profile);
    this.analyzeTripService.countAnalyzeManager(profile).subscribe(
      (t) => {
        if (t > 0) {
          Swal.fire({
            icon: "warning",
            title: "Usuario Gestor",
            html: `<h6>Indentificamos que você é um gestor e tem ${t} nova analises para
            tratar, deseja ir para tela de analises do gestor? </h6>`,
            showCloseButton: false,
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: "Não",
            showConfirmButton: true,
            confirmButtonText: "Sim",
            focusConfirm: false,
          }).then((action) => {
            if (action.isConfirmed) {
              this.router.navigate(["/analise-gestor"]);
            }
          });
        }
      },
      (error) => {
        console.log("Erro ao obter a quantidade de analises");
        this.spinner.hide();
      }
    );
  }

  private loadActivateRouteGetId(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const id = params.get("id");

      if (window.location.pathname.indexOf("electronic-fence-view") > -1) {
        if (id) {
          this.loadEletricFences(id);
        }
      } else if (window.location.pathname.indexOf("route-view") > -1) {
        if (id) {
          this.loadRoute(id);
        }
      }
    });
  }

  private mapToolboxServiceCurrentToolboxCommand(): void {
    if (this.subscribeCommand == true) {
      return;
    } else {
      this.subscribeCommand = true;
    }

    const self = this;

    this.mapToolboxService.currentCommand.subscribe((command) => {
      this.command = command;
      const action = command?.action;
      const values = command?.values;

      if (!command || !action || this.map == null) {
        return;
      }

      if (action === "center") {
        this.isFitMap = true;
        this.setBounds(null);
        this.mapToolboxService.setToolboxCommand("center");
      }

      if (action === "path_to") {
        const latSource = values.latSource;
        const lngSource = values.lngSource;

        const latTarget = values.latTarget;
        const lngTarget = values.lngTarget;

        this.routeOrigin = {
          latitude: latSource,
          longitude: lngSource,
        };
        this.routeDestination = {
          latitude: latTarget,
          longitude: lngTarget,
        };
        this.displayDirections = true;

        const coordSource = new google.maps.LatLng(latSource, lngSource);
        const coordTarget = new google.maps.LatLng(latTarget, lngTarget);

        const service = new google.maps.DirectionsService();
        service.route(
          {
            origin: coordSource,
            destination: coordTarget,
            travelMode: google.maps.DirectionsTravelMode.DRIVING,
          },
          function (result, status) {
            if (status == google.maps.DirectionsStatus.OK) {
              console.log("result direction>>>>", result);
              const displayDistance = result.routes[0].legs[0].distance.text;
              self.resetToolboxCommand();
              self.dialog.closeAll();
              self.showMessage(displayDistance);
              self.mapButtonService.setPathButton(true);
              self.mapButtonService.setPathButtonDisabled(false);
            }
          }
        );
      }

      // Processa a ação de gerar o trajeto
      if (action === "path") {
        const i = this.terminalsData.findIndex(({ id }) => id === command.id);
        this.terminalsData.splice(i, 1);
        this.toolboxControl.toggleSideNav();
        pathControl.setPathControlMarkerHistory(this, command, values);
        pathControl.pathControlRouteInMap(this, values);
        return;
      }

      if (action === "traking_and_path") {
        this.markerTracking = values;

        this.centerMarkerOnMap(values);
        this.markerTrackingTraceRoute = true;
        this.trakingMarker();
      }

      if (action === "electronic_fence") {
        console.log("electronic_fence", values);
        this.resetToolboxCommand();
        this.openAddElectronicFenceDialog();
      }

      if (action === "traking") {
        this.markerTracking = values;
        this.markerTrackingTraceRoute = false;
        this.trakingMarker();
      }

      if (action === "search") {
        this.lat = values.latitude;
        this.lng = values.longitude;

        const position = new google.maps.LatLng(this.lat, this.lng);

        const bounds: LatLngBounds = new google.maps.LatLngBounds();
        bounds.extend(position);
        this.map.fitBounds(bounds);
        this.map.setZoom(18);

        this.isFitMap = false;
        this.dialog.closeAll();
        return;
      }

      if (action === "proximity_location") {
        this.getMarkersFromRadius(values);
      }

      if (action === "proximity_map") {
        this.map.setOptions({ draggableCursor: "crosshair" });
        this.isMarkerClickable = false;
      }

      if (action === "route_location") {
        let paths = [];

        const latLongOrigem = new google.maps.LatLng(
          values.latitudeOrigem,
          values.longitudeOrigem
        );

        paths.push(latLongOrigem);

        const latLongDestino = new google.maps.LatLng(
          values.latitudeDestino,
          values.longitudeDestino
        );

        const service = this.service;
        const valor = values;
        let posicoes = "";

        const serviceRoute = new google.maps.DirectionsService();
        serviceRoute.route(
          {
            origin: latLongOrigem,
            destination: latLongDestino,
            travelMode: google.maps.DirectionsTravelMode.DRIVING,
          },
          function (result, status) {
            if (status == google.maps.DirectionsStatus.OK) {
              paths = paths.concat(result.routes[0].overview_path);

              for (let i = 0; i < paths.length; i++) {
                posicoes = posicoes + paths[i].lat() + "!" + paths[i].lng();
                if (i != paths.length - 1) {
                  posicoes = posicoes + "|";
                }
              }

              try {
                const user = storageX
                  .whereKeyIs(STORAGE_KEY_CURRENT_USER)
                  .get();

                let post;

                if (valor.idEmpresa != null) {
                  post = {
                    terminalRotaList:
                      valor.veiculos.length == 0
                        ? null
                        : valor.veiculos.map((element) => {
                          return {
                            idTerminal: element,
                          };
                        }),
                    dsPosicoes: posicoes,
                    flAtivoPontoControle: true,
                    nmNome: valor.nomeRota,
                    nrLimiteVelocidade: valor.velocidade,
                    nrDistanciaTolerada: valor.distancia,
                    empresa: {
                      id: valor.idEmpresa,
                    },
                  };
                } else {
                  post = {
                    terminalRotaList:
                      valor.veiculos.length == 0
                        ? null
                        : valor.veiculos.map((element) => {
                          return {
                            idTerminal: element,
                          };
                        }),
                    dsPosicoes: posicoes,
                    flAtivoPontoControle: true,
                    nmNome: valor.nomeRota,
                    nrLimiteVelocidade: valor.velocidade,
                    nrDistanciaTolerada: valor.distancia,
                    divisao: {
                      id: valor.divisao,
                    },
                  };
                }

                const url = "rota";
                const id = service.post(url, post);
                self.mapButtonService.setRouteButton(false);

                self.showMessage("Rota criada com sucesso");
              } catch (exception) {
                console.error(exception);
              }
            }
          }
        );
      }

      if (action === "route_map") {
        const drawingManager = new google.maps.drawing.DrawingManager({
          drawingMode: google.maps.drawing.OverlayType.POLYLINE,
          drawingControl: false,
        });

        const service = this.service;
        const valor = values;
        drawingManager.setMap(this.map);
        let posicoes = "";

        google.maps.event.addListenerOnce(
          drawingManager,
          "overlaycomplete",
          function (event) {
            if (event.type == google.maps.drawing.OverlayType.POLYLINE) {
              const paths = event.overlay.getPath();
              for (let i = 0; i < paths.length; i++) {
                if (i != paths.length - 1) {
                  posicoes =
                    posicoes +
                    paths.getAt(i).lat() +
                    "!" +
                    paths.getAt(i).lng() +
                    "|";
                } else {
                  posicoes =
                    posicoes +
                    paths.getAt(i).lat() +
                    "!" +
                    paths.getAt(i).lng();
                }
              }
              drawingManager.setMap(null);

              if (event != null && event.overlay != null) {
                event.overlay.setMap(null);
              }

              try {
                const user = storageX
                  .whereKeyIs(STORAGE_KEY_CURRENT_USER)
                  .get();

                let post;

                if (valor.idEmpresa != null) {
                  post = {
                    terminalRotaList:
                      valor.veiculos.length == 0
                        ? null
                        : valor.veiculos.map((element) => {
                          return {
                            idTerminal: element,
                          };
                        }),
                    dsPosicoes: posicoes,
                    flAtivoPontoControle: true,
                    nmNome: valor.nomeRota,
                    nrLimiteVelocidade: valor.velocidade,
                    nrDistanciaTolerada: valor.distancia,
                    empresa: {
                      id: valor.idEmpresa,
                    },
                  };
                } else {
                  post = {
                    terminalRotaList:
                      valor.veiculos.length == 0
                        ? null
                        : valor.veiculos.map((element) => {
                          return {
                            idTerminal: element,
                          };
                        }),
                    dsPosicoes: posicoes,
                    flAtivoPontoControle: true,
                    nmNome: valor.nomeRota,
                    nrLimiteVelocidade: valor.velocidade,
                    nrDistanciaTolerada: valor.distancia,
                    divisao: {
                      id: valor.divisao,
                    },
                  };
                }

                const url = "rota";
                const id = service.post(url, post);
                self.mapButtonService.setRouteButton(false);

                self.showMessage("Rota criada com sucesso");
              } catch (exception) {
                console.error(exception);
              }
            }
          }
        );
      }

      if (action === "electronicFence_map") {
        const service = this.service;
        const valor = values;

        let posicoes = "";

        let drawingMode = google.maps.drawing.OverlayType.POLYGON;

        if (valor.idCercaEletronicaLocalProcessamento == 1) {
          drawingMode = google.maps.drawing.OverlayType.RECTANGLE;
        }

        const drawingManager = new google.maps.drawing.DrawingManager({
          drawingMode,
          drawingControl: false,
        });
        drawingManager.setMap(this.map);

        google.maps.event.addListenerOnce(
          drawingManager,
          "overlaycomplete",
          function (event) {
            if (event.type == drawingMode) {
              console.log(event);
              if (drawingMode == google.maps.drawing.OverlayType.RECTANGLE) {
                const paths = event.overlay.bounds;
                console.log(paths);
                posicoes = paths.Ua.lo + "!" + paths.Ga.lo + "|";
                posicoes = posicoes + paths.Ua.hi + "!" + paths.Ga.hi;

                console.log(posicoes);
              } else {
                const paths = event.overlay.getPath();
                for (let i = 0; i < paths.length; i++) {
                  if (i != paths.length - 1) {
                    posicoes =
                      posicoes +
                      paths.getAt(i).lat() +
                      "!" +
                      paths.getAt(i).lng() +
                      "|";
                  } else {
                    posicoes =
                      posicoes +
                      paths.getAt(i).lat() +
                      "!" +
                      paths.getAt(i).lng();
                  }
                  console.log(posicoes);
                }
              }

              drawingManager.setMap(null);

              if (event != null && event.overlay != null) {
                event.overlay.setMap(null);
              }

              try {
                const user = storageX
                  .whereKeyIs(STORAGE_KEY_CURRENT_USER)
                  .get();

                console.log(valor);

                const post = {
                  terminalCercaEletronicaList:
                    valor.veiculos.length == 0
                      ? null
                      : valor.veiculos.map((element) => {
                        return {
                          idTerminal: element,
                        };
                      }),
                  dsPosicoes: posicoes,
                  nmNome: valor.nomeCercaEletronica,
                  nrVelocidadeAlarmePainel: valor.velocidadeAlarmePainel,
                  nrVelocidadeAlarmeVeiculo: valor.velocidadeAlarmeVeiculo,
                  cercaEletronicaLocalProcessamento: {
                    id: valor.idCercaEletronicaLocalProcessamento,
                  },
                  cercaEletronicaTipo: {
                    id: valor.idCercaEletronicaTipo,
                  },
                  empresa: {
                    id: valor.idEmpresa,
                    cdCnpj: valor.cnpj,
                  },
                };

                console.log(post);
                const url = "cerca-eletronica";
                const id = service.post(url, post);
                self.mapButtonService.setElectronicFenceButton(false);

                self.showMessage("Cerca Eletrônica criada com sucesso");
              } catch (exception) {
                console.error(exception);
              }
            }
          }
        );
      }

      if (action === "zoom_in_path") {
        const values = command.values;
        if (!values) {
          this.resetToolboxCommand();
          return;
        }

        this.isFitMap = false;
        if (values.bounds) {
          const bounds: LatLngBounds = new google.maps.LatLngBounds();

          for (const e of values.bounds) {
            bounds.extend(new google.maps.LatLng(e.lat, e.lng));
          }

          this.map.fitBounds(bounds);
          if (this.map.getZoom() > 15) {
            this.map.setZoom(15);
          }
          return;
        }

        const lat = parseFloat(values.latitude);
        const lng = parseFloat(values.longitude);

        this.map.setCenter({
          lat,
          lng,
        });
        // this.map.setZoom(10);
      }

      if (action === "addMarkerToMap") {
        this.addMarker(values);
      }

      if (action === "general_filter") {
        console.log("Processando o filtro geral");
        this.onChangeTerminals(Array.of(command.values.id_terminal));
        this.onChangeCenterMap(command.values.id_terminal);
      }
    });
  }

  public removerRota(): void {
    if (this.poligonoRota != null) {
      this.poligonoRota.setMap(null);
      this.poligonoRota = null;
    }
  }

  public removerElectronicFence(): void {
    if (this.poligonoRota != null) {
      this.poligonoRota.setMap(null);
      this.poligonoRota = null;
    }
  }

  /*
   * Renderiza uma rota no mapa
   */
  visualizarRota(posicoes: string): void {
    const bounds = new google.maps.LatLngBounds();

    const arrayItens = String(posicoes).split("|");
    const latlng = [];

    for (let i = 0; i < arrayItens.length; i++) {
      const arrayLatLng = String(arrayItens[i]).split("!");
      latlng.push(new google.maps.LatLng(arrayLatLng[0], arrayLatLng[1]));
      bounds.extend(new google.maps.LatLng(arrayLatLng[0], arrayLatLng[1]));
    }

    if (this.poligonoRota != null) {
      this.poligonoRota.setMap(null);
    }

    this.poligonoRota = new google.maps.Polyline({
      path: latlng,
      strokeColor: "#080249",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      clickable: false,
    });

    this.map.fitBounds(bounds);
    this.poligonoRota.setMap(this.map);
  }

  /*
   * Renderiza uma cerca eletrônica no mapa
   */
  visualizarCercaEletronica(posicoes: string): void {
    const bounds = new google.maps.LatLngBounds();

    const arrayItens = String(posicoes).split("|");

    if (arrayItens.length > 2) {
      const latlng = [];
      for (let i = 0; i < arrayItens.length; i++) {
        const arrayLatLng = String(arrayItens[i]).split("!");
        latlng.push(new google.maps.LatLng(arrayLatLng[0], arrayLatLng[1]));
        bounds.extend(new google.maps.LatLng(arrayLatLng[0], arrayLatLng[1]));
      }

      const arrayLatLng = String(arrayItens[0]).split("!");
      latlng.push(new google.maps.LatLng(arrayLatLng[0], arrayLatLng[1]));

      if (this.poligonoRota != null) {
        this.poligonoRota.setMap(null);
      }

      this.poligonoRota = new google.maps.Polyline({
        path: latlng,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        clickable: false,
      });
      this.poligonoRota.setMap(this.map);
      this.map.fitBounds(bounds);
    } else if (arrayItens.length == 2) {
      for (let i = 0; i < arrayItens.length; i++) {
        const arrayLatLng = String(arrayItens[i]).split("!");
        bounds.extend(new google.maps.LatLng(arrayLatLng[0], arrayLatLng[1]));
        this.map.fitBounds(bounds);
      }

      this.poligonoRota = new google.maps.Rectangle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        clickable: false,
        map: this.map,
        bounds,
      });

      this.poligonoRota.setMap(this.map);
    }
  }

  /** Método responsavel por tratar os eventos da toolbox superior
   * Revisado na refatoração para remover o uso dos objetos adicionais
   * @private
   */
  private mapToolboxServiceCurrentToolboxCommand2(): void {
    if (MainComponent.subscription != null) {
      MainComponent.subscription.unsubscribe();
    }

    MainComponent.subscription =
      this.mapToolboxService.currentToolboxCommand.subscribe((action) => {
        this.toolboxAction = action;

        if (!action || this.map == null) {
          return;
        }
        const runInstantly = [
          "center",
          "zoom_area",
          "select",
          "zoom_in",
          "zoom_out",
          "trafego",
        ];
        console.log("action ", action);

        if (runInstantly.includes(action)) {
          this.isFitMap = true;
          this.callCommand();
        }

        if (action === "add_marker") {
          this.map.setOptions({ draggableCursor: "crosshair" });
          this.command = { action, values: null };
        }

        if (action === "calc_distance") {
          this.mapButtonService.setDistanceButtonDisabled(true);
          this.map.setOptions({ draggableCursor: "crosshair" });
          this.isMarkerClickable = false;
          const polylineOptions = {
            strokeColor: "#FF0000",
            strokeOpacity: 1,
            strokeWeight: 2,
            zIndex: 5,
          };

          this.poly = new google.maps.Polyline(polylineOptions);
        }

        if (action === "calc_distance_reset") {
          this.mapButtonService.setPathButtonDisabled(false);
          this.map.setOptions({ draggableCursor: null });
          this.isMarkerClickable = true;
          this.setBounds(null);
        }

        if (action === "hide_plate") {
          this.hidePlate();
        }

        if (action === "show_plate") {
          this.showPlate();
        }

        if (action === "search") {
          this.openSearchDialog();
        }
        if (action === "search_reset") {
          this.isFitMap = true;
          this.searchMarker?.setMap(null);
        }
        // Ação de inativar o trajeto
        if (action === "button_path_reset") {
          pathControl.button_path_reset(this);
        }

        if (action === "proximity") {
          this.openProximityDialog();
        }

        if (action === "route") {
          this.openRouteDialog();
        }

        if (action === "hide-route") {
          this.removerRota();
        }

        if (action === "electronic-fence") {
          this.openElectronicFenceDialog();
        }

        if (action === "hide-electronic-fence") {
          this.removerElectronicFence();
        }

        if (action === "select") {
          const self = this;
          const options = {
            drawingControl: false,
            drawingControlOptions: {
              drawingModes: ["rectangle"],
            },
            polygonOptions: {
              draggable: false,
              editable: false,
            },
            drawingMode: google.maps.drawing.OverlayType.RECTANGLE,
          };

          this.drawingManager = new google.maps.drawing.DrawingManager(options);
          this.drawingManager.setMap(this.map);

          google.maps.event.addListener(
            this.drawingManager,
            "rectanglecomplete",
            function (event) {
              self.loadAllTerminals().subscribe((t) => {
                self.terminalsOnMap = [];
                self.terminalsData = [];
                const selecteds = [];
                const allTerminals = t;
                for (let i = 0; i < allTerminals.length; i++) {
                  const marker = allTerminals[i];
                  const { latitude, longitude } = marker;
                  const position = new google.maps.LatLng(latitude, longitude);

                  if (event.getBounds().contains(position)) {
                    selecteds.push(marker);
                    self.terminalsOnMap.push(allTerminals[i].id_terminal);
                  }
                }

                self.processPositions(selecteds);

                if (selecteds.length > 0) {
                  self.openTerminalDialog(selecteds);
                } else {
                  self.showMessage(
                    "Não há veículos dentro dos parâmetros escolhidos"
                  );
                }

                event.setMap(null);
                self.drawingManager.setMap(null);
              });
            }
          );
        }

        if (action === "general_filter") {
          this.openGeneralFilterDialog();
        }
      });
  }

  openGeneralFilterDialog(): void {
    this.openDialog(GeneralFilterComponent);
  }

  /* LOAD END */

  /* GOOGLE LBS */

  public postGeolocation$(): void {
    GOOGLE_LOCATE_LBS$.subscribe((result) => {
      console.log(result);
      this.googleService
        .postGeolocation(
          this.buildPayload(result.idV, result.lacV, result.mccV, result.mncV)
        )
        .then((r) => {
          const newMarker = {
            id: result.id + "gr",
            latitude: r.location.lat,
            longitude: r.location.lng,
            position: {
              latitude: r.location.lat,
              longitude: r.location.lng,
            },
            placa: result.placa,
            tag: "lbs",
            pin: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
          };

          this.markerMap.markersMap.push(newMarker);

          setTimeout(() => {
            this.markerMap.onCenterPosition(newMarker);
          }, 500);
        });
    });
  }

  private buildPayload(idV, lacV, mccV, mncV): any {
    return {
      cellTowers: [
        {
          cellId: idV,
          locationAreaCode: lacV,
          mobileCountryCode: mccV,
          mobileNetworkCode: mncV,
        },
      ],
    };
  }

  public removeMarker(): void {
    REMOVE_MARKER$.subscribe((id) => {
      this.markers = this.markers.filter((option) => option.id != id);
    });
  }

  /* GOOGLE LBS  END */

  ngOnDestroy(): void {
    console.log("Encerrando listeners...");
    this.listenerTerminals.unsubscribe();
  }
}
