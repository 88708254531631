import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Atividade } from "../../models/Atividade.model";
import { ApiService } from "../../services/api.service";
import { Validation } from "./validation";
import { NgxSpinnerService } from "ngx-spinner";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-create-atividade",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class EditAtividadeComponent implements OnInit {
  public atividadeForm: FormGroup;
  public ativo;
  public atividadeSelected;
  public atividadeId: number;
  public title = "Atualizar Atividade";
  public errorMessage;
  private service: ApiService;
  private validation: Validation;

  ngOnInit(): void {
    this.atividadeForm = this.formBuilder.group(this.validation.rules());
    this.atividadeId = this.activatedRoute.snapshot.params["id"];
    this.loadAtividade(this.atividadeId);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.service = apiService;
    this.validation = new Validation();
  }

  fillData(): void {
    const fields = ["cdCodigo", "nmNome", "dsObservacao", "ativo"];
    fields.forEach((element) => {
      this.atividadeForm.controls[element].setValue(
        this.atividadeSelected[element]
      );
    });
  }

  public async loadAtividade(id): Promise<void> {
    this.spinner.show();

    try {
      const response = await this.service.get<Atividade>({
        url: `atividade/${id}`,
      });
      this.atividadeSelected = response as Atividade;
      this.fillData();
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error(error);
    }
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();

    try {
      this.coverterUpperCase(this.atividadeForm);
      const values = this.atividadeForm.value;
      const id = Math.floor(this.atividadeId);
      const post = {
        id,
        ...values,
        idUsuarioAlteracao: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()
          ?.id,
      };

      await this.service.put<Atividade>(this.atividadeId, "atividade", post);
      this.spinner.hide();
      this.router.navigate(["/atividades"]);
    } catch (exception) {
      this.spinner.hide();
      this.errorMessage = exception.message;
      console.error(exception);
    }
  }

  public coverterUpperCase(atividadeForm: FormGroup) {
    console.log(atividadeForm);

    atividadeForm
      .get("nmNome")
      .setValue(atividadeForm.get("nmNome").value.toUpperCase());
    atividadeForm
      .get("dsObservacao")
      .setValue(atividadeForm.get("dsObservacao").value.toUpperCase());
  }
}
