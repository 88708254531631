import { DatePipe } from '@angular/common';

export class FormateDate {

    static formateDateAndHour(data: any, hora) {
        const dataPipe = new DatePipe("en-US");
        return dataPipe.transform(data, "yyyy-MM-dd") + 'T' + hora;
    }

    static parseDate(data: any) {
        const date = new Date(data);
        const dataPipe = new DatePipe("en-US");
        return dataPipe.transform(date, "yyyy-MM-dd");
    }

    static formateDateToIso(date: any) {
        const dateF: any = new Date(date).toISOString().split("T");
        return dateF[0];
    }

    static formateDate(date: any) {
        const dateF: any = new Date(date);
        const dataPipe = new DatePipe("en-US");
        return dataPipe.transform(dateF, "yyyy-MM-dd");
    }

    static formateTime(date: any) {
        const dateF = new Date(date);
        const dataPipe = new DatePipe("en-US");
        return dataPipe.transform(dateF, "HH:mm");
    }

    static formateDataRegister(data: any) {

        if (data.indexOf('-') > -1) {
            return data;
        }
        return FormateDate.parse(data) + 'T';
    }

    public static formateDateWithTime(date: string) {

        if (date.indexOf('-') > -1) {
            return date;
        }

        let datePaylod = date.split(" ");

        let dateFinal = FormateDate.parse(datePaylod[0]) + 'T';

        return dateFinal + datePaylod[1] + ":00";
    }

    public static formateDateWithTimeSeconds(date: string) {

        if (date.indexOf('-') > -1) {
            return date;
        }

        let datePaylod = date.split(" ");

        let dateFinal = FormateDate.parse(datePaylod[0]) + 'T';

        return dateFinal + datePaylod[1];
    }

    static formateDateT(date: string) {
        let tDate;

        if (date) {
            tDate = date.replace("T", " ");
        }

        let dt = new Date(tDate)
        const dataPipe = new DatePipe("en-US");
        return dataPipe.transform(dt, "dd/MM/yyyy HH:mm:ss");
    }


    public static parse(date: string): any {

        date = date.replace(/\D+/g, '');

        let dd = date.substring(0, 2);
        let mm = date.substring(2, 4) + '-';
        let yy = date.substring(4, 9) + '-';
        let result = yy + mm + dd;

        return result;
    }

    public static invalidDate(date: any): boolean {

        if (date == null || date == '' || date == undefined) {
            return true
        }

        let d: any

        if (date.length !== 10) {
            return true;
        }

        if (date.indexOf('-') > -1) {

            d = date
        } else {
            d = FormateDate.parse(date)
        }

        d = new Date(d);

        if (d == 'Invalid Date') {
            return true;
        }

        return false;
    }
}