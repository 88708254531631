export class ToastMsgErro{
  constructor(detail,
              key?){
    this.detail = detail;
    this.key = key;
    this.severity = 'error';
    this.summary = 'Erro!';
  }

  private key: string;
  private severity: string;
  private summary: string;
  private detail: string;
}

export class ToastMsgSuccess{
  constructor(detail,
              key?){
    this.detail = detail;
    this.key = key;
    this.severity = 'success';
    this.summary = 'Ok';
  }

  private key: string;
  private severity: string;
  private summary: string;
  private detail: string;
}

export class ToastMsgInfo{
  constructor(detail,
              key?){
    this.detail = detail;
    this.key = key;
    this.severity = 'info';
    this.summary = 'Info';
  }

  private key: string;
  private severity: string;
  private summary: string;
  private detail: string;
}
