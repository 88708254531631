import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Perfil } from "../../models/Perfil.model";
import { Validation } from "./validation";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { PerfilTipo } from "src/app/models/PerfilTipo.model";

@Component({
  selector: "app-create-perfil",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ViewPerfilComponent implements OnInit {
  private apiBaseService: ApiBaseService;

  public perfilForm: FormGroup;
  public perfilTipoSelected;
  public perfil: Perfil;
  public perfilId: number;
  public title = "Visualizar Perfil";
  public errorMessage;
  private validation: Validation;
  public types: any = [];

  public pageSize = 10;

  ngOnInit(): void {
    this.perfilForm = this.formBuilder.group(this.validation.rules());
    this.perfilId = this.activatedRoute.snapshot.params["id"];
    this.loadPerfil(this.perfilId);
    this.setup();
  }

  async setup(): Promise<void> {
    var pageIndex = 0;

    const params = {
      size: this.pageSize,
      page: pageIndex,
    };
    const types = await this.apiBaseService.get<PerfilTipo>({
      url: new PerfilTipo()["nomeConsultaApiBase"],
      params,
    });
    this.types = types["content"];
  }

  public async loadPerfil(id): Promise<void> {
    try {
      const response = await this.apiBaseService.get<Perfil>({
        url: new Perfil()["nomeConsultaApiBase"] + "/" + id,
      });
      this.perfil = response as Perfil;
      this.fillUserData();
    } catch (error) {
      console.error(error);
    }
  }
  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }
  fillUserData(): void {
    this.perfilTipoSelected = this.perfil.perfilTipo.id;

    if (this.perfilTipoSelected) {
      this.perfilForm.controls["perfilTipo"].setValue(this.perfilTipoSelected);
    }

    const fields = ["nmPerfil", "dsPerfil"];
    fields.forEach((element) => {
      this.perfilForm.controls[element].setValue(this.perfil[element]);
    });
  }
}
