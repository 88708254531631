import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "./../../services/authentication.service";
import { StartAppService } from "src/app/start-app.service";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  notifications: any[] = [];
  showNotificationList: boolean = false;
  modalOpen: boolean = false;

  public analyzeTripService: AnalyzeTripService;
  private intervalId: any;

  constructor(
    private authenticationService: AuthenticationService,
    private oauthTokenService: StartAppService,
    private router: Router,
    analyzeTripService: AnalyzeTripService
  ) {
    this.analyzeTripService = analyzeTripService;
  }

  ngOnInit(): void {
    this.loadDataElements();
    this.startCallingLoadNotify();
    // comentado.
    // const idPerfil = parseInt(localStorage.getItem('idPerfil'));
    // if (!idPerfil) {
    //   this.logout();
    // }
  }

  ngOnDestroy(): void {
    this.stopCallingLoadNotify();
  }

  private startCallingLoadNotify(): void {
    console.log("Data atual:", new Date());
    this.intervalId = setInterval(() => {
      this.loadDataElements();
    }, 120000); // Chama o método loadReports a cada 2 minutos
  }

  private stopCallingLoadNotify(): void {
    clearInterval(this.intervalId); // Para de chamar loadReports
  }

  public viewRejectionOperator(uuid: string) {
    this.analyzeTripService.operatorView(uuid).subscribe((t) => {
      this.notifications = t;
      this.loadDataElements();
    });
  }

  public async loadDataElements() {
    try {
      var userId = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;

      this.analyzeTripService.getNotifyOperator(userId).subscribe((t) => {
        this.notifications = t;
        console.log(this.notifications.length); // Executado após a conclusão de loadDataElements
      });
    } catch (error) {
      throw new EvalError(error);
    }
  }

  toggleNotificationList() {
    this.showNotificationList = !this.showNotificationList;
  }

  async logout(): Promise<void> {
    await this.authenticationService.logout();
    await this.oauthTokenService.initApp();
    setTimeout(() => {
      this.router.navigate(["/login"]);
    }, 500);
  }
}
