import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { VinculoDialogComponent } from "src/app/components/dialog-template/vinculo-dialog/vinculo-dialog.component";
import { FuncionalidadeInterface } from "src/app/contracts/Funcionalidade.interface";
import { PerfilFuncionalidadeInterface } from "src/app/contracts/PerfilFuncionalidade.interface";
import { Funcionalidade } from "src/app/models/Funcionalidade.model";
import { PerfilFuncionalidade } from "src/app/models/PerfilFuncionalidade.model";
import ApiService from "src/app/services/api.service";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { hasAnyDifferenceInAttributeValue } from "src/app/utils/Funcoes";
import { Page } from "src/app/utils/paginate";

@Component({
  selector: "app-form-aba-funcionalidade",
  templateUrl: "./form-aba-funcionalidade.component.html",
  styleUrls: ["./form-aba-funcionalidade.component.scss"],
})
export class FormAbaFuncionalidadeComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("filter") filter: ElementRef;
  @ViewChild("typeFilter") typeFilter: ElementRef;

  @Input()
  public perfilId: number;
  public hideMasterToggle = false;
  public page = new Page();

  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[] = [
    "id",
    "nmFuncionalidade",
    "configurar",
    "vincular",
  ];

  private service: ApiService;
  private apiBaseService: ApiBaseService;
  public funcionalidade: MatTableDataSource<FuncionalidadeInterface>;
  public perfilFuncionalidades: PerfilFuncionalidadeInterface[];

  constructor(
    apiService: ApiService,
    apiBaseService: ApiBaseService,
    private changeDetectorRefs: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    this.service = apiService;
    this.apiBaseService = apiBaseService;
    this.funcionalidade = new MatTableDataSource<FuncionalidadeInterface>([]);
  }

  ngOnInit(): void {
    this.findPerfilFuncionalidades();
    this.loadData();
  }

  async findPerfilFuncionalidades(): Promise<void> {
    const perfilId = this.perfilId;
    const params = {
      size: this.pageSize,
      page: 0,
      perfilId,
    };

    this.perfilFuncionalidades = await this.apiBaseService.get<
      PerfilFuncionalidadeInterface[]
    >({
      url: new PerfilFuncionalidade()["listConsultaApiBase"],
      params,
    });
  }

  public hasVinculo(row): boolean {
    return this.perfilFuncionalidades["content"]?.some(
      (pf) => pf.funcionalidade.id === row.id && pf.ativo
    );
  }

  public onMasterToggleChange = (e): void => {
    this.funcionalidade.data.forEach((func) => {
      this.updateData(func, e.checked);
    });
  };

  public handleMasterToggle = (): boolean => {
    return this.funcionalidade.data.every((func) => this.hasVinculo(func));
  };
  public onCheckboxChange = (e, row): void => {
    this.updateData(row, e.checked);
  };

  public isVinculosDisabled = (row): boolean => {
    return this.loader;
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadData(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadData(
      0,
      this.typeFilter.nativeElement.value,
      this.filter.nativeElement.value
    );
  };

  private async updateData(data, check) {
    this.loader = true;
    const vinculo = this.perfilFuncionalidades["content"].find(
      (pf) => pf.funcionalidade.id === data.id
    );

    const post = {
      ativo: check,
      perfilId: this.perfilId,
    };
    if (vinculo) {
      vinculo.ativo = check;
      await this.apiBaseService.patch<PerfilFuncionalidade>(
        vinculo.id,
        new PerfilFuncionalidade()["nomeConsultaApiBase"],
        post
      );
    } else {
      const perfilFuncionalidade = {
        ativo: true,
        perfil: { id: this.perfilId },
        funcionalidade: { id: data.id },
        flLeitura: true,
        flInsercao: true,
        flAtualizacao: true,
        flDelecao: true,
        idUsuarioCriacao: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()
          ?.id,
      };
      const novoperfilFuncionalidade =
        await this.apiBaseService.post<PerfilFuncionalidade>(
          new PerfilFuncionalidade()["nomeConsultaApiBase"],
          perfilFuncionalidade
        );
      this.loader = false;
      this.perfilFuncionalidades["content"].push(novoperfilFuncionalidade);
    }
    this.loader = false;
  }

  public async loadData(pageIndex = 0, campo?, filter?): Promise<void> {
    this.loader = true;
    const params = {
      size: this.pageSize,
      page: pageIndex,
    };
    // if (pageIndex) {
    //   params["page"] = pageIndex;
    // }

    // if (campo && filter) {
    //   params[campo] = filter;
    // }

    try {
      const result = await this.apiBaseService.get<any>({
        url: new Funcionalidade()["nomeConsultaApiBase"],
        params,
      });

      this.funcionalidade.data = result["content"];
      this.page = result["page"];

      this.changeDetectorRefs.detectChanges();

      this.loader = false;
    } catch (error) {
      console.error("loadPerfil", error);
      this.loader = false;
    }
  }

  public configVinculo(funcionalidade) {
    // Não existe vínculo
    const vinculo = this.perfilFuncionalidades.find(
      (pf) => pf.funcionalidade.id === funcionalidade.id
    );

    if (!vinculo || !vinculo.ativo) {
      return;
    }
    this.dialog
      .open(VinculoDialogComponent, {
        data: { dataValue: Object.assign({}, vinculo) },
        panelClass: "custom-dialog",
      })
      .afterClosed()
      .subscribe((dataAfterClose) => {
        if (hasAnyDifferenceInAttributeValue(dataAfterClose, vinculo)) {
          vinculo.flAtualizacao = dataAfterClose.flAtualizacao;
          vinculo.flDelecao = dataAfterClose.flDelecao;
          vinculo.flInsercao = dataAfterClose.flInsercao;
          vinculo.flLeitura = dataAfterClose.flLeitura;

          this.service.patch<PerfilFuncionalidade>(
            vinculo.id,
            "perfil-funcionalidade",
            {
              flAtualizacao: vinculo.flAtualizacao,
              flDelecao: vinculo.flDelecao,
              flInsercao: vinculo.flInsercao,
              flLeitura: vinculo.flLeitura,
            }
          );
        }
      });
  }
}
