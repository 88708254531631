import { PerfilEmpresaInterface } from "../contracts/PerfilEmpresa.interface";
import { CompanyInterface } from "../contracts/Company.interface";
import { PerfilInterface } from "../contracts/Perfil.interface";
import { DivisionInterface } from "../contracts/Division.interface";

export class PerfilEmpresa implements PerfilEmpresaInterface {
  nomeConsultaApiBase = "perfil-empresa";
  listConsultaApiBase = "perfil-empresa/list";
  listConsultaTransporter = "perfil-empresa/transporter";

  divisao: DivisionInterface;
  id: number;
  ativo: boolean;
  perfil: PerfilInterface;
  empresa: CompanyInterface;
}
