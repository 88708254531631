import { Component, OnInit } from '@angular/core';
import {TransporterCore} from './transporter.core';
import {FormBuilder} from '@angular/forms';
import {ApiBaseService} from '../../services/migrate/api.base.service';
import {VehiclesMenuService} from '../../services/vehicles-menu.service';
import {TransporterService} from '../../services/transporter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import ApiService from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../models/User.model';
import {Transporter} from '../../models/transporter.model';

@Component({
  selector: 'app-view-transporter',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class ViewTransporterComponent extends TransporterCore implements OnInit {
  public title = 'Visualizar Transportador/Agregado';
  public formMode: any;
  public titleVar = '';
  public registrationTypes: Array<any>;
  public companies: Array<any>;
  public companiesFiltered: Array<any>;
  public hide = true;
  public transporterUuid = '';
  public transporter: Transporter;
  public userOfTransporter: User;
  public userProfiles;
  public empresaSelected: any;

  constructor(formBuilder: FormBuilder,
              apiBaseService: ApiBaseService,
              service: ApiService,
              vehicleService: VehiclesMenuService,
              transporterService: TransporterService,
              spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute,
              router: Router) {
    super(formBuilder, apiBaseService, vehicleService, service, transporterService, spinner, router);
    this.transporterService = transporterService;
    this.transporterFormGroup = super.loadFormDefinition();
    this.userFormGroup = super.loadUserFormDefinition();
    this.transporterFormGroup.disable();
    this.userFormGroup.disable();

  }

  ngOnInit(): void {
    console.log('Iniciando o componente de visualização do transportador');
    this.formMode = 'view';
    this.transporterUuid = this.activatedRoute.snapshot.params.uuid;
    this.registrationTypes = this.getRegistrationType();
    this.loadTransporter(this.transporterUuid, false);
  }

  submitForm(): void {}

}
