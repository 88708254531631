import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsgToastErrorService } from 'src/app/utils/menssages/msg.service';
import { ApiHttpCService } from './api.http.client.service';
import { Params } from './params';
import {ApiEndPoints} from './apiEndPoints.enum';
import storageX from '../../storageCore/storageX';
import {STORAGE_KEY_ID_PERFIL} from '../../storageCore/constStorageKeys';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  private router: Router;
  private msgToastErrorService: MsgToastErrorService;
  private spinner : NgxSpinnerService;
  private params: Params;


constructor(
     private trouter: Router,
     private api: ApiHttpCService,
     private tmsgToastErrorService: MsgToastErrorService,
     private tspinner : NgxSpinnerService,
     private tparams: Params
     ) {
       this.router = this.trouter;
       this.msgToastErrorService = this.tmsgToastErrorService;
       this.spinner = this.tspinner;
       this.params = this.tparams;
      }

      public builder(){
        return {
          router : this.router,
          openToast : this.msgToastErrorService,
          spinner : this.spinner,
          api: this.api,
          params: this.tparams,
          mainApis: {
            companys: this.api.get(`${this.api}${ApiEndPoints.COMPANY}?profileId=${storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get()}&page=0&size10000`)
          }
        }
      }

}
