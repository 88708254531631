export const EQUIPAMENTOS =  [
    {
      "enum": 0,
      "value": "Tomada de Força"
    },
    {
      "enum": 1,
      "value": "Utilização de GNV"
    },
    {
      "enum": 2,
      "value": "Recarga de Baterias"
    },
    {
      "enum": 3,
      "value": "Indicador de baixo nível de oléo"
    },
    {
      "enum": 4,
      "value": "Indicador de alerta de manutenção"
    },
    {
      "enum": 5,
      "value": "Indicador de falha no airbag"
    },
    {
      "enum": 6,
      "value": "Indicador de falha na injeção eletrônica"
    },
    {
      "enum": 7,
      "value": "Indicador de falha no ESP"
    },
    {
      "enum": 8,
      "value": "Indicador de falha no ABS"
    },
    {
      "enum": 9,
      "value": "Indicador de superaquecimento do motor"
    },
    {
      "enum": 10,
      "value": "Indicador de pressão irregular de óleo"
    },
    {
      "enum": 11,
      "value": "Indicador de problema no sistema de freios"
    },
    {
      "enum": 12,
      "value": "Indicador de problema na bateria"
    },
    {
      "enum": 13,
      "value": "Indicador de baixo nível de líquido de arrefecimento"
    },
    {
      "enum": 14,
      "value": "Indicador de baixo nível de fluído de freio"
    },
    {
      "enum": 15,
      "value": "Aquecedor de estacionamento"
    },
    {
      "enum": 16,
      "value": "Baú"
    },
    {
      "enum": 17,
      "value": "Portas"
    },
    {
      "enum": 18,
      "value": "Farol de Neblina traseiro"
    },
    {
      "enum": 19,
      "value": "Farol de neblina frontal"
    },
    {
      "enum": 20,
      "value": "Farol alto"
    },
    {
      "enum": 21,
      "value": "Farol baixo"
    },
    {
      "enum": 22,
      "value": "Luz de contorno"
    },
    {
      "enum": 23,
      "value": "Marcha a ré"
    },
    {
      "enum": 24,
      "value": "Trava Central"
    },
    {
      "enum": 25,
      "value": "Freio de mão"
    },
    {
      "enum": 26,
      "value": "Pedal de embreagem"
    },
    {
      "enum": 27,
      "value": "Pedal de freio"
    },
    {
      "enum": 28,
      "value": "Cruise Control"
    },
    {
      "enum": 29,
      "value": "Ar condicionado"
    },
    {
      "enum": 30,
      "value": "Indicador de cinto do motorista"
    },
    {
      "enum": 31,
      "value": "Indicador de reserva do tanque"
    },
    {
      "enum": 32,
      "value": "Compartimento do motor"
    },
    {
      "enum": 33,
      "value": "Baú porta malas"
    },
    {
      "enum": 34,
      "value": "Porta traseira direita"
    },
    {
      "enum": 35,
      "value": "Porta traseira esquerda"
    },
    {
      "enum": 36,
      "value": "Porta do passageiro"
    },
    {
      "enum": 37,
      "value": "Porta do Motorista"
    }
  ]
    