import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { toCamel } from "snake-camel";
import { OperatorAnalyzeModel } from "src/app/models/operator-analize-model";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { ToolsService } from "src/app/services/migrate/tools.service";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

@Component({
  selector: "app-treat-analysis-edit",
  templateUrl: "./treat-analysis-edit.component.html",
  styleUrls: ["./treat-analysis-edit.component.scss"],
})
export class TreatAnalysisEditComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public reportOperationalForm: FormGroup;

  public treatmentOperatorsSelected;
  public treatmentOperators = [];

  public treatOperatorUuid = "";
  public treatOperatorOld: any;

  errorMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private tools: ToolsService,
    private spinner: NgxSpinnerService,
    public router: Router,
    analyzeTripService: AnalyzeTripService
  ) {
    this.analyzeTripService = analyzeTripService;
  }

  ngOnInit(): void {
    this.reportOperationalForm = this.formBuilder.group(
      {
        treatment: ["", Validators.required],
        operatorComment: ["", Validators.required],
        state: ["", Validators.required],
        city: ["", Validators.required],
        image: [null, [this.validateImage]],
        extension: [null],
      },
      { updateOn: "blur" }
    );

    this.treatOperatorUuid = this.activatedRoute.snapshot.params.uuid;

    this.loadDataElements(this.treatOperatorUuid);
  }

  validateImage(control: FormControl) {
    const file: File = control.value;

    if (file instanceof File) {
      const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
      const extension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.indexOf(extension) === -1) {
        return { invalidImageFormat: true };
      }

      if (file.size > 5242880) {
        // 5MB
        return { invalidImageSize: true };
      }
    }

    return null;
  }

  public async loadDataElements(analyzeReportUuid: any) {
    try {
      this.tools.builder().spinner.show();

      this.analyzeTripService.getAllOperatorDealings().subscribe((t) => {
        this.treatmentOperators.push(t);
      });

      this.analyzeTripService
        .getByUuidTreat(analyzeReportUuid)
        .subscribe((resp) => {
          this.treatOperatorOld = toCamel(resp);
          this.fillTransporterData();
        });

      console.log(this.treatmentOperators);

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  onImageChange(event: any) {
    const file = (event.target as HTMLInputElement).files?.[0];
    const imageControl = this.reportOperationalForm.get("image");
    const extensionControl = this.reportOperationalForm.get("extension");

    if (file) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
        const base64String = (e.target as any).result.toString();
        imageControl.setValue(btoa(base64String));
        extensionControl.setValue(file.name.split(".").pop().toLowerCase());
      };
    } else {
      imageControl.setValue(null);
      extensionControl.setValue(null);
    }
  }

  private fillTransporterData(): void {
    this.reportOperationalForm
      .get("operatorComment")
      .setValue(this.treatOperatorOld.descriptionAnalyze);
    this.reportOperationalForm
      .get("state")
      .setValue(this.treatOperatorOld.state);
    this.reportOperationalForm.get("city").setValue(this.treatOperatorOld.city);
    this.reportOperationalForm
      .get("treatment")
      .setValue(this.treatOperatorOld.operatorDealingsUuid);
  }

  public submitForm(): void {
    this.spinner.show();
    const values = this.reportOperationalForm.value;

    var userId = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;

    var operatorAnalyzeModel: OperatorAnalyzeModel = {
      operator_dealings_uuid: values.treatment, // Certifique-se de inicializar essa propriedade ou ajustar conforme necessário
      description_analyze: values.operatorComment,
      file: values.image,
      extesion: values.extension,
      state: values.state,
      city: values.city,
      user_id: userId,
    };

    this.analyzeTripService
      .updateOperatorAnalyzeTreat(operatorAnalyzeModel, this.treatOperatorUuid)
      .subscribe((t) => {
        console.log(t.report_analyze_uuid);
        //Inicia o temporizador de 3 segundos
        setTimeout(() => {
          // Esconde o spinner
          this.spinner.hide();

          // Navega para a rota desejada
          this.router.navigate([
            "/analise-operacional/view-tratamento-operador/" +
              t.report_analyze_uuid,
          ]);
        }, 3000); // 3000 milissegundos = 3 segundos
      });
  }
}
