<div class="main_s">
  <app-sidebar
          [terminalsOnMap]="this.terminalsOnMap"
          (onChangeTerminals)="onChangeTerminals($event)"
          (onChangeCenterMap)="onChangeCenterMap($event)"
          [style.display]="!this.toolboxControl.showSidebar ? 'none' : 'block'">
  <!--[ngStyle]="{'display': !this.markerMap.showSidebar ? 'none' : 'block'}"-->
  </app-sidebar>

<div class="main" [ngClass]="{ hideSidebar: !this.toolboxControl.showSidebar }">
  <div class="container-fluid no-gutters-p">
    <app-toolbox></app-toolbox>

    <div [ngStyle]="{'margin-top': this.toolboxControl.mapPosition ? '67px' : '125px' }">
      <agm-map
        [ngStyle]="{'height': this.toolboxControl.mapPosition ? '90vh' : '81vh' }"
        #AgmMap
        appDirectionsMap
        [showDirection]="displayDirections"
        [origin]="routeOrigin"
        [destination]="routeDestination"
        [mapTypeControl]="true"
        [latitude]="lat"
        [longitude]="lng"
        [zoomControl]="true"
        [fullscreenControl]="true"
        [zoom]="zoom"
        (centerChange)="zoomChange($event)"
        (mapReady)="mapReady($event)"
        (mapClick)="handleMapClicked($event)"
        (idle)="mapIdle()"
      >
        <agm-direction
          *ngIf="origin && destination && waypoints.length > 0"
          [renderOptions]="renderOptions"
          [origin]="origin"
          [destination]="destination"
          [waypoints]="waypoints"
        >
        </agm-direction>

        <!-- Markers do usuario (usuario-marker) -->
        <agm-marker
          *ngFor="let marker of userMarkers"
          [title]="marker.nmNome"
          [label]=""
          (markerDblClick)="handleMarkerDblClick(marker)"
          [latitude]="marker.cdLatidude"
          [longitude]="marker.cdLongitude"
		       iconUrl="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
        >
          <agm-overlay
            [latitude]="marker.cdLatidude"
            [longitude]="marker.cdLongitude"
            *ngIf="toolboxControl.showMarkerLabel"
          >
            <div
              class="block"
              style="
                background-color: black;
                padding: 5px;
                border-radius: 15px;
              "
            >
              <strong style="color: white;">
                &nbsp;{{ marker.nmNome }} &nbsp;
              </strong>
            </div>
          </agm-overlay>
        </agm-marker>

        <div>
          <!-- Markers dos terminais -->
          <app-marker
                  [$this]="this"
                  *ngFor="let terminal of this.terminalsData"
                  [terminal]="terminal"
                  [clickable]="isMarkerClickable"
                  [showMarkerLabel]="this.toolboxControl.showMarkerLabel"
          >
            <!--<span title="{{terminal.getValue().placa}}"></span>-->
          </app-marker>

          <agm-polyline
            [editable]="polyline.editable"
            [strokeColor]="polyline.color"
          >
            <agm-polyline-point
              *ngFor="let data of lines"
              [latitude]="data.lat"
              [longitude]="data.lng"
            >
            </agm-polyline-point>
          </agm-polyline>
        </div>
      </agm-map>
    </div>
    <!-- Tracking -->
    <div
      *ngIf="markerTracking"
      (click)="cancelTrakingMarker()"
      style="
        border-radius: 16px;
        border: 3px solid white;
        background: #5bb793;
        position: absolute;
        padding: 10px;
        bottom: 80px;
        z-index: 1001;
        left: 80px;
      "
    >
      Rastreando: <strong>{{ markerTracking.placa }} </strong>

      <button
        style="height: 20px; line-height: 20px;"
        class="close"
        mat-icon-button
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div
      *ngIf="polygonElectronicFence"
      style="
        border-radius: 16px;
        border: 3px solid white;
        background: #fe9639;
        position: absolute;
        padding: 10px;
        bottom: 80px;
        z-index: 1001;
        left: 80px;
      "
    >
      Voltar para o mapa
      <p-button    style="height: 20px; line-height: 20px;"
      class="close"
      mat-icon-button
      (click)="removePolygonElectronicFence()" label="Click" ></p-button>

      
    </div>
  </div>
</div>

<button
  mat-mini-fab
  color="primary"
  [ngStyle]="{ right: this.toolboxControl.showSidebar ? '293px' : '0px' }"
  style="position: absolute; outline: transparent !important; top: 192px; z-index: 999;"
  (click)=" this.toolboxControl.toggleSideNav()"
>
  <mat-icon>{{ this.toolboxControl.showSidebar ? 'navigate_next' : 'navigate_before' }}</mat-icon>
</button>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
             size="medium" color="#fff" type="ball-spin-fade"
             [fullScreen]="true">
  <p style="color: white"> carregando... </p>
</ngx-spinner>
</div>

<p-sidebar (onShow)="this.onShowTelemetria($event)" (onHide)="this.onHideTelemetria($event)" [(visible)]="this.toolboxControl.popupTelemetria" [fullScreen]="true" [baseZIndex]="10000">

  <div class="body-telemetria">
    <app-telemetria *ngIf="this.loadAppTelemetria"></app-telemetria> 
  </div>

</p-sidebar>
