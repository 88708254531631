import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { STORAGE_KEY_CD_USUARIO } from "../storageCore/constStorageKeys";
import storageX from "../storageCore/storageX";
import { PermissionsService } from "./permissions.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  public whiteList = [
    "/",
    "/home",
    "/login",
    "/consultations",
    "/registrations",
    "/journey-control",
    "/driver-report",
    "/vehicle-report",
    "/validate-token",
    "/validate-code",
    "/create-new-password",
  ];

  public permissoes: Array<any> = [];

  /*  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> | boolean | UrlTree {
      const paths = this.permissionService.getPaths();
     
  

    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      return true;
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  } */

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (storageX.whereKeyIs(STORAGE_KEY_CD_USUARIO).get()) {
      return true;
    }

    this.router.navigate(["/login"]);

    return false;
  }
}
