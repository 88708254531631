import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { VeiculoSensor } from "src/app/models/VeiculoSensor.model";
import { VwSensor } from "src/app/models/VwSensor.model";
import { EventEmitterSensorService } from "src/app/services/event-emitter-sensor.service";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import Swal from "sweetalert2";

@Component({
  selector: "app-axle-three-simple",
  templateUrl: "./axle-three-simple.component.html",
  styleUrls: ["./axle-three-simple.component.scss"],
})
export class AxleThreeSimpleComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() sensoresDisponiveis: any;
  @Input() veiculoId: number;

  @Output() atualizarSensores = new EventEmitter<void>();

  public sensorThreeLeftSelect: any = [];
  public sensorThreeRightSelect: any = [];

  public sensorThreeRightCombo: any = [];
  public sensorThreeLeftCombo: any = [];

  public axisThreeSimpleLeftForm: FormGroup;
  public axisThreeSimpleRightForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private serviceApiBase: ApiBaseService,
    private componentResetService: EventEmitterSensorService
  ) {}

  ngOnInit(): void {
    this.componentResetService.reiniciarComponentes$.subscribe(async (a) => {
      this.sensoresDisponiveis = null;
      this.veiculoSensores = null;
      this.sensorThreeRightCombo = [];
      this.sensorThreeLeftCombo = [];

      const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();

      const eixo = await this.serviceApiBase.get<any>({
        url: new VeiculoSensor()["nomeConsultaApiBase"] + this.veiculoId,
      });

      this.veiculoSensores = eixo["content"];

      const sensor = await this.serviceApiBase.get<any>({
        url:
          new VwSensor()["nomeConsultaApiBaseSensor"] +
          this.veiculoId +
          "/perfil/" +
          perfil,
      });

      this.sensoresDisponiveis = sensor;

      if (this.sensoresDisponiveis.length > 0) {
        this.sensoresDisponiveis.forEach((element) => {
          this.sensorThreeRightCombo.push(element);
          this.sensorThreeLeftCombo.push(element);
        });
      }

      this.loadSensor();
    });
    this.axisThreeSimpleLeftForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisThreeSimpleRightForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    if (this.sensoresDisponiveis.length > 0) {
      this.sensoresDisponiveis.forEach((element) => {
        this.sensorThreeRightCombo.push(element);
        this.sensorThreeLeftCombo.push(element);
      });
    }

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisThreeSimpleLeftForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 3 ESQ"
      );
      this.axisThreeSimpleRightForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 3 ESQ"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo3Direito;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 40) {
              eixo3Direito = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 40) {
              eixo3Direito = filter;
            }
          }
        });

        var eixo3Esquerdo;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 39) {
              eixo3Esquerdo = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 39) {
              eixo3Esquerdo = filter;
            }
          }
        });

        // EIXO 3 DIREITO
        if (eixo3Direito != null) {
          this.axisThreeSimpleRightForm.controls["id"].setValue(
            eixo3Direito.id
          );

          if (eixo3Direito.sensor != null) {
            this.sensorThreeRightCombo.push(eixo3Direito.sensor);
            this.sensorThreeRightSelect = eixo3Direito.sensor.id;
          }

          if (eixo3Direito.pneu != null) {
            this.axisThreeSimpleRightForm.controls["pneu"].setValue(
              eixo3Direito.pneu.numeroFogo
            );
          }

          this.axisThreeSimpleRightForm.controls["nrPressaoMinima"].setValue(
            eixo3Direito.nrPressaoMinima
          );

          this.axisThreeSimpleRightForm.controls["nrPressaoMaxima"].setValue(
            eixo3Direito.nrPressaoMaxima
          );

          this.axisThreeSimpleRightForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo3Direito.nrTemperaturaMaxima);
        }

        // EIXO 3 ESQUERDO
        if (eixo3Esquerdo != null) {
          this.axisThreeSimpleLeftForm.controls["id"].setValue(
            eixo3Esquerdo.id
          );

          if (eixo3Esquerdo.sensor != null) {
            this.sensorThreeLeftCombo.push(eixo3Esquerdo.sensor);
            this.sensorThreeLeftSelect = eixo3Esquerdo.sensor.id;
          }

          if (eixo3Esquerdo.pneu != null) {
            this.axisThreeSimpleLeftForm.controls["pneu"].setValue(
              eixo3Esquerdo.pneu.numeroFogo
            );
          }

          this.axisThreeSimpleLeftForm.controls["nrPressaoMinima"].setValue(
            eixo3Esquerdo.nrPressaoMinima
          );

          this.axisThreeSimpleLeftForm.controls["nrPressaoMaxima"].setValue(
            eixo3Esquerdo.nrPressaoMaxima
          );

          this.axisThreeSimpleLeftForm.controls["nrTemperaturaMaxima"].setValue(
            eixo3Esquerdo.nrTemperaturaMaxima
          );
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public async submitFormDelete(form: FormGroup): Promise<void> {
    this.spinner.show();
    try {
      const values = form.value;
      var post;

      post = {
        veiculo: {
          id: this.veiculoId,
        },
        nrPressaoMinima: values.nrPressaoMinima,
        nrPressaoMaxima: values.nrPressaoMaxima,
        nrTemperaturaMaxima: values.nrTemperaturaMaxima,
        sensor: {
          id: values.cdSensor,
        },
        pneu: null,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      console.log(post);

      const result = await this.serviceApiBase.put<VeiculoSensor>(
        values.id,
        new VeiculoSensor()["nomeConsultaApiBaseRemove"],
        post
      );

      form.get("cdSensor").setValue("");

      this.spinner.hide();

      this.handleDelete();

      this.atualizarSensores.emit();
    } catch (error) {
      this.spinner.hide();
      //this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public async submitFormDeleteAll(): Promise<void> {
    this.spinner.show();
    try {
      var estepeArray: any = [];
      var posicoesPneusList: any = [];

      estepeArray.push(this.axisThreeSimpleRightForm.value);
      estepeArray.push(this.axisThreeSimpleLeftForm.value);

      if (estepeArray.length > 0) {
        estepeArray.forEach((element) => {
          var posicaoPneuResquest = {
            cdPosicaoPneu: element.cdPosicaoPneu,
          };
          posicoesPneusList.push(posicaoPneuResquest);
        });
      }

      var post = {
        veiculoSensores: posicoesPneusList,
      };

      const result = await this.serviceApiBase.put<VeiculoSensor>(
        this.veiculoId,
        new VeiculoSensor()["nomeConsultaApiBaseRemoveAll"],
        post
      );

      this.axisThreeSimpleRightForm.get("cdSensor").setValue("");
      this.axisThreeSimpleLeftForm.get("cdSensor").setValue("");

      this.spinner.hide();

      this.handleDeleteAll();

      this.atualizarSensores.emit();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public async submitFormAddAll(): Promise<void> {
    this.spinner.show();
    try {
      var estepeArray: any = [];
      var posicoesPneusList: any = [];

      estepeArray.push(this.axisThreeSimpleRightForm.value);
      estepeArray.push(this.axisThreeSimpleLeftForm.value);

      if (estepeArray.length > 0) {
        var boolean = this.verificarDuplicados(estepeArray);

        if (boolean == true) {
          this.handleItensDuplicados();
          this.spinner.hide();
          return;
        }

        estepeArray.forEach((element) => {
          if (element.cdSensor != "") {
            var posicaoPneuResquest = {
              cdPosicaoPneu: element.cdPosicaoPneu,
              cdSensor: element.cdSensor,
              pneu: element.pneu,
              nrPressaoMinima: element.nrPressaoMinima,
              nrPressaoMaxima: element.nrPressaoMaxima,
              nrTemperaturaMaxima: element.nrTemperaturaMaxima,
              idPerfil: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
            };
            posicoesPneusList.push(posicaoPneuResquest);
          }
        });
      }

      var post = {
        veiculoSensores: posicoesPneusList,
      };

      const result = await this.serviceApiBase.put<VeiculoSensor>(
        this.veiculoId,
        new VeiculoSensor()["nomeConsultaApiBaseAddAll"],
        post
      );

      this.spinner.hide();

      this.handleAddAll();

      this.atualizarSensores.emit();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  verificarDuplicados(axisOneArray: any[]): boolean {
    const seen = new Set();
    for (const item of axisOneArray) {
      if (seen.has(item.cdSensor)) {
        return true; // Retorna true se encontrar um valor duplicado
      } else {
        seen.add(item.cdSensor);
      }
    }
    return false; // Retorna false se não houver valores duplicados
  }

  public async handleItensDuplicados(): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Sensores selecionados estão com duplicidade, por favor corrigir!",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public async handleAddAll(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Sensores do EIXO 3 vinculados com sucesso",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public async handleDeleteAll(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Sensores do EIXO 3 desvinculados com sucesso",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public async handleDelete(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Sensor desvinculados com sucesso",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }
}
