import { Router, NavigationEnd, Event } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { PermissionsService } from "src/app/helpers/permissions.service";
@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  location = null;
  permissions: [];

  constructor(
    private router: Router,
    public permissionService: PermissionsService
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.location = event.url;
      }
    });
  }

  ngOnInit(): void {
    this.permissions = this.permissionService.getPermissions();
  }
}
