import { ToolsService } from "./../services/migrate/tools.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ListaTerminalService } from "./lista-terminal.service";
import { MensagemTerminal } from "./model/ListaTerminal";

const MSG_ERRO_MENU = "Escolha uma jornada para continuar.";

@Component({
  selector: "app-journey-control",
  templateUrl: "./lista-terminal.component.html",
  styleUrls: ["./lista-terminal.component.scss"],
})
export class ListaTerminalComponent implements OnInit {
  public colsHeader = new Array<any>();
  public cols = new Array<any>();

  public mensagemFiltro;
  public idFiltro;
  public activities = new Array<any>();
  public journey = new Array<any>();
  public displayStyle = "";
  public exportColumns: any[];
  public driverName;
  public items;
  public openDialog;
  public scrollHeight;
  public selectedRows;
  public classCSSHoverBody = "hrvh";
  public classCSSHoverSub = "";
  public terminaisMensagemProcessada: MensagemTerminal[] = [];
  public pageSize: Number = 0;
  private currentPage: Number = 0;

  @ViewChild("activitiesTable") activitiesTable;

  constructor(
    private service: ListaTerminalService,
    private tools: ToolsService
  ) {}
  sales: any[];

  ngOnInit() {
    this.loadTerminais(0);
  }

  loadTerminais(page: Number) {
    this.tools.builder().spinner.show();
    this.service.getMensagensTerminais(page).subscribe((response) => {
      if (response != null) {
        this.terminaisMensagemProcessada = response.content;
        this.pageSize = response.totalPages;
        this.tools.builder().spinner.hide();
      }
    });
  }

  public handleFilter = (): void => {
    this.currentPage = 0;
    if (!this.isEmpty(this.idFiltro) && !this.isEmpty(this.mensagemFiltro)) {
      this.loadTerminalByIdAndMensagem();
    } else {
      !this.isEmpty(this.idFiltro)
        ? this.loadTerminalByIdTerminal()
        : this.loadTerminalByMensagem();
    }
  };

  isEmpty(variavel: string): Boolean {
    return variavel == null || variavel.toString().trim() === "";
  }

  private loadTerminalByIdAndMensagem() {
    this.service
      .getMensagensTerminaisByMensagemAndIdTerminal(
        this.currentPage,
        this.idFiltro,
        this.mensagemFiltro
      )
      .subscribe((response) => {
        if (response != null) {
          this.terminaisMensagemProcessada = response.content;
          this.pageSize = response.totalPages;
          this.tools.builder().spinner.hide();
        }
      });
  }

  private loadTerminalByMensagem() {
    if (this.isEmpty(this.mensagemFiltro)) {
      this.loadTerminais(this.currentPage);
    } else {
      this.service
        .getMensagensTerminaisByMensagem(this.currentPage, this.mensagemFiltro)
        .subscribe((response) => {
          if (response != null) {
            this.terminaisMensagemProcessada = response.content;
            this.pageSize = response.totalPages;
            this.tools.builder().spinner.hide();
          }
        });
    }
  }

  private loadTerminalByIdTerminal() {
    this.service
      .getMensagensTerminaisByIdTerminal(this.currentPage, this.idFiltro)
      .subscribe((response) => {
        if (response != null) {
          this.terminaisMensagemProcessada = response.content;
          this.pageSize = response.totalPages;
          this.tools.builder().spinner.hide();
        }
      });
  }

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.currentPage = pageIndex;
    if (!this.isEmpty(this.idFiltro) && !this.isEmpty(this.mensagemFiltro)) {
      this.loadTerminalByIdAndMensagem();
    } else {
      !this.isEmpty(this.idFiltro)
        ? this.loadTerminalByIdTerminal()
        : this.loadTerminalByMensagem();
    }
  };

  public async loadAtividade(pageIndex = 0, sort?): Promise<void> {
    const params = {
      size: this.pageSize,
      page: pageIndex,
    };

    try {
      this.tools.builder().spinner.show();

      this.tools.builder().spinner.hide();
    } catch (error) {
      throw new EvalError(error);
    }
  }

  convertToDate(data): string {
    if (this.isEmpty(data)) {
      return "-";
    }

    return this.dateFormat(new Date(data));
  }

  dateFormat(data: Date) {
    return (
      this.adicionaDigito(data.getDate()) +
      "/" +
      this.adicionaDigito(data.getMonth() + 1) +
      "/" +
      data.getFullYear() +
      " " +
      this.getHora(data)
    );
  }

  private getHora(data: Date) {
    return (
      this.adicionaDigito(data.getHours()) +
      ":" +
      this.adicionaDigito(data.getMinutes())
    );
  }

  adicionaDigito(data: Number): string {
    if (data < 10) {
      return "0" + data.toString();
    }

    return data.toString();
  }
}
