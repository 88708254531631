<div class="toolbox_route" *ngIf="this.permissionService.hasPermission('ROTA_BOTAO_FUNCAO', this.permissions)">
  <mat-button-toggle-group
    name="route"
    [selected]="selected"
    (click)="onChangeValue()"
  >
    <mat-button-toggle value="route" [ngClass]="{ btnSelected: selected }">
      <mat-icon>directions</mat-icon>
      <span class="btn-icon-text">Rota</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
