import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { AnalyzeReportSupervisorModelPaginated } from "src/app/models/analyze-report-supervisor-model-paginated";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from "@angular/common";

import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { MatDialog } from "@angular/material/dialog";
import { SupervisorCompanyModel } from "src/app/models/supervisor-company.model";
import { SupervisorCompanyRequestModel } from "src/app/models/supervisor-company-request-model";

@Component({
  selector: 'app-company-supervisor',
  templateUrl: './company-supervisor.component.html',
  styleUrls: ['./company-supervisor.component.scss']
})
export class CompanySupervisorComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public datePipe: DatePipe;

  public analyzeReportSupervisorModelsPaginated: AnalyzeReportSupervisorModelPaginated;
  public managerViewModelModel: SupervisorCompanyModel;
  public errorMessage;
  public analyzeReportSupervisorsPage: AnalyzeReportSupervisorModelPaginated;
  public managersView: MatTableDataSource<any> =
    new MatTableDataSource<any>();
  public tableColumns: string[];
  public typeFieldSelect = [
    { name: "Nome", value: "NAME" },
  ];

  public actualPage: PageEvent;
  public permissions: [];
  public filter = "";
  public field = "";
  public typeFieldFilterSelected;
  public supervisorUuid = "";

  constructor(
    private activatedRoute: ActivatedRoute,
    datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    analyzeTripService: AnalyzeTripService,
    public dialog: MatDialog
  ) {
    this.datePipe = datePipe;
    this.analyzeTripService = analyzeTripService;
  }

  ngOnInit(): void {
    this.supervisorUuid = this.activatedRoute.snapshot.params.uuid;
    this.typeFieldFilterSelected = this.typeFieldSelect[0].value;
    this.field = this.typeFieldFilterSelected;
    this.initialPage();
    this.loadReports(this.actualPage, null, null);
    this.loadTableDefinitions();
  }


  private loadReports(page: PageEvent, name: string, field: string): void {
    this.spinner.show();

    this.analyzeTripService
      .getAllCompanySupervisor(page, name, field, this.supervisorUuid)
      .subscribe(
        (t) => {
          this.analyzeReportSupervisorsPage = t;
          this.managersView =
            new MatTableDataSource<SupervisorCompanyModel>(
              this.analyzeReportSupervisorsPage.content
            );
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter os gestores");
          this.spinner.hide();
        }
      );
  }

  public onToggleAndCheckboxChange = (element, property): void => {
    this.onCheckboxChange(element);
    this.onToggleChange(element, property);
  };

  public onCheckboxChange = (element): void => {
    this.updateCompany(element);
  };

  private onToggleChange = (element, property): void => {
    console.log(`Toggled ${property} for`, element);
    // Adicione aqui a lógica que você quer executar quando o toggle mudar
  };

  private updateCompany(element): void {
    this.spinner.show();

    // Inicializando a variável supervisorCompanyRequestModel
    let supervisorCompanyRequestModel: SupervisorCompanyRequestModel = {
      automaticShipping: element.automatic_shipping,
      companyBond: element.company_bond,
      companyId: element.company_id,
      divisionId: element.division_id
    };

    this.analyzeTripService
      .updateSupervisorCompany(supervisorCompanyRequestModel, this.supervisorUuid)
      .subscribe(
        (t) => {
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao atualizar os supervisores");
          this.spinner.hide();
        }
      );
  }


  applyFilter(): void {
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  fieldFilter(): void {
    this.field = this.typeFieldFilterSelected;
    this.filter = "";
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "nameCompany",
      "companyBond",
      "automaticShipping"
    ];
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }

}
