<div class="main-page cadastros" style="background: #ffffff;
border-radius: 4px;
width: 100%;
height: 55%;
padding-left: 1%;
margin-top: -50px;">
    <div class="container-fluid">
        <div class="mat-elevation table-content">
            <form [formGroup]="pneuPosicaoForm">
                <h2 class="mb-3" style="color: #11207b;font-size: 14px; line-height: 16px;font-weight: 400; ">Novo Posição Pneu</h2>
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <mat-form-field appearance="outline">
                          <mat-label>Veiculo</mat-label>
                          <mat-select formControlName="veiculo" [(value)]="vehicleSelected" [(ngModel)]="vehicleSelected" [readonly]=true [disabled]=true required>
                            <mat-option *ngFor="let type of vehicles" [value]="type.id" >
                              {{ type.cdPlaca }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="form-group col-md-6" *ngIf="pneuPosicaoForm.get('veiculo').value != null">
                        <mat-form-field appearance="outline">
                          <mat-label>Posição Pneu</mat-label>
                          <mat-select formControlName="posicaoPneu" [(value)]="vehiclesTireSelected" [(ngModel)]="vehiclesTireSelected" [readonly]=true [disabled]=true required>
                            <mat-option *ngFor="let type of vehiclesTire" [value]="type.id" >
                              {{ type.nome }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>  
                    <div *ngIf="errorMessage" class="alert alert-danger">
                      {{ errorMessage }}
                    </div>
            </form>
        </div>
    </div>
</div>