import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-axle-three-simple-view",
  templateUrl: "./axle-three-simple-view.component.html",
  styleUrls: ["./axle-three-simple-view.component.scss"],
})
export class AxleThreeSimpleViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorThreeLeftSelect: any = [];
  public sensorThreeRightSelect: any = [];

  public sensorThreeRightCombo: any = [];
  public sensorThreeLeftCombo: any = [];

  public axisThreeSimpleLeftForm: FormGroup;
  public axisThreeSimpleRightForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisThreeSimpleLeftForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisThreeSimpleRightForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisThreeSimpleLeftForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 3 ESQ"
      );
      this.axisThreeSimpleRightForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 3 ESQ"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo3Direito;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 40) {
              eixo3Direito = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 40) {
              eixo3Direito = filter;
            }
          }
        });

        var eixo3Esquerdo;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 39) {
              eixo3Esquerdo = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 39) {
              eixo3Esquerdo = filter;
            }
          }
        });

        // EIXO 3 DIREITO
        if (eixo3Direito != null) {
          this.axisThreeSimpleRightForm.controls["id"].setValue(
            eixo3Direito.id
          );

          if (eixo3Direito.sensor != null) {
            this.sensorThreeRightCombo.push(eixo3Direito.sensor);
            this.sensorThreeRightSelect = eixo3Direito.sensor.id;
          }

          if (eixo3Direito.pneu != null) {
            this.axisThreeSimpleRightForm.controls["pneu"].setValue(
              eixo3Direito.pneu.numeroFogo
            );
          }

          this.axisThreeSimpleRightForm.controls["nrPressaoMinima"].setValue(
            eixo3Direito.nrPressaoMinima
          );

          this.axisThreeSimpleRightForm.controls["nrPressaoMaxima"].setValue(
            eixo3Direito.nrPressaoMaxima
          );

          this.axisThreeSimpleRightForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo3Direito.nrTemperaturaMaxima);
        }

        // EIXO 3 ESQUERDO
        if (eixo3Esquerdo != null) {
          this.axisThreeSimpleLeftForm.controls["id"].setValue(
            eixo3Esquerdo.id
          );

          if (eixo3Esquerdo.sensor != null) {
            this.sensorThreeLeftCombo.push(eixo3Esquerdo.sensor);
            this.sensorThreeLeftSelect = eixo3Esquerdo.sensor.id;
          }

          if (eixo3Esquerdo.pneu != null) {
            this.axisThreeSimpleLeftForm.controls["pneu"].setValue(
              eixo3Esquerdo.pneu.numeroFogo
            );
          }

          this.axisThreeSimpleLeftForm.controls["nrPressaoMinima"].setValue(
            eixo3Esquerdo.nrPressaoMinima
          );

          this.axisThreeSimpleLeftForm.controls["nrPressaoMaxima"].setValue(
            eixo3Esquerdo.nrPressaoMaxima
          );

          this.axisThreeSimpleLeftForm.controls["nrTemperaturaMaxima"].setValue(
            eixo3Esquerdo.nrTemperaturaMaxima
          );
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
