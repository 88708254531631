<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="funcionalidadeTipoForm">
                    <h2 class="mb-3">Novo Tipo de Funcionalidade</h2>
                    <div class="form-row">

                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome Tipo de Funcionalidade</mat-label>
                                <input formControlName="nmFuncionalidadeTipo" matInput
                                    placeholder="Nome Tipo de Funcionalidade" maxlength="200"  [readonly]=true [disabled]=true/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Descrição Tipo de Funcionalidade</mat-label>
                                <input formControlName="dsFuncionalidadeTipo" matInput
                                    placeholder="Descrição Tipo de Funcionalidade" maxlength="200"  [readonly]=true [disabled]=true/>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>