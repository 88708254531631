import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { FuncionalidadeTipo } from "../../models/FuncionalidadeTipo.model";
import { FuncionalidadeTipoInterface } from "../../contracts/FuncionalidadeTipo.interface";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Page } from "src/app/utils/paginate";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-funcionalidade-tipo",
  templateUrl: "./funcionalidade-tipo.component.html",
  styleUrls: ["./funcionalidade-tipo.component.scss"],
})
export class FuncionalidadeTipoComponent implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('filter') filter: ElementRef;
  // @ViewChild('typeFilter') typeFilter: ElementRef;
  public filter;
  public typeFilter;

  public page = new Page();
  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[] = [
    //'id', OCULTADO CAMPO ID DA TABELA - 20102020 - MARCOS TAVARES
    "nmFuncionalidadeTipo",
    "update",
    "clone",
    "view",
    "delete",
  ];

  public filtros = [
    //{ campo: 'id', valor: 'ID' },
    { campo: "nmFuncionalidadeTipo", valor: "Funcionalidade Tipo" },
  ];
  private apiBaseService: ApiBaseService;
  public funcionalidadeTipo: MatTableDataSource<FuncionalidadeTipoInterface>;

  constructor(
    apiBaseService: ApiBaseService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.apiBaseService = apiBaseService;
    this.funcionalidadeTipo =
      new MatTableDataSource<FuncionalidadeTipoInterface>([]);
  }

  ngOnInit(): void {
    this.loadFuncionalidadeTipo();
  }
  //public handleFilter = (value: string): void => {
  //this.funcionalidadeTipo.filter = value.trim().toLocaleLowerCase();
  //};

  //public handleSort = (sort): void => {
  //  this.loadFuncionalidadeTipo(0, sort);
  //};

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadFuncionalidadeTipo(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadFuncionalidadeTipo();
  };
  public handleDelete = (id: string): void => {
    this.deleteFuncionalidadeTipo(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/funcionalidade-tipo/${id}`]);
  };

  ngAfterViewInit(): void {
    this.funcionalidadeTipo.sort = this.sort;
    this.funcionalidadeTipo.paginator = this.paginator;
  }

  public async deleteFuncionalidadeTipo(id): Promise<void> {
    const idUsuario = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        await this.apiBaseService.delete<FuncionalidadeTipo>(
          idUsuario,
          id,
          new FuncionalidadeTipo()["nomeConsultaApiBase"]
        );

        this.spinner.hide();
        this.loadFuncionalidadeTipo();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public async loadFuncionalidadeTipo(pageIndex = 0): Promise<void> {
    const params = {
      size: this.pageSize,
      page: pageIndex,
    };

    try {
      this.spinner.show();

      const result = await this.apiBaseService.get<any>({
        url: new FuncionalidadeTipo()["nomeConsultaApiBase"],
        params,
      });

      this.funcionalidadeTipo.data = result["content"];
      this.page = result["page"];

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
