import { ApiBaseService } from "./../../../services/migrate/api.base.service";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  ValidationErrors,
} from "@angular/forms";

import axios from "axios";

import { getFormValidationErrors } from "../../../helpers/validation.errors";
import { Company } from "./../../../models/Company.model";
import { ContentInterface } from "../../../contracts/Content.interface";
import { Validation } from "../validation";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";
import { FormateDate } from "src/app/utils/date/formateDate";

@Component({
  selector: "app-edit",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CompanyViewComponent implements OnInit {
  public companyId;
  public company;
  public empresa;
  public empresaId;

  private apiBaseService: ApiBaseService;
  public companyForm: FormGroup;
  public errorMessage;
  public errorDebug;
  public isValid = false;
  public title = "Visualizar Empresa";
  public empresaTipoSelected;
  public empresaMatrizSelected;
  public empresaSelected;
  public types: any = [];
  public companies: any = [];
  public companiesSelect: any = [];
  private validation: Validation;
  public loader: false;

  constructor(
    private formBuilder: FormBuilder,
    private service: ApiBaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.apiBaseService = service;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.companyForm = this.formBuilder.group(
      {
        selecaoEmpresa: [""],
        empresaMatriz: [""],
        nome: [""],
        contato: [""],
        telefone: [""],
        email: [""],
        creditoSms: [""],
        empresaTipo: [""],
        cnpj: [""],
        inscricaoEstadual: [""],
        inscricaoMunicipal: [""],
        razaoSocial: [""],
        cep: [""],
        endereco: [""],
        numero: [""],
        complemento: [""],
        bairro: [""],
        cidade: [""],
        estado: [""],
        fax: [""],
        observacao: [""],
        exibePainel: [false],
        listEmpresaAutorizacaoAntt: this.formBuilder.array([
          this.formBuilder.group({
            autorizacaoAnttId: [""],
            dataCadastro: [""],
            dataVencimento: [""],
            flagGerarAlertaVencimento: [false],
            tipoAutorizacao: [""],
            numero: [""],
          }),
        ]),
        listEmpresaSeguroRctrcList: this.formBuilder.array([
          this.formBuilder.group({
            seguroRctrcId: [""],
            dataContratacao: [""],
            dataVencimento: [""],
            flagGerarAlertaVencimento: [false],
            seguradora: [""],
            numeroApolice: [""],
          }),
        ]),
      },
      { updateOn: "blur" }
    );

    var valorId = this.activatedRoute.snapshot.params["id"];
    var posicaoId = valorId.indexOf("&");
    var posicaoCnpj = valorId.substring(valorId.indexOf("&") + 1);

    this.companyId = valorId.substring(0, posicaoId);
    this.loadCompany(this.companyId, posicaoCnpj);
  }

  get empresaAutorizacaoAnttItems(): FormArray {
    return this.companyForm.get("listEmpresaAutorizacaoAntt") as FormArray;
  }

  get empresaSeguroRctrcItems(): FormArray {
    return this.companyForm.get("listEmpresaSeguroRctrcList") as FormArray;
  }

  addAutorizacaoAnttItem(element?: any): void {
    if (element) {
      const {
        id,
        dataCadastro,
        dataVencimento,
        tipoAutorizacao,
        flagGerarAlertaVencimento,
        numero,
      } = element;

      const form = this.formBuilder.group({
        autorizacaoAnttId: [id],
        dataCadastro: [dataCadastro],
        dataVencimento: [dataVencimento],
        flagGerarAlertaVencimento: [flagGerarAlertaVencimento],
        tipoAutorizacao: [tipoAutorizacao],
        numero: [numero],
      });

      this.empresaAutorizacaoAnttItems.push(form);
      return;
    } else {
      this.empresaAutorizacaoAnttItems.push(
        this.formBuilder.group({
          autorizacaoAnttId: [""],
          dataCadastro: [""],
          dataVencimento: [""],
          flagGerarAlertaVencimento: [false],
          tipoAutorizacao: [""],
          numero: [""],
        })
      );
    }
  }

  deleteAutorizacaoAnttItem(index): void {
    this.empresaAutorizacaoAnttItems.removeAt(index);
  }

  addSeguroRctrcItem(element?: any): void {
    if (element) {
      const {
        id,
        numeroApolice,
        seguradora,
        dataContratacao,
        dataVencimento,
        flagGerarAlertaVencimento,
      } = element;

      const form = this.formBuilder.group({
        seguroRctrcId: [id],
        dataContratacao: [FormateDate.formateDate(new Date(dataContratacao))],
        dataVencimento: [FormateDate.formateDate(new Date(dataVencimento))],
        flagGerarAlertaVencimento: [flagGerarAlertaVencimento],
        seguradora: [seguradora],
        numeroApolice: [numeroApolice],
      });

      this.empresaSeguroRctrcItems.push(form);
      return;
    } else {
      this.empresaSeguroRctrcItems.push(
        this.formBuilder.group({
          seguroRctrcId: [""],
          dataContratacao: [""],
          dataVencimento: [""],
          flagGerarAlertaVencimento: [false],
          seguradora: [""],
          numeroApolice: [""],
        })
      );
    }
  }

  deleteSeguroRctrcItem(index): void {
    this.empresaSeguroRctrcItems.removeAt(index);
  }

  public async loadCompany(id, cnpj): Promise<void> {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
    try {
      const types = await this.apiBaseService.get<ContentInterface[]>({
        url: "empresa-tipo",
      });
      this.types = types["content"];

      var response;

      if (cnpj != "undefined") {
        response = await this.apiBaseService.get<Company>({
          url: new Company()["nomeConsultaApiBase"] + "/" + this.companyId,
        });
      } else {
        response = await this.apiBaseService.get<Company>({
          url: new Company()["nomeConsultaApiBaseGet"] + "/" + this.companyId,
        });
      }

      this.company = response as Company;

      this.companiesSelect = [
        { name: "Divisão", value: 1 },
        { name: "Matriz", value: 2 },
        { name: "Filial", value: 3 },
      ];

      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      const result = await this.apiBaseService.get<any>({
        url: new Company()["nomeConsultaApiBase"],
        params,
      });

      this.companies = result["content"];

      this.loader = false;

      this.fillCompanyData();
    } catch (error) {
      console.error("loadCompany() error", error);
    }
  }

  fillCompanyData(): void {
    if (this.company.cnpj == null || this.company.cnpj == "") {
      this.empresaSelected = "Divisão";
    } else if (
      (this.company.cnpj != null || this.company.cnpj != "") &&
      this.company.idEmpresaMatriz == null
    ) {
      this.empresaSelected = "Matriz";
    } else {
      this.empresaSelected = "Filial";
    }

    if (this.company.idEmpresaMatriz) {
      this.companyForm.controls["empresaMatriz"].setValue(
        this.company.idEmpresaMatriz
      );
    }

    if (this.company.idEmpresaTipo) {
      this.companyForm.controls["empresaTipo"].setValue(
        this.company.idEmpresaTipo
      );
    }

    Object.getOwnPropertyNames(this.company).forEach((element) => {
      if (this.companyForm.controls[element]) {
        this.companyForm.controls[element].setValue(this.company[element]);
      }
    });

    const listEmpresaAutorizacaoAntt =
      this.company.listEmpresaAutorizacaoAnttResponse;

    if (
      listEmpresaAutorizacaoAntt != null &&
      listEmpresaAutorizacaoAntt.length > 0
    ) {
      listEmpresaAutorizacaoAntt.forEach((element) => {
        this.addAutorizacaoAnttItem(element);
      });
    }
    const listEmpresaSeguroRctrcList =
      this.company.listEmpresaSeguroRctrcResponse;

    console.log(listEmpresaSeguroRctrcList);

    if (
      listEmpresaSeguroRctrcList != null &&
      listEmpresaSeguroRctrcList.length > 0
    ) {
      listEmpresaSeguroRctrcList.forEach((element) => {
        this.addSeguroRctrcItem(element);
      });
    }

    this.errorDebug = getFormValidationErrors(this.companyForm);
  }

  async searchCep(): Promise<void> {
    const { cdCep } = this.companyForm.value;
    const cep = cdCep.replace(/\D/g, "");

    if (cep !== "") {
      const cepValidatorRules = /^[0-9]{8}$/;
      if (cepValidatorRules.test(cep)) {
        const url = `https://viacep.com.br/ws/${cep}/json`;
        try {
          const { data } = await axios.get(url);
          const { logradouro, complemento, bairro, localidade, uf } = data;

          this.companyForm.controls["txEndereco"].setValue(logradouro);
          this.companyForm.controls["dsComplemento"].setValue(complemento);
          this.companyForm.controls["nmBairro"].setValue(bairro);
          this.companyForm.controls["nmCidade"].setValue(localidade);
          this.companyForm.controls["nmEstado"].setValue(uf);
        } catch (error) {
          console.log("error ", error);
        }
      }
    }
  }

  getFormValidationErrors(): void {
    const errors = [];
    Object.keys(this.companyForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.companyForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          const error = `Key control: ${key}, keyError: ${keyError}, err value: `;
          const value = controlErrors[keyError];
          console.log(error, value);
          errors.push({ error, value });
        });
      }
    });
  }
}
