<div class="toolbox_proximity" *ngIf="this.permissionService.hasPermission('PROXIMIDADE_BOTAO_FUNCAO', this.permissions)">
  <mat-button-toggle-group
    name="proximity"
    [(ngModel)]="action"
    (change)="toggleView($event)"
  >
    <mat-button-toggle value="proximity">
      <mat-icon>flip_camera_android</mat-icon>
      <span class="btn-icon-text">Proximidade</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
