<div class="toolbox_select" *ngIf="this.permissionService.hasPermission('SELECIONAR_BOTAO_FUNCAO', this.permissions)">
  <mat-button-toggle-group
    name="select"
    [(ngModel)]="action"
    (change)="toggleView($event)"
  >
    <mat-button-toggle value="select">
      <mat-icon>touch_app</mat-icon>
      <span class="btn-icon-text">Selecionar</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
