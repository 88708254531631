import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { TypeProfile } from "../../models/TypeProfile.model";
import { Validation } from "./validation";
import { NgxSpinnerService } from "ngx-spinner";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-create-perfil-tipo",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class EditPerfilTipoComponent implements OnInit {
  public perfilTipoForm: FormGroup;
  public perfilTipo: TypeProfile;
  public perfilTipoId: number;
  public title = "Atualizar Perfil Tipo";
  public errorMessage;
  private apiBaseService: ApiBaseService;
  validation: Validation;

  ngOnInit(): void {
    this.perfilTipoForm = this.formBuilder.group(this.validation.rules());
    this.perfilTipoId = this.activatedRoute.snapshot.params["id"];
    this.loadPerfilTipo(this.perfilTipoId);
    //this.setup();
  }
  public async loadPerfilTipo(id): Promise<void> {
    try {
      this.spinner.show();
      const response = await this.apiBaseService.get<TypeProfile>({
        url: new TypeProfile()["nomeConsultaApiBase"] + "/" + id,
      });
      this.perfilTipo = response as TypeProfile;
      this.fillUserData();
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }
  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }

  fillUserData(): void {
    const fields = ["cdPerfilTipo", "nmPerfilTipo"];
    fields.forEach((element) => {
      this.perfilTipoForm.controls[element].setValue(this.perfilTipo[element]);
    });
  }
  public async submitForm(): Promise<void> {
    try {
      this.spinner.show();
      this.coverterUpperCase(this.perfilTipoForm);

      const values = this.perfilTipoForm.value;
      const id = Math.floor(this.perfilTipoId);
      const post = {
        id,
        ...values,
        ativo: true,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      await this.apiBaseService.put<TypeProfile>(
        this.perfilTipoId,
        new TypeProfile()["nomeConsultaApiBase"],
        post
      );
      this.spinner.hide();
      this.router.navigate(["/perfil-tipo"]);
    } catch (error) {
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public coverterUpperCase(perfilTipoForm: FormGroup) {
    console.log(perfilTipoForm);

    perfilTipoForm
      .get("cdPerfilTipo")
      .setValue(perfilTipoForm.get("cdPerfilTipo").value.toUpperCase());
    perfilTipoForm
      .get("nmPerfilTipo")
      .setValue(perfilTipoForm.get("nmPerfilTipo").value.toUpperCase());
  }
}
