import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-axle-four-dual-view",
  templateUrl: "./axle-four-dual-view.component.html",
  styleUrls: ["./axle-four-dual-view.component.scss"],
})
export class AxleFourDualViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorFourIntLeftSelect: any = [];
  public sensorFourIntRightSelect: any = [];

  public sensorFourExtLeftSelect: any = [];
  public sensorFourExtRightSelect: any = [];

  public sensorFourIntRightCombo: any = [];
  public sensorFourIntLeftCombo: any = [];

  public sensorFourExtRightCombo: any = [];
  public sensorFourExtLeftCombo: any = [];

  public axisFourDoubleLeftIntForm: FormGroup;
  public axisFourDoubleLeftExtForm: FormGroup;
  public axisFourDoubleRightIntForm: FormGroup;
  public axisFourDoubleRightExtForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisFourDoubleLeftIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisFourDoubleLeftExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisFourDoubleRightIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisFourDoubleRightExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisFourDoubleLeftIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 4 ESQ INT"
      );
      this.axisFourDoubleLeftExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 4 ESQ EXT"
      );

      this.axisFourDoubleRightIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 4 DIR INT"
      );
      this.axisFourDoubleRightExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 4 DIR EXT"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo4DireitoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 10) {
              eixo4DireitoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 10) {
              eixo4DireitoExt = filter;
            }
          }
        });
        var eixo4EsquerdoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 9) {
              eixo4EsquerdoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 9) {
              eixo4EsquerdoExt = filter;
            }
          }
        });
        var eixo4DireitoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 26) {
              eixo4DireitoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 26) {
              eixo4DireitoInt = filter;
            }
          }
        });
        var eixo4EsquerdoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 25) {
              eixo4EsquerdoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 25) {
              eixo4EsquerdoInt = filter;
            }
          }
        });

        // EIXO 4 DIREITO EXTERNO
        if (eixo4DireitoExt != null) {
          this.axisFourDoubleRightExtForm.controls["id"].setValue(
            eixo4DireitoExt.id
          );

          if (eixo4DireitoExt.sensor != null) {
            this.sensorFourExtRightCombo.push(eixo4DireitoExt.sensor);
            this.sensorFourExtRightSelect = eixo4DireitoExt.sensor.id;
          }

          if (eixo4DireitoExt.pneu != null) {
            this.axisFourDoubleRightExtForm.controls["pneu"].setValue(
              eixo4DireitoExt.pneu.numeroFogo
            );
          }

          this.axisFourDoubleRightExtForm.controls["nrPressaoMinima"].setValue(
            eixo4DireitoExt.nrPressaoMinima
          );

          this.axisFourDoubleRightExtForm.controls["nrPressaoMaxima"].setValue(
            eixo4DireitoExt.nrPressaoMaxima
          );

          this.axisFourDoubleRightExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo4DireitoExt.nrTemperaturaMaxima);
        }

        // EIXO 4 ESQUERDO EXTERNO
        if (eixo4EsquerdoExt != null) {
          this.axisFourDoubleLeftExtForm.controls["id"].setValue(
            eixo4EsquerdoExt.id
          );

          if (eixo4EsquerdoExt.sensor != null) {
            this.sensorFourExtLeftCombo.push(eixo4EsquerdoExt.sensor);
            this.sensorFourExtLeftSelect = eixo4EsquerdoExt.sensor.id;
          }

          if (eixo4EsquerdoExt.pneu != null) {
            this.axisFourDoubleLeftExtForm.controls["pneu"].setValue(
              eixo4EsquerdoExt.pneu.numeroFogo
            );
          }

          this.axisFourDoubleLeftExtForm.controls["nrPressaoMinima"].setValue(
            eixo4EsquerdoExt.nrPressaoMinima
          );

          this.axisFourDoubleLeftExtForm.controls["nrPressaoMaxima"].setValue(
            eixo4EsquerdoExt.nrPressaoMaxima
          );

          this.axisFourDoubleLeftExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo4EsquerdoExt.nrTemperaturaMaxima);
        }

        // EIXO 4 DIREITO INTERNO
        if (eixo4DireitoInt != null) {
          this.axisFourDoubleRightIntForm.controls["id"].setValue(
            eixo4DireitoInt.id
          );

          if (eixo4DireitoInt.sensor != null) {
            this.sensorFourIntRightCombo.push(eixo4DireitoInt.sensor);
            this.sensorFourIntRightSelect = eixo4DireitoInt.sensor.id;
          }

          if (eixo4DireitoInt.pneu != null) {
            this.axisFourDoubleRightIntForm.controls["pneu"].setValue(
              eixo4DireitoInt.pneu.numeroFogo
            );
          }

          this.axisFourDoubleRightIntForm.controls["nrPressaoMinima"].setValue(
            eixo4DireitoInt.nrPressaoMinima
          );

          this.axisFourDoubleRightIntForm.controls["nrPressaoMaxima"].setValue(
            eixo4DireitoInt.nrPressaoMaxima
          );

          this.axisFourDoubleRightIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo4DireitoInt.nrTemperaturaMaxima);
        }

        // EIXO 4 ESQUERDO INTERNO
        if (eixo4EsquerdoInt != null) {
          this.axisFourDoubleLeftIntForm.controls["id"].setValue(
            eixo4EsquerdoInt.id
          );

          if (eixo4EsquerdoInt.sensor != null) {
            this.sensorFourIntLeftCombo.push(eixo4EsquerdoInt.sensor);
            this.sensorFourIntLeftSelect = eixo4EsquerdoInt.sensor.id;
          }

          if (eixo4EsquerdoInt.pneu != null) {
            this.axisFourDoubleLeftIntForm.controls["pneu"].setValue(
              eixo4EsquerdoInt.pneu.numeroFogo
            );
          }

          this.axisFourDoubleLeftIntForm.controls["nrPressaoMinima"].setValue(
            eixo4EsquerdoInt.nrPressaoMinima
          );

          this.axisFourDoubleLeftIntForm.controls["nrPressaoMaxima"].setValue(
            eixo4EsquerdoInt.nrPressaoMaxima
          );

          this.axisFourDoubleLeftIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo4EsquerdoInt.nrTemperaturaMaxima);
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
