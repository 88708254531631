import { ToolsService } from "src/app/services/migrate/tools.service";

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { MatTableDataSource } from "@angular/material/table";
import { DatePipe } from "@angular/common";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import { FinishOperatorModel } from "../../../models/finish-operator-model";
import { MinioService } from "src/app/services/minio.service";

@Component({
  selector: "app-finalize-analysis",
  templateUrl: "./finalize-analysis.component.html",
  styleUrls: ["./finalize-analysis.component.scss"],
})
export class FinalizeAnalysisComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;
  public minioService: MinioService;
  public datePipe: DatePipe;

  public treatmentManagerForm: FormGroup;

  public treatmentManagersSelected;
  public treatmentManager = [];

  public dataSource: MatTableDataSource<any>;

  public analyzeReportUuid = "";
  public tableColumns: string[];

  public errorMessage;

  ngOnInit(): void {
    this.analyzeReportUuid = this.activatedRoute.snapshot.params.uuid;
    this.treatmentManagerForm = this.formBuilder.group(
      {
        managerComment: ["", Validators.required],
      },
      { updateOn: "blur" }
    );
    this.loadTableDefinitions();
    this.loadDataElements();
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "treatmentDate",
      "operatorDealings",
      "nameOperator",
      "descriptionAnalyze",
      "minioObject",
      "state",
      "city",
    ];
  }

  public async submitForm(): Promise<void> {
    console.log(this.treatmentManagerForm.get("managerComment").value);

    var finishOperatorModel: FinishOperatorModel = {
      operatorSummary: "",
    };

    finishOperatorModel.operatorSummary =
      this.treatmentManagerForm.get("managerComment").value;

    var operator = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;
    this.analyzeTripService
      .finishOperator(finishOperatorModel, operator, this.analyzeReportUuid)
      .subscribe(
        (v) => {
          console.log("Analise finalizada com sucesso");
          this.router.navigate(["/analise-operacional"]);
          this.spinner.hide();
        },
        (e) => {
          console.log("Erro ao finalizar analise");
          this.spinner.hide();
          this.errorMessage = e.message;
          throw new EvalError(e);
        }
      );
  }

  exportFile(element): void {
    this.minioService.exportReport(element).subscribe();
  }

  constructor(
    private tools: ToolsService,
    datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    analyzeTripService: AnalyzeTripService,
    minioService: MinioService,
  ) {
    this.datePipe = datePipe;
    this.analyzeTripService = analyzeTripService;
    this.minioService = minioService;
  }

  public async loadDataElements() {
    this.spinner.show();
    try {
      this.analyzeTripService
        .getOperatorTreat(this.analyzeReportUuid, "false")
        .subscribe((t) => {
          this.dataSource = t;
        });

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }
}
