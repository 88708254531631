import { ContentInterface } from "./../../contracts/Content.interface";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Maintenance } from "../../models/Maintenance.model";
import { ApiService } from "../../services/api.service";
import { Validation } from "./validation";
import storageX from "src/app/storageCore/storageX";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-create-maintenance",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CloneMaintenanceComponent implements OnInit {
  public manutencaoForm: FormGroup;
  public veiculoSelected;
  public manutencao: Maintenance;
  public manutencaoId: number;
  public title = "Duplicar Manutenção";
  public errorMessage;
  private service: ApiService;
  private validation: Validation;
  public vehicle: any = [];
  public loader = false;

  ngOnInit(): void {
    this.manutencaoForm = this.formBuilder.group(this.validation.rules());
    this.manutencaoId = this.activatedRoute.snapshot.params["id"];
    this.loadMaintenance(this.manutencaoId);
    this.setup();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  async setup(): Promise<void> {
    try {
      this.spinner.show();

      const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(); //localStorage.getItem('idPerfil');
      const vehicles = await this.service.get<ContentInterface[]>({
        url: "veiculo/perfil/" + perfil + "?size=2000",
      });
      this.vehicle = vehicles["content"];

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.service = apiService;
    this.validation = new Validation();
  }

  fillMaintenanceData(): void {
    this.veiculoSelected = this.manutencao.veiculo.id;

    if (this.veiculoSelected) {
      this.manutencaoForm.controls["veiculo"].setValue(this.veiculoSelected);
    }

    const fields = [
      "dtManutencao",
      "nrQuilometragem",
      "dsDescricao",
      "flAlarmeGerado",
    ];
    fields.forEach((element) => {
      this.manutencaoForm.controls[element].setValue(this.manutencao[element]);
    });
  }

  public async loadMaintenance(id): Promise<void> {
    try {
      this.spinner.show();
      const response = await this.service.get<Maintenance>({
        url: `veiculo-manutencao/${id}`,
      });
      this.manutencao = response as Maintenance;
      this.fillMaintenanceData();

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public async submitForm(): Promise<void> {
    try {
      this.spinner.show();
      this.coverterUpperCase(this.manutencaoForm);

      const values = this.manutencaoForm.value;

      const post = {
        ...values,
        veiculo: { id: parseInt(values.veiculo) },
        idUsuarioCriacao: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()
          ?.id,
      };

      await this.service.post<Maintenance>("veiculo-manutencao", post);
      this.spinner.hide();

      this.router.navigate(["/maintenance"]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public coverterUpperCase(manutencaoForm: FormGroup) {
    console.log(manutencaoForm);

    manutencaoForm
      .get("dsDescricao")
      .setValue(manutencaoForm.get("dsDescricao").value.toUpperCase());
  }
}
