<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>Seleção de Análises</app-title>
  
        <div class="row mt-3 no-gutters p-4 row-form">
              <div class="col-12">
                  <form [formGroup]="analysisOperatorForm" (ngSubmit)="submitForm()">
                      <h2 class="mb-3">Análises Operacionais</h2>
                      <div class="form-row" >
                        <div class="form-group col-md-8">
                          <mat-form-field appearance="outline">
                            <mat-label>Análises Operador</mat-label>
                            <mat-select formControlName="analyze" required>
                              <mat-option *ngFor="let type of analysisOperator[0]" [value]="type">
                                {{ formatDate(type.analyze_start_date) }} Até {{ formatDate(type.analyze_end_date)}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        
                      </div>
                        
  
                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>
  
                    <div class="form-group mt-2 mb-0 text-center">
                        
                        <back-button></back-button>
                        <button  (click)="generateNewAnalysis()"  style="background: darkmagenta;"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0" >
                            Gerar Nova Análise
                        </button>
                        <button type="submit" [disabled]="!analysisOperatorForm.valid"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Gerar Tratativas
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  
  