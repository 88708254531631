import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
/**
 * Classe responsavel pelo controle de exibição dos itens do menu principal
 */
export class ToolboxControl {
  public mapPosition = false;
  public positionBarPx = false;
  public popupTelemetria = false;
  public showMarkerLabel = false;
  /**
   * Controla a exibição da barra lateral
   */
  public showSidebar = true;

  toggleSideNav(): void {
    setTimeout(() => {
      this.showSidebar = !this.showSidebar;
      this.positionBarPx = !this.showSidebar;
      this.mapPosition = !this.showSidebar;
    }, 0);

  }
}
