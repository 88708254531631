import { AppPerfilSelectorComponent } from "./login/app-perfil-selector/app-perfil-selector.component";
import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { AgmDirectionModule } from "agm-direction";
import { AgmOverlays } from "agm-overlays";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
  APP_INITIALIZER,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ChartsModule } from "ng2-charts";

import { Ng2SearchPipeModule } from "ng2-search-filter";

import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BackButtonComponent } from "./components/common/back-button/back-button.component";
import { ValidationErrorsComponent } from "./components/common/validation-errors/validation-errors.component";
import { HeaderComponent } from "./components/header/header.component";
import { ProximityComponent } from "./components/maps/proximity/proximity.component";
import { RouteComponent } from "./components/maps/route/route.component";
import { ElectronicFenceComponent } from "./components/maps/electronic-fence/electronic-fence.component";
import { FindVehiclesComponent } from "./components/maps/find-vehicles/find-vehicles.component";
import { SearchComponent } from "./components/maps/search/search.component";
import { StreetviewComponent } from "./components/maps/streetview/streetview.component";
import { TerminalComponent } from "./components/maps/terminal/terminal.component";
import { MenuComponent } from "./components/menu/menu.component";
import { TitleComponent } from "./components/title/title.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ErrorInterceptor } from "./helpers/error.interceptor";
import { AtividadeComponent } from "./pages/atividade/atividade.component";
import { CreateAtividadeComponent } from "./pages/atividade/create.component";
import { EditAtividadeComponent } from "./pages/atividade/edit.component";
import { CloneAtividadeComponent } from "./pages/atividade/clone.component";
import { ViewAtividadeComponent } from "./pages/atividade/view.component";
import { CockpitComponent } from "./pages/cockpit/cockpit.component";
import { CompanyCreateComponent } from "./pages/company/form/create.component";
import { CompanyEditComponent } from "./pages/company/form/edit.component";
import { CompanyCloneComponent } from "./pages/company/form/clone.component";
import { CompanyViewComponent } from "./pages/company/form/view.component";
import { ConsultationsComponent } from "./pages/consultations/consultations.component";
import { DriverReportComponent } from "./pages/driver-report/driver-report.component";
import { DriverReportMapComponent } from "./pages/driver-report/driver-report-map.component";
import { StopReportMapComponent } from "./pages/stop-report/stop-report-map.component";
import { VehicleReportComponent } from "./pages/vehicle-report/vehicle-report.component";
import { StopReportComponent } from "./pages/stop-report/stop-report.component";
import { EventsReportComponent } from "./pages/events-report/events-report.component";
import { CommandReportComponent } from "./pages/command-report/command-report.component";
import { ControlItemComponent } from "./pages/control-item/control-item.component";
import { CreateDriverComponent } from "./pages/driver/form/create.component";
import { EditDriverComponent } from "./pages/driver/form/edit.component";
import { CloneDriverComponent } from "./pages/driver/form/clone.component";
import { ViewDriverComponent } from "./pages/driver/form/view.component";
import { ConsultaComponent } from "./pages/consulta/consulta.component";
import { DriversComponent } from "./pages/driver/list/drivers.component";
import { EventsAlarmComponent } from "./pages/events-alarm/events-alarm.component";
import { StatusComponent } from "./pages/status/status.component";
import { CreateFuncionalidadeTipoComponent } from "./pages/funcionalidade-tipo/create.component";
import { EditFuncionalidadeTipoComponent } from "./pages/funcionalidade-tipo/edit.component";
import { CloneFuncionalidadeTipoComponent } from "./pages/funcionalidade-tipo/clone.component";
import { ViewFuncionalidadeTipoComponent } from "./pages/funcionalidade-tipo/view.component";
import { FuncionalidadeTipoComponent } from "./pages/funcionalidade-tipo/funcionalidade-tipo.component";
import { CreateFuncionalidadeComponent } from "./pages/funcionalidade/create.component";
import { EditFuncionalidadeComponent } from "./pages/funcionalidade/edit.component";
import { CloneFuncionalidadeComponent } from "./pages/funcionalidade/clone.component";
import { ViewFuncionalidadeComponent } from "./pages/funcionalidade/view.component";
import { FuncionalidadeComponent } from "./pages/funcionalidade/funcionalidade.component";
import { CreateMaintenanceComponent } from "./pages/maintenance/create.component";
import { EditMaintenanceComponent } from "./pages/maintenance/edit.component";
import { CloneMaintenanceComponent } from "./pages/maintenance/clone.component";
import { ViewMaintenanceComponent } from "./pages/maintenance/view.component";
import { MaintenanceComponent } from "./pages/maintenance/maintenance.component";
import { CreateModuloComponent } from "./pages/modulo/create.component";
import { EditModuloComponent } from "./pages/modulo/edit.component";
import { CloneModuloComponent } from "./pages/modulo/clone.component";
import { ViewModuloComponent } from "./pages/modulo/view.component";
import { ModuloComponent } from "./pages/modulo/modulo.component";
import { CreatePerfilTipoComponent } from "./pages/perfil-tipo/create.component";
import { EditPerfilTipoComponent } from "./pages/perfil-tipo/edit.component";
import { ClonePerfilTipoComponent } from "./pages/perfil-tipo/clone.component";
import { ViewPerfilTipoComponent } from "./pages/perfil-tipo/view.component";
import { PerfilTipoComponent } from "./pages/perfil-tipo/perfil-tipo.component";
import { CreatePerfilComponent } from "./pages/perfil/create.component";
import { EditPerfilComponent } from "./pages/perfil/edit.component";
import { ClonePerfilComponent } from "./pages/perfil/clone.component";
import { ViewPerfilComponent } from "./pages/perfil/view.component";
import { FormAbaFuncionalidadeComponent } from "./pages/perfil/form-aba-funcionalidade/form-aba-funcionalidade.component";
import { PerfilComponent } from "./pages/perfil/perfil.component";
import { RegistrationsComponent } from "./pages/registrations/registrations.component";
import { RoutesComponent } from "./pages/route/routes.component";
import { EletronicFencesComponent } from "./pages/eletronic-fence/eletronic-fences.component";
import { SettingsComponent } from "./pages/setting/settings.component";
import { CreateTerminalAlarmeComponent } from "./pages/terminal-alarme/create.component";
import { EditTerminalAlarmeComponent } from "./pages/terminal-alarme/edit.component";
import { CloneTerminalAlarmeComponent } from "./pages/terminal-alarme/clone.component";
import { ViewTerminalAlarmeComponent } from "./pages/terminal-alarme/view.component";
import { TerminalAlarmeComponent } from "./pages/terminal-alarme/terminal-alarme.component";
import { CreateTerminalComponent } from "./pages/terminal/create.component";
import { EditTerminalComponent } from "./pages/terminal/edit.component";
import { CloneTerminalComponent } from "./pages/terminal/clone.component";
import { ViewTerminalComponent } from "./pages/terminal/view.component";
import { TerminalComponentTerminal } from "./pages/terminal/terminal.component";
import { CreateUserComponent } from "./pages/users/create.component";
import { EditUserComponent } from "./pages/users/edit.component";
import { CloneUserComponent } from "./pages/users/clone.component";
import { ViewUserComponent } from "./pages/users/view.component";
import { VehicleTypesComponent } from "./pages/vehicle-types/vehicle-types.component";
import { CreateVehicleComponent } from "./pages/vehicles/create.component";
import { EditVehicleComponent } from "./pages/vehicles/edit.component";
import { CloneVehicleComponent } from "./pages/vehicles/clone.component";
import { ViewVehicleComponent } from "./pages/vehicles/view.component";
import { VehiclesComponent } from "./pages/vehicles/vehicles.component";
import { MapToolboxService } from "./services/map.toolbox.service";
import { VinculoDialogComponent } from "./components/dialog-template/vinculo-dialog/vinculo-dialog.component";
import { AppListToggleComponent } from "./components/app-list-toggle/app-list-toggle.component";
import { FormAbaEmpresaComponent } from "./pages/perfil/form-aba-empresa/form-aba-empresa.component";
import { VisualizarDialogComponent } from "./pages/events-alarm/visualizar-dialog/visualizar-dialog.component";
import { EditRouteComponent } from "./pages/route/edit.component";
import { EditEletronicFenceComponent } from "./pages/eletronic-fence/edit.component";
import { SensorComponent } from "./pages/sensor/sensor.component";
import { CreateSensorComponent } from "./pages/sensor/create.component";
import { EditSensorComponent } from "./pages/sensor/edit.component";
import { CloneSensorComponent } from "./pages/sensor/clone.component";
import { ViewSensorComponent } from "./pages/sensor/view.component";
import { CreatePneuComponent } from "./pages/pneu/create.component";
import { EditPneuComponent } from "./pages/pneu/edit.component";
import { ClonePneuComponent } from "./pages/pneu/clone.component";
import { ViewPneuComponent } from "./pages/pneu/view.component";
import { DatePipe, registerLocaleData } from "@angular/common";
import ptBr from "@angular/common/locales/pt";
import { IConfig, NgxMaskModule } from "ngx-mask";

// relatórios
import { ToastModule } from "primeng/toast";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(ptBr);

import { DistanceDialogComponent } from "./components/maps/toolbox/distance-dialog/distance-dialog.component";
import { PathComponent } from "./components/maps/path/path.component";

// PAG. SITE
import { FormAbaPerfilComponent } from "./pages/users/form-aba-perfil/form-aba-perfil.component";
import { FormAbaPerfilVisualizacaoComponent } from "./pages/users/form-aba-perfil-visualizacao/form-aba-perfil-visualizacao.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { MessageService } from "primeng/api";
import { CreateNewPasswordWithCodeComponent } from "./create-new-password-with-code/create-new-password-with-code.component";
import { ValidateCodeComponent } from "./components/validate-code/validate-code.component";
import { StyleModule } from "./services/migrate/styleModules.module";
import { LoginComponent } from "./login/login.component";
import { RedirectLoadUserComponent } from "./login/redirect-load-user/redirect-load-user.component";
import { SkeletonWModule } from "./components/common/skeleton/skeleton.module";
import { ListaTerminalComponent } from "./lista-terminal/lista-terminal.component";
import { environment } from "src/environments/environment";
import { TranslatePipe } from "./pipes/translate.pipe";
import { ReportsComponent } from "./pages/reports/reports.component";
import { CockpitGraphicComponent } from "./pages/cockpit-graphic/cockpit-graphic.component";
import { GeneralFilterComponent } from "./components/maps/general-filter/general-filter.component";
import { TransporterComponent } from "./pages/transporter/transporter.component";
import { CreateTransporterComponent } from "./pages/transporter/create-transporter.component";
import { EditTransporterComponent } from "./pages/transporter/edit-transporter.component";
import { CloneTransporterComponent } from "./pages/transporter/clone-transporter.component";
import { ViewTransporterComponent } from "./pages/transporter/view-transporter.component";
import { PneuComponent } from "./pages/pneu/pneu.component";
import { PneuPosicaoComponent } from "./pages/pneu/pneu-posicao/pneu-posicao.component";
import { PneuPosicaoViewComponent } from "./pages/pneu/pneu-posicao-view/pneu-posicao-view.component";
import { DirectionalComponent } from "./pages/vehicles/directional/directional.component";
import { AxleOneDualComponent } from "./pages/vehicles/axle-one-dual/axle-one-dual.component";
import { AxleOneSimpleComponent } from "./pages/vehicles/axle-one-simple/axle-one-simple.component";
import { AxleTwoSimpleComponent } from "./pages/vehicles/axle-two-simple/axle-two-simple.component";
import { AxleTwoDualComponent } from "./pages/vehicles/axle-two-dual/axle-two-dual.component";
import { AxleThreeSimpleComponent } from "./pages/vehicles/axle-three-simple/axle-three-simple.component";
import { AxleThreeDualComponent } from "./pages/vehicles/axle-three-dual/axle-three-dual.component";
import { AxleFourSimpleComponent } from "./pages/vehicles/axle-four-simple/axle-four-simple.component";
import { AxleFourDualComponent } from "./pages/vehicles/axle-four-dual/axle-four-dual.component";
import { AxleFiveDualComponent } from "./pages/vehicles/axle-five-dual/axle-five-dual.component";
import { AxleFiveSimpleComponent } from "./pages/vehicles/axle-five-simple/axle-five-simple.component";
import { AxleSixSimpleComponent } from "./pages/vehicles/axle-six-simple/axle-six-simple.component";
import { AxleSixDualComponent } from "./pages/vehicles/axle-six-dual/axle-six-dual.component";
import { AxleSevenDualComponent } from "./pages/vehicles/axle-seven-dual/axle-seven-dual.component";
import { AxleSevenSimpleComponent } from "./pages/vehicles/axle-seven-simple/axle-seven-simple.component";
import { AxleEightSimpleComponent } from "./pages/vehicles/axle-eight-simple/axle-eight-simple.component";
import { AxleEightDualComponent } from "./pages/vehicles/axle-eight-dual/axle-eight-dual.component";
import { SteppeComponent } from "./pages/vehicles/steppe/steppe.component";
import { AxleNineSimpleComponent } from "./pages/vehicles/axle-nine-simple/axle-nine-simple.component";
import { AxleNineDualComponent } from "./pages/vehicles/axle-nine-dual/axle-nine-dual.component";
import { DirectionalTwoComponent } from "./pages/vehicles/directional-two/directional-two.component";
import { SteppeViewComponent } from "./pages/vehicles/steppe-view/steppe-view.component";
import { DirectionalViewComponent } from "./pages/vehicles/directional-view/directional-view.component";
import { DirectionalTwoViewComponent } from "./pages/vehicles/directional-two-view/directional-two-view.component";
import { AxleTwoSimpleViewComponent } from "./pages/vehicles/axle-two-simple-view/axle-two-simple-view.component";
import { AxleTwoDualViewComponent } from "./pages/vehicles/axle-two-dual-view/axle-two-dual-view.component";
import { AxleThreeSimpleViewComponent } from "./pages/vehicles/axle-three-simple-view/axle-three-simple-view.component";
import { AxleThreeDualViewComponent } from "./pages/vehicles/axle-three-dual-view/axle-three-dual-view.component";
import { AxleSixSimpleViewComponent } from "./pages/vehicles/axle-six-simple-view/axle-six-simple-view.component";
import { AxleSixDualViewComponent } from "./pages/vehicles/axle-six-dual-view/axle-six-dual-view.component";
import { AxleSevenSimpleViewComponent } from "./pages/vehicles/axle-seven-simple-view/axle-seven-simple-view.component";
import { AxleSevenDualViewComponent } from "./pages/vehicles/axle-seven-dual-view/axle-seven-dual-view.component";
import { AxleOneSimpleViewComponent } from "./pages/vehicles/axle-one-simple-view/axle-one-simple-view.component";
import { AxleOneDualViewComponent } from "./pages/vehicles/axle-one-dual-view/axle-one-dual-view.component";
import { AxleNineSimpleViewComponent } from "./pages/vehicles/axle-nine-simple-view/axle-nine-simple-view.component";
import { AxleNineDualViewComponent } from "./pages/vehicles/axle-nine-dual-view/axle-nine-dual-view.component";
import { AxleFourSimpleViewComponent } from "./pages/vehicles/axle-four-simple-view/axle-four-simple-view.component";
import { AxleFourDualViewComponent } from "./pages/vehicles/axle-four-dual-view/axle-four-dual-view.component";
import { AxleFiveSimpleViewComponent } from "./pages/vehicles/axle-five-simple-view/axle-five-simple-view.component";
import { AxleFiveDualViewComponent } from "./pages/vehicles/axle-five-dual-view/axle-five-dual-view.component";
import { AxleEightSimpleViewComponent } from "./pages/vehicles/axle-eight-simple-view/axle-eight-simple-view.component";
import { AxleEightDualViewComponent } from "./pages/vehicles/axle-eight-dual-view/axle-eight-dual-view.component";

import { StartAppService } from "../app/start-app.service";
import { ReportOperationalAsyncComponent } from "./pages/report-operational-async/report-operational-async.component";
import { ReportAsyncDetailComponent } from "./pages/report-operational-async/report-async-detail/report-async-detail.component";
import { ReportOperationalAsyncEditComponent } from "./pages/report-operational-async/report-operational-async-edit.component";
import { ReportAsyncViewComponent } from "./pages/report-operational-async/report-async-view/report-async-view.component";
import { AnalyzeReportOperatorComponent } from "./pages/analyze-report-operator/analyze-report-operator.component";
import { CreateAnalysisComponent } from "./pages/analyze-report-operator/create-analysis/create-analysis.component";
import { TreatAnalysisComponent } from "./pages/analyze-report-operator/treat-analysis/treat-analysis.component";
import { ManagerAnalysisComponent } from "./pages/manager-analysis/manager-analysis.component";
import { ManagerTreatmentComponent } from "./pages/manager-analysis/manager-treatment/manager-treatment.component";
import { EditAnalysisComponent } from "./pages/analyze-report-operator/edit-analysis/edit-analysis.component";
import { ViewReportOperatorComponent } from "./pages/analyze-report-operator/view-report-operator/view-report-operator.component";
import { SupervisorAnalysisComponent } from "./pages/supervisor-analysis/supervisor-analysis.component";
import { SupervisorViewTreatComponent } from "./pages/supervisor-analysis/supervisor-view-treat/supervisor-view-treat.component";
import { RejectionSupervisorComponent } from "./pages/supervisor-analysis/rejection-supervisor/rejection-supervisor.component";
import { AnalyzeReportHistoricComponent } from "./pages/analyze-report-historic/analyze-report-historic.component";
import { ViewTreatAnalysisComponent } from "./pages/analyze-report-operator/view-treat-analysis/view-treat-analysis.component";
import { TreatAnalysisSelectComponent } from "./pages/analyze-report-operator/treat-analysis-select/treat-analysis-select.component";
import { NewAnalysisComponent } from "./pages/analyze-report-operator/new-analysis/new-analysis.component";
import { AppUserSelectorComponent } from "./login/app-user-selector/app-user-selector.component";
import { TreatAnalysisEditComponent } from "./pages/analyze-report-operator/treat-analysis-edit/treat-analysis-edit.component";
import { PenaltyFormComponent } from "./pages/supervisor-analysis/penalty-form/penalty-form.component";
import { FinalizeAnalysisComponent } from './pages/analyze-report-operator/finalize-analysis/finalize-analysis.component';
import { MonthlyReportComponent } from './pages/monthly-report/monthly-report.component';
import { MonthlyReportAllComponent } from './pages/monthly-report/monthly-report-all/monthly-report-all.component';
import { ManagerComponent } from './pages/manager/manager.component';
import { SupervisorComponent } from './pages/supervisor/supervisor.component';
import { CreateManagerComponent } from './pages/manager/create-manager/create-manager.component';
import { CreateSupervisorComponent } from './pages/supervisor/create-supervisor/create-supervisor.component';
import { CambusComponent } from './pages/cambus/cambus.component';
import { CompanySupervisorComponent } from './pages/supervisor/company-supervisor/company-supervisor.component';
import { CompanyManagerComponent } from './pages/manager/company-manager/company-manager.component';

export function initApplication(startApp: StartAppService) {
  return (): Promise<any> => {
    return startApp.initApp();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ValidateCodeComponent,
    CreateNewPasswordWithCodeComponent,
    HeaderComponent,
    MenuComponent,
    LoginComponent,
    AppPerfilSelectorComponent,
    RegistrationsComponent,
    ReportsComponent,
    ConsultationsComponent,
    BackButtonComponent,
    TitleComponent,
    CreateAtividadeComponent,
    EditAtividadeComponent,
    CloneAtividadeComponent,
    ViewAtividadeComponent,
    AtividadeComponent,
    CreateUserComponent,
    EditUserComponent,
    CloneUserComponent,
    ViewUserComponent,
    CompanyEditComponent,
    CompanyCreateComponent,
    CompanyCloneComponent,
    CompanyViewComponent,
    ValidationErrorsComponent,
    SearchComponent,
    StreetviewComponent,
    RouteComponent,
    ElectronicFenceComponent,
    FindVehiclesComponent,
    ProximityComponent,
    TerminalComponent,
    VehiclesComponent,
    VehicleTypesComponent,
    TerminalAlarmeComponent,
    CreateTerminalAlarmeComponent,
    EditTerminalAlarmeComponent,
    CloneTerminalAlarmeComponent,
    ViewTerminalAlarmeComponent,
    ConsultaComponent,
    DriversComponent,
    RoutesComponent,
    EletronicFencesComponent,
    ControlItemComponent,
    MaintenanceComponent,
    CreateVehicleComponent,
    EditVehicleComponent,
    CloneVehicleComponent,
    ViewVehicleComponent,
    CreateDriverComponent,
    EditDriverComponent,
    CloneDriverComponent,
    ViewDriverComponent,
    EditTerminalComponent,
    EditEletronicFenceComponent,
    EditRouteComponent,
    CloneTerminalComponent,
    ViewTerminalComponent,
    TerminalComponentTerminal,
    CreateTerminalComponent,
    CreateMaintenanceComponent,
    EditMaintenanceComponent,
    CloneMaintenanceComponent,
    ViewMaintenanceComponent,
    CockpitComponent,
    SettingsComponent,
    ForgotPasswordComponent,
    ModuloComponent,
    CreateModuloComponent,
    EditModuloComponent,
    CloneModuloComponent,
    ViewModuloComponent,
    PerfilComponent,
    CreatePerfilComponent,
    EditPerfilComponent,
    ClonePerfilComponent,
    ViewPerfilComponent,
    FuncionalidadeComponent,
    CreateFuncionalidadeComponent,
    EditFuncionalidadeComponent,
    CloneFuncionalidadeComponent,
    ViewFuncionalidadeComponent,
    FuncionalidadeTipoComponent,
    CreateFuncionalidadeTipoComponent,
    EditFuncionalidadeTipoComponent,
    CloneFuncionalidadeTipoComponent,
    ViewFuncionalidadeTipoComponent,
    PerfilTipoComponent,
    CreatePerfilTipoComponent,
    EditPerfilTipoComponent,
    ClonePerfilTipoComponent,
    ViewPerfilTipoComponent,
    EventsAlarmComponent,
    StatusComponent,
    FormAbaFuncionalidadeComponent,
    VinculoDialogComponent,
    VisualizarDialogComponent,
    AppListToggleComponent,
    FormAbaEmpresaComponent,
    DistanceDialogComponent,
    PathComponent,
    FormAbaPerfilComponent,
    RedirectLoadUserComponent,
    ListaTerminalComponent,
    DriverReportComponent,
    DriverReportMapComponent,
    StopReportMapComponent,
    VehicleReportComponent,
    CommandReportComponent,
    TranslatePipe,
    StopReportComponent,
    EventsReportComponent,
    FormAbaPerfilVisualizacaoComponent,
    CockpitGraphicComponent,
    SearchComponent,
    SensorComponent,
    CreateSensorComponent,
    EditSensorComponent,
    CloneSensorComponent,
    ViewSensorComponent,
    PneuComponent,
    CreatePneuComponent,
    EditPneuComponent,
    ClonePneuComponent,
    ViewPneuComponent,
    DirectionalComponent,
    AxleOneDualComponent,
    AxleOneSimpleComponent,
    AxleTwoDualComponent,
    AxleTwoSimpleComponent,
    AxleThreeSimpleComponent,
    AxleThreeDualComponent,
    AxleFourSimpleComponent,
    AxleFourDualComponent,
    AxleFiveDualComponent,
    AxleFiveSimpleComponent,
    AxleSixSimpleComponent,
    AxleSixDualComponent,
    AxleSevenDualComponent,
    AxleSevenSimpleComponent,
    GeneralFilterComponent,
    PneuPosicaoComponent,
    PneuPosicaoViewComponent,
    AxleEightSimpleComponent,
    AxleEightDualComponent,
    TransporterComponent,
    CreateTransporterComponent,
    EditTransporterComponent,
    CloneTransporterComponent,
    ViewTransporterComponent,
    SteppeComponent,
    AxleNineSimpleComponent,
    AxleNineDualComponent,
    DirectionalTwoComponent,
    SteppeViewComponent,
    DirectionalViewComponent,
    DirectionalTwoViewComponent,
    AxleTwoSimpleViewComponent,
    AxleTwoDualViewComponent,
    AxleThreeSimpleViewComponent,
    AxleThreeDualViewComponent,
    AxleSixSimpleViewComponent,
    AxleSixDualViewComponent,
    AxleSevenSimpleViewComponent,
    AxleSevenDualViewComponent,
    AxleOneSimpleViewComponent,
    AxleOneDualViewComponent,
    AxleNineSimpleViewComponent,
    AxleNineDualViewComponent,
    AxleFourSimpleViewComponent,
    AxleFourDualViewComponent,
    AxleFiveSimpleViewComponent,
    AxleFiveDualViewComponent,
    AxleEightSimpleViewComponent,
    AxleEightDualViewComponent,
    ReportOperationalAsyncComponent,
    ReportAsyncDetailComponent,
    ReportOperationalAsyncEditComponent,
    ReportAsyncViewComponent,
    AnalyzeReportOperatorComponent,
    CreateAnalysisComponent,
    TreatAnalysisComponent,
    ManagerAnalysisComponent,
    ManagerTreatmentComponent,
    EditAnalysisComponent,
    ViewReportOperatorComponent,
    SupervisorAnalysisComponent,
    SupervisorViewTreatComponent,
    RejectionSupervisorComponent,
    AnalyzeReportHistoricComponent,
    ViewTreatAnalysisComponent,
    TreatAnalysisSelectComponent,
    NewAnalysisComponent,
    AppUserSelectorComponent,
    TreatAnalysisEditComponent,
    PenaltyFormComponent,
    FinalizeAnalysisComponent,
    MonthlyReportComponent,
    MonthlyReportAllComponent,
    ManagerComponent,
    SupervisorComponent,
    CreateManagerComponent,
    CreateSupervisorComponent,
    CambusComponent,
    CompanySupervisorComponent,
    CompanyManagerComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    SkeletonWModule,
    StyleModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    NgbModule,
    AppRoutingModule,
    ChartsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    NgxSpinnerModule,
    AgmOverlays,
    ToastModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiGeoLocateKey,
      libraries: ["drawing", "places", "geometry"],
    }),
    AgmDirectionModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      multi: true,
      deps: [StartAppService],
    },
    MessageService,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    MapToolboxService,
    GoogleMapsAPIWrapper,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    StreetviewComponent,
    RouteComponent,
    ElectronicFenceComponent,
    FindVehiclesComponent,
    DistanceDialogComponent,
    GeneralFilterComponent,
  ],
})
export class AppModule { }
