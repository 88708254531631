import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { HttpUtil } from "../_util/http-util";
import { BehaviorSubject, Observable, Subject, interval } from "rxjs";
import { environment } from "../../environments/environment";
import { GatewayCredentials } from "../models/gateway/gateway-credentials.model";
import { AccessTokenResponse } from "../models/gateway/access-token-response.model";
import { AccessTokenRequest } from "../models/gateway/access-token-request.model";
import { GrantCodeRequest } from "../models/gateway/grant-code-request.model";
import storageX from "../storageCore/storageX";
import {
  STORAGE_KEY_ACCESS_TOKEN_WSO2,
  STORAGE_KEY_UUID,
} from "../storageCore/constStorageKeys";
import getBrowserFingerprint from "get-browser-fingerprint";

@Injectable({
  providedIn: "root",
})
export class OauthTokenService {
  private clientId = environment.apisConfig.gateway.clientId;
  private clientSecret = environment.apisConfig.gateway.clientSecret;

  constructor(private http: HttpClient, private httpUtil: HttpUtil) {}

  public getGatewayCredentials(): Observable<GatewayCredentials> {
    const subject: Subject<GatewayCredentials> =
      new BehaviorSubject<GatewayCredentials>(Object());

    subject.next(new GatewayCredentials(this.clientId, this.clientSecret));

    return subject.asObservable();
  }

  public generateGrantToken(): Observable<AccessTokenResponse> {
    this.cleanToken();
    const uri = "/token";
    const grantCodeRequest = new GrantCodeRequest();
    const fingerprint = getBrowserFingerprint();
    console.log(fingerprint);
    let headers = this.generateHeadersOauth();
    console.log(headers);
    headers = headers.append("Content-Type", "application/json");

    console.log(storageX.whereKeyIs(STORAGE_KEY_UUID).get());

    grantCodeRequest.grant_type = "client_credentials";
    grantCodeRequest.scope =
      "devices_" +
      fingerprint +
      "-" +
      storageX.whereKeyIs(STORAGE_KEY_UUID).get();

    return this.http.post<AccessTokenResponse>(
      this.httpUtil.buildUrlOauth(uri),
      grantCodeRequest,
      { headers }
    );
  }

  public cleanToken(): void {
    storageX.whereKeyIs("access-token").remove();
    console.log(storageX);
  }

  public generateAccessToken(
    accessTokenRequest: AccessTokenRequest
  ): Observable<AccessTokenResponse> {
    const uri = "/access-token";
    let headers = this.generateHeadersOauth();
    headers = headers.append(
      "Content-type",
      "application/x-www-form-urlencoded"
    );

    let params = new HttpParams();
    params = params.append("grant_type", accessTokenRequest.grantType);
    params = params.append("code", accessTokenRequest.code);

    return this.http.post<AccessTokenResponse>(
      this.httpUtil.buildUrlOauth(uri),
      null,
      { headers, params }
    );
  }

  public refreshToken(
    accessTokenRequest: AccessTokenRequest
  ): Observable<AccessTokenResponse> {
    const uri = "/access-token";
    let headers = this.generateHeadersOauth();
    headers = headers.append(
      "Content-type",
      "application/x-www-form-urlencoded"
    );

    let params = new HttpParams();
    params = params.append("grant_type", accessTokenRequest.grantType);
    params = params.append("code", accessTokenRequest.code);

    var response = this.http.post<AccessTokenResponse>(
      this.httpUtil.buildUrlOauth(uri),
      null,
      { headers, params }
    );

    storageX
      .whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2)
      .set(response.subscribe((a) => a.access_token));

    return response;
  }

  private generateHeadersOauth(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Basic " + btoa(this.clientId + ":" + this.clientSecret)
    );
    return headers;
  }
}
