import { Injectable } from "@angular/core";
import { HateoasBaseService } from "./hateoas-base.service";
import { HttpUtil } from "../utils/http-util";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { PageEvent } from "@angular/material/paginator";
import { ReportTypeEngineModelPaginated } from "../models/report-type-engine-model-paginated";

import { Observable } from "rxjs";
import { ReportEngineModel } from "../models/report-engine-model";
import { toSnake } from "snake-camel";

@Injectable({
  providedIn: "root",
})
export class ReportEngineService extends HateoasBaseService {
  // tslint:disable-next-line:variable-name
  private _httpUtil: HttpUtil;
  private resourceNameReportType = "report-type";
  private resourceNameReport = "report";

  constructor(http: HttpClient, httpUtil: HttpUtil) {
    super(http, httpUtil);
    this._httpUtil = httpUtil;
  }

  public getAllReportType(): Observable<ReportTypeEngineModelPaginated> {
    //TODO - Deixar essa paginação mais dinamica
    const pageEvent = new PageEvent();
    pageEvent.pageIndex = 0;
    pageEvent.pageSize = 50;

    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(pageEvent, direction);

    return this.executeActionOnResource(
      this.resourceNameReportType,
      "get-all",
      null,
      null,
      params
    );
  }

  public getAllReportMonthly(
    page: PageEvent,
    name: string,
    field: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && name !== null && field.length > 0) {
      params.set("field", field);
    }


    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-all-report-monthly",
      null,
      null,
      params
    );
  }

  public getAllReport(
    page: PageEvent,
    requestOperator: string,
    name: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    params.set("requestOperator", requestOperator);

    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-all-reports",
      null,
      null,
      params
    );
  }

  public getByUuidReport(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-by-uuid",
      null,
      null,
      params
    );
  }

  public updateReport(
    reportEnquine: ReportEngineModel,
    uuid: string
  ): Observable<any> {
    const reportEnquineSnake = toSnake(reportEnquine);
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "process-report-update",
      null,
      reportEnquineSnake,
      params
    );
  }

  public saveReport(reportEnquine: ReportEngineModel): Observable<any> {
    let request = toSnake(reportEnquine);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "save",
      null,
      request,
      null
    );
  }

  public createReport(reportEnquine: ReportEngineModel): Observable<any> {
    let request = toSnake(reportEnquine);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "process-report",
      null,
      request,
      null
    );
  }

  public deleteReport(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "delete-by-uuid",
      null,
      null,
      params
    );
  }

  public exportReport(uuidMinio: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuidMinio", uuidMinio);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-file-minio",
      null,
      null,
      params
    );
  }

  public exportJsonReport(uuidMinio: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuidMinio", uuidMinio);
    return this.executeActionOnResource(
      this.resourceNameReport,
      "get-file-minio-json",
      null,
      null,
      params
    );
  }

  protected getApiBaseUrl(): string {
    return environment.apisConfig.seteraReportEngine.apiBaseUrl;
  }

  protected getMicroServiceBaseUrl(): string {
    return environment.apisConfig.seteraReportEngine.microServiceBaseUrl;
  }
}
