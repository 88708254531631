import { Validators } from '@angular/forms';

export class Validation {
    public rules = (): object => {
        return {
            modulo: ['', Validators.required],
            funcionalidadeTipo: ['', Validators.required],
            nmFuncionalidade: ['', Validators.required],
            dsFuncionalidade: ['', Validators.required],
            cdPath: [''],
            cdFuncionalidade: [''],
            sgFuncionalidade: [''],
        };
    };
}
