
export class Address{
  street
  number
  neighborhood
  city
  state
  postalCode
}

export const BUILD_LINK_GOOGLE_MAPS = function (addres: Address){
  if(addres.postalCode){
    addres.postalCode = addres.postalCode.replace(/\D+/g, "");
  }
  window.open(`${'https://www.google.com/maps/place'}/${addres.street},${addres.number} - ${addres.neighborhood} - ${addres.city} - ${addres.state},${addres.postalCode},674m/data=!3m1!1e3!4m5!3m4!1s0x0:0x0!8m2!3d!4d`)
}
