import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { TerminalAlarme } from "../../models/TerminalAlarme.model";
import { ApiService } from "../../services/api.service";
import { Validation } from "./validation";

@Component({
  selector: "app-create-terminal-alarme",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ViewTerminalAlarmeComponent implements OnInit {
  public terminalalarmeForm: FormGroup;
  public terminalalarmeSelected;
  public terminalalarme: TerminalAlarme;
  public terminalalarmeId: number;
  public title = "Visualizar Eventos";
  public errorMessage;
  private service: ApiService;
  private validation: Validation;

  ngOnInit(): void {
    this.terminalalarmeForm = this.formBuilder.group(this.validation.rules());
    this.terminalalarmeId = this.activatedRoute.snapshot.params["id"];
    this.loadTerminalAlarme(this.terminalalarmeId);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.service = apiService;
    this.validation = new Validation();
  }

  fillUserData(): void {
    const fields = [
      "cdAtributo1",
      "cdAtributo2",
      "cdCodigo",
      "nmNomeIconeCss",
      "dsMensagem",
      "ativo",
      "flMarkerRelatorioGerencial",
      "flExibePainelEvento",
      "cdCor",
      "nmFator",
      "nrOrdemPrioridade",
      "dsProcedimentoOperador",
    ];
    fields.forEach((element) => {
      this.terminalalarmeForm.controls[element].setValue(
        this.terminalalarmeSelected[element]
      );
    });
  }

  public async loadTerminalAlarme(id): Promise<void> {
    try {
      const response = await this.service.get<TerminalAlarme>({
        url: `terminal-alarme/${id}`,
      });
      this.terminalalarmeSelected = response as TerminalAlarme;
      this.fillUserData();
    } catch (error) {
      console.error(error);
    }
  }
}
