<div class="toolbox_distance"
     *ngIf="this.permissionService.hasPermission('DISTANCIA_BOTAO_FUNCAO', this.permissions)">
  <mat-button-toggle-group
    name="distance"
    [disabled]="disabled"
    [selected]="selected"
    (click)="onChangeValue()"
  >
    <mat-button-toggle
      value="calc_distance"
      [ngClass]="{ btnSelected: !!disabled }"
    >
      <mat-icon>architecture</mat-icon>
      <span class="btn-icon-text">Distância</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
