import { STORAGE_KEY_ID_PERFIL } from "./../../storageCore/constStorageKeys";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EventAlarmService } from "../../services/EventAlarm.service";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { Page } from "src/app/utils/paginate";
import * as FileSaver from "file-saver";

import { CockpitService } from "../cockpit/cockpit.service";
import storageX from "src/app/storageCore/storageX";
import { NgxSpinnerService } from "ngx-spinner";
import { DriverReportMapComponent } from "../driver-report/driver-report-map.component";

@Component({
  selector: "status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss"],
})
export class StatusComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public resultadoAnalitico = new Array<any>();

  public errorMessage;
  public length = 0;
  public page = new Page();
  public interval;
  public events = new Array<any>();
  private service: EventAlarmService;
  public status: string;
  public items;
  id: any;

  constructor(
    service: EventAlarmService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public cockpitService: CockpitService
  ) {
    this.service = service;
  }

  ngOnInit(): void {
    this.page.size = 10;
    this.page.currentPage = 0;
    this.status = this.activatedRoute.snapshot.params.status;
    this.loadMenuItems();
    this.loadData();
  }

  public async loadData(): Promise<void> {
    this.spinner.show();
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
    if (!perfil) {
      throw new Error("Nenhum perfil encontrado");
    }

    if (this.status === "0") {
      this.cockpitService.getTerminaisOffline(perfil).subscribe((result) => {
        result.forEach((element) => {
          element.dataAtualizacao = new String(
            "'" + element.dataAtualizacao + "'"
          );
          element.dataOnline = new String("'" + element.dataOnline + "'");
        });
        this.resultadoAnalitico = result;
        this.spinner.hide();
      });
    }

    if (this.status === "1") {
      this.cockpitService.getTerminaisOnline(perfil).subscribe((result) => {
        result.forEach((element) => {
          element.dataAtualizacao = new String(
            "'" + element.dataAtualizacao + "'"
          );
          element.dataOnline = new String("'" + element.dataOnline + "'");
        });
        this.resultadoAnalitico = result;
        this.spinner.hide();
      });
    }
  }

  public openMap(lat, long) {
    const dialogRef = this.dialog.open(DriverReportMapComponent, {
      width: "450px",
      height: "450px",
      data: {
        lat: lat,
        lng: long,
      },
    });
  }

  private loadMenuItems() {
    this.items = [
      {
        label: "Exportar",
        items: [
          {
            label: "Excel",
            icon: "pi pi-file-excel",
            command: () => {
              this.exportExcel();
            },
          },
          {
            label: "PDF",
            icon: "pi pi-file-pdf",
            command: () => {
              this.exportPdf();
            },
          },
        ],
      },
    ];
  }

  exportPdf() {
    if (this.resultadoAnalitico.length > 0) {
      import("jspdf").then((jsPDF) => {
        import("jspdf-autotable").then((x) => {
          const doc = new jsPDF.default(0, 0);
          doc.autoTable({
            startY: 10,
            html: ".tableAnalitico",
            useCss: true,
          });
          if (this.status === "0") {
            doc.save(`relatorio_analitico_status_offline.pdf`);
          } else {
            doc.save(`relatorio_analitico_status_online.pdf`);
          }
        });
      });
    }
  }

  exportExcel() {
    if (this.resultadoAnalitico.length > 0) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.table_to_sheet(
          document.getElementsByClassName("tableAnalitico")[0]
        );
        xlsx.utils.sheet_to_json(worksheet, {
          raw: true,
        });

        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };

        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        if (this.status === "0") {
          this.saveAsExcelFile(
            excelBuffer,
            `relatorio_analitico_status_offline`
          );
        } else {
          this.saveAsExcelFile(
            excelBuffer,
            `relatorio_analitico_status_online`
          );
        }
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    console.log(buffer);
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  // private loadMenuItems() {
  //   this.items = [
  //     {
  //       label: "Exportar",
  //       items: [
  //         {
  //           label: "Excel",
  //           icon: "pi pi-file-excel",
  //           command: () => {
  //             this.exportExcel();
  //           },
  //         },
  //         {
  //           label: "PDF",
  //           icon: "pi pi-file-pdf",
  //           command: () => {
  //             this.exportPdf();
  //           },
  //         },
  //       ],
  //     },
  //   ];
  // }

  // exportPdf() {
  //   if (this.listResultado != null) {
  //     import("jspdf").then((jsPDF) => {
  //       import("jspdf-autotable").then((x) => {
  //         const doc = new jsPDF.default(0, 0);
  //         doc.autoTable({
  //           startY: 10,
  //           html: document.getElementById("table"),
  //           useCss: true,
  //         });
  //         if (this.status === "0") {
  //           doc.save("painel_eventos_offline.pdf");
  //         }

  //         if (this.status === "1") {
  //           doc.save("painel_eventos_online.pdf");
  //         }
  //       });
  //     });
  //   }
  // }

  // exportExcel() {
  //   if (this.listResultado != null) {
  //     let element = document.getElementById("table");
  //     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  //     if (this.status === "0") {
  //       XLSX.writeFile(wb, "painel_eventos_offline.xlsx");
  //     }

  //     if (this.status === "1") {
  //       XLSX.writeFile(wb, "painel_eventos_online.xlsx");
  //     }
  //   }
  // }
}
