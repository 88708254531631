import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { MinioService } from "src/app/services/minio.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-analyze-report-historic",
  templateUrl: "./analyze-report-historic.component.html",
  styleUrls: ["./analyze-report-historic.component.scss"],
})
export class AnalyzeReportHistoricComponent implements OnInit {
  public errorMessage;
  public analyzeReportHistoricPage: any;
  public analyzesManagers: MatTableDataSource<any> =
    new MatTableDataSource<any>();
  public tableColumns: string[];
  public actualPage: PageEvent;
  public permissions: [];
  public typeFieldSelect = [
    { name: "Número Pacote", value: "PACKAGE" },
    { name: "Motorista", value: "DRIVER" },
    { name: "Placa", value: "PLATE" },
    { name: "Base Opera.", value: "BASE" },
  ];

  public filter = "";
  public field = "";
  public profile = "";
  public typeFieldFilterSelected;
  private intervalId: any;

  constructor(
    private spinner: NgxSpinnerService,
    public router: Router,
    private analyzeTripService: AnalyzeTripService,
    private minioService: MinioService,
    public dialog: MatDialog,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.profile = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();

    this.typeFieldFilterSelected = this.typeFieldSelect[0].value;
    this.field = this.typeFieldFilterSelected;
    this.initialPage();
    this.loadReports(this.actualPage, null, null);
    this.loadTableDefinitions();
    this.startCallingLoadReports();
  }

  ngOnDestroy(): void {
    this.stopCallingLoadReports();
  }

  private startCallingLoadReports(): void {
    console.log("Data atual:", new Date());
    this.intervalId = setInterval(() => {
      this.loadReports(this.actualPage, this.filter, this.field);
    }, 30000); // Chama o método loadReports a cada 30 segundos
  }

  private stopCallingLoadReports(): void {
    clearInterval(this.intervalId); // Para de chamar loadReports
  }

  private loadReports(page: PageEvent, name: string, field: string): void {
    this.spinner.show();

    this.analyzeTripService
      .getAllAnalyzeReportHistoric(this.profile, page, name, field)
      .subscribe(
        (t) => {
          this.analyzeReportHistoricPage = t;
          this.analyzesManagers = new MatTableDataSource<any>(
            this.analyzeReportHistoricPage.content
          );
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter as analises do operador");
          this.spinner.hide();
        }
      );
  }

  applyFilter(): void {
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  fieldFilter(): void {
    this.field = this.typeFieldFilterSelected;
    this.filter = "";
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "packageNumber",
      "dateStart",
      "dateEnd",
      "operationalBase",
      "companyDestiny",
      "driverName",
      "plate",
      "OperadorName",
      "filePdfAnalytical",
      "filePdfSynthetic",
      "operatorHandling",
      "managerName",
      "supervisorName",
      "treatManagerName",
      "descriptionManager",
    ];
  }

  exportReport(element): void {
    this.minioService.exportReport(element).subscribe();
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  openToTreatment(uuid: string) {
    const url = "/analise-supervisor/tratamento-operador/" + uuid;
    window.open(url, "_blank");
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }

}
