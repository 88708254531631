<div
  class="alert-danger"
  *ngIf="
    !form.controls[controlName].valid && form.controls[controlName].touched
  "
>
  <div [hidden]="!form.controls[controlName].errors.required">
    O campo {{ controlLabel ? controlLabel : controlName }} é obrigatório
  </div>

  <!-- {{ form.controls[controlName].errors | json }} -->

  <div
    [hidden]="!form.controls[controlName].errors.minlength"
    *ngIf="form.controls[controlName].errors.minlength != undefined"
  >
    O campo {{ controlLabel ? controlLabel : controlName }} precisa ter
    {{ form.controls[controlName].errors.minlength.requiredLength }}
    caracteres
  </div>

  <div
    [hidden]="!form.controls[controlName].errors.maxlength"
    *ngIf="form.controls[controlName].errors.maxlength != undefined"
  >
    O campo {{ controlLabel ? controlLabel : controlName }} precisa ter no
    máximo
    {{ form.controls[controlName].errors.maxlength.requiredLength }}
    caracteres
  </div>

  <div
          [hidden]="!form.controls[controlName].errors.cpfNotValid"
          *ngIf="form.controls[controlName].errors.cpfNotValid != undefined"
  >
    O CPF informado é inválido
  </div>
</div>
