<div class="row align-items-center">
  <div class="col-12 my-2">
    <h1>
      <a
        class="btn btn-voltar text-center mr-2"
        href="javascript:history.back()"
        data-title="Voltar"
        ><i class="fas fa-arrow-left"></i
      ></a>
      <ng-content></ng-content>
    </h1>
  </div>
</div>
