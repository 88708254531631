import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { EventAlarm } from '../models/EventAlarm.model';
import { ContentInterface } from './../contracts/Content.interface';
import { STORAGE_KEY_ID_PERFIL } from '../storageCore/constStorageKeys';
import storageX from '../storageCore/storageX';

@Injectable({ providedIn: 'root' })
export class EventAlarmService extends ApiService {
  async list(): Promise<EventAlarm[]> {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();//localStorage.getItem('idPerfil');
    try {
      const result = await this.get<ContentInterface[]>({
        url: 'evento-alarme/perfil/' + perfil + '?flFinalizado=false&size=2000',
      });

      const CONTENT = 'content';
      const events = result[CONTENT];
      if (!events) {
        Promise.reject(Error('Erro ao listar Event-Alarm'));
      }

      return events;
    } catch (error) {
      console.error('me', error);
      return null;
    }
  }

  async amount(): Promise<any[]> {
    try {
      const result = await this.get<ContentInterface[]>({
        url: 'v2/evento-alarme/amount/?status=progress',
      });

      const CONTENT = 'content';
      const events = result;
      if (!events) {
        Promise.reject(Error('Erro ao listar Event-Alarm'));
      }

      return events;
    } catch (error) {
      console.error('me', error);
      return null;
    }
  }

  async listByTerminalId(
    id: number,
    pageIndex = 0,
    pageSize = 10
  ): Promise<any> {
    const params = {
      size: pageSize,
    };
    if (pageIndex) {
      params['page'] = pageIndex;
    }
    try {
      const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();//localStorage.getItem('idPerfil');
      const result = await this.get<any>({
        url:
          'v2/evento-alarme/' +
          id +
          '?status=progress&size=' + pageSize + '&page=' + pageIndex,
      });

      const CONTENT = 'content';
      const events = result;
      if (!events) {
        Promise.reject(Error('Erro ao buscar por TerminalId'));
      }

      return events;
    } catch (error) {
      console.error('me', error);
      return null;
    }
  }

  async atualizar(eventAlarm: EventAlarm) {
    await this.put(eventAlarm.id, 'evento-alarme', eventAlarm);
  }
}
