<div class="main-page consultas">
    <div class="container-fluid">
        <!-- LINHA TITULO + PESQUISA -->
        <div fxLayout fxLayoutAlign="center center">
            <mat-form-field fxFlex="40%" class="ml-2">
             <mat-label>Filtrar por Nome</mat-label>
             <input matInput autocomplete="off"
             (keyup)="this.applyFilter($event)" style="text-transform: uppercase;" placeholder="" #input>
           </mat-form-field>
        </div>  

        <div class="row no-gutters mt-3" *ngIf="eletronicFences.data.length > 0">
            <div class="mat-elevation table-content">                
                <table mat-table [dataSource]="eletronicFences" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                    </ng-container>

                    <ng-container matColumnDef="nmNome">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Nome
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.nmNome }}
                        </td>
                    </ng-container>                  
                   
                    <ng-container matColumnDef="cercaEletronicaLocalProcessamento.nmNome">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Process. 
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.cercaEletronicaLocalProcessamento.nmNome }}
                        </td>
                    </ng-container> 


                    <ng-container matColumnDef="cercaEletronicaTipo.nmNome">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Tipo 
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.cercaEletronicaTipo.nmNome }}
                        </td>
                    </ng-container> 

                    <ng-container matColumnDef="nrVelocidadeAlarmeVeiculo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Vel. Alerta Sonoro 
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.cercaEletronicaLocalProcessamento.id != 2 ? element.nrVelocidadeAlarmeVeiculo: "" }}
                        </td>
                    </ng-container> 

                    <ng-container matColumnDef="nrVelocidadeAlarmePainel">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Vel. Evento Plataforma 
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.nrVelocidadeAlarmePainel }}
                        </td>
                    </ng-container> 

                    <ng-container matColumnDef="sendConfig">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                          <span class="alerta mr-2" data-title="Enviar dados da Cerca Eletrônica ao Terminal"
                            *ngIf="element.cercaEletronicaLocalProcessamento.id == 1"           
                            matTooltip="Enviar dados da Cerca Eletrônica ao Terminal">
                            <a (click)="handSendConfig(element.id)" class="btn btn-link">
                              <img height="40" src="assets/img/icon-configurar.svg" alt="" />      
                            </a>
                          </span>
                        </td>
                      </ng-container>
            
                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Visualizar" matTooltip="Visualizar">
                                <a
                                (click)="this.router.navigate(['/electronic-fence-view/' + element.id])"
                                class="btn btn-link"
                                >
                                <img height="20" src="assets/img/view.svg" alt="" />
                                </a>
                            </span>
                            
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="update">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Editar"
                                matTooltip="Editar">
                                <a (click)="this.router.navigate(['/eletronic-fences/edit/' + element.id])" class="btn btn-link">
                                    <img height="20" src="assets/img/edit.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="handleDelete(element.id)" class="btn btn-link"
                                matTooltip="Apagar">
                                <img height="20" src="assets/img/trash-cadastro.svg" alt="" />
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 40, 100]" showFirstLastButtons></mat-paginator>

            </div>
        </div>
    </div>
</div>
