<div class="toolbox_path" *ngIf="this.permissionService.hasPermission('TRAJETO_BOTAO_FUNCAO', this.permissions)">
  <mat-button-toggle-group
    name="button_path"
    [disabled]="disabled"
    [selected]="selected"
    (click)="onChangeValue()"
  >
    <mat-button-toggle
      value="button_path"
      [ngClass]="{ btnSelected: selected && !disabled }"
    >
      <mat-icon>edit_road</mat-icon>
      <span class="btn-icon-text">Trajeto</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
