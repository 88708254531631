import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { FuncionalidadeTipo } from "../../models/FuncionalidadeTipo.model";
import { Validation } from "./validation";
import { NgxSpinnerService } from "ngx-spinner";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-create-funcionalidade",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CloneFuncionalidadeTipoComponent implements OnInit {
  public funcionalidadeTipoForm: FormGroup;
  public funcionalidadeTipo: FuncionalidadeTipo;
  public funcionalidadeTipoId: number;
  public title = "Duplicar Tipo de Funcionalidade";
  public errorMessage;
  private validation: Validation;
  private apiBaseService: ApiBaseService;

  ngOnInit(): void {
    this.funcionalidadeTipoForm = this.formBuilder.group(
      this.validation.rules()
    );
    this.funcionalidadeTipoId = this.activatedRoute.snapshot.params["id"];
    this.loadFuncionalidadeTipo(this.funcionalidadeTipoId);
  }
  public async loadFuncionalidadeTipo(id): Promise<void> {
    try {
      this.spinner.show();

      const response = await this.apiBaseService.get<FuncionalidadeTipo>({
        url: new FuncionalidadeTipo()["nomeConsultaApiBase"] + "/" + id,
      });

      this.funcionalidadeTipo = response as FuncionalidadeTipo;
      this.fillUserData();
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }
  fillUserData(): void {
    const fields = ["nmFuncionalidadeTipo", "dsFuncionalidadeTipo"];
    fields.forEach((element) => {
      this.funcionalidadeTipoForm.controls[element].setValue(
        this.funcionalidadeTipo[element]
      );
    });
  }

  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }

  public async submitForm(): Promise<void> {
    try {
      this.spinner.show();
      this.coverterUpperCase(this.funcionalidadeTipoForm);

      const values = this.funcionalidadeTipoForm.value;
      const post = {
        ...values,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      await this.apiBaseService.post<FuncionalidadeTipo>(
        new FuncionalidadeTipo()["nomeConsultaApiBase"],
        post
      );

      this.spinner.hide();

      this.router.navigate(["/funcionalidade-tipo"]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public coverterUpperCase(funcionalidadeTipoForm: FormGroup) {
    console.log(funcionalidadeTipoForm);

    funcionalidadeTipoForm
      .get("nmFuncionalidadeTipo")
      .setValue(
        funcionalidadeTipoForm.get("nmFuncionalidadeTipo").value.toUpperCase()
      );
    funcionalidadeTipoForm
      .get("dsFuncionalidadeTipo")
      .setValue(
        funcionalidadeTipoForm.get("dsFuncionalidadeTipo").value.toUpperCase()
      );
  }
}
