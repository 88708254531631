<div class="main-page consultas" >
    <div class="container-fluid"#printContent>
        <div class="title-container">
            <div class="title-circle">
                <h3>COMUNICAÇÃO INTERNA</h3>
            </div>
        </div>
        <div class="title-container">
            <div class="subtitle-circle">
                <p>NÃO SE ACEITAM INSTRUÇÕES VERBAIS</p>
            </div>
        </div>
        <div class="paragraph-container">
            <p>PARA: {{nameManager}}</p>
            <p>SEÇÃO: TRANSPORTE</p>
        </div>
        <div class="paragraph-container">
            <p>DE: {{nameSupervisor}}</p>
            <p>{{currentDate}}</p>
        </div>
        <div class="paragraph-description-container">
            <p>CORTE REFERENTE A INFRAÇÕES DE: {{infractions}}</p>
            <b>MOTORISTA : {{nameDriver}}</b>
            <p>
                <b>PACOTE : {{namePackage}}</b>
            </p>
            <p>
                {{operatorSummary}}
            </p>
            <br>
            <br>
            <br>
            <b>CORTE: </b>{{managerTreatment}}
            <br>
            <br>
            <p>ASSINATURA RESPONSAVEL:</p>
        </div>
    </div>
    <button (click)="print()" style="background-color: #3f51b5;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 2%;">Imprimir</button>
</div>


