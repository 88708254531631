import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Modulo } from "../../models/Modulo.model";
import { Validation } from "./validation";
import { NgxSpinnerService } from "ngx-spinner";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-create-modulo",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class EditModuloComponent implements OnInit {
  public moduloForm: FormGroup;
  public modulo: Modulo;
  public moduloId: number;
  public title = "Atualizar Módulo";
  public errorMessage;
  private validation: Validation;
  private apiBaseService: ApiBaseService;

  ngOnInit(): void {
    this.moduloForm = this.formBuilder.group(this.validation.rules());
    this.moduloId = this.activatedRoute.snapshot.params["id"];
    this.loadModulo(this.moduloId);
  }
  public async loadModulo(id): Promise<void> {
    try {
      this.spinner.show();

      const response = await this.apiBaseService.get<Modulo>({
        url: new Modulo()["nomeConsultaApiBase"] + "/" + id,
      });

      this.modulo = response as Modulo;
      this.fillUserData();

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }
  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }
  fillUserData(): void {
    const fields = ["nmModulo", "dsModulo", "cdPath", "cdIcone"];

    fields.forEach((element) => {
      this.moduloForm.controls[element].setValue(this.modulo[element]);
    });
  }
  public async submitForm(): Promise<void> {
    try {
      this.spinner.show();
      this.coverterUpperCase(this.moduloForm);

      const values = this.moduloForm.value;
      const id = Math.floor(this.moduloId);
      const post = {
        id,
        ...values,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      await this.apiBaseService.put<Modulo>(
        id,
        new Modulo()["nomeConsultaApiBase"],
        post
      );

      this.router.navigate(["/modulo"]);

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public coverterUpperCase(moduloForm: FormGroup) {
    console.log(moduloForm);

    moduloForm
      .get("nmModulo")
      .setValue(moduloForm.get("nmModulo").value.toUpperCase());
    moduloForm
      .get("dsModulo")
      .setValue(moduloForm.get("dsModulo").value.toUpperCase());
    moduloForm
      .get("cdPath")
      .setValue(moduloForm.get("cdPath").value.toUpperCase());
    moduloForm
      .get("cdIcone")
      .setValue(moduloForm.get("cdIcone").value.toUpperCase());
  }
}
