<div class="main-page cadastros journey-main">
    <div class="container-fluid"> 
        <div class="row align-items-center">
            <div class="col-12 my-2">
              <h1>Visualização Relatório Operacional do Motorista</h1>
            </div>
        </div>         
        <!-- COMECA -->        
        <p-table #journeyheader [value]="this.analyticalReport[0]" tableStyleClass="tableAnalitico">    
            <ng-template pTemplate="header" let-columns>            
                <tr [hidden]="this.analyticalReport[0].length == 0" [ngClass]="this.filterDialog == true ? '' : 'sticky-top'" >
                    <th style="text-align:center !important;border:none">Placa</th>
                    <th style="text-align:center !important;border:none">Frota</th>
                    <th style="text-align:center !important;border:none">Motorista</th>
                    <th style="text-align:center !important;border:none">Data</th>
                    <th style="text-align:center !important;border:none">Evento</th>
                    <th style="text-align:center !important;border:none">Valor do Evento</th>                    
                    <th style="text-align:center !important;border:none">Mapa</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowdata>          
                <tr>
                    <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["placa"]}}</td>
                    <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["frota"]}}</td>
                    <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["motoristaNome"]}}</td>
                    <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["dataTexto"]}}</td>
                    <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["alarmeTexto"]}}</td>
                    <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["valorEvento"]}}</td>
                    <td style="text-align:center !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">                   
                        <span class="alerta mr-2" data-title="Visualizar" matTooltip="Visualizar">
                            <a class="btn btn-link">
                                <img (click)="this.openMap(rowdata['latitude'], rowdata['longitude'])" height="15" src="assets/img/view.svg" alt="" />
                            </a>
                        </span>
                    </td>                   
                </tr>                 
            </ng-template>
        </p-table>
        <!-- FIM-->                  
    </div>
</div>