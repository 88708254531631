import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  STORAGE_KEY_ID_MANAGER,
  STORAGE_KEY_ID_SUPERVISOR,
  STORAGE_KEY_UUID_MANAGER,
  STORAGE_KEY_UUID_SUPERVISOR,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";

@Component({
  selector: "app-app-user-selector",
  templateUrl: "./app-user-selector.component.html",
  styleUrls: ["./app-user-selector.component.scss"],
})
export class AppUserSelectorComponent {
  loader = false;
  perfils: string[] = ["SUPERVISOR", "GESTOR"];

  constructor(public dialogRef: MatDialogRef<any>) {}

  async onPerfilClick(userPerfil): Promise<void> {
    try {
      if (userPerfil == "SUPERVISOR") {
        storageX.whereKeyIs(STORAGE_KEY_ID_MANAGER).set("");
        storageX.whereKeyIs(STORAGE_KEY_UUID_MANAGER).set("");
      }

      if (userPerfil == "GESTOR") {
        storageX.whereKeyIs(STORAGE_KEY_ID_SUPERVISOR).set("");
        storageX.whereKeyIs(STORAGE_KEY_UUID_SUPERVISOR).set("");
      }

      this.close();
    } catch (error) {
      console.log("ocorreu um erro onPerfilClick()", error);
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
