import { Validators } from '@angular/forms';

export class Validation {
  public rules = (): object => {
    return {
      cdUsuario: ['', Validators.required],
      nmUsuario: ['', Validators.required],
      dsEmail: ['', Validators.required],
      nrMatricula: [0],
      cdSenha: ['', Validators.required],
      generalFilterPermission: ['']
    };
  }
}
