<mat-dialog-content class="mat-typography" style="max-height: none;">
  <div class="container">
    <h2>Localizar Veículos</h2>

    <div *ngIf="retornoEntidades == null || retornoEntidades?.data?.length === 0">
      <div class="row no-gutters mt-3">
        <p>Nenhum registro at&eacute; o momento...</p>
      </div>
    </div>

    <div class="row no-gutters mt-3" *ngIf="retornoEntidades?.data?.length > 0">
      <div class="mat-elevation table-content">
        <div fxLayout fxLayoutAlign="center center">
          <mat-form-field fxFlex="40%" class="ml-2">
            <mat-label>Filtrar</mat-label>
            <input matInput autocomplete="off" (keyup)="this.applyFilter($event)" placeholder="" #input>
          </mat-form-field>
        </div>

        <table mat-table [dataSource]="this.retornoEntidades" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef style="padding: 5px;">
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" style="padding: 5px;">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="placa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Placa</th>
            <td mat-cell *matCellDef="let element" style="padding: 5px;">{{ element["placa"] }}</td>
          </ng-container>
          <ng-container matColumnDef="nomeEmpresa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Empresa</th>
            <td mat-cell *matCellDef="let element" style="padding: 5px;">{{ element["nome_empresa"] }}</td>
          </ng-container>
          <ng-container matColumnDef="nmMotorista">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Motorista</th>
            <td mat-cell *matCellDef="let element" style="padding: 5px;">{{ element["nome_motorista"] }}</td>
          </ng-container>
          <ng-container matColumnDef="dataOnline">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Online</th>
            <td mat-cell *matCellDef="let element" style="padding: 5px;">{{ element["data_online"]}}</td>
          </ng-container>
          <ng-container matColumnDef="localizacao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Localização</th>
            <td mat-cell *matCellDef="let element" style="padding: 5px;">{{ element["localizacao"] }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 40, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Fechar</button>
  <button mat-button (click)="localizar()">Localizar</button>
</mat-dialog-actions>
