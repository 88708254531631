/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorHandler, Injectable } from "@angular/core";
import axios, { AxiosInstance } from "axios";
import { STORAGE_KEY_ACCESS_TOKEN_WSO2 } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { environment } from "src/environments/environment";

export interface Params {
  [key: string]: any;
}

export interface GetOptions {
  url: string;
  params?: any;
}

export interface PostOptions {
  url: string;
  body?: Params;
}

export interface ErrorResponse {
  success: boolean;
  message: string;
  status: number;
}

@Injectable({
  providedIn: "root",
})
export class ApiReportService {
  private http: AxiosInstance;
  private errorHandler: ErrorHandler;

  constructor(errorHandler: ErrorHandler) {
    this.errorHandler = errorHandler;

    this.http = axios.create({
      baseURL: environment.othersUrl.reports,
      timeout: 100000,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.http.interceptors.request.use(
      (req) => {
        if (!req.headers.common["Authorization"]) {
          const Authorization =
            "Bearer " +
            storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get();
          req.headers.common["Authorization"] = Authorization;
          this.http.defaults.headers.common["Authorization"] = Authorization;
        }
        return req;
        //throw ({ message: "the token is not available" });
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  /**
   *  Para métodos via GET
   */
  public async get<T>(options: GetOptions): Promise<T> {
    try {
      const response = await this.http.request<T>({
        method: "get",
        url: options.url,
        params: options.params,
      });

      return response.data;
    } catch (error) {
      return Promise.reject(this.managerError(error));
    }
  }

  /**
   *  Para métodos via POST
   */
  public async post<T>(url: string, body: any, options?: any): Promise<T> {
    try {
      const response = await this.http.request<T>({
        method: "post",
        url: url,
        data: body,
        responseType: options?.responseType,
      });

      return response.data;
    } catch (error) {
      return Promise.reject(this.managerError(error));
    }
  }

  public async postWithBody<T>(url: string, body: any): Promise<T> {
    try {
      const response = await this.http.request<T>({
        method: "post",
        url: url,
        data: body,
      });

      return response.data;
    } catch (error) {
      return Promise.reject(this.managerError(error));
    }
  }

  /**
   *  Para métodos via PUT
   */
  public async put<T>(id: number, url: string, body: any): Promise<T> {
    try {
      const response = await this.http.request<T>({
        method: "put",
        url: `${url}/${id}`,
        data: body,
      });

      return response.data;
    } catch (error) {
      return Promise.reject(this.managerError(error));
    }
  }

  /**
   *  Para métodos via PATCH
   */
  public async patch<T>(id: number, url: string, body: any): Promise<T> {
    try {
      const response = await this.http.request<T>({
        method: "patch",
        url: `${url}/${id}`,
        data: body,
      });

      return response.data;
    } catch (error) {
      return Promise.reject(this.managerError(error));
    }
  }

  /**
   *  Para métodos via DELETE
   */
  public async delete<T>(id: number, url: string): Promise<T> {
    try {
      const response = await this.http.request<T>({
        method: "delete",
        url: `${url}/${id}`,
      });

      return response.data;
    } catch (error) {
      return Promise.reject(this.managerError(error));
    }
  }

  /**
   * Gerenciador de erros padrão (interceptador)
   */
  private managerError(error: any): ErrorResponse {
    this.errorHandler.handleError(error);
    const {
      response: { data = null, status },
    } = error;

    if (data) {
      return data;
    }
    return {
      success: false,
      status,
      message: "Ocorreu um erro na requisição com o servidor",
    };
  }
}

export default ApiReportService;
