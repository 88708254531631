<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>Nova análise</app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
              <div class="col-12">
                  <form [formGroup]="reportOperationalForm" (ngSubmit)="submitForm()">
                      <h2 class="mb-3">Dados da nova análise</h2>
                        <div class="form-row" >
                            <div class="form-group col-6">
                                <mat-form-field appearance="outline">
                                <mat-label>Data Inicio</mat-label>
                                <input formControlName="dateStart" type="datetime-local" matInput placeholder="Data Inicio" [readonly]=true [disabled]=true required />
                                </mat-form-field>
                            </div>
                            <div class="form-group col-6" *ngIf="reportOperationalForm.get('dateStart').value != ''">
                                <mat-form-field appearance="outline">
                                  <mat-label>Data Fim</mat-label>
                                  <input formControlName="dateEnd" type="datetime-local" matInput placeholder="Data Fim" required />
                                </mat-form-field>
                                <div *ngIf="reportOperationalForm.hasError('invalidDateRange')">
                                  A data de início não pode ser maior que a data atual e a data de fim deve ser maior que a data de início.
                                </div>
                                <div *ngIf="reportOperationalForm.hasError('invalidFutureDate')">
                                  As datas não podem ser no futuro.
                                </div>
                            </div>
                        </div>
                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit" [disabled]="!reportOperationalForm.valid"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Atualizar Análise
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
