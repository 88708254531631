import {BaseModel} from './hateoas/base-model';

export class Transporter extends BaseModel {
    uuid?: string;
    companyId?: number;
    divisionId?: number;
    userId?: number;
    name?: string;
    cpf?: string;
    registration?: string;
    birthDate?: Date;
    phone?: string;
    postalCode?: string;
    state?: string;
    country?: string;
    city?: string;
    district?: string;
    street?: string;
    number?: string;
    complement?: string;
    reference?: string;
    vehicles?: Array<number>;

    public constructor(init?: Partial<Transporter>) {
        super();
        Object.assign(this, init);
    }
}
