import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { TypeProfile } from "../../models/TypeProfile.model";
import { Validation } from "./validation";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-create-perfil-tipo",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CreatePerfilTipoComponent implements OnInit {
  public perfilTipoForm: FormGroup;
  public title = "Cadastrar Perfil Tipo";
  public errorMessage;
  private apiBaseService: ApiBaseService;
  private validation: Validation;

  ngOnInit(): void {
    this.perfilTipoForm = this.formBuilder.group(this.validation.rules());
    //this.setup();
  }
  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();
    try {
      this.coverterUpperCase(this.perfilTipoForm);
      const values = this.perfilTipoForm.value;
      const post = {
        ...values,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };
      await this.apiBaseService.post<TypeProfile>(
        new TypeProfile()["nomeConsultaApiBase"],
        post
      );
      this.spinner.hide();
      this.router.navigate(["/perfil-tipo"]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public coverterUpperCase(perfilTipoForm: FormGroup) {
    console.log(perfilTipoForm);

    perfilTipoForm
      .get("cdPerfilTipo")
      .setValue(perfilTipoForm.get("cdPerfilTipo").value.toUpperCase());
    perfilTipoForm
      .get("nmPerfilTipo")
      .setValue(perfilTipoForm.get("nmPerfilTipo").value.toUpperCase());
  }
}
