import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-axle-three-dual-view",
  templateUrl: "./axle-three-dual-view.component.html",
  styleUrls: ["./axle-three-dual-view.component.scss"],
})
export class AxleThreeDualViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorThreeIntLeftSelect: any = [];
  public sensorThreeIntRightSelect: any = [];

  public sensorThreeExtLeftSelect: any = [];
  public sensorThreeExtRightSelect: any = [];

  public sensorThreeIntRightCombo: any = [];
  public sensorThreeIntLeftCombo: any = [];

  public sensorThreeExtRightCombo: any = [];
  public sensorThreeExtLeftCombo: any = [];

  public axisThreeDoubleLeftIntForm: FormGroup;
  public axisThreeDoubleLeftExtForm: FormGroup;
  public axisThreeDoubleRightIntForm: FormGroup;
  public axisThreeDoubleRightExtForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisThreeDoubleLeftIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisThreeDoubleLeftExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisThreeDoubleRightIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisThreeDoubleRightExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisThreeDoubleLeftIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 3 ESQ INT"
      );
      this.axisThreeDoubleLeftExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 3 ESQ EXT"
      );

      this.axisThreeDoubleRightIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 3 DIR INT"
      );
      this.axisThreeDoubleRightExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 3 DIR EXT"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo3DireitoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 8) {
              eixo3DireitoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 8) {
              eixo3DireitoExt = filter;
            }
          }
        });
        var eixo3EsquerdoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 7) {
              eixo3EsquerdoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 7) {
              eixo3EsquerdoExt = filter;
            }
          }
        });
        var eixo3DireitoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 24) {
              eixo3DireitoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 24) {
              eixo3DireitoInt = filter;
            }
          }
        });
        var eixo3EsquerdoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 24) {
              eixo3EsquerdoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 24) {
              eixo3EsquerdoInt = filter;
            }
          }
        });

        // EIXO 3 DIREITO EXTERNO
        if (eixo3DireitoExt != null) {
          this.axisThreeDoubleRightExtForm.controls["id"].setValue(
            eixo3DireitoExt.id
          );

          if (eixo3DireitoExt.sensor != null) {
            this.sensorThreeExtRightCombo.push(eixo3DireitoExt.sensor);
            this.sensorThreeExtRightSelect = eixo3DireitoExt.sensor.id;
          }

          if (eixo3DireitoExt.pneu != null) {
            this.axisThreeDoubleRightExtForm.controls["pneu"].setValue(
              eixo3DireitoExt.pneu.numeroFogo
            );
          }

          this.axisThreeDoubleRightExtForm.controls["nrPressaoMinima"].setValue(
            eixo3DireitoExt.nrPressaoMinima
          );

          this.axisThreeDoubleRightExtForm.controls["nrPressaoMaxima"].setValue(
            eixo3DireitoExt.nrPressaoMaxima
          );

          this.axisThreeDoubleRightExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo3DireitoExt.nrTemperaturaMaxima);
        }

        // EIXO 3 ESQUERDO EXTERNO
        if (eixo3EsquerdoExt != null) {
          this.axisThreeDoubleLeftExtForm.controls["id"].setValue(
            eixo3EsquerdoExt.id
          );

          if (eixo3EsquerdoExt.sensor != null) {
            this.sensorThreeExtLeftCombo.push(eixo3EsquerdoExt.sensor);
            this.sensorThreeExtLeftSelect = eixo3EsquerdoExt.sensor.id;
          }

          if (eixo3EsquerdoExt.pneu != null) {
            this.axisThreeDoubleLeftExtForm.controls["pneu"].setValue(
              eixo3EsquerdoExt.pneu.numeroFogo
            );
          }

          this.axisThreeDoubleLeftExtForm.controls["nrPressaoMinima"].setValue(
            eixo3EsquerdoExt.nrPressaoMinima
          );

          this.axisThreeDoubleLeftExtForm.controls["nrPressaoMaxima"].setValue(
            eixo3EsquerdoExt.nrPressaoMaxima
          );

          this.axisThreeDoubleLeftExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo3EsquerdoExt.nrTemperaturaMaxima);
        }

        // EIXO 3 DIREITO INTERNO
        if (eixo3DireitoInt != null) {
          this.axisThreeDoubleRightIntForm.controls["id"].setValue(
            eixo3DireitoInt.id
          );

          if (eixo3DireitoInt.sensor != null) {
            this.sensorThreeIntRightCombo.push(eixo3DireitoInt.sensor);
            this.sensorThreeIntRightSelect = eixo3DireitoInt.sensor.id;
          }

          if (eixo3DireitoInt.pneu != null) {
            this.axisThreeDoubleRightIntForm.controls["pneu"].setValue(
              eixo3DireitoInt.pneu.numeroFogo
            );
          }

          this.axisThreeDoubleRightIntForm.controls["nrPressaoMinima"].setValue(
            eixo3DireitoInt.nrPressaoMinima
          );

          this.axisThreeDoubleRightIntForm.controls["nrPressaoMaxima"].setValue(
            eixo3DireitoInt.nrPressaoMaxima
          );

          this.axisThreeDoubleRightIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo3DireitoInt.nrTemperaturaMaxima);
        }

        // EIXO 3 ESQUERDO INTERNO
        if (eixo3EsquerdoInt != null) {
          this.axisThreeDoubleLeftIntForm.controls["id"].setValue(
            eixo3EsquerdoInt.id
          );

          if (eixo3EsquerdoInt.sensor != null) {
            this.sensorThreeIntLeftCombo.push(eixo3EsquerdoInt.sensor);
            this.sensorThreeIntLeftSelect = eixo3EsquerdoInt.sensor.id;
          }

          if (eixo3EsquerdoInt.pneu != null) {
            this.axisThreeDoubleLeftIntForm.controls["pneu"].setValue(
              eixo3EsquerdoInt.pneu.numeroFogo
            );
          }

          this.axisThreeDoubleLeftIntForm.controls["nrPressaoMinima"].setValue(
            eixo3EsquerdoInt.nrPressaoMinima
          );

          this.axisThreeDoubleLeftIntForm.controls["nrPressaoMaxima"].setValue(
            eixo3EsquerdoInt.nrPressaoMaxima
          );

          this.axisThreeDoubleLeftIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo3EsquerdoInt.nrTemperaturaMaxima);
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
