<div class="main-page consultas">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])" data-title="Voltar"><i
                            class="fas fa-arrow-left"></i></a>
                    Consultar Transportador/Agregado
                </h1>
            </div>
        </div>
        <div *ngIf="transportersPage?.page.totalElements === 0">
            <div class="row no-gutters mt-3">
                <p>Nenhum registro até o momento...</p>
            </div>
            <div class="row no-gutters mt-3">
                <a (click)="this.router.navigate(['/transporters/create'])" mat-button color="primary"
                   class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                    Criar um novo transportador
                </a>
            </div>
        </div>
        <div class="row no-gutters mt-3" *ngIf="transportersPage?.page.totalElements > 0">
            <div class="mat-elevation table-content">
                <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field fxFlex="40%" class="ml-2">
                        <mat-label>Filtrar por nome</mat-label>
                        <input matInput autocomplete="off"
                               (keyup)="this.applyFilter()" [(ngModel)]="filter" style="text-transform: uppercase;" placeholder="Ex: João da silva" #input>
                    </mat-form-field>

                </div>
                <table mat-table [dataSource]="transporters" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
                        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                    </ng-container>
                    <ng-container matColumnDef="cpf">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>CPF</th>
                        <td mat-cell *matCellDef="let element">{{ element.cpf }}</td>
                    </ng-container>
                    <ng-container matColumnDef="birthdate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de Nascimento</th>
                        <td mat-cell *matCellDef="let element">{{ element.birth_date | date: "dd/MM/yyyy" }}</td>
                    </ng-container>
                    <ng-container matColumnDef="update">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Editar"
                                  *ngIf="this.permissionService.hasPermission('TRANSPORTER_ATUALIZACAO_FUNCIONALIDADE', this.permissions)" matTooltip="Editar">
                                <a (click)="this.router.navigate(['/transporters/edit/' + element.uuid])" class="btn btn-link">
                                    <img height="20" src="assets/img/edit.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="clone">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Duplicar"
                                  *ngIf="this.permissionService.hasPermission('TRANSPORTER_ATUALIZACAO_FUNCIONALIDADE', this.permissions)" matTooltip="Duplicar">
                                <a (click)="this.router.navigate(['/transporters/clone/' + element.uuid])" class="btn btn-link">
                                    <img height="20" src="assets/img/duplic.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Visualizar"
                                  *ngIf="this.permissionService.hasPermission('TRANSPORTER_ATUALIZACAO_FUNCIONALIDADE', this.permissions)" matTooltip="Visualizar">
                                <a (click)="this.router.navigate(['/transporters/view/' + element.uuid])" class="btn btn-link">
                                    <img height="20" src="assets/img/view.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="deleteTransporter(element)" class="btn btn-link"
                                    *ngIf="this.permissionService.hasPermission('TRANSPORTER_DELECAO_FUNCIONALIDADE', this.permissions)" matTooltip="Apagar">
                                <img height="20" src="assets/img/trash-cadastro.svg" alt="" />
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 40, 100]"
                               [pageSize]="transportersPage.page.size"
                               (page)="onChangePage($event)"
                               [length]="transportersPage.page.totalElements"
                               showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>
