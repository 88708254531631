/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Injectable, ErrorHandler } from "@angular/core";
import { BehaviorSubject, Observable, interval } from "rxjs";
import { ErrorResponse } from "./api.service";
import { UserService } from "./user.service";

import { User } from "./../models/User.model";

import storageX from "../storageCore/storageX";
import {
  STORAGE_KEY_ACCESS_TOKEN_WSO2,
  STORAGE_KEY_CD_USUARIO,
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_MANAGER,
  STORAGE_KEY_ID_SUPERVISOR,
  STORAGE_KEY_UUID_MANAGER,
  STORAGE_KEY_UUID_SUPERVISOR,
} from "../storageCore/constStorageKeys";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public intervalTimeInMilliseconds = 10 * 60 * 1000; // 10 minutes in milliseconds

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private errorHandler: ErrorHandler;

  constructor(errorHandler: ErrorHandler, private userService: UserService) {
    this.errorHandler = errorHandler;
    this.currentUserSubject = new BehaviorSubject<User>(
      storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get() //JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public async login(username: string, password: string): Promise<User> {
    try {
      const formData = new FormData();
      formData.set("username", username);
      formData.set("password", password);
      formData.set("grant_type", "password");
      storageX.whereKeyIs(STORAGE_KEY_CD_USUARIO).set(username);
      const user = await this.userService.login(username, password);
      if (!user) {
        return Promise.reject(
          this.managerError("Nao foi possivel obter o usuario")
        );
      }
      console.log(user);
      storageX
        .whereKeyIs(STORAGE_KEY_ID_MANAGER)
        .set(user.gestorId != null ? user.gestorId : "");
      storageX
        .whereKeyIs(STORAGE_KEY_ID_SUPERVISOR)
        .set(user.supervisorId != null ? user.supervisorId : "");
      storageX
        .whereKeyIs(STORAGE_KEY_UUID_MANAGER)
        .set(user.gestorUuid != null ? user.gestorUuid : "");
      storageX
        .whereKeyIs(STORAGE_KEY_UUID_SUPERVISOR)
        .set(user.supervisorUuid != null ? user.supervisorUuid : "");
      const userParse = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).set(user); //JSON.stringify(user);
      const hasUser = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get(); //JSON.parse(localStorage.getItem('currentUser'));
      if (!hasUser) {
        return Promise.reject(
          this.managerError("Usuario nao foi salvo corretamente")
        );
      }
      this.currentUserSubject.next(user);
      return user;
    } catch (error) {
      return Promise.reject(this.managerError(error));
    }
  }

  public initListenerToken(): void {
    interval(this.intervalTimeInMilliseconds).subscribe(
      (x) => {
        console.log("--> Refresh de token iniciada " + new Date());
        //this.gerarCode();
      },
      (error) => {
        throw new Error("Erro ao executar o listener do token");
      }
    );
  }

  logout(): void {
    var token = storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get();
    console.log(token);
    storageX.removeAll();
    storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).set(token);
    this.currentUserSubject.next(null);
  }

  private managerError(error: any): ErrorResponse {
    this.errorHandler.handleError(error);
    const {
      response: { data = null, status = null },
    } = error;
    if (data) {
      return data;
    }
    return {
      success: false,
      status,
      message: "Ocorreu um erro na requisição com o servidor",
    };
  }
}
