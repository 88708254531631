<div class="sidebar-right">

  <mat-progress-bar mode="indeterminate" style="margin: 10px;" *ngIf="!dataLoaded">
  </mat-progress-bar>

  <div *ngIf="dataLoaded">

    <!-- Botão com a lista das matrizes -->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <button style="     position: relative;
          margin-left: 56%;
          top: -20px;
          width: 45%;" mat-raised-button color="primary" [matMenuTriggerFor]="menu"
            aria-label="Matriz">
            <small>Matriz</small>
            <mat-icon>more_vert</mat-icon>
            <mat-menu #menu="matMenu">
              <button (click)="this.loadChildrens(parent)"  *ngFor="let parent of menuTree.parents"
                mat-menu-item>
                <span>{{parent.company_name}}</span>
              </button>
            </mat-menu>
          </button>
        </div>
      </div>
    </div>

    <!-- Combo de veiculos da matriz -->
      <div class="row" *ngIf="this.selectedCompany.id != -1">
          <div class="col-3" style="padding: 5px">
            <button style="margin-top: -63px; position: absolute;" mat-raised-button color="primary" [matMenuTriggerFor]="menu"
              aria-label="Veiculos da Matriz">
              <small>Veículos Matriz</small>
              <mat-icon>more_vert</mat-icon>
              <mat-menu #menu="matMenu">
                <button (click)="this.onSelectCompany(parent)"  *ngFor="let parent of menuTree.parents"
                        mat-menu-item>
                  <span>{{parent.company_name}}</span>
                </button>
              </mat-menu>
            </button>
          </div>
      </div>

    <!-- Combo veiculos das filiais -->
      <div class="row" *ngIf="this.selectedCompany.id != -1">
          <div class="col-3">
            <button style="margin-left: 170%;
            margin-top: -8px;
            width: 130px;
            position: absolute;" mat-raised-button color="primary" [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu">
              <small>Veículos Filial</small>
              <mat-icon>more_vert</mat-icon>
              <mat-menu #menu="matMenu">
                  <button (click)="this.onSelectCompany(subsidiary); this.loadChildrens(subsidiary)" *ngFor="let subsidiary of this.subsidiariesToList"
                          mat-menu-item>
                  <span>{{subsidiary.company_name}}</span>
                </button>
              </mat-menu>
            </button>
          </div>
      </div>

    <!-- Combo veiculos da divisão -->
      <div class="row" *ngIf="this.selectedCompany.id != -1">
          <div class="col-3">
            <button style="margin-left: 48%;
            margin-top: -10px;
            width: 135px;" mat-raised-button color="primary" [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu">
              <small>Veiculos Divisão</small>
              <mat-icon>more_vert</mat-icon>
              <mat-menu #menu="matMenu">
                <div >
                  <button (click)="this.onSelectCompany(division)" *ngFor="let division of this.divisionsToList"
                  mat-menu-item>
                  <span>{{division.company_name}}</span>
                </button>
                </div>
              </mat-menu>
            </button>
          </div>
      </div>

    <mat-tab-group>
      <!-- Aba Veiculos (Veiculos da empresa selecionada) -->
      <mat-tab [label]="this.selectedCompany.company_name ? this.selectedCompany.company_name : 'Veículos'">
        <div>
          <div>
            <div>
                <div class="card-body text-left">
                  <table cellspacing="0" cellpadding="0" border="0">
                    <!-- Localizar Veiculos -->
                    <tr>
                      <td>
                        <button mat-icon-button (click)="localizarVeiculos()" *ngIf="this.selectedCompany.company_name">
                          <mat-icon>search</mat-icon> Localizar Veículos
                        </button>
                      </td>
                    </tr>
                    <!-- Filtro por status -->
                    <tr>
                      <td align="left" width="200">
                        <h1 style="
                        position: relative; left: 14px;" class="no-b">
                          <small>
                            {{this.statusTagOptionsSelected.status}}
                          </small>
                        </h1>
                      </td>
                      <td>
                        <button style="
                        cursor: pointer;
                        position: relative;
                        left: 26px;" mat-icon-button [matMenuTriggerFor]="submenu" aria-label="">

                          <mat-icon [ngStyle]="{'color': this.statusTagOptionsSelected.color}"
                            style="font-size: 14px;">stop_circle</mat-icon>

                          <mat-menu #submenu="matMenu">
                            <button (click)="statusAndTextFilter(i)" *ngFor="let i of this.tagOptionsList"
                                    mat-menu-item>
                              <mat-icon style="font-size: 12px;" [ngStyle]="{'color': i.color}">stop_circle</mat-icon>
                              {{i.status}}
                            </button>
                          </mat-menu>
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>              
            </div>
          </div>
          <!-- Listagem de veiculos por empresa (vehiclesSubMenuList)-->
          <div *ngIf="this.selectedCompany.id != -1" class="container">
            <div class="row">
              <div class="col-12" style="margin-bottom: 15px;">
                <div class="form-group has-search">
                  <span class="fa fa-search form-control-feedback"></span>
                  <input [(ngModel)]="textFilterValue" (change)="statusAndTextFilter(statusTagOptionsSelected)" type="text" class="form-control" [placeholder]="
                      activeTab === 'vehicles'
                        ? 'Pesquisar por placa...'
                        : 'Filtro'
                    " />
                </div>
              </div>
            </div>
          </div>
          <div style="cursor: pointer;" class="h-f">
            <div style="display: inline-flex;">
              <a class="btn btn-link">
              </a>
              <a>
                <div class="card-body text-left">
                  <table cellspacing="0" cellpadding="0" border="0">
                    <tr>
                      <td align="left" width="210">
                        <h1 style="margin-left: -44px;" class="no-b">
                          <mat-icon style="font-size: 23px; visibility: hidden;">stop_circle</mat-icon>
                          <small>
                            TODOS
                          </small>
                        </h1>
                      </td>
                      <td>
                        <span title="desfocar" style="position: relative; left: -29px;"
                          (click)="this.markerMap.center()" class="material-icons">
                          crop_free
                        </span>
                      </td>
                      <td (click)="this.switchAllTerminalsOnMap()">
                        <i *ngIf="this.isAllVisible()" class="material-icons" style="color: #107cfa; font-size: 1.5rem;
                                 position: relative; left: -10px;">
                          visibility
                        </i>
                        <i *ngIf="!this.isAllVisible()" class="material-icons" style="color: #212529; font-size: 1.5rem;
                                 position: relative; left: -10px;">
                          visibility_off
                        </i>
                      </td>
                    </tr>
                  </table>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div style="height: 18em;">
          <div style="cursor: pointer; display: inline-flex;"
            *ngFor="let vehicle of this.vehiclesSubMenuList">
            <a>
              <div class="h-f card-body text-left">
                <table cellspacing="0" cellpadding="0" border="0">
                  <tr>
                    <td width="27">
                      <span [title]="this.tagOptions[vehicle.tag]" style="font-size: 12px;"
                        [ngStyle]="{'color': vehicle.tag}" class="material-icons">
                        stop_circle
                      </span>
                    </td>
                    <td align="left" width="170">
                      <h1 class="no-b">{{ vehicle.placa }}</h1>
                      <h1 class="no-b">{{ vehicle.frota == null ? 'Frota não cadastrada' : vehicle.frota}}</h1>
                      <h1 class="no-b">{{ vehicle.categoria == null ? 'Categoria não cadastrada' : vehicle.categoria}}</h1>
                    </td>
                    <td>
                      <h1 style="position: relative; left: -27px; top: 0px;" class="no-b"
                        [ngStyle]="{'color' : vehicle.status_gps == 0 ? 'lawngreen' : 'darkgrey'}">GPS
                      </h1>
                    </td>
                    <td>
                      <span *ngIf="this.isVisible(vehicle)" title="focar" (click)="centerMapOn(vehicle)"
                        class="material-icons">
                        center_focus_strong
                      </span>
                    </td>
                    <td (click)="this.switchVehicleOnMap(vehicle)" align="left" width="50">
                      <a class="btn btn-link">
                        <i *ngIf="this.isVisible(vehicle)" class="material-icons"
                          style="color: #107cfa; font-size: 1.5rem">
                          visibility
                        </i>
                        <i *ngIf="!this.isVisible(vehicle)" class="material-icons"
                          style="color: #212529; font-size: 1.5rem">
                          visibility_off
                        </i>
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </a>
          </div>
        </div>
      </mat-tab>
      <!-- Aba das empresas (Arvore completa de veiculos -->
      <mat-tab label="Empresas">
        <div id="companies" class="card tab-pane fade show" role="tabpanel"
             [ngClass]="{ active: activeTab === 'companies' }">
          <div id="pane-B" class="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
            <div id="content-B" class="tab-content scroll" style="direction: ltr;" role="tablist-2">
              <div class="clt">
                <ul>
                  <ng-container *ngFor="let root of menuTree.full_tree">
                    <li>
                      {{ root.company_name }}
                      <ul>
                        <li *ngFor="
                          let company of root.childrens">
                          {{ company.company_name }}
                          <ul>
                            <li *ngFor="let innerCompany of company.childrens">
                              {{innerCompany.company_name}}

                              <ul>
                                <li>
                                  <ng-container *ngFor="let vehicles of innerCompany.vehicles">
                                    <li>
                                      <a href="#" class="tree-link" (click)="addSingleVehicleOnMap(vehicles)">
                                        {{ vehicles.placa }}
                                      </a>
                                    </li>
                                  </ng-container>
                                </li>
                              </ul>

                            </li>
                          </ul>
                          <ul>
                            <li *ngFor="
                              let vehicleOfCompany of company.vehicles
                            ">
                              <a href="#" class="tree-link" (click)="addSingleVehicleOnMap(vehicleOfCompany)">
                                {{ vehicleOfCompany.placa }}
                              </a>
                            </li>
                          </ul>
                        </li>
                        <ng-container *ngFor="let vehicleOfRoot of root.vehicles">
                          <li>
                            <a href="#" class="tree-link" (click)="addSingleVehicleOnMap(vehicleOfRoot)">
                              {{ vehicleOfRoot.placa }}
                            </a>
                          </li>
                        </ng-container>
                      </ul>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <!--<div class="container no-gutters-p">
      <div class="row">
        <div class="col-12 my-3">
          <div id="content" class="tab-content" role="tablist">

            <div id="companies-what" class="card tab-pane fade show" role="tabpanel"
              [ngClass]="{ active: activeTab === 'companies' }">
              <div id="pane-B" class="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
                <div id="content-B" class="tab-content scroll" style="direction: ltr;" role="tablist-2">
                  <div class="clt">
                    <ul>
                      <ng-container *ngFor="let it of sourceMarkers">
                        <li>
                          {{ it.name }}
                          <ul>
                            <li *ngFor="
                                let chi of it.children
                                  | searchPipe: 'name':searchText
                              ">
                              {{ chi.name }}
                              <ul>
                                <li *ngFor="let chiInChi of this.listSort(chi?.children, 'name')">
                                  {{chiInChi.name}}

                                  <ul>
                                    <li>
                                      <ng-container
                                        *ngFor="let subChiInChi of this.listSort(chiInChi?.posicaoAtualTerminalList, 'placa')">
                                    <li>
                                      <a href="#" class="tree-link" (click)="centerMapOn(subChiInChi)">
                                        {{ subChiInChi.placa }}
                                      </a>
                                    </li>
                      </ng-container>
                      </li>
                    </ul>

                    </li>
                    </ul>
                    <ul>
                      <li *ngFor="
                                    let subchi of this.listSort(chi?.posicaoAtualTerminalList, 'placa')
                                  ">
                        <a href="#" class="tree-link" (click)="centerMapOn(subchi)">
                          {{ subchi.placa }}



                        </a>
                      </li>
                    </ul>
                    </li>
                    <ng-container *ngFor="let sub of this.listSort(it.posicaoAtualTerminalList, 'placa')">
                      <li>
                        <a href="#" class="tree-link" (click)="centerMapOn(sub)">
                          {{ sub.placa }}
                        </a>
                      </li>
                    </ng-container>
                    </ul>
                    </li>
                    </ng-container>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
