<div >
  <mat-button-toggle-group
    name="zoom"
    [(ngModel)]="action"
    (change)="toggleView($event)"
  >

<!--     <mat-button-toggle value="zoom_in">
      <mat-icon>zoom_in</mat-icon>
    </mat-button-toggle>

    <mat-button-toggle value="zoom_out">
      <mat-icon>zoom_out</mat-icon>
    </mat-button-toggle>
 -->
    <mat-button-toggle value="center">
      <mat-icon>gps_fixed</mat-icon>
    </mat-button-toggle>

    <mat-button-toggle value="zoom_area">
      <mat-icon>search</mat-icon>
      <span class="btn-icon-text">Zoom Area</span>
    </mat-button-toggle>

<!--     <mat-button-toggle value="trafego">
      <mat-icon>search</mat-icon>
      <span class="btn-icon-text">Tráfego</span>
    </mat-button-toggle> -->


  </mat-button-toggle-group>
</div>
