import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { UserInterface } from "src/app/contracts/User.interface";
import {
  STORAGE_KEY_CURRENT_PERFIL,
  STORAGE_KEY_CURRENT_PERFIL_FUNCIONALIDADES,
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_CURRENT_VEHICLES,
  STORAGE_KEY_ID_PERFIL,
  STORAGE_KEY_PATHS,
  STORAGE_KEY_PERMISSIONS,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { ApiService } from "./../../services/api.service";
import { TransporterService } from "../../services/transporter.service";
import { PerfilInterface } from "../../contracts/Perfil.interface";

@Component({
  selector: "app-perfil-selector",
  templateUrl: "./app-perfil-selector.component.html",
  styleUrls: ["./app-perfil-selector.component.scss"],
})
export class AppPerfilSelectorComponent {
  private service: ApiService;
  loader = false;

  usuario: UserInterface;
  perfils = [];
  funcionalidadesUsuario = [];
  pathsUsuario = [];

  constructor(
    public dialogRef: MatDialogRef<any>,
    apiService: ApiService,
    private transporterService: TransporterService
  ) {
    this.service = apiService;
    this.usuario = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get(); //JSON.parse(localStorage.getItem('currentUser'));
    this.loadFuncionalidadesUsuario();
    this.loadPerfil();
  }

  close(data): void {
    this.dialogRef.close(data);
  }

  async onPerfilClick(userPerfil): Promise<void> {
    try {
      const userPerfis = this.usuario.perfis;
      const perfil = userPerfis.find((e) => e.id === userPerfil.id);
      if (!perfil || !perfil.id) {
        alert("Error de perfil, entre em contato com o administrador");
        return;
      }

      const idPerfil = await storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(); //parseInt(localStorage.getItem('idPerfil'));
      const isReloadPerfil = idPerfil !== perfil.id;

      if (isReloadPerfil) {
        /*   localStorage.setItem(
          'currentPerfilFuncionalidades',
          JSON.stringify(perfil.funcionalidades)
        ) */

        storageX
          .whereKeyIs(STORAGE_KEY_CURRENT_PERFIL_FUNCIONALIDADES)
          .set(perfil.funcionalidades);

        this.currentFuncionalidades(perfil);
        this.currentVehicles(this.usuario, perfil);

        const perfilId = JSON.stringify(perfil.id);

        if (perfilId) {
          storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).set(perfilId); //localStorage.setItem('idPerfil', perfilId);
        }

        storageX.whereKeyIs(STORAGE_KEY_CURRENT_PERFIL).set(perfil); // localStorage.setItem('currentPerfil', JSON.stringify(perfil));
      }
      this.close({ idPerfil });
    } catch (error) {
      console.log("ocorreu um erro onPerfilClick()", error);
    }
  }

  async loadPerfil(): Promise<void> {
    this.loader = true;

    const user = await storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get(); //JSON.parse(localStorage.getItem('currentUser'));
    try {
      if (!user) {
        this.loader = false;
        alert("Usuário não salvo corretamente");
        return;
      }

      const url = "usuario-perfil";
      const res = await this.service.get<any>({
        url,
        params: {
          usuario: user.id,
        },
      });

      const perfils = res["content"];

      if (!perfils || perfils.length === 0) {
        alert("Usuário sem perfil associado");
        return;
      }
      if (perfils.length === 1) {
        this.onPerfilClick(perfils[0].perfil);
        return;
      }

      this.perfils = perfils.map((e) => e.perfil);

      this.loader = false;
    } catch (error) {
      this.loader = false;
      console.log(">>>> error", error);
    }
  }

  private currentFuncionalidades(perfil): void {
    const permissionsPerfil = [];
    const pathsPerfil = [];
    const { funcionalidades = [] } = perfil;

    funcionalidades.forEach((permissao) => {
      const {
        cdFuncionalidade: cdPermissao,
        nmFuncionalidadeTipo: cdModulo,
        cdPath,
      } = permissao;

      const atualizacao = cdPermissao + "_ATUALIZACAO_" + cdModulo;
      const leitura = cdPermissao + "_LEITURA_" + cdModulo;
      const delecao = cdPermissao + "_DELECAO_" + cdModulo;
      const insercao = cdPermissao + "_INSERCAO_" + cdModulo;

      if (permissao.ativo) {
        permissionsPerfil.push(cdPermissao + "_" + cdModulo);
        pathsPerfil.push(cdPath);
      }

      if (permissao.flAtualizacao) {
        permissionsPerfil.push(atualizacao);
      }

      if (permissao.flDelecao) {
        permissionsPerfil.push(delecao);
      }

      if (permissao.flInsercao) {
        permissionsPerfil.push(insercao);
      }

      if (permissao.flLeitura) {
        permissionsPerfil.push(leitura);
      }
    });

    const permissionUserAndPerfil = permissionsPerfil.concat(
      this.funcionalidadesUsuario
    );
    const pathsUserAndPerfil = pathsPerfil.concat(this.pathsUsuario);

    if (permissionUserAndPerfil.length > 0) {
      storageX.whereKeyIs(STORAGE_KEY_PERMISSIONS).set(permissionUserAndPerfil);
    }
    if (pathsUserAndPerfil.length > 0) {
      storageX.whereKeyIs(STORAGE_KEY_PATHS).set(pathsUserAndPerfil);
    }
  }

  private loadFuncionalidadesUsuario(): void {
    const permissions = [];
    const paths = [];
    const user = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get();

    user.funcionalidades.forEach((permissao) => {
      const {
        cdFuncionalidade: cdPermissao,
        nmFuncionalidadeTipo: cdModulo,
        cdPath,
      } = permissao;

      const atualizacao = cdPermissao + "_ATUALIZACAO_" + cdModulo;
      const leitura = cdPermissao + "_LEITURA_" + cdModulo;
      const delecao = cdPermissao + "_DELECAO_" + cdModulo;
      const insercao = cdPermissao + "_INSERCAO_" + cdModulo;

      if (permissao.ativo) {
        permissions.push(cdPermissao + "_" + cdModulo);
        paths.push(cdPath);
      }

      if (permissao.flAtualizacao) {
        permissions.push(atualizacao);
      }

      if (permissao.flDelecao) {
        permissions.push(delecao);
      }

      if (permissao.flInsercao) {
        permissions.push(insercao);
      }

      if (permissao.flLeitura) {
        permissions.push(leitura);
      }

      this.funcionalidadesUsuario = permissions;
      this.pathsUsuario = paths;
    });
  }

  private currentVehicles(
    login: UserInterface,
    selectedProfile: PerfilInterface
  ): void {
    if (selectedProfile.tipoPerfil === "AGREGADO_TRANSPORTADOR") {
      this.transporterService.search(login.id).subscribe((v) => {
        storageX.whereKeyIs(STORAGE_KEY_CURRENT_VEHICLES).set(v.vehicles);
      });
    }
  }
}
