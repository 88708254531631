import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import storageX from "../storageCore/storageX";
import {
  STORAGE_KEY_ACCESS_TOKEN_WSO2,
  STORAGE_KEY_CURRENT_USER,
} from "../storageCore/constStorageKeys";

@Injectable({
  providedIn: "root",
})
export class UserMarkerService {
  RESOURCE = environment.apiUrl.concat("/usuario-marker/all");
  constructor(private http: HttpClient) {}

  public getUserMarkers(): Observable<any> {
    const url = this.RESOURCE.concat(
      "/" + storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get().id
    );

    return this.http.get(url, {
      headers: {
        Authorization:
          "Bearer " +
          `${storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get()}`,
        "Content-Type": "application/json",
      },
    });
  }
}
