import {
  Component,
  NgZone,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { MapToolboxService } from "../../services/map.toolbox.service";
import { FindVehiclesComponent } from "../maps/find-vehicles/find-vehicles.component";
import { MarkerMap } from "../main/markerMap";
import { MatDialog } from "@angular/material/dialog";
import {
  STORAGE_KEY_CURRENT_VEHICLES,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import ApiService from "src/app/services/api.service";
import { VehiclesMenuService } from "../../services/vehicles-menu.service";
import { VehicleMenu } from "../../models/VehicleMenu.model";
import { CompanyMenuItem } from "../../models/CompanyMenuItem.model";
import { VehicleMenuItem } from "../../models/VehicleMenuItem.model";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  private mapService: MapToolboxService;
  private service: ApiService;
  private vehicleMenuService: VehiclesMenuService;
  activeTab = "";
  public loader = false;
  public statusTagOptionsDefault = {
    color: "#607d8b",
    status: "Filtrar por status",
  };
  public statusTagOptionsSelected = this.statusTagOptionsDefault;
  public tagOptions = {
    "#607d8b": "TODOS STATUS",
    red: "EVENTO NÃO TRATADO",
    orange: "EVENTO EM TRATAMENTO",
    green: "EM MOVIMENTO",
    blue: "PONTO MORTO",
    brown: "PARADO",
    black: "SEM COMUNICAÇÃO",
    gray: "SEM BATERIA",
  };

  // Novas variaveis
  public tagOptionsList = [
    { color: "#607d8b", status: "TODOS STATUS" },
    { color: "red", status: "EVENTO NÃO TRATADO" },
    { color: "orange", status: "EVENTO EM TRATAMENTO" },
    { color: "green", status: "EM MOVIMENTO" },
    { color: "blue", status: "PONTO MORTO" },
    { color: "brown", status: "PARADO" },
    { color: "black", status: "SEM COMUNICAÇÃO" },
    { color: "gray", status: "SEM BATERIA" },
  ];

  public menuTree: VehicleMenu;
  public dataLoaded = false;
  public divisionsToList: Array<CompanyMenuItem> = [];
  public subsidiariesToList: Array<CompanyMenuItem> = [];
  public selectedCompany: CompanyMenuItem = new CompanyMenuItem();
  public vehiclesSubMenuList: Array<VehicleMenuItem> = [];
  public textFilterValue = "";
  @Input() terminalsOnMap: Array<number>;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChangeTerminals = new EventEmitter<Array<number>>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChangeCenterMap = new EventEmitter<number>();

  // fim das novas variaveis
  constructor(
    public zone: NgZone,
    apiService: ApiService,
    mapService: MapToolboxService,
    vehicleMenuService: VehiclesMenuService,
    public markerMap: MarkerMap,
    public dialog: MatDialog
  ) {
    this.mapService = mapService;
    this.service = apiService;
    this.vehicleMenuService = vehicleMenuService;
  }

  public ngOnInit(): void {
    this.loadVehiclesMenu();
  }

  public loadChildrens(company: CompanyMenuItem): void {
    this.selectedCompany = company;

    if (company.company_type === "M") {
      this.subsidiariesToList = this.menuTree.subsidiaries.filter(
        (subsidiarie) => subsidiarie.parent_id === company.id
      );
      this.divisionsToList = this.menuTree.divisions.filter(
        (division) => division.parent_id === company.id
      );
    }

    if (company.company_type === "F") {
      this.divisionsToList = this.menuTree.divisions.filter(
        (division) => division.parent_id === company.id
      );
    }
  }
  /**
   * Carrega terminais no mapa adicionando novos terminais ou substituindo os existentes
   * @param vehicles array com os ids dos terminais que devem ser adicoinados no mapa
   * @param replace booleano que determina se os veiculos existentes devem ser sobrescritos
   */
  public loadVehicles(vehicles: Array<number>, replace: boolean): void {
    if (replace) {
      this.terminalsOnMap = new Array<number>();
    }
    vehicles.forEach((vehicle) => {
      if (!this.terminalsOnMap.includes(vehicle)) {
        this.terminalsOnMap.push(vehicle);
      }
    });
    console.log(this.terminalsOnMap);
    this.onChangeTerminals.emit(this.terminalsOnMap);
  }

  public loadVehiclesSubMenu(company: CompanyMenuItem): void {
    this.selectedCompany = company;
    if (company.company_type === "M") {
      this.vehiclesSubMenuList = this.menuTree.full_tree.find(
        (root) => root.id === company.id
      ).vehicles;
    }
    if (company.company_type === "F") {
      this.vehiclesSubMenuList = this.menuTree.full_tree
        .find((root) => root.id === company.parent_id)
        .childrens.find((filial) => filial.id === company.id).vehicles;
    }
    if (company.company_type === "D") {
      // Divisão filha da matriz
      if (
        this.menuTree.full_tree.some((root) => root.id === company.parent_id)
      ) {
        this.vehiclesSubMenuList = this.menuTree.full_tree
          .find((root) => root.id === company.parent_id)
          .childrens.find(
            (c) => c.id === company.id && c.company_type === "D"
          ).vehicles;
      } else {
        this.menuTree.full_tree.forEach((m) => {
          m.childrens.forEach((f) => {
            if (f.id === company.parent_id && f.company_type === "F") {
              this.vehiclesSubMenuList = f.childrens.find(
                (d) => d.id === company.id
              ).vehicles;
            }
          });
        });
      }
    }
  }

  public localizarVeiculos(): void {
    this.openDialog(FindVehiclesComponent);
  }

  public openDialog(component: any): any {
    const dialogRef = this.dialog
      .open(component, {
        width: "1000px",
        height: "600px",
        data: {
          selectedCompany: this.selectedCompany,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result !== undefined) {
          const selections = [];
          result._selection.forEach((s) => {
            selections.push(s.id_terminal);
            this.loadVehicles(selections, true);
          });
        }
      });
  }

  /**
   * Método responsavel pelo processamento dos filtros de status e texto
   * @param value representa um status selecionado no filtro de status
   */
  statusAndTextFilter(value): void {
    this.statusTagOptionsSelected = value;
    const searchString = this.textFilterValue.toUpperCase().trim();
    this.loadVehiclesSubMenu(this.selectedCompany);
    if (
      value.status !== "TODOS STATUS" &&
      value.status !== this.statusTagOptionsDefault.status
    ) {
      // Realiza o filtro na lista de veiculos apenas se algum status especifico foi selecionado
      this.vehiclesSubMenuList = this.vehiclesSubMenuList.filter(
        (vehicle) => vehicle.tag === value.color
      );
    }

    if (searchString !== "") {
      this.vehiclesSubMenuList = this.vehiclesSubMenuList.filter(
        (vehicle) =>
          vehicle.placa.toUpperCase().includes(searchString) ||
          (vehicle.categoria !== undefined &&
            vehicle.categoria.toUpperCase().includes(searchString)) ||
          (vehicle.frota !== undefined &&
            vehicle.frota.toUpperCase().includes(searchString))
      );
    }

    // T1618 - Veiculos filtrados devem refletir no mapa
    this.loadVehicles(
      this.vehiclesSubMenuList.map((v) => v.id_terminal),
      true
    );
  }

  /**
   * Adiciona um unico veiculo no mapa, sem remover os existentes
   * @param vehicle item de menu correspondente ao veiculo que deve ser adicionado ao mapa
   */
  addVehicleOnMap(vehicle: VehicleMenuItem): void {
    const vehicles = new Array<number>();
    vehicles.push(vehicle.id_terminal);
    this.loadVehicles(vehicles, false);
  }

  /**
   * Adiciona um veiculo no mapa, removendo os anteriores e centralizando o mapa
   * @param vehicle representa o item de menu que deve ser adicionado ao mapa
   */
  addSingleVehicleOnMap(vehicle: VehicleMenuItem): void {
    const vehicles = new Array<number>();
    vehicles.push(vehicle.id_terminal);
    this.loadVehicles(vehicles, true);
    this.centerMapOn(vehicle);
  }
  removeVehicleOffMap(vehicle: VehicleMenuItem): void {
    const indexToDelete = this.terminalsOnMap.indexOf(vehicle.id_terminal);
    this.terminalsOnMap.splice(indexToDelete);
    this.onChangeTerminals.emit(this.terminalsOnMap);
  }

  removeAllVehiclesOffMap(): void {
    this.terminalsOnMap = [];
    this.onChangeTerminals.emit(this.terminalsOnMap);
  }

  switchVehicleOnMap(vehicle: VehicleMenuItem): void {
    if (this.isVisible(vehicle)) {
      this.removeVehicleOffMap(vehicle);
    } else {
      this.addVehicleOnMap(vehicle);
    }
  }

  isVisible(vehicle: VehicleMenuItem): boolean {
    return this.terminalsOnMap.includes(vehicle.id_terminal);
  }

  isAllVisible(): boolean {
    return this.vehiclesSubMenuList.length === this.terminalsOnMap.length;
  }

  switchAllTerminalsOnMap(): void {
    if (this.terminalsOnMap.length > 0) {
      this.removeAllVehiclesOffMap();
    } else {
      console.log("Adicionando todos os terminais no mapa");
      this.loadVehicles(
        this.vehiclesSubMenuList.map((v) => v.id_terminal),
        true
      );
    }
  }

  centerMapOn(vehicle: VehicleMenuItem): void {
    if (!this.terminalsOnMap.includes(vehicle.id_terminal)) {
      this.addVehicleOnMap(vehicle);
    }
    this.onChangeCenterMap.emit(vehicle.id_terminal);
  }

  /**
   * Método responsavel por carregar os dados completos do menu de veiculos
   * @private
   */
  private loadVehiclesMenu(): void {
    this.vehicleMenuService
      .getVehiclesMenuByProfile(this.getIdPerfil())
      .subscribe(
        (menu) => {
          if (
            storageX.whereKeyIs(STORAGE_KEY_CURRENT_VEHICLES).get() !== null
          ) {
            console.log("perfil com restrição de veiculos, filtrando");
            this.menuTree = this.filterByVehicle(
              menu,
              storageX.whereKeyIs(STORAGE_KEY_CURRENT_VEHICLES).get()
            );
          } else {
            this.menuTree = menu;
          }

          this.dataLoaded = true;
        },
        (error) => {
          this.dataLoaded = true;
          throw new Error("Erro ao carregar o menu" + error);
        }
      );
  }

  private getIdPerfil(): number {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();

    if (!perfil) {
      throw new Error("Nenhum perfil encontrado");
    }
    return perfil;
  }

  /**
   * Método responsavel por processar a selação de uma empresa
   * nos menus de matriz/filial/divisão
   * @param company
   */
  onSelectCompany(company: CompanyMenuItem): void {
    this.removeAllVehiclesOffMap();
    this.statusTagOptionsSelected = this.statusTagOptionsDefault;
    this.textFilterValue = "";
    this.loadVehiclesSubMenu(company);
  }

  private filterByVehicle(menu: VehicleMenu, param: Array<number>) {
    menu.full_tree.forEach((company) => {
      company.vehicles = company.vehicles.filter((vehicle) =>
        param.includes(vehicle.id)
      );
      if (
        company.childrens !== undefined &&
        company.childrens !== null &&
        company.childrens.length > 0
      ) {
        company.childrens.forEach((companyChildren1) => {
          companyChildren1.vehicles = companyChildren1.vehicles.filter(
            (vehicle) => param.includes(vehicle.id)
          );
          if (
            companyChildren1.childrens !== undefined &&
            companyChildren1.childrens !== null &&
            companyChildren1.childrens.length > 0
          ) {
            companyChildren1.childrens.forEach((companyChildren2) => {
              companyChildren2.vehicles = companyChildren2.vehicles.filter(
                (vehicle) => param.includes(vehicle.id)
              );
            });
          }
        });
      }
    });
    return menu;
  }
}
