import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { FuncionalidadeTipo } from "../../models/FuncionalidadeTipo.model";
import { Validation } from "./validation";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-create-funcionalidade-tipo",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CreateFuncionalidadeTipoComponent implements OnInit {
  public funcionalidadeTipoForm: FormGroup;
  public title = "Cadastrar Tipo Funcionalidade";
  public errorMessage;
  private validation: Validation;
  private apiBaseService: ApiBaseService;

  ngOnInit(): void {
    this.funcionalidadeTipoForm = this.formBuilder.group(
      this.validation.rules()
    );
  }
  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }

  public async submitForm(): Promise<void> {
    this.spinner.show();
    try {
      this.coverterUpperCase(this.funcionalidadeTipoForm);
      const values = this.funcionalidadeTipoForm.value;
      const post = {
        ...values,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      await this.apiBaseService.post<FuncionalidadeTipo>(
        new FuncionalidadeTipo()["nomeConsultaApiBase"],
        post
      );

      this.spinner.hide();
      this.router.navigate(["/funcionalidade-tipo"]);
    } catch (error) {
      this.errorMessage = error.message;
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public coverterUpperCase(funcionalidadeTipoForm: FormGroup) {
    console.log(funcionalidadeTipoForm);

    funcionalidadeTipoForm
      .get("nmFuncionalidadeTipo")
      .setValue(
        funcionalidadeTipoForm.get("nmFuncionalidadeTipo").value.toUpperCase()
      );
    funcionalidadeTipoForm
      .get("dsFuncionalidadeTipo")
      .setValue(
        funcionalidadeTipoForm.get("dsFuncionalidadeTipo").value.toUpperCase()
      );
  }
}
