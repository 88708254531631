import { Validators } from '@angular/forms';

export class Validation {
    public rules = (): object => {
        return {
            nmModulo: ['', Validators.required],
            dsModulo: ['', Validators.required],
            cdPath: [''],
            cdIcone: [''],
        };
    };
}
