import { ToolsService } from "./../../services/migrate/tools.service";
import { CoreService } from "./../../services/migrate/api.core.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { USER_DATA } from "../../utils/constants.util";
import {
  FIELDS,
  NO_SELECTED_COMPANY,
  VHEICLE_WITHOUT_TERMINAL_NOT_FOUND_MSG,
} from "./constants.terminal";
import Swal from "sweetalert2";

import { ApiService } from "../../services/api.service";
import { Validation } from "./validation";
import { FormateDate } from "src/app/utils/date/formateDate";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { Company } from "src/app/models/Company.model";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import { TrackerModelService } from "../../services/tracker-model.service";
import { TrackerModel } from "../../models/tracker-model";
import { PageEvent } from "@angular/material/paginator";
import { TrackerModelPaginated } from "../../models/tracker-model-paginated";
import { toCamel } from "snake-camel";

export class TerminalCore {
  public apiBaseService: ApiBaseService;
  public activedView = false;
  public activedClone = false;
  public activedEdit = false;
  public swal = true;

  public titleVar = "";

  public terminalForm: FormGroup;
  public validation: Validation;
  public serviceCore: CoreService;
  public service: ApiService;

  public terminal: any;
  public terminalId: any;
  public idVeiculo: any;

  public terminalProtocoloSelected: any;
  public empresaSelected: any;
  public empresaFilterSelected;
  public empresaSelectedEdit: any;
  public veiculoSelected: number;
  public errorMessage;

  public companys = new Array<any>();
  public terminalProtocols = new Array<any>();
  public terminalIcons = new Array<any>();
  public vehicles = new Array<any>();
  public vehiclesEdit = new Array<any>();
  public companiesFilter: any = [];
  public companiesSelect: any = [];

  public vehiclesForm = new Array<any>(NO_SELECTED_COMPANY);
  public records = new Array<any>();

  public envioLote = true;
  public cond = true;
  public compLote = 0;
  public tmp = 0;
  public nomeArq: string = "";
  public btnText = "Processar";
  public btnDisabled = false;
  public cadsituacao = 0;

  public trackerModelService: TrackerModelService;
  public trackerModelsPaginated: TrackerModelPaginated;
  public trackerModelSelected: TrackerModel;

  constructor(
    apiserviceCore: CoreService,
    apiService: ApiService,
    formBuilder: FormBuilder,
    public tools: ToolsService /*   private doenload_cvs: DownloadCsv, */,
    baseService: ApiBaseService,
    trackerModelService: TrackerModelService
  ) {
    this.serviceCore = apiserviceCore;
    this.service = apiService;
    this.validation = new Validation();
    this.apiBaseService = baseService;
    this.trackerModelService = trackerModelService;
  }

  /* API */

  public async loadDataElements() {
    try {
      this.tools.builder().spinner.show();

      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      const result = await this.apiBaseService.get<any>({
        url: new Company()["nomeConsultaApiBaseGetAll"],
        params,
      });

      //const terminalProtocolos = await this.service.get<any>({
      //  url: "terminal-protocolo",
      //});

      this.trackerModelService.getAll().subscribe((t) => {
        this.trackerModelsPaginated = toCamel(t) as TrackerModelPaginated;
      });

      const vehicles: any = await this.apiBaseService.get<any>({
        url: "veiculos/getAllWithoutTerminal",
      });

      this.vehiclesForm = vehicles["content"];
      this.vehicles = vehicles["content"];
      this.vehiclesEdit = vehicles["content"];
      this.companys = result["content"];
      //this.terminalProtocols = terminalProtocolos.content;

      this.verifyIfLoadTerminalById();

      this.companiesSelect = [
        { name: "Divisão", value: 1 },
        { name: "Matriz", value: 2 },
        { name: "Filial", value: 3 },
      ];

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }

  public async loadTerminal(id): Promise<void> {
    try {
      this.tools.builder().spinner.show();
      const response = await this.serviceCore.get<any>({
        url: `v2/terminals/${id}`,
      });

      const vehiclesEdit: any = await this.apiBaseService.get<any>({
        url: `veiculos/${response.idVeiculo}`,
      });

      const vehicles: any = await this.apiBaseService.get<any>({
        url: "veiculos/getAllWithoutTerminal",
        params: {
          idEmpresa: response.idEmpresa,
        },
      });

      this.terminal = response;
      this.vehicles = vehicles;
      this.vehiclesForm = vehicles;

      console.log(this.terminal);

      this.companys.forEach((element) => {
        if (element.id == this.terminal.idEmpresa) {
          if (element.cnpj == null) {
            this.titleVar = "Divisão";
          }

          if (element.cnpj != null && element.idEmpresaMatriz == null) {
            this.titleVar = "Matriz";
          }

          if (element.cnpj != null && element.idEmpresaMatriz != null) {
            this.titleVar = "Filial";
          }
        }
      });

      this.vehiclesForm.push(vehiclesEdit);

      console.log(this.vehiclesForm);

      if (this.vehicles.length != 0) {
        this.vehicles.forEach((veiculos) => {
          if (veiculos.id == this.terminal.idVeiculo) {
            this.veiculoSelected = veiculos;
          }
        });
      } else {
        this.msgSwalWarningEdit();
      }

      this.tools.builder().spinner.hide();

      this.loadIfTerminaId();
    } catch (error) {
      this.tools.builder().spinner.hide();
      this.tools.builder().openToast.emit(error.message);
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public async register(): Promise<void> {
    this.tools.builder().spinner.show();

    try {
      await this.serviceCore.post<any>(
        `v2/terminals?userId=${USER_DATA().userId}`,
        this.buildPayload()
      );

      this.tools.builder().spinner.hide();

      this.tools.builder().router.navigate(["/terminal"]);
    } catch (error) {
      this.tools.builder().spinner.hide();
      this.tools.builder().openToast.emit(error.message);

      this.errorMessage = error.message;

      throw new EvalError(error);
    }
  }

  public async update() {
    try {
      this.tools.builder().spinner.show();

      const id = Math.floor(this.terminalId);
      const paylpod = this.buildPayloadEdit();
      console.log(paylpod);
      paylpod["id"] = id;

      await this.serviceCore.patch<any>(
        `${this.terminalId}?userId=${USER_DATA().userId}`,
        `v2/terminals/`,
        paylpod
      );
      this.tools.builder().router.navigate(["/terminal"]);
    } catch (error) {
      this.tools.builder().spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  /* functions */

  public changeCompany() {
    console.log(this.empresaSelected);
    var idEmpresa = this.empresaSelected;

    if (this.empresaSelected == this.terminal?.idEmpresa && this.activedEdit) {
      this.filterVehiclesWhenEdit();
      this.swal = true;
      return;
    }

    this.loadVehicles(idEmpresa);
    //this.filterVehicles();
    this.swal = true;
  }

  public changeCompanyEdit() {
    var idEmpresa = this.empresaSelectedEdit;

    if (
      this.empresaSelectedEdit == this.terminal?.idEmpresa &&
      this.activedEdit
    ) {
      this.filterVehiclesWhenEdit();
      this.swal = true;
      return;
    }

    this.loadVehicles(idEmpresa);
    //this.filterVehicles();
    this.swal = true;
  }

  private async loadVehicles(empresa: any) {
    try {
      console.log(empresa);

      var vehicles;

      if (empresa.cnpj != null) {
        vehicles = await this.apiBaseService.get<any>({
          url: "veiculos/getAllWithoutTerminal",
          params: {
            idEmpresa: empresa.id,
          },
        });
      } else {
        vehicles = await this.apiBaseService.get<any>({
          url: "veiculos/getAllWithoutTerminal",
          params: {
            idDivisao: empresa.id,
          },
        });
      }

      if (vehicles.length != 0) {
        this.vehiclesForm = vehicles;
      } else {
        this.vehiclesForm = vehicles;
        this.msgSwalWarning();
      }
    } catch (error) {
      this.tools.builder().spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  private loadIfTerminaId() {
    this.fillUserData();

    if (this.activedView) {
      this.setValuesInFormGroupLoadInView();
    } else {
      this.setValuesInFormGroupLoad();

      if (this.activedClone) {
        this.filterVehicles();
      }

      if (this.activedEdit) {
        //this.filterVehiclesWhenEdit();
      }
    }
  }

  public fillUserData(): void {
    this.trackerModelSelected = this.getSelectedTrackerModel();
    this.terminalProtocoloSelected = this.terminal?.idTerminalProtocolo;
    this.empresaSelected = this.terminal?.idEmpresa;
    this.veiculoSelected = this.terminal?.idVeiculo;
  }

  private getSelectedTrackerModel(): TrackerModel {
    return this.trackerModelsPaginated.content.find(
        (f) => f.uuid == this.terminal?.trackerModelUuid
    );
  }

  public setFormValuesDefault() {
    this.terminalForm.controls["minutoAlarmeParada"].setValue("10");
    this.terminalForm.controls["minutoAlarmePontoMorto"].setValue("10");
    this.terminalForm.controls["fusoHorarioEnum"].setValue("0");
  }

  public filterVehicles(): void {
    if (this.vehicles.length > 0) {
      this.vehiclesForm = this.vehicles.filter(
        (option: any) =>
          option.idEmpresa == this.empresaSelected && !option.terminalId
      );
    }

    if (!this.vehiclesForm.length) {
      if (
        this.terminal?.idEmpresa != this.empresaSelected &&
        this.activedEdit
      ) {
        this.veiculoSelected = 0.1;
        this.vehiclesForm = [NO_SELECTED_COMPANY];
      } else {
        if (this.activedEdit) {
          this.filterVehiclesWhenEdit();
        }
      }

      if (!this.activedEdit) {
        this.veiculoSelected = 0.1;
        this.vehiclesForm = [NO_SELECTED_COMPANY];
      }

      this.msgSwalWarning();
    }
  }

  public filterVehiclesWhenEdit(): void {
    if (this.vehicles.length > 0) {
      this.vehiclesForm = [
        this.vehicles
          .filter((option: any) => option.idEmpresa == this.empresaSelected)
          .find((e) => e.id == this.veiculoSelected),
      ].map((e) => {
        e["id"] = Number(e["id"]);

        return e;
      });
    }

    if (!this.vehiclesForm.length) {
      this.veiculoSelected = 0.1;
      this.vehiclesForm = [NO_SELECTED_COMPANY];
    }
  }

  public verifyVehicle(close?) {
    if (close) {
      if (
        this.terminal?.idVeiculo == this.veiculoSelected &&
        this.activedEdit
      ) {
        this.filterVehiclesWhenEdit();
      }
    } else {
      setTimeout(() => {
        this.filterVehicles();
      }, 100);
    }
  }

  public verifyIfLoadTerminalById() {
    if (this.terminalId) {
      this.loadTerminal(this.terminalId);
    }
  }

  private setValuesInFormGroupLoad() {
    FIELDS.forEach((element) => {
      if (element == "conectado" || element == "instalado") {
        this.terminalForm.controls[element].setValue(
          FormateDate.formateDate(this.terminal[element])
        );
      } else {
        this.terminalForm.controls[element].setValue(this.terminal[element]);
      }
    });
  }

  private setValuesInFormGroupLoadInView() {
    FIELDS.forEach((element) => {
      switch (element) {
        case "idEmpresa":
          const company = this.companys.filter(
            (c) => c?.id == this.terminal[element]
          )[0];
          this.terminalForm.controls[element].setValue(company?.nmNome);
          return;

        case "idTerminalIcone":
          const icon = this.terminalIcons.filter(
            (c) => c?.id == this.terminal[element]
          )[0];
          this.terminalForm.controls[element].setValue(icon?.nmNome);
          return;

        case "idTerminalProtocolo":
          const protocol = this.terminalProtocols.filter(
            (c) => c?.id == this.terminal[element]
          )[0];
          this.terminalForm.controls[element].setValue(protocol?.nmNome);
          return;

        case "idVeiculo":
          const vehicle = this.vehicles.filter(
            (c) => c?.id == this.terminal[element]
          )[0];
          this.terminalForm.controls[element].setValue(vehicle?.cdPlaca);
          return;

        default:
          if (element == "conectado" || element == "instalado") {
            this.terminalForm.controls[element].setValue(
              FormateDate.formateDate(this.terminal[element])
            );
          } else {
            this.terminalForm.controls[element].setValue(
              this.terminal[element]
            );
          }

          break;
      }
    });
  }

  /* utils */

  public buildPayload() {
    const vheicle = this.vehiclesForm.find(
      ({ id }) => Number(id) == Number(this.veiculoSelected)
    );

    if (this.empresaSelected.cnpj != null) {
      return {
        ...this.terminalForm.value,
        idTerminalProtocolo: this.trackerModelSelected.legacyProtocolId,
        trackerModelUuid: this.trackerModelSelected.uuid,
        idEmpresa: this.empresaSelected.id,
        idTerminalIcone: parseInt(
          this.terminalForm.controls["idTerminalIcone"].value
        ),
        idVeiculo: this.veiculoSelected,
        placa: vheicle.cdPlaca,
      };
    } else {
      return {
        ...this.terminalForm.value,
        idTerminalProtocolo: this.trackerModelSelected.legacyProtocolId,
        trackerModelUuid: this.trackerModelSelected.uuid,
        idDivisao: this.empresaSelected.id,
        idEmpresa: null,
        idTerminalIcone: parseInt(
          this.terminalForm.controls["idTerminalIcone"].value
        ),
        idVeiculo: this.veiculoSelected,
        placa: vheicle.cdPlaca,
      };
    }
  }

  public buildPayloadEdit() {
    const vheicle = this.vehiclesForm.find(
      ({ id }) => Number(id) == Number(this.veiculoSelected)
    );

    console.log(this.empresaSelected);

    console.log(this.empresaSelectedEdit);

    if (
      this.empresaSelectedEdit != undefined ||
      this.empresaSelectedEdit != null
    ) {
      return this.converterCompanyOrDivisionNew(
        this.empresaSelectedEdit,
        vheicle
      );
    } else {
      return this.converterCompanyOrDivision(this.empresaSelected, vheicle);
    }
  }

  public converterCompanyOrDivisionNew(companyOrDivision: any, vheicle: any) {
    if (companyOrDivision.cnpj != null) {
      return {
        ...this.terminalForm.value,
        idTerminalProtocolo: this.trackerModelSelected.legacyProtocolId,
        trackerModelUuid: this.trackerModelSelected.uuid,
        idEmpresa: companyOrDivision.id,
        idTerminalIcone: parseInt(
          this.terminalForm.controls["idTerminalIcone"].value
        ),
        idVeiculo: this.veiculoSelected,
        placa: vheicle.cdPlaca,
      };
    } else {
      return {
        ...this.terminalForm.value,
        idTerminalProtocolo: this.trackerModelSelected.legacyProtocolId,
        trackerModelUuid: this.trackerModelSelected.uuid,
        idDivisao: companyOrDivision.id,
        idEmpresa: null,
        idTerminalIcone: parseInt(
          this.terminalForm.controls["idTerminalIcone"].value
        ),
        idVeiculo: this.veiculoSelected,
        placa: vheicle.cdPlaca,
      };
    }
  }

  public converterCompanyOrDivision(companyOrDivision: any, vheicle: any) {
    if (this.terminal.idDivisao != null) {
      return {
        ...this.terminalForm.value,
        idTerminalProtocolo: this.trackerModelSelected.legacyProtocolId,
        trackerModelUuid: this.trackerModelSelected.uuid,
        idEmpresa: companyOrDivision.id,
        idTerminalIcone: parseInt(
          this.terminalForm.controls["idTerminalIcone"].value
        ),
        idVeiculo: this.veiculoSelected,
        placa: vheicle.cdPlaca,
      };
    } else {
      return {
        ...this.terminalForm.value,
        idTerminalProtocolo: this.trackerModelSelected.legacyProtocolId,
        trackerModelUuid: this.trackerModelSelected.uuid,
        idDivisao: companyOrDivision.id,
        idEmpresa: null,
        idTerminalIcone: parseInt(
          this.terminalForm.controls["idTerminalIcone"].value
        ),
        idVeiculo: this.veiculoSelected,
        placa: vheicle.cdPlaca,
      };
    }
  }

  public numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }

    return true;
  }

  public msgSwalWarning() {
    if (this.swal && this.empresaSelected) {
      Swal.fire({
        icon: "warning",
        title: "Aviso",
        html: `<h6> ${VHEICLE_WITHOUT_TERMINAL_NOT_FOUND_MSG} </h6>`,
        showCloseButton: false,
        showCancelButton: true,
        reverseButtons: false,
        cancelButtonText: "Entendi",
        showConfirmButton: true,
        confirmButtonText: "Cadastrar novo veículo",
        focusConfirm: false,
      }).then((action) => {
        if (action.isConfirmed) {
          this.tools.builder().router.navigate(["/vehicles/create"]);
        }

        this.swal = false;
      });
    }
  }

  public msgSwalWarningEdit() {
    Swal.fire({
      icon: "warning",
      title: "Aviso",
      html: `<h6> ${VHEICLE_WITHOUT_TERMINAL_NOT_FOUND_MSG} </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: false,
      cancelButtonText: "Entendi",
      showConfirmButton: true,
      confirmButtonText: "Cadastrar novo veículo",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.tools.builder().router.navigate(["/vehicles/create"]);
      }

      this.swal = false;
    });
  }

  public filterCompany() {
    var idEmpresa = this.empresaFilterSelected;
    var empresas = [];

    if (idEmpresa == "Divisão") {
      this.companys.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Matriz") {
      this.companys.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Filial") {
      this.companys.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    console.log(this.companiesFilter);
  }
}
