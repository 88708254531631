<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="atividadeForm">
                    <h2 class="mb-3">Nova Atividade</h2>
                    <div class="form-row">
                        <div class="form-group col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Código</mat-label>
                                <input formControlName="cdCodigo" matInput placeholder="Código" maxlength="2" minlength="2" (keypress)="numberOnly($event)" required  [readonly]=true [disabled]=true/>
                            </mat-form-field>

                            <validation-errors [form]="atividadeForm" controlLabel="Código" controlName="cdCodigo">
                            </validation-errors>
                        </div>
                        <div class="form-group col-lg-10">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input formControlName="nmNome" matInput placeholder="Nome" maxlength="120"  [readonly]=true [disabled]=true/>
                            </mat-form-field>

                            <validation-errors [form]="atividadeForm" controlLabel="Nome" controlName="nmNome">
                            </validation-errors>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Mensagem</mat-label>
                                <input formControlName="dsObservacao" matInput maxlength="500"
                                    placeholder="Observação"  [readonly]=true [disabled]=true/>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <mat-slide-toggle name="ativo" formControlName="ativo" color="primary" [readonly]=true [disabled]=true>
                                Ativo?
                            </mat-slide-toggle>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>