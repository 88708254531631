import { MatStepper } from "@angular/material/stepper";
import { STORAGE_KEY_ID_PERFIL } from "./../../storageCore/constStorageKeys";
import { ToolsService } from "./../../services/migrate/tools.service";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import * as FileSaver from "file-saver";
import storageX from "../../storageCore/storageX";
import { Page } from "../../utils/paginate";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CoreService } from "src/app/services/migrate/api.core.service";
import ApiService from "src/app/services/api.service";
import { Empresa } from "src/app/models/markerModel";
import { EnvioComandoTerminal } from "src/app/models/EnvioComandoTerminal.model";
import { USER_DATA } from "src/app/utils/constants.util";
import { MatDialog } from "@angular/material/dialog";
import { User } from "src/app/models/User.model";

@Component({
  selector: "app-command-report",
  templateUrl: "./command-report.component.html",
  styleUrls: ["./command-report.component.scss"],
})
export class CommandReportComponent implements OnInit, AfterViewInit {
  public controlEmpresa = new FormControl();
  public controlVeiculo = new FormControl();
  public controlUsuario = new FormControl();

  public colsHeader = [
    { field: "terminal.veiculo.cdPlaca", header: "Placa" },
    { field: "usuario.nmUsuario", header: "Usuário" },
    { field: "dsTextoEnvio", header: "Texto Envio" },
    { field: "terminalComandoStatus.dsDescricao", header: "Status" },
    { typefield: "date", field: "dtAdicao", header: "Data Adição" },
    { typefield: "date", field: "dtConfirmacao", header: "Data Confirmação" },
  ];

  public companys = new Array<any>();
  public companysFiltered = new Observable<Array<any>>();
  public vehicles = new Array<any>();
  public vehiclesFiltered = new Observable<Array<any>>();
  public users = new Array<any>();
  public usersFiltered = new Observable<Array<any>>();
  private profileId = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
  public paramsLoad = {
    profileId: this.profileId,
    size: 9999,
    page: 0,
    sort: "nome",
    sortDirection: "ASC",
  };

  public consultaFormGroup: FormGroup;

  public serviceCore: CoreService;
  public serviceApi: ApiService;

  public pagination = new Page();
  public matStepper: MatStepper;
  public matAutocomplete: MatAutocomplete;
  public filterDialog = true;
  public items;

  public resultado = new Array<EnvioComandoTerminal>();

  constructor(
    private tools: ToolsService,
    apiserviceCore: CoreService,
    serviceApi: ApiService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {
    this.serviceCore = apiserviceCore;
    this.serviceApi = serviceApi;
  }

  ngAfterViewInit(): void {
    this.filterDialog = true;
  }

  ngOnInit() {
    this.pagination.currentPage = 0;
    this.pagination.size = 10;

    this.tools.builder().spinner.show();
    this.loadMenuItems();
    this.loadDataElements();

    this.consultaFormGroup = this.formBuilder.group({
      dataInicio: ["", Validators.required],
      dataFim: ["", Validators.required],
    });

    this.companysFiltered = this.controlEmpresa.valueChanges.pipe(
      map((value) => (typeof value === "string" ? value : value.nmNome)),
      map((name) =>
        name ? this.filterValuesEmpresa(name) : this.companys.slice()
      )
    );

    this.vehiclesFiltered = this.controlVeiculo.valueChanges.pipe(
      map((value) => (typeof value === "string" ? value : value.cdPlaca)),
      map((name) =>
        name ? this.filterValuesTerminal(name) : this.vehicles.slice()
      )
    );

    this.usersFiltered = this.controlUsuario.valueChanges.pipe(
      map((value) => (typeof value === "string" ? value : value.cdUsuario)),
      map((name) =>
        name ? this.filterValuesUsuario(name) : this.users.slice()
      )
    );
  }

  public filterValuesEmpresa(value: string): Array<any> {
    const filterValue = value.toLowerCase();
    return this.companys
      .sort((a, b) => a.nmNome.localeCompare(b.nmNome))
      .filter((client) => client.nmNome.toLowerCase().includes(filterValue));
  }

  displayEmpresa(empresa: Empresa): string {
    return empresa && empresa.nmNome ? empresa.nmNome : "";
  }

  public filterValuesTerminal(value: string): Array<any> {
    const filterValue = value.toLowerCase();
    return this.vehicles
      .sort((a, b) => a.cdPlaca.localeCompare(b.cdPlaca))
      .filter((client) => client.cdPlaca.toLowerCase().includes(filterValue));
  }

  public filterValuesUsuario(value: string): Array<any> {
    const filterValue = value.toLowerCase();
    return this.users
      .sort((a, b) => a.cdUsuario.localeCompare(b.cdUsuario))
      .filter((client) => client.cdUsuario.toLowerCase().includes(filterValue));
  }

  displayTerminal(terminal: any): string {
    var terminalPlaca = terminal.cdPlaca ? terminal.cdPlaca : "";
    var terminalFrota = terminal.frota ? terminal.frota : "";

    return terminal && terminal.cdPlaca
      ? terminalPlaca + "/" + terminalFrota
      : "";
  }

  displayUsuario(usuario: any): string {
    return usuario && usuario.cdUsuario ? usuario.cdUsuario : "";
  }

  public paginate(event) {
    const params = this.tools.builder().params;

    this.pagination.currentPage = event.page;
    this.pagination.size = event.rows;
  }

  public onOpenDialogFilter() {
    this.filterDialog = true;
  }

  private loadMenuItems() {
    this.items = [
      {
        label: "Filtrar",
        icon: "pi pi-filter",
        command: () => {
          this.onOpenDialogFilter();
        },
      },
      {
        label: "Exportar",
        items: [
          {
            label: "Excel",
            icon: "pi pi-file-excel",
            command: () => {
              this.exportExcel();
            },
          },
          {
            label: "PDF",
            icon: "pi pi-file-pdf",
            command: () => {
              this.exportPdf();
            },
          },
        ],
      },
    ];
  }

  exportPdf() {
    if (this.resultado.length > 0) {
      import("jspdf").then((jsPDF) => {
        import("jspdf-autotable").then((x) => {
          const doc = new jsPDF.default("l", "pt");
          doc.autoTable({
            startY: 10,
            html: ".table",
            useCss: true,
          });
          doc.save(`relatorio_envio_comandos.pdf`);
        });
      });
    }
  }

  exportExcel() {
    if (this.resultado.length > 0) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.table_to_sheet(
          document.getElementsByClassName("table")[0]
        );
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        this.saveAsExcelFile(excelBuffer, `relatorio_envio_comandos`);
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      `relatorio_envio_comandos` +
        "_export_" +
        new Date().getTime() +
        EXCEL_EXTENSION
    );
  }

  public async onConsultar() {
    if (this.consultaFormGroup.valid) {
      this.tools.builder().spinner.show();
      const response = await this.serviceApi.get<EnvioComandoTerminal[]>({
        url: "envio-comando-terminal/buscar-envio-comando-terminal",
        params: {
          idTerminal:
            this.controlVeiculo.value != null
              ? this.controlVeiculo.value.id
              : 0,
          idUsuario:
            this.controlUsuario.value != null
              ? this.controlUsuario.value.id
              : 0,
          startDate: (this.consultaFormGroup.value.dataInicio + ":00").replace(
            "T",
            " "
          ),
          endDate: (this.consultaFormGroup.value.dataFim + ":00").replace(
            "T",
            " "
          ),
        },
      });

      this.resultado = response;

      this.resultado.forEach(async (element) => {
        const user = await this.serviceApi.get<User>({
          url: "usuario/" + element.idUsuarioCriacao,
        });
        element.nomeUsuario = user.cdUsuario;
      });
      this.tools.builder().spinner.hide();
    }
  }

  public convertTimeStamp(timestamp) {
    var newDate = new Date();
    newDate.setTime(timestamp);
    return (
      ("0" + newDate.getDate()).slice(-2) +
      "/" +
      ("0" + newDate.getMonth()).slice(-2) +
      "/" +
      newDate.getFullYear() +
      " " +
      ("0" + newDate.getHours()).slice(-2) +
      ":" +
      ("0" + newDate.getMinutes()).slice(-2)
    );
  }

  public async loadDataElements() {
    try {
      this.tools.builder().spinner.show();

      const companys = await this.serviceCore.get<any>({
        url: "v2/companys",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sort: "nome",
          sortDirections: "ASC",
        },
      });

      this.companys = companys?.elements;
      this.companys = this.companys.sort((a, b) =>
        a.nmNome.localeCompare(b.nmNome)
      );
      this.controlEmpresa.setValue("");

      const terminals = await this.serviceCore.get<any>({
        url: "v2/terminals",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sortDirections: "ASC",
        },
      });

      this.vehicles = terminals.elements;
      this.vehicles = this.vehicles.sort((a, b) =>
        a.cdPlaca.localeCompare(b.cdPlaca)
      );
      this.controlVeiculo.setValue("");

      const users = await this.serviceCore.get<any>({
        url: "v2/users",
        params: {
          profileId: USER_DATA().profileId,
          size: "5000",
          page: "0",
          sortDirections: "ASC",
        },
      });

      this.users = users.elements;
      this.users = this.users.sort((a, b) =>
        a.cdUsuario.localeCompare(b.cdUsuario)
      );
      this.controlUsuario.setValue("");

      this.tools.builder().spinner.hide();
    } catch (error) {
      this.tools.builder().spinner.hide();
      throw new EvalError(error);
    }
  }
}
