<agm-marker class="marker" [latitude]="currentPositionSubject.value?.latitude"
  [longitude]="currentPositionSubject.value?.longitude" [agmFitBounds]="true"
  [iconUrl]="$any(currentPositionSubject.value?.pin)" [markerClickable]="clickable">

  <agm-overlay [latitude]="currentPositionSubject.value.latitude" [longitude]="currentPositionSubject.value.longitude"
    *ngIf="this.showMarkerLabel">
    <div class="block" style="background-color: black; padding: 5px; border-radius: 15px;">
      <strong style="color: white;">{{ currentPositionSubject.value.placa }} / {{ currentPositionSubject.value.frota ==
        null ? 'Frota não cadastrada' : currentPositionSubject.value.frota}}</strong>
    </div>
  </agm-overlay>

  <!-- Janela de informações, após o clique no marker  -->
  <agm-snazzy-info-window *ngIf="!cloneMarker" #infoWindow [maxHeight]="600" [maxWidth]="900" [padding]="1"
    (beforeOpen)="this.beforeOpenModal()" (afterClose)="this.afterClose()" [closeWhenOthersOpen]="true">
    <!-- Conteudo do modal para os marcadores de posição atual -->
    <ng-template>
      <h4 style="font-size: 1rem;">Informações do Veículo</h4>
      <span *ngIf="!this.terminalMarker.load">Carregando terminal, aguarde...</span>
      <span *ngIf="this.terminalMarker.load" style="float: right;
                    position: relative;
                    top: -17px;">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon style="font-size: 25px;">more_vert</mat-icon>
          <mat-menu #menu="matMenu">
            <div *ngIf="!this.terminalMarker.load">
              <button [disabled]="!this.terminalMarker.load" mat-menu-item>
                Carregando terminal, aguarde...
              </button>
            </div>
            <div *ngIf="this.terminalMarker.load">
              <button mat-menu-item
                *ngIf="this.permissionService.hasPermission('TRAJETO_BOTAO_FUNCAO', this.permissions)"
                (click)="openPathDialog(infoWindow)">
                Trajeto
              </button>
              <button (click)="openPathToDialog()" mat-menu-item>
                Caminho Até
              </button>
              <button (click)="openTerminalDialog()" mat-menu-item
                *ngIf="this.permissionService.hasPermission('SELECIONAR_BOTAO_FUNCAO', this.permissions)">
                Configurações
              </button>
              <button (click)="openStreetViewDialog()" mat-menu-item>
                Visão da Rua
              </button>
              <button (click)="traking(infoWindow)" mat-menu-item
                *ngIf="this.permissionService.hasPermission('RASTREAR_BOTAO_FUNCAO', this.permissions)">Rastrear</button>
              <button disabled (click)="trakingAndPath()" mat-menu-item>
                Rastrear e Traçar Rota
              </button>
              <button (click)="openAddElectronicFenceDialog()"
                *ngIf="this.permissionService.hasPermission('CERCA_BOTAO_FUNCAO', this.permissions)" mat-menu-item>
                Adicionar Cerca Eletrônica
              </button>
            </div>
          </mat-menu>
        </button>
      </span>

      <table *ngIf="!this.terminalMarker.historic && this.terminalMarker.load" class="table">
        <tbody>
          <tr>
            <td width="200">
              <strong>Placa: </strong>
              <small>
                <span *ngIf="this.terminalMarker.placa">{{ terminalMarker.placa }}</span>
              </small>
            </td>
            <td width="200">
              <strong>Frota: </strong>
              <small>
                <span> {{ terminalMarker.frota == null ? 'Frota não cadastrada' : terminalMarker.frota}}
                </span>
              </small>
            </td>
          </tr>


          <tr>
            <td width="200">
              <strong>Motorista</strong>
              <small *ngIf="terminalMarker.id_motorista !== undefined">
                <span>{{ terminalMarker.nome_motorista }}</span>
                <span>--</span>
              </small>
            </td>
            <td width="200">
              <strong>Data: </strong>
              <small>
                <span *ngIf="terminalMarker.dataAtualizacao">
                  {{ terminalMarker.dataAtualizacao }}
                </span>
              </small>
            </td>
          </tr>


          <tr>
            <td width="200">
              <strong>Velocidade</strong>
              <span>
                {{
                this.terminalMarker.nrEnumTipoVelocidade == 1
                ? this.terminalMarker.velocidade + ' Km/h'
                : this.terminalMarker.velocidadeObd + ' Km/h'
                }}
              </span>
            </td>
            <td width="200">
              <strong>Odômetro</strong>
              <small>
                <span>
                  {{
                  this.terminalMarker.nrEnumTipoVelocidade == 1
                  ? this.terminalMarker.odometro
                  : this.terminalMarker.odometroVeiculo
                  }}
                </span>
              </small>
            </td>
          </tr>

          <tr>
            <td width="200">
              <strong>Consumo Total</strong>
              <small>
                <span *ngIf="this.terminalMarker.combustivelConsumido">
                  {{ this.terminalMarker.combustivelConsumido.toString() + ' L' }}
                </span>
              </small>
            </td>
            <td width="200">
              <strong>Nível do Tanque: </strong>
              <small>
                <span>
                  {{ this.terminalMarker.nivelCombustivel == null
                  ? 0
                  : this.terminalMarker.nivelCombustivel}}%
                </span>
              </small>
            </td>
          </tr>

          <tr>
            <td width="200">
              <strong>Atividade</strong>
              <small>
                <span *ngIf="this.terminalMarker.atividade">{{ this.terminalMarker.atividade }}</span>
              </small>
            </td>
            <td width="200">
              <strong>Nível da bateria: </strong>
              <small>
                <span *ngIf="this.terminalMarker.nivelBateria">
                  {{ this.terminalMarker.nivelBateria }}%
                </span>
              </small>
            </td>
          </tr>

          <tr>
            <td width="200">
              <strong>Bateria do veículo: </strong>
              <small>
                <span *ngIf="this.terminalMarker.voltagemBateriaVeiculo">
                  {{ this.terminalMarker.voltagemBateriaVeiculo }}v
                </span>
                <span *ngIf="!this.terminalMarker.voltagemBateriaVeiculo">
                  Não possui registro
                </span>
              </small>
            </td>
            <td width="200">
              <strong *ngIf="terminalMarker.nome_empresa">Empresa: </strong>
              <small>
                <span *ngIf="terminalMarker.nome_empresa"> {{ terminalMarker.nome_empresa }}
                </span>
              </small>
              <strong *ngIf="terminalMarker.nome_divisao">Divisão: </strong>
              <small>
                <span *ngIf="terminalMarker.nome_divisao"> {{ terminalMarker.nome_divisao }}
                </span>
              </small>
            </td>
          </tr>

          <tr>
            <td width="200">
              <strong>Endereço: </strong>
              <small>
                <a [href]="this.linkMap" target="blank">{{ addressDisplay }}</a>
                <mat-button-toggle (click)="this.searchAddress()" [class.spinner]="loading" *ngIf="!addressDisplay"
                  value="search">
                  <mat-icon>search</mat-icon>
                </mat-button-toggle>
              </small>
            </td>
            <td width="200">
              <strong>Categoria Veículo: </strong>
              <small>
                <span> {{ terminalMarker.categoriaVeiculo == null ? 'Categoria não identificada' :
                  terminalMarker.categoriaVeiculo}}
                </span>
              </small>
            </td>
          </tr>

          <tr *ngIf="this.terminalMarker.id.toString().indexOf('gr') == -1">
            <td>
              <button (click)="this.moreInfo()" class="more_info" mat-button>Mais informações</button>
            </td>
          </tr>

          <tr *ngIf="this.terminalMarker.id.toString().indexOf('gr') > -1">
            <td>
              <button (click)="this.removeMarkerLbs()" class="more_info" mat-button>
                Remover Marcador
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="this.more_info && !this.terminalMarker.historic" class="table">
        <tbody>
          <tr>
            <td width="200">
              <strong>IMEI</strong>
              <small>
                <span *ngIf="this.terminalMarker.id_terminal">{{
                  this.terminalMarker.terminal_imei
                  }}</span>
              </small>
            </td>
            <td width="200">
              <strong>Nr SIM Card</strong>
              <small>
                {{ this.terminalMarker.terminal_simCard }}
              </small>
            </td>
          </tr>

          <tr>
            <td width="200">
              <strong>Eventos</strong>
              <small>
                <span>
                  {{ !this.terminalMarker.terminalAlarme1 ? '' : this.terminalMarker.terminalAlarme1?.dsMensagem}}
                  {{ !this.terminalMarker.terminalAlarme2 ? '' : ' - ' +
                  this.terminalMarker.terminalAlarme2?.dsMensagem}}
                  {{ !this.terminalMarker.terminalAlarme3 ? '' : ' - ' +
                  this.terminalMarker.terminalAlarme3?.dsMensagem}}
                  {{ !this.terminalMarker.terminalAlarme4 ? '' : ' - ' +
                  this.terminalMarker.terminalAlarme4?.dsMensagem}}
                  {{ !this.terminalMarker.terminalAlarme5 ? '' : ' - ' +
                  this.terminalMarker.terminalAlarme5?.dsMensagem}}
                </span>
              </small>
            </td>
            <td width="200">
              <strong>Telemetria</strong>
              <small>
                <span *ngIf="terminalMarker.telemetry">
                  {{ terminalMarker.telemetry }}
                </span>
              </small>

              <small>
                <a (click)="this.openTelemetriaDialog()" style="width: 10em; cursor: pointer;" color="primary">
                  Visualizar
                </a>
              </small>
            </td>
          </tr>

          <tr>
            <td width="200">
              <strong>Direção/Altitude</strong>
              <small>
                <span *ngIf="this.terminalMarker.direcao > -1">{{ this.terminalMarker.direcao }} °</span> / <span
                  *ngIf="this.terminalMarker.altitude > -1">{{ this.terminalMarker.altitude }} m</span>
              </small>
            </td>
            <td>
              <strong>KML</strong>
              <a (click)="downloadKml()" style="width: 10em;" color="primary">
                Baixar
              </a>
            </td>
          </tr>

          <tr>
            <td>
              <span>
                <strong>
                  lat:
                </strong>
                <small>
                  {{this.terminalMarker.latitude}}&nbsp;
                </small>
              </span>
            </td>
            <td>
              <span>
                <strong>
                  lng:
                </strong>
                <small>
                  {{this.terminalMarker.longitude}}&nbsp;
                </small>
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <strong>Firmware</strong>
              <small>
                <span *ngIf="this.terminalMarker.versaoFirmware">{{ this.terminalMarker.versaoFirmware }}</span>
              </small>
            </td>
            <td>
              <strong>GPS &nbsp;&nbsp;</strong>
              <small>
                <div *ngIf="terminalMarker.enumStatusGps == 0; then gpson else gpsoff"></div>
                <ng-template #gpson>
                  <span>Válido &nbsp;</span> <br>
                </ng-template>
                <ng-template #gpsoff>
                  <a style="cursor: pointer;"
                    (click)="posicaoLbs(terminalMarker, terminalMarker.mccMncCelula, terminalMarker.mncCelula, terminalMarker.lacCelulaGsm, terminalMarker.idCelulaGsm, infoWindow)">
                    <span>
                      <a routerLink="">Inválido &nbsp;&nbsp;</a>
                    </span>
                  </a>
                </ng-template>
              </small>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <div class="input-append">
                <mat-form-field class="full-width">
                  <mat-label>Observações</mat-label>
                  <input type="text" matInput placeholder="" [(ngModel)]="dsObservacao" />
                  <mat-icon style="cursor: pointer;" matSuffix (click)="saveObservacao()">save</mat-icon>
                </mat-form-field>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!--<table *ngIf="this.terminalMarker.historic" class="table">
        <tbody>
        <tr>
          <td width="200">
            <strong>Placa: </strong>
            <small>
              <span *ngIf="terminalMarker.placa">{{ terminalMarker.placa }}</span>
            </small>
          </td>
          <td width="200">
            <strong>Empresa: </strong>
            <small>
                <span *ngIf="terminalMarker.nome_empresa"> {{  terminalMarker.nome_empresa }}
                </span>
            </small>
          </td>
        </tr>


        <tr>
          <td width="200">
            <strong>Motorista</strong>
            <small>
              <span>{{ terminalMarker.nome_motorista }}</span>
            </small>
          </td>
          <td width="200">
            <strong>Data: </strong>
            <small>
                <span *ngIf="terminalMarker.data_online">
                  {{ terminalMarker.data_online }}
                </span>
            </small>
          </td>
        </tr>


        <tr>
          <td width="200">
            <strong>Velocidade</strong>
            <span>
                {{
              this.terminalMarker.nrEnumTipoVelocidade == 1
                      ? this.terminalMarker.velocidade
                      : this.terminalMarker.velocidadeObd + ' Km/h'
              }}
              </span>
          </td>
          <td width="200">
            <strong>Odômetro</strong>
            <small>
                <span>
                  {{
                  this.terminalMarker.nrEnumTipoVelocidade == 1
                          ? this.terminalMarker.odometro
                          : this.terminalMarker.odometroVeiculo
                  }}
                </span>
            </small>
          </td>
        </tr>

        <tr>
          <td width="200">
            <strong>Consumo Total</strong>
            <small>
                <span *ngIf="this.terminalMarker.combustivelConsumido">
                  {{ this.terminalMarker.combustivelConsumido.toString() + ' L' }}
                </span>
            </small>
          </td>
          <td width="200">
            <strong>Horímetro: </strong>
            <small>
                <span *ngIf="this.terminalMarker.nivelBateria">
                  {{  convertSecondsInHours(this.terminalMarker.horimetroMotor) }}
                </span>
            </small>
          </td>
        </tr>

        <tr>
          <td width="200">
            <strong>Atividade</strong>
            <small>
              <span>{{ this.terminalMarker.codigoAtividade }}</span>
            </small>
          </td>
          <td width="200">
            <strong>Nível da bateria: </strong>
            <small>
                <span *ngIf="this.terminalMarker.nivelBateria">
                  {{ this.terminalMarker.nivelBateria }}%
                </span>
            </small>
          </td>
        </tr>

        <tr>
          <td colspan=2 width="400">
            <strong>Endereço: </strong>
            <small>
              <a [href]="this.linkMap" target="blank">{{ addressDisplay }}</a>
              <mat-button-toggle (click)="this.searchAddress()" [class.spinner]="loading" *ngIf="!addressDisplay"
                                 value="search">
                <mat-icon>search</mat-icon>
              </mat-button-toggle>
            </small>
          </td>
        </tr>

        <tr *ngIf="this.terminalMarker.id.toString().indexOf('gr') == -1">
          <td>
            <button (click)="this.moreInfo()" class="more_info" mat-button>Mais informações</button>
          </td>
        </tr>

        <tr *ngIf="this.terminalMarker.id.toString().indexOf('gr') > -1">
          <td>
            <button (click)="this.removeMarkerLbs()" class="more_info" mat-button>
              Remover Marcador
            </button>
          </td>
        </tr>
        </tbody>
      </table>

      <table *ngIf="this.more_info && this.terminalMarker.historic" class="table">
        <tbody>
        <tr>
          <td width="200">
            <strong>IMEI</strong>
            <small>
              <span>
               {{ this.terminalMarker.terminal_imei }}
              </span>
            </small>
          </td>
          <td width="200">
            <strong>Nr SIM Card</strong>
            <small>
              {{ this.terminalMarker.terminal_simCard }}
            </small>
          </td>
        </tr>

        <tr>
          <td width="200">
            <strong>Eventos</strong>
            <small>
              <span>
                {{ this.terminalMarker.eventos }}
              </span>
            </small>
          </td>
          <td width="200">
            <strong>Telemetria</strong>
            <small>
              <span *ngIf="terminalMarker.telemetry">
                {{ terminalMarker.telemetry }}
              </span>
            </small>

            <small>
              <a (click)="openTelemetriaDialog()" style="width: 10em; cursor: pointer;" color="primary">
                Visualizar
              </a>
            </small>
          </td>
        </tr>

        <tr>
          <td width="200">
            <strong>Direção/Altitude</strong>
            <small>
              <span *ngIf="this.terminalMarker.direcao > -1">{{ this.terminalMarker.direcao }} °</span> / <span
                    *ngIf="this.terminalMarker.altitude > -1">{{ this.terminalMarker.altitude }} m</span>
            </small>
          </td>
          <td>
            <strong>KML</strong>
            <a (click)="downloadKml()" style="width: 10em;" color="primary">
              Baixar
            </a>
          </td>
        </tr>

        <tr>
          <td>
            <span>
              <strong>
                lat:
              </strong>
                <small>
                  {{this.terminalMarker.latitude}}&nbsp;
                </small>
              </span>
          </td>
          <td>
              <span>
                <strong>
                  lng:
                </strong>
                <small>
                   {{this.terminalMarker.longitude}}&nbsp;
                </small>
              </span>
          </td>
        </tr>

        <tr>
          <td>
            <strong>Firmware</strong>
            <small>
              <span>{{ this.terminalMarker.versaoFirmware }}</span>
            </small>
          </td>
          <td>
            <strong>GPS &nbsp;&nbsp;</strong>
            <small>
              <div *ngIf="terminalMarker.enumStatusGps == 0; then gpson else gpsoff"></div>
              <ng-template #gpson>
                <span>Válido &nbsp;</span> <br>
              </ng-template>
              <ng-template #gpsoff>
                <a style="cursor: pointer;"
                   (click)="posicaoLbs(terminalMarker, terminalMarker.mccMncCelula, terminalMarker.mncCelula, terminalMarker.lacCelulaGsm, terminalMarker.idCelulaGsm, infoWindow)">
                  <span>
                    <a routerLink="">Inválido &nbsp;&nbsp;</a>
                  </span>
                </a>
              </ng-template>
            </small>
          </td>
        </tr>
        </tbody>
      </table>-->

    </ng-template>

  </agm-snazzy-info-window>

  <!-- Modal para os marcadores de histórico -->
  <agm-snazzy-info-window *ngIf="cloneMarker" #infoHistoricWindow [maxHeight]="600" [maxWidth]="900" [padding]="1"
    [closeWhenOthersOpen]="true">
    <ng-template>
      <h4 style="font-size: 1rem;">Informações do Veículo - Histórico</h4>
      <table class="table">
        <tbody>
          <tr>
            <td width="200">
              <strong>Placa: </strong>
              <small>
                <span *ngIf="terminal.cloneTerminalData.placa">{{ terminal.cloneTerminalData.placa }}</span>
              </small>
            </td>
            <td width="200">
              <strong *ngIf="terminal.cloneTerminalData.nome_divisao">Empresa: </strong>
              <small>
                <span *ngIf="terminal.cloneTerminalData.nome_empresa"> {{ terminal.cloneTerminalData.nome_empresa }}
                </span>
              </small>
              <strong *ngIf="terminal.cloneTerminalData.nome_divisao">Divisão: </strong>
              <small>
                <span *ngIf="terminal.cloneTerminalData.nome_divisao"> {{ terminal.cloneTerminalData.nome_divisao }}
                </span>
              </small>
            </td>
          </tr>


          <tr>
            <td width="200">
              <strong>Motorista</strong>
              <small>
                <span>{{ terminal.cloneTerminalData.nome_motorista }}</span>
              </small>
            </td>
            <td width="200">
              <strong>Data: </strong>
              <small>
                <span *ngIf="terminal.cloneTerminalData.dataAtualizacaoFormate">
                  {{ terminal.cloneTerminalData.dataAtualizacaoFormate}}
                </span>
              </small>
            </td>
          </tr>


          <tr>
            <td width="200">
              <strong>Velocidade</strong>
              <span>
                {{
                this.terminalMarker.nrEnumTipoVelocidade == 1
                ? this.terminal.cloneTerminalData.velocidade + ' Km/h'
                : this.terminal.cloneTerminalData.velocidadeObd + ' Km/h'
                }}
              </span>
            </td>
            <td width="200">
              <strong>Odômetro</strong>
              <small>
                <span>
                  {{
                  this.terminal.cloneTerminalData.enumTipovelocidade == 1
                  ? this.terminal.cloneTerminalData.odometro
                  : this.terminal.cloneTerminalData.odometroVeiculo
                  }}
                </span>
              </small>
            </td>
          </tr>

          <tr>
            <td width="200">
              <strong>Consumo Total</strong>
              <small>
                <span *ngIf="this.terminal.cloneTerminalData.combustivelConsumido">
                  {{ this.terminal.cloneTerminalData.combustivelConsumido.toString() + ' L' }}
                </span>
              </small>
            </td>
            <td width="200">
              <strong>Nível do Tanque: </strong>
              <small>
                <span>
                  {{ this.terminal.cloneTerminalData.nivelCombustivel == null
                  ? 0
                  : this.terminal.cloneTerminalData.nivelCombustivel}}%
                </span>
              </small>
            </td>

          </tr>

          <tr>
            <td width="200">
              <strong>Atividade</strong>
              <small>
                <span>{{ this.terminal.cloneTerminalData.codigoAtividade }}</span>
              </small>
            </td>
            <td width="200">
              <strong>Nível da bateria: </strong>
              <small>
                <span *ngIf="this.terminal.cloneTerminalData.nivelBateria">
                  {{ this.terminal.cloneTerminalData.nivelBateria }}%
                </span>
              </small>
            </td>
          </tr>

          <tr>
            <td width="200">
              <strong>Bateria do veículo: </strong>
              <small>
                <span *ngIf="this.terminal.cloneTerminalData.voltagemBateriaVeiculo">
                  {{ this.terminal.cloneTerminalData.voltagemBateriaVeiculo }}v
                </span>
                <span *ngIf="!this.terminal.cloneTerminalData.voltagemBateriaVeiculo">
                  Não possui registro
                </span>
              </small>
            </td>
          </tr>

          <tr>
            <td colspan=2 width="400">
              <strong>Endereço: </strong>
              <small>
                <a [href]="this.linkMap" target="blank">{{ addressDisplay }}</a>
                <mat-button-toggle
                  (click)="this.searchAddressHistoric(this.terminal.cloneTerminalData.latitude,this.terminal.cloneTerminalData.longitude )"
                  [class.spinner]="loading" *ngIf="!addressDisplay" value="search">
                  <mat-icon>search</mat-icon>
                </mat-button-toggle>
              </small>
            </td>
          </tr>

          <tr *ngIf="this.terminal.cloneTerminalData.id.toString().indexOf('gr') == -1">
            <td>
              <button (click)="this.moreInfo()" class="more_info" mat-button>Mais informações</button>
            </td>
          </tr>

          <tr *ngIf="this.terminal.cloneTerminalData.id.toString().indexOf('gr') > -1">
            <td>
              <button (click)="this.removeMarkerLbs()" class="more_info" mat-button>
                Remover Marcador
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="this.more_info" class="table">
        <tbody>
          <tr>
            <td width="200">
              <strong>IMEI</strong>
              <small>
                <span>
                  {{ this.terminal.cloneTerminalData.terminal_imei }}
                </span>
              </small>
            </td>
            <td width="200">
              <strong>Nr SIM Card</strong>
              <small>
                {{ this.terminal.cloneTerminalData.terminal_simCard }}
              </small>
            </td>
          </tr>

          <tr>
            <td width="200">
              <strong>Eventos</strong>
              <small>
                <span>
                  {{ this.terminal.cloneTerminalData.eventos }}
                </span>
              </small>
            </td>
            <td width="200">
              <strong>Telemetria</strong>
              <small>
                <span *ngIf="terminal.cloneTerminalData.telemetry">
                  {{ terminal.cloneTerminalData.telemetry }}
                </span>
              </small>

              <small>
                <a (click)="openTelemetriaDialog()" style="width: 10em; cursor: pointer;" color="primary">
                  Visualizar
                </a>
              </small>
            </td>
          </tr>

          <tr>
            <td width="200">
              <strong>Direção/Altitude</strong>
              <small>
                <span *ngIf="this.terminal.cloneTerminalData.direcao > -1">{{ this.terminal.cloneTerminalData.direcao }}
                  °</span> / <span *ngIf="this.terminal.cloneTerminalData.altitude > -1">{{
                  this.terminal.cloneTerminalData.altitude }} m</span>
              </small>
            </td>
            <td>
              <strong>KML</strong>
              <a (click)="downloadKml()" style="width: 10em;" color="primary">
                Baixar
              </a>
            </td>
          </tr>

          <tr>
            <td>
              <span>
                <strong>
                  lat:
                </strong>
                <small>
                  {{this.terminal.cloneTerminalData.latitude}}&nbsp;
                </small>
              </span>
            </td>
            <td>
              <span>
                <strong>
                  lng:
                </strong>
                <small>
                  {{this.terminal.cloneTerminalData.longitude}}&nbsp;
                </small>
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <strong>Firmware</strong>
              <small>
                <span>{{ this.terminal.cloneTerminalData.versaoFirmware }}</span>
              </small>
            </td>
            <td>
              <strong>GPS &nbsp;&nbsp;</strong>
              <small>
                <div *ngIf="terminal.cloneTerminalData.enumStatusGps == 0; then gpson else gpsoff"></div>
                <ng-template #gpson>
                  <span>Válido &nbsp;</span> <br>
                </ng-template>
                <ng-template #gpsoff>
                  <a style="cursor: pointer;"
                    (click)="posicaoLbs(terminal.cloneTerminalData, terminal.cloneTerminalData.mccMncCelula, terminal.cloneTerminalData.mncCelula, terminal.cloneTerminalData.lacCelulaGsm, terminal.cloneTerminalData.idCelulaGsm, infoHistoricWindow)">
                    <span>
                      <a routerLink="">Inválido &nbsp;&nbsp;</a>
                    </span>
                  </a>
                </ng-template>
              </small>
            </td>
          </tr>
        </tbody>
      </table>


    </ng-template>
  </agm-snazzy-info-window>


</agm-marker>

<!--   <agm-info-window *ngIf="marker.tag != 'lbs'" (infoWindowClose)="this.closeIntervalpopUp()"  [disableAutoPan]="false"  #infoWindow style="max-width: 850px;">

  </agm-info-window> -->;

<button (click)="openCercaEletronica()" mat-stroked-button>
  Consultar Cerca Eletrônica
</button>