<div class="sidebar">
  <ul>
    <li>
      <a [ngClass]="{
          active: location === null || ['/'].includes(location)
        }" routerLink="/">
        <img src="../../assets/img/icons/icon-1.svg" width="40" class="img-icon" alt="" />
        <img src="../../assets/img/icons/icon-1-hover.svg" width="40" class="img-icon-hover" alt="" />
        <span>Início</span>
      </a>
    </li>
    <li *ngIf="this.permissionService.hasPermission('PAINEL_EVENTOS_MENU', this.permissions)">
      <a [ngClass]="{
          active: location && ['/painel-evento'].includes(location)
        }" routerLink="/painel-evento">
        <img src="../../assets/img/icons/icon-2.svg" width="40" class="img-icon" alt="" />
        <img src="../../assets/img/icons/icon-2-hover.svg" width="40" class="img-icon-hover" alt="" />
        <span>Painel de Eventos</span>
      </a>
    </li>
    <li *ngIf="this.permissionService.hasPermission('GRAFICO_EVENTOS_MENU', this.permissions)">
      <a [ngClass]="{
          active: location && ['/painel-evento-grafico'].includes(location)
        }" routerLink="/painel-evento-grafico">
        <img src="../../assets/img/icons/icon-7.svg" width="40" class="img-icon" alt="" />
        <img src="../../assets/img/icons/icon-7-hover.svg" width="40" class="img-icon-hover" alt="" />
        <span>Gráfico de Eventos</span>
      </a>
    </li>
    <li *ngIf="this.permissionService.hasPermission('REGISTRATIONS_MENU', this.permissions)">
      <a [ngClass]="{
          active:
            location &&
            [
              '/registrations',
              '/users/create',
              '/companies/create',
              '/drivers/create',
              '/vehicles/create',
              '/maintenance/create',
              '/terminal-alarme/create',
              '/terminal/create',
              '/atividades/create',
              '/modulo/create',
              '/perfil/create',
              '/perfil-tipo/create',
              '/funcionalidade/create',
              '/funcionalidade-tipo/create'
            ].includes(location)
        }" routerLink="/registrations">
        <img src="../../assets/img/icons/icon-3.svg" width="40" class="img-icon" alt="" />
        <img src="../../assets/img/icons/icon-3-hover.svg" width="40" class="img-icon-hover" alt="" />
        <span>Cadastros</span>
      </a>
    </li>
    <li *ngIf="this.permissionService.hasPermission('CONSULTA_MENU', this.permissions)">
      <a [ngClass]="{
                active:
                  location &&
                  [
                    '/consultations',
                    '/users',
                    '/companies',
                    '/drivers',
                    '/vehicles',
                    '/routes',
                    '/electric-fence',
                    '/maintenance',
                    '/terminal-alarme',
                    '/terminal',
                    '/atividades',
                    '/modulo',
                    '/perfil',
                    '/perfil-tipo',
                    '/sensor',
                    '/funcionalidade',
                    '/funcionalidade-tipo'
                  ].includes(location)
              }" routerLink="/consultations">
        <img src="../../assets/img/icons/icon-4.svg" width="40" class="img-icon" alt="" />
        <img src="../../assets/img/icons/icon-4-hover.svg" width="40" class="img-icon-hover" alt="" />
        <span>Consultas</span>
      </a>
    </li>
    <li *ngIf="this.permissionService.hasPermission('RELATORIO_MENU', this.permissions)">
      <a [ngClass]="{
          active:
            location &&
            [
              '/reports',            
              '/reports/acompanhamnto-motorista'             
            ].includes(location)

        }" routerLink="/reports">
        <img src="../../assets/img/icons/icon-5.svg" width="40" class="img-icon" alt="" />
        <img src="../../assets/img/icons/icon-5-hover.svg" width="40" class="img-icon-hover" alt="" />
        <span>Relatórios</span>
      </a>
    </li> 
    <li>
      <a [ngClass]="{ active: location && location === '/settings' }" routerLink="/settings">
        <img src="../../assets/img/icons/icon-6.svg" width="40" class="img-icon" alt="" />
        <img src="../../assets/img/icons/icon-6-hover.svg" width="40" class="img-icon-hover" alt="" />
        <span>Configurações</span>
      </a>
    </li>
  </ul>
</div>
