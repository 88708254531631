import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonComponent } from './skeleton.component';
import {SkeletonModule} from 'primeng/skeleton';

@NgModule({
  imports: [
    CommonModule,
    SkeletonModule
  ], 
  exports:[SkeletonModule, SkeletonComponent, CommonModule],
  declarations: [SkeletonComponent]
})
export class SkeletonWModule { }
