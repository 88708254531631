import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TOAST_ERROR, TOAST_SUCCESSO, TOAST_INFO } from './toastEvent';

@Injectable({
  providedIn: 'root'
})
export class MsgToastErrorService {


  constructor( private message: MessageService ) {}

  public emit( msg: any ): string {
    const emit: any = TOAST_ERROR(msg);
    this.message.add(emit);
    return msg.detail;
  }

  public emitInf( msg: any ): string {
    const emit: any = TOAST_INFO(msg);
    this.message.add(emit);
    return msg.detail;
  }
  
  public emitSucess( msg: any ): string {
    const emit: any = TOAST_SUCCESSO(msg);
    this.message.add(emit);
    return msg.detail;
  }
}

