<div class="main-page cockpit-graphic consultas">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 my-2">
        <h1>     
          Grafico de Painel de Eventos
        </h1>
      </div>
    </div>

    <div class="filter-date">
      <div class="form-row">
        <div class="form-group col-md-8">
            <mat-form-field appearance="outline">
            <mat-label>Filtro por dias</mat-label>
                <mat-select [(value)]="selectedDays" [(ngModel)]="selectedDays" (selectionChange)="this.filterDaysTreated()">
                    <mat-option *ngFor="let type of filterDays" [value]="type.value" >
                        {{ type.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
      </div>
    </div>

   
    <div style="margin-top: 5%;">
      <b style="margin-left: 45%;">Todos Eventos</b>
      <canvas #todosEventos></canvas>
    </div>
  </div>


  <div class="col-12" style="margin-top: 8%;">
    <div class="form-row">
      <div class="form-group col-4">
        <b style="margin-left: 22%;">Evento super aquecimento motor</b>
        <canvas #superAquecimentoMotor></canvas>
      </div>

      <div class="form-group col-4">
        <b style="margin-left: 17%;">Evento bateria veículo desconectada</b>
        <canvas #bateriaVeiculoDesconectada></canvas>
      </div>

      <div class="form-group col-4">
        <b style="margin-left: 19%;">Evento Motorista não identificado</b>
        <canvas #motoristaNaoIdentificado></canvas>
      </div>
    </div>
  </div>

  <div class="col-12" style="margin-top: 5%;">
    <div class="form-row">
      <div class="form-group col-4">
        <b style="margin-left: 21%;">Evento entrou em cerca eletrônica</b>
        <canvas #entrouCerca></canvas>
      </div>

      <div class="form-group col-4">
        <b style="margin-left: 15%;">Evento temperatura irregular do pneu</b>
        <canvas #temperaturaIrregularPneu></canvas>
      </div>

      <div class="form-group col-4">
        <b style="margin-left: 15%;">Evento impacto ou vibração detectado</b>
        <canvas #impactoVibracaoDetectado></canvas>
      </div>
    </div>
  </div>
  <div class="col-12" style="margin-top: 5%;">
    <div class="form-row">
      <div class="form-group col-4">
        <b style="margin-left: 23%;">Possível jammer detectado</b>
        <canvas #possivelJammerDetectado></canvas>
      </div>

      <div class="form-group col-4">
        <b style="margin-left: 27%;">Evento pânico acionado</b>
        <canvas #panicoAcionado></canvas>
      </div>

      <div class="form-group col-4">
        <b style="margin-left: 20%;">Evento fim da bateria de backup</b>
        <canvas #fimBatericaBackup></canvas>
      </div>
    </div>
  </div>
</div>