import { CoreService } from "./../../services/migrate/api.core.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import { ApiService } from "../../services/api.service";
import { DownloadCsv } from "src/app/services/download_csv.service";
import { TerminalCore } from "./terminal.core";
import { ToolsService } from "src/app/services/migrate/tools.service";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import {TrackerModelService} from "../../services/tracker-model.service";

@Component({
  selector: "app-create-terminal",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CreateTerminalComponent extends TerminalCore implements OnInit {
  public title = "Cadastrar Terminal";
  public titleVar = "";
  @ViewChild("csvReader") csvReader: any;

  constructor(
    private apiserviceCore: CoreService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    public tools: ToolsService,
    private doenload_cvs: DownloadCsv,
    private baseService: ApiBaseService,
    trackerModelService: TrackerModelService
  ) {
    super(apiserviceCore, apiService, formBuilder, tools, baseService, trackerModelService);
  }

  ngOnInit(): void {
    this.terminalForm = this.formBuilder.group(this.validation.rules());
    this.loadDataElements();
    this.setFormValuesDefault();
  }

  public async submitForm() {
    this.coverterUpperCase(this.terminalForm);
    this.register();
  }

  public coverterUpperCase(terminalForm: FormGroup) {
    if (terminalForm.get("observacao").value != null) {
      terminalForm
        .get("observacao")
        .setValue(terminalForm.get("observacao").value.toUpperCase());
    }
  }
}
