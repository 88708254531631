import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { VeiculoSensor } from "src/app/models/VeiculoSensor.model";
import { VwSensor } from "src/app/models/VwSensor.model";
import { EventEmitterSensorService } from "src/app/services/event-emitter-sensor.service";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import Swal from "sweetalert2";

@Component({
  selector: "app-directional",
  templateUrl: "./directional.component.html",
  styleUrls: ["./directional.component.scss"],
})
export class DirectionalComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() sensoresDisponiveis: any;
  @Input() veiculoId: number;

  @Output() atualizarSensores = new EventEmitter<void>();

  public sensorLeftSelect: any = [];
  public sensorRightSelect: any = [];

  public sensorRightCombo: any = [];
  public sensorLeftCombo: any = [];

  public direcionalLeftForm: FormGroup;
  public direcionalRightForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private serviceApiBase: ApiBaseService,
    private componentResetService: EventEmitterSensorService
  ) {}

  ngOnInit(): void {
    this.componentResetService.reiniciarComponentes$.subscribe(async (a) => {
      this.sensoresDisponiveis = null;
      this.veiculoSensores = null;
      this.sensorRightCombo = [];
      this.sensorLeftCombo = [];

      const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();

      const eixo = await this.serviceApiBase.get<any>({
        url: new VeiculoSensor()["nomeConsultaApiBase"] + this.veiculoId,
      });

      this.veiculoSensores = eixo["content"];

      const sensor = await this.serviceApiBase.get<any>({
        url:
          new VwSensor()["nomeConsultaApiBaseSensor"] +
          this.veiculoId +
          "/perfil/" +
          perfil,
      });

      this.sensoresDisponiveis = sensor;

      if (this.sensoresDisponiveis.length > 0) {
        this.sensoresDisponiveis.forEach((element) => {
          this.sensorRightCombo.push(element);
          this.sensorLeftCombo.push(element);
        });
      }

      this.loadSensor();
    });
    this.direcionalLeftForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: ["", Validators.required],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.direcionalRightForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: ["", Validators.required],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    if (this.sensoresDisponiveis.length > 0) {
      this.sensoresDisponiveis.forEach((element) => {
        this.sensorRightCombo.push(element);
        this.sensorLeftCombo.push(element);
      });
    }

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.direcionalLeftForm.controls["cdPosicaoPneu"].setValue(
        "DIRECIONAL ESQ"
      );
      this.direcionalRightForm.controls["cdPosicaoPneu"].setValue(
        "DIRECIONAL DIR"
      );

      if (this.veiculoSensores.length > 0) {
        var direcionalDireito;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 1) {
              direcionalDireito = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 1) {
              direcionalDireito = filter;
            }
          }
        });

        var direcionalEsquerdo;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 2) {
              direcionalEsquerdo = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 2) {
              direcionalEsquerdo = filter;
            }
          }
        });

        //DIRECIONAL DIREITO SET
        if (direcionalDireito) {
          this.direcionalRightForm.controls["id"].setValue(
            direcionalDireito.id
          );

          if (direcionalDireito.sensor != null) {
            this.sensorRightCombo.push(direcionalDireito.sensor);
            this.sensorRightSelect = direcionalDireito.sensor.id;
          }

          if (direcionalDireito.pneu != null) {
            this.direcionalRightForm.controls["pneu"].setValue(
              direcionalDireito.pneu.numeroFogo
            );
          }

          this.direcionalRightForm.controls["nrPressaoMinima"].setValue(
            direcionalDireito.nrPressaoMinima
          );

          this.direcionalRightForm.controls["nrPressaoMaxima"].setValue(
            direcionalDireito.nrPressaoMaxima
          );

          this.direcionalRightForm.controls["nrTemperaturaMaxima"].setValue(
            direcionalDireito.nrTemperaturaMaxima
          );
        }

        //DIRECIONAL ESQUERDO SET
        if (direcionalEsquerdo != null) {
          this.direcionalLeftForm.controls["id"].setValue(
            direcionalEsquerdo.id
          );

          if (direcionalEsquerdo.sensor != null) {
            this.sensorLeftCombo.push(direcionalEsquerdo.sensor);
            this.sensorLeftSelect = direcionalEsquerdo.sensor.id;
          }

          if (direcionalEsquerdo.pneu != null) {
            this.direcionalLeftForm.controls["pneu"].setValue(
              direcionalEsquerdo.pneu.numeroFogo
            );
          }

          this.direcionalLeftForm.controls["nrPressaoMinima"].setValue(
            direcionalEsquerdo.nrPressaoMinima
          );

          this.direcionalLeftForm.controls["nrPressaoMaxima"].setValue(
            direcionalEsquerdo.nrPressaoMaxima
          );

          this.direcionalLeftForm.controls["nrTemperaturaMaxima"].setValue(
            direcionalEsquerdo.nrTemperaturaMaxima
          );
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public async submitFormDelete(form: FormGroup): Promise<void> {
    this.spinner.show();
    try {
      const values = form.value;
      var post;

      post = {
        veiculo: {
          id: this.veiculoId,
        },
        nrPressaoMinima: values.nrPressaoMinima,
        nrPressaoMaxima: values.nrPressaoMaxima,
        nrTemperaturaMaxima: values.nrTemperaturaMaxima,
        sensor: {
          id: values.cdSensor,
        },
        pneu: null,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      console.log(post);

      const result = await this.serviceApiBase.put<VeiculoSensor>(
        values.id,
        new VeiculoSensor()["nomeConsultaApiBaseRemove"],
        post
      );

      form.get("cdSensor").setValue("");

      this.spinner.hide();

      this.handleDelete();

      this.atualizarSensores.emit();
    } catch (error) {
      this.spinner.hide();
      //this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public async submitFormDeleteAll(): Promise<void> {
    this.spinner.show();
    try {
      var estepeArray: any = [];
      var posicoesPneusList: any = [];

      estepeArray.push(this.direcionalRightForm.value);
      estepeArray.push(this.direcionalLeftForm.value);

      if (estepeArray.length > 0) {
        estepeArray.forEach((element) => {
          var posicaoPneuResquest = {
            cdPosicaoPneu: element.cdPosicaoPneu,
          };
          posicoesPneusList.push(posicaoPneuResquest);
        });
      }

      var post = {
        veiculoSensores: posicoesPneusList,
      };

      const result = await this.serviceApiBase.put<VeiculoSensor>(
        this.veiculoId,
        new VeiculoSensor()["nomeConsultaApiBaseRemoveAll"],
        post
      );

      this.direcionalRightForm.get("cdSensor").setValue("");
      this.direcionalLeftForm.get("cdSensor").setValue("");

      this.spinner.hide();

      this.handleDeleteAll();

      this.atualizarSensores.emit();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public async submitFormAddAll(): Promise<void> {
    this.spinner.show();
    try {
      var estepeArray: any = [];
      var posicoesPneusList: any = [];

      estepeArray.push(this.direcionalRightForm.value);
      estepeArray.push(this.direcionalLeftForm.value);

      if (estepeArray.length > 0) {
        var boolean = this.verificarDuplicados(estepeArray);

        if (boolean == true) {
          this.handleItensDuplicados();
          this.spinner.hide();
          return;
        }

        estepeArray.forEach((element) => {
          if (element.cdSensor != "") {
            var posicaoPneuResquest = {
              cdPosicaoPneu: element.cdPosicaoPneu,
              cdSensor: element.cdSensor,
              pneu: element.pneu,
              nrPressaoMinima: element.nrPressaoMinima,
              nrPressaoMaxima: element.nrPressaoMaxima,
              nrTemperaturaMaxima: element.nrTemperaturaMaxima,
              idPerfil: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
            };
            posicoesPneusList.push(posicaoPneuResquest);
          }
        });
      }

      var post = {
        veiculoSensores: posicoesPneusList,
      };

      const result = await this.serviceApiBase.put<VeiculoSensor>(
        this.veiculoId,
        new VeiculoSensor()["nomeConsultaApiBaseAddAll"],
        post
      );

      this.spinner.hide();

      this.handleAddAll();

      this.atualizarSensores.emit();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  verificarDuplicados(axisOneArray: any[]): boolean {
    const seen = new Set();
    for (const item of axisOneArray) {
      if (seen.has(item.cdSensor)) {
        return true; // Retorna true se encontrar um valor duplicado
      } else {
        seen.add(item.cdSensor);
      }
    }
    return false; // Retorna false se não houver valores duplicados
  }

  public async handleItensDuplicados(): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Sensores selecionados estão com duplicidade, por favor corrigir!",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public async handleAddAll(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Sensores do DIRECIONAL 1 vinculados com sucesso",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public async handleDeleteAll(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Sensores do DIRECIONAL 1 desvinculados com sucesso",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public async handleDelete(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Sensor desvinculados com sucesso",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }
}
