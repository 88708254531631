import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { GeneralFilterOption } from "./general-filter-option";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { VehiclesMenuService } from "../../../services/vehicles-menu.service";
import storageX from "../../../storageCore/storageX";
import { STORAGE_KEY_CURRENT_PERFIL } from "../../../storageCore/constStorageKeys";
import { MapButtonService } from "../../../services/map.buttom.service";
import { MatDialogRef } from "@angular/material/dialog";
import { MapToolboxService } from "../../../services/map.toolbox.service";

@Component({
  selector: "app-general-filter",
  templateUrl: "./general-filter.component.html",
  styleUrls: ["./general-filter.component.scss"],
})
export class GeneralFilterComponent implements OnInit {
  private service: VehiclesMenuService;
  private mapButtonService: MapButtonService;
  private mapToolboxService: MapToolboxService;

  myControl = new FormControl("");
  options: GeneralFilterOption[] = [];
  filteredOptions: Observable<GeneralFilterOption[]>;
  selectedOption: GeneralFilterOption = null;

  constructor(
    service: VehiclesMenuService,
    mapButtonService: MapButtonService,
    mapToolboxService: MapToolboxService,
    public dialogRef: MatDialogRef<GeneralFilterComponent>
  ) {
    this.service = service;
    this.mapButtonService = mapButtonService;
    this.mapToolboxService = mapToolboxService;
  }

  ngOnInit(): void {
    console.log("Inicializando componente de filtro geral");
    this.filteredOptions = this.myControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      filter((value) => !!value),
      switchMap((value) => {
        const placa = typeof value === "string" ? value : value?.placa;
        return this.service.getVehiclesToSelectByProfileAndSearch(
          this.getIdPerfil(),
          placa
        );
      })
    );
  }

  setVehicle($event: MatAutocompleteSelectedEvent): void {
    this.selectedOption = $event.option.value;
    console.log(
      "Veiculo selecionado no filtro geral -> " +
        $event.option.value.placa +
        " | " +
        $event.option.value.id_terminal
    );
  }

  getFilterDescription(option: GeneralFilterOption): string {
    return option.placa;
  }

  private getIdPerfil(): number {
    const profile = storageX.whereKeyIs(STORAGE_KEY_CURRENT_PERFIL).get();
    return profile.id;
  }

  submit(): void {
    const command = {
      action: "general_filter",
      values: {
        id_terminal: this.selectedOption.id_terminal,
      },
    };

    this.mapToolboxService.setCommand(command);
    this.dialogRef.close(true);
    this.mapButtonService.setGeneralFilterButton(false);
  }

  close(): void {
    this.mapButtonService.setGeneralFilterButton(false);
    this.dialogRef.close();
  }
}
