<div class="container">
    <h5>Consultar Trajeto</h5>
    <div class="row mt-12 row-form">
        <div class="col-12">
            <div class="form-group">
                <div class="col-12">
                    <div class="form-group">
                        <mat-horizontal-stepper [linear]="isLinear" #stepper>
                            <mat-step [stepControl]="this.dateFormGroup">
                                <ng-template matStepLabel>Selecione as Datas</ng-template>

                                <form [formGroup]="this.dateFormGroup">
                                    <br>
                                    <p-calendar formControlName="dateRange" [required]="true" [inline]="true"
                                        selectionMode="range" [readonlyInput]="true" inputId="range"
                                        dateFormat="dd/mm/yy" [maxDate]="pathMaxDate">
                                    </p-calendar>

                                </form>

                                <div>
                                    <br>
                                    <button pButton [disabled]="this.openNext" pRipple label="Continuar"
                                        tooltipPosition="bottom" (click)="this.handleStep('next')"></button>
                                </div>
                            </mat-step>
                            <mat-step [ngStyle]="{width: ''}">
                                <ng-template matStepLabel>Selecione os horários</ng-template>
                                <div class="col-12">
                                    <div class="form-group">
                                        <div *ngIf="errorMessage" style="text-align: center;" class="col-12">
                                            <span class="error">{{ errorMessage }}</span>
                                            <br>
                                        </div>
                                        <div class="col-12" style="text-align: center;">
                                            <br>
                                            <button mat-button mat-dialog-close>Fechar</button>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>
                        </mat-horizontal-stepper>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Horários" [(visible)]="this.displayResponsive" [breakpoints]="{'960px': '75vw'}"
    [style]="{width: '50vw', heigth: '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <div class="container">
        <div [formGroup]="this.timeFormGroup" class="row mt-12 row-form">
            <div class="col-6">
                <div class="form-group">
                    <div class="col-12">
                        <div style="font-size: 15px;">
                            <b>
                                {{this.statrtDate | date}}
                            </b>
                        </div>
                        <br>
                        <p-calendar formControlName="start" [readonlyInput]="false" placeholder="ex: 10:00"
                            dateFormat="dd/mm/yy" [timeOnly]="true" hourFormat="24" inputId="timeonly"></p-calendar>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group">
                    <div class="col-12">
                        <div style="font-size: 15px;">
                            <b>
                                {{this.endDate | date}}
                            </b>
                        </div>
                        <br>
                        <p-calendar formControlName="end" placeholder="ex: 18:00" hourFormat="24" dateFormat="dd/mm/yy"
                            [readonlyInput]="false" [timeOnly]="true" inputId="timeonly2"></p-calendar>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Apresentar Marcadores a cada:</mat-label>
                            <select matNativeControl formControlName="numeroMarcadores">
                                <option value=10>
                                    10 minutos
                                </option>
                                <option value=5 selected>
                                    5 minutos
                                </option>
                                <option value=3>
                                    3 minutos
                                </option>
                                <option value=1>
                                    Todos Marcadores
                                </option>
                            </select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Trajeto em formato de paradas?</mat-label>
                            <select matNativeControl formControlName="stops">
                                <option [value]="true">Sim</option>
                                <option [value]="false">Não</option>
                            </select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="this.handleStep('back')" label="Voltar" styleClass="p-button-text"></p-button>
        <button pButton [disabled]="this.timeFormGroup.invalid" tooltipPosition="bottom" (click)="this.handleStep()"
            label="Concluir" styleClass="p-button-text"></button>
    </ng-template>
</p-dialog>