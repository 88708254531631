import { NgxSpinnerService } from 'ngx-spinner';
import { ApiReportService } from '../../../services/migrate/api.report.service';
import { MapToolboxService } from '../../../services/map.toolbox.service';
import { ApiService } from '../../../services/api.service';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { PrimeNGConfig } from 'primeng/api';
import PRIME_TRANSLATE from 'src/app/utils/primetranslate';
import { FormateDate } from 'src/app/utils/date/formateDate';
import { MsgToastErrorService } from 'src/app/utils/menssages/msg.service';

@Component({
    selector: 'app-path',
    templateUrl: './path.component.html',
    styleUrls: ['./path.component.scss'],
})
export class PathComponent implements OnInit {
    public isLinear = true;
    public displayResponsive = false;
    public dateFormGroup: FormGroup;
    public timeFormGroup: FormGroup;
    @ViewChild('stepper') stepper: MatStepper;
    public statrtDate;
    public endDate;
    public startTime;
    public endTime;
    public openNext = true;
    public fullParamsUrl;
    private service: ApiService;
    private serviceReport: ApiReportService;
    private mapService: MapToolboxService;
    public loader = false;
    public errorMessage;
    public pathMaxDate;

    constructor(
        private apiService: ApiService,
        private sserviceReport: ApiReportService,
        mapService: MapToolboxService,
        private primengConfig: PrimeNGConfig,
        private toast: MsgToastErrorService,
        private ngxSpinnerService: NgxSpinnerService,
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.mapService = mapService;
        this.serviceReport = sserviceReport;
        this.service = apiService;
        this.pathMaxDate = new Date();
    }

    ngOnInit(): void {
        this.dateFormGroup = this.formBuilder.group({
            dateRange: ['', Validators.required],
        });

        this.timeFormGroup = this.formBuilder.group({
            start: ['', Validators.required],
            end: ['', Validators.required],
            numeroMarcadores: ['', Validators.required],
            stops: [false, Validators.required],
        });

        this.dateFormGroup.valueChanges.subscribe((data) => {
            const dt = data.dateRange;
            if (dt[0] && dt[1]) {
                this.statrtDate = dt[0];
                this.endDate = dt[1];
                this.openNext = false;
            }
        });

        this.primengConfig.setTranslation(PRIME_TRANSLATE);
    }

    public handleStep(key?: string): void {
        switch (key) {
            case 'next':
                this.displayResponsive = true;
                this.statrtDate = this.dateFormGroup.value.dateRange[0];
                this.endDate = this.dateFormGroup.value.dateRange[1];
                this.openNext = false;
                this.stepper.next();
                break;

            case 'back':
                this.stepper.previous();
                this.displayResponsive = false;
                break;

            default:
                // T1626 Valida as datas
                const fullStartDate =
                    new Date(FormateDate.formateDateAndHour(this.statrtDate, FormateDate.formateTime(this.timeFormGroup.value.start)));
                const fullEndDate =
                    new Date(FormateDate.formateDateAndHour(this.endDate, FormateDate.formateTime(this.timeFormGroup.value.end)));
                if (fullStartDate > this.pathMaxDate || fullEndDate > this.pathMaxDate) {
                    this.displayResponsive = false;
                    this.errorMessage = 'Data inválida. As datas não podem ser superiores a data atual';
                    return;
                }
                this.fullParamsUrl = `?enumStatusGps=0&idTerminal=${this.data.marker.id_terminal
                    }&stops=${this.timeFormGroup.value.stops
                    } &startDate=${FormateDate.formateDate(
                        this.statrtDate
                    )}T${FormateDate.formateTime(
                        this.timeFormGroup.value.start
                    )}:00&endDate=${FormateDate.formateDate(
                        this.endDate
                    )}T${FormateDate.formateTime(this.timeFormGroup.value.end)}:00`;
                this.submit();
        }
    }

    async submit(): Promise<void> {
        this.ngxSpinnerService.show();

        try {
            const url = `posicao-historico-terminal${this.fullParamsUrl}`;
            const response = await this.serviceReport.get<any[]>({
                url,
            });

            this.displayResponsive = false;
            this.ngxSpinnerService.hide();

            const result = response;

            if (result && result.length === 0) {
                this.displayResponsive = false;
                this.errorMessage = 'Nenhum trajeto encontrado';
                return;
            }

            const coordenates = result.map((e) => {
                return {
                    lat: parseFloat(e.latitude),
                    lng: parseFloat(e.longitude),
                };
            });

            const origin = coordenates[0];
            const destination = coordenates.slice(-1)[0];
            let dataHoraInicial = new Date(result[0].dataAtualizacao);

            let waypoints = result.map((e) => {
                const dataHoraAtual = new Date(e.dataAtualizacao);
                const diffMs = dataHoraAtual.getTime() - dataHoraInicial.getTime();
                const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

                if (
                    diffMins > this.timeFormGroup.value.numeroMarcadores ||
                    this.timeFormGroup.value.numeroMarcadores == 1
                ) {
                    dataHoraInicial = dataHoraAtual;
                    return {
                        ...e,
                    };
                }
            });

            const temp = [];
            for (const i of waypoints) {
                i && temp.push(i);
            }
            waypoints = temp;

            const values = {
                id: this.data.marker.id,
                action: 'path',
                values: {
                    startDate: this.startTime,
                    endDate: this.endTime,
                    origin: { lat: origin.lat, lng: origin.lng },
                    destination: {
                        lat: destination.lat,
                        lng: destination.lng,
                    },
                    waypoints,
                },
            };

            this.mapService.setCommand(values);
            this.dialogRef.close();
        } catch (error) {
            this.displayResponsive = false;
            this.loader = false;
        }
    }
}
