import { STORAGE_ALLKEYS, STORAGE_KEY_KEEP_LOGGEDIN } from "./constStorageKeys";

 class TypeStorage{

   public storage: Storage;

    constructor(){
        this.loadStorage( this.statusKeepLoggedIn() );
    }

    public loadStorage( keepLoggedIn: boolean): void{
        if(  keepLoggedIn  ){
            this.storage = localStorage;
            this.verifySessionStorageAndClear();
        }else{
            this.storage = sessionStorage;
            this.verifyLocalStorageAndClear();
        }
    }

    private verifyLocalStorageAndClear(): void{
         if(this.storage.length > 0){
            STORAGE_ALLKEYS.forEach((key: string) => {
                localStorage.removeItem(key);
            })
         };
    }

    private verifySessionStorageAndClear(): void{
        if(this.storage.length > 0){
          sessionStorage.clear();
        };
   } 

   private statusKeepLoggedIn(): boolean{
       
    const value = localStorage.getItem( STORAGE_KEY_KEEP_LOGGEDIN );
    
    if(!value){
       return false;
    }

     return true;
}
}

export default new TypeStorage();