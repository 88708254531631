import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { Vehicle } from "../../models/Vehicle.model";
import { ApiService } from "../../services/api.service";
import { VehicleInterface } from "../../contracts/Vehicle.interface";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { Page } from "src/app/utils/paginate";
import { CoreService } from "src/app/services/migrate/api.core.service";
import { USER_DATA } from "../../utils/constants.util";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-vehicles",
  templateUrl: "./vehicles.component.html",
  styleUrls: ["./vehicles.component.scss"],
})
export class VehiclesComponent implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('filter') filter: ElementRef;
  // @ViewChild('typeFilter') typeFilter: ElementRef;
  public filter;
  public typeFilter;

  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 5000;
  public totalElements = 0;
  public totalPages = 0;
  public page = new Page();

  public displayedColumns: string[] = [
    //'id',
    "placa",
    "nmempresa",
    "marca",
    "nmModelo",
    "nmterminal",
    "sendTpms",
    "update",
    "clone",
    "view",
    "delete",
  ];

  public filtros = [
    //{ campo: 'id', valor: 'ID' },
    { campo: "empresa.nmNome", valor: "Empresa" },
    { campo: "cdPlaca", valor: "Placa" },
    { campo: "nmModelo", valor: "Modelo" },
  ];

  private service: ApiService;
  private apiBaseService: ApiBaseService;
  private coreService: CoreService;

  public vehicles: MatTableDataSource<VehicleInterface>;

  constructor(
    apiCoreService: CoreService,
    apiService: ApiService,
    apiBaseService: ApiBaseService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.service = apiService;
    this.apiBaseService = apiBaseService;
    this.coreService = apiCoreService;
    this.loadVehicles();

    //  this.vehicles = new MatTableDataSource<VehicleInterface>([]);
  }

  ngOnInit(): void {}

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.vehicles.filter = filterValue.trim().toLowerCase();

    if (this.vehicles.paginator) {
      this.vehicles.paginator.firstPage();
    }
  }

  public handleSort = (sort): void => {
    this.loadVehicles(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadVehicles(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadVehicles();
  };

  public handleDelete = (id: string): void => {
    this.deleteVehicle(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/vehicles/${id}`]);
  };

  ngAfterViewInit(): void {}

  public async deleteVehicle(id): Promise<void> {
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        await this.service.delete<Vehicle>(id, "veiculo");

        this.spinner.hide();

        this.loadVehicles();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }
  public handSendTpms = (id: string): void => {
    this.sendTpms(id);
  };
  public async sendTpms(id): Promise<void> {
    if (confirm("Tem certeza que deseja enviar dados de TPMS ao terminal?")) {
      try {
        this.spinner.show();

        const post = {
          id,
        };

        await this.apiBaseService.put<Vehicle>(id, "veiculos/send-tpms", post);

        this.spinner.hide();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public async loadVehicles(pageIndex = 0, sort?): Promise<void> {
    const params = {
      profileId: USER_DATA().profileId,
      size: this.pageSize,
      page: pageIndex,
      sort: "placa",
      sortDirections: "ASC",
    };

    try {
      this.spinner.show();

      const result = await this.coreService.get<any>({
        url: "v2/vehicles",
        params,
      });

      this.vehicles = new MatTableDataSource<VehicleInterface>(result.elements);

      setTimeout(() => {
        this.vehicles.sort = this.sort;
        this.vehicles.paginator = this.paginator;
        this.spinner.hide();
      }, 300);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
