import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-axle-one-dual-view",
  templateUrl: "./axle-one-dual-view.component.html",
  styleUrls: ["./axle-one-dual-view.component.scss"],
})
export class AxleOneDualViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorOneIntLeftSelect: any = [];
  public sensorOneIntRightSelect: any = [];

  public sensorOneExtLeftSelect: any = [];
  public sensorOneExtRightSelect: any = [];

  public sensorOneIntRightCombo: any = [];
  public sensorOneIntLeftCombo: any = [];

  public sensorOneExtRightCombo: any = [];
  public sensorOneExtLeftCombo: any = [];

  public axisOneDoubleLeftIntForm: FormGroup;
  public axisOneDoubleLeftExtForm: FormGroup;

  public axisOneDoubleRightIntForm: FormGroup;
  public axisOneDoubleRightExtForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisOneDoubleLeftIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisOneDoubleLeftExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisOneDoubleRightIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisOneDoubleRightExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisOneDoubleLeftIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 1 ESQ INT"
      );
      this.axisOneDoubleLeftExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 1 ESQ EXT"
      );

      this.axisOneDoubleRightIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 1 DIR INT"
      );
      this.axisOneDoubleRightExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 1 DIR EXT"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo1DireitoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 4) {
              eixo1DireitoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 4) {
              eixo1DireitoExt = filter;
            }
          }
        });
        var eixo1EsquerdoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 3) {
              eixo1EsquerdoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 3) {
              eixo1EsquerdoExt = filter;
            }
          }
        });
        var eixo1DireitoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 20) {
              eixo1DireitoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 20) {
              eixo1DireitoInt = filter;
            }
          }
        });
        var eixo1EsquerdoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 19) {
              eixo1EsquerdoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 19) {
              eixo1EsquerdoInt = filter;
            }
          }
        });

        // EIXO 1 DIREITO EXTERNO
        if (eixo1DireitoExt != null) {
          this.axisOneDoubleRightExtForm.controls["id"].setValue(
            eixo1DireitoExt.id
          );

          if (eixo1DireitoExt.sensor != null) {
            this.sensorOneExtRightCombo.push(eixo1DireitoExt.sensor);
            this.sensorOneExtRightSelect = eixo1DireitoExt.sensor.id;
          }

          if (eixo1DireitoExt.pneu != null) {
            this.axisOneDoubleRightExtForm.controls["pneu"].setValue(
              eixo1DireitoExt.pneu.numeroFogo
            );
          }

          this.axisOneDoubleRightExtForm.controls["nrPressaoMinima"].setValue(
            eixo1DireitoExt.nrPressaoMinima
          );

          this.axisOneDoubleRightExtForm.controls["nrPressaoMaxima"].setValue(
            eixo1DireitoExt.nrPressaoMaxima
          );

          this.axisOneDoubleRightExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo1DireitoExt.nrTemperaturaMaxima);
        }

        // EIXO 1 ESQ EXT
        if (eixo1EsquerdoExt != null) {
          this.axisOneDoubleLeftExtForm.controls["id"].setValue(
            eixo1EsquerdoExt.id
          );

          if (eixo1EsquerdoExt.sensor != null) {
            this.sensorOneExtLeftCombo.push(eixo1EsquerdoExt.sensor);
            this.sensorOneExtLeftSelect = eixo1EsquerdoExt.sensor.id;
          }

          if (eixo1EsquerdoExt.pneu != null) {
            this.axisOneDoubleLeftExtForm.controls["pneu"].setValue(
              eixo1EsquerdoExt.pneu.numeroFogo
            );
          }

          this.axisOneDoubleLeftExtForm.controls["nrPressaoMinima"].setValue(
            eixo1EsquerdoExt.nrPressaoMinima
          );

          this.axisOneDoubleLeftExtForm.controls["nrPressaoMaxima"].setValue(
            eixo1EsquerdoExt.nrPressaoMaxima
          );

          this.axisOneDoubleLeftExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo1EsquerdoExt.nrTemperaturaMaxima);
        }

        // EIXO 1 DIREITO INTERNO
        if (eixo1DireitoInt != null) {
          this.axisOneDoubleRightIntForm.controls["id"].setValue(
            eixo1DireitoInt.id
          );

          if (eixo1DireitoInt.sensor != null) {
            this.sensorOneIntRightCombo.push(eixo1DireitoInt.sensor);
            this.sensorOneIntRightSelect = eixo1DireitoInt.sensor.id;
          }

          if (eixo1DireitoInt.pneu != null) {
            this.axisOneDoubleRightIntForm.controls["pneu"].setValue(
              eixo1DireitoInt.pneu.numeroFogo
            );
          }

          this.axisOneDoubleRightIntForm.controls["nrPressaoMinima"].setValue(
            eixo1DireitoInt.nrPressaoMinima
          );

          this.axisOneDoubleRightIntForm.controls["nrPressaoMaxima"].setValue(
            eixo1DireitoInt.nrPressaoMaxima
          );

          this.axisOneDoubleRightIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo1DireitoInt.nrTemperaturaMaxima);
        }

        // EIXO 1 ESQ INT
        if (eixo1EsquerdoInt != null) {
          this.axisOneDoubleLeftIntForm.controls["id"].setValue(
            eixo1EsquerdoInt.id
          );

          if (eixo1EsquerdoInt.sensor != null) {
            this.sensorOneIntLeftCombo.push(eixo1EsquerdoInt.sensor);
            this.sensorOneIntLeftSelect = eixo1EsquerdoInt.sensor.id;
          }

          if (eixo1EsquerdoInt.pneu != null) {
            this.axisOneDoubleLeftIntForm.controls["pneu"].setValue(
              eixo1EsquerdoInt.pneu.numeroFogo
            );
          }

          this.axisOneDoubleLeftIntForm.controls["nrPressaoMinima"].setValue(
            eixo1EsquerdoInt.nrPressaoMinima
          );

          this.axisOneDoubleLeftIntForm.controls["nrPressaoMaxima"].setValue(
            eixo1EsquerdoInt.nrPressaoMaxima
          );

          this.axisOneDoubleLeftIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo1EsquerdoInt.nrTemperaturaMaxima);
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
