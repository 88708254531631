import { Validators } from "@angular/forms";

export class Validation {
    public rules = (): object => {
        return {
            veiculo: ['', Validators.required],
            dtManutencao: [''],
            nrQuilometragem: [''],
            dsDescricao: ['', Validators.required ],
            flAlarmeGerado: [''],
        };
    };
}
