import { PneuInterface } from "../contracts/Pneu.interface";
import { Vehicle } from "./Vehicle.model";
import { PosicaoPneu } from "./PosicaoPneu.model";
import { Company } from "./Company.model";
import { Division } from "./Division.model";

export class Pneu implements PneuInterface {
  nomeConsultaApiBase = "pneu";
  nomeConsultaApiBaseGetAll = "pneu/getall";
  nomeConsultaApiBaseUpdatePosition = "pneu/posicao-pneu";
  nomeConsultaApiBaseReplacementPosition = "pneu/troca-pneu";
  id: number;
  marca: String;
  tamanho: String;
  numeroFogo: String;
  observacao: String;
  empresa: Company;
  divisao: Division;
  veiculo: Vehicle;
  posicaoPneu: PosicaoPneu;
}
