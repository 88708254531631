<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>Editar análise</app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
              <div class="col-12">
                  <form [formGroup]="reportOperationalForm" (ngSubmit)="submitForm()">
                      <h2 class="mb-3">Dados da análise</h2>
                        <div class="form-row" >
                          <div class="form-group col-6">
                            <mat-form-field appearance="outline">
                              <mat-label>Descrição análise</mat-label>
                              <input formControlName="description"  matInput placeholder="Descrição" required />
                            </mat-form-field>
                          </div>
                          <div class="form-group col-6">
                            <mat-form-field appearance="outline">
                              <mat-label>Núm. Pacote</mat-label>
                              <input formControlName="packageNumber"  matInput placeholder="Núm. Pacote"/>
                            </mat-form-field>
                          </div>
                        </div>
                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>

                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit" [disabled]="!reportOperationalForm.valid"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Atualizar Análise
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
