<div class="main-page consultas">
  <div class="container-fluid">
    <!-- LINHA TITULO -->
    <div class="row align-items-center">
      <div class="col-12 my-2">
        <h1>Qual relatório você quer acessar?</h1>
      </div>
    </div>
    <!-- ITEM CARDS -->
    <div class="row mt-3">
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('JORNADA_RELATORIO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/journey-control'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/jornada.svg" height="55" alt="" />
              <h5>Controle de Jornada</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('MOTORISTA_RELATORIO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/driver-report'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/motorista.svg" height="55" alt="" />
              <h5>Relatório Operacional do Motorista</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('VEICULO_RELATORIO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/vehicle-report'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="/assets/img/icons-page/veiculo.svg" height="55" alt="" />
              <h5>Relatório Operacional do Veículo</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('COMANDO_RELATORIO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/command-report'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/processador.svg" height="55" alt="" />
              <h5>Relatório Envio Comandos</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('POSICOES_RELATORIO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/stop-report'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/parada.svg" height="55" alt="" />
              <h5>Relatório de Posições</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('EVENTOS_RELATORIO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/events-report'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/tratamentoeventos.svg" height="55" alt="" />
              <h5>Relatório Tratameto de Eventos</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('RELATORIO_MENSAL_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/relatorio-analitico-mensal'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/report-monthly.svg" height="55" alt="" />
              <h5>Relatório Analitico Mensal</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 my-2" *ngIf="this.permissionService.hasPermission('OCULTO', this.permissions)">
        <a (click)="this.router.navigate(['/relatorio-operacional-assincrono'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/report-async.svg" height="55" alt="" />
              <h5>Relatório Operacional Assincrono</h5>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>