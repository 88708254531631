import Swal from "sweetalert2";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AnalyzeTripService } from "src/app/services/analyze-trip.service";
import { RejectionSupervisorModel } from "src/app/models/rejection-supervisor-model";
import { toCamel } from "snake-camel";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_UUID_SUPERVISOR } from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-rejection-supervisor",
  templateUrl: "./rejection-supervisor.component.html",
  styleUrls: ["./rejection-supervisor.component.scss"],
})
export class RejectionSupervisorComponent implements OnInit {
  public analyzeTripService: AnalyzeTripService;

  public rejectionSupervisorForm: FormGroup;

  public rejectionSupervisorModel: RejectionSupervisorModel;

  public analyzeReportUuid = "";

  public errorMessage;

  ngOnInit(): void {
    this.analyzeReportUuid = this.activatedRoute.snapshot.params.uuid;
    this.rejectionSupervisorForm = this.formBuilder.group(
      {
        supervisorComment: ["", Validators.required],
      },
      { updateOn: "blur" }
    );
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    analyzeTripService: AnalyzeTripService
  ) {
    this.analyzeTripService = analyzeTripService;
  }

  public async submitForm(): Promise<void> {
    console.log(this.rejectionSupervisorForm.get("supervisorComment").value);
    this.handleRejection();
  }

  public rejectionManager() {
    this.spinner.show();
    const values = this.rejectionSupervisorForm.value;

    var rejectionSupervisor: RejectionSupervisorModel =
      new RejectionSupervisorModel();

    rejectionSupervisor.descriptionRejection = values.supervisorComment;
    rejectionSupervisor.supervisorUuid = storageX
      .whereKeyIs(STORAGE_KEY_UUID_SUPERVISOR)
      .get();
    rejectionSupervisor.analyzeReportUuid = this.analyzeReportUuid;

    this.analyzeTripService
      .createRejectionSupervisor(rejectionSupervisor)
      .subscribe((t) => {
        this.rejectionSupervisorModel = toCamel(t) as RejectionSupervisorModel;
      });

    // Inicia o temporizador de 3 segundos
    setTimeout(() => {
      // Esconde o spinner
      this.spinner.hide();

      // Navega para a rota desejada

      this.router.navigate(["/analise-supervisor"]);
    }, 3000); // 3000 milissegundos = 3 segundos
  }

  public async handleRejection(): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Rejeitar Analise",
      html: `<h6>tem certeza que deseja rejeitar essa análise?</h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.rejectionManager();
      }
    });
  }
}
