import { ApiBaseService } from "./../../services/migrate/api.base.service";
import { Company } from "./../../models/Company.model";
import { Page } from "./../../utils/paginate";
import { NgxSpinnerService } from "ngx-spinner";
import { Component, ViewChild, AfterViewInit, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { PermissionsService } from "src/app/helpers/permissions.service";
import storageX from "src/app/storageCore/storageX";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import { User } from "src/app/models/User.model";
import { Vehicle } from "src/app/models/Vehicle.model";
import ApiService from "src/app/services/api.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-consulta",
  templateUrl: "./consulta.component.html",
  styleUrls: ["./consulta.component.scss"],
})
export class ConsultaComponent implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public filter;
  public typeFilter;
  public title = "";
  public moduloPesquisa = "";

  public page = new Page();
  public errorMessage;
  public length = 0;
  public pageSize = 5000;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[];

  public displayedColumnsFullVehicle: string[] = [
    "odometer",
    "sendTpms",
    "update",
    "clone",
    "view",
    "delete",
  ];

  public displayedColumnsFull: string[] = ["update", "clone", "view", "delete"];

  public retornoEntidades: MatTableDataSource<any>;
  private apiBaseService: ApiBaseService;
  public objetoBase: Object;
  private service: ApiService;

  constructor(
    apiBaseService: ApiBaseService,
    apiService: ApiService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public permissionService: PermissionsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.service = apiService;
    this.apiBaseService = apiBaseService;
  }

  ngOnInit(): void {
    var valorPesquisa = this.activatedRoute.snapshot.params["pesquisa"];
    console.log(valorPesquisa);
    this.moduloPesquisa = valorPesquisa;

    if (
      !this.permissionService.hasPermission(valorPesquisa, this.permissions)
    ) {
      this.router.navigate(["/consultations"]);
      return;
    }

    this.loadObjectBase(valorPesquisa);
    this.loadData();
  }

  public async loadObjectBase(valorPesquisa): Promise<void> {
    if (valorPesquisa == "COMPANY_FUNCIONALIDADE") {
      this.title = "Empresas";
      this.objetoBase = new Company();
    } else if (valorPesquisa == "USER_FUNCIONALIDADE") {
      this.title = "Usuários";
      this.objetoBase = new User();
    } else if (valorPesquisa == "VEHICLE_FUNCIONALIDADE") {
      this.title = "Veículos";
      this.objetoBase = new Vehicle();
    }

    if (valorPesquisa == "VEHICLE_FUNCIONALIDADE") {
      this.displayedColumns = this.objetoBase["listCamposConsulta"];
      this.displayedColumnsFull = this.displayedColumns.concat(
        this.displayedColumnsFullVehicle
      );
    } else {
      this.displayedColumns = this.objetoBase["listCamposConsulta"];
      this.displayedColumnsFull = this.displayedColumns.concat(
        this.displayedColumnsFull
      );
    }
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.retornoEntidades.filter = filterValue.trim().toLowerCase();

    if (this.retornoEntidades.paginator) {
      this.retornoEntidades.paginator.firstPage();
    }
  }

  public handleSort = (sort): void => {
    this.loadData(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadData(pageIndex);
  };

  public handleFilter = (): void => {
    this.loadData();
  };

  public handleDelete = (value: any): void => {
    if (this.moduloPesquisa == "COMPANY_FUNCIONALIDADE" && value.cnpj == null) {
      this.deleteDivisao(value);
    } else {
      this.deleteDriver(value);
    }
  };

  public handleUpdate = (id): void => {
    console.log(id);
    this.router.navigate(this.objetoBase["nomeConsultaApiBase"] + [`/${id}`]);
  };

  ngAfterViewInit(): void { }

  public async deleteDriver(id): Promise<void> {
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        var idUsuario = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;

        await this.apiBaseService.delete(
          idUsuario,
          id.id,
          this.objetoBase["nomeConsultaApiBase"]
        );
        this.loadData();

        this.spinner.hide();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public async deleteDivisao(id): Promise<void> {
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        var idUsuario = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;

        await this.apiBaseService.delete(
          idUsuario,
          id.id,
          this.objetoBase["nomeConsultaApiBaseDelete"]
        );
        this.loadData();

        this.spinner.hide();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public async loadData(pageIndex = 0, sort?): Promise<void> {
    const params = {
      size: this.pageSize,
      page: pageIndex,
      profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
    };

    try {
      this.spinner.show();
      var result;
      if (
        this.moduloPesquisa == "VEHICLE_FUNCIONALIDADE" ||
        this.moduloPesquisa == "COMPANY_FUNCIONALIDADE" ||
        this.moduloPesquisa == "USER_FUNCIONALIDADE"
      ) {
        result = await this.apiBaseService.get<any>({
          url: this.objetoBase["nomeConsultaApiBaseGetAll"],
          params,
        });
      } else {
        result = await this.apiBaseService.get<any>({
          url: this.objetoBase["nomeConsultaApiBase"],
          params,
        });
      }

      this.retornoEntidades = new MatTableDataSource<any>(result.content);
      setTimeout(() => {
        this.retornoEntidades.sort = this.sort;
        this.retornoEntidades.paginator = this.paginator;
        this.spinner.hide();
      }, 300);

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public async handleSentOdometer(element): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Comando odômetro",
      html: `<h6>Tem certeza que deseja enviar o comando para atualizar o odômetro do terminal 
      para esse valor <b>${element.odometro}</b>? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.SentFinish(element);
      }
    });
  }

  public async SentFinish(element): Promise<void> {
    this.spinner.show();

    let idTerminal = element.terminalId;
    let odometro = element.odometro;

    const post = [{
      idUsuarioCriacao: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      terminal: { id: idTerminal },
      terminalComandoTipo: {
        id: 100,
      },
      dsTextoEnvio: odometro,
    }];

    console.log(post)

    const url = 'envio-comando-terminal/all';
    await this.service.post<any>(url, post);
    this.spinner.hide();
  } catch(exception) {
    this.errorMessage = exception.message;
    console.error(exception);
    this.spinner.hide();
  }

  public handSendTpms = (id: string): void => {
    this.sendTpms(id);
  };

  public async sendTpms(id): Promise<void> {
    if (confirm("Tem certeza que deseja enviar dados de TPMS ao terminal?")) {
      try {
        this.spinner.show();

        const post = {
          id,
        };

        await this.apiBaseService.put<Vehicle>(id, "veiculos/send-tpms", post);

        this.spinner.hide();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }
}
