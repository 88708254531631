import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { EventAlarmService } from "../../services/EventAlarm.service";
import { Router } from "@angular/router";
import { interval } from "rxjs";
import { EventService } from "src/app/services/migrate/Event.service";
import { CockpitService } from "./cockpit.service";
import { Page } from "src/app/utils/paginate";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
const INTERVAL_IN_SECONDS = 1 * 10 * 1000;

@Component({
  selector: "app-cockpit",
  templateUrl: "./cockpit.component.html",
  styleUrls: ["./cockpit.component.scss"],
})
export class CockpitComponent implements OnInit, OnDestroy {
  private service: EventAlarmService;
  private serviceEvent: EventService;
  private router: Router;
  alarms = [];
  public page = new Page();
  public cols = new Array<any>();
  private intervalRef;
  private data = new Array<any>();
  private idEventAlarm: any;
  totalOnline = 0;
  totalOffline = 0;
  totalOfflineCarregado = false;
  totalOnlineCarregado = false;

  constructor(
    private spinner: NgxSpinnerService,
    private httpService: CockpitService,
    eventAlarmService: EventAlarmService,
    serviceEvent: EventService,
    router: Router
  ) {
    this.serviceEvent = serviceEvent;
    this.service = eventAlarmService;
    this.router = router;
  }

  async ngOnInit(): Promise<void> {
    this.page.size = 10;
    this.page.currentPage = 0;

    await this.loadEventos();
    this.loadData();

    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(); // localStorage.getItem('idPerfil');
    if (!perfil) {
      throw new Error("Nenhum perfil encontrado");
    }

    this.loadTerminais(perfil);

    setTimeout(() => {
      this.setInterval();
    }, 3000);
  }

  private loadData(): void {
    this.cols = [
      { field: "placa", header: "Placa" },
      { field: "pneu", header: "Pneu" },
      { field: "pressao", header: "Pressão PSI" },
      { field: "temperatura", header: "Temperatura ºC" },
    ];
  }

  ngOnDestroy(): void {
    if (this.intervalRef) {
      this.intervalRef.unsubscribe();
    }
  }

  public handleFilter = (value: string): void => {
    // this.companies.filter = value.trim().toLocaleLowerCase();
  };

  private loadEventos() {
    this.spinner.show();

    return this.httpService
      .amount()
      .toPromise()
      .then((r) => {
        this.alarms = r;
        this.spinner.hide();
      });
  }

  public enableMenuPneu(value) {
    const result = this.alarms.find(
      ({ title }) =>
        title?.toLowerCase().indexOf(VALUE_DEFAULT_PNEU_MENU) > -1 &&
        value?.toLowerCase().indexOf(VALUE_DEFAULT_PNEU_MENU) > -1
    );
    return !!result;
  }

  private setInterval(): void {
    this.intervalRef = interval(INTERVAL_IN_SECONDS).subscribe(
      () => {
        this.httpService
          .amount()
          .toPromise()
          .then((r) => {
            this.alarms = r;
          });
        this.loadTerminais(storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get());
      },
      (error) => console.log("error on interval", error)
    );
  }

  public openTable(event, op, idEventAlarm): void {
    this.spinner.show();

    this.httpService
      .loadEvents(
        idEventAlarm,
        String(this.page.size),
        String(this.page.currentPage)
      )
      .subscribe((r) => {
        this.httpService
          .loadStatusPneu(
            this.buildPayload(r.elements),
            r.elements[0].idTerminalAlarme
          )
          .subscribe((r2: Array<any>) => {
            for (const i of r2) {
              const elm = r.elements.find(
                (e) => e?.idTerminal == i?.idTerminal
              );

              if (elm) {
                i.placa = elm?.placa;
              }
            }
            this.data = r2.sort((a, b) => {
              if (a?.placa < b?.placa) {
                return -1;
              }
              if (a?.placa > b?.placa) {
                return 0;
              }
              return 1;
            });

            this.spinner.hide();
            op?.toggle(event);
          });
      });
  }

  clickOnCard(terminalId): void {
    const rota = "/events/" + terminalId;
    this.router.navigate([rota]);
  }

  clickStatus(status): void {
    const rota = "/status/" + status;
    this.router.navigate([rota]);
  }

  private buildPayload(data: Array<any>) {
    const payload = [];

    for (const i of data) {
      payload.push({
        root: [
          {
            key: "IdEventoTerminalAlarme",
            value: i?.id,
          },
        ],
      });
    }

    return payload;
  }

  private loadTerminais(perfil) {
    this.httpService.getCountTerminaisOffline(perfil).subscribe((value) => {
      this.totalOffline = value;
      this.totalOfflineCarregado = true;
    });

    this.httpService.getCountTerminaisOnline(perfil).subscribe((value) => {
      this.totalOnline = value;
      this.totalOnlineCarregado = true;
    });
  }
}

const VALUE_DEFAULT_PNEU_MENU = "pneu";
