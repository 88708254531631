import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Modulo } from "../../models/Modulo.model";
import { Validation } from "./validation";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";

@Component({
  selector: "app-create-modulo",
  templateUrl: "./formview.component.html",
  styleUrls: ["./form.component.scss"],
})
export class ViewModuloComponent implements OnInit {
  public moduloForm: FormGroup;
  public modulo: Modulo;
  public moduloId: number;
  public title = "Visualizar Módulo";
  public errorMessage;
  private validation: Validation;
  private apiBaseService: ApiBaseService;

  ngOnInit(): void {
    this.moduloForm = this.formBuilder.group(this.validation.rules());
    this.moduloId = this.activatedRoute.snapshot.params["id"];
    this.loadModulo(this.moduloId);
  }
  public async loadModulo(id): Promise<void> {
    try {
      const response = await this.apiBaseService.get<Modulo>({
        url: new Modulo()["nomeConsultaApiBase"] + "/" + id,
      });
      this.modulo = response as Modulo;
      this.fillUserData();
    } catch (error) {
      console.error(error);
    }
  }
  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }
  fillUserData(): void {
    const fields = ["nmModulo", "dsModulo", "cdPath", "cdIcone"];

    fields.forEach((element) => {
      this.moduloForm.controls[element].setValue(this.modulo[element]);
    });
  }
}
