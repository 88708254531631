/* tslint:disable:variable-name */
import {MapPin} from './MapPin.model';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {TerminalMarker} from "../components/main/terminal-marker";
import {TerminalComponent} from "../components/maps/terminal/terminal.component";

@Injectable({
    providedIn: 'root',
})
export class CurrentPosition {
    id: number;
    // Variavel utilizada para identificar se a posição é de um terminal real ou um clone gerado pela funcionalidade de trajeto
    clone = false;
    id_terminal: number;
    id_empresa: number;
    nome_empresa: string;
    id_divisao: number;
    nome_divisao: string;
    id_motorista: number;
    nome_motorista: string;
    direcao: number;
    latitude: number;
    longitude: number;
    tag: string;
    placa: string;
    frota: string;
    data_online: Date;
    localizacao: string;
    pin: MapPin;
    //Armazena os dados das posições clonadas geradas pela funcionalidade de trajeto
    cloneTerminalData: TerminalMarker;
    terminalSubject: BehaviorSubject<CurrentPosition> = new BehaviorSubject<CurrentPosition>(null);


    constructor() {
        this.id = -1;
        this.id_terminal = -1;
        this.id_empresa = -1;
        this.nome_empresa = '';
        this.id_divisao = -1;
        this.nome_divisao = '';
        this.id_motorista = -1;
        this.nome_motorista = '';
        this.direcao = -1;
        this.latitude = -1;
        this.longitude = -1;
        this.tag = '';
        this.placa = '';
        this.frota = '';
        this.data_online = new Date();
        this.localizacao = '';
        // this.pin = {};
    }


}
