/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Inject,
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  NgZone,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';
import { MapToolboxService } from './../../../services/map.toolbox.service';
import { MapButtonService } from './../../../services/map.buttom.service';

declare let google: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  latitude: number;
  longitude: number;
  private geoCoder;
  command: any;

  private service: MapToolboxService;
  private mapButtonService: MapButtonService;

  @ViewChild('search') searchElementRef: ElementRef;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    service: MapToolboxService,
    mapButtonService: MapButtonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SearchComponent>
  ) {
    this.service = service;
    this.mapButtonService = mapButtonService;
    this.dialogRef.afterClosed().subscribe(() => {
       if (
         !this.latitude ||
        !this.longitude ||
        (this.data && this.data.action === 'path_to')
      ) {
        this.mapButtonService.setSearchButton(false);
       }
   });
  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();

      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
        });
      });
    });
  }

  close(): void {
    this.mapButtonService.setSearchButton(false);
    this.dialogRef.close();
  }

  submit(): void {
    if (!this.latitude || !this.longitude) return;

    if (this.data && this.data.action === 'path_to') {
      const { marker } = this.data;

      const data = {
        action: 'path_to',
        values: {
          latSource: parseFloat(marker.latitude),
          lngSource: parseFloat(marker.longitude),
          latTarget: this.latitude,
          lngTarget: this.longitude,
        },
      };

      console.log('data', data);

      this.service.setCommand(data);

      return;
    }
    const data = {
      action: 'search',
      values: {
        latitude: this.latitude,
        longitude: this.longitude,
      },
    };
    this.service.setCommand(data);
  }
}
