<div class="main-page cadastros">
  <div class="container-fluid">
    <app-title>{{ title ? title : 'Inserir novo' }}</app-title>

    <!-- ITEM CARDS -->
    <div class="row mt-3 no-gutters p-4 row-form">
      <div class="col-12">
        <form [formGroup]="companyForm" (ngSubmit)="submitForm()">
          <h2 class="mb-3">Dados cadastrais</h2>
          <div class="form-row">
            <div class="form-group col-6">
              <mat-form-field>
                <mat-label>Tipo de Cadastro</mat-label>
                <mat-select formControlName="selecaoEmpresa" [(value)]="empresaFilterSelected" [(ngModel)]="empresaFilterSelected" (selectionChange)="this.filterCompany()" required 
                [disabled]="this.title == 'Atualizar Empresa' || this.title == 'Clonar Empresa'" >
                  <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <validation-errors [form]="companyForm" controlLabel="Tipo de Cadastro" controlName="selecaoEmpresa">
              </validation-errors>
            </div>
            <div class="form-group col-lg-6" style="margin-top: 10px;">
                <mat-slide-toggle name="exibePainel" formControlName="exibePainel" color="primary">
                  Exibir no painel de eventos</mat-slide-toggle>
            </div>
          </div>
          <div class="form-row" *ngIf="companyForm.get('selecaoEmpresa').value == 'Divisão' || companyForm.get('selecaoEmpresa').value == null">
            <div class="form-group col-6">
              <mat-form-field>
                <mat-label>Empresa</mat-label>
                <mat-select formControlName="empresaMatriz" [(value)]="empresaMatrizSelected" [(ngModel)]="empresaMatrizSelected" [required]="companyForm.get('selecaoEmpresa').value == 'Divisão'">
                  <mat-option *ngFor="let type of companiesFilter" [value]="type.id">
                    {{ type.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <validation-errors [form]="companyForm" controlLabel="Empresa" controlName="empresaMatriz">
              </validation-errors>
            </div>
          </div>
          <div class="form-row" *ngIf="companyForm.get('selecaoEmpresa').value == 'Filial' || companyForm.get('selecaoEmpresa').value == null">
            <div class="form-group col-6">
              <mat-form-field>
                <mat-label>Matriz</mat-label>
                <mat-select formControlName="empresaMatriz" [(value)]="empresaMatrizSelected" [(ngModel)]="empresaMatrizSelected" [required]="companyForm.get('selecaoEmpresa').value == 'Filial'">
                  <mat-option *ngFor="let type of companiesFilter" [value]="type.id">
                    {{ type.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <validation-errors [form]="companyForm" controlLabel="Matriz" controlName="empresaMatriz">
              </validation-errors>
            </div>
          </div>
          <div class="form-row" *ngIf="companyForm.get('selecaoEmpresa').value == 'Matriz' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            <div class="form-group col-lg-4" >
              <mat-form-field appearance="outline">
                <mat-label>CNPJ</mat-label>
                <input formControlName="cnpj" required matInput placeholder="CNPJ" mask="00.000.000/0000-00" maxlength="20" (keypress)="numberOnly($event)"/>
              </mat-form-field>
              <validation-errors [form]="companyForm" controlLabel="CNPJ" controlName="cnpj">
              </validation-errors>
            </div>
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Inscrição estadual</mat-label>
                <input formControlName="inscricaoEstadual" pattern="[0-9]*" matInput placeholder="Inscrição estadual" maxlength="80" (keypress)="numberOnly($event)"/>
              </mat-form-field>
              <span class="alert-danger" *ngIf="!companyForm.get('inscricaoEstadual').valid">
                Inscrição estadual é um campo numérico
              </span>
            </div>
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Inscrição municipal</mat-label>
                <input formControlName="inscricaoMunicipal" pattern="[0-9]*" matInput placeholder="Inscrição municipal"
                  maxlength="80" (keypress)="numberOnly($event)"/>
              </mat-form-field>
              <span class="alert-danger" *ngIf="!companyForm.get('inscricaoMunicipal').valid">
                Inscrição municipal é um campo numérico
              </span>
            </div>
          </div>    

          <div class="form-row" *ngIf="companyForm.get('selecaoEmpresa').value == 'Matriz' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            <div class="form-group col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>Razão social</mat-label>
                <input formControlName="razaoSocial" matInput placeholder="Razão social" maxlength="80" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>Nome fantasia</mat-label>
                <input formControlName="nome" matInput placeholder="Nome fantasia" maxlength="80" required/>
              </mat-form-field>
              <validation-errors [form]="companyForm" controlLabel="Nome fantasia" controlName="nome">
              </validation-errors>
            </div>
          </div>
          <div class="form-row"*ngIf="companyForm.get('selecaoEmpresa').value == 'Divisão'">
            <div class="form-group col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>Nome</mat-label>
                <input formControlName="nome" matInput placeholder="Nome" maxlength="80" required/>
              </mat-form-field>
              <validation-errors [form]="companyForm" controlLabel="Nome" controlName="nome">
              </validation-errors>
            </div>
          </div>
          <div class="form-row" *ngIf="companyForm.get('selecaoEmpresa').value == 'Matriz' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            <div class="form-group col-6">
              <mat-form-field>
                <mat-label>Tipo de empresa</mat-label>
                <mat-select formControlName="empresaTipo" [(value)]="empresaTipoSelected" required>
                  <mat-option *ngFor="let type of types" [value]="type.id">
                    {{ type.nome }}
                  </mat-option>
                </mat-select>           
              </mat-form-field>
              <validation-errors [form]="companyForm" controlLabel="Tipo de empresa" controlName="empresaTipo">
              </validation-errors>
            </div>
          </div>

          <h2 class="my-3">Contato</h2>
          <div class="form-row">
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Contato</mat-label>
                <input formControlName="contato" matInput placeholder="Contato" maxlength="80" required />
              </mat-form-field>
              <validation-errors [form]="companyForm" controlLabel="Contato" controlName="contato">
              </validation-errors>
            </div>

            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Telefone*</mat-label>
                <input formControlName="telefone" pattern="[0-9]*" matInput placeholder="Telefone" maxlength="20" (keypress)="numberOnly($event)" ngModel="0"/>
              </mat-form-field>
              <span class="alert-danger" *ngIf="!companyForm.get('telefone').valid">
                Telefone é um campo obrigatório e numérico
              </span>
            </div>

            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Celular</mat-label>
                <input formControlName="fax" pattern="[0-9]*" matInput placeholder="Celular" maxlength="20" (keypress)="numberOnly($event)"/>
              </mat-form-field>
              <span class="alert-danger" *ngIf="!companyForm.get('fax').valid">
                Celular é um campo numérico
              </span>
            </div>

            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>E-mail</mat-label>
                <input formControlName="email" matInput placeholder="E-mail" maxlength="80" required />
              </mat-form-field>
              <validation-errors [form]="companyForm" controlLabel="E-mail" controlName="email">
              </validation-errors>
            </div>
          </div>

          <h2 class="my-3">Endereço</h2>
          <div class="form-row">
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>CEP</mat-label>
                <input formControlName="cep" matInput placeholder="CEP" maxlength="10" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-1">
              <a (click)="searchCep()" mat-icon-button class="btn-cep-search">
                <mat-icon>search</mat-icon>
              </a>
            </div>
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Logradouro</mat-label>
                <input formControlName="endereco" matInput placeholder="Logradouro" maxlength="80" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Número</mat-label>
                <input formControlName="numero" matInput placeholder="Número" maxlength="20" />
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Complemento</mat-label>
                <input formControlName="complemento" matInput placeholder="Complemento" maxlength="40" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Bairro</mat-label>
                <input formControlName="bairro" matInput placeholder="Bairro" maxlength="100" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Cidade</mat-label>
                <input formControlName="cidade" matInput placeholder="Cidade" maxlength="100" />
              </mat-form-field>
            </div>
            <div class="form-group col-lg-3">
              <mat-form-field appearance="outline">
                <mat-label>Estado</mat-label>
                <input formControlName="estado" matInput placeholder="Estado" maxlength="100" />
              </mat-form-field>
            </div>
          </div>

          <h2 class="my-3">Observações</h2>
          <div class="form-row">
            <div class="form-group col-lg-12">
              <mat-form-field appearance="outline">
                <mat-label>Observações</mat-label>
                <input formControlName="observacao" matInput placeholder="Informe observações" maxlength="200" />
              </mat-form-field>
            </div>
          </div>

           <h2 class="my-3" *ngIf="companyForm.get('selecaoEmpresa').value == 'Empresa' || companyForm.get('selecaoEmpresa').value == 'Filial'">Autorizações ANTT</h2>

          <div formArrayName="listEmpresaAutorizacaoAntt" *ngIf="companyForm.get('selecaoEmpresa').value == 'Empresa' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            <div *ngFor="
                let item of empresaAutorizacaoAnttItems.controls;
                let itemIndex = index
              " [formGroupName]="itemIndex">
              <input *ngIf="companyId" type="hidden" formControlName="autorizacaoAnttId" />

              <div class="form-row">
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo de autorização</mat-label>
                    <input formControlName="tipoAutorizacao" matInput placeholder="Tipo de autorização" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Número</mat-label>
                    <input formControlName="numero" pattern="[0-9]*" matInput placeholder="Número" (keypress)="numberOnly($event)"/>
                  </mat-form-field>
                  <span class="alert-danger" *ngIf="!item.get('numero').valid">
                    Número é um campo numérico
                  </span> 
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de cadastro </mat-label>
                    <input min="1000-12-31" max="3000-12-31" type="date" formControlName="dataCadastro" matInput placeholder="Data de cadastro" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de vencimento</mat-label>
                    <input min="1000-12-31" max="3000-12-31" type="date" formControlName="dataVencimento" matInput placeholder="Data de vencimento"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-lg-6">
                  <span>Gerar alerta de vencimento?</span>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" formControlName="flagGerarAlertaVencimento" type="radio"
                      id="inlineRadio1" [value]="true" />
                    <label class="form-check-label" for="inlineRadio1">Sim</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="flagGerarAlertaVencimento"
                      id="inlineRadio2" [value]="false" />
                    <label class="form-check-label" for="inlineRadio2">Não</label>
                  </div>
                </div>
                <div class="form-group col-lg-6 text-left text-lg-right">
                  <a (click)="deleteAutorizacaoAnttItem(itemIndex)">Excluir autorização
                    <img src="assets/img/trash.svg" class="mb-1" height="20" alt="" /></a>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-12 text-center" *ngIf="companyForm.get('selecaoEmpresa').value == 'Empresa' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            <a class="repeat-line" (click)="addAutorizacaoAnttItem()"><img src="assets/img/plus.svg" class="mb-0"
                height="20" alt="" />
              Adicionar nova autorização</a>
          </div>

          <hr />

         <h2 class="my-3" *ngIf="companyForm.get('selecaoEmpresa').value == 'Empresa' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            Seguro de Responsabilidade Civil do Transportador de Cargas - RCTR-C
          </h2>

          <div formArrayName="listEmpresaSeguroRctrcList" *ngIf="companyForm.get('selecaoEmpresa').value == 'Empresa' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            <div *ngFor="
                let item of empresaSeguroRctrcItems.controls;
                let itemIndex = index
              " [formGroupName]="itemIndex">
              <input *ngIf="companyId" type="hidden" formControlName="seguroRctrcId" />

              <div class="form-row">
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Número da apólice</mat-label>
                    <input formControlName="numeroApolice" pattern="[0-9]*" matInput placeholder="Número da apólice" (keypress)="numberOnly($event)"/>
                  </mat-form-field>
                  <span class="alert-danger" *ngIf="!item.get('numeroApolice').valid">
                    Número da apólice é um campo numérico
                  </span> 
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Seguradora</mat-label>
                    <input formControlName="seguradora" matInput placeholder="Seguradora" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de contratação</mat-label>
                    <input min="1000-12-31" max="3000-12-31" formControlName="dataContratacao" matInput type="date" placeholder="Data de contratação" />
                  </mat-form-field>
                </div>
                <div class="form-group col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Data de vencimento</mat-label>
                    <input min="1000-12-31" max="3000-12-31" formControlName="dataVencimento" matInput type="date" placeholder="Data de vencimento" />
                  </mat-form-field>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-lg-6">
                  <span>Gerar alerta de vencimento?</span>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" formControlName="flagGerarAlertaVencimento" type="radio"
                      id="rctrInlineRadio1" [value]="true" />
                    <label class="form-check-label" for="rctrInlineRadio1">Sim</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="flagGerarAlertaVencimento"
                      id="rctrInlineRadio2" [value]="false" />
                    <label class="form-check-label" for="rctrInlineRadio2">Não</label>
                  </div>
                </div>
                <div class="form-group col-lg-6 text-left text-lg-right">
                  <a (click)="deleteSeguroRctrcItem(itemIndex)">Excluir autorização
                    <img src="assets/img/trash.svg" class="mb-1" height="20" alt="" /></a>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-12 text-center" *ngIf="companyForm.get('selecaoEmpresa').value == 'Empresa' || companyForm.get('selecaoEmpresa').value == 'Filial'">
            <a class="repeat-line" (click)="addSeguroRctrcItem()"><img src="assets/img/plus.svg" class="mb-0"
                height="20" alt="" />
              Adicionar novo seguro</a>
          </div>
          <hr />       
          <div *ngIf="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>

          <div class="form-group mt-2 mb-0 text-center">
            <back-button></back-button>
            <!-- [disabled]="!companyForm.invalid" [disabled]="!companyForm.valid" -->
            <button type="submit" [disabled]="!companyForm.valid"
              class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
