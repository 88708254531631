<div class="main-page cockpit consultas">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 my-2">
        <h1>
          Painel de Eventos
        </h1>
      </div>
    </div>

    <div class="wrapper">
      <div class="row">
        <mat-card (click)="clickStatus(1)" style="cursor: pointer;" class="col-lg-3 my-3">                   
          <div class="details" *ngIf="totalOnlineCarregado">
            <mat-icon [ngStyle]="{'color': 'green'}" style="font-size: 30px;">stop_circle</mat-icon>             
            <div class="desc">                        
                {{totalOnline}} terminal(is) Online              
            </div>
          </div> 
          <div class="details" *ngIf="!totalOnlineCarregado">
            <div class="desc">                        
                Carregando dados de terminal(is) Online              
            </div>
          </div> 
        </mat-card>
        <mat-card (click)="clickStatus(0)" style="cursor: pointer;" class="col-lg-3 my-3">                   
          <div class="details" *ngIf="totalOfflineCarregado">
            <mat-icon [ngStyle]="{'color': 'red'}" style="font-size: 30px;">stop_circle</mat-icon>
            <div class="desc">
              {{totalOffline}} terminal(is) Offline
            </div>
          </div>  
          <div class="details" *ngIf="!totalOfflineCarregado">
            <div class="desc">                        
                Carregando dados de terminal(is) Offline              
            </div>
          </div>         
        </mat-card>
      </div>
    </div>

    <div class="wrapper">
      <div class="row">
        <mat-card style="cursor: pointer;" class="col-lg-3 my-2" *ngIf="!this.alarms.length">
          <a class="dashboard-stat">
            <div class="visual">
              <!-- <i class="fa fa-info"></i> -->
            </div>
            <div class="details">
              <div class="number">
                <span style="visibility: hidden;">0</span>
              </div>
              <div class="desc">Não há eventos para exibir!</div>
            </div>
          </a>
        </mat-card>
        <mat-card style="cursor: pointer;" class="col-lg-3 my-2" *ngFor="let alarm of alarms">

          <span style="float: right;" *ngIf="this.enableMenuPneu(alarm?.title)">
                      <small>
                            <strong style="margin-right: 17px; !important;">
                                 <p-button [label]="'Orientações'" icon="pi pi-search" (click)="this.openTable($event, op, alarm?.idEventAlarm)"></p-button>
                            </strong>
                      </small>
          </span>

          <br>
          <br>
        
          <span [ngClass]="alarm?.statusColor == 'red' ? 'fa-blink' : '' " [ngStyle]="{ color: alarm?.statusColor }" class="icon_panel material-icons">
            fiber_smart_record
            </span>

          <div class="titleCard">
            <small style="font-weight: 500;">{{ alarm?.factor }} </small>
          </div>
          <mat-divider></mat-divider>
          <a class="dashboard-stat"
            (click)="clickOnCard(alarm?.idEventAlarm)">
            <div class="visual">
              <!-- <i class="fa fa-info"></i> -->
            </div>
            <div class="details">
              <div class="number">
                <span style="font-weight: 600;"> {{ alarm.amount }}</span>
              </div>
              <div class="desc">{{ alarm.title }}</div>
            </div>
          </a>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '450px'}">
  <ng-template pTemplate>
    <p-table [columns]="this.cols" [value]="this.data" selectionMode="single" [rows]="5">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
