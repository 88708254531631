import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { AnalyzeTripService } from './analyze-trip.service';

@Injectable({
  providedIn: 'root'
})
export class MinioService {

  constructor(private analyzeTripService: AnalyzeTripService) { }

  exportReport(element: string): Observable<any> {
    if (element) {
      element = element.replace(/\//g, "-");
    }
    return this.analyzeTripService.exportReport(element).pipe(
      tap(response => {
        const linkMinio = response.link_minio;
        if (linkMinio) {
          window.open(linkMinio, "_blank");
        } else {
          console.error("Link MinIO não fornecido na resposta.");
        }
      })
    );
  }
}
