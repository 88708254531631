import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { VeiculoSensor } from "src/app/models/VeiculoSensor.model";
import { VwSensor } from "src/app/models/VwSensor.model";
import { EventEmitterSensorService } from "src/app/services/event-emitter-sensor.service";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import Swal from "sweetalert2";

@Component({
  selector: "app-axle-five-dual",
  templateUrl: "./axle-five-dual.component.html",
  styleUrls: ["./axle-five-dual.component.scss"],
})
export class AxleFiveDualComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() sensoresDisponiveis: any;
  @Input() veiculoId: number;

  @Output() atualizarSensores = new EventEmitter<void>();

  public sensorFiveIntLeftSelect: any = [];
  public sensorFiveIntRightSelect: any = [];

  public sensorFiveExtLeftSelect: any = [];
  public sensorFiveExtRightSelect: any = [];

  public sensorFiveIntRightCombo: any = [];
  public sensorFiveIntLeftCombo: any = [];

  public sensorFiveExtRightCombo: any = [];
  public sensorFiveExtLeftCombo: any = [];

  public axisFiveDoubleLeftIntForm: FormGroup;
  public axisFiveDoubleLeftExtForm: FormGroup;
  public axisFiveDoubleRightIntForm: FormGroup;
  public axisFiveDoubleRightExtForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private serviceApiBase: ApiBaseService,
    private componentResetService: EventEmitterSensorService
  ) {}

  ngOnInit(): void {
    this.componentResetService.reiniciarComponentes$.subscribe(async (a) => {
      this.sensoresDisponiveis = null;
      this.veiculoSensores = null;
      this.sensorFiveIntRightCombo = [];
      this.sensorFiveIntLeftCombo = [];
      this.sensorFiveExtRightCombo = [];
      this.sensorFiveExtLeftCombo = [];

      const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();

      const eixo = await this.serviceApiBase.get<any>({
        url: new VeiculoSensor()["nomeConsultaApiBase"] + this.veiculoId,
      });

      this.veiculoSensores = eixo["content"];

      const sensor = await this.serviceApiBase.get<any>({
        url:
          new VwSensor()["nomeConsultaApiBaseSensor"] +
          this.veiculoId +
          "/perfil/" +
          perfil,
      });

      this.sensoresDisponiveis = sensor;

      if (this.sensoresDisponiveis.length > 0) {
        this.sensoresDisponiveis.forEach((element) => {
          this.sensorFiveIntRightCombo.push(element);
          this.sensorFiveIntLeftCombo.push(element);
          this.sensorFiveExtRightCombo.push(element);
          this.sensorFiveExtLeftCombo.push(element);
        });
      }

      this.loadSensor();
    });
    this.axisFiveDoubleLeftIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisFiveDoubleLeftExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisFiveDoubleRightIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisFiveDoubleRightExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    if (this.sensoresDisponiveis.length > 0) {
      this.sensoresDisponiveis.forEach((element) => {
        this.sensorFiveExtRightCombo.push(element);
        this.sensorFiveExtLeftCombo.push(element);
        this.sensorFiveIntRightCombo.push(element);
        this.sensorFiveIntLeftCombo.push(element);
      });
    }

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisFiveDoubleLeftIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 5 ESQ INT"
      );
      this.axisFiveDoubleLeftExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 5 ESQ EXT"
      );

      this.axisFiveDoubleRightIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 5 DIR INT"
      );
      this.axisFiveDoubleRightExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 5 DIR EXT"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo5DireitoExt;

        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 12) {
              eixo5DireitoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 12) {
              eixo5DireitoExt = filter;
            }
          }
        });
        var eixo5EsquerdoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 11) {
              eixo5EsquerdoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 11) {
              eixo5EsquerdoExt = filter;
            }
          }
        });
        var eixo5DireitoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 28) {
              eixo5DireitoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 28) {
              eixo5DireitoInt = filter;
            }
          }
        });
        var eixo5EsquerdoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 27) {
              eixo5EsquerdoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 27) {
              eixo5EsquerdoInt = filter;
            }
          }
        });

        // EIXO 5 DIREITO EXTERNO
        if (eixo5DireitoExt != null) {
          this.axisFiveDoubleRightExtForm.controls["id"].setValue(
            eixo5DireitoExt.id
          );

          if (eixo5DireitoExt.sensor != null) {
            this.sensorFiveExtRightCombo.push(eixo5DireitoExt.sensor);
            this.sensorFiveExtRightSelect = eixo5DireitoExt.sensor.id;
          }

          if (eixo5DireitoExt.pneu != null) {
            this.axisFiveDoubleRightExtForm.controls["pneu"].setValue(
              eixo5DireitoExt.pneu.numeroFogo
            );
          }

          this.axisFiveDoubleRightExtForm.controls["nrPressaoMinima"].setValue(
            eixo5DireitoExt.nrPressaoMinima
          );

          this.axisFiveDoubleRightExtForm.controls["nrPressaoMaxima"].setValue(
            eixo5DireitoExt.nrPressaoMaxima
          );

          this.axisFiveDoubleRightExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo5DireitoExt.nrTemperaturaMaxima);
        }

        // EIXO 5 ESQUERDO EXTERNO
        if (eixo5EsquerdoExt != null) {
          this.axisFiveDoubleLeftExtForm.controls["id"].setValue(
            eixo5EsquerdoExt.id
          );

          if (eixo5EsquerdoExt.sensor != null) {
            this.sensorFiveExtLeftCombo.push(eixo5EsquerdoExt.sensor);
            this.sensorFiveExtLeftSelect = eixo5EsquerdoExt.sensor.id;
          }

          if (eixo5EsquerdoExt.pneu != null) {
            this.axisFiveDoubleLeftExtForm.controls["pneu"].setValue(
              eixo5EsquerdoExt.pneu.numeroFogo
            );
          }

          this.axisFiveDoubleLeftExtForm.controls["nrPressaoMinima"].setValue(
            eixo5EsquerdoExt.nrPressaoMinima
          );

          this.axisFiveDoubleLeftExtForm.controls["nrPressaoMaxima"].setValue(
            eixo5EsquerdoExt.nrPressaoMaxima
          );

          this.axisFiveDoubleLeftExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo5EsquerdoExt.nrTemperaturaMaxima);
        }

        // EIXO 5 DIREITO INTERNO
        if (eixo5DireitoInt != null) {
          this.axisFiveDoubleRightIntForm.controls["id"].setValue(
            eixo5DireitoInt.id
          );

          if (eixo5DireitoInt.sensor != null) {
            this.sensorFiveIntRightCombo.push(eixo5DireitoInt.sensor);
            this.sensorFiveIntRightSelect = eixo5DireitoInt.sensor.id;
          }

          if (eixo5DireitoInt.pneu != null) {
            this.axisFiveDoubleRightIntForm.controls["pneu"].setValue(
              eixo5DireitoInt.pneu.numeroFogo
            );
          }

          this.axisFiveDoubleRightIntForm.controls["nrPressaoMinima"].setValue(
            eixo5DireitoInt.nrPressaoMinima
          );

          this.axisFiveDoubleRightIntForm.controls["nrPressaoMaxima"].setValue(
            eixo5DireitoInt.nrPressaoMaxima
          );

          this.axisFiveDoubleRightIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo5DireitoInt.nrTemperaturaMaxima);
        }

        // EIXO 5 ESQUERDO INTERNO
        if (eixo5EsquerdoInt != null) {
          this.axisFiveDoubleLeftIntForm.controls["id"].setValue(
            eixo5EsquerdoInt.id
          );

          if (eixo5EsquerdoInt.sensor != null) {
            this.sensorFiveIntLeftCombo.push(eixo5EsquerdoInt.sensor);
            this.sensorFiveIntLeftSelect = eixo5EsquerdoInt.sensor.id;
          }

          if (eixo5EsquerdoInt.pneu != null) {
            this.axisFiveDoubleLeftIntForm.controls["pneu"].setValue(
              eixo5EsquerdoInt.pneu.numeroFogo
            );
          }

          this.axisFiveDoubleLeftIntForm.controls["nrPressaoMinima"].setValue(
            eixo5EsquerdoInt.nrPressaoMinima
          );

          this.axisFiveDoubleLeftIntForm.controls["nrPressaoMaxima"].setValue(
            eixo5EsquerdoInt.nrPressaoMaxima
          );

          this.axisFiveDoubleLeftIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo5EsquerdoInt.nrTemperaturaMaxima);
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public async submitFormDelete(form: FormGroup): Promise<void> {
    this.spinner.show();
    try {
      const values = form.value;
      var post;

      post = {
        veiculo: {
          id: this.veiculoId,
        },
        nrPressaoMinima: values.nrPressaoMinima,
        nrPressaoMaxima: values.nrPressaoMaxima,
        nrTemperaturaMaxima: values.nrTemperaturaMaxima,
        sensor: {
          id: values.cdSensor,
        },
        pneu: null,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      console.log(post);

      const result = await this.serviceApiBase.put<VeiculoSensor>(
        values.id,
        new VeiculoSensor()["nomeConsultaApiBaseRemove"],
        post
      );

      form.get("cdSensor").setValue("");

      this.spinner.hide();

      this.handleDelete();

      this.atualizarSensores.emit();
    } catch (error) {
      this.spinner.hide();
      //this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public async submitFormDeleteAll(): Promise<void> {
    this.spinner.show();
    try {
      var estepeArray: any = [];
      var posicoesPneusList: any = [];

      estepeArray.push(this.axisFiveDoubleLeftExtForm.value);
      estepeArray.push(this.axisFiveDoubleRightExtForm.value);
      estepeArray.push(this.axisFiveDoubleLeftIntForm.value);
      estepeArray.push(this.axisFiveDoubleRightIntForm.value);

      if (estepeArray.length > 0) {
        estepeArray.forEach((element) => {
          var posicaoPneuResquest = {
            cdPosicaoPneu: element.cdPosicaoPneu,
          };
          posicoesPneusList.push(posicaoPneuResquest);
        });
      }

      var post = {
        veiculoSensores: posicoesPneusList,
      };

      const result = await this.serviceApiBase.put<VeiculoSensor>(
        this.veiculoId,
        new VeiculoSensor()["nomeConsultaApiBaseRemoveAll"],
        post
      );

      this.axisFiveDoubleLeftExtForm.get("cdSensor").setValue("");
      this.axisFiveDoubleLeftIntForm.get("cdSensor").setValue("");
      this.axisFiveDoubleRightExtForm.get("cdSensor").setValue("");
      this.axisFiveDoubleRightIntForm.get("cdSensor").setValue("");

      this.spinner.hide();

      this.handleDeleteAll();

      this.atualizarSensores.emit();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public async submitFormAddAll(): Promise<void> {
    this.spinner.show();
    try {
      var estepeArray: any = [];
      var posicoesPneusList: any = [];

      estepeArray.push(this.axisFiveDoubleLeftExtForm.value);
      estepeArray.push(this.axisFiveDoubleLeftIntForm.value);
      estepeArray.push(this.axisFiveDoubleRightExtForm.value);
      estepeArray.push(this.axisFiveDoubleRightIntForm.value);

      if (estepeArray.length > 0) {
        var boolean = this.verificarDuplicados(estepeArray);

        if (boolean == true) {
          this.handleItensDuplicados();
          this.spinner.hide();
          return;
        }

        estepeArray.forEach((element) => {
          if (element.cdSensor != "") {
            var posicaoPneuResquest = {
              cdPosicaoPneu: element.cdPosicaoPneu,
              cdSensor: element.cdSensor,
              pneu: element.pneu,
              nrPressaoMinima: element.nrPressaoMinima,
              nrPressaoMaxima: element.nrPressaoMaxima,
              nrTemperaturaMaxima: element.nrTemperaturaMaxima,
              idPerfil: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
            };
            posicoesPneusList.push(posicaoPneuResquest);
          }
        });
      }

      var post = {
        veiculoSensores: posicoesPneusList,
      };

      const result = await this.serviceApiBase.put<VeiculoSensor>(
        this.veiculoId,
        new VeiculoSensor()["nomeConsultaApiBaseAddAll"],
        post
      );

      this.spinner.hide();

      this.handleAddAll();

      this.atualizarSensores.emit();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  verificarDuplicados(axisOneArray: any[]): boolean {
    const seen = new Set();
    for (const item of axisOneArray) {
      if (seen.has(item.cdSensor)) {
        return true; // Retorna true se encontrar um valor duplicado
      } else {
        seen.add(item.cdSensor);
      }
    }
    return false; // Retorna false se não houver valores duplicados
  }

  public async handleItensDuplicados(): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Sensores selecionados estão com duplicidade, por favor corrigir!",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public async handleAddAll(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Sensores do Eixo 5 vinculados com sucesso",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public async handleDeleteAll(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Sensores do Eixo 5 desvinculados com sucesso",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }

  public async handleDelete(): Promise<void> {
    Swal.fire({
      icon: "success",
      title: "Sensor desvinculados com sucesso",
      showCloseButton: false,
      reverseButtons: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      focusConfirm: false,
    });
  }
}
