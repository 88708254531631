import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { PneuInterface } from "src/app/contracts/Pneu.interface";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { Pneu } from "src/app/models/Pneu.model";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";
import storageX from "src/app/storageCore/storageX";
import { Page } from "src/app/utils/paginate";

import Swal from "sweetalert2";

@Component({
  selector: "app-pneu",
  templateUrl: "./pneu.component.html",
  styleUrls: ["./pneu.component.scss"],
})
export class PneuComponent implements OnInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public filter;
  public typeFilter;

  public page = new Page();
  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[] = [
    "id",
    "numeroFogo",
    "marca",
    "nome",
    "posicao",
    "placa",
    "update",
    "clone",
    "view",
    "delete",
  ];

  private apiBaseService: ApiBaseService;

  public pneu: MatTableDataSource<PneuInterface>;

  constructor(
    apiBaseService: ApiBaseService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.apiBaseService = apiBaseService;
    this.pneu = new MatTableDataSource<PneuInterface>([]);
  }

  ngOnInit(): void {
    this.loadPneu();
  }

  public handleSort = (sort): void => {
    this.loadPneu(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadPneu(pageIndex);
  };

  public async handleDelete(element): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Excluir Pneu",
      html: `<h6>Tem certeza que você deseja excluir o pneu
       <small><b> ${element.numeroFogo}</b>? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.deletePneu(element.id);
      }
    });
  }

  public handleUpdate = (id): void => {
    this.router.navigate([`/pneu/${id}`]);
  };

  ngAfterViewInit(): void {
    this.pneu.sort = this.sort;
    this.pneu.paginator = this.paginator;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.pneu.filter = filterValue.trim().toLowerCase();

    console.log(this.pneu);
    if (this.pneu.paginator) {
      this.pneu.paginator.firstPage();
    }
  }

  public async deletePneu(id): Promise<void> {
    const idUsuario = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;
    try {
      this.spinner.show();

      await this.apiBaseService.delete<Pneu>(
        idUsuario,
        id,
        new Pneu()["nomeConsultaApiBase"]
      );

      this.spinner.hide();

      this.loadPneu();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public async loadPneu(pageIndex = 0, sort?): Promise<void> {
    const params = {
      profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      size: this.pageSize,
      page: pageIndex,
    };

    try {
      this.spinner.show();

      const result = await this.apiBaseService.get<any>({
        url: new Pneu()["nomeConsultaApiBaseGetAll"],
        params,
      });
      this.pneu.data = result["content"];
      this.page = result["page"];

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
