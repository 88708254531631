import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { NgxSpinnerService } from "ngx-spinner";
import { Sensor } from "../../models/Sensor.model";
import { Validation } from "./validation";
import storageX from "../../storageCore/storageX";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "../../storageCore/constStorageKeys";
import { ApiBaseService } from "../../services/migrate/api.base.service";
import { Company } from "src/app/models/Company.model";

@Component({
  selector: "app-create-sensor",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class EditSensorComponent implements OnInit {
  public sensorForm: FormGroup;
  public sensor: Sensor;
  public sensorId: number;
  public title = "Atualizar Sensor";
  public errorMessage;
  private apiBaseService: ApiBaseService;
  validation: Validation;

  public empresaSelected;
  public empresaFilterSelected;

  public companies: any = [];
  public companiesFilter: any = [];
  public companiesSelect: any = [
    { name: "Divisão", value: 1 },
    { name: "Matriz", value: 2 },
    { name: "Filial", value: 3 },
  ];
  public companiesUpdateSelect: any = [];

  ngOnInit(): void {
    this.sensorForm = this.formBuilder.group(this.validation.rules());
    this.sensorId = this.activatedRoute.snapshot.params["id"];
    this.loadSensor(this.sensorId);
  }
  public async loadSensor(id): Promise<void> {
    try {
      this.spinner.show();

      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      const result = await this.apiBaseService.get<any>({
        url: new Company()["nomeConsultaApiBaseGetAll"],
        params,
      });

      this.companies = result["content"];

      const response = await this.apiBaseService.get<Sensor>({
        url: new Sensor()["nomeConsultaApiBase"] + "/" + id,
      });
      this.sensor = response as Sensor;

      console.log(this.sensor);

      if (this.sensor.idEmpresa != null) {
        if (this.sensor.idEmpresaMatriz == null) {
          this.empresaFilterSelected = "Matriz";
        } else {
          this.empresaFilterSelected = "Filial";
        }
      } else {
        this.empresaFilterSelected = "Divisão";
      }

      this.filterCompany();

      if (this.sensor.idEmpresa != null) {
        this.sensorForm.controls["idEmpresa"].setValue(this.sensor.idEmpresa);
      } else {
        this.sensorForm.controls["idEmpresa"].setValue(this.sensor.idDivisao);
      }

      this.fillUserData();
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }
  constructor(
    apiBaseService: ApiBaseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.apiBaseService = apiBaseService;
    this.validation = new Validation();
  }

  fillUserData(): void {
    const fields = ["codigoSensor"];
    fields.forEach((element) => {
      this.sensorForm.controls[element].setValue(this.sensor[element]);
    });
  }
  public async submitForm(): Promise<void> {
    try {
      this.spinner.show();
      this.coverterUpperCase(this.sensorForm);

      const values = this.sensorForm.value;
      const id = Math.floor(this.sensorId);
      var post;

      if (this.empresaFilterSelected != "Divisão") {
        post = {
          id,
          codigoSensor: values.codigoSensor,
          empresa: {
            id: parseInt(values.idEmpresa),
          },
          divisao: null,
          idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
        };
      } else {
        post = {
          id,
          codigoSensor: values.codigoSensor,
          divisao: {
            id: parseInt(values.idEmpresa),
          },
          empresa: null,
          idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
        };
      }

      await this.apiBaseService.put<Sensor>(
        this.sensorId,
        new Sensor()["nomeConsultaApiBase"],
        post
      );
      this.spinner.hide();
      this.router.navigate(["/sensor"]);
    } catch (error) {
      this.errorMessage = error.message;
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public filterCompany() {
    var idEmpresa = this.empresaFilterSelected;
    this.sensorForm.controls["idEmpresa"].setValue(null);
    var empresas = [];

    if (idEmpresa == "Divisão") {
      this.companies.forEach((element) => {
        if (element.cnpj == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Matriz") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz == null) {
          empresas.push(element);
        }
      });
    }

    if (idEmpresa == "Filial") {
      this.companies.forEach((element) => {
        if (element.cnpj != null && element.idEmpresaMatriz != null) {
          empresas.push(element);
        }
      });
    }

    this.companiesFilter = empresas;

    console.log(this.companiesFilter);
  }

  public coverterUpperCase(sensorForm: FormGroup) {
    sensorForm
      .get("codigoSensor")
      .setValue(sensorForm.get("codigoSensor").value.toUpperCase());
  }
}
