import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-axle-nine-dual-view",
  templateUrl: "./axle-nine-dual-view.component.html",
  styleUrls: ["./axle-nine-dual-view.component.scss"],
})
export class AxleNineDualViewComponent implements OnInit {
  @Input() veiculoSensores: any;
  @Input() veiculoId: number;

  public sensorNineIntLeftSelect: any = [];
  public sensorNineIntRightSelect: any = [];

  public sensorNineExtLeftSelect: any = [];
  public sensorNineExtRightSelect: any = [];

  public sensorNineIntRightCombo: any = [];
  public sensorNineIntLeftCombo: any = [];

  public sensorNineExtRightCombo: any = [];
  public sensorNineExtLeftCombo: any = [];

  public axisNineDoubleLeftIntForm: FormGroup;
  public axisNineDoubleLeftExtForm: FormGroup;
  public axisNineDoubleRightIntForm: FormGroup;
  public axisNineDoubleRightExtForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.axisNineDoubleLeftIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisNineDoubleLeftExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisNineDoubleRightIntForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.axisNineDoubleRightExtForm = this.formBuilder.group(
      {
        id: [""],
        pneu: [null],
        cdPosicaoPneu: [""],
        cdSensor: [""],
        nrPressaoMinima: ["", Validators.required],
        nrPressaoMaxima: ["", Validators.required],
        nrTemperaturaMaxima: ["", Validators.required],
      },

      { updateOn: "blur" }
    );

    this.loadSensor();
  }

  public async loadSensor(): Promise<void> {
    try {
      this.spinner.show();
      this.axisNineDoubleLeftIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 9 ESQ INT"
      );
      this.axisNineDoubleLeftExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 9 ESQ EXT"
      );

      this.axisNineDoubleRightIntForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 9 DIR INT"
      );
      this.axisNineDoubleRightExtForm.controls["cdPosicaoPneu"].setValue(
        "EIXO 9 DIR EXT"
      );

      if (this.veiculoSensores.length > 0) {
        var eixo9DireitoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 56) {
              eixo9DireitoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 56) {
              eixo9DireitoExt = filter;
            }
          }
        });
        var eixo9EsquerdoExt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 58) {
              eixo9EsquerdoExt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 58) {
              eixo9EsquerdoExt = filter;
            }
          }
        });
        var eixo9DireitoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 55) {
              eixo9DireitoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 55) {
              eixo9DireitoInt = filter;
            }
          }
        });
        var eixo9EsquerdoInt;
        this.veiculoSensores.forEach((filter) => {
          if (filter.pneu != null) {
            if (filter.pneu.posicaoPneu.id == 57) {
              eixo9EsquerdoInt = filter;
            }
          } else {
            if (filter.posicaoPneu.id == 57) {
              eixo9EsquerdoInt = filter;
            }
          }
        });

        // EIXO 9 DIREITO EXTERNO
        if (eixo9DireitoExt != null) {
          this.axisNineDoubleRightExtForm.controls["id"].setValue(
            eixo9DireitoExt.id
          );

          if (eixo9DireitoExt.sensor != null) {
            this.sensorNineExtRightCombo.push(eixo9DireitoExt.sensor);
            this.sensorNineExtRightSelect = eixo9DireitoExt.sensor.id;
          }

          if (eixo9DireitoExt.pneu != null) {
            this.axisNineDoubleRightExtForm.controls["pneu"].setValue(
              eixo9DireitoExt.pneu.numeroFogo
            );
          }

          this.axisNineDoubleRightExtForm.controls["nrPressaoMinima"].setValue(
            eixo9DireitoExt.nrPressaoMinima
          );

          this.axisNineDoubleRightExtForm.controls["nrPressaoMaxima"].setValue(
            eixo9DireitoExt.nrPressaoMaxima
          );

          this.axisNineDoubleRightExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo9DireitoExt.nrTemperaturaMaxima);
        }

        // EIXO 9 ESQUERDO EXTERNO
        if (eixo9EsquerdoExt != null) {
          this.axisNineDoubleLeftExtForm.controls["id"].setValue(
            eixo9EsquerdoExt.id
          );

          if (eixo9EsquerdoExt.sensor != null) {
            this.sensorNineExtLeftCombo.push(eixo9EsquerdoExt.sensor);
            this.sensorNineExtLeftSelect = eixo9EsquerdoExt.sensor.id;
          }

          if (eixo9EsquerdoExt.pneu != null) {
            this.axisNineDoubleLeftExtForm.controls["pneu"].setValue(
              eixo9EsquerdoExt.pneu.numeroFogo
            );
          }

          this.axisNineDoubleLeftExtForm.controls["nrPressaoMinima"].setValue(
            eixo9EsquerdoExt.nrPressaoMinima
          );

          this.axisNineDoubleLeftExtForm.controls["nrPressaoMaxima"].setValue(
            eixo9EsquerdoExt.nrPressaoMaxima
          );

          this.axisNineDoubleLeftExtForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo9EsquerdoExt.nrTemperaturaMaxima);
        }

        // EIXO 9 DIREITO INTERNO
        if (eixo9DireitoInt != null) {
          this.axisNineDoubleRightIntForm.controls["id"].setValue(
            eixo9DireitoInt.id
          );

          if (eixo9DireitoInt.sensor != null) {
            this.sensorNineIntRightCombo.push(eixo9DireitoInt.sensor);
            this.sensorNineIntRightSelect = eixo9DireitoInt.sensor.id;
          }

          if (eixo9DireitoInt.pneu != null) {
            this.axisNineDoubleRightIntForm.controls["pneu"].setValue(
              eixo9DireitoInt.pneu.numeroFogo
            );
          }

          this.axisNineDoubleRightIntForm.controls["nrPressaoMinima"].setValue(
            eixo9DireitoInt.nrPressaoMinima
          );

          this.axisNineDoubleRightIntForm.controls["nrPressaoMaxima"].setValue(
            eixo9DireitoInt.nrPressaoMaxima
          );

          this.axisNineDoubleRightIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo9DireitoInt.nrTemperaturaMaxima);
        }

        // EIXO 9 ESQUERDO INTERNO
        if (eixo9EsquerdoInt != null) {
          this.axisNineDoubleLeftIntForm.controls["id"].setValue(
            eixo9EsquerdoInt.id
          );

          if (eixo9EsquerdoInt.sensor != null) {
            this.sensorNineIntLeftCombo.push(eixo9EsquerdoInt.sensor);
            this.sensorNineIntLeftSelect = eixo9EsquerdoInt.sensor.id;
          }

          if (eixo9EsquerdoInt.pneu != null) {
            this.axisNineDoubleLeftIntForm.controls["pneu"].setValue(
              eixo9EsquerdoInt.pneu.numeroFogo
            );
          }

          this.axisNineDoubleLeftIntForm.controls["nrPressaoMinima"].setValue(
            eixo9EsquerdoInt.nrPressaoMinima
          );

          this.axisNineDoubleLeftIntForm.controls["nrPressaoMaxima"].setValue(
            eixo9EsquerdoInt.nrPressaoMaxima
          );

          this.axisNineDoubleLeftIntForm.controls[
            "nrTemperaturaMaxima"
          ].setValue(eixo9EsquerdoInt.nrTemperaturaMaxima);
        }
      }

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("loadVeiculo() error", error);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
