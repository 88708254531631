import { STORAGE_KEY_CURRENT_USER } from "./../../storageCore/constStorageKeys";
import { environment } from "./../../../environments/environment";
import { ToolsService } from "./../../services/migrate/tools.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ContentInterface } from "./../../contracts/Content.interface";
import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { ApiService } from "../../services/api.service";
import { Validation } from "./validation";
import { MaintenanceInterface } from "src/app/contracts/Maintenance.interface";
import { MatTableDataSource } from "@angular/material/table";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_ID_PERFIL } from "src/app/storageCore/constStorageKeys";
import { PrimeNGConfig } from "primeng/api";
import PRIME_TRANSLATE from "src/app/utils/primetranslate";

@Component({
  selector: "app-create-maintenance",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CreateMaintenanceComponent implements OnInit {
  public manutencaoForm: FormGroup;
  public title = "Cadastrar Manutenção";
  public errorMessage;
  private service: ApiService;
  private validation: Validation;
  public visible;
  public alarm;
  public yearRange = String(new Date().getFullYear())
    .concat(":")
    .concat(String(new Date().getFullYear() + 15));

  public veiculoSelected;
  public vehicle: any = [];
  public loader = false;

  ngOnInit(): void {
    this.manutencaoForm = this.formBuilder.group(this.validation.rules());
    this.setup();
    this.primengConfig.setTranslation(PRIME_TRANSLATE);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public handleChange(event) {
    this.visible = event.checked;
    this.alarm = event.checked;
  }

  public cancelChange(event) {
    this.visible = false;
    this.alarm = false;
  }

  async setup(): Promise<void> {
    try {
      this.spinner.show();

      const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();

      const vehicles = await this.service.get<ContentInterface[]>({
        url: "veiculo/perfil/" + perfil + "?size=2000",
      });

      this.vehicle = vehicles["content"];

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      throw new EvalError(error);
    }
  }

  public manutencao: MatTableDataSource<MaintenanceInterface>;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private primengConfig: PrimeNGConfig,
    private spinner: NgxSpinnerService,
    private tools: ToolsService
  ) {
    this.service = apiService;
    this.validation = new Validation();
  }

  public validateKmOrDate() {
    if (
      !this.manutencaoForm.value.dtManutencao &&
      !this.manutencaoForm.value.nrQuilometragem
    ) {
      this.errorMessage = "Km ou data deve ser preenchido";
      throw new EvalError(this.errorMessage);
    }
  }

  public async submitForm(): Promise<void> {
    this.coverterUpperCase(this.manutencaoForm);
    this.validateKmOrDate();

    const values = this.manutencaoForm.value;

    const payload = {
      ...values,
      veiculoId: values.veiculo,
    };

    const id = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;
    this.spinner.show();
    this.tools
      .builder()
      .api.post(`${environment.othersUrl.core}/v2/maintenances/${id}`, payload)
      .subscribe(() => {
        this.router.navigate(["/maintenance"]);
        this.spinner.hide();
      });
  }

  public coverterUpperCase(manutencaoForm: FormGroup) {
    console.log(manutencaoForm);

    manutencaoForm
      .get("dsDescricao")
      .setValue(manutencaoForm.get("dsDescricao").value.toUpperCase());
  }
}
