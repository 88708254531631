import { Component, ViewChild, OnInit } from "@angular/core";
import {
  MatButtonToggleChange,
  MatButtonToggle,
} from "@angular/material/button-toggle";
import { MapToolboxService } from "./../../../../services/map.toolbox.service";
import {PermissionsService} from "../../../../helpers/permissions.service";

@Component({
  selector: "toolbox-button-plate",
  templateUrl: "./button-plate.component.html",
  styleUrls: ["./button-plate.component.scss"],
})
export class ButtonPlateComponent implements OnInit {
  @ViewChild(MatButtonToggle) btnAddMarker: MatButtonToggle;
  permissions: [];

  public action: string;
  private service: MapToolboxService;

  constructor(service: MapToolboxService,
              public permissionService: PermissionsService) {
    this.service = service;
  }
  ngOnInit(): void {
    this.permissions = this.permissionService.getPermissions();
    this.service.currentToolboxCommand.subscribe((action) => {
      this.action = action;
    });
  }

  toggleView(change: MatButtonToggleChange): void {
    const { value, source } = change;
    const [selected = "hide_plate"] = value;
    console.log("value", selected);
    this.btnAddMarker = source;
    this.service.setToolboxCommand(selected);
  }
}
