import { Injectable } from "@angular/core";
import { HateoasBaseService } from "./hateoas-base.service";
import { HttpUtil } from "../utils/http-util";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { PageEvent } from "@angular/material/paginator";

import { Observable } from "rxjs";
import { AnalyzeReportModel } from "../models/analyze-report-model";
import { toSnake } from "snake-camel";
import { ManagerAnalyzeModel } from "../models/manager-analyze-model";
import { OperatorAnalyzeListModel } from "../models/operator-analize-list-model";
import { RejectionManagerModel } from "../models/rejection-manager-model";
import { RejectionSupervisorModel } from "../models/rejection-supervisor-model";
import { OperatorAnalyzeModel } from "../models/operator-analize-model";
import { FinishOperatorModel } from "../models/finish-operator-model";
import { ManagerModel } from "../models/manager-model";
import { SupervisorCompanyRequestModel } from "../models/supervisor-company-request-model";

@Injectable({
  providedIn: "root",
})
export class AnalyzeTripService extends HateoasBaseService {
  // tslint:disable-next-line:variable-name
  private _httpUtil: HttpUtil;
  private resourceNameAnalyzeReport = "analyze-report";
  private resourceNameOperatorDealings = "operator-dealings";
  private resourceNameOperatorAnalyze = "operator-analyze";
  private resourceNameManagerDealings = "manager-dealings";
  private resourceNameManagerAnalyze = "manager-analyze";
  private resourceNameManager = "manager";
  private resourceNameSupervisor = "supervisor";
  private resourceNameNotifyOperator = "notify-operator";
  private resourceNameOperationalBase = "operational-base";

  constructor(http: HttpClient, httpUtil: HttpUtil) {
    super(http, httpUtil);
    this._httpUtil = httpUtil;
  }

  public createAnalyzeReport(
    analyzeReportModel: AnalyzeReportModel
  ): Observable<any> {
    let request = toSnake(analyzeReportModel);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "save-operator",
      null,
      request,
      null
    );
  }

  public createManager(
    managerModel: ManagerModel
  ): Observable<any> {
    let request = toSnake(managerModel);
    return this.executeActionOnResource(
      this.resourceNameManager,
      "save",
      null,
      request,
      null
    );
  }

  public createSupervisor(
    managerModel: ManagerModel
  ): Observable<any> {
    let request = toSnake(managerModel);
    return this.executeActionOnResource(
      this.resourceNameSupervisor,
      "save",
      null,
      request,
      null
    );
  }

  public createAnalyzeOperator(
    operatorAnalyzeListModel: OperatorAnalyzeListModel[]
  ): Observable<any> {
    let request = toSnake(operatorAnalyzeListModel);
    return this.executeActionOnResource(
      this.resourceNameOperatorAnalyze,
      "save-treat-operator",
      null,
      request,
      null
    );
  }

  public createAnalyzeManager(
    managerAnalyzeModel: ManagerAnalyzeModel
  ): Observable<any> {
    let request = toSnake(managerAnalyzeModel);
    return this.executeActionOnResource(
      this.resourceNameManagerAnalyze,
      "save-manager",
      null,
      request,
      null
    );
  }

  public createRejectionManager(
    rejectionManager: RejectionManagerModel
  ): Observable<any> {
    let request = toSnake(rejectionManager);
    return this.executeActionOnResource(
      this.resourceNameNotifyOperator,
      "save-manager-rejection",
      null,
      request,
      null
    );
  }

  public createRejectionSupervisor(
    rejectionSupervisor: RejectionSupervisorModel
  ): Observable<any> {
    let request = toSnake(rejectionSupervisor);
    return this.executeActionOnResource(
      this.resourceNameNotifyOperator,
      "save-supervisor-rejection",
      null,
      request,
      null
    );
  }

  public updateAnalyzeReport(
    analyzeReport: AnalyzeReportModel,
    uuid: string
  ): Observable<any> {
    const analyzeReportSnake = toSnake(analyzeReport);
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "update",
      null,
      analyzeReportSnake,
      params
    );
  }

  public updateOperatorAnalyzeTreat(
    operatorAnalyzeModel: OperatorAnalyzeModel,
    uuid: string
  ): Observable<any> {
    const analyzeReportSnake = toSnake(operatorAnalyzeModel);
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameOperatorAnalyze,
      "update-treat-operator",
      null,
      analyzeReportSnake,
      params
    );
  }

  public updateNewAnalyzeReport(
    analyzeReport: AnalyzeReportModel,
    uuid: string,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const analyzeReportSnake = toSnake(analyzeReport);
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    params.set("startDate", startDate);
    params.set("endDate", endDate);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "update-analyze",
      null,
      analyzeReportSnake,
      params
    );
  }

  public finishOperator(
    finishOperatorModel: FinishOperatorModel,
    requestOperator: string,
    uuid: string
  ): Observable<any> {
    const finishOperatorSnake = toSnake(finishOperatorModel);
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    params.set("requestOperator", requestOperator);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "finish-operator",
      null,
      finishOperatorSnake,
      params
    );
  }

  public finishReport(requestOperator: string, uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    params.set("requestOperator", requestOperator);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "finish-operator-report",
      null,
      null,
      params
    );
  }

  public getTempleteInformation(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "get-templete-information",
      null,
      null,
      params
    );
  }

  public finishSupervisor(
    requestSupervisor: string,
    uuid: string
  ): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    params.set("requestSupervisor", requestSupervisor);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "finish-supervisor",
      null,
      null,
      params
    );
  }

  public deleteAnalyzeReport(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "delete-by-uuid",
      null,
      null,
      params
    );
  }

  public deleteTreatOperator(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameOperatorAnalyze,
      "delete-by-uuid",
      null,
      null,
      params
    );
  }

  public getMinioJsonOperator(profile: string, uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    params.set("profile", profile);
    return this.executeActionOnResource(
      this.resourceNameOperatorDealings,
      "get-file-minio-json",
      null,
      null,
      params
    );
  }

  public getAllOperatorDealings(): Observable<any> {
    return this.executeActionOnResource(
      this.resourceNameOperatorDealings,
      "get-all",
      null,
      null,
      null
    );
  }

  public getAllOperationaBase(): Observable<any> {
    return this.executeActionOnResource(
      this.resourceNameOperationalBase,
      "get-all",
      null,
      null,
      null
    );
  }

  public getByManagerFree(): Observable<any> {
    return this.executeActionOnResource(
      this.resourceNameManager,
      "get-by-manager-free",
      null,
      null,
      null
    );
  }

  public getBySupervisorFree(): Observable<any> {
    return this.executeActionOnResource(
      this.resourceNameSupervisor,
      "get-by-supervisor-free",
      null,
      null,
      null
    );
  }

  public getBySupervisor(
    idCompany: string,
    idDivision: string
  ): Observable<any> {
    const params = new Map<string, string>();
    params.set("idCompany", idCompany);
    params.set("idDivision", idDivision);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "get-by-supervisor",
      null,
      null,
      params
    );
  }

  public getByManager(idCompany: string, idDivision: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("idCompany", idCompany);
    params.set("idDivision", idDivision);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "get-by-manager",
      null,
      null,
      params
    );
  }

  public getAllAnalysisReport(uuidAnalyzeReport: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuidAnalyzeReport", uuidAnalyzeReport);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "get-all-analysis",
      null,
      null,
      params
    );
  }

  public getAllManagerDealings(): Observable<any> {
    return this.executeActionOnResource(
      this.resourceNameManagerDealings,
      "get-all",
      null,
      null,
      null
    );
  }

  public getNotifyOperator(userId: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("userId", userId);
    return this.executeActionOnResource(
      this.resourceNameNotifyOperator,
      "get-all-notify-user",
      null,
      null,
      params
    );
  }

  public operatorView(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameNotifyOperator,
      "view-operator",
      null,
      null,
      params
    );
  }

  public getOperatorTreat(uuid: string, infringement: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    params.set("infringement", infringement);
    return this.executeActionOnResource(
      this.resourceNameOperatorAnalyze,
      "get-by-uuid-analyze",
      null,
      null,
      params
    );
  }

  public countAnalyzeManager(uuidManager: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuidManager", uuidManager);

    return this.executeActionOnResource(
      this.resourceNameManagerAnalyze,
      "count-analyze-manager",
      null,
      null,
      params
    );
  }

  public countAnalyzeSupervisor(uuidSupervisor: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuidSupervisor", uuidSupervisor);

    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "count-analyze-supervisor",
      null,
      null,
      params
    );
  }

  public getAllAnalyzeReportOperator(
    page: PageEvent,
    profile: string,
    name: string,
    field: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && name !== null && field.length > 0) {
      params.set("field", field);
    }

    params.set("profile", profile);

    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "get-all-operator",
      null,
      null,
      params
    );
  }

  public getAllManager(
    page: PageEvent,
    name: string,
    field: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && name !== null && field.length > 0) {
      params.set("field", field);
    }

    return this.executeActionOnResource(
      this.resourceNameManager,
      "get-all-view",
      null,
      null,
      params
    );
  }

  public getAllSupervisor(
    page: PageEvent,
    name: string,
    field: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && name !== null && field.length > 0) {
      params.set("field", field);
    }

    return this.executeActionOnResource(
      this.resourceNameSupervisor,
      "get-all-view",
      null,
      null,
      params
    );
  }

  public getAllCompanySupervisor(
    page: PageEvent,
    name: string,
    field: string,
    uuid: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && name !== null && field.length > 0) {
      params.set("field", field);
    }

    params.set("uuid", uuid);

    return this.executeActionOnResource(
      this.resourceNameSupervisor,
      "get-all-company",
      null,
      null,
      params
    );
  }

  public getAllCompanyManager(
    page: PageEvent,
    name: string,
    field: string,
    uuid: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && name !== null && field.length > 0) {
      params.set("field", field);
    }

    params.set("uuid", uuid);

    return this.executeActionOnResource(
      this.resourceNameManager,
      "get-all-company",
      null,
      null,
      params
    );
  }

  public deleteManager(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameManager,
      "delete-by-uuid",
      null,
      null,
      params
    );
  }

  public deleteSupervisor(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameSupervisor,
      "delete-by-uuid",
      null,
      null,
      params
    );
  }

  public updateSupervisorCompany(
    supervisorCompanyRequestModel: SupervisorCompanyRequestModel,
    uuid: string
  ): Observable<any> {
    let request = toSnake(supervisorCompanyRequestModel);
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameSupervisor,
      "update-company",
      null,
      request,
      params
    );
  }

  public updateManagerCompany(
    supervisorCompanyRequestModel: SupervisorCompanyRequestModel,
    uuid: string
  ): Observable<any> {
    let request = toSnake(supervisorCompanyRequestModel);
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameManager,
      "update-company",
      null,
      request,
      params
    );
  }

  public getAllAnalyzeReportManager(
    page: PageEvent,
    uuidManager: string,
    name: string,
    field: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && field !== null && field.length > 0) {
      params.set("field", field);
    }

    params.set("uuidManager", uuidManager);

    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "get-all-manager",
      null,
      null,
      params
    );
  }

  public getAllAnalyzeReportSupervisor(
    page: PageEvent,
    uuidSupervisor: string,
    name: string,
    field: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && field !== null && field.length > 0) {
      params.set("field", field);
    }

    params.set("uuidSupervisor", uuidSupervisor);

    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "get-all-supervisor",
      null,
      null,
      params
    );
  }

  public getAllAnalyzeReportHistoric(
    profile: string,
    page: PageEvent,
    name: string,
    field: string
  ): Observable<any> {
    //TODO - Deixar essa paginação mais dinamica
    const direction = "ASC";
    const params = this._httpUtil.buildPaginator(page, direction);
    params.set("profile", profile);
    if (name !== undefined && name !== null && name.length > 0) {
      params.set("name", name);
    }

    if (field !== undefined && field !== null && field.length > 0) {
      params.set("field", field);
    }

    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "get-all-historic",
      null,
      null,
      params
    );
  }

  public exportReport(uuidMinio: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuidMinio", uuidMinio);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "get-file-minio",
      null,
      null,
      params
    );
  }

  public getByUuidAnalyzeReport(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "get-operator-by-uuid",
      null,
      null,
      params
    );
  }

  public getByOneUuidAnalyzeReport(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameAnalyzeReport,
      "get-by-uuid",
      null,
      null,
      params
    );
  }

  public getByUuidTreat(uuid: string): Observable<any> {
    const params = new Map<string, string>();
    params.set("uuid", uuid);
    return this.executeActionOnResource(
      this.resourceNameOperatorAnalyze,
      "get-by-uuid-treat",
      null,
      null,
      params
    );
  }

  protected getApiBaseUrl(): string {
    return environment.apisConfig.seteraAnalyzeTrip.apiBaseUrl;
  }

  protected getMicroServiceBaseUrl(): string {
    return environment.apisConfig.seteraAnalyzeTrip.microServiceBaseUrl;
  }
}
