import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EventEmitterSensorService {
  private reiniciarComponentes = new Subject<void>();

  reiniciarComponentes$ = this.reiniciarComponentes.asObservable();

  acionarReinicioComponentes() {
    this.reiniciarComponentes.next();
  }
}
