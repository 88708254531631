<div class="main-page consultas">
    <div class="container-fluid">
        <!-- LINHA TITULO + PESQUISA -->

        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>
                    <a class="btn btn-voltar text-center mr-2" (click)="this.router.navigate(['/consultations'])" data-title="Voltar"><i
                            class="fas fa-arrow-left"></i></a>
                    Consultar Eventos
                </h1>
            </div>
        </div>

        <div *ngIf="terminalalarme?.data?.length === 0 && !loader">
            <div class="row no-gutters mt-3">
                <p>Nenhum registro at&eacute; o momento...</p>
            </div>
            <div class="row no-gutters mt-3">
                <a (click)="this.router.navigate(['/terminal-alarme/create'])" mat-button color="primary"
                    class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                    Criar um novo evento
                </a>
            </div>
        </div>

        <div class="row no-gutters mt-3" *ngIf="terminalalarme?.data?.length > 0">
            <div class="mat-elevation table-content">
              <!--   <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field>
                        <mat-label>Campo</mat-label>
                        <select matNativeControl [(ngModel)]="typeFilter">
                            <option *ngFor="let filtro of filtros" value={{filtro.campo}}>
                                {{filtro.valor}}
                            </option>
                        </select>
                    </mat-form-field>
                    <mat-form-field fxFlex="40%" class="ml-2">
                        <input autofocus type="text" matInput placeholder="Filtro" [(ngModel)]="filter" />
                    </mat-form-field>
                    <button type="button" mat-icon-button (click)="handleFilter()">
                        <mat-icon>search</mat-icon>
                    </button>
                </div> -->

                <!-- (matSortChange)="handleSort($event)" -->

                <div fxLayout fxLayoutAlign="center center">
                    <mat-form-field fxFlex="40%" class="ml-2">
                     <mat-label>Filtrar Por Nome</mat-label>
                     <input matInput autocomplete="off"
                     (keyup)="this.applyFilter($event)"  placeholder="Ex: Arrancada Brusca" #input>
                   </mat-form-field>
                 </div>


                <table mat-table [dataSource]="terminalalarme" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                    </ng-container>


                    <ng-container matColumnDef="dsMensagem">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
                        <td mat-cell *matCellDef="let element">{{ element.dsMensagem }}</td>
                    </ng-container>

                    <ng-container matColumnDef="nmNomeIconeCss">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome Icone</th>
                        <td mat-cell *matCellDef="let element">{{ element.nmNomeIconeCss }}</td>
                    </ng-container>

                    <ng-container matColumnDef="cdCodigo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Código
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.cdCodigo }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="cdCor">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Cor
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.cdCor }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="nmFator">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Fator
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.nmFator }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="ativo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Ativo?
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.ativo ? 'SIM' : 'NAO' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="flExibePainelEvento">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Exibir Painel de Evento?</th>
                        <td mat-cell *matCellDef="let element">{{ element.flExibePainelEvento ? 'SIM' : 'NÃO'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="flMarkerRelatorioGerencial">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Relatório Gerencial?</th>
                        <td mat-cell *matCellDef="let element">{{ element.flMarkerRelatorioGerencial ? 'SIM' : 'NÃO'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="cdAtributo1">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Atributo 1</th>
                        <td mat-cell *matCellDef="let element">{{ element.cdAtributo1 }}</td>
                    </ng-container>

                    <ng-container matColumnDef="cdAtributo2">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Atributo 2</th>
                        <td mat-cell *matCellDef="let element">{{ element.cdAtributo2 }}</td>
                    </ng-container>

                    <ng-container matColumnDef="update">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Editar"
                                *ngIf="this.permissionService.hasPermission('TERMINAL_ALARME_ATUALIZACAO_FUNCIONALIDADE', this.permissions)" matTooltip="Editar">
                                <a (click)="this.router.navigate(['/terminal-alarme/edit/' + element.id])" class="btn btn-link">
                                    <img height="20" src="assets/img/edit.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="clone">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Duplicar"
                                *ngIf="this.permissionService.hasPermission('TERMINAL_ALARME_ATUALIZACAO_FUNCIONALIDADE', this.permissions)" matTooltip="Duplicar">
                                <a (click)="this.router.navigate(['/terminal-alarme/clone/' + element.id])" class="btn btn-link">
                                    <img height="20" src="assets/img/duplic.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="alerta mr-2" data-title="Visualizar"
                                *ngIf="this.permissionService.hasPermission('TERMINAL_ALARME_ATUALIZACAO_FUNCIONALIDADE', this.permissions)" matTooltip="Visualizar">
                                <a (click)="this.router.navigate(['/terminal-alarme/view/' + element.id])" class="btn btn-link">
                                    <img height="20" src="assets/img/view.svg" alt="" />
                                </a>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="handleDelete(element.id)" class="btn btn-link"
                                *ngIf="this.permissionService.hasPermission('TERMINAL_ALARME_DELECAO_FUNCIONALIDADE', this.permissions)" matTooltip="Apagar">
                                <img height="20" src="assets/img/trash-cadastro.svg" alt="" />
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 20, 40, 100]" showFirstLastButtons></mat-paginator>
              <!--   <mat-paginator *ngIf="terminalalarme.data.length" [pageSize]="this.page.size" (page)="handlePageChanged($event)"
                [length]="this.page.totalElements" showFirstLastButtons></mat-paginator> -->
            </div>
        </div>
    </div>
</div>
