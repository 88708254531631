import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { Modulo } from "../../models/Modulo.model";
import { ModuloInterface } from "../../contracts/Modulo.interface";
import { PermissionsService } from "src/app/helpers/permissions.service";
import { Page } from "src/app/utils/paginate";
import { ApiBaseService } from "src/app/services/migrate/api.base.service";
import storageX from "src/app/storageCore/storageX";
import { STORAGE_KEY_CURRENT_USER } from "src/app/storageCore/constStorageKeys";
@Component({
  selector: "app-modulo",
  templateUrl: "./modulo.component.html",
  styleUrls: ["./modulo.component.scss"],
})
export class ModuloComponent implements OnInit, AfterViewInit {
  permissions: [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('filter') filter: ElementRef;
  // @ViewChild('typeFilter') typeFilter: ElementRef;
  public filter;
  public typeFilter;

  public page = new Page();
  public errorMessage;
  public loader = false;
  public length = 0;
  public pageSize = 10;
  public totalElements = 0;
  public totalPages = 0;

  public displayedColumns: string[] = [
    //'id', OCULTADO CAMPO ID DA TABELA - 20102020 - MARCOS TAVARES
    "nmModulo",
    "update",
    "clone",
    "view",
    "delete",
  ];

  public filtros = [
    //{ campo: 'id', valor: 'ID' },
    { campo: "nmModulo", valor: "Nome Modulo" },
  ];

  private apiBaseService: ApiBaseService;
  public modulo: MatTableDataSource<ModuloInterface>;

  constructor(
    apiBaseService: ApiBaseService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public permissionService: PermissionsService
  ) {
    this.apiBaseService = apiBaseService;
    this.modulo = new MatTableDataSource<ModuloInterface>([]);
  }

  ngOnInit(): void {
    this.loadModulo();
  }
  //public handleFilter = (value: string): void => {
  //this.modulo.filter = value.trim().toLocaleLowerCase();
  //};

  public handleSort = (sort): void => {
    this.loadModulo(0, sort);
  };

  public handlePageChanged = (value): void => {
    const { pageIndex } = value;
    this.loadModulo(pageIndex);
  };
  public handleFilter = (): void => {
    this.loadModulo();
  };

  public handleDelete = (id: string): void => {
    this.deleteModulo(id);
  };

  public handleUpdate = (id): void => {
    this.router.navigate([`/modulo/${id}`]);
  };

  ngAfterViewInit(): void {
    this.modulo.sort = this.sort;
    this.modulo.paginator = this.paginator;
  }

  public async deleteModulo(id): Promise<void> {
    const idUsuario = storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id;
    if (confirm("Tem certeza que deseja deletar esse registro?")) {
      try {
        this.spinner.show();

        await this.apiBaseService.delete<Modulo>(
          idUsuario,
          id,
          new Modulo()["nomeConsultaApiBase"]
        );

        this.spinner.hide();

        this.loadModulo();
      } catch (error) {
        this.spinner.hide();
        this.errorMessage = error.message;
        throw new EvalError(error);
      }
    }
  }

  public async loadModulo(pageIndex = 0, sort?): Promise<void> {
    const params = {
      size: this.pageSize,
      page: pageIndex,
    };

    try {
      this.spinner.show();

      const result = await this.apiBaseService.get<any>({
        url: new Modulo()["nomeConsultaApiBase"],
        params,
      });

      this.modulo.data = result["content"];
      this.page = result["page"];

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
