<div class="main-page cadastros journey-main">
    <div class="container-fluid"> 
        <div class="row align-items-center">
            <div class="col-12 my-2">
              <h1>Relatório de Tratativa de Eventos</h1>
            </div>
        </div>            
        <p-menubar #menu [model]="items"></p-menubar>
        <mat-horizontal-stepper [linear]="true" #stepper>
            <mat-step>
                
                <!-- COMECA -->        
                <p-table #journeyheader [value]="this.resultadoAnalitico" tableStyleClass="tableAnalitico">    
                    <ng-template pTemplate="header" let-columns>            
                        <tr [hidden]="this.resultadoAnalitico.length == 0" id="tabela-analitico">
                            <th style="text-align:center !important;width: 12% !important;border:none"class="linha-analitico">Data Inicio</th>
                            <th style="text-align:center !important;width: 12% !important;border:none"class="linha-analitico">Data Fim</th>
                            <th style="text-align:center !important;border:none"class="linha-analitico">Tempo Total Tratativa</th>
                            <th style="text-align:center !important;border:none"class="linha-analitico">Tipo de Evento</th>
                            <th style="text-align:center !important;width: 12% !important;border:none"class="linha-analitico">Status Evento</th>  
                            <th style="text-align:center !important;border:none"class="linha-analitico">Operador da Tratativa</th>  
                            <th style="text-align:center !important;width: 15% !important;border:none"class="linha-analitico">Descrição Tratativa</th>     
                            <th style="text-align:center !important;border:none"class="linha-analitico">Empresa/Divisão</th>        
                            <th style="text-align:center !important;width: 7% !important;border:none"class="linha-analitico">Placa do Veículo</th>            
                            <th style="text-align:center !important;width: 7% !important;border:none"class="linha-analitico">Frota do Veículo</th>
                        </tr>
                    </ng-template>
                   
                <br>
                    <ng-template pTemplate="body" let-rowdata >          
                        <tr>
                            <td style="text-align:center !important;padding: 8px !important;border:none"  align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["dataInicioEvento"]}}</td>
                            <td style="text-align:center !important;padding: 8px !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["dataFimEvento"]}}</td>
                            <td style="text-align:center !important;padding: 8px !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["tempoTotalTratativa"]}}</td>
                            <td style="text-align:center !important;padding: 8px !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["nomeEvento"]}}</td>
                            <td *ngIf="rowdata['statusEvento'] == true" style="text-align:center !important;padding: 8px !important; color: #220de6; border:none" align="center" valign=middle [pRowToggler]="rowdata">FINALIZADO</td>
                            <td *ngIf="rowdata['statusEvento'] == false" style="text-align:center !important;padding: 8px !important;color: #ff9204;border:none" align="center" valign=middle [pRowToggler]="rowdata">EM TRATAMENTO</td>
                            <td *ngIf="rowdata['statusEvento'] == null" style="text-align:center !important;padding: 8px !important;color: #ff9204;border:none" align="center" valign=middle [pRowToggler]="rowdata">EM TRATAMENTO</td>
                            <td style="text-align:center !important;padding: 8px !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["operadorEvento"]}}</td>
                            <td style="text-align:center !important;padding: 8px !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["descricaoTratativa"]}}</td>
                            <td style="text-align:center !important;padding: 8px !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["empresa"]}}</td>
                            <td style="text-align:center !important;padding: 8px !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["placa"]}}</td>
                            <td style="text-align:center !important;padding: 8px !important;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["frota"]}}</td>             
                        </tr>                 
                    </ng-template>
                </p-table>
                <!-- FIM-->            
            </mat-step>     
        </mat-horizontal-stepper>
    </div>
</div>

<p-sidebar [(visible)]="this.filterDialog" position="right">
    <div style="margin-bottom: 20px;">
        <span>Filtros</span> 
    </div>
    <form [formGroup]="this.consultaFormGroup">
        <div class="form-row">
            <div class="form-group col-lg-12">
            <mat-form-field appearance="outline">
                <mat-label>Data Inicío do Evento</mat-label>
                <input formControlName="dataInicioEvento" type="datetime-local" matInput placeholder="Inicio" required />
            </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-lg-12">
            <mat-form-field appearance="outline">
                <mat-label>Data Fim do Evento</mat-label>
                <input formControlName="dataFimEvento" type="datetime-local" matInput placeholder="Fim" required />
            </mat-form-field>
            </div>
        </div>    

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                <mat-label>Tipo de Empresa</mat-label>
                    <mat-select formControlName="selecaoEmpresa" [(value)]="empresaFilterSelected" [(ngModel)]="empresaFilterSelected" (selectionChange)="this.filterCompanyReports()">
                        <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Empresa</mat-label>
                    <input type="text"  matInput [formControl]="controlEmpresa" [matAutocomplete]="autoEmpresa" required>                     
                    <mat-autocomplete #autoEmpresa="matAutocomplete" [displayWith]="displayEmpresa">
                         <mat-option *ngFor="let type of this.companiesFilter" [value]="type">
                            {{type.nmNome}}
                        </mat-option>
                    </mat-autocomplete>                       
                </mat-form-field>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline"*ngIf="controlEmpresa.value != ''">
                    <mat-label>Placa / Frota</mat-label >
                    <input type="text" matInput [formControl]="controlVeiculo" [matAutocomplete]="autoTerminal">                     
                    <mat-autocomplete #autoTerminal="matAutocomplete" [displayWith]="displayTerminal">
                         <mat-option *ngFor="let type of this.vehiclesFiltered | async" [value]="type">
                            {{type.cdPlaca}}/{{type.frota}}
                        </mat-option>
                    </mat-autocomplete>                       
                </mat-form-field>            
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo de Evento</mat-label>
                    <input type="text" matInput [formControl]="controlEvents" [matAutocomplete]="autoEvents">                     
                    <mat-autocomplete #autoEvents="matAutocomplete">
                         <mat-option *ngFor="let type of this.events" [value]="type">
                            {{type}}
                        </mat-option>
                    </mat-autocomplete>                                        
                </mat-form-field>            
            </div>
        </div>
        
        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Status do Evento:</mat-label>
                        <select matNativeControl formControlName="controlStatusEvento">                                  
                            <option value = 1>
                                RESOLVIDO
                            </option>                       
                            <option value = 3 selected>
                                TODOS
                            </option>
                            <option value = 2 >
                                EM ANDAMENTO
                            </option>     
                        </select>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">                
                <button type="submit" (click)="onConsultar()" [disabled]="!consultaFormGroup.valid">
                    Consultar
                </button>                
            </div>
        </div>
       

    </form>
  
</p-sidebar>