import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { VehicleMenu } from "../models/VehicleMenu.model";
import storageX from "../storageCore/storageX";
import { STORAGE_KEY_ACCESS_TOKEN_WSO2 } from "../storageCore/constStorageKeys";
import { GeneralFilterOption } from "../components/maps/general-filter/general-filter-option";

@Injectable({
  providedIn: "root",
})
export class VehiclesMenuService {
  RESOURCE = environment.apiUrl.concat("/vehicles-menu");

  constructor(private http: HttpClient) {}

  public getVehiclesMenuByProfile(profileId: number): Observable<VehicleMenu> {
    const url = this.RESOURCE.concat("?profile=" + profileId);

    return this.http.get<VehicleMenu>(url, {
      headers: {
        Authorization:
          "Bearer " +
          `${storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get()}`,
        "Content-Type": "application/json",
      },
    });
  }

  public getVehiclesToSelectByProfileAndSearch(
    profileId: number,
    search: string
  ): Observable<GeneralFilterOption[]> {
    const url = this.RESOURCE.concat(
      "/to-select?profile=" + profileId + "&search=" + search
    );

    return this.http.get<GeneralFilterOption[]>(url, {
      headers: {
        Authorization:
          "Bearer " +
          `${storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get()}`,
        "Content-Type": "application/json",
      },
    });
  }
}
