import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MarkerCore {

  public id: number;
  public setIdMarker$ = new EventEmitter<any>();
  constructor() { }
}
