import { PerfilInterface } from "../contracts/Perfil.interface";
import { TypeProfileInterface } from "../contracts/TypeProfile.interface";
import { PerfilFuncionalidadeInterface } from "../contracts/PerfilFuncionalidade.interface";

export class Perfil implements PerfilInterface {
  nomeConsultaApiBase = "perfil";

  id: number;
  nmPerfil: string;
  dsPerfil: string;
  perfilTipo: TypeProfileInterface;
  perfilFuncionalidades: PerfilFuncionalidadeInterface[];
}
