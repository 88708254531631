<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>Rejeitar Análise</app-title>
  
        <div class="row mt-3 no-gutters p-4 row-form">
              <div class="col-12">
                  <form [formGroup]="rejectionSupervisorForm" (ngSubmit)="submitForm()">
                      <h2 class="mb-3">Dados da Rejeição</h2>
                      <div class="form-row" >
                        
                        <div class="form-group col-lg-10">
                          <mat-form-field appearance="outline">
                              <mat-label>Comentário</mat-label>
                              <input formControlName="supervisorComment" required matInput placeholder="Comentário">
                          </mat-form-field>
                        </div>
                      </div>
                        
  
                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>
  
                    <div class="form-group mt-2 mb-0 text-center">
                        <back-button></back-button>
                        <button type="submit" [disabled]="!rejectionSupervisorForm.valid"
                            class="btn ml-0 ml-sm-2 ml-md-3 btn-salvar my-2 my-md-0">
                            Rejeitar Análise
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
    
  </div>
  
  