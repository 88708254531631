<button class="close" mat-button (click)="close()">
  X
</button>

<div *ngIf="!distance" style="justify-items: center; width: 100%;">
  <mat-spinner value="10" style="margin: 0 auto;" class="loader"></mat-spinner>
</div>

<div *ngIf="distance">
  <small>Distância total de:</small>
  <h2 mat-dialog-title>{{ distance + ' Km' }}</h2>
</div>
