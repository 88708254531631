import { ApiBaseService } from "./../../services/migrate/api.base.service";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { User } from "../../models/User.model";
import { NgxSpinnerService } from "ngx-spinner";
import storageX from "src/app/storageCore/storageX";
import {
  STORAGE_KEY_CURRENT_USER,
  STORAGE_KEY_ID_PERFIL,
} from "src/app/storageCore/constStorageKeys";

@Component({
  selector: "app-create-user",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class CloneUserComponent implements OnInit {
  public userForm: FormGroup;
  public user: User;
  public userId: boolean;
  public userIdInternal: number;
  public title = "Duplicar Usuário";
  public errorMessage;
  public types: any = [];
  public situations: any = [];

  ngOnInit(): void {
    this.userId = false;
    this.userForm = this.formBuilder.group({
      cdUsuario: ["", Validators.required],
      nmUsuario: ["", Validators.required],
      dsEmail: ["", Validators.required],
      nrMatricula: [""],
      cdSenha: ["", Validators.required],
      generalFilterPermission: [""],
    });
    this.userIdInternal = this.activatedRoute.snapshot.params.id;
    this.loadUser(this.userIdInternal);
  }

  exibevalor(): void {}

  public async loadUser(id): Promise<void> {
    try {
      this.spinner.show();

      const response = await this.apiService.get<User>({
        url: new User().nomeConsultaApiBase + "/" + this.userIdInternal,
      });

      this.user = response as User;
      this.loadCompany(this.user.idEmpresa);

      this.fillUserData();

      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }

  public async loadCompany(id): Promise<void> {
    const perfil = storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get();
    try {
      const params = {
        size: 999999,
        page: 0,
        profileId: storageX.whereKeyIs(STORAGE_KEY_ID_PERFIL).get(),
      };

      this.spinner.hide();
    } catch (error) {
      console.error("loadCompany() error", error);
    }
  }

  constructor(
    private apiService: ApiBaseService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  fillUserData(): void {
    //Limpa o id do u
    const fields = [
      "cdUsuario",
      "nrMatricula",
      "nmUsuario",
      "dsEmail",
      "generalFilterPermission",
    ];
    fields.forEach((element) => {
      this.userForm.controls[element].setValue(this.user[element]);
    });
  }

  public async submitForm(): Promise<void> {
    try {
      this.spinner.show();

      const values = this.userForm.value;

      let post;
      post = {
        ...values,
        ativo: true,
        idUsuario: storageX.whereKeyIs(STORAGE_KEY_CURRENT_USER).get()?.id,
      };

      await this.apiService.post<User>(new User().nomeConsultaApiBase, post);

      this.spinner.hide();

      this.router.navigate(["/consulta/USER_FUNCIONALIDADE"]);
    } catch (error) {
      this.spinner.hide();
      this.errorMessage = error.message;
      throw new EvalError(error);
    }
  }
}
