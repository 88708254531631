<div class="main-page cadastros">
    <div class="container-fluid">
        <app-title>{{ title ? title : 'Inserir novo' }} </app-title>

        <div class="row mt-3 no-gutters p-4 row-form">
            <div class="col-12">
                <form [formGroup]="perfilTipoForm">
                    <h2 class="mb-3">Novo Tipo de Perfil</h2>
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome Tipo de Perfil</mat-label>
                                <input autofocus formControlName="nmPerfilTipo" matInput
                                    placeholder="Nome Tipo de Perfil" maxlength="200"  [readonly]=true [disabled]=true/>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Cad. Tipo de Perfil</mat-label>
                                <input formControlName="cdPerfilTipo" matInput placeholder="Cadastro Tipo de Perfil"
                                    maxlength="200"  [readonly]=true [disabled]=true/>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>