export enum ApiEndPoints {
  JOURNEYS = "setera-reports/v1/journeys",
  JOURNEYS_CONTROLS = "setera-reports/v1/journeys/contols",
  REPORT_TERMINAL_STATUS_PNEUS = "setera-reports/v1/terminal-status-pneus/telemetrias",
  REPORT_SUMMARY = "setera-reports/v1/reports-alarms-summaries",
  DRIVERS = "setera-core/v1/v2/drivers",
  COMPANY = "setera-core/v1/v2/companys",
  CHECK_CODES = "setera-notify/v1/notifys/token/checks-codes",
  CREATE_PASSWORD = "setera-notify/v1/users/create-password",
  RECOVERS_PASSWORDS = "/users/recovers-passwords",
  TERMINALS = "setera-core/v1/v2/terminals",
}
