import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CurrentPosition } from "../models/CurrentPosition";
import { environment } from "../../environments/environment";
import storageX from "../storageCore/storageX";
import { STORAGE_KEY_ACCESS_TOKEN_WSO2 } from "../storageCore/constStorageKeys";

@Injectable({
  providedIn: "root",
})
export class CurrentPositionService {
  RESOURCE = environment.apiUrl.concat("/current-positions");

  constructor(private http: HttpClient) {}

  public getCurrentPositionTerminals(
    terminals: Array<number>
  ): Observable<Array<CurrentPosition>> {
    let url = this.RESOURCE;
    if (terminals.length > 0) {
      url = url.concat("?terminals=" + terminals.toString());
    }
    return this.http.get<Array<CurrentPosition>>(url, {
      headers: this.getHeaders(),
    });
  }

  public getCurrentPositionTerminalsByCompany(
    companyId: number
  ): Observable<Array<CurrentPosition>> {
    const url = this.RESOURCE.concat("?idEmpresa=" + companyId);

    return this.http.get<Array<CurrentPosition>>(url, {
      headers: this.getHeaders(),
    });
  }

  public getCurrentPositionTerminalsByDivision(
    divisionId: number
  ): Observable<Array<CurrentPosition>> {
    const url = this.RESOURCE.concat("?idDivisao=" + divisionId);

    return this.http.get<Array<CurrentPosition>>(url, {
      headers: this.getHeaders(),
    });
  }

  private getHeaders(): any {
    return {
      Authorization:
        "Bearer " +
        `${storageX.whereKeyIs(STORAGE_KEY_ACCESS_TOKEN_WSO2).get()}`,
      "Content-Type": "application/json",
    };
  }
}
