<div class="main-page consultas">
  <div class="container-fluid"> 
    <!-- LINHA TITULO + PESQUISA -->
    <app-title>{{ title }} </app-title>

    <div *ngIf="events?.data?.length === 0">
      <div class="row no-gutters mt-3">
          <p>Nenhum registro at&eacute; o momento...</p>
      </div>        
    </div>

  <div class="row no-gutters mt-3" *ngIf="events?.data?.length > 0">
      <div class="mat-elevation table-content">                
          <div fxLayout fxLayoutAlign="center center">
              <mat-form-field fxFlex="40%" class="ml-2">
               <mat-label>Filtrar</mat-label>
               <input matInput autocomplete="off"
               (keyup)="this.applyFilter($event)"  placeholder="" #input>
             </mat-form-field>
           </div>

          <table mat-table [dataSource]="events" matSort class="mat-elevation-z8">

              <ng-container matColumnDef="dhVisualizacao">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                   <span title="Não visualizado" *ngIf="!element?.dhVisualizacao" class="material-icons destacarNaoLido">
                    visibility_off
                    </span>
        
                    <span title="visualizado" *ngIf="!element?.dhFinalizacao && element?.dhVisualizacao " class="material-icons destacarLido">
                      visibility
                      </span>
                </td>
              </ng-container>
        
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Código </th>
                <td mat-cell *matCellDef="let element"
                 >
                  {{element.id}}
                </td>
              </ng-container>
        
              <ng-container matColumnDef="dhAlarme">
                <th mat-header-cell *matHeaderCellDef> Data Evento </th>
                <td mat-cell *matCellDef="let element"
                 >
                  {{element.dhAlarme | date : "dd/MM/yyyy HH:mm"}}
                </td>
              </ng-container>
        
              <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef> Motorista </th>
                <td mat-cell *matCellDef="let element"
                 >
                  {{!element?.nome ? "--" : element?.nome}}
                </td>
              </ng-container>
        
              <ng-container matColumnDef="nmEmpresa">
                <th mat-header-cell *matHeaderCellDef> Empresa/Filial </th>
                <td mat-cell *matCellDef="let element"
                 >
                  {{element.nmEmpresa != null ? element.nmEmpresa : "--"}}
                </td>
              </ng-container>
        
              <ng-container matColumnDef="nmDivisao">
                <th mat-header-cell *matHeaderCellDef> Divisão </th>
                <td mat-cell *matCellDef="let element"
                 >
                  {{element.nmDivisao != null ? element.nmDivisao : "--"}}
                </td>
              </ng-container>
        
              <ng-container matColumnDef="placa">
                <th mat-header-cell *matHeaderCellDef> Placa </th>
                <td mat-cell *matCellDef="let element"
                 >
                  {{element.placa}}
                </td>
              </ng-container>
        
              <ng-container matColumnDef="flFinalizado">
                <th mat-header-cell *matHeaderCellDef> Finalizado </th>
                <td mat-cell *matCellDef="let element"
                 >
                  {{element?.flFinalizado ? 'Sim' : 'Não'}}
                </td>
              </ng-container>
        
              <ng-container matColumnDef="visualizar">
                <th mat-header-cell *matHeaderCellDef> Visualizar </th>
                <td mat-cell *matCellDef="let row"
                 class="icon-cust">
                  <mat-button-toggle *ngIf="this.loading && row.id == this.id"  [class.spinner]="this.loading" value="search">
                    <mat-icon>search</mat-icon>
                 </mat-button-toggle>
        
                 <mat-button-toggle *ngIf="!this.loading" (click)="visualizar(row)" value="search">
                  <mat-icon>search</mat-icon>
               </mat-button-toggle>
        
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>                   
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>                                        
          </table>

          <mat-paginator [pageSizeOptions]="[10, 20, 40, 100]" showFirstLastButtons></mat-paginator>                 
      </div>
     </div>
    
  </div>
</div>
